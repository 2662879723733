'use strict';

var cov_1d2zlpfn8d = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/modules/formFromKobo.js',
      hash = 'b4559fa474bc91569c5d2bc2f4813c3a81351f63',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/modules/formFromKobo.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 216,
          column: 4
        }
      },
      '1': {
        start: {
          line: 2,
          column: 17
        },
        end: {
          line: 30,
          column: 3
        }
      },
      '2': {
        start: {
          line: 32,
          column: 24
        },
        end: {
          line: 36,
          column: 3
        }
      },
      '3': {
        start: {
          line: 33,
          column: 4
        },
        end: {
          line: 35,
          column: 8
        }
      },
      '4': {
        start: {
          line: 34,
          column: 6
        },
        end: {
          line: 34,
          column: 32
        }
      },
      '5': {
        start: {
          line: 38,
          column: 2
        },
        end: {
          line: 215,
          column: 4
        }
      },
      '6': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 42,
          column: 7
        }
      },
      '7': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 41,
          column: 18
        }
      },
      '8': {
        start: {
          line: 44,
          column: 19
        },
        end: {
          line: 44,
          column: 35
        }
      },
      '9': {
        start: {
          line: 46,
          column: 6
        },
        end: {
          line: 120,
          column: 7
        }
      },
      '10': {
        start: {
          line: 48,
          column: 10
        },
        end: {
          line: 48,
          column: 37
        }
      },
      '11': {
        start: {
          line: 49,
          column: 10
        },
        end: {
          line: 49,
          column: 115
        }
      },
      '12': {
        start: {
          line: 52,
          column: 10
        },
        end: {
          line: 52,
          column: 37
        }
      },
      '13': {
        start: {
          line: 53,
          column: 10
        },
        end: {
          line: 53,
          column: 114
        }
      },
      '14': {
        start: {
          line: 56,
          column: 10
        },
        end: {
          line: 56,
          column: 37
        }
      },
      '15': {
        start: {
          line: 57,
          column: 10
        },
        end: {
          line: 57,
          column: 113
        }
      },
      '16': {
        start: {
          line: 60,
          column: 10
        },
        end: {
          line: 60,
          column: 37
        }
      },
      '17': {
        start: {
          line: 61,
          column: 10
        },
        end: {
          line: 61,
          column: 113
        }
      },
      '18': {
        start: {
          line: 63,
          column: 10
        },
        end: {
          line: 63,
          column: 37
        }
      },
      '19': {
        start: {
          line: 64,
          column: 10
        },
        end: {
          line: 64,
          column: 81
        }
      },
      '20': {
        start: {
          line: 67,
          column: 10
        },
        end: {
          line: 67,
          column: 37
        }
      },
      '21': {
        start: {
          line: 68,
          column: 10
        },
        end: {
          line: 68,
          column: 156
        }
      },
      '22': {
        start: {
          line: 72,
          column: 10
        },
        end: {
          line: 72,
          column: 37
        }
      },
      '23': {
        start: {
          line: 73,
          column: 10
        },
        end: {
          line: 73,
          column: 113
        }
      },
      '24': {
        start: {
          line: 76,
          column: 10
        },
        end: {
          line: 76,
          column: 37
        }
      },
      '25': {
        start: {
          line: 77,
          column: 10
        },
        end: {
          line: 77,
          column: 42
        }
      },
      '26': {
        start: {
          line: 78,
          column: 10
        },
        end: {
          line: 78,
          column: 81
        }
      },
      '27': {
        start: {
          line: 81,
          column: 10
        },
        end: {
          line: 81,
          column: 37
        }
      },
      '28': {
        start: {
          line: 82,
          column: 10
        },
        end: {
          line: 82,
          column: 27
        }
      },
      '29': {
        start: {
          line: 83,
          column: 10
        },
        end: {
          line: 83,
          column: 81
        }
      },
      '30': {
        start: {
          line: 86,
          column: 26
        },
        end: {
          line: 90,
          column: 11
        }
      },
      '31': {
        start: {
          line: 88,
          column: 14
        },
        end: {
          line: 88,
          column: 51
        }
      },
      '32': {
        start: {
          line: 91,
          column: 22
        },
        end: {
          line: 91,
          column: 23
        }
      },
      '33': {
        start: {
          line: 92,
          column: 22
        },
        end: {
          line: 92,
          column: 24
        }
      },
      '34': {
        start: {
          line: 93,
          column: 10
        },
        end: {
          line: 102,
          column: 11
        }
      },
      '35': {
        start: {
          line: 94,
          column: 12
        },
        end: {
          line: 94,
          column: 20
        }
      },
      '36': {
        start: {
          line: 95,
          column: 12
        },
        end: {
          line: 97,
          column: 13
        }
      },
      '37': {
        start: {
          line: 96,
          column: 14
        },
        end: {
          line: 96,
          column: 20
        }
      },
      '38': {
        start: {
          line: 98,
          column: 12
        },
        end: {
          line: 100,
          column: 13
        }
      },
      '39': {
        start: {
          line: 99,
          column: 14
        },
        end: {
          line: 99,
          column: 23
        }
      },
      '40': {
        start: {
          line: 103,
          column: 10
        },
        end: {
          line: 103,
          column: 41
        }
      },
      '41': {
        start: {
          line: 104,
          column: 10
        },
        end: {
          line: 104,
          column: 89
        }
      },
      '42': {
        start: {
          line: 107,
          column: 10
        },
        end: {
          line: 107,
          column: 37
        }
      },
      '43': {
        start: {
          line: 108,
          column: 10
        },
        end: {
          line: 108,
          column: 116
        }
      },
      '44': {
        start: {
          line: 111,
          column: 10
        },
        end: {
          line: 111,
          column: 37
        }
      },
      '45': {
        start: {
          line: 112,
          column: 10
        },
        end: {
          line: 112,
          column: 113
        }
      },
      '46': {
        start: {
          line: 115,
          column: 10
        },
        end: {
          line: 115,
          column: 37
        }
      },
      '47': {
        start: {
          line: 116,
          column: 10
        },
        end: {
          line: 116,
          column: 114
        }
      },
      '48': {
        start: {
          line: 123,
          column: 6
        },
        end: {
          line: 123,
          column: 33
        }
      },
      '49': {
        start: {
          line: 124,
          column: 6
        },
        end: {
          line: 124,
          column: 77
        }
      },
      '50': {
        start: {
          line: 127,
          column: 16
        },
        end: {
          line: 127,
          column: 31
        }
      },
      '51': {
        start: {
          line: 128,
          column: 6
        },
        end: {
          line: 128,
          column: 72
        }
      },
      '52': {
        start: {
          line: 129,
          column: 6
        },
        end: {
          line: 129,
          column: 69
        }
      },
      '53': {
        start: {
          line: 130,
          column: 6
        },
        end: {
          line: 130,
          column: 52
        }
      },
      '54': {
        start: {
          line: 131,
          column: 6
        },
        end: {
          line: 131,
          column: 17
        }
      },
      '55': {
        start: {
          line: 134,
          column: 6
        },
        end: {
          line: 139,
          column: 9
        }
      },
      '56': {
        start: {
          line: 148,
          column: 21
        },
        end: {
          line: 148,
          column: 112
        }
      },
      '57': {
        start: {
          line: 150,
          column: 16
        },
        end: {
          line: 150,
          column: 31
        }
      },
      '58': {
        start: {
          line: 151,
          column: 6
        },
        end: {
          line: 151,
          column: 72
        }
      },
      '59': {
        start: {
          line: 152,
          column: 6
        },
        end: {
          line: 152,
          column: 52
        }
      },
      '60': {
        start: {
          line: 153,
          column: 6
        },
        end: {
          line: 153,
          column: 69
        }
      },
      '61': {
        start: {
          line: 154,
          column: 6
        },
        end: {
          line: 154,
          column: 24
        }
      },
      '62': {
        start: {
          line: 155,
          column: 6
        },
        end: {
          line: 155,
          column: 17
        }
      },
      '63': {
        start: {
          line: 159,
          column: 29
        },
        end: {
          line: 161,
          column: 8
        }
      },
      '64': {
        start: {
          line: 160,
          column: 8
        },
        end: {
          line: 160,
          column: 63
        }
      },
      '65': {
        start: {
          line: 163,
          column: 16
        },
        end: {
          line: 163,
          column: 31
        }
      },
      '66': {
        start: {
          line: 164,
          column: 6
        },
        end: {
          line: 164,
          column: 72
        }
      },
      '67': {
        start: {
          line: 165,
          column: 6
        },
        end: {
          line: 165,
          column: 27
        }
      },
      '68': {
        start: {
          line: 166,
          column: 6
        },
        end: {
          line: 166,
          column: 28
        }
      },
      '69': {
        start: {
          line: 167,
          column: 6
        },
        end: {
          line: 167,
          column: 69
        }
      },
      '70': {
        start: {
          line: 168,
          column: 6
        },
        end: {
          line: 168,
          column: 36
        }
      },
      '71': {
        start: {
          line: 169,
          column: 6
        },
        end: {
          line: 171,
          column: 9
        }
      },
      '72': {
        start: {
          line: 170,
          column: 8
        },
        end: {
          line: 170,
          column: 60
        }
      },
      '73': {
        start: {
          line: 172,
          column: 6
        },
        end: {
          line: 172,
          column: 17
        }
      },
      '74': {
        start: {
          line: 175,
          column: 16
        },
        end: {
          line: 175,
          column: 31
        }
      },
      '75': {
        start: {
          line: 176,
          column: 6
        },
        end: {
          line: 176,
          column: 72
        }
      },
      '76': {
        start: {
          line: 177,
          column: 6
        },
        end: {
          line: 177,
          column: 69
        }
      },
      '77': {
        start: {
          line: 178,
          column: 6
        },
        end: {
          line: 178,
          column: 52
        }
      },
      '78': {
        start: {
          line: 179,
          column: 6
        },
        end: {
          line: 179,
          column: 17
        }
      },
      '79': {
        start: {
          line: 183,
          column: 16
        },
        end: {
          line: 183,
          column: 31
        }
      },
      '80': {
        start: {
          line: 184,
          column: 6
        },
        end: {
          line: 184,
          column: 72
        }
      },
      '81': {
        start: {
          line: 185,
          column: 6
        },
        end: {
          line: 185,
          column: 69
        }
      },
      '82': {
        start: {
          line: 186,
          column: 6
        },
        end: {
          line: 186,
          column: 52
        }
      },
      '83': {
        start: {
          line: 187,
          column: 6
        },
        end: {
          line: 187,
          column: 17
        }
      },
      '84': {
        start: {
          line: 191,
          column: 16
        },
        end: {
          line: 191,
          column: 32
        }
      },
      '85': {
        start: {
          line: 192,
          column: 6
        },
        end: {
          line: 192,
          column: 69
        }
      },
      '86': {
        start: {
          line: 193,
          column: 6
        },
        end: {
          line: 193,
          column: 72
        }
      },
      '87': {
        start: {
          line: 194,
          column: 6
        },
        end: {
          line: 194,
          column: 52
        }
      },
      '88': {
        start: {
          line: 195,
          column: 6
        },
        end: {
          line: 195,
          column: 17
        }
      },
      '89': {
        start: {
          line: 198,
          column: 16
        },
        end: {
          line: 198,
          column: 32
        }
      },
      '90': {
        start: {
          line: 199,
          column: 6
        },
        end: {
          line: 199,
          column: 69
        }
      },
      '91': {
        start: {
          line: 200,
          column: 6
        },
        end: {
          line: 200,
          column: 72
        }
      },
      '92': {
        start: {
          line: 201,
          column: 6
        },
        end: {
          line: 201,
          column: 27
        }
      },
      '93': {
        start: {
          line: 202,
          column: 6
        },
        end: {
          line: 202,
          column: 17
        }
      },
      '94': {
        start: {
          line: 205,
          column: 16
        },
        end: {
          line: 205,
          column: 32
        }
      },
      '95': {
        start: {
          line: 206,
          column: 6
        },
        end: {
          line: 206,
          column: 69
        }
      },
      '96': {
        start: {
          line: 207,
          column: 6
        },
        end: {
          line: 207,
          column: 72
        }
      },
      '97': {
        start: {
          line: 208,
          column: 6
        },
        end: {
          line: 208,
          column: 27
        }
      },
      '98': {
        start: {
          line: 209,
          column: 6
        },
        end: {
          line: 209,
          column: 17
        }
      },
      '99': {
        start: {
          line: 212,
          column: 6
        },
        end: {
          line: 212,
          column: 82
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 71
          },
          end: {
            line: 1,
            column: 72
          }
        },
        loc: {
          start: {
            line: 1,
            column: 97
          },
          end: {
            line: 216,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 32,
            column: 24
          },
          end: {
            line: 32,
            column: 25
          }
        },
        loc: {
          start: {
            line: 32,
            column: 32
          },
          end: {
            line: 36,
            column: 3
          }
        },
        line: 32
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 33,
            column: 36
          },
          end: {
            line: 33,
            column: 37
          }
        },
        loc: {
          start: {
            line: 33,
            column: 45
          },
          end: {
            line: 35,
            column: 5
          }
        },
        line: 33
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 87,
            column: 31
          },
          end: {
            line: 87,
            column: 32
          }
        },
        loc: {
          start: {
            line: 87,
            column: 43
          },
          end: {
            line: 89,
            column: 13
          }
        },
        line: 87
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 159,
            column: 44
          },
          end: {
            line: 159,
            column: 45
          }
        },
        loc: {
          start: {
            line: 159,
            column: 54
          },
          end: {
            line: 161,
            column: 7
          }
        },
        line: 159
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 169,
            column: 37
          },
          end: {
            line: 169,
            column: 38
          }
        },
        loc: {
          start: {
            line: 169,
            column: 47
          },
          end: {
            line: 171,
            column: 7
          }
        },
        line: 169
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 39,
            column: 16
          },
          end: {
            line: 39,
            column: 34
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 39,
            column: 32
          },
          end: {
            line: 39,
            column: 34
          }
        }],
        line: 39
      },
      '1': {
        loc: {
          start: {
            line: 39,
            column: 36
          },
          end: {
            line: 39,
            column: 48
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 39,
            column: 46
          },
          end: {
            line: 39,
            column: 48
          }
        }],
        line: 39
      },
      '2': {
        loc: {
          start: {
            line: 39,
            column: 50
          },
          end: {
            line: 39,
            column: 65
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 39,
            column: 63
          },
          end: {
            line: 39,
            column: 65
          }
        }],
        line: 39
      },
      '3': {
        loc: {
          start: {
            line: 40,
            column: 6
          },
          end: {
            line: 42,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 40,
            column: 6
          },
          end: {
            line: 42,
            column: 7
          }
        }, {
          start: {
            line: 40,
            column: 6
          },
          end: {
            line: 42,
            column: 7
          }
        }],
        line: 40
      },
      '4': {
        loc: {
          start: {
            line: 46,
            column: 6
          },
          end: {
            line: 120,
            column: 7
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 47,
            column: 8
          },
          end: {
            line: 47,
            column: 23
          }
        }, {
          start: {
            line: 47,
            column: 24
          },
          end: {
            line: 47,
            column: 41
          }
        }, {
          start: {
            line: 47,
            column: 42
          },
          end: {
            line: 47,
            column: 57
          }
        }, {
          start: {
            line: 47,
            column: 58
          },
          end: {
            line: 49,
            column: 115
          }
        }, {
          start: {
            line: 51,
            column: 8
          },
          end: {
            line: 53,
            column: 114
          }
        }, {
          start: {
            line: 55,
            column: 8
          },
          end: {
            line: 55,
            column: 24
          }
        }, {
          start: {
            line: 55,
            column: 25
          },
          end: {
            line: 57,
            column: 113
          }
        }, {
          start: {
            line: 59,
            column: 8
          },
          end: {
            line: 59,
            column: 20
          }
        }, {
          start: {
            line: 59,
            column: 21
          },
          end: {
            line: 59,
            column: 36
          }
        }, {
          start: {
            line: 59,
            column: 37
          },
          end: {
            line: 59,
            column: 51
          }
        }, {
          start: {
            line: 59,
            column: 52
          },
          end: {
            line: 61,
            column: 113
          }
        }, {
          start: {
            line: 62,
            column: 8
          },
          end: {
            line: 62,
            column: 21
          }
        }, {
          start: {
            line: 62,
            column: 22
          },
          end: {
            line: 64,
            column: 81
          }
        }, {
          start: {
            line: 66,
            column: 8
          },
          end: {
            line: 66,
            column: 31
          }
        }, {
          start: {
            line: 66,
            column: 32
          },
          end: {
            line: 68,
            column: 156
          }
        }, {
          start: {
            line: 70,
            column: 8
          },
          end: {
            line: 70,
            column: 20
          }
        }, {
          start: {
            line: 70,
            column: 21
          },
          end: {
            line: 70,
            column: 33
          }
        }, {
          start: {
            line: 70,
            column: 34
          },
          end: {
            line: 70,
            column: 51
          }
        }, {
          start: {
            line: 70,
            column: 52
          },
          end: {
            line: 73,
            column: 113
          }
        }, {
          start: {
            line: 75,
            column: 8
          },
          end: {
            line: 78,
            column: 81
          }
        }, {
          start: {
            line: 80,
            column: 8
          },
          end: {
            line: 83,
            column: 81
          }
        }, {
          start: {
            line: 85,
            column: 8
          },
          end: {
            line: 104,
            column: 89
          }
        }, {
          start: {
            line: 106,
            column: 8
          },
          end: {
            line: 108,
            column: 116
          }
        }, {
          start: {
            line: 110,
            column: 8
          },
          end: {
            line: 110,
            column: 21
          }
        }, {
          start: {
            line: 110,
            column: 22
          },
          end: {
            line: 112,
            column: 113
          }
        }, {
          start: {
            line: 114,
            column: 8
          },
          end: {
            line: 116,
            column: 114
          }
        }, {
          start: {
            line: 118,
            column: 8
          },
          end: {
            line: 118,
            column: 16
          }
        }],
        line: 46
      },
      '5': {
        loc: {
          start: {
            line: 95,
            column: 12
          },
          end: {
            line: 97,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 95,
            column: 12
          },
          end: {
            line: 97,
            column: 13
          }
        }, {
          start: {
            line: 95,
            column: 12
          },
          end: {
            line: 97,
            column: 13
          }
        }],
        line: 95
      },
      '6': {
        loc: {
          start: {
            line: 98,
            column: 12
          },
          end: {
            line: 100,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 98,
            column: 12
          },
          end: {
            line: 100,
            column: 13
          }
        }, {
          start: {
            line: 98,
            column: 12
          },
          end: {
            line: 100,
            column: 13
          }
        }],
        line: 98
      },
      '7': {
        loc: {
          start: {
            line: 126,
            column: 24
          },
          end: {
            line: 126,
            column: 39
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 126,
            column: 37
          },
          end: {
            line: 126,
            column: 39
          }
        }],
        line: 126
      },
      '8': {
        loc: {
          start: {
            line: 130,
            column: 13
          },
          end: {
            line: 130,
            column: 51
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 130,
            column: 26
          },
          end: {
            line: 130,
            column: 39
          }
        }, {
          start: {
            line: 130,
            column: 42
          },
          end: {
            line: 130,
            column: 51
          }
        }],
        line: 130
      },
      '9': {
        loc: {
          start: {
            line: 133,
            column: 22
          },
          end: {
            line: 133,
            column: 37
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 133,
            column: 35
          },
          end: {
            line: 133,
            column: 37
          }
        }],
        line: 133
      },
      '10': {
        loc: {
          start: {
            line: 136,
            column: 12
          },
          end: {
            line: 136,
            column: 50
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 136,
            column: 25
          },
          end: {
            line: 136,
            column: 38
          }
        }, {
          start: {
            line: 136,
            column: 41
          },
          end: {
            line: 136,
            column: 50
          }
        }],
        line: 136
      },
      '11': {
        loc: {
          start: {
            line: 141,
            column: 22
          },
          end: {
            line: 141,
            column: 37
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 141,
            column: 35
          },
          end: {
            line: 141,
            column: 37
          }
        }],
        line: 141
      },
      '12': {
        loc: {
          start: {
            line: 144,
            column: 22
          },
          end: {
            line: 144,
            column: 37
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 144,
            column: 35
          },
          end: {
            line: 144,
            column: 37
          }
        }],
        line: 144
      },
      '13': {
        loc: {
          start: {
            line: 147,
            column: 22
          },
          end: {
            line: 147,
            column: 37
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 147,
            column: 35
          },
          end: {
            line: 147,
            column: 37
          }
        }],
        line: 147
      },
      '14': {
        loc: {
          start: {
            line: 148,
            column: 21
          },
          end: {
            line: 148,
            column: 112
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 148,
            column: 43
          },
          end: {
            line: 148,
            column: 54
          }
        }, {
          start: {
            line: 148,
            column: 58
          },
          end: {
            line: 148,
            column: 111
          }
        }],
        line: 148
      },
      '15': {
        loc: {
          start: {
            line: 148,
            column: 58
          },
          end: {
            line: 148,
            column: 111
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 148,
            column: 80
          },
          end: {
            line: 148,
            column: 92
          }
        }, {
          start: {
            line: 148,
            column: 95
          },
          end: {
            line: 148,
            column: 111
          }
        }],
        line: 148
      },
      '16': {
        loc: {
          start: {
            line: 152,
            column: 13
          },
          end: {
            line: 152,
            column: 51
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 152,
            column: 26
          },
          end: {
            line: 152,
            column: 39
          }
        }, {
          start: {
            line: 152,
            column: 42
          },
          end: {
            line: 152,
            column: 51
          }
        }],
        line: 152
      },
      '17': {
        loc: {
          start: {
            line: 158,
            column: 33
          },
          end: {
            line: 158,
            column: 51
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 158,
            column: 47
          },
          end: {
            line: 158,
            column: 51
          }
        }],
        line: 158
      },
      '18': {
        loc: {
          start: {
            line: 158,
            column: 53
          },
          end: {
            line: 158,
            column: 68
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 158,
            column: 66
          },
          end: {
            line: 158,
            column: 68
          }
        }],
        line: 158
      },
      '19': {
        loc: {
          start: {
            line: 170,
            column: 15
          },
          end: {
            line: 170,
            column: 59
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 170,
            column: 30
          },
          end: {
            line: 170,
            column: 45
          }
        }, {
          start: {
            line: 170,
            column: 48
          },
          end: {
            line: 170,
            column: 59
          }
        }],
        line: 170
      },
      '20': {
        loc: {
          start: {
            line: 174,
            column: 25
          },
          end: {
            line: 174,
            column: 40
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 174,
            column: 38
          },
          end: {
            line: 174,
            column: 40
          }
        }],
        line: 174
      },
      '21': {
        loc: {
          start: {
            line: 178,
            column: 13
          },
          end: {
            line: 178,
            column: 51
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 178,
            column: 26
          },
          end: {
            line: 178,
            column: 39
          }
        }, {
          start: {
            line: 178,
            column: 42
          },
          end: {
            line: 178,
            column: 51
          }
        }],
        line: 178
      },
      '22': {
        loc: {
          start: {
            line: 182,
            column: 22
          },
          end: {
            line: 182,
            column: 37
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 182,
            column: 35
          },
          end: {
            line: 182,
            column: 37
          }
        }],
        line: 182
      },
      '23': {
        loc: {
          start: {
            line: 186,
            column: 13
          },
          end: {
            line: 186,
            column: 51
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 186,
            column: 26
          },
          end: {
            line: 186,
            column: 39
          }
        }, {
          start: {
            line: 186,
            column: 42
          },
          end: {
            line: 186,
            column: 51
          }
        }],
        line: 186
      },
      '24': {
        loc: {
          start: {
            line: 190,
            column: 23
          },
          end: {
            line: 190,
            column: 38
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 190,
            column: 36
          },
          end: {
            line: 190,
            column: 38
          }
        }],
        line: 190
      },
      '25': {
        loc: {
          start: {
            line: 194,
            column: 13
          },
          end: {
            line: 194,
            column: 51
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 194,
            column: 26
          },
          end: {
            line: 194,
            column: 39
          }
        }, {
          start: {
            line: 194,
            column: 42
          },
          end: {
            line: 194,
            column: 51
          }
        }],
        line: 194
      },
      '26': {
        loc: {
          start: {
            line: 197,
            column: 23
          },
          end: {
            line: 197,
            column: 38
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 197,
            column: 36
          },
          end: {
            line: 197,
            column: 38
          }
        }],
        line: 197
      },
      '27': {
        loc: {
          start: {
            line: 204,
            column: 22
          },
          end: {
            line: 204,
            column: 37
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 204,
            column: 35
          },
          end: {
            line: 204,
            column: 37
          }
        }],
        line: 204
      },
      '28': {
        loc: {
          start: {
            line: 212,
            column: 37
          },
          end: {
            line: 212,
            column: 69
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 212,
            column: 61
          },
          end: {
            line: 212,
            column: 64
          }
        }, {
          start: {
            line: 212,
            column: 67
          },
          end: {
            line: 212,
            column: 69
          }
        }],
        line: 212
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0],
      '1': [0],
      '2': [0],
      '3': [0, 0],
      '4': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0],
      '8': [0, 0],
      '9': [0],
      '10': [0, 0],
      '11': [0],
      '12': [0],
      '13': [0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0],
      '18': [0],
      '19': [0, 0],
      '20': [0],
      '21': [0, 0],
      '22': [0],
      '23': [0, 0],
      '24': [0],
      '25': [0, 0],
      '26': [0],
      '27': [0],
      '28': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1d2zlpfn8d.s[0]++;
angular.module('koboModule', []).factory('$kobo', ['$q', '$translate', function ($q, $translate) {
  cov_1d2zlpfn8d.f[0]++;

  var fields = (cov_1d2zlpfn8d.s[1]++, [{
    icon: 'text-width', label: $translate.instant('FORMULAR.FIELD.TEXT'), type: 1, format: 125, required: false, cols: true
  }, {
    icon: 'list-ol', label: $translate.instant('FORMULAR.FIELD.LIST'), type: 2, type_option: 3, optionp: 'unites', options: [], required: false, cols: true
  }, {
    icon: 'calendar', label: $translate.instant('FORMULAR.FIELD.DATE'), type: 3, format: 'mediumDate', required: false, cols: true
  }, {
    icon: 'calculator', label: $translate.instant('FORMULAR.FIELD.NUMBER'), type: 4, format: 2, required: false, cols: true
  }, {
    icon: 'dot-circle-o', label: $translate.instant('FORMULAR.FIELD.CHOISE'), type: 6, options: [], multi: false, required: false, cols: true
  }, {
    icon: 'star-o', label: $translate.instant('FORMULAR.FIELD.SCORE'), type: 5, options: [], multi: false, required: false, cols: true
  }, /* {
      icon: 'link', label: 'URL', type: 20, required: false, cols: true
     }, */{
    icon: 'file', label: $translate.instant('FORMULAR.FIELD.FILE'), type: 7
  }, {
    icon: 'image', label: $translate.instant('FORMULAR.FIELD.PICTURE'), type: 8, cols: true
  }, /* {
      icon: 'movie', label: $translate.instant('FORMULAR.FIELD.VIDEO'), type: 9, cols: true
     }, */{
    icon: 'microphone', label: $translate.instant('FORMULAR.FIELD.AUDIO'), type: 10, cols: true
  }, {
    icon: 'map-marker', label: $translate.instant('FORMULAR.FIELD.LOCATION'), type: 11, cols: true
  }, {
    icon: 'code-fork', label: $translate.instant('FORMULAR.FIELD.PATH'), type: 13, cols: true
  }]);

  cov_1d2zlpfn8d.s[2]++;
  var findInFields = function findInFields(type) {
    cov_1d2zlpfn8d.f[1]++;
    cov_1d2zlpfn8d.s[3]++;

    return angular.copy(fields.find(function (value) {
      cov_1d2zlpfn8d.f[2]++;
      cov_1d2zlpfn8d.s[4]++;

      return value.type == type;
    }));
  };

  cov_1d2zlpfn8d.s[5]++;
  return {
    processForm: function processForm() {
      var formStructure = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (cov_1d2zlpfn8d.b[0][0]++, []);
      var choises = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_1d2zlpfn8d.b[1][0]++, []);
      var prefixedId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : (cov_1d2zlpfn8d.b[2][0]++, []);
      cov_1d2zlpfn8d.s[6]++;

      if (formStructure.length == 0) {
        cov_1d2zlpfn8d.b[3][0]++;
        cov_1d2zlpfn8d.s[7]++;

        return [];
      } else {
        cov_1d2zlpfn8d.b[3][1]++;
      }

      var item = (cov_1d2zlpfn8d.s[8]++, formStructure[0]);

      cov_1d2zlpfn8d.s[9]++;
      switch (item.type) {
        case 'decimal':
          cov_1d2zlpfn8d.b[4][0]++;
        case 'calculate':
          cov_1d2zlpfn8d.b[4][1]++;
        case 'integer':
          cov_1d2zlpfn8d.b[4][2]++;
        case 'range':
          cov_1d2zlpfn8d.b[4][3]++;
          cov_1d2zlpfn8d.s[10]++;

          formStructure.splice(0, 1);
          cov_1d2zlpfn8d.s[11]++;
          return this.processNumber(item, prefixedId).concat(this.processForm(formStructure, choises, prefixedId));

        case 'geopoint':
          cov_1d2zlpfn8d.b[4][4]++;
          cov_1d2zlpfn8d.s[12]++;

          formStructure.splice(0, 1);
          cov_1d2zlpfn8d.s[13]++;
          return this.processPoint(item, prefixedId).concat(this.processForm(formStructure, choises, prefixedId));

        case 'geotrace':
          cov_1d2zlpfn8d.b[4][5]++;
        case 'geoshape':
          cov_1d2zlpfn8d.b[4][6]++;
          cov_1d2zlpfn8d.s[14]++;

          formStructure.splice(0, 1);
          cov_1d2zlpfn8d.s[15]++;
          return this.processPath(item, prefixedId).concat(this.processForm(formStructure, choises, prefixedId));

        case 'text':
          cov_1d2zlpfn8d.b[4][7]++;
        case 'barcode':
          cov_1d2zlpfn8d.b[4][8]++;
        case 'hidden':
          cov_1d2zlpfn8d.b[4][9]++;
        case 'phonenumber':
          cov_1d2zlpfn8d.b[4][10]++;
          cov_1d2zlpfn8d.s[16]++;

          formStructure.splice(0, 1);
          cov_1d2zlpfn8d.s[17]++;
          return this.processText(item, prefixedId).concat(this.processForm(formStructure, choises, prefixedId));
        case 'start':
          cov_1d2zlpfn8d.b[4][11]++;
        case 'end':
          cov_1d2zlpfn8d.b[4][12]++;
          cov_1d2zlpfn8d.s[18]++;

          formStructure.splice(0, 1);
          cov_1d2zlpfn8d.s[19]++;
          return [].concat(this.processForm(formStructure, choises, prefixedId));

        case 'select_multiple':
          cov_1d2zlpfn8d.b[4][13]++;
        case 'select_one':
          cov_1d2zlpfn8d.b[4][14]++;
          cov_1d2zlpfn8d.s[20]++;

          formStructure.splice(0, 1);
          cov_1d2zlpfn8d.s[21]++;
          return this.processChoise(item, choises, item.type == 'select_multiple', prefixedId).concat(this.processForm(formStructure, choises, prefixedId));

        case 'date':
          cov_1d2zlpfn8d.b[4][15]++;
        case 'time':
          cov_1d2zlpfn8d.b[4][16]++;
        case 'datetime':
          cov_1d2zlpfn8d.b[4][17]++;
        case 'today':
          cov_1d2zlpfn8d.b[4][18]++;
          cov_1d2zlpfn8d.s[22]++;


          formStructure.splice(0, 1);
          cov_1d2zlpfn8d.s[23]++;
          return this.processDate(item, prefixedId).concat(this.processForm(formStructure, choises, prefixedId));

        case 'begin_group':
          cov_1d2zlpfn8d.b[4][19]++;
          cov_1d2zlpfn8d.s[24]++;

          formStructure.splice(0, 1);
          cov_1d2zlpfn8d.s[25]++;
          prefixedId.push(item.$autoname);
          cov_1d2zlpfn8d.s[26]++;
          return [].concat(this.processForm(formStructure, choises, prefixedId));

        case 'end_group':
          cov_1d2zlpfn8d.b[4][20]++;
          cov_1d2zlpfn8d.s[27]++;

          formStructure.splice(0, 1);
          cov_1d2zlpfn8d.s[28]++;
          prefixedId.pop();
          cov_1d2zlpfn8d.s[29]++;
          return [].concat(this.processForm(formStructure, choises, prefixedId));

        case 'begin_score':
          cov_1d2zlpfn8d.b[4][21]++;

          var endItem = (cov_1d2zlpfn8d.s[30]++, formStructure.indexOf(formStructure.find(function (endScore) {
            cov_1d2zlpfn8d.f[3]++;
            cov_1d2zlpfn8d.s[31]++;

            return endScore.type === 'end_score';
          })));
          var index = (cov_1d2zlpfn8d.s[32]++, 0);
          var res = (cov_1d2zlpfn8d.s[33]++, []);
          cov_1d2zlpfn8d.s[34]++;
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = formStructure[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var score = _step.value;
              cov_1d2zlpfn8d.s[35]++;

              index++;
              cov_1d2zlpfn8d.s[36]++;
              if (score.type === 'end_score') {
                cov_1d2zlpfn8d.b[5][0]++;
                cov_1d2zlpfn8d.s[37]++;

                break;
              } else {
                cov_1d2zlpfn8d.b[5][1]++;
              }
              cov_1d2zlpfn8d.s[38]++;
              if (score.type === 'begin_score') {
                cov_1d2zlpfn8d.b[6][0]++;
                cov_1d2zlpfn8d.s[39]++;

                continue;
              } else {
                cov_1d2zlpfn8d.b[6][1]++;
              }
              // res.concat();
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          cov_1d2zlpfn8d.s[40]++;
          formStructure.splice(0, index);
          cov_1d2zlpfn8d.s[41]++;
          return this.processDate(item).concat(this.processForm(formStructure, choises));

        case 'image':
          cov_1d2zlpfn8d.b[4][22]++;
          cov_1d2zlpfn8d.s[42]++;

          formStructure.splice(0, 1);
          cov_1d2zlpfn8d.s[43]++;
          return this.processPicture(item, prefixedId).concat(this.processForm(formStructure, choises, prefixedId));

        case 'video':
          cov_1d2zlpfn8d.b[4][23]++;
        case 'file':
          cov_1d2zlpfn8d.b[4][24]++;
          cov_1d2zlpfn8d.s[44]++;

          formStructure.splice(0, 1);
          cov_1d2zlpfn8d.s[45]++;
          return this.processFile(item, prefixedId).concat(this.processForm(formStructure, choises, prefixedId));

        case 'audio':
          cov_1d2zlpfn8d.b[4][25]++;
          cov_1d2zlpfn8d.s[46]++;

          formStructure.splice(0, 1);
          cov_1d2zlpfn8d.s[47]++;
          return this.processAudio(item, prefixedId).concat(this.processForm(formStructure, choises, prefixedId));

        default:
          cov_1d2zlpfn8d.b[4][26]++;

        // return [].concat(this.processForm(formStructure.splice(0, 1)));
      }

      cov_1d2zlpfn8d.s[48]++;
      formStructure.splice(0, 1);
      cov_1d2zlpfn8d.s[49]++;
      return [].concat(this.processForm(formStructure, choises, prefixedId));
    },
    processNumber: function processNumber(item) {
      var prefixedId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_1d2zlpfn8d.b[7][0]++, []);

      var _ = (cov_1d2zlpfn8d.s[50]++, findInFields(4));
      cov_1d2zlpfn8d.s[51]++;
      _.id = item.$kuid + Math.floor(Math.random() * 100000).toString();
      cov_1d2zlpfn8d.s[52]++;
      _.autoname = this._processAutoName(item.$autoname, prefixedId);
      cov_1d2zlpfn8d.s[53]++;
      _.dl = item.label ? (cov_1d2zlpfn8d.b[8][0]++, item.label[0]) : (cov_1d2zlpfn8d.b[8][1]++, item.name);
      cov_1d2zlpfn8d.s[54]++;
      return [_];
    },
    processText: function processText(item) {
      var prefixedId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_1d2zlpfn8d.b[9][0]++, []);
      cov_1d2zlpfn8d.s[55]++;

      return [{
        icon: 'text-width', label: $translate.instant('FORMULAR.FIELD.TEXT'), type: 1, format: 125,
        dl: item.label ? (cov_1d2zlpfn8d.b[10][0]++, item.label[0]) : (cov_1d2zlpfn8d.b[10][1]++, item.name),
        required: false, cols: true, id: item.$kuid + Math.floor(Math.random() * 100000).toString(),
        autoname: this._processAutoName(item.$autoname, prefixedId)
      }];
    },
    processGrid: function processGrid(item) {
      var prefixedId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_1d2zlpfn8d.b[11][0]++, []);
    },
    processList: function processList(item) {
      var prefixedId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_1d2zlpfn8d.b[12][0]++, []);
    },
    processDate: function processDate(item) {
      var prefixedId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_1d2zlpfn8d.b[13][0]++, []);

      var format = (cov_1d2zlpfn8d.s[56]++, item.type == 'time' ? (cov_1d2zlpfn8d.b[14][0]++, 'shortTime') : (cov_1d2zlpfn8d.b[14][1]++, item.type == 'date' ? (cov_1d2zlpfn8d.b[15][0]++, 'mediumDate') : (cov_1d2zlpfn8d.b[15][1]++, 'mediumDateTime')));

      var _ = (cov_1d2zlpfn8d.s[57]++, findInFields(3));
      cov_1d2zlpfn8d.s[58]++;
      _.id = item.$kuid + Math.floor(Math.random() * 100000).toString();
      cov_1d2zlpfn8d.s[59]++;
      _.dl = item.label ? (cov_1d2zlpfn8d.b[16][0]++, item.label[0]) : (cov_1d2zlpfn8d.b[16][1]++, item.name);
      cov_1d2zlpfn8d.s[60]++;
      _.autoname = this._processAutoName(item.$autoname, prefixedId);
      cov_1d2zlpfn8d.s[61]++;
      _.format = format;
      cov_1d2zlpfn8d.s[62]++;
      return [_];
      // date
    },
    processChoise: function processChoise(item, choices) {
      var multiSelect = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : (cov_1d2zlpfn8d.b[17][0]++, true);
      var prefixedId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : (cov_1d2zlpfn8d.b[18][0]++, []);

      var selectedChoise = (cov_1d2zlpfn8d.s[63]++, choices.filter(function (choice) {
        cov_1d2zlpfn8d.f[4]++;
        cov_1d2zlpfn8d.s[64]++;

        return choice.list_name === item.select_from_list_name;
      }));

      var _ = (cov_1d2zlpfn8d.s[65]++, findInFields(6));
      cov_1d2zlpfn8d.s[66]++;
      _.id = item.$kuid + Math.floor(Math.random() * 100000).toString();
      cov_1d2zlpfn8d.s[67]++;
      _.dl = item.label[0];
      cov_1d2zlpfn8d.s[68]++;
      _.multi = multiSelect;
      cov_1d2zlpfn8d.s[69]++;
      _.autoname = this._processAutoName(item.$autoname, prefixedId);
      cov_1d2zlpfn8d.s[70]++;
      _.autovalue = item.$autovalue;
      cov_1d2zlpfn8d.s[71]++;
      _.options = selectedChoise.map(function (option) {
        cov_1d2zlpfn8d.f[5]++;
        cov_1d2zlpfn8d.s[72]++;

        return option.label ? (cov_1d2zlpfn8d.b[19][0]++, option.label[0]) : (cov_1d2zlpfn8d.b[19][1]++, option.name);
      });
      cov_1d2zlpfn8d.s[73]++;
      return [_];
    },
    processPicture: function processPicture(item) {
      var prefixedId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_1d2zlpfn8d.b[20][0]++, []);

      var _ = (cov_1d2zlpfn8d.s[74]++, findInFields(8));
      cov_1d2zlpfn8d.s[75]++;
      _.id = item.$kuid + Math.floor(Math.random() * 100000).toString();
      cov_1d2zlpfn8d.s[76]++;
      _.autoname = this._processAutoName(item.$autoname, prefixedId);
      cov_1d2zlpfn8d.s[77]++;
      _.dl = item.label ? (cov_1d2zlpfn8d.b[21][0]++, item.label[0]) : (cov_1d2zlpfn8d.b[21][1]++, item.name);
      cov_1d2zlpfn8d.s[78]++;
      return [_];
    },
    processFile: function processFile(item) {
      var prefixedId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_1d2zlpfn8d.b[22][0]++, []);

      var _ = (cov_1d2zlpfn8d.s[79]++, findInFields(7));
      cov_1d2zlpfn8d.s[80]++;
      _.id = item.$kuid + Math.floor(Math.random() * 100000).toString();
      cov_1d2zlpfn8d.s[81]++;
      _.autoname = this._processAutoName(item.$autoname, prefixedId);
      cov_1d2zlpfn8d.s[82]++;
      _.dl = item.label ? (cov_1d2zlpfn8d.b[23][0]++, item.label[0]) : (cov_1d2zlpfn8d.b[23][1]++, item.name);
      cov_1d2zlpfn8d.s[83]++;
      return [_];
    },
    processAudio: function processAudio(item) {
      var prefixedId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_1d2zlpfn8d.b[24][0]++, []);

      var _ = (cov_1d2zlpfn8d.s[84]++, findInFields(10));
      cov_1d2zlpfn8d.s[85]++;
      _.autoname = this._processAutoName(item.$autoname, prefixedId);
      cov_1d2zlpfn8d.s[86]++;
      _.id = item.$kuid + Math.floor(Math.random() * 100000).toString();
      cov_1d2zlpfn8d.s[87]++;
      _.dl = item.label ? (cov_1d2zlpfn8d.b[25][0]++, item.label[0]) : (cov_1d2zlpfn8d.b[25][1]++, item.name);
      cov_1d2zlpfn8d.s[88]++;
      return [_];
    },
    processPoint: function processPoint(item) {
      var prefixedId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_1d2zlpfn8d.b[26][0]++, []);

      var _ = (cov_1d2zlpfn8d.s[89]++, findInFields(11));
      cov_1d2zlpfn8d.s[90]++;
      _.autoname = this._processAutoName(item.$autoname, prefixedId);
      cov_1d2zlpfn8d.s[91]++;
      _.id = item.$kuid + Math.floor(Math.random() * 100000).toString();
      cov_1d2zlpfn8d.s[92]++;
      _.dl = item.label[0];
      cov_1d2zlpfn8d.s[93]++;
      return [_];
    },
    processPath: function processPath(item) {
      var prefixedId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_1d2zlpfn8d.b[27][0]++, []);

      var _ = (cov_1d2zlpfn8d.s[94]++, findInFields(13));
      cov_1d2zlpfn8d.s[95]++;
      _.autoname = this._processAutoName(item.$autoname, prefixedId);
      cov_1d2zlpfn8d.s[96]++;
      _.id = item.$kuid + Math.floor(Math.random() * 100000).toString();
      cov_1d2zlpfn8d.s[97]++;
      _.dl = item.label[0];
      cov_1d2zlpfn8d.s[98]++;
      return [_];
    },
    _processAutoName: function _processAutoName(autoName, prefixedId) {
      cov_1d2zlpfn8d.s[99]++;

      return prefixedId.join('/') + (prefixedId.length > 0 ? (cov_1d2zlpfn8d.b[28][0]++, '/') : (cov_1d2zlpfn8d.b[28][1]++, '')) + autoName;
    }
  };
}]);
'use strict';

var cov_2ln58896qt = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/login/loginctrl.js',
      hash = 'b51edebffbd15927efe65327c4d4c41392d75034',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/login/loginctrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 112,
          column: 7
        }
      },
      '1': {
        start: {
          line: 4,
          column: 6
        },
        end: {
          line: 4,
          column: 26
        }
      },
      '2': {
        start: {
          line: 5,
          column: 6
        },
        end: {
          line: 5,
          column: 29
        }
      },
      '3': {
        start: {
          line: 6,
          column: 6
        },
        end: {
          line: 6,
          column: 27
        }
      },
      '4': {
        start: {
          line: 8,
          column: 6
        },
        end: {
          line: 8,
          column: 23
        }
      },
      '5': {
        start: {
          line: 9,
          column: 6
        },
        end: {
          line: 15,
          column: 8
        }
      },
      '6': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 38,
          column: 8
        }
      },
      '7': {
        start: {
          line: 17,
          column: 20
        },
        end: {
          line: 17,
          column: 21
        }
      },
      '8': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 23,
          column: 9
        }
      },
      '9': {
        start: {
          line: 19,
          column: 10
        },
        end: {
          line: 22,
          column: 11
        }
      },
      '10': {
        start: {
          line: 20,
          column: 12
        },
        end: {
          line: 20,
          column: 22
        }
      },
      '11': {
        start: {
          line: 21,
          column: 12
        },
        end: {
          line: 21,
          column: 18
        }
      },
      '12': {
        start: {
          line: 24,
          column: 8
        },
        end: {
          line: 24,
          column: 50
        }
      },
      '13': {
        start: {
          line: 25,
          column: 8
        },
        end: {
          line: 25,
          column: 46
        }
      },
      '14': {
        start: {
          line: 26,
          column: 8
        },
        end: {
          line: 26,
          column: 47
        }
      },
      '15': {
        start: {
          line: 27,
          column: 8
        },
        end: {
          line: 27,
          column: 46
        }
      },
      '16': {
        start: {
          line: 28,
          column: 8
        },
        end: {
          line: 28,
          column: 46
        }
      },
      '17': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 29,
          column: 57
        }
      },
      '18': {
        start: {
          line: 30,
          column: 8
        },
        end: {
          line: 30,
          column: 53
        }
      },
      '19': {
        start: {
          line: 31,
          column: 8
        },
        end: {
          line: 31,
          column: 46
        }
      },
      '20': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 32,
          column: 42
        }
      },
      '21': {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 37,
          column: 52
        }
      },
      '22': {
        start: {
          line: 40,
          column: 25
        },
        end: {
          line: 40,
          column: 54
        }
      },
      '23': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 44,
          column: 7
        }
      },
      '24': {
        start: {
          line: 42,
          column: 22
        },
        end: {
          line: 42,
          column: 50
        }
      },
      '25': {
        start: {
          line: 43,
          column: 8
        },
        end: {
          line: 43,
          column: 73
        }
      },
      '26': {
        start: {
          line: 46,
          column: 6
        },
        end: {
          line: 109,
          column: 8
        }
      },
      '27': {
        start: {
          line: 47,
          column: 28
        },
        end: {
          line: 47,
          column: 67
        }
      },
      '28': {
        start: {
          line: 48,
          column: 8
        },
        end: {
          line: 51,
          column: 9
        }
      },
      '29': {
        start: {
          line: 49,
          column: 30
        },
        end: {
          line: 49,
          column: 59
        }
      },
      '30': {
        start: {
          line: 50,
          column: 10
        },
        end: {
          line: 50,
          column: 93
        }
      },
      '31': {
        start: {
          line: 53,
          column: 8
        },
        end: {
          line: 108,
          column: 11
        }
      },
      '32': {
        start: {
          line: 54,
          column: 10
        },
        end: {
          line: 106,
          column: 11
        }
      },
      '33': {
        start: {
          line: 55,
          column: 12
        },
        end: {
          line: 57,
          column: 13
        }
      },
      '34': {
        start: {
          line: 56,
          column: 14
        },
        end: {
          line: 56,
          column: 46
        }
      },
      '35': {
        start: {
          line: 58,
          column: 43
        },
        end: {
          line: 58,
          column: 50
        }
      },
      '36': {
        start: {
          line: 59,
          column: 12
        },
        end: {
          line: 59,
          column: 48
        }
      },
      '37': {
        start: {
          line: 61,
          column: 27
        },
        end: {
          line: 61,
          column: 97
        }
      },
      '38': {
        start: {
          line: 62,
          column: 12
        },
        end: {
          line: 65,
          column: 15
        }
      },
      '39': {
        start: {
          line: 63,
          column: 14
        },
        end: {
          line: 63,
          column: 79
        }
      },
      '40': {
        start: {
          line: 64,
          column: 14
        },
        end: {
          line: 64,
          column: 77
        }
      },
      '41': {
        start: {
          line: 67,
          column: 12
        },
        end: {
          line: 78,
          column: 15
        }
      },
      '42': {
        start: {
          line: 80,
          column: 12
        },
        end: {
          line: 80,
          column: 41
        }
      },
      '43': {
        start: {
          line: 81,
          column: 12
        },
        end: {
          line: 81,
          column: 43
        }
      },
      '44': {
        start: {
          line: 82,
          column: 12
        },
        end: {
          line: 82,
          column: 40
        }
      },
      '45': {
        start: {
          line: 84,
          column: 12
        },
        end: {
          line: 98,
          column: 13
        }
      },
      '46': {
        start: {
          line: 85,
          column: 25
        },
        end: {
          line: 85,
          column: 43
        }
      },
      '47': {
        start: {
          line: 87,
          column: 34
        },
        end: {
          line: 87,
          column: 55
        }
      },
      '48': {
        start: {
          line: 88,
          column: 33
        },
        end: {
          line: 88,
          column: 43
        }
      },
      '49': {
        start: {
          line: 89,
          column: 27
        },
        end: {
          line: 89,
          column: 87
        }
      },
      '50': {
        start: {
          line: 90,
          column: 14
        },
        end: {
          line: 97,
          column: 15
        }
      },
      '51': {
        start: {
          line: 91,
          column: 16
        },
        end: {
          line: 96,
          column: 19
        }
      },
      '52': {
        start: {
          line: 99,
          column: 12
        },
        end: {
          line: 99,
          column: 62
        }
      },
      '53': {
        start: {
          line: 101,
          column: 12
        },
        end: {
          line: 101,
          column: 55
        }
      },
      '54': {
        start: {
          line: 104,
          column: 12
        },
        end: {
          line: 104,
          column: 33
        }
      },
      '55': {
        start: {
          line: 107,
          column: 10
        },
        end: {
          line: 107,
          column: 32
        }
      },
      '56': {
        start: {
          line: 110,
          column: 6
        },
        end: {
          line: 110,
          column: 30
        }
      },
      '57': {
        start: {
          line: 111,
          column: 6
        },
        end: {
          line: 111,
          column: 21
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 29
          },
          end: {
            line: 3,
            column: 30
          }
        },
        loc: {
          start: {
            line: 3,
            column: 136
          },
          end: {
            line: 112,
            column: 5
          }
        },
        line: 3
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 16,
            column: 30
          },
          end: {
            line: 16,
            column: 31
          }
        },
        loc: {
          start: {
            line: 16,
            column: 46
          },
          end: {
            line: 38,
            column: 7
          }
        },
        line: 16
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 46,
            column: 21
          },
          end: {
            line: 46,
            column: 22
          }
        },
        loc: {
          start: {
            line: 46,
            column: 33
          },
          end: {
            line: 109,
            column: 7
          }
        },
        line: 46
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 53,
            column: 128
          },
          end: {
            line: 53,
            column: 129
          }
        },
        loc: {
          start: {
            line: 53,
            column: 136
          },
          end: {
            line: 108,
            column: 9
          }
        },
        line: 53
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 62,
            column: 43
          },
          end: {
            line: 62,
            column: 44
          }
        },
        loc: {
          start: {
            line: 62,
            column: 52
          },
          end: {
            line: 65,
            column: 13
          }
        },
        line: 62
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 19,
            column: 10
          },
          end: {
            line: 22,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 19,
            column: 10
          },
          end: {
            line: 22,
            column: 11
          }
        }, {
          start: {
            line: 19,
            column: 10
          },
          end: {
            line: 22,
            column: 11
          }
        }],
        line: 19
      },
      '1': {
        loc: {
          start: {
            line: 41,
            column: 6
          },
          end: {
            line: 44,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 41,
            column: 6
          },
          end: {
            line: 44,
            column: 7
          }
        }, {
          start: {
            line: 41,
            column: 6
          },
          end: {
            line: 44,
            column: 7
          }
        }],
        line: 41
      },
      '2': {
        loc: {
          start: {
            line: 41,
            column: 10
          },
          end: {
            line: 41,
            column: 86
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 41,
            column: 10
          },
          end: {
            line: 41,
            column: 20
          }
        }, {
          start: {
            line: 41,
            column: 24
          },
          end: {
            line: 41,
            column: 53
          }
        }, {
          start: {
            line: 41,
            column: 57
          },
          end: {
            line: 41,
            column: 86
          }
        }],
        line: 41
      },
      '3': {
        loc: {
          start: {
            line: 48,
            column: 8
          },
          end: {
            line: 51,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 48,
            column: 8
          },
          end: {
            line: 51,
            column: 9
          }
        }, {
          start: {
            line: 48,
            column: 8
          },
          end: {
            line: 51,
            column: 9
          }
        }],
        line: 48
      },
      '4': {
        loc: {
          start: {
            line: 48,
            column: 12
          },
          end: {
            line: 48,
            column: 57
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 48,
            column: 12
          },
          end: {
            line: 48,
            column: 23
          }
        }, {
          start: {
            line: 48,
            column: 27
          },
          end: {
            line: 48,
            column: 57
          }
        }],
        line: 48
      },
      '5': {
        loc: {
          start: {
            line: 54,
            column: 10
          },
          end: {
            line: 106,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 54,
            column: 10
          },
          end: {
            line: 106,
            column: 11
          }
        }, {
          start: {
            line: 54,
            column: 10
          },
          end: {
            line: 106,
            column: 11
          }
        }],
        line: 54
      },
      '6': {
        loc: {
          start: {
            line: 55,
            column: 12
          },
          end: {
            line: 57,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 55,
            column: 12
          },
          end: {
            line: 57,
            column: 13
          }
        }, {
          start: {
            line: 55,
            column: 12
          },
          end: {
            line: 57,
            column: 13
          }
        }],
        line: 55
      },
      '7': {
        loc: {
          start: {
            line: 84,
            column: 12
          },
          end: {
            line: 98,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 84,
            column: 12
          },
          end: {
            line: 98,
            column: 13
          }
        }, {
          start: {
            line: 84,
            column: 12
          },
          end: {
            line: 98,
            column: 13
          }
        }],
        line: 84
      },
      '8': {
        loc: {
          start: {
            line: 89,
            column: 27
          },
          end: {
            line: 89,
            column: 87
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 89,
            column: 27
          },
          end: {
            line: 89,
            column: 82
          }
        }, {
          start: {
            line: 89,
            column: 86
          },
          end: {
            line: 89,
            column: 87
          }
        }],
        line: 89
      },
      '9': {
        loc: {
          start: {
            line: 90,
            column: 14
          },
          end: {
            line: 97,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 90,
            column: 14
          },
          end: {
            line: 97,
            column: 15
          }
        }, {
          start: {
            line: 90,
            column: 14
          },
          end: {
            line: 97,
            column: 15
          }
        }],
        line: 90
      },
      '10': {
        loc: {
          start: {
            line: 93,
            column: 53
          },
          end: {
            line: 93,
            column: 117
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 93,
            column: 64
          },
          end: {
            line: 93,
            column: 88
          }
        }, {
          start: {
            line: 93,
            column: 91
          },
          end: {
            line: 93,
            column: 117
          }
        }],
        line: 93
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2ln58896qt.s[0]++;
angular.module('app').controller('loginctrl', function ($scope, $LOGINService, $rootScope, $translate, moment, $location, tmhDynamicLocale, $deltadate, toast) {
  cov_2ln58896qt.f[0]++;
  cov_2ln58896qt.s[1]++;

  $scope.email = null;
  cov_2ln58896qt.s[2]++;
  $scope.password = null;
  cov_2ln58896qt.s[3]++;
  $scope.espace = null;

  cov_2ln58896qt.s[4]++;
  $scope.flag = '';
  cov_2ln58896qt.s[5]++;
  $scope.langs = [{ lib: 'English', flag: 'img/flags/16/Canada.png', display: 'img/flags/24/Canada.png', id: 'en' }, { lib: 'Français', flag: 'img/flags/16/France.png', display: 'img/flags/24/France.png', id: 'fr' }, { lib: 'Arabe', flag: 'img/flags/16/France.png', display: 'img/flags/24/France.png', id: 'ar' }, { lib: 'Espagnole', flag: 'img/flags/16/France.png', display: 'img/flags/24/France.png', id: 'es' }, { lib: 'Portugais', flag: 'img/flags/16/France.png', display: 'img/flags/24/France.png', id: 'pt' }];
  cov_2ln58896qt.s[6]++;
  $scope.changeLanguage = function (lang) {
    cov_2ln58896qt.f[1]++;

    var index = (cov_2ln58896qt.s[7]++, 0);
    cov_2ln58896qt.s[8]++;
    for (var i = $scope.langs.length - 1; i >= 0; i--) {
      cov_2ln58896qt.s[9]++;

      if ($scope.langs[i].id == lang) {
        cov_2ln58896qt.b[0][0]++;
        cov_2ln58896qt.s[10]++;

        index = i;
        cov_2ln58896qt.s[11]++;
        break;
      } else {
        cov_2ln58896qt.b[0][1]++;
      }
    }
    cov_2ln58896qt.s[12]++;
    $scope.flag = $scope.langs[index].display;
    cov_2ln58896qt.s[13]++;
    $scope.lang = $scope.langs[index].lib;
    cov_2ln58896qt.s[14]++;
    $translate.use($scope.langs[index].id);
    cov_2ln58896qt.s[15]++;
    $scope.language = $scope.langs[index];
    cov_2ln58896qt.s[16]++;
    $rootScope.language = $scope.language;
    cov_2ln58896qt.s[17]++;
    $deltadate.CURRENT_LANG = $scope.langs[index].id;
    cov_2ln58896qt.s[18]++;
    tmhDynamicLocale.set($scope.langs[index].id);
    cov_2ln58896qt.s[19]++;
    moment.locale($scope.langs[index].id);
    cov_2ln58896qt.s[20]++;
    $LOGINService.setLang(lang, true);
    // const quarter = $translate.instant('COMMON.TRIM_SHORT');
    /* if($LOGINService.getLang() == 'fr'){
      const quarter = 'Q';
    } */
    cov_2ln58896qt.s[21]++;
    window['moment-range'].extendMoment(moment);
  };

  var themeLocal = (cov_2ln58896qt.s[22]++, localStorage.getItem('THEME'));
  cov_2ln58896qt.s[23]++;
  if ((cov_2ln58896qt.b[2][0]++, themeLocal) && (cov_2ln58896qt.b[2][1]++, angular.isDefined(themeLocal)) && (cov_2ln58896qt.b[2][2]++, angular.isDefined(themeLocal))) {
    cov_2ln58896qt.b[1][0]++;

    var theme = (cov_2ln58896qt.s[24]++, angular.fromJson(themeLocal));
    cov_2ln58896qt.s[25]++;
    $scope.updateTheme(theme.primary, theme.tertiaire, theme.second);
  } else {
    cov_2ln58896qt.b[1][1]++;
  }

  cov_2ln58896qt.s[26]++;
  $scope.login = function () {
    cov_2ln58896qt.f[2]++;

    var local_theme = (cov_2ln58896qt.s[27]++, localStorage.getItem('THEME_DELTA_KEY'));
    cov_2ln58896qt.s[28]++;
    if ((cov_2ln58896qt.b[4][0]++, local_theme) && (cov_2ln58896qt.b[4][1]++, angular.isDefined(local_theme))) {
      cov_2ln58896qt.b[3][0]++;

      var theme_delta = (cov_2ln58896qt.s[29]++, angular.fromJson(local_theme));
      cov_2ln58896qt.s[30]++;
      $scope.updateTheme(theme_delta.primary, theme_delta.tertiaire, theme_delta.second);
    } else {
      cov_2ln58896qt.b[3][1]++;
    }

    cov_2ln58896qt.s[31]++;
    $LOGINService.login({ t: 1, e: $scope.espace, m: $scope.email, p: $scope.password, all: 1, n: $rootScope.connexionDate }, function (data) {
      cov_2ln58896qt.f[3]++;
      cov_2ln58896qt.s[32]++;

      if (data.i) {
        cov_2ln58896qt.b[5][0]++;
        cov_2ln58896qt.s[33]++;

        if (data.token) {
          cov_2ln58896qt.b[6][0]++;
          cov_2ln58896qt.s[34]++;

          $rootScope.setToken(data.token);
        } else {
          cov_2ln58896qt.b[6][1]++;
        }
        var lang = ( /*  'en' || /* */cov_2ln58896qt.s[35]++, data.la);
        cov_2ln58896qt.s[36]++;
        $LOGINService.setUE(data.i, data.e);
        // GET THEME FOR ESPACE
        var params = (cov_2ln58896qt.s[37]++, { t: 1, e: $scope.espace, m: $scope.email, n: $rootScope.connexionDate });
        cov_2ln58896qt.s[38]++;
        $LOGINService.getTheme(params, function (theme) {
          cov_2ln58896qt.f[4]++;
          cov_2ln58896qt.s[39]++;

          $scope.updateTheme(theme.primary, theme.tertiaire, theme.second);
          cov_2ln58896qt.s[40]++;
          localStorage.setItem('THEME_DELTA_KEY', angular.toJson(theme));
        });

        cov_2ln58896qt.s[41]++;
        $rootScope.setUser({
          name: data.n,
          id: data.i,
          espace: data.e,
          login: data.l,
          token: data.t,
          langue: lang,
          multi: data.multi == 1,
          isAdmin: data.type == 1,
          finalSuperAdmin: data.type == 1,
          superAdmin: data.type == 1
        });

        cov_2ln58896qt.s[42]++;
        $rootScope.settingSecurity();
        cov_2ln58896qt.s[43]++;
        $LOGINService.setToken(data.t);
        cov_2ln58896qt.s[44]++;
        $scope.changeLanguage(lang);

        cov_2ln58896qt.s[45]++;
        if (data.ex) {
          cov_2ln58896qt.b[7][0]++;

          var d_ = (cov_2ln58896qt.s[46]++, data.ex.split('-'));

          var expireDate = (cov_2ln58896qt.s[47]++, Date.newDate(data.ex));
          var todayDate = (cov_2ln58896qt.s[48]++, new Date());
          var diff = (cov_2ln58896qt.s[49]++, (cov_2ln58896qt.b[8][0]++, parseInt((expireDate - todayDate) / (1000 * 3600 * 24))) || (cov_2ln58896qt.b[8][1]++, 1));
          cov_2ln58896qt.s[50]++;
          if (diff <= 30) {
            cov_2ln58896qt.b[9][0]++;
            cov_2ln58896qt.s[51]++;

            toast.create({
              timeout: 10000 * 1000,
              message: '<h3>' + $translate.instant(diff < 2 ? (cov_2ln58896qt.b[10][0]++, 'LOGIN.PASSWORD_EXPIRED') : (cov_2ln58896qt.b[10][1]++, 'LOGIN.PASSWORD_EXPIRED_S'), { days: diff }) + '</h3>',
              className: 'alert-danger',
              dismissible: true
            });
          } else {
            cov_2ln58896qt.b[9][1]++;
          }
        } else {
          cov_2ln58896qt.b[7][1]++;
        }
        cov_2ln58896qt.s[52]++;
        $rootScope.personnalPortfolio.getLabelPortfolio();
        // $location.path('/app/projects').replace();
        cov_2ln58896qt.s[53]++;
        $location.path('/app/portfolio').replace();
        // $location.path('/app/dashboards').replace();
      } else {
        cov_2ln58896qt.b[5][1]++;
        cov_2ln58896qt.s[54]++;

        $scope.denied = true;
        // $location.path('/uclga_login').replace();
      }
      cov_2ln58896qt.s[55]++;
      $rootScope.endLogin();
    });
  };
  cov_2ln58896qt.s[56]++;
  $scope.changeLanguage();
  cov_2ln58896qt.s[57]++;
  $scope.login();
});
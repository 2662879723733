'use strict';

var cov_1gjoi0oouv = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/directives/dashboard-combine.js',
      hash = '454596dcb04f2f01c231db030d04982b6b44f88a',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/directives/dashboard-combine.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 432,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 431,
          column: 4
        }
      },
      '2': {
        start: {
          line: 17,
          column: 19
        },
        end: {
          line: 17,
          column: 28
        }
      },
      '3': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 18,
          column: 67
        }
      },
      '4': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 21,
          column: 8
        }
      },
      '5': {
        start: {
          line: 20,
          column: 8
        },
        end: {
          line: 20,
          column: 53
        }
      },
      '6': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 24,
          column: 7
        }
      },
      '7': {
        start: {
          line: 23,
          column: 8
        },
        end: {
          line: 23,
          column: 52
        }
      },
      '8': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 28
        }
      },
      '9': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 43,
          column: 8
        }
      },
      '10': {
        start: {
          line: 27,
          column: 8
        },
        end: {
          line: 29,
          column: 9
        }
      },
      '11': {
        start: {
          line: 28,
          column: 10
        },
        end: {
          line: 28,
          column: 22
        }
      },
      '12': {
        start: {
          line: 30,
          column: 8
        },
        end: {
          line: 32,
          column: 9
        }
      },
      '13': {
        start: {
          line: 31,
          column: 10
        },
        end: {
          line: 31,
          column: 23
        }
      },
      '14': {
        start: {
          line: 33,
          column: 8
        },
        end: {
          line: 35,
          column: 9
        }
      },
      '15': {
        start: {
          line: 34,
          column: 10
        },
        end: {
          line: 34,
          column: 22
        }
      },
      '16': {
        start: {
          line: 36,
          column: 21
        },
        end: {
          line: 36,
          column: 44
        }
      },
      '17': {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 41,
          column: 9
        }
      },
      '18': {
        start: {
          line: 38,
          column: 10
        },
        end: {
          line: 40,
          column: 11
        }
      },
      '19': {
        start: {
          line: 39,
          column: 12
        },
        end: {
          line: 39,
          column: 33
        }
      },
      '20': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 42,
          column: 21
        }
      },
      '21': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 65,
          column: 8
        }
      },
      '22': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 64,
          column: 9
        }
      },
      '23': {
        start: {
          line: 47,
          column: 12
        },
        end: {
          line: 47,
          column: 24
        }
      },
      '24': {
        start: {
          line: 49,
          column: 12
        },
        end: {
          line: 49,
          column: 24
        }
      },
      '25': {
        start: {
          line: 51,
          column: 12
        },
        end: {
          line: 51,
          column: 24
        }
      },
      '26': {
        start: {
          line: 53,
          column: 12
        },
        end: {
          line: 53,
          column: 24
        }
      },
      '27': {
        start: {
          line: 55,
          column: 12
        },
        end: {
          line: 55,
          column: 24
        }
      },
      '28': {
        start: {
          line: 57,
          column: 12
        },
        end: {
          line: 57,
          column: 24
        }
      },
      '29': {
        start: {
          line: 59,
          column: 12
        },
        end: {
          line: 59,
          column: 24
        }
      },
      '30': {
        start: {
          line: 61,
          column: 12
        },
        end: {
          line: 61,
          column: 24
        }
      },
      '31': {
        start: {
          line: 63,
          column: 12
        },
        end: {
          line: 63,
          column: 24
        }
      },
      '32': {
        start: {
          line: 66,
          column: 6
        },
        end: {
          line: 419,
          column: 8
        }
      },
      '33': {
        start: {
          line: 68,
          column: 10
        },
        end: {
          line: 70,
          column: 11
        }
      },
      '34': {
        start: {
          line: 69,
          column: 12
        },
        end: {
          line: 69,
          column: 19
        }
      },
      '35': {
        start: {
          line: 71,
          column: 10
        },
        end: {
          line: 71,
          column: 58
        }
      },
      '36': {
        start: {
          line: 73,
          column: 10
        },
        end: {
          line: 79,
          column: 19
        }
      },
      '37': {
        start: {
          line: 74,
          column: 12
        },
        end: {
          line: 77,
          column: 13
        }
      },
      '38': {
        start: {
          line: 75,
          column: 14
        },
        end: {
          line: 75,
          column: 69
        }
      },
      '39': {
        start: {
          line: 76,
          column: 14
        },
        end: {
          line: 76,
          column: 21
        }
      },
      '40': {
        start: {
          line: 78,
          column: 12
        },
        end: {
          line: 78,
          column: 67
        }
      },
      '41': {
        start: {
          line: 82,
          column: 10
        },
        end: {
          line: 84,
          column: 11
        }
      },
      '42': {
        start: {
          line: 83,
          column: 12
        },
        end: {
          line: 83,
          column: 25
        }
      },
      '43': {
        start: {
          line: 85,
          column: 10
        },
        end: {
          line: 87,
          column: 11
        }
      },
      '44': {
        start: {
          line: 86,
          column: 12
        },
        end: {
          line: 86,
          column: 24
        }
      },
      '45': {
        start: {
          line: 89,
          column: 10
        },
        end: {
          line: 91,
          column: 11
        }
      },
      '46': {
        start: {
          line: 90,
          column: 12
        },
        end: {
          line: 90,
          column: 24
        }
      },
      '47': {
        start: {
          line: 93,
          column: 10
        },
        end: {
          line: 95,
          column: 11
        }
      },
      '48': {
        start: {
          line: 94,
          column: 12
        },
        end: {
          line: 94,
          column: 24
        }
      },
      '49': {
        start: {
          line: 97,
          column: 10
        },
        end: {
          line: 97,
          column: 23
        }
      },
      '50': {
        start: {
          line: 100,
          column: 23
        },
        end: {
          line: 100,
          column: 63
        }
      },
      '51': {
        start: {
          line: 102,
          column: 10
        },
        end: {
          line: 112,
          column: 11
        }
      },
      '52': {
        start: {
          line: 103,
          column: 12
        },
        end: {
          line: 105,
          column: 13
        }
      },
      '53': {
        start: {
          line: 104,
          column: 14
        },
        end: {
          line: 104,
          column: 21
        }
      },
      '54': {
        start: {
          line: 106,
          column: 17
        },
        end: {
          line: 112,
          column: 11
        }
      },
      '55': {
        start: {
          line: 107,
          column: 12
        },
        end: {
          line: 109,
          column: 13
        }
      },
      '56': {
        start: {
          line: 108,
          column: 14
        },
        end: {
          line: 108,
          column: 21
        }
      },
      '57': {
        start: {
          line: 110,
          column: 17
        },
        end: {
          line: 112,
          column: 11
        }
      },
      '58': {
        start: {
          line: 111,
          column: 12
        },
        end: {
          line: 111,
          column: 19
        }
      },
      '59': {
        start: {
          line: 113,
          column: 10
        },
        end: {
          line: 113,
          column: 60
        }
      },
      '60': {
        start: {
          line: 114,
          column: 10
        },
        end: {
          line: 114,
          column: 30
        }
      },
      '61': {
        start: {
          line: 115,
          column: 10
        },
        end: {
          line: 115,
          column: 26
        }
      },
      '62': {
        start: {
          line: 118,
          column: 10
        },
        end: {
          line: 120,
          column: 11
        }
      },
      '63': {
        start: {
          line: 119,
          column: 12
        },
        end: {
          line: 119,
          column: 19
        }
      },
      '64': {
        start: {
          line: 121,
          column: 10
        },
        end: {
          line: 121,
          column: 48
        }
      },
      '65': {
        start: {
          line: 122,
          column: 10
        },
        end: {
          line: 122,
          column: 26
        }
      },
      '66': {
        start: {
          line: 125,
          column: 10
        },
        end: {
          line: 125,
          column: 27
        }
      },
      '67': {
        start: {
          line: 126,
          column: 10
        },
        end: {
          line: 129,
          column: 12
        }
      },
      '68': {
        start: {
          line: 132,
          column: 10
        },
        end: {
          line: 132,
          column: 27
        }
      },
      '69': {
        start: {
          line: 133,
          column: 10
        },
        end: {
          line: 136,
          column: 12
        }
      },
      '70': {
        start: {
          line: 139,
          column: 10
        },
        end: {
          line: 139,
          column: 27
        }
      },
      '71': {
        start: {
          line: 140,
          column: 10
        },
        end: {
          line: 143,
          column: 12
        }
      },
      '72': {
        start: {
          line: 147,
          column: 26
        },
        end: {
          line: 150,
          column: 11
        }
      },
      '73': {
        start: {
          line: 152,
          column: 10
        },
        end: {
          line: 152,
          column: 33
        }
      },
      '74': {
        start: {
          line: 153,
          column: 19
        },
        end: {
          line: 153,
          column: 21
        }
      },
      '75': {
        start: {
          line: 154,
          column: 24
        },
        end: {
          line: 154,
          column: 26
        }
      },
      '76': {
        start: {
          line: 155,
          column: 10
        },
        end: {
          line: 178,
          column: 11
        }
      },
      '77': {
        start: {
          line: 156,
          column: 12
        },
        end: {
          line: 156,
          column: 27
        }
      },
      '78': {
        start: {
          line: 157,
          column: 12
        },
        end: {
          line: 157,
          column: 39
        }
      },
      '79': {
        start: {
          line: 158,
          column: 12
        },
        end: {
          line: 158,
          column: 46
        }
      },
      '80': {
        start: {
          line: 160,
          column: 17
        },
        end: {
          line: 178,
          column: 11
        }
      },
      '81': {
        start: {
          line: 161,
          column: 12
        },
        end: {
          line: 161,
          column: 37
        }
      },
      '82': {
        start: {
          line: 162,
          column: 12
        },
        end: {
          line: 162,
          column: 46
        }
      },
      '83': {
        start: {
          line: 163,
          column: 12
        },
        end: {
          line: 163,
          column: 22
        }
      },
      '84': {
        start: {
          line: 165,
          column: 17
        },
        end: {
          line: 178,
          column: 11
        }
      },
      '85': {
        start: {
          line: 166,
          column: 12
        },
        end: {
          line: 166,
          column: 41
        }
      },
      '86': {
        start: {
          line: 167,
          column: 12
        },
        end: {
          line: 167,
          column: 22
        }
      },
      '87': {
        start: {
          line: 169,
          column: 17
        },
        end: {
          line: 178,
          column: 11
        }
      },
      '88': {
        start: {
          line: 170,
          column: 12
        },
        end: {
          line: 170,
          column: 46
        }
      },
      '89': {
        start: {
          line: 171,
          column: 12
        },
        end: {
          line: 171,
          column: 22
        }
      },
      '90': {
        start: {
          line: 174,
          column: 12
        },
        end: {
          line: 174,
          column: 42
        }
      },
      '91': {
        start: {
          line: 175,
          column: 12
        },
        end: {
          line: 175,
          column: 28
        }
      },
      '92': {
        start: {
          line: 176,
          column: 12
        },
        end: {
          line: 176,
          column: 46
        }
      },
      '93': {
        start: {
          line: 179,
          column: 10
        },
        end: {
          line: 316,
          column: 13
        }
      },
      '94': {
        start: {
          line: 180,
          column: 12
        },
        end: {
          line: 187,
          column: 13
        }
      },
      '95': {
        start: {
          line: 181,
          column: 14
        },
        end: {
          line: 183,
          column: 15
        }
      },
      '96': {
        start: {
          line: 182,
          column: 16
        },
        end: {
          line: 182,
          column: 23
        }
      },
      '97': {
        start: {
          line: 184,
          column: 14
        },
        end: {
          line: 186,
          column: 15
        }
      },
      '98': {
        start: {
          line: 185,
          column: 16
        },
        end: {
          line: 185,
          column: 23
        }
      },
      '99': {
        start: {
          line: 188,
          column: 12
        },
        end: {
          line: 188,
          column: 37
        }
      },
      '100': {
        start: {
          line: 189,
          column: 27
        },
        end: {
          line: 250,
          column: 13
        }
      },
      '101': {
        start: {
          line: 191,
          column: 16
        },
        end: {
          line: 191,
          column: 98
        }
      },
      '102': {
        start: {
          line: 195,
          column: 16
        },
        end: {
          line: 197,
          column: 17
        }
      },
      '103': {
        start: {
          line: 196,
          column: 18
        },
        end: {
          line: 196,
          column: 43
        }
      },
      '104': {
        start: {
          line: 247,
          column: 16
        },
        end: {
          line: 247,
          column: 56
        }
      },
      '105': {
        start: {
          line: 251,
          column: 12
        },
        end: {
          line: 267,
          column: 13
        }
      },
      '106': {
        start: {
          line: 252,
          column: 24
        },
        end: {
          line: 265,
          column: 15
        }
      },
      '107': {
        start: {
          line: 266,
          column: 14
        },
        end: {
          line: 266,
          column: 36
        }
      },
      '108': {
        start: {
          line: 268,
          column: 12
        },
        end: {
          line: 289,
          column: 13
        }
      },
      '109': {
        start: {
          line: 269,
          column: 14
        },
        end: {
          line: 275,
          column: 17
        }
      },
      '110': {
        start: {
          line: 270,
          column: 16
        },
        end: {
          line: 274,
          column: 18
        }
      },
      '111': {
        start: {
          line: 277,
          column: 29
        },
        end: {
          line: 279,
          column: 19
        }
      },
      '112': {
        start: {
          line: 278,
          column: 16
        },
        end: {
          line: 278,
          column: 82
        }
      },
      '113': {
        start: {
          line: 280,
          column: 14
        },
        end: {
          line: 288,
          column: 15
        }
      },
      '114': {
        start: {
          line: 281,
          column: 16
        },
        end: {
          line: 287,
          column: 19
        }
      },
      '115': {
        start: {
          line: 282,
          column: 18
        },
        end: {
          line: 286,
          column: 20
        }
      },
      '116': {
        start: {
          line: 290,
          column: 27
        },
        end: {
          line: 290,
          column: 71
        }
      },
      '117': {
        start: {
          line: 291,
          column: 12
        },
        end: {
          line: 314,
          column: 13
        }
      },
      '118': {
        start: {
          line: 292,
          column: 14
        },
        end: {
          line: 292,
          column: 59
        }
      },
      '119': {
        start: {
          line: 297,
          column: 14
        },
        end: {
          line: 303,
          column: 15
        }
      },
      '120': {
        start: {
          line: 298,
          column: 16
        },
        end: {
          line: 298,
          column: 73
        }
      },
      '121': {
        start: {
          line: 299,
          column: 21
        },
        end: {
          line: 303,
          column: 15
        }
      },
      '122': {
        start: {
          line: 300,
          column: 16
        },
        end: {
          line: 300,
          column: 70
        }
      },
      '123': {
        start: {
          line: 302,
          column: 16
        },
        end: {
          line: 302,
          column: 80
        }
      },
      '124': {
        start: {
          line: 305,
          column: 28
        },
        end: {
          line: 305,
          column: 92
        }
      },
      '125': {
        start: {
          line: 307,
          column: 14
        },
        end: {
          line: 313,
          column: 15
        }
      },
      '126': {
        start: {
          line: 308,
          column: 36
        },
        end: {
          line: 308,
          column: 66
        }
      },
      '127': {
        start: {
          line: 309,
          column: 16
        },
        end: {
          line: 312,
          column: 19
        }
      },
      '128': {
        start: {
          line: 315,
          column: 12
        },
        end: {
          line: 315,
          column: 113
        }
      },
      '129': {
        start: {
          line: 319,
          column: 24
        },
        end: {
          line: 319,
          column: 172
        }
      },
      '130': {
        start: {
          line: 320,
          column: 10
        },
        end: {
          line: 320,
          column: 51
        }
      },
      '131': {
        start: {
          line: 321,
          column: 10
        },
        end: {
          line: 321,
          column: 27
        }
      },
      '132': {
        start: {
          line: 322,
          column: 10
        },
        end: {
          line: 326,
          column: 11
        }
      },
      '133': {
        start: {
          line: 323,
          column: 12
        },
        end: {
          line: 323,
          column: 25
        }
      },
      '134': {
        start: {
          line: 324,
          column: 12
        },
        end: {
          line: 324,
          column: 25
        }
      },
      '135': {
        start: {
          line: 325,
          column: 12
        },
        end: {
          line: 325,
          column: 25
        }
      },
      '136': {
        start: {
          line: 327,
          column: 10
        },
        end: {
          line: 381,
          column: 12
        }
      },
      '137': {
        start: {
          line: 333,
          column: 36
        },
        end: {
          line: 333,
          column: 107
        }
      },
      '138': {
        start: {
          line: 334,
          column: 20
        },
        end: {
          line: 336,
          column: 21
        }
      },
      '139': {
        start: {
          line: 335,
          column: 22
        },
        end: {
          line: 335,
          column: 34
        }
      },
      '140': {
        start: {
          line: 337,
          column: 37
        },
        end: {
          line: 337,
          column: 63
        }
      },
      '141': {
        start: {
          line: 338,
          column: 20
        },
        end: {
          line: 340,
          column: 21
        }
      },
      '142': {
        start: {
          line: 339,
          column: 22
        },
        end: {
          line: 339,
          column: 40
        }
      },
      '143': {
        start: {
          line: 341,
          column: 20
        },
        end: {
          line: 341,
          column: 38
        }
      },
      '144': {
        start: {
          line: 350,
          column: 39
        },
        end: {
          line: 350,
          column: 63
        }
      },
      '145': {
        start: {
          line: 351,
          column: 22
        },
        end: {
          line: 353,
          column: 23
        }
      },
      '146': {
        start: {
          line: 352,
          column: 24
        },
        end: {
          line: 352,
          column: 42
        }
      },
      '147': {
        start: {
          line: 354,
          column: 22
        },
        end: {
          line: 354,
          column: 40
        }
      },
      '148': {
        start: {
          line: 365,
          column: 16
        },
        end: {
          line: 370,
          column: 17
        }
      },
      '149': {
        start: {
          line: 366,
          column: 18
        },
        end: {
          line: 369,
          column: 19
        }
      },
      '150': {
        start: {
          line: 367,
          column: 20
        },
        end: {
          line: 367,
          column: 62
        }
      },
      '151': {
        start: {
          line: 368,
          column: 20
        },
        end: {
          line: 368,
          column: 27
        }
      },
      '152': {
        start: {
          line: 382,
          column: 29
        },
        end: {
          line: 382,
          column: 31
        }
      },
      '153': {
        start: {
          line: 383,
          column: 10
        },
        end: {
          line: 393,
          column: 11
        }
      },
      '154': {
        start: {
          line: 384,
          column: 12
        },
        end: {
          line: 384,
          column: 33
        }
      },
      '155': {
        start: {
          line: 385,
          column: 12
        },
        end: {
          line: 385,
          column: 54
        }
      },
      '156': {
        start: {
          line: 386,
          column: 12
        },
        end: {
          line: 390,
          column: 15
        }
      },
      '157': {
        start: {
          line: 391,
          column: 12
        },
        end: {
          line: 391,
          column: 99
        }
      },
      '158': {
        start: {
          line: 392,
          column: 12
        },
        end: {
          line: 392,
          column: 73
        }
      },
      '159': {
        start: {
          line: 394,
          column: 10
        },
        end: {
          line: 394,
          column: 34
        }
      },
      '160': {
        start: {
          line: 395,
          column: 10
        },
        end: {
          line: 400,
          column: 11
        }
      },
      '161': {
        start: {
          line: 396,
          column: 12
        },
        end: {
          line: 399,
          column: 15
        }
      },
      '162': {
        start: {
          line: 397,
          column: 14
        },
        end: {
          line: 397,
          column: 45
        }
      },
      '163': {
        start: {
          line: 398,
          column: 14
        },
        end: {
          line: 398,
          column: 47
        }
      },
      '164': {
        start: {
          line: 403,
          column: 10
        },
        end: {
          line: 403,
          column: 27
        }
      },
      '165': {
        start: {
          line: 404,
          column: 10
        },
        end: {
          line: 416,
          column: 13
        }
      },
      '166': {
        start: {
          line: 417,
          column: 10
        },
        end: {
          line: 417,
          column: 26
        }
      },
      '167': {
        start: {
          line: 420,
          column: 6
        },
        end: {
          line: 420,
          column: 28
        }
      },
      '168': {
        start: {
          line: 422,
          column: 6
        },
        end: {
          line: 429,
          column: 9
        }
      },
      '169': {
        start: {
          line: 423,
          column: 8
        },
        end: {
          line: 423,
          column: 28
        }
      },
      '170': {
        start: {
          line: 425,
          column: 8
        },
        end: {
          line: 428,
          column: 9
        }
      },
      '171': {
        start: {
          line: 426,
          column: 10
        },
        end: {
          line: 426,
          column: 47
        }
      },
      '172': {
        start: {
          line: 427,
          column: 10
        },
        end: {
          line: 427,
          column: 27
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 52
          },
          end: {
            line: 2,
            column: 53
          }
        },
        loc: {
          start: {
            line: 2,
            column: 64
          },
          end: {
            line: 432,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 19,
            column: 36
          },
          end: {
            line: 19,
            column: 37
          }
        },
        loc: {
          start: {
            line: 19,
            column: 52
          },
          end: {
            line: 21,
            column: 7
          }
        },
        line: 19
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 26,
            column: 30
          },
          end: {
            line: 26,
            column: 31
          }
        },
        loc: {
          start: {
            line: 26,
            column: 60
          },
          end: {
            line: 43,
            column: 7
          }
        },
        line: 26
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 44,
            column: 31
          },
          end: {
            line: 44,
            column: 32
          }
        },
        loc: {
          start: {
            line: 44,
            column: 48
          },
          end: {
            line: 65,
            column: 7
          }
        },
        line: 44
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 73,
            column: 19
          },
          end: {
            line: 73,
            column: 20
          }
        },
        loc: {
          start: {
            line: 73,
            column: 25
          },
          end: {
            line: 79,
            column: 11
          }
        },
        line: 73
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 179,
            column: 58
          },
          end: {
            line: 179,
            column: 59
          }
        },
        loc: {
          start: {
            line: 179,
            column: 67
          },
          end: {
            line: 316,
            column: 11
          }
        },
        line: 179
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 269,
            column: 32
          },
          end: {
            line: 269,
            column: 33
          }
        },
        loc: {
          start: {
            line: 269,
            column: 42
          },
          end: {
            line: 275,
            column: 15
          }
        },
        line: 269
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 277,
            column: 42
          },
          end: {
            line: 277,
            column: 43
          }
        },
        loc: {
          start: {
            line: 277,
            column: 50
          },
          end: {
            line: 279,
            column: 15
          }
        },
        line: 277
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 281,
            column: 106
          },
          end: {
            line: 281,
            column: 107
          }
        },
        loc: {
          start: {
            line: 281,
            column: 114
          },
          end: {
            line: 287,
            column: 17
          }
        },
        line: 281
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 396,
            column: 63
          },
          end: {
            line: 396,
            column: 64
          }
        },
        loc: {
          start: {
            line: 396,
            column: 71
          },
          end: {
            line: 399,
            column: 13
          }
        },
        line: 396
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 422,
            column: 20
          },
          end: {
            line: 422,
            column: 21
          }
        },
        loc: {
          start: {
            line: 422,
            column: 29
          },
          end: {
            line: 424,
            column: 7
          }
        },
        line: 422
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 424,
            column: 9
          },
          end: {
            line: 424,
            column: 10
          }
        },
        loc: {
          start: {
            line: 424,
            column: 21
          },
          end: {
            line: 429,
            column: 7
          }
        },
        line: 424
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 22,
            column: 6
          },
          end: {
            line: 24,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 22,
            column: 6
          },
          end: {
            line: 24,
            column: 7
          }
        }, {
          start: {
            line: 22,
            column: 6
          },
          end: {
            line: 24,
            column: 7
          }
        }],
        line: 22
      },
      '1': {
        loc: {
          start: {
            line: 27,
            column: 8
          },
          end: {
            line: 29,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 27,
            column: 8
          },
          end: {
            line: 29,
            column: 9
          }
        }, {
          start: {
            line: 27,
            column: 8
          },
          end: {
            line: 29,
            column: 9
          }
        }],
        line: 27
      },
      '2': {
        loc: {
          start: {
            line: 30,
            column: 8
          },
          end: {
            line: 32,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 30,
            column: 8
          },
          end: {
            line: 32,
            column: 9
          }
        }, {
          start: {
            line: 30,
            column: 8
          },
          end: {
            line: 32,
            column: 9
          }
        }],
        line: 30
      },
      '3': {
        loc: {
          start: {
            line: 33,
            column: 8
          },
          end: {
            line: 35,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 33,
            column: 8
          },
          end: {
            line: 35,
            column: 9
          }
        }, {
          start: {
            line: 33,
            column: 8
          },
          end: {
            line: 35,
            column: 9
          }
        }],
        line: 33
      },
      '4': {
        loc: {
          start: {
            line: 38,
            column: 10
          },
          end: {
            line: 40,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 38,
            column: 10
          },
          end: {
            line: 40,
            column: 11
          }
        }, {
          start: {
            line: 38,
            column: 10
          },
          end: {
            line: 40,
            column: 11
          }
        }],
        line: 38
      },
      '5': {
        loc: {
          start: {
            line: 45,
            column: 8
          },
          end: {
            line: 64,
            column: 9
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 46,
            column: 10
          },
          end: {
            line: 46,
            column: 17
          }
        }, {
          start: {
            line: 46,
            column: 18
          },
          end: {
            line: 47,
            column: 24
          }
        }, {
          start: {
            line: 48,
            column: 10
          },
          end: {
            line: 48,
            column: 17
          }
        }, {
          start: {
            line: 48,
            column: 18
          },
          end: {
            line: 49,
            column: 24
          }
        }, {
          start: {
            line: 50,
            column: 10
          },
          end: {
            line: 50,
            column: 17
          }
        }, {
          start: {
            line: 50,
            column: 18
          },
          end: {
            line: 51,
            column: 24
          }
        }, {
          start: {
            line: 52,
            column: 10
          },
          end: {
            line: 52,
            column: 17
          }
        }, {
          start: {
            line: 52,
            column: 18
          },
          end: {
            line: 53,
            column: 24
          }
        }, {
          start: {
            line: 54,
            column: 10
          },
          end: {
            line: 54,
            column: 17
          }
        }, {
          start: {
            line: 54,
            column: 18
          },
          end: {
            line: 55,
            column: 24
          }
        }, {
          start: {
            line: 56,
            column: 10
          },
          end: {
            line: 56,
            column: 17
          }
        }, {
          start: {
            line: 56,
            column: 18
          },
          end: {
            line: 57,
            column: 24
          }
        }, {
          start: {
            line: 58,
            column: 10
          },
          end: {
            line: 58,
            column: 17
          }
        }, {
          start: {
            line: 58,
            column: 18
          },
          end: {
            line: 59,
            column: 24
          }
        }, {
          start: {
            line: 60,
            column: 10
          },
          end: {
            line: 60,
            column: 17
          }
        }, {
          start: {
            line: 60,
            column: 18
          },
          end: {
            line: 61,
            column: 24
          }
        }, {
          start: {
            line: 62,
            column: 10
          },
          end: {
            line: 63,
            column: 24
          }
        }],
        line: 45
      },
      '6': {
        loc: {
          start: {
            line: 68,
            column: 10
          },
          end: {
            line: 70,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 68,
            column: 10
          },
          end: {
            line: 70,
            column: 11
          }
        }, {
          start: {
            line: 68,
            column: 10
          },
          end: {
            line: 70,
            column: 11
          }
        }],
        line: 68
      },
      '7': {
        loc: {
          start: {
            line: 74,
            column: 12
          },
          end: {
            line: 77,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 74,
            column: 12
          },
          end: {
            line: 77,
            column: 13
          }
        }, {
          start: {
            line: 74,
            column: 12
          },
          end: {
            line: 77,
            column: 13
          }
        }],
        line: 74
      },
      '8': {
        loc: {
          start: {
            line: 82,
            column: 10
          },
          end: {
            line: 84,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 82,
            column: 10
          },
          end: {
            line: 84,
            column: 11
          }
        }, {
          start: {
            line: 82,
            column: 10
          },
          end: {
            line: 84,
            column: 11
          }
        }],
        line: 82
      },
      '9': {
        loc: {
          start: {
            line: 85,
            column: 10
          },
          end: {
            line: 87,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 85,
            column: 10
          },
          end: {
            line: 87,
            column: 11
          }
        }, {
          start: {
            line: 85,
            column: 10
          },
          end: {
            line: 87,
            column: 11
          }
        }],
        line: 85
      },
      '10': {
        loc: {
          start: {
            line: 85,
            column: 14
          },
          end: {
            line: 85,
            column: 67
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 85,
            column: 14
          },
          end: {
            line: 85,
            column: 32
          }
        }, {
          start: {
            line: 85,
            column: 36
          },
          end: {
            line: 85,
            column: 67
          }
        }],
        line: 85
      },
      '11': {
        loc: {
          start: {
            line: 89,
            column: 10
          },
          end: {
            line: 91,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 89,
            column: 10
          },
          end: {
            line: 91,
            column: 11
          }
        }, {
          start: {
            line: 89,
            column: 10
          },
          end: {
            line: 91,
            column: 11
          }
        }],
        line: 89
      },
      '12': {
        loc: {
          start: {
            line: 89,
            column: 14
          },
          end: {
            line: 89,
            column: 87
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 89,
            column: 14
          },
          end: {
            line: 89,
            column: 47
          }
        }, {
          start: {
            line: 89,
            column: 51
          },
          end: {
            line: 89,
            column: 87
          }
        }],
        line: 89
      },
      '13': {
        loc: {
          start: {
            line: 93,
            column: 10
          },
          end: {
            line: 95,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 93,
            column: 10
          },
          end: {
            line: 95,
            column: 11
          }
        }, {
          start: {
            line: 93,
            column: 10
          },
          end: {
            line: 95,
            column: 11
          }
        }],
        line: 93
      },
      '14': {
        loc: {
          start: {
            line: 93,
            column: 14
          },
          end: {
            line: 93,
            column: 161
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 93,
            column: 14
          },
          end: {
            line: 93,
            column: 65
          }
        }, {
          start: {
            line: 93,
            column: 69
          },
          end: {
            line: 93,
            column: 124
          }
        }, {
          start: {
            line: 93,
            column: 128
          },
          end: {
            line: 93,
            column: 161
          }
        }],
        line: 93
      },
      '15': {
        loc: {
          start: {
            line: 102,
            column: 10
          },
          end: {
            line: 112,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 102,
            column: 10
          },
          end: {
            line: 112,
            column: 11
          }
        }, {
          start: {
            line: 102,
            column: 10
          },
          end: {
            line: 112,
            column: 11
          }
        }],
        line: 102
      },
      '16': {
        loc: {
          start: {
            line: 103,
            column: 12
          },
          end: {
            line: 105,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 103,
            column: 12
          },
          end: {
            line: 105,
            column: 13
          }
        }, {
          start: {
            line: 103,
            column: 12
          },
          end: {
            line: 105,
            column: 13
          }
        }],
        line: 103
      },
      '17': {
        loc: {
          start: {
            line: 106,
            column: 17
          },
          end: {
            line: 112,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 106,
            column: 17
          },
          end: {
            line: 112,
            column: 11
          }
        }, {
          start: {
            line: 106,
            column: 17
          },
          end: {
            line: 112,
            column: 11
          }
        }],
        line: 106
      },
      '18': {
        loc: {
          start: {
            line: 107,
            column: 12
          },
          end: {
            line: 109,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 107,
            column: 12
          },
          end: {
            line: 109,
            column: 13
          }
        }, {
          start: {
            line: 107,
            column: 12
          },
          end: {
            line: 109,
            column: 13
          }
        }],
        line: 107
      },
      '19': {
        loc: {
          start: {
            line: 110,
            column: 17
          },
          end: {
            line: 112,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 110,
            column: 17
          },
          end: {
            line: 112,
            column: 11
          }
        }, {
          start: {
            line: 110,
            column: 17
          },
          end: {
            line: 112,
            column: 11
          }
        }],
        line: 110
      },
      '20': {
        loc: {
          start: {
            line: 118,
            column: 10
          },
          end: {
            line: 120,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 118,
            column: 10
          },
          end: {
            line: 120,
            column: 11
          }
        }, {
          start: {
            line: 118,
            column: 10
          },
          end: {
            line: 120,
            column: 11
          }
        }],
        line: 118
      },
      '21': {
        loc: {
          start: {
            line: 155,
            column: 10
          },
          end: {
            line: 178,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 155,
            column: 10
          },
          end: {
            line: 178,
            column: 11
          }
        }, {
          start: {
            line: 155,
            column: 10
          },
          end: {
            line: 178,
            column: 11
          }
        }],
        line: 155
      },
      '22': {
        loc: {
          start: {
            line: 160,
            column: 17
          },
          end: {
            line: 178,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 160,
            column: 17
          },
          end: {
            line: 178,
            column: 11
          }
        }, {
          start: {
            line: 160,
            column: 17
          },
          end: {
            line: 178,
            column: 11
          }
        }],
        line: 160
      },
      '23': {
        loc: {
          start: {
            line: 165,
            column: 17
          },
          end: {
            line: 178,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 165,
            column: 17
          },
          end: {
            line: 178,
            column: 11
          }
        }, {
          start: {
            line: 165,
            column: 17
          },
          end: {
            line: 178,
            column: 11
          }
        }],
        line: 165
      },
      '24': {
        loc: {
          start: {
            line: 169,
            column: 17
          },
          end: {
            line: 178,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 169,
            column: 17
          },
          end: {
            line: 178,
            column: 11
          }
        }, {
          start: {
            line: 169,
            column: 17
          },
          end: {
            line: 178,
            column: 11
          }
        }],
        line: 169
      },
      '25': {
        loc: {
          start: {
            line: 181,
            column: 14
          },
          end: {
            line: 183,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 181,
            column: 14
          },
          end: {
            line: 183,
            column: 15
          }
        }, {
          start: {
            line: 181,
            column: 14
          },
          end: {
            line: 183,
            column: 15
          }
        }],
        line: 181
      },
      '26': {
        loc: {
          start: {
            line: 184,
            column: 14
          },
          end: {
            line: 186,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 184,
            column: 14
          },
          end: {
            line: 186,
            column: 15
          }
        }, {
          start: {
            line: 184,
            column: 14
          },
          end: {
            line: 186,
            column: 15
          }
        }],
        line: 184
      },
      '27': {
        loc: {
          start: {
            line: 191,
            column: 43
          },
          end: {
            line: 191,
            column: 97
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 191,
            column: 72
          },
          end: {
            line: 191,
            column: 92
          }
        }, {
          start: {
            line: 191,
            column: 95
          },
          end: {
            line: 191,
            column: 97
          }
        }],
        line: 191
      },
      '28': {
        loc: {
          start: {
            line: 195,
            column: 16
          },
          end: {
            line: 197,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 195,
            column: 16
          },
          end: {
            line: 197,
            column: 17
          }
        }, {
          start: {
            line: 195,
            column: 16
          },
          end: {
            line: 197,
            column: 17
          }
        }],
        line: 195
      },
      '29': {
        loc: {
          start: {
            line: 239,
            column: 21
          },
          end: {
            line: 239,
            column: 75
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 239,
            column: 50
          },
          end: {
            line: 239,
            column: 70
          }
        }, {
          start: {
            line: 239,
            column: 73
          },
          end: {
            line: 239,
            column: 75
          }
        }],
        line: 239
      },
      '30': {
        loc: {
          start: {
            line: 253,
            column: 22
          },
          end: {
            line: 253,
            column: 81
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 253,
            column: 41
          },
          end: {
            line: 253,
            column: 69
          }
        }, {
          start: {
            line: 253,
            column: 73
          },
          end: {
            line: 253,
            column: 81
          }
        }],
        line: 253
      },
      '31': {
        loc: {
          start: {
            line: 253,
            column: 41
          },
          end: {
            line: 253,
            column: 69
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 253,
            column: 54
          },
          end: {
            line: 253,
            column: 60
          }
        }, {
          start: {
            line: 253,
            column: 63
          },
          end: {
            line: 253,
            column: 69
          }
        }],
        line: 253
      },
      '32': {
        loc: {
          start: {
            line: 268,
            column: 12
          },
          end: {
            line: 289,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 268,
            column: 12
          },
          end: {
            line: 289,
            column: 13
          }
        }, {
          start: {
            line: 268,
            column: 12
          },
          end: {
            line: 289,
            column: 13
          }
        }],
        line: 268
      },
      '33': {
        loc: {
          start: {
            line: 278,
            column: 45
          },
          end: {
            line: 278,
            column: 66
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 278,
            column: 45
          },
          end: {
            line: 278,
            column: 60
          }
        }, {
          start: {
            line: 278,
            column: 64
          },
          end: {
            line: 278,
            column: 66
          }
        }],
        line: 278
      },
      '34': {
        loc: {
          start: {
            line: 280,
            column: 14
          },
          end: {
            line: 288,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 280,
            column: 14
          },
          end: {
            line: 288,
            column: 15
          }
        }, {
          start: {
            line: 280,
            column: 14
          },
          end: {
            line: 288,
            column: 15
          }
        }],
        line: 280
      },
      '35': {
        loc: {
          start: {
            line: 285,
            column: 41
          },
          end: {
            line: 285,
            column: 106
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 285,
            column: 72
          },
          end: {
            line: 285,
            column: 74
          }
        }, {
          start: {
            line: 285,
            column: 78
          },
          end: {
            line: 285,
            column: 105
          }
        }],
        line: 285
      },
      '36': {
        loc: {
          start: {
            line: 297,
            column: 14
          },
          end: {
            line: 303,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 297,
            column: 14
          },
          end: {
            line: 303,
            column: 15
          }
        }, {
          start: {
            line: 297,
            column: 14
          },
          end: {
            line: 303,
            column: 15
          }
        }],
        line: 297
      },
      '37': {
        loc: {
          start: {
            line: 299,
            column: 21
          },
          end: {
            line: 303,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 299,
            column: 21
          },
          end: {
            line: 303,
            column: 15
          }
        }, {
          start: {
            line: 299,
            column: 21
          },
          end: {
            line: 303,
            column: 15
          }
        }],
        line: 299
      },
      '38': {
        loc: {
          start: {
            line: 302,
            column: 30
          },
          end: {
            line: 302,
            column: 78
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 302,
            column: 30
          },
          end: {
            line: 302,
            column: 40
          }
        }, {
          start: {
            line: 302,
            column: 44
          },
          end: {
            line: 302,
            column: 60
          }
        }, {
          start: {
            line: 302,
            column: 64
          },
          end: {
            line: 302,
            column: 78
          }
        }],
        line: 302
      },
      '39': {
        loc: {
          start: {
            line: 305,
            column: 56
          },
          end: {
            line: 305,
            column: 91
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 305,
            column: 81
          },
          end: {
            line: 305,
            column: 86
          }
        }, {
          start: {
            line: 305,
            column: 89
          },
          end: {
            line: 305,
            column: 91
          }
        }],
        line: 305
      },
      '40': {
        loc: {
          start: {
            line: 308,
            column: 36
          },
          end: {
            line: 308,
            column: 66
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 308,
            column: 36
          },
          end: {
            line: 308,
            column: 60
          }
        }, {
          start: {
            line: 308,
            column: 64
          },
          end: {
            line: 308,
            column: 66
          }
        }],
        line: 308
      },
      '41': {
        loc: {
          start: {
            line: 311,
            column: 21
          },
          end: {
            line: 311,
            column: 48
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 311,
            column: 21
          },
          end: {
            line: 311,
            column: 40
          }
        }, {
          start: {
            line: 311,
            column: 44
          },
          end: {
            line: 311,
            column: 48
          }
        }],
        line: 311
      },
      '42': {
        loc: {
          start: {
            line: 319,
            column: 24
          },
          end: {
            line: 319,
            column: 172
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 319,
            column: 50
          },
          end: {
            line: 319,
            column: 79
          }
        }, {
          start: {
            line: 319,
            column: 83
          },
          end: {
            line: 319,
            column: 171
          }
        }],
        line: 319
      },
      '43': {
        loc: {
          start: {
            line: 319,
            column: 83
          },
          end: {
            line: 319,
            column: 171
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 319,
            column: 109
          },
          end: {
            line: 319,
            column: 134
          }
        }, {
          start: {
            line: 319,
            column: 137
          },
          end: {
            line: 319,
            column: 171
          }
        }],
        line: 319
      },
      '44': {
        loc: {
          start: {
            line: 322,
            column: 10
          },
          end: {
            line: 326,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 322,
            column: 10
          },
          end: {
            line: 326,
            column: 11
          }
        }, {
          start: {
            line: 322,
            column: 10
          },
          end: {
            line: 326,
            column: 11
          }
        }],
        line: 322
      },
      '45': {
        loc: {
          start: {
            line: 322,
            column: 14
          },
          end: {
            line: 322,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 322,
            column: 14
          },
          end: {
            line: 322,
            column: 37
          }
        }, {
          start: {
            line: 322,
            column: 41
          },
          end: {
            line: 322,
            column: 64
          }
        }],
        line: 322
      },
      '46': {
        loc: {
          start: {
            line: 333,
            column: 36
          },
          end: {
            line: 333,
            column: 107
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 333,
            column: 36
          },
          end: {
            line: 333,
            column: 99
          }
        }, {
          start: {
            line: 333,
            column: 103
          },
          end: {
            line: 333,
            column: 107
          }
        }],
        line: 333
      },
      '47': {
        loc: {
          start: {
            line: 334,
            column: 20
          },
          end: {
            line: 336,
            column: 21
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 334,
            column: 20
          },
          end: {
            line: 336,
            column: 21
          }
        }, {
          start: {
            line: 334,
            column: 20
          },
          end: {
            line: 336,
            column: 21
          }
        }],
        line: 334
      },
      '48': {
        loc: {
          start: {
            line: 338,
            column: 20
          },
          end: {
            line: 340,
            column: 21
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 338,
            column: 20
          },
          end: {
            line: 340,
            column: 21
          }
        }, {
          start: {
            line: 338,
            column: 20
          },
          end: {
            line: 340,
            column: 21
          }
        }],
        line: 338
      },
      '49': {
        loc: {
          start: {
            line: 338,
            column: 24
          },
          end: {
            line: 338,
            column: 78
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 338,
            column: 24
          },
          end: {
            line: 338,
            column: 49
          }
        }, {
          start: {
            line: 338,
            column: 53
          },
          end: {
            line: 338,
            column: 78
          }
        }],
        line: 338
      },
      '50': {
        loc: {
          start: {
            line: 351,
            column: 22
          },
          end: {
            line: 353,
            column: 23
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 351,
            column: 22
          },
          end: {
            line: 353,
            column: 23
          }
        }, {
          start: {
            line: 351,
            column: 22
          },
          end: {
            line: 353,
            column: 23
          }
        }],
        line: 351
      },
      '51': {
        loc: {
          start: {
            line: 351,
            column: 26
          },
          end: {
            line: 351,
            column: 80
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 351,
            column: 26
          },
          end: {
            line: 351,
            column: 51
          }
        }, {
          start: {
            line: 351,
            column: 55
          },
          end: {
            line: 351,
            column: 80
          }
        }],
        line: 351
      },
      '52': {
        loc: {
          start: {
            line: 366,
            column: 18
          },
          end: {
            line: 369,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 366,
            column: 18
          },
          end: {
            line: 369,
            column: 19
          }
        }, {
          start: {
            line: 366,
            column: 18
          },
          end: {
            line: 369,
            column: 19
          }
        }],
        line: 366
      },
      '53': {
        loc: {
          start: {
            line: 388,
            column: 27
          },
          end: {
            line: 388,
            column: 69
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 388,
            column: 64
          },
          end: {
            line: 388,
            column: 65
          }
        }, {
          start: {
            line: 388,
            column: 68
          },
          end: {
            line: 388,
            column: 69
          }
        }],
        line: 388
      },
      '54': {
        loc: {
          start: {
            line: 389,
            column: 20
          },
          end: {
            line: 389,
            column: 71
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 389,
            column: 57
          },
          end: {
            line: 389,
            column: 63
          }
        }, {
          start: {
            line: 389,
            column: 66
          },
          end: {
            line: 389,
            column: 71
          }
        }],
        line: 389
      },
      '55': {
        loc: {
          start: {
            line: 404,
            column: 25
          },
          end: {
            line: 416,
            column: 12
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 404,
            column: 51
          },
          end: {
            line: 409,
            column: 11
          }
        }, {
          start: {
            line: 409,
            column: 15
          },
          end: {
            line: 416,
            column: 11
          }
        }],
        line: 404
      },
      '56': {
        loc: {
          start: {
            line: 409,
            column: 15
          },
          end: {
            line: 416,
            column: 11
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 409,
            column: 41
          },
          end: {
            line: 414,
            column: 11
          }
        }, {
          start: {
            line: 414,
            column: 14
          },
          end: {
            line: 416,
            column: 11
          }
        }],
        line: 409
      },
      '57': {
        loc: {
          start: {
            line: 425,
            column: 8
          },
          end: {
            line: 428,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 425,
            column: 8
          },
          end: {
            line: 428,
            column: 9
          }
        }, {
          start: {
            line: 425,
            column: 8
          },
          end: {
            line: 428,
            column: 9
          }
        }],
        line: 425
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0],
      '50': [0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0],
      '54': [0, 0],
      '55': [0, 0],
      '56': [0, 0],
      '57': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1gjoi0oouv.s[0]++;

angular.module('app').directive('dashboardCombine', function ($timeout) {
  cov_1gjoi0oouv.f[0]++;
  cov_1gjoi0oouv.s[1]++;

  return {
    restrict: 'E',
    scope: {
      idcomponent: '@',
      data: '=',
      analyse: '=',
      titleDashboard: '@',
      height: '@',
      print: '=',
      hidebutton: '='
    },
    templateUrl: 'app/views/common/dashboard-combine.html',

    controller: function controller($scope, $deltadate, $rootScope, $translate, $filter, $LOGINService, $CRUDService) {
      var PATH = (cov_1gjoi0oouv.s[2]++, 'Analyse');
      cov_1gjoi0oouv.s[3]++;
      $scope.ISINCLUDEDRAPPORT = $rootScope.getISINCLUDEDRAPPORT();
      cov_1gjoi0oouv.s[4]++;
      $scope.getCadreLogiqueLabel = function (item) {
        cov_1gjoi0oouv.f[1]++;
        cov_1gjoi0oouv.s[5]++;

        return $rootScope.getCadreLogiqueLabel(item);
      };
      cov_1gjoi0oouv.s[6]++;
      if (!$scope.idcomponent) {
        cov_1gjoi0oouv.b[0][0]++;
        cov_1gjoi0oouv.s[7]++;

        $scope.idcomponent = new Date().getTime();
      } else {
        cov_1gjoi0oouv.b[0][1]++;
      }
      cov_1gjoi0oouv.s[8]++;
      $scope.lastItems = [];
      cov_1gjoi0oouv.s[9]++;
      $scope.filteData_date = function (item, index, items) {
        cov_1gjoi0oouv.f[2]++;
        cov_1gjoi0oouv.s[10]++;

        if (item.id == '0_0_0') {
          cov_1gjoi0oouv.b[1][0]++;
          cov_1gjoi0oouv.s[11]++;

          return true;
        } else {
          cov_1gjoi0oouv.b[1][1]++;
        }
        cov_1gjoi0oouv.s[12]++;
        if (!items[0].open) {
          cov_1gjoi0oouv.b[2][0]++;
          cov_1gjoi0oouv.s[13]++;

          return false;
        } else {
          cov_1gjoi0oouv.b[2][1]++;
        }
        cov_1gjoi0oouv.s[14]++;
        if (item.label.length == 4) {
          cov_1gjoi0oouv.b[3][0]++;
          cov_1gjoi0oouv.s[15]++;

          return true;
        } else {
          cov_1gjoi0oouv.b[3][1]++;
        }
        var year = (cov_1gjoi0oouv.s[16]++, item.id.substring(0, 4));
        cov_1gjoi0oouv.s[17]++;
        for (var i = index - 1; i >= 0; i--) {
          cov_1gjoi0oouv.s[18]++;

          if (year == items[i].label) {
            cov_1gjoi0oouv.b[4][0]++;
            cov_1gjoi0oouv.s[19]++;

            return items[i].open;
          } else {
            cov_1gjoi0oouv.b[4][1]++;
          }
        }
        cov_1gjoi0oouv.s[20]++;
        return false;
      };
      cov_1gjoi0oouv.s[21]++;
      $scope.getCorrectIndex = function (index) {
        cov_1gjoi0oouv.f[3]++;
        cov_1gjoi0oouv.s[22]++;

        switch (index) {
          case 8:
            cov_1gjoi0oouv.b[5][0]++;
          case '8':
            cov_1gjoi0oouv.b[5][1]++;
            cov_1gjoi0oouv.s[23]++;

            return 'BB';
          case 7:
            cov_1gjoi0oouv.b[5][2]++;
          case '7':
            cov_1gjoi0oouv.b[5][3]++;
            cov_1gjoi0oouv.s[24]++;

            return 'CB';
          case 6:
            cov_1gjoi0oouv.b[5][4]++;
          case '6':
            cov_1gjoi0oouv.b[5][5]++;
            cov_1gjoi0oouv.s[25]++;

            return 'EB';
          case 2:
            cov_1gjoi0oouv.b[5][6]++;
          case '2':
            cov_1gjoi0oouv.b[5][7]++;
            cov_1gjoi0oouv.s[26]++;

            return 'EP';
          case 4:
            cov_1gjoi0oouv.b[5][8]++;
          case '4':
            cov_1gjoi0oouv.b[5][9]++;
            cov_1gjoi0oouv.s[27]++;

            return 'PP';
          case 3:
            cov_1gjoi0oouv.b[5][10]++;
          case '3':
            cov_1gjoi0oouv.b[5][11]++;
            cov_1gjoi0oouv.s[28]++;

            return 'RP';
          case 5:
            cov_1gjoi0oouv.b[5][12]++;
          case '5':
            cov_1gjoi0oouv.b[5][13]++;
            cov_1gjoi0oouv.s[29]++;

            return 'TB';
          case 1:
            cov_1gjoi0oouv.b[5][14]++;
          case '1':
            cov_1gjoi0oouv.b[5][15]++;
            cov_1gjoi0oouv.s[30]++;

            return 'TP';
          default:
            cov_1gjoi0oouv.b[5][16]++;
            cov_1gjoi0oouv.s[31]++;

            return 'TP';
        }
      };
      cov_1gjoi0oouv.s[32]++;
      $scope.process = {
        export_data: function export_data(type) {
          cov_1gjoi0oouv.s[33]++;

          if (!$scope.chartDesign) {
            cov_1gjoi0oouv.b[6][0]++;
            cov_1gjoi0oouv.s[34]++;

            return;
          } else {
            cov_1gjoi0oouv.b[6][1]++;
          }
          cov_1gjoi0oouv.s[35]++;
          $scope.chartDesign.title = $scope.analyse.TITLE;
          // $scope.chartDesign.title = $scope.titleDashboard == 1 ? $scope.analyse.TITLE : '';
          cov_1gjoi0oouv.s[36]++;
          $timeout(function () {
            cov_1gjoi0oouv.f[4]++;
            cov_1gjoi0oouv.s[37]++;

            if (type == 1) {
              cov_1gjoi0oouv.b[7][0]++;
              cov_1gjoi0oouv.s[38]++;

              $scope.chartDesign.export('PNG', $scope.analyse.TITLE);
              cov_1gjoi0oouv.s[39]++;
              return;
            } else {
              cov_1gjoi0oouv.b[7][1]++;
            }
            cov_1gjoi0oouv.s[40]++;
            $scope.chartDesign.export('PDF', $scope.analyse.TITLE);
          }, 1500);
        },
        filter_date: function filter_date(item) {
          cov_1gjoi0oouv.s[41]++;

          if ($scope.analyse.AXE != 2) {
            cov_1gjoi0oouv.b[8][0]++;
            cov_1gjoi0oouv.s[42]++;

            return false;
          } else {
            cov_1gjoi0oouv.b[8][1]++;
          }
          cov_1gjoi0oouv.s[43]++;
          if ((cov_1gjoi0oouv.b[10][0]++, item.id == '0_0_0') && (cov_1gjoi0oouv.b[10][1]++, $scope.process.itemCol.id == '')) {
            cov_1gjoi0oouv.b[9][0]++;
            cov_1gjoi0oouv.s[44]++;

            return true;
          } else {
            cov_1gjoi0oouv.b[9][1]++;
          }

          cov_1gjoi0oouv.s[45]++;
          if ((cov_1gjoi0oouv.b[12][0]++, item.id.substring(4, 8) == '_1_1') && (cov_1gjoi0oouv.b[12][1]++, $scope.process.itemCol.id == '0_0_0')) {
            cov_1gjoi0oouv.b[11][0]++;
            cov_1gjoi0oouv.s[46]++;

            return true;
          } else {
            cov_1gjoi0oouv.b[11][1]++;
          }

          cov_1gjoi0oouv.s[47]++;
          if ((cov_1gjoi0oouv.b[14][0]++, $scope.process.itemCol.id.substring(4, 8) == '_1_1') && (cov_1gjoi0oouv.b[14][1]++, item.id.substring(0, 4) == $scope.process.itemCol.label) && (cov_1gjoi0oouv.b[14][2]++, item.id.substring(4, 8) != '_1_1')) {
            cov_1gjoi0oouv.b[13][0]++;
            cov_1gjoi0oouv.s[48]++;

            return true;
          } else {
            cov_1gjoi0oouv.b[13][1]++;
          }

          cov_1gjoi0oouv.s[49]++;
          return false;
        },
        deroule: function deroule(index) {
          var item = (cov_1gjoi0oouv.s[50]++, $scope.process.grapheParams.items[index]);
          // this.itemCol = item;
          cov_1gjoi0oouv.s[51]++;
          if ($scope.analyse.AXE == 3) {
            cov_1gjoi0oouv.b[15][0]++;
            cov_1gjoi0oouv.s[52]++;

            if (item.leaf) {
              cov_1gjoi0oouv.b[16][0]++;
              cov_1gjoi0oouv.s[53]++;

              return;
            } else {
              cov_1gjoi0oouv.b[16][1]++;
            }
          } else {
              cov_1gjoi0oouv.b[15][1]++;
              cov_1gjoi0oouv.s[54]++;
              if ($scope.analyse.AXE == 1) {
                cov_1gjoi0oouv.b[17][0]++;
                cov_1gjoi0oouv.s[55]++;

                if (item.leaf) {
                  cov_1gjoi0oouv.b[18][0]++;
                  cov_1gjoi0oouv.s[56]++;

                  return;
                } else {
                  cov_1gjoi0oouv.b[18][1]++;
                }
              } else {
                  cov_1gjoi0oouv.b[17][1]++;
                  cov_1gjoi0oouv.s[57]++;
                  if (item.leveld == 2) {
                    cov_1gjoi0oouv.b[19][0]++;
                    cov_1gjoi0oouv.s[58]++;

                    return;
                  } else {
                    cov_1gjoi0oouv.b[19][1]++;
                  }
                }
            }cov_1gjoi0oouv.s[59]++;
          $scope.lastItems.push(angular.copy(this.itemCol));
          cov_1gjoi0oouv.s[60]++;
          this.itemCol = item;
          cov_1gjoi0oouv.s[61]++;
          this.loadData();
        },
        enroule: function enroule() {
          cov_1gjoi0oouv.s[62]++;

          if ($scope.lastItems.length == 0) {
            cov_1gjoi0oouv.b[20][0]++;
            cov_1gjoi0oouv.s[63]++;

            return;
          } else {
            cov_1gjoi0oouv.b[20][1]++;
          }
          cov_1gjoi0oouv.s[64]++;
          this.itemCol = $scope.lastItems.pop();
          cov_1gjoi0oouv.s[65]++;
          this.loadData();
        },
        __loadData_planlogique: function __loadData_planlogique() {
          cov_1gjoi0oouv.s[66]++;

          $scope.data = {};
          cov_1gjoi0oouv.s[67]++;
          return {
            a: this.itemCol.CODE_CLC,
            la: this.itemCol.level
          };
        },
        __loadData_plangeographique: function __loadData_plangeographique() {
          cov_1gjoi0oouv.s[68]++;

          $scope.data = {};
          cov_1gjoi0oouv.s[69]++;
          return {
            a: this.itemCol.CODE_LC,
            la: this.itemCol.level
          };
        },
        __loadData_periode: function __loadData_periode() {
          cov_1gjoi0oouv.s[70]++;

          $scope.data = {};
          cov_1gjoi0oouv.s[71]++;
          return {
            a: this.itemCol.id,
            la: 0
          };
        },
        __launchDrawing: function __launchDrawing() {
          // Get Concerne Data
          var params_ = (cov_1gjoi0oouv.s[72]++, {
            data: { get: 'all', p_bksb_: $scope.analyse.ID_PROJET, simple: 1 },
            path: ''
          });

          cov_1gjoi0oouv.s[73]++;
          $scope.amountTotal = 0;
          var id = (cov_1gjoi0oouv.s[74]++, '');
          var items = (cov_1gjoi0oouv.s[75]++, []);
          cov_1gjoi0oouv.s[76]++;
          if ($scope.analyse.AXE == 3) {
            cov_1gjoi0oouv.b[21][0]++;
            cov_1gjoi0oouv.s[77]++;

            id = 'CODE_LC';
            cov_1gjoi0oouv.s[78]++;
            params_.path = 'Localites';
            cov_1gjoi0oouv.s[79]++;
            params_.data.id = this.itemCol.id;
            // items = $filter('filter')($scope.listData_bksb, {CODE_LP: this.itemCol.id}, true);
          } else {
              cov_1gjoi0oouv.b[21][1]++;
              cov_1gjoi0oouv.s[80]++;
              if ($scope.analyse.AXE == 2) {
                cov_1gjoi0oouv.b[22][0]++;
                cov_1gjoi0oouv.s[81]++;

                params_.path = 'Periode';
                cov_1gjoi0oouv.s[82]++;
                params_.data.id = this.itemCol.id;
                cov_1gjoi0oouv.s[83]++;
                id = 'id';
                // items = $scope.dataperiodeGlobal;
              } else {
                  cov_1gjoi0oouv.b[22][1]++;
                  cov_1gjoi0oouv.s[84]++;
                  if ($scope.analyse.AXE == 4) {
                    cov_1gjoi0oouv.b[23][0]++;
                    cov_1gjoi0oouv.s[85]++;

                    params_.path = 'Financement';
                    cov_1gjoi0oouv.s[86]++;
                    id = 'id';
                    // items = $scope.listData_FinancialSource_bksb;
                  } else {
                      cov_1gjoi0oouv.b[23][1]++;
                      cov_1gjoi0oouv.s[87]++;
                      if ($scope.analyse.AXE == 5) {
                        cov_1gjoi0oouv.b[24][0]++;
                        cov_1gjoi0oouv.s[88]++;

                        params_.path = 'CategoriesBudget';
                        cov_1gjoi0oouv.s[89]++;
                        id = 'id';
                        // items = $scope.listData_CategoriesBudget_bksb;
                      } else {
                        cov_1gjoi0oouv.b[24][1]++;
                        cov_1gjoi0oouv.s[90]++;

                        params_.path = 'CadreLogique';
                        cov_1gjoi0oouv.s[91]++;
                        id = 'CODE_CLC';
                        cov_1gjoi0oouv.s[92]++;
                        params_.data.id = this.itemCol.id;
                        // items = $filter('filter')($scope.listData_pl_bksb, {CODE_CLP: this.itemCol.id}, true);
                      }
                    }
                }
            }cov_1gjoi0oouv.s[93]++;
          $CRUDService.getAll(params_.path, params_.data, function (items) {
            cov_1gjoi0oouv.f[5]++;
            cov_1gjoi0oouv.s[94]++;

            for (var i = $scope.analyse.SERIES.length - 1; i >= 0; i--) {
              cov_1gjoi0oouv.s[95]++;

              if (angular.isUndefined($scope.data[i])) {
                cov_1gjoi0oouv.b[25][0]++;
                cov_1gjoi0oouv.s[96]++;

                return;
              } else {
                cov_1gjoi0oouv.b[25][1]++;
              }
              cov_1gjoi0oouv.s[97]++;
              if (angular.isUndefined($scope.data[i].index)) {
                cov_1gjoi0oouv.b[26][0]++;
                cov_1gjoi0oouv.s[98]++;

                return;
              } else {
                cov_1gjoi0oouv.b[26][1]++;
              }
            }
            cov_1gjoi0oouv.s[99]++;
            $scope.isloading = false;
            var params = (cov_1gjoi0oouv.s[100]++, {
              afterExport: function afterExport() {
                cov_1gjoi0oouv.s[101]++;

                $scope.chartDesign.title = $scope.titleDashboard == 1 ? (cov_1gjoi0oouv.b[27][0]++, $scope.analyse.TITLE) : (cov_1gjoi0oouv.b[27][1]++, '');
              },

              locale: $LOGINService.getLang(),
              chartMouseClick: function chartMouseClick(args) {
                cov_1gjoi0oouv.s[102]++;
                // https://ej2.syncfusion.com/javascript/demos/src/chart/images/back.png
                if (args.target.indexOf('back') > -1) {
                  cov_1gjoi0oouv.b[28][0]++;
                  cov_1gjoi0oouv.s[103]++;

                  $scope.process.enroule();
                } else {
                  cov_1gjoi0oouv.b[28][1]++;
                }
              },

              // Initializing Primary X and Y Axis
              primaryXAxis: {
                // title: 'Years',
                interval: 1,
                labelIntersectAction: 'Rotate45',
                valueType: 'Category',
                majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
                majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
                lineStyle: { width: 0 }
              },
              primaryYAxis: {
                valueType: 'Double',
                // title: 'Growth',
                /* minimum: -3,
                maximum: 3,
                interval: 1, */
                lineStyle: { width: 0 },
                majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
                minorGridLines: { width: 1 }, minorTickLines: { width: 0
                  // labelFormat: ($scope.analyse.FIELD == 1 || $scope.analyse.FIELD == 5) ? 'p0' : 'n0'
                } },
              chartArea: {
                border: {
                  width: 0
                }
              },
              useGroupingSeparator: true,
              // Initializing Chart Series
              series: [],
              textRender: function textRender(args, a, b) {

                /* if (!args.extraData) {
                  args.extraData = $filter('number')(args.text);
                }
                args.text = args.point.x + ' (' + args.extraData + (($scope.analyse.FIELD == 1 || $scope.analyse.FIELD == 5) ? '%' : '') + ')'; */
              },

              width: '100%',
              height: '90%',
              // Initializing Chart title
              title: $scope.titleDashboard == 1 ? (cov_1gjoi0oouv.b[29][0]++, $scope.analyse.TITLE) : (cov_1gjoi0oouv.b[29][1]++, ''),
              // crosshair: {enable: true, lineType: 'Vertical'},
              // Enable Legend
              legendSettings: {
                visible: true
              },
              // selectionMode: 'Series',
              pointClick: function pointClick(args) {
                cov_1gjoi0oouv.s[104]++;

                $scope.process.deroule(args.pointIndex);
              },

              tooltip: { enable: true, shared: false }
            });
            cov_1gjoi0oouv.s[105]++;
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = $scope.analyse.SERIES[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var serie = _step.value;

                var _ = (cov_1gjoi0oouv.s[106]++, { // FILL
                  type: serie.TYPE == 1 ? (cov_1gjoi0oouv.b[30][0]++, serie.FILL ? (cov_1gjoi0oouv.b[31][0]++, 'Area') : (cov_1gjoi0oouv.b[31][1]++, 'Line')) : (cov_1gjoi0oouv.b[30][1]++, 'Column'),
                  opacity: 0.5,
                  dataSource: [],
                  // dataLabel: {visible: true, position: 'Outside'},
                  fill: 'rgba(' + serie.COLOR + ',0.7)',
                  xName: 'x', yName: 'y', name: serie.TITLE,
                  width: 3,
                  marker: {
                    visible: serie.TYPE == 1 /* && !serie.FILL */
                    , width: 10,
                    height: 10
                  }
                });
                cov_1gjoi0oouv.s[107]++;
                params.series.push(_);
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            cov_1gjoi0oouv.s[108]++;
            if (params_.data.id == '0_0_0') {
              cov_1gjoi0oouv.b[32][0]++;
              cov_1gjoi0oouv.s[109]++;

              items = items.map(function (period) {
                cov_1gjoi0oouv.f[6]++;
                cov_1gjoi0oouv.s[110]++;

                return {
                  leveld: 1,
                  id: period.ANNEE_PERIOD + '_1_1',
                  label: period.ANNEE_PERIOD
                };
              });
            } else {
              cov_1gjoi0oouv.b[32][1]++;

              var myYear = (cov_1gjoi0oouv.s[111]++, items.filter(function (item) {
                cov_1gjoi0oouv.f[7]++;
                cov_1gjoi0oouv.s[112]++;

                return item.ANNEE_PERIOD == ((cov_1gjoi0oouv.b[33][0]++, params_.data.id) || (cov_1gjoi0oouv.b[33][1]++, '')).split('_')[0];
              })[0]);
              cov_1gjoi0oouv.s[113]++;
              if (myYear) {
                cov_1gjoi0oouv.b[34][0]++;
                cov_1gjoi0oouv.s[114]++;

                items = $deltadate.getYearPeriod(myYear.ANNEE_PERIOD, myYear.DECOUPAGE_PERIOD, false).map(function (item) {
                  cov_1gjoi0oouv.f[8]++;
                  cov_1gjoi0oouv.s[115]++;

                  return {
                    leveld: 2,
                    id: myYear.ANNEE_PERIOD + '_' + item.index + '_' + myYear.DECOUPAGE_PERIOD,
                    label: item.label + (myYear.DECOUPAGE_PERIOD == 1 ? (cov_1gjoi0oouv.b[35][0]++, '') : (cov_1gjoi0oouv.b[35][1]++, ' - ' + myYear.ANNEE_PERIOD))
                  };
                });
              } else {
                cov_1gjoi0oouv.b[34][1]++;
              }
            }
            var index_ = (cov_1gjoi0oouv.s[116]++, $scope.getCorrectIndex($scope.analyse.FIELD));
            cov_1gjoi0oouv.s[117]++;
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
              for (var _iterator2 = items[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var item = _step2.value;
                cov_1gjoi0oouv.s[118]++;

                $scope.process.grapheParams.items.push(item);
                /* $scope.process.grapheParams.labels.push(
                                (id == 'CODE_CLC' ? item.CODE_CLCAFF : (id == 'id' ? (item.label || item.LIBELLE_FIN || item.LABEL_CAT) : item.CODE_LC))
                            ); */
                // const label = (id === 'CODE_CLC' ? item.CODE_CLCAFF + ' - ' + $rootScope.getCadreLogiqueLabel(item) : (id === 'id' ? (item.label || item.LIBELLE_FIN || item.LABEL_CAT) : item.CODE_LC + ' - ' + item.DESCRIPTION_L));
                cov_1gjoi0oouv.s[119]++;
                if (item.CODE_CLCAFF) {
                  cov_1gjoi0oouv.b[36][0]++;
                  cov_1gjoi0oouv.s[120]++;

                  item.label = item.CODE_CLCAFF + ': ' + item.LIBELLE_C_CL;
                } else {
                    cov_1gjoi0oouv.b[36][1]++;
                    cov_1gjoi0oouv.s[121]++;
                    if (item.CODE_LC) {
                      cov_1gjoi0oouv.b[37][0]++;
                      cov_1gjoi0oouv.s[122]++;

                      item.label = item.CODE_LC + ': ' + item.DESCRIPTION_L;
                    } else {
                      cov_1gjoi0oouv.b[37][1]++;
                      cov_1gjoi0oouv.s[123]++;

                      item.label = (cov_1gjoi0oouv.b[38][0]++, item.label) || (cov_1gjoi0oouv.b[38][1]++, item.LIBELLE_FIN) || (cov_1gjoi0oouv.b[38][2]++, item.LABEL_CAT);
                    }
                  }var label = (cov_1gjoi0oouv.s[124]++, item.label.substr(0, 64) + (item.label.length > 64 ? (cov_1gjoi0oouv.b[39][0]++, '...') : (cov_1gjoi0oouv.b[39][1]++, '')));

                cov_1gjoi0oouv.s[125]++;
                for (var _i = $scope.analyse.SERIES.length - 1; _i >= 0; _i--) {
                  var valueGraphe = (cov_1gjoi0oouv.s[126]++, (cov_1gjoi0oouv.b[40][0]++, $scope.data[_i][item[id]]) || (cov_1gjoi0oouv.b[40][1]++, {}));
                  cov_1gjoi0oouv.s[127]++;
                  params.series[_i].dataSource.push({
                    x: label,
                    y: (cov_1gjoi0oouv.b[41][0]++, valueGraphe[index_]) || (cov_1gjoi0oouv.b[41][1]++, null)
                  });
                }
              }
            } catch (err) {
              _didIteratorError2 = true;
              _iteratorError2 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion2 && _iterator2.return) {
                  _iterator2.return();
                }
              } finally {
                if (_didIteratorError2) {
                  throw _iteratorError2;
                }
              }
            }

            cov_1gjoi0oouv.s[128]++;
            $scope.chartDesign = new ej.charts.Chart(params, '#dashboard_combine_graphe__' + $scope.idcomponent);
          });
        },
        loadData: function loadData() {
          var param = (cov_1gjoi0oouv.s[129]++, $scope.analyse.AXE == 1 ? (cov_1gjoi0oouv.b[42][0]++, this.__loadData_planlogique()) : (cov_1gjoi0oouv.b[42][1]++, $scope.analyse.AXE == 2 ? (cov_1gjoi0oouv.b[43][0]++, this.__loadData_periode()) : (cov_1gjoi0oouv.b[43][1]++, this.__loadData_plangeographique())));
          cov_1gjoi0oouv.s[130]++;
          param.p_bksb_ = $scope.analyse.ID_PROJET;
          cov_1gjoi0oouv.s[131]++;
          $scope.data = [];
          cov_1gjoi0oouv.s[132]++;
          if ((cov_1gjoi0oouv.b[45][0]++, $scope.analyse.AXE == 4) || (cov_1gjoi0oouv.b[45][1]++, $scope.analyse.AXE == 5)) {
            cov_1gjoi0oouv.b[44][0]++;
            cov_1gjoi0oouv.s[133]++;

            param.id = 0;
            cov_1gjoi0oouv.s[134]++;
            param.a = '';
            cov_1gjoi0oouv.s[135]++;
            param.la = 0;
          } else {
            cov_1gjoi0oouv.b[44][1]++;
          }
          cov_1gjoi0oouv.s[136]++;
          $scope.process.grapheParams = {
            data: [], data_override: [], labels: [], colors: [], items: [],
            options: {
              tooltips: {
                callbacks: {
                  label: function label(tooltipItem, data) {
                    var myValue = (cov_1gjoi0oouv.s[137]++, (cov_1gjoi0oouv.b[46][0]++, data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]) || (cov_1gjoi0oouv.b[46][1]++, null));
                    cov_1gjoi0oouv.s[138]++;
                    if (myValue === null) {
                      cov_1gjoi0oouv.b[47][0]++;
                      cov_1gjoi0oouv.s[139]++;

                      return null;
                    } else {
                      cov_1gjoi0oouv.b[47][1]++;
                    }
                    var labelValue = (cov_1gjoi0oouv.s[140]++, $filter('number')(myValue));
                    cov_1gjoi0oouv.s[141]++;
                    if ((cov_1gjoi0oouv.b[49][0]++, $scope.analyse.FIELD == 1) || (cov_1gjoi0oouv.b[49][1]++, $scope.analyse.FIELD == 5)) {
                      cov_1gjoi0oouv.b[48][0]++;
                      cov_1gjoi0oouv.s[142]++;

                      labelValue += '%';
                    } else {
                      cov_1gjoi0oouv.b[48][1]++;
                    }
                    cov_1gjoi0oouv.s[143]++;
                    return labelValue;
                  }
                }
              },
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    callback: function callback(value, index, values) {
                      var labelValue = (cov_1gjoi0oouv.s[144]++, $filter('number')(value));
                      cov_1gjoi0oouv.s[145]++;
                      if ((cov_1gjoi0oouv.b[51][0]++, $scope.analyse.FIELD == 1) || (cov_1gjoi0oouv.b[51][1]++, $scope.analyse.FIELD == 5)) {
                        cov_1gjoi0oouv.b[50][0]++;
                        cov_1gjoi0oouv.s[146]++;

                        labelValue += '%';
                      } else {
                        cov_1gjoi0oouv.b[50][1]++;
                      }
                      cov_1gjoi0oouv.s[147]++;
                      return labelValue;
                    }
                  }
                }]
              },
              legend: {
                display: true,
                position: 'top'
              },
              maintainAspectRatio: false,
              onClick: function onClick(params, element) {
                cov_1gjoi0oouv.s[148]++;

                for (var i = $scope.analyse.SERIES.length - 1; i >= 0; i--) {
                  cov_1gjoi0oouv.s[149]++;

                  if (angular.isDefined(element[i])) {
                    cov_1gjoi0oouv.b[52][0]++;
                    cov_1gjoi0oouv.s[150]++;

                    $scope.process.deroule(element[i]._index);
                    cov_1gjoi0oouv.s[151]++;
                    return;
                  } else {
                    cov_1gjoi0oouv.b[52][1]++;
                  }
                }
              },

              elements: {
                line: {
                  fill: false,
                  cubicInterpolationMode: 'monotone',
                  spanGaps: false,
                  tension: 1
                }
              }
            }
          };
          var complement = (cov_1gjoi0oouv.s[152]++, []);
          cov_1gjoi0oouv.s[153]++;
          for (var i = 0; i < $scope.analyse.SERIES.length; i++) {
            cov_1gjoi0oouv.s[154]++;

            $scope.data.push({});
            cov_1gjoi0oouv.s[155]++;
            $scope.process.grapheParams.data.push([]);
            cov_1gjoi0oouv.s[156]++;
            $scope.process.grapheParams.data_override.push({
              label: $scope.analyse.SERIES[i].TITLE,
              borderWidth: $scope.analyse.SERIES[i].TYPE == 1 ? (cov_1gjoi0oouv.b[53][0]++, 3) : (cov_1gjoi0oouv.b[53][1]++, 1),
              type: $scope.analyse.SERIES[i].TYPE == 1 ? (cov_1gjoi0oouv.b[54][0]++, 'line') : (cov_1gjoi0oouv.b[54][1]++, 'bar')
            });
            cov_1gjoi0oouv.s[157]++;
            $scope.process.grapheParams.colors.push('rgb(' + $scope.analyse.SERIES[i].COLOR + ')');
            cov_1gjoi0oouv.s[158]++;
            complement.push({ get: 'combine', ida: $scope.analyse.id, i: i });
          }
          cov_1gjoi0oouv.s[159]++;
          $scope.isloading = true;
          cov_1gjoi0oouv.s[160]++;
          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = complement[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var c = _step3.value;
              cov_1gjoi0oouv.s[161]++;

              $CRUDService.getAll(PATH, Object.assign(c, param), function (data) {
                cov_1gjoi0oouv.f[9]++;
                cov_1gjoi0oouv.s[162]++;

                $scope.data[data.index] = data;
                cov_1gjoi0oouv.s[163]++;
                $scope.process.__launchDrawing();
              });
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }
        },
        open: function open() {
          cov_1gjoi0oouv.s[164]++;

          $scope.data = [];
          cov_1gjoi0oouv.s[165]++;
          this.itemCol = $scope.analyse.AXE == 3 ? (cov_1gjoi0oouv.b[55][0]++, {
            level: 0,
            CODE_LC: '',
            id: null,
            CODE_LP: -1
          }) : (cov_1gjoi0oouv.b[55][1]++, $scope.analyse.AXE == 1 ? (cov_1gjoi0oouv.b[56][0]++, {
            level: 0,
            CODE_CLC: '',
            id: null,
            CODE_CLP: -1
          }) : (cov_1gjoi0oouv.b[56][1]++, {
            id: '0_0_0'
          }));
          cov_1gjoi0oouv.s[166]++;
          this.loadData();
        }
      };
      cov_1gjoi0oouv.s[167]++;
      $scope.process.open();

      cov_1gjoi0oouv.s[168]++;
      $scope.$watch(function (scope) {
        cov_1gjoi0oouv.f[10]++;
        cov_1gjoi0oouv.s[169]++;

        return $scope.print;
      }, function (newValue) {
        cov_1gjoi0oouv.f[11]++;
        cov_1gjoi0oouv.s[170]++;

        if (newValue != 0) {
          cov_1gjoi0oouv.b[57][0]++;
          cov_1gjoi0oouv.s[171]++;

          $scope.process.export_data(newValue);
          cov_1gjoi0oouv.s[172]++;
          $scope.print = 0;
        } else {
          cov_1gjoi0oouv.b[57][1]++;
        }
      });
    }
  };
});
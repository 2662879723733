'use strict';

var cov_1zjfdh61ki = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/TypeActivity/TypeActivCtrl.js',
      hash = '5259c6c4b20a73a2b1ccd8f05ba8f358dec95cd3',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/TypeActivity/TypeActivCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 248,
          column: 4
        }
      },
      '1': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 21
        }
      },
      '2': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 10,
          column: 22
        }
      },
      '3': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 27
        }
      },
      '4': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 26
        }
      },
      '5': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 32
        }
      },
      '6': {
        start: {
          line: 14,
          column: 18
        },
        end: {
          line: 14,
          column: 48
        }
      },
      '7': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 43
        }
      },
      '8': {
        start: {
          line: 16,
          column: 2
        },
        end: {
          line: 18,
          column: 3
        }
      },
      '9': {
        start: {
          line: 20,
          column: 2
        },
        end: {
          line: 24,
          column: 4
        }
      },
      '10': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 21,
          column: 22
        }
      },
      '11': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 22,
          column: 24
        }
      },
      '12': {
        start: {
          line: 23,
          column: 4
        },
        end: {
          line: 23,
          column: 21
        }
      },
      '13': {
        start: {
          line: 26,
          column: 2
        },
        end: {
          line: 61,
          column: 4
        }
      },
      '14': {
        start: {
          line: 27,
          column: 4
        },
        end: {
          line: 34,
          column: 5
        }
      },
      '15': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 32,
          column: 8
        }
      },
      '16': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 33,
          column: 51
        }
      },
      '17': {
        start: {
          line: 37,
          column: 4
        },
        end: {
          line: 37,
          column: 44
        }
      },
      '18': {
        start: {
          line: 40,
          column: 4
        },
        end: {
          line: 52,
          column: 5
        }
      },
      '19': {
        start: {
          line: 41,
          column: 4
        },
        end: {
          line: 41,
          column: 42
        }
      },
      '20': {
        start: {
          line: 42,
          column: 4
        },
        end: {
          line: 42,
          column: 50
        }
      },
      '21': {
        start: {
          line: 43,
          column: 4
        },
        end: {
          line: 47,
          column: 7
        }
      },
      '22': {
        start: {
          line: 46,
          column: 6
        },
        end: {
          line: 46,
          column: 67
        }
      },
      '23': {
        start: {
          line: 48,
          column: 4
        },
        end: {
          line: 50,
          column: 7
        }
      },
      '24': {
        start: {
          line: 49,
          column: 6
        },
        end: {
          line: 49,
          column: 45
        }
      },
      '25': {
        start: {
          line: 56,
          column: 4
        },
        end: {
          line: 60,
          column: 6
        }
      },
      '26': {
        start: {
          line: 63,
          column: 2
        },
        end: {
          line: 67,
          column: 4
        }
      },
      '27': {
        start: {
          line: 64,
          column: 4
        },
        end: {
          line: 64,
          column: 29
        }
      },
      '28': {
        start: {
          line: 65,
          column: 4
        },
        end: {
          line: 65,
          column: 31
        }
      },
      '29': {
        start: {
          line: 66,
          column: 4
        },
        end: {
          line: 66,
          column: 44
        }
      },
      '30': {
        start: {
          line: 68,
          column: 2
        },
        end: {
          line: 110,
          column: 4
        }
      },
      '31': {
        start: {
          line: 70,
          column: 4
        },
        end: {
          line: 90,
          column: 5
        }
      },
      '32': {
        start: {
          line: 71,
          column: 6
        },
        end: {
          line: 71,
          column: 48
        }
      },
      '33': {
        start: {
          line: 72,
          column: 6
        },
        end: {
          line: 72,
          column: 53
        }
      },
      '34': {
        start: {
          line: 73,
          column: 6
        },
        end: {
          line: 73,
          column: 44
        }
      },
      '35': {
        start: {
          line: 74,
          column: 6
        },
        end: {
          line: 74,
          column: 44
        }
      },
      '36': {
        start: {
          line: 77,
          column: 6
        },
        end: {
          line: 81,
          column: 7
        }
      },
      '37': {
        start: {
          line: 78,
          column: 8
        },
        end: {
          line: 78,
          column: 49
        }
      },
      '38': {
        start: {
          line: 79,
          column: 8
        },
        end: {
          line: 79,
          column: 54
        }
      },
      '39': {
        start: {
          line: 80,
          column: 8
        },
        end: {
          line: 80,
          column: 15
        }
      },
      '40': {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 87,
          column: 9
        }
      },
      '41': {
        start: {
          line: 86,
          column: 8
        },
        end: {
          line: 86,
          column: 32
        }
      },
      '42': {
        start: {
          line: 92,
          column: 25
        },
        end: {
          line: 97,
          column: 5
        }
      },
      '43': {
        start: {
          line: 98,
          column: 4
        },
        end: {
          line: 98,
          column: 30
        }
      },
      '44': {
        start: {
          line: 99,
          column: 4
        },
        end: {
          line: 99,
          column: 28
        }
      },
      '45': {
        start: {
          line: 100,
          column: 4
        },
        end: {
          line: 109,
          column: 7
        }
      },
      '46': {
        start: {
          line: 101,
          column: 6
        },
        end: {
          line: 107,
          column: 7
        }
      },
      '47': {
        start: {
          line: 102,
          column: 24
        },
        end: {
          line: 102,
          column: 43
        }
      },
      '48': {
        start: {
          line: 103,
          column: 8
        },
        end: {
          line: 103,
          column: 31
        }
      },
      '49': {
        start: {
          line: 104,
          column: 8
        },
        end: {
          line: 104,
          column: 35
        }
      },
      '50': {
        start: {
          line: 105,
          column: 8
        },
        end: {
          line: 105,
          column: 35
        }
      },
      '51': {
        start: {
          line: 106,
          column: 8
        },
        end: {
          line: 106,
          column: 58
        }
      },
      '52': {
        start: {
          line: 108,
          column: 6
        },
        end: {
          line: 108,
          column: 25
        }
      },
      '53': {
        start: {
          line: 112,
          column: 2
        },
        end: {
          line: 148,
          column: 4
        }
      },
      '54': {
        start: {
          line: 113,
          column: 5
        },
        end: {
          line: 147,
          column: 8
        }
      },
      '55': {
        start: {
          line: 125,
          column: 7
        },
        end: {
          line: 127,
          column: 8
        }
      },
      '56': {
        start: {
          line: 126,
          column: 9
        },
        end: {
          line: 126,
          column: 16
        }
      },
      '57': {
        start: {
          line: 128,
          column: 7
        },
        end: {
          line: 128,
          column: 31
        }
      },
      '58': {
        start: {
          line: 129,
          column: 28
        },
        end: {
          line: 134,
          column: 8
        }
      },
      '59': {
        start: {
          line: 135,
          column: 7
        },
        end: {
          line: 146,
          column: 10
        }
      },
      '60': {
        start: {
          line: 136,
          column: 9
        },
        end: {
          line: 143,
          column: 10
        }
      },
      '61': {
        start: {
          line: 137,
          column: 11
        },
        end: {
          line: 140,
          column: 14
        }
      },
      '62': {
        start: {
          line: 141,
          column: 11
        },
        end: {
          line: 141,
          column: 36
        }
      },
      '63': {
        start: {
          line: 142,
          column: 11
        },
        end: {
          line: 142,
          column: 18
        }
      },
      '64': {
        start: {
          line: 144,
          column: 9
        },
        end: {
          line: 144,
          column: 22
        }
      },
      '65': {
        start: {
          line: 145,
          column: 9
        },
        end: {
          line: 145,
          column: 28
        }
      },
      '66': {
        start: {
          line: 150,
          column: 2
        },
        end: {
          line: 159,
          column: 3
        }
      },
      '67': {
        start: {
          line: 151,
          column: 15
        },
        end: {
          line: 151,
          column: 48
        }
      },
      '68': {
        start: {
          line: 153,
          column: 4
        },
        end: {
          line: 156,
          column: 5
        }
      },
      '69': {
        start: {
          line: 154,
          column: 6
        },
        end: {
          line: 154,
          column: 126
        }
      },
      '70': {
        start: {
          line: 158,
          column: 4
        },
        end: {
          line: 158,
          column: 15
        }
      },
      '71': {
        start: {
          line: 161,
          column: 2
        },
        end: {
          line: 163,
          column: 4
        }
      },
      '72': {
        start: {
          line: 165,
          column: 2
        },
        end: {
          line: 247,
          column: 3
        }
      },
      '73': {
        start: {
          line: 176,
          column: 6
        },
        end: {
          line: 176,
          column: 18
        }
      },
      '74': {
        start: {
          line: 179,
          column: 6
        },
        end: {
          line: 179,
          column: 25
        }
      },
      '75': {
        start: {
          line: 180,
          column: 6
        },
        end: {
          line: 180,
          column: 21
        }
      },
      '76': {
        start: {
          line: 181,
          column: 6
        },
        end: {
          line: 181,
          column: 26
        }
      },
      '77': {
        start: {
          line: 182,
          column: 6
        },
        end: {
          line: 182,
          column: 29
        }
      },
      '78': {
        start: {
          line: 183,
          column: 6
        },
        end: {
          line: 183,
          column: 34
        }
      },
      '79': {
        start: {
          line: 184,
          column: 6
        },
        end: {
          line: 184,
          column: 25
        }
      },
      '80': {
        start: {
          line: 191,
          column: 6
        },
        end: {
          line: 191,
          column: 29
        }
      },
      '81': {
        start: {
          line: 192,
          column: 6
        },
        end: {
          line: 192,
          column: 34
        }
      },
      '82': {
        start: {
          line: 194,
          column: 6
        },
        end: {
          line: 198,
          column: 7
        }
      },
      '83': {
        start: {
          line: 195,
          column: 8
        },
        end: {
          line: 195,
          column: 30
        }
      },
      '84': {
        start: {
          line: 196,
          column: 8
        },
        end: {
          line: 196,
          column: 35
        }
      },
      '85': {
        start: {
          line: 197,
          column: 8
        },
        end: {
          line: 197,
          column: 15
        }
      },
      '86': {
        start: {
          line: 199,
          column: 6
        },
        end: {
          line: 202,
          column: 7
        }
      },
      '87': {
        start: {
          line: 200,
          column: 8
        },
        end: {
          line: 200,
          column: 35
        }
      },
      '88': {
        start: {
          line: 201,
          column: 8
        },
        end: {
          line: 201,
          column: 15
        }
      },
      '89': {
        start: {
          line: 204,
          column: 20
        },
        end: {
          line: 206,
          column: 8
        }
      },
      '90': {
        start: {
          line: 205,
          column: 8
        },
        end: {
          line: 205,
          column: 70
        }
      },
      '91': {
        start: {
          line: 207,
          column: 6
        },
        end: {
          line: 211,
          column: 7
        }
      },
      '92': {
        start: {
          line: 208,
          column: 8
        },
        end: {
          line: 208,
          column: 30
        }
      },
      '93': {
        start: {
          line: 210,
          column: 8
        },
        end: {
          line: 210,
          column: 15
        }
      },
      '94': {
        start: {
          line: 213,
          column: 6
        },
        end: {
          line: 213,
          column: 49
        }
      },
      '95': {
        start: {
          line: 215,
          column: 6
        },
        end: {
          line: 220,
          column: 7
        }
      },
      '96': {
        start: {
          line: 216,
          column: 8
        },
        end: {
          line: 216,
          column: 51
        }
      },
      '97': {
        start: {
          line: 217,
          column: 8
        },
        end: {
          line: 217,
          column: 35
        }
      },
      '98': {
        start: {
          line: 219,
          column: 8
        },
        end: {
          line: 219,
          column: 15
        }
      },
      '99': {
        start: {
          line: 222,
          column: 6
        },
        end: {
          line: 226,
          column: 9
        }
      },
      '100': {
        start: {
          line: 228,
          column: 6
        },
        end: {
          line: 228,
          column: 21
        }
      },
      '101': {
        start: {
          line: 229,
          column: 6
        },
        end: {
          line: 229,
          column: 26
        }
      },
      '102': {
        start: {
          line: 234,
          column: 6
        },
        end: {
          line: 241,
          column: 9
        }
      },
      '103': {
        start: {
          line: 235,
          column: 8
        },
        end: {
          line: 239,
          column: 9
        }
      },
      '104': {
        start: {
          line: 236,
          column: 10
        },
        end: {
          line: 236,
          column: 49
        }
      },
      '105': {
        start: {
          line: 238,
          column: 11
        },
        end: {
          line: 238,
          column: 24
        }
      },
      '106': {
        start: {
          line: 240,
          column: 8
        },
        end: {
          line: 240,
          column: 20
        }
      },
      '107': {
        start: {
          line: 243,
          column: 6
        },
        end: {
          line: 243,
          column: 21
        }
      },
      '108': {
        start: {
          line: 244,
          column: 6
        },
        end: {
          line: 244,
          column: 26
        }
      },
      '109': {
        start: {
          line: 245,
          column: 6
        },
        end: {
          line: 245,
          column: 25
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 50
          },
          end: {
            line: 2,
            column: 51
          }
        },
        loc: {
          start: {
            line: 2,
            column: 189
          },
          end: {
            line: 248,
            column: 2
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 20,
            column: 18
          },
          end: {
            line: 20,
            column: 19
          }
        },
        loc: {
          start: {
            line: 20,
            column: 30
          },
          end: {
            line: 24,
            column: 3
          }
        },
        line: 20
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 26,
            column: 19
          },
          end: {
            line: 26,
            column: 20
          }
        },
        loc: {
          start: {
            line: 26,
            column: 35
          },
          end: {
            line: 61,
            column: 3
          }
        },
        line: 26
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 43,
            column: 45
          },
          end: {
            line: 43,
            column: 46
          }
        },
        loc: {
          start: {
            line: 43,
            column: 65
          },
          end: {
            line: 47,
            column: 5
          }
        },
        line: 43
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 63,
            column: 21
          },
          end: {
            line: 63,
            column: 22
          }
        },
        loc: {
          start: {
            line: 63,
            column: 33
          },
          end: {
            line: 67,
            column: 3
          }
        },
        line: 63
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 68,
            column: 24
          },
          end: {
            line: 68,
            column: 25
          }
        },
        loc: {
          start: {
            line: 68,
            column: 36
          },
          end: {
            line: 110,
            column: 3
          }
        },
        line: 68
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 83,
            column: 47
          },
          end: {
            line: 83,
            column: 48
          }
        },
        loc: {
          start: {
            line: 83,
            column: 67
          },
          end: {
            line: 87,
            column: 7
          }
        },
        line: 83
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 100,
            column: 97
          },
          end: {
            line: 100,
            column: 98
          }
        },
        loc: {
          start: {
            line: 100,
            column: 105
          },
          end: {
            line: 109,
            column: 5
          }
        },
        line: 100
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 112,
            column: 25
          },
          end: {
            line: 112,
            column: 26
          }
        },
        loc: {
          start: {
            line: 112,
            column: 41
          },
          end: {
            line: 148,
            column: 3
          }
        },
        line: 112
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 124,
            column: 8
          },
          end: {
            line: 124,
            column: 9
          }
        },
        loc: {
          start: {
            line: 124,
            column: 21
          },
          end: {
            line: 147,
            column: 6
          }
        },
        line: 124
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 135,
            column: 96
          },
          end: {
            line: 135,
            column: 97
          }
        },
        loc: {
          start: {
            line: 135,
            column: 104
          },
          end: {
            line: 146,
            column: 8
          }
        },
        line: 135
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 150,
            column: 42
          },
          end: {
            line: 150,
            column: 43
          }
        },
        loc: {
          start: {
            line: 150,
            column: 54
          },
          end: {
            line: 159,
            column: 3
          }
        },
        line: 150
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 173,
            column: 11
          },
          end: {
            line: 173,
            column: 12
          }
        },
        loc: {
          start: {
            line: 173,
            column: 23
          },
          end: {
            line: 177,
            column: 5
          }
        },
        line: 173
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 178,
            column: 10
          },
          end: {
            line: 178,
            column: 11
          }
        },
        loc: {
          start: {
            line: 178,
            column: 22
          },
          end: {
            line: 185,
            column: 5
          }
        },
        line: 178
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 186,
            column: 13
          },
          end: {
            line: 186,
            column: 14
          }
        },
        loc: {
          start: {
            line: 186,
            column: 25
          },
          end: {
            line: 230,
            column: 5
          }
        },
        line: 186
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 204,
            column: 41
          },
          end: {
            line: 204,
            column: 42
          }
        },
        loc: {
          start: {
            line: 204,
            column: 53
          },
          end: {
            line: 206,
            column: 7
          }
        },
        line: 204
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 231,
            column: 16
          },
          end: {
            line: 231,
            column: 17
          }
        },
        loc: {
          start: {
            line: 231,
            column: 32
          },
          end: {
            line: 246,
            column: 5
          }
        },
        line: 231
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 234,
            column: 43
          },
          end: {
            line: 234,
            column: 44
          }
        },
        loc: {
          start: {
            line: 234,
            column: 55
          },
          end: {
            line: 241,
            column: 7
          }
        },
        line: 234
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 27,
            column: 4
          },
          end: {
            line: 34,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 27,
            column: 4
          },
          end: {
            line: 34,
            column: 5
          }
        }, {
          start: {
            line: 27,
            column: 4
          },
          end: {
            line: 34,
            column: 5
          }
        }],
        line: 27
      },
      '1': {
        loc: {
          start: {
            line: 40,
            column: 4
          },
          end: {
            line: 52,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 40,
            column: 4
          },
          end: {
            line: 52,
            column: 5
          }
        }, {
          start: {
            line: 40,
            column: 4
          },
          end: {
            line: 52,
            column: 5
          }
        }],
        line: 40
      },
      '2': {
        loc: {
          start: {
            line: 40,
            column: 8
          },
          end: {
            line: 40,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 40,
            column: 8
          },
          end: {
            line: 40,
            column: 18
          }
        }, {
          start: {
            line: 40,
            column: 22
          },
          end: {
            line: 40,
            column: 41
          }
        }],
        line: 40
      },
      '3': {
        loc: {
          start: {
            line: 48,
            column: 4
          },
          end: {
            line: 50,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 48,
            column: 4
          },
          end: {
            line: 50,
            column: 7
          }
        }, {
          start: {
            line: 48,
            column: 4
          },
          end: {
            line: 50,
            column: 7
          }
        }],
        line: 48
      },
      '4': {
        loc: {
          start: {
            line: 70,
            column: 4
          },
          end: {
            line: 90,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 70,
            column: 4
          },
          end: {
            line: 90,
            column: 5
          }
        }, {
          start: {
            line: 70,
            column: 4
          },
          end: {
            line: 90,
            column: 5
          }
        }],
        line: 70
      },
      '5': {
        loc: {
          start: {
            line: 77,
            column: 6
          },
          end: {
            line: 81,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 77,
            column: 6
          },
          end: {
            line: 81,
            column: 7
          }
        }, {
          start: {
            line: 77,
            column: 6
          },
          end: {
            line: 81,
            column: 7
          }
        }],
        line: 77
      },
      '6': {
        loc: {
          start: {
            line: 77,
            column: 10
          },
          end: {
            line: 77,
            column: 129
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 77,
            column: 10
          },
          end: {
            line: 77,
            column: 43
          }
        }, {
          start: {
            line: 77,
            column: 47
          },
          end: {
            line: 77,
            column: 85
          }
        }, {
          start: {
            line: 77,
            column: 90
          },
          end: {
            line: 77,
            column: 128
          }
        }],
        line: 77
      },
      '7': {
        loc: {
          start: {
            line: 95,
            column: 10
          },
          end: {
            line: 95,
            column: 65
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 95,
            column: 39
          },
          end: {
            line: 95,
            column: 61
          }
        }, {
          start: {
            line: 95,
            column: 64
          },
          end: {
            line: 95,
            column: 65
          }
        }],
        line: 95
      },
      '8': {
        loc: {
          start: {
            line: 101,
            column: 6
          },
          end: {
            line: 107,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 101,
            column: 6
          },
          end: {
            line: 107,
            column: 7
          }
        }, {
          start: {
            line: 101,
            column: 6
          },
          end: {
            line: 107,
            column: 7
          }
        }],
        line: 101
      },
      '9': {
        loc: {
          start: {
            line: 125,
            column: 7
          },
          end: {
            line: 127,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 125,
            column: 7
          },
          end: {
            line: 127,
            column: 8
          }
        }, {
          start: {
            line: 125,
            column: 7
          },
          end: {
            line: 127,
            column: 8
          }
        }],
        line: 125
      },
      '10': {
        loc: {
          start: {
            line: 136,
            column: 9
          },
          end: {
            line: 143,
            column: 10
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 136,
            column: 9
          },
          end: {
            line: 143,
            column: 10
          }
        }, {
          start: {
            line: 136,
            column: 9
          },
          end: {
            line: 143,
            column: 10
          }
        }],
        line: 136
      },
      '11': {
        loc: {
          start: {
            line: 153,
            column: 4
          },
          end: {
            line: 156,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 153,
            column: 4
          },
          end: {
            line: 156,
            column: 5
          }
        }, {
          start: {
            line: 153,
            column: 4
          },
          end: {
            line: 156,
            column: 5
          }
        }],
        line: 153
      },
      '12': {
        loc: {
          start: {
            line: 153,
            column: 8
          },
          end: {
            line: 153,
            column: 78
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 153,
            column: 8
          },
          end: {
            line: 153,
            column: 38
          }
        }, {
          start: {
            line: 153,
            column: 42
          },
          end: {
            line: 153,
            column: 78
          }
        }],
        line: 153
      },
      '13': {
        loc: {
          start: {
            line: 194,
            column: 6
          },
          end: {
            line: 198,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 194,
            column: 6
          },
          end: {
            line: 198,
            column: 7
          }
        }, {
          start: {
            line: 194,
            column: 6
          },
          end: {
            line: 198,
            column: 7
          }
        }],
        line: 194
      },
      '14': {
        loc: {
          start: {
            line: 194,
            column: 10
          },
          end: {
            line: 194,
            column: 53
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 194,
            column: 11
          },
          end: {
            line: 194,
            column: 26
          }
        }, {
          start: {
            line: 194,
            column: 32
          },
          end: {
            line: 194,
            column: 52
          }
        }],
        line: 194
      },
      '15': {
        loc: {
          start: {
            line: 199,
            column: 6
          },
          end: {
            line: 202,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 199,
            column: 6
          },
          end: {
            line: 202,
            column: 7
          }
        }, {
          start: {
            line: 199,
            column: 6
          },
          end: {
            line: 202,
            column: 7
          }
        }],
        line: 199
      },
      '16': {
        loc: {
          start: {
            line: 199,
            column: 11
          },
          end: {
            line: 199,
            column: 104
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 199,
            column: 11
          },
          end: {
            line: 199,
            column: 26
          }
        }, {
          start: {
            line: 199,
            column: 31
          },
          end: {
            line: 199,
            column: 69
          }
        }, {
          start: {
            line: 199,
            column: 74
          },
          end: {
            line: 199,
            column: 104
          }
        }],
        line: 199
      },
      '17': {
        loc: {
          start: {
            line: 207,
            column: 6
          },
          end: {
            line: 211,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 207,
            column: 6
          },
          end: {
            line: 211,
            column: 7
          }
        }, {
          start: {
            line: 207,
            column: 6
          },
          end: {
            line: 211,
            column: 7
          }
        }],
        line: 207
      },
      '18': {
        loc: {
          start: {
            line: 215,
            column: 6
          },
          end: {
            line: 220,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 215,
            column: 6
          },
          end: {
            line: 220,
            column: 7
          }
        }, {
          start: {
            line: 215,
            column: 6
          },
          end: {
            line: 220,
            column: 7
          }
        }],
        line: 215
      },
      '19': {
        loc: {
          start: {
            line: 235,
            column: 8
          },
          end: {
            line: 239,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 235,
            column: 8
          },
          end: {
            line: 239,
            column: 9
          }
        }, {
          start: {
            line: 235,
            column: 8
          },
          end: {
            line: 239,
            column: 9
          }
        }],
        line: 235
      },
      '20': {
        loc: {
          start: {
            line: 235,
            column: 12
          },
          end: {
            line: 235,
            column: 74
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 235,
            column: 13
          },
          end: {
            line: 235,
            column: 39
          }
        }, {
          start: {
            line: 235,
            column: 45
          },
          end: {
            line: 235,
            column: 73
          }
        }],
        line: 235
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1zjfdh61ki.s[0]++;

angular.module('app').controller('TypeActivCtrl', function ($scope, SweetAlert, $deltadate, $deltaTypeActivity, $translate, $CRUDService, $otherHttpService, $location, $filter, $rootScope, $log) {
  cov_1zjfdh61ki.f[0]++;
  cov_1zjfdh61ki.s[1]++;


  /*$rootScope.processPageRight('2_1');
  if ($rootScope.global_access_page_denied) {
    return;
  }*/

  $scope.add = false;
  cov_1zjfdh61ki.s[2]++;
  $scope.edit = false;
  cov_1zjfdh61ki.s[3]++;
  $scope.btnAction = false;
  cov_1zjfdh61ki.s[4]++;
  $scope.MyDataListe = [];
  cov_1zjfdh61ki.s[5]++;
  $scope.PATH = 'type_activity';
  var project = (cov_1zjfdh61ki.s[6]++, $rootScope.getCurrentProject());
  cov_1zjfdh61ki.s[7]++;
  $deltaTypeActivity.addController($scope);
  cov_1zjfdh61ki.s[8]++;
  $scope.typeActivity = {
    searchText: ""
  };

  cov_1zjfdh61ki.s[9]++;
  $scope.setAdd = function () {
    cov_1zjfdh61ki.f[1]++;
    cov_1zjfdh61ki.s[10]++;

    $scope.add = true;
    cov_1zjfdh61ki.s[11]++;
    $scope.edit = false;
    cov_1zjfdh61ki.s[12]++;
    $scope.setEdit();
  };

  cov_1zjfdh61ki.s[13]++;
  $scope.setEdit = function (item) {
    cov_1zjfdh61ki.f[2]++;
    cov_1zjfdh61ki.s[14]++;

    if (!item) {
      cov_1zjfdh61ki.b[0][0]++;
      cov_1zjfdh61ki.s[15]++;

      item = {
        id: -1,
        LABEL_TYPE: '',
        COLOR: '32,32,32'
      };
      cov_1zjfdh61ki.s[16]++;
      $scope.listData_TypeActivity_bksb.push(item);
    } else {
      cov_1zjfdh61ki.b[0][1]++;
    }
    //$log.log('setEdit');
    //$log.log(item);
    cov_1zjfdh61ki.s[17]++;
    $scope.activity_process.checked = false;
    //$log.log(item.STEPS);

    cov_1zjfdh61ki.s[18]++;
    if ((cov_1zjfdh61ki.b[2][0]++, item.STEPS) && (cov_1zjfdh61ki.b[2][1]++, item.STEPS.length > 0)) {
      cov_1zjfdh61ki.b[1][0]++;
      cov_1zjfdh61ki.s[19]++;

      $scope.activity_process.dataTotal = 0;
      cov_1zjfdh61ki.s[20]++;
      $scope.activity_process.listStep = item.STEPS;
      cov_1zjfdh61ki.s[21]++;
      $scope.activity_process.listStep.forEach(function (element, index) {
        cov_1zjfdh61ki.f[3]++;
        cov_1zjfdh61ki.s[22]++;

        //$log.log('listStep');
        //$log.log(index, element);
        $scope.activity_process.dataTotal += parseInt(element.VALUE);
      });
      cov_1zjfdh61ki.s[23]++;
      if ($scope.activity_process.dataTotal > 0) {
        cov_1zjfdh61ki.b[3][0]++;
        cov_1zjfdh61ki.s[24]++;

        $scope.activity_process.checked = true;
      } else {
        cov_1zjfdh61ki.b[3][1]++;
      }
    } else {
      cov_1zjfdh61ki.b[1][1]++;
    }
    //$log.log($scope.activity_process.dataTotal);
    //$log.log($scope.activity_process.checked);

    cov_1zjfdh61ki.s[25]++;
    $scope.validateItem = {
      LABEL_TYPE: item.LABEL_TYPE,
      COLOR: item.COLOR,
      id: item.id
    };
  };

  cov_1zjfdh61ki.s[26]++;
  $scope.setCancel = function () {
    cov_1zjfdh61ki.f[4]++;
    cov_1zjfdh61ki.s[27]++;

    $scope.isloading = false;
    cov_1zjfdh61ki.s[28]++;
    $scope.validateItem = null;
    cov_1zjfdh61ki.s[29]++;
    $deltaTypeActivity.removeData({ id: -1 });
  };
  cov_1zjfdh61ki.s[30]++;
  $scope.setValidData = function () {
    cov_1zjfdh61ki.f[5]++;
    cov_1zjfdh61ki.s[31]++;


    if (!$scope.activity_process.checked) {
      cov_1zjfdh61ki.b[4][0]++;
      cov_1zjfdh61ki.s[32]++;

      $scope.activity_process.errorStep = false;
      cov_1zjfdh61ki.s[33]++;
      $scope.activity_process.errorStepValue = false;
      cov_1zjfdh61ki.s[34]++;
      $scope.activity_process.dataTotal = 0;
      cov_1zjfdh61ki.s[35]++;
      $scope.activity_process.listStep = [];
    } else {
      cov_1zjfdh61ki.b[4][1]++;
      cov_1zjfdh61ki.s[36]++;


      if ((cov_1zjfdh61ki.b[6][0]++, $scope.activity_process.errorStep) || (cov_1zjfdh61ki.b[6][1]++, $scope.activity_process.errorStepValue) || (cov_1zjfdh61ki.b[6][2]++, $scope.activity_process.dataTotal != 100)) {
        cov_1zjfdh61ki.b[5][0]++;
        cov_1zjfdh61ki.s[37]++;

        $scope.activity_process.errorStep = true;
        cov_1zjfdh61ki.s[38]++;
        $scope.activity_process.errorStepValue = true;
        cov_1zjfdh61ki.s[39]++;
        return;
      } else {
        cov_1zjfdh61ki.b[5][1]++;
      }

      cov_1zjfdh61ki.s[40]++;
      $scope.activity_process.listStep.forEach(function (element, index) {
        cov_1zjfdh61ki.f[6]++;
        cov_1zjfdh61ki.s[41]++;

        //$log.log('listStep');
        //$log.log(index, element);
        element.NIVSETP = index;
      });
    }

    var validateItem = (cov_1zjfdh61ki.s[42]++, {
      LABEL_TYPE: $scope.validateItem.LABEL_TYPE,
      COLOR: $scope.validateItem.COLOR,
      id: $scope.validateItem.id > 0 ? (cov_1zjfdh61ki.b[7][0]++, $scope.validateItem.id) : (cov_1zjfdh61ki.b[7][1]++, 0),
      STEPS: $scope.activity_process.listStep
    });
    cov_1zjfdh61ki.s[43]++;
    $scope.error_code = false;
    cov_1zjfdh61ki.s[44]++;
    $scope.isloading = true;
    cov_1zjfdh61ki.s[45]++;
    $CRUDService.save($scope.PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function (data) {
      cov_1zjfdh61ki.f[7]++;
      cov_1zjfdh61ki.s[46]++;

      if (data > 0) {
        cov_1zjfdh61ki.b[8][0]++;

        var newData = (cov_1zjfdh61ki.s[47]++, validateItem.id > 0);
        cov_1zjfdh61ki.s[48]++;
        validateItem.id = data;
        cov_1zjfdh61ki.s[49]++;
        validateItem.update = true;
        cov_1zjfdh61ki.s[50]++;
        validateItem.delete = true;
        cov_1zjfdh61ki.s[51]++;
        $deltaTypeActivity.addData(validateItem, newData);
      } else {
        cov_1zjfdh61ki.b[8][1]++;
      }
      cov_1zjfdh61ki.s[52]++;
      $scope.setCancel();
    });
  };

  cov_1zjfdh61ki.s[53]++;
  $scope.setDeleteData = function (item) {
    cov_1zjfdh61ki.f[8]++;
    cov_1zjfdh61ki.s[54]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("TYPACTIVITY.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_1zjfdh61ki.f[9]++;
      cov_1zjfdh61ki.s[55]++;

      if (isconfirm) {
        cov_1zjfdh61ki.b[9][0]++;
        cov_1zjfdh61ki.s[56]++;

        return;
      } else {
        cov_1zjfdh61ki.b[9][1]++;
      }
      cov_1zjfdh61ki.s[57]++;
      $scope.isloading = true;
      var validateItem = (cov_1zjfdh61ki.s[58]++, {
        LABEL_TYPE: item.LABEL_TYPE,
        ID_PROJET: item.ID_PROJET,
        COLOR: item.COLOR,
        id: item.id
      });
      cov_1zjfdh61ki.s[59]++;
      $CRUDService.delet($scope.PATH, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_1zjfdh61ki.f[10]++;
        cov_1zjfdh61ki.s[60]++;

        if (data < 0) {
          cov_1zjfdh61ki.b[10][0]++;
          cov_1zjfdh61ki.s[61]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_1zjfdh61ki.s[62]++;
          $scope.isloading = false;
          cov_1zjfdh61ki.s[63]++;
          return;
        } else {
          cov_1zjfdh61ki.b[10][1]++;
        }
        cov_1zjfdh61ki.s[64]++;
        item.id = -1;
        cov_1zjfdh61ki.s[65]++;
        $scope.setCancel();
      });
    });
  };

  cov_1zjfdh61ki.s[66]++;
  $scope.filterlistDataTypeActivityBksb = function () {
    cov_1zjfdh61ki.f[11]++;

    var data = (cov_1zjfdh61ki.s[67]++, $scope.listData_TypeActivity_bksb);

    cov_1zjfdh61ki.s[68]++;
    if ((cov_1zjfdh61ki.b[12][0]++, $scope.typeActivity.searchText) && (cov_1zjfdh61ki.b[12][1]++, $scope.typeActivity.searchText != '')) {
      cov_1zjfdh61ki.b[11][0]++;
      cov_1zjfdh61ki.s[69]++;

      data = $filter('filter')($scope.listData_TypeActivity_bksb, { LABEL_TYPE: $scope.typeActivity.searchText.toLowerCase() });
    } else {
      cov_1zjfdh61ki.b[11][1]++;
    }

    cov_1zjfdh61ki.s[70]++;
    return data;
  };

  cov_1zjfdh61ki.s[71]++;
  $scope.sortableOptions = {
    connectWith: '.connectList'
  };

  cov_1zjfdh61ki.s[72]++;
  $scope.activity_process = {
    checked: false,
    dataTotal: 0,
    step: '',
    stepValue: '',
    errorStep: false,
    errorStepValue: false,
    listStep: [],
    check: function check() {
      cov_1zjfdh61ki.f[12]++;
      cov_1zjfdh61ki.s[73]++;

      //$log.log('check');
      //$log.log(this.checked);
      this.init();
    },
    init: function init() {
      cov_1zjfdh61ki.f[13]++;
      cov_1zjfdh61ki.s[74]++;

      this.dataTotal = 0;
      cov_1zjfdh61ki.s[75]++;
      this.step = "";
      cov_1zjfdh61ki.s[76]++;
      this.stepValue = "";
      cov_1zjfdh61ki.s[77]++;
      this.errorStep = false;
      cov_1zjfdh61ki.s[78]++;
      this.errorStepValue = false;
      cov_1zjfdh61ki.s[79]++;
      this.listStep = [];
    },
    addStep: function addStep() {
      var _this = this;

      cov_1zjfdh61ki.f[14]++;
      cov_1zjfdh61ki.s[80]++;

      //$log.log('addStep');
      //$log.log(this.step);
      //$log.log(this.stepValue);

      this.errorStep = false;
      cov_1zjfdh61ki.s[81]++;
      this.errorStepValue = false;

      cov_1zjfdh61ki.s[82]++;
      if ((cov_1zjfdh61ki.b[14][0]++, this.step == "") || (cov_1zjfdh61ki.b[14][1]++, this.stepValue == "")) {
        cov_1zjfdh61ki.b[13][0]++;
        cov_1zjfdh61ki.s[83]++;

        this.errorStep = true;
        cov_1zjfdh61ki.s[84]++;
        this.errorStepValue = true;
        cov_1zjfdh61ki.s[85]++;
        return;
      } else {
        cov_1zjfdh61ki.b[13][1]++;
      }
      cov_1zjfdh61ki.s[86]++;
      if ((cov_1zjfdh61ki.b[16][0]++, !this.stepValue) || (cov_1zjfdh61ki.b[16][1]++, typeof this.stepValue == 'undefined') || (cov_1zjfdh61ki.b[16][2]++, parseInt(this.stepValue) > 100)) {
        cov_1zjfdh61ki.b[15][0]++;
        cov_1zjfdh61ki.s[87]++;

        this.errorStepValue = true;
        cov_1zjfdh61ki.s[88]++;
        return;
      } else {
        cov_1zjfdh61ki.b[15][1]++;
      }

      var tmpList = (cov_1zjfdh61ki.s[89]++, this.listStep.filter(function (stepItem) {
        cov_1zjfdh61ki.f[15]++;
        cov_1zjfdh61ki.s[90]++;

        return stepItem.STEP.toUpperCase() == _this.step.toUpperCase();
      }));
      cov_1zjfdh61ki.s[91]++;
      if (tmpList.length > 0) {
        cov_1zjfdh61ki.b[17][0]++;
        cov_1zjfdh61ki.s[92]++;

        this.errorStep = true;
        //$log.log(this.step+' -- Existe');
        cov_1zjfdh61ki.s[93]++;
        return;
      } else {
        cov_1zjfdh61ki.b[17][1]++;
      }

      cov_1zjfdh61ki.s[94]++;
      this.dataTotal += parseInt(this.stepValue);
      //$log.log('dataTotal', this.dataTotal);
      cov_1zjfdh61ki.s[95]++;
      if (this.dataTotal > 100) {
        cov_1zjfdh61ki.b[18][0]++;
        cov_1zjfdh61ki.s[96]++;

        this.dataTotal -= parseInt(this.stepValue);
        cov_1zjfdh61ki.s[97]++;
        this.errorStepValue = true;
        //$log.log(this.dataTotal+' > 100');
        cov_1zjfdh61ki.s[98]++;
        return;
      } else {
        cov_1zjfdh61ki.b[18][1]++;
      }

      cov_1zjfdh61ki.s[99]++;
      this.listStep.push({
        NIVSETP: this.listStep.length,
        STEP: this.step,
        VALUE: this.stepValue
      });

      cov_1zjfdh61ki.s[100]++;
      this.step = "";
      cov_1zjfdh61ki.s[101]++;
      this.stepValue = "";
    },
    deleteStep: function deleteStep(item) {
      var _this2 = this;

      cov_1zjfdh61ki.f[16]++;
      cov_1zjfdh61ki.s[102]++;

      //$log.log('deleteStep');
      //$log.log(item);
      this.listStep = this.listStep.filter(function (stepItem) {
        cov_1zjfdh61ki.f[17]++;
        cov_1zjfdh61ki.s[103]++;

        if ((cov_1zjfdh61ki.b[20][0]++, stepItem.STEP == item.STEP) && (cov_1zjfdh61ki.b[20][1]++, stepItem.VALUE == item.VALUE)) {
          cov_1zjfdh61ki.b[19][0]++;
          cov_1zjfdh61ki.s[104]++;

          _this2.dataTotal -= parseInt(item.VALUE);
          //$log.log('dataTotal', this.dataTotal);
          cov_1zjfdh61ki.s[105]++;
          return false;
        } else {
          cov_1zjfdh61ki.b[19][1]++;
        }
        cov_1zjfdh61ki.s[106]++;
        return true;
      });
      //$log.log(this.listStep);
      cov_1zjfdh61ki.s[107]++;
      this.step = "";
      cov_1zjfdh61ki.s[108]++;
      this.stepValue = "";
      cov_1zjfdh61ki.s[109]++;
      this.valid = false;
    }
  };
});
'use strict';

var cov_1hs25yilg4 = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/indicator_portfolio/edit/indicateurEditCtrl.js',
      hash = '97bd49e2323944b30e1b483f528da0abe4e54eff',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/indicator_portfolio/edit/indicateurEditCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 5,
          column: 0
        },
        end: {
          line: 484,
          column: 3
        }
      },
      '1': {
        start: {
          line: 6,
          column: 15
        },
        end: {
          line: 6,
          column: 34
        }
      },
      '2': {
        start: {
          line: 7,
          column: 23
        },
        end: {
          line: 7,
          column: 31
        }
      },
      '3': {
        start: {
          line: 8,
          column: 17
        },
        end: {
          line: 8,
          column: 40
        }
      },
      '4': {
        start: {
          line: 9,
          column: 18
        },
        end: {
          line: 9,
          column: 54
        }
      },
      '5': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 10,
          column: 25
        }
      },
      '6': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 16,
          column: 5
        }
      },
      '7': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 15,
          column: 7
        }
      },
      '8': {
        start: {
          line: 13,
          column: 6
        },
        end: {
          line: 13,
          column: 52
        }
      },
      '9': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 14,
          column: 15
        }
      },
      '10': {
        start: {
          line: 18,
          column: 26
        },
        end: {
          line: 71,
          column: 3
        }
      },
      '11': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 21,
          column: 5
        }
      },
      '12': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 20,
          column: 19
        }
      },
      '13': {
        start: {
          line: 22,
          column: 19
        },
        end: {
          line: 22,
          column: 23
        }
      },
      '14': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 29,
          column: 5
        }
      },
      '15': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 28,
          column: 7
        }
      },
      '16': {
        start: {
          line: 26,
          column: 8
        },
        end: {
          line: 26,
          column: 22
        }
      },
      '17': {
        start: {
          line: 27,
          column: 8
        },
        end: {
          line: 27,
          column: 14
        }
      },
      '18': {
        start: {
          line: 30,
          column: 4
        },
        end: {
          line: 32,
          column: 5
        }
      },
      '19': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 31,
          column: 19
        }
      },
      '20': {
        start: {
          line: 33,
          column: 4
        },
        end: {
          line: 35,
          column: 5
        }
      },
      '21': {
        start: {
          line: 34,
          column: 6
        },
        end: {
          line: 34,
          column: 19
        }
      },
      '22': {
        start: {
          line: 37,
          column: 4
        },
        end: {
          line: 50,
          column: 5
        }
      },
      '23': {
        start: {
          line: 38,
          column: 20
        },
        end: {
          line: 38,
          column: 45
        }
      },
      '24': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 44,
          column: 7
        }
      },
      '25': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 43,
          column: 9
        }
      },
      '26': {
        start: {
          line: 41,
          column: 10
        },
        end: {
          line: 41,
          column: 35
        }
      },
      '27': {
        start: {
          line: 42,
          column: 10
        },
        end: {
          line: 42,
          column: 16
        }
      },
      '28': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 49,
          column: 7
        }
      },
      '29': {
        start: {
          line: 46,
          column: 8
        },
        end: {
          line: 48,
          column: 9
        }
      },
      '30': {
        start: {
          line: 47,
          column: 10
        },
        end: {
          line: 47,
          column: 64
        }
      },
      '31': {
        start: {
          line: 52,
          column: 4
        },
        end: {
          line: 56,
          column: 5
        }
      },
      '32': {
        start: {
          line: 53,
          column: 6
        },
        end: {
          line: 55,
          column: 7
        }
      },
      '33': {
        start: {
          line: 54,
          column: 8
        },
        end: {
          line: 54,
          column: 63
        }
      },
      '34': {
        start: {
          line: 59,
          column: 4
        },
        end: {
          line: 61,
          column: 5
        }
      },
      '35': {
        start: {
          line: 60,
          column: 6
        },
        end: {
          line: 60,
          column: 60
        }
      },
      '36': {
        start: {
          line: 63,
          column: 4
        },
        end: {
          line: 65,
          column: 5
        }
      },
      '37': {
        start: {
          line: 64,
          column: 6
        },
        end: {
          line: 64,
          column: 62
        }
      },
      '38': {
        start: {
          line: 67,
          column: 4
        },
        end: {
          line: 69,
          column: 5
        }
      },
      '39': {
        start: {
          line: 68,
          column: 6
        },
        end: {
          line: 68,
          column: 61
        }
      },
      '40': {
        start: {
          line: 70,
          column: 4
        },
        end: {
          line: 70,
          column: 17
        }
      },
      '41': {
        start: {
          line: 73,
          column: 2
        },
        end: {
          line: 75,
          column: 4
        }
      },
      '42': {
        start: {
          line: 77,
          column: 2
        },
        end: {
          line: 81,
          column: 4
        }
      },
      '43': {
        start: {
          line: 82,
          column: 2
        },
        end: {
          line: 82,
          column: 23
        }
      },
      '44': {
        start: {
          line: 83,
          column: 2
        },
        end: {
          line: 83,
          column: 20
        }
      },
      '45': {
        start: {
          line: 84,
          column: 2
        },
        end: {
          line: 84,
          column: 24
        }
      },
      '46': {
        start: {
          line: 85,
          column: 2
        },
        end: {
          line: 85,
          column: 18
        }
      },
      '47': {
        start: {
          line: 86,
          column: 2
        },
        end: {
          line: 86,
          column: 36
        }
      },
      '48': {
        start: {
          line: 88,
          column: 2
        },
        end: {
          line: 88,
          column: 29
        }
      },
      '49': {
        start: {
          line: 90,
          column: 2
        },
        end: {
          line: 90,
          column: 29
        }
      },
      '50': {
        start: {
          line: 91,
          column: 2
        },
        end: {
          line: 91,
          column: 26
        }
      },
      '51': {
        start: {
          line: 92,
          column: 2
        },
        end: {
          line: 92,
          column: 28
        }
      },
      '52': {
        start: {
          line: 93,
          column: 2
        },
        end: {
          line: 93,
          column: 22
        }
      },
      '53': {
        start: {
          line: 94,
          column: 2
        },
        end: {
          line: 94,
          column: 23
        }
      },
      '54': {
        start: {
          line: 95,
          column: 2
        },
        end: {
          line: 95,
          column: 33
        }
      },
      '55': {
        start: {
          line: 96,
          column: 2
        },
        end: {
          line: 430,
          column: 4
        }
      },
      '56': {
        start: {
          line: 99,
          column: 6
        },
        end: {
          line: 111,
          column: 7
        }
      },
      '57': {
        start: {
          line: 100,
          column: 8
        },
        end: {
          line: 110,
          column: 9
        }
      },
      '58': {
        start: {
          line: 101,
          column: 10
        },
        end: {
          line: 107,
          column: 11
        }
      },
      '59': {
        start: {
          line: 102,
          column: 12
        },
        end: {
          line: 104,
          column: 13
        }
      },
      '60': {
        start: {
          line: 103,
          column: 14
        },
        end: {
          line: 103,
          column: 75
        }
      },
      '61': {
        start: {
          line: 106,
          column: 12
        },
        end: {
          line: 106,
          column: 73
        }
      },
      '62': {
        start: {
          line: 109,
          column: 10
        },
        end: {
          line: 109,
          column: 71
        }
      },
      '63': {
        start: {
          line: 114,
          column: 6
        },
        end: {
          line: 116,
          column: 7
        }
      },
      '64': {
        start: {
          line: 115,
          column: 8
        },
        end: {
          line: 115,
          column: 66
        }
      },
      '65': {
        start: {
          line: 117,
          column: 6
        },
        end: {
          line: 117,
          column: 25
        }
      },
      '66': {
        start: {
          line: 120,
          column: 6
        },
        end: {
          line: 122,
          column: 7
        }
      },
      '67': {
        start: {
          line: 121,
          column: 8
        },
        end: {
          line: 121,
          column: 20
        }
      },
      '68': {
        start: {
          line: 123,
          column: 6
        },
        end: {
          line: 125,
          column: 7
        }
      },
      '69': {
        start: {
          line: 124,
          column: 8
        },
        end: {
          line: 124,
          column: 20
        }
      },
      '70': {
        start: {
          line: 126,
          column: 6
        },
        end: {
          line: 128,
          column: 7
        }
      },
      '71': {
        start: {
          line: 127,
          column: 8
        },
        end: {
          line: 127,
          column: 20
        }
      },
      '72': {
        start: {
          line: 129,
          column: 6
        },
        end: {
          line: 131,
          column: 7
        }
      },
      '73': {
        start: {
          line: 130,
          column: 8
        },
        end: {
          line: 130,
          column: 20
        }
      },
      '74': {
        start: {
          line: 132,
          column: 6
        },
        end: {
          line: 132,
          column: 19
        }
      },
      '75': {
        start: {
          line: 135,
          column: 6
        },
        end: {
          line: 137,
          column: 7
        }
      },
      '76': {
        start: {
          line: 136,
          column: 8
        },
        end: {
          line: 136,
          column: 15
        }
      },
      '77': {
        start: {
          line: 138,
          column: 6
        },
        end: {
          line: 138,
          column: 25
        }
      },
      '78': {
        start: {
          line: 144,
          column: 6
        },
        end: {
          line: 146,
          column: 7
        }
      },
      '79': {
        start: {
          line: 145,
          column: 8
        },
        end: {
          line: 145,
          column: 21
        }
      },
      '80': {
        start: {
          line: 147,
          column: 6
        },
        end: {
          line: 149,
          column: 7
        }
      },
      '81': {
        start: {
          line: 148,
          column: 8
        },
        end: {
          line: 148,
          column: 20
        }
      },
      '82': {
        start: {
          line: 150,
          column: 6
        },
        end: {
          line: 150,
          column: 29
        }
      },
      '83': {
        start: {
          line: 153,
          column: 6
        },
        end: {
          line: 155,
          column: 7
        }
      },
      '84': {
        start: {
          line: 154,
          column: 8
        },
        end: {
          line: 154,
          column: 21
        }
      },
      '85': {
        start: {
          line: 156,
          column: 6
        },
        end: {
          line: 163,
          column: 7
        }
      },
      '86': {
        start: {
          line: 157,
          column: 8
        },
        end: {
          line: 162,
          column: 9
        }
      },
      '87': {
        start: {
          line: 158,
          column: 10
        },
        end: {
          line: 160,
          column: 11
        }
      },
      '88': {
        start: {
          line: 159,
          column: 12
        },
        end: {
          line: 159,
          column: 58
        }
      },
      '89': {
        start: {
          line: 161,
          column: 10
        },
        end: {
          line: 161,
          column: 32
        }
      },
      '90': {
        start: {
          line: 164,
          column: 6
        },
        end: {
          line: 166,
          column: 7
        }
      },
      '91': {
        start: {
          line: 165,
          column: 8
        },
        end: {
          line: 165,
          column: 54
        }
      },
      '92': {
        start: {
          line: 167,
          column: 6
        },
        end: {
          line: 167,
          column: 29
        }
      },
      '93': {
        start: {
          line: 187,
          column: 6
        },
        end: {
          line: 187,
          column: 33
        }
      },
      '94': {
        start: {
          line: 188,
          column: 6
        },
        end: {
          line: 192,
          column: 7
        }
      },
      '95': {
        start: {
          line: 189,
          column: 8
        },
        end: {
          line: 189,
          column: 22
        }
      },
      '96': {
        start: {
          line: 191,
          column: 8
        },
        end: {
          line: 191,
          column: 55
        }
      },
      '97': {
        start: {
          line: 195,
          column: 17
        },
        end: {
          line: 195,
          column: 47
        }
      },
      '98': {
        start: {
          line: 196,
          column: 6
        },
        end: {
          line: 198,
          column: 7
        }
      },
      '99': {
        start: {
          line: 197,
          column: 8
        },
        end: {
          line: 197,
          column: 46
        }
      },
      '100': {
        start: {
          line: 199,
          column: 6
        },
        end: {
          line: 199,
          column: 37
        }
      },
      '101': {
        start: {
          line: 200,
          column: 6
        },
        end: {
          line: 212,
          column: 9
        }
      },
      '102': {
        start: {
          line: 215,
          column: 6
        },
        end: {
          line: 215,
          column: 21
        }
      },
      '103': {
        start: {
          line: 216,
          column: 19
        },
        end: {
          line: 216,
          column: 38
        }
      },
      '104': {
        start: {
          line: 217,
          column: 6
        },
        end: {
          line: 217,
          column: 26
        }
      },
      '105': {
        start: {
          line: 218,
          column: 6
        },
        end: {
          line: 218,
          column: 26
        }
      },
      '106': {
        start: {
          line: 220,
          column: 6
        },
        end: {
          line: 223,
          column: 7
        }
      },
      '107': {
        start: {
          line: 221,
          column: 8
        },
        end: {
          line: 221,
          column: 68
        }
      },
      '108': {
        start: {
          line: 222,
          column: 8
        },
        end: {
          line: 222,
          column: 68
        }
      },
      '109': {
        start: {
          line: 225,
          column: 6
        },
        end: {
          line: 230,
          column: 7
        }
      },
      '110': {
        start: {
          line: 226,
          column: 8
        },
        end: {
          line: 229,
          column: 9
        }
      },
      '111': {
        start: {
          line: 227,
          column: 10
        },
        end: {
          line: 227,
          column: 29
        }
      },
      '112': {
        start: {
          line: 228,
          column: 10
        },
        end: {
          line: 228,
          column: 16
        }
      },
      '113': {
        start: {
          line: 231,
          column: 6
        },
        end: {
          line: 236,
          column: 7
        }
      },
      '114': {
        start: {
          line: 232,
          column: 8
        },
        end: {
          line: 235,
          column: 9
        }
      },
      '115': {
        start: {
          line: 233,
          column: 10
        },
        end: {
          line: 233,
          column: 38
        }
      },
      '116': {
        start: {
          line: 234,
          column: 10
        },
        end: {
          line: 234,
          column: 16
        }
      },
      '117': {
        start: {
          line: 237,
          column: 6
        },
        end: {
          line: 242,
          column: 7
        }
      },
      '118': {
        start: {
          line: 238,
          column: 8
        },
        end: {
          line: 241,
          column: 9
        }
      },
      '119': {
        start: {
          line: 239,
          column: 10
        },
        end: {
          line: 239,
          column: 39
        }
      },
      '120': {
        start: {
          line: 240,
          column: 10
        },
        end: {
          line: 240,
          column: 16
        }
      },
      '121': {
        start: {
          line: 243,
          column: 6
        },
        end: {
          line: 248,
          column: 7
        }
      },
      '122': {
        start: {
          line: 244,
          column: 8
        },
        end: {
          line: 247,
          column: 9
        }
      },
      '123': {
        start: {
          line: 245,
          column: 10
        },
        end: {
          line: 245,
          column: 35
        }
      },
      '124': {
        start: {
          line: 246,
          column: 10
        },
        end: {
          line: 246,
          column: 16
        }
      },
      '125': {
        start: {
          line: 250,
          column: 20
        },
        end: {
          line: 250,
          column: 22
        }
      },
      '126': {
        start: {
          line: 251,
          column: 6
        },
        end: {
          line: 255,
          column: 7
        }
      },
      '127': {
        start: {
          line: 252,
          column: 8
        },
        end: {
          line: 254,
          column: 9
        }
      },
      '128': {
        start: {
          line: 253,
          column: 10
        },
        end: {
          line: 253,
          column: 27
        }
      },
      '129': {
        start: {
          line: 257,
          column: 6
        },
        end: {
          line: 257,
          column: 35
        }
      },
      '130': {
        start: {
          line: 258,
          column: 6
        },
        end: {
          line: 258,
          column: 30
        }
      },
      '131': {
        start: {
          line: 259,
          column: 6
        },
        end: {
          line: 259,
          column: 32
        }
      },
      '132': {
        start: {
          line: 260,
          column: 6
        },
        end: {
          line: 288,
          column: 9
        }
      },
      '133': {
        start: {
          line: 261,
          column: 8
        },
        end: {
          line: 264,
          column: 9
        }
      },
      '134': {
        start: {
          line: 262,
          column: 10
        },
        end: {
          line: 262,
          column: 43
        }
      },
      '135': {
        start: {
          line: 263,
          column: 10
        },
        end: {
          line: 263,
          column: 43
        }
      },
      '136': {
        start: {
          line: 266,
          column: 8
        },
        end: {
          line: 287,
          column: 9
        }
      },
      '137': {
        start: {
          line: 267,
          column: 10
        },
        end: {
          line: 267,
          column: 34
        }
      },
      '138': {
        start: {
          line: 268,
          column: 10
        },
        end: {
          line: 279,
          column: 13
        }
      },
      '139': {
        start: {
          line: 269,
          column: 12
        },
        end: {
          line: 272,
          column: 13
        }
      },
      '140': {
        start: {
          line: 270,
          column: 14
        },
        end: {
          line: 270,
          column: 40
        }
      },
      '141': {
        start: {
          line: 271,
          column: 14
        },
        end: {
          line: 271,
          column: 74
        }
      },
      '142': {
        start: {
          line: 273,
          column: 12
        },
        end: {
          line: 273,
          column: 45
        }
      },
      '143': {
        start: {
          line: 274,
          column: 12
        },
        end: {
          line: 274,
          column: 37
        }
      },
      '144': {
        start: {
          line: 275,
          column: 12
        },
        end: {
          line: 275,
          column: 77
        }
      },
      '145': {
        start: {
          line: 276,
          column: 12
        },
        end: {
          line: 276,
          column: 39
        }
      },
      '146': {
        start: {
          line: 278,
          column: 12
        },
        end: {
          line: 278,
          column: 33
        }
      },
      '147': {
        start: {
          line: 281,
          column: 10
        },
        end: {
          line: 281,
          column: 35
        }
      },
      '148': {
        start: {
          line: 282,
          column: 10
        },
        end: {
          line: 284,
          column: 11
        }
      },
      '149': {
        start: {
          line: 283,
          column: 12
        },
        end: {
          line: 283,
          column: 71
        }
      },
      '150': {
        start: {
          line: 285,
          column: 10
        },
        end: {
          line: 285,
          column: 75
        }
      },
      '151': {
        start: {
          line: 286,
          column: 10
        },
        end: {
          line: 286,
          column: 37
        }
      },
      '152': {
        start: {
          line: 291,
          column: 6
        },
        end: {
          line: 291,
          column: 32
        }
      },
      '153': {
        start: {
          line: 294,
          column: 20
        },
        end: {
          line: 294,
          column: 39
        }
      },
      '154': {
        start: {
          line: 295,
          column: 6
        },
        end: {
          line: 297,
          column: 7
        }
      },
      '155': {
        start: {
          line: 296,
          column: 8
        },
        end: {
          line: 296,
          column: 15
        }
      },
      '156': {
        start: {
          line: 298,
          column: 6
        },
        end: {
          line: 300,
          column: 7
        }
      },
      '157': {
        start: {
          line: 299,
          column: 8
        },
        end: {
          line: 299,
          column: 15
        }
      },
      '158': {
        start: {
          line: 301,
          column: 6
        },
        end: {
          line: 303,
          column: 7
        }
      },
      '159': {
        start: {
          line: 302,
          column: 8
        },
        end: {
          line: 302,
          column: 15
        }
      },
      '160': {
        start: {
          line: 304,
          column: 6
        },
        end: {
          line: 306,
          column: 7
        }
      },
      '161': {
        start: {
          line: 305,
          column: 8
        },
        end: {
          line: 305,
          column: 15
        }
      },
      '162': {
        start: {
          line: 307,
          column: 6
        },
        end: {
          line: 309,
          column: 7
        }
      },
      '163': {
        start: {
          line: 308,
          column: 8
        },
        end: {
          line: 308,
          column: 15
        }
      },
      '164': {
        start: {
          line: 311,
          column: 19
        },
        end: {
          line: 311,
          column: 53
        }
      },
      '165': {
        start: {
          line: 312,
          column: 6
        },
        end: {
          line: 330,
          column: 7
        }
      },
      '166': {
        start: {
          line: 313,
          column: 8
        },
        end: {
          line: 328,
          column: 11
        }
      },
      '167': {
        start: {
          line: 324,
          column: 10
        },
        end: {
          line: 326,
          column: 11
        }
      },
      '168': {
        start: {
          line: 325,
          column: 12
        },
        end: {
          line: 325,
          column: 19
        }
      },
      '169': {
        start: {
          line: 327,
          column: 10
        },
        end: {
          line: 327,
          column: 44
        }
      },
      '170': {
        start: {
          line: 329,
          column: 8
        },
        end: {
          line: 329,
          column: 15
        }
      },
      '171': {
        start: {
          line: 331,
          column: 6
        },
        end: {
          line: 331,
          column: 40
        }
      },
      '172': {
        start: {
          line: 334,
          column: 6
        },
        end: {
          line: 336,
          column: 7
        }
      },
      '173': {
        start: {
          line: 335,
          column: 8
        },
        end: {
          line: 335,
          column: 39
        }
      },
      '174': {
        start: {
          line: 337,
          column: 19
        },
        end: {
          line: 337,
          column: 22
        }
      },
      '175': {
        start: {
          line: 338,
          column: 6
        },
        end: {
          line: 342,
          column: 7
        }
      },
      '176': {
        start: {
          line: 339,
          column: 8
        },
        end: {
          line: 341,
          column: 9
        }
      },
      '177': {
        start: {
          line: 340,
          column: 10
        },
        end: {
          line: 340,
          column: 25
        }
      },
      '178': {
        start: {
          line: 343,
          column: 6
        },
        end: {
          line: 343,
          column: 19
        }
      },
      '179': {
        start: {
          line: 346,
          column: 19
        },
        end: {
          line: 358,
          column: 7
        }
      },
      '180': {
        start: {
          line: 359,
          column: 6
        },
        end: {
          line: 359,
          column: 30
        }
      },
      '181': {
        start: {
          line: 360,
          column: 6
        },
        end: {
          line: 393,
          column: 9
        }
      },
      '182': {
        start: {
          line: 361,
          column: 8
        },
        end: {
          line: 361,
          column: 33
        }
      },
      '183': {
        start: {
          line: 362,
          column: 8
        },
        end: {
          line: 391,
          column: 9
        }
      },
      '184': {
        start: {
          line: 363,
          column: 22
        },
        end: {
          line: 363,
          column: 24
        }
      },
      '185': {
        start: {
          line: 364,
          column: 10
        },
        end: {
          line: 389,
          column: 11
        }
      },
      '186': {
        start: {
          line: 368,
          column: 14
        },
        end: {
          line: 368,
          column: 20
        }
      },
      '187': {
        start: {
          line: 373,
          column: 14
        },
        end: {
          line: 373,
          column: 20
        }
      },
      '188': {
        start: {
          line: 377,
          column: 14
        },
        end: {
          line: 377,
          column: 20
        }
      },
      '189': {
        start: {
          line: 382,
          column: 14
        },
        end: {
          line: 382,
          column: 20
        }
      },
      '190': {
        start: {
          line: 386,
          column: 14
        },
        end: {
          line: 386,
          column: 20
        }
      },
      '191': {
        start: {
          line: 390,
          column: 10
        },
        end: {
          line: 390,
          column: 17
        }
      },
      '192': {
        start: {
          line: 392,
          column: 8
        },
        end: {
          line: 392,
          column: 35
        }
      },
      '193': {
        start: {
          line: 396,
          column: 6
        },
        end: {
          line: 428,
          column: 9
        }
      },
      '194': {
        start: {
          line: 409,
          column: 8
        },
        end: {
          line: 411,
          column: 9
        }
      },
      '195': {
        start: {
          line: 410,
          column: 10
        },
        end: {
          line: 410,
          column: 17
        }
      },
      '196': {
        start: {
          line: 412,
          column: 8
        },
        end: {
          line: 412,
          column: 32
        }
      },
      '197': {
        start: {
          line: 413,
          column: 8
        },
        end: {
          line: 427,
          column: 11
        }
      },
      '198': {
        start: {
          line: 414,
          column: 10
        },
        end: {
          line: 421,
          column: 11
        }
      },
      '199': {
        start: {
          line: 415,
          column: 12
        },
        end: {
          line: 418,
          column: 15
        }
      },
      '200': {
        start: {
          line: 419,
          column: 12
        },
        end: {
          line: 419,
          column: 37
        }
      },
      '201': {
        start: {
          line: 420,
          column: 12
        },
        end: {
          line: 420,
          column: 19
        }
      },
      '202': {
        start: {
          line: 422,
          column: 24
        },
        end: {
          line: 422,
          column: 82
        }
      },
      '203': {
        start: {
          line: 423,
          column: 10
        },
        end: {
          line: 425,
          column: 11
        }
      },
      '204': {
        start: {
          line: 424,
          column: 12
        },
        end: {
          line: 424,
          column: 82
        }
      },
      '205': {
        start: {
          line: 426,
          column: 10
        },
        end: {
          line: 426,
          column: 35
        }
      },
      '206': {
        start: {
          line: 432,
          column: 2
        },
        end: {
          line: 436,
          column: 4
        }
      },
      '207': {
        start: {
          line: 433,
          column: 4
        },
        end: {
          line: 435,
          column: 5
        }
      },
      '208': {
        start: {
          line: 434,
          column: 6
        },
        end: {
          line: 434,
          column: 58
        }
      },
      '209': {
        start: {
          line: 438,
          column: 2
        },
        end: {
          line: 445,
          column: 4
        }
      },
      '210': {
        start: {
          line: 439,
          column: 4
        },
        end: {
          line: 444,
          column: 5
        }
      },
      '211': {
        start: {
          line: 440,
          column: 6
        },
        end: {
          line: 442,
          column: 7
        }
      },
      '212': {
        start: {
          line: 441,
          column: 8
        },
        end: {
          line: 441,
          column: 17
        }
      },
      '213': {
        start: {
          line: 443,
          column: 6
        },
        end: {
          line: 443,
          column: 59
        }
      },
      '214': {
        start: {
          line: 447,
          column: 2
        },
        end: {
          line: 479,
          column: 4
        }
      },
      '215': {
        start: {
          line: 449,
          column: 6
        },
        end: {
          line: 458,
          column: 7
        }
      },
      '216': {
        start: {
          line: 450,
          column: 8
        },
        end: {
          line: 450,
          column: 45
        }
      },
      '217': {
        start: {
          line: 452,
          column: 8
        },
        end: {
          line: 457,
          column: 9
        }
      },
      '218': {
        start: {
          line: 453,
          column: 10
        },
        end: {
          line: 456,
          column: 11
        }
      },
      '219': {
        start: {
          line: 454,
          column: 12
        },
        end: {
          line: 454,
          column: 48
        }
      },
      '220': {
        start: {
          line: 455,
          column: 12
        },
        end: {
          line: 455,
          column: 18
        }
      },
      '221': {
        start: {
          line: 461,
          column: 6
        },
        end: {
          line: 466,
          column: 9
        }
      },
      '222': {
        start: {
          line: 469,
          column: 6
        },
        end: {
          line: 469,
          column: 43
        }
      },
      '223': {
        start: {
          line: 470,
          column: 6
        },
        end: {
          line: 477,
          column: 9
        }
      },
      '224': {
        start: {
          line: 480,
          column: 2
        },
        end: {
          line: 482,
          column: 4
        }
      },
      '225': {
        start: {
          line: 483,
          column: 2
        },
        end: {
          line: 483,
          column: 37
        }
      },
      '226': {
        start: {
          line: 487,
          column: 17
        },
        end: {
          line: 487,
          column: 40
        }
      },
      '227': {
        start: {
          line: 488,
          column: 2
        },
        end: {
          line: 498,
          column: 4
        }
      },
      '228': {
        start: {
          line: 489,
          column: 4
        },
        end: {
          line: 496,
          column: 5
        }
      },
      '229': {
        start: {
          line: 490,
          column: 6
        },
        end: {
          line: 492,
          column: 7
        }
      },
      '230': {
        start: {
          line: 491,
          column: 8
        },
        end: {
          line: 491,
          column: 20
        }
      },
      '231': {
        start: {
          line: 493,
          column: 6
        },
        end: {
          line: 495,
          column: 7
        }
      },
      '232': {
        start: {
          line: 494,
          column: 8
        },
        end: {
          line: 494,
          column: 20
        }
      },
      '233': {
        start: {
          line: 497,
          column: 4
        },
        end: {
          line: 497,
          column: 17
        }
      },
      '234': {
        start: {
          line: 499,
          column: 2
        },
        end: {
          line: 515,
          column: 4
        }
      },
      '235': {
        start: {
          line: 500,
          column: 17
        },
        end: {
          line: 500,
          column: 56
        }
      },
      '236': {
        start: {
          line: 501,
          column: 4
        },
        end: {
          line: 514,
          column: 5
        }
      },
      '237': {
        start: {
          line: 502,
          column: 6
        },
        end: {
          line: 506,
          column: 9
        }
      },
      '238': {
        start: {
          line: 503,
          column: 8
        },
        end: {
          line: 503,
          column: 50
        }
      },
      '239': {
        start: {
          line: 504,
          column: 8
        },
        end: {
          line: 504,
          column: 79
        }
      },
      '240': {
        start: {
          line: 505,
          column: 8
        },
        end: {
          line: 505,
          column: 44
        }
      },
      '241': {
        start: {
          line: 508,
          column: 6
        },
        end: {
          line: 511,
          column: 7
        }
      },
      '242': {
        start: {
          line: 509,
          column: 21
        },
        end: {
          line: 509,
          column: 95
        }
      },
      '243': {
        start: {
          line: 510,
          column: 8
        },
        end: {
          line: 510,
          column: 57
        }
      },
      '244': {
        start: {
          line: 512,
          column: 6
        },
        end: {
          line: 512,
          column: 77
        }
      },
      '245': {
        start: {
          line: 513,
          column: 6
        },
        end: {
          line: 513,
          column: 42
        }
      },
      '246': {
        start: {
          line: 516,
          column: 2
        },
        end: {
          line: 518,
          column: 4
        }
      },
      '247': {
        start: {
          line: 517,
          column: 4
        },
        end: {
          line: 517,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 5,
            column: 64
          },
          end: {
            line: 5,
            column: 65
          }
        },
        loc: {
          start: {
            line: 5,
            column: 231
          },
          end: {
            line: 484,
            column: 1
          }
        },
        line: 5
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 11,
            column: 56
          },
          end: {
            line: 11,
            column: 57
          }
        },
        loc: {
          start: {
            line: 11,
            column: 64
          },
          end: {
            line: 16,
            column: 3
          }
        },
        line: 11
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 12,
            column: 33
          },
          end: {
            line: 12,
            column: 34
          }
        },
        loc: {
          start: {
            line: 12,
            column: 38
          },
          end: {
            line: 15,
            column: 5
          }
        },
        line: 12
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 18,
            column: 26
          },
          end: {
            line: 18,
            column: 27
          }
        },
        loc: {
          start: {
            line: 18,
            column: 42
          },
          end: {
            line: 71,
            column: 3
          }
        },
        line: 18
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 260,
            column: 92
          },
          end: {
            line: 260,
            column: 93
          }
        },
        loc: {
          start: {
            line: 260,
            column: 102
          },
          end: {
            line: 288,
            column: 7
          }
        },
        line: 260
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 268,
            column: 68
          },
          end: {
            line: 268,
            column: 69
          }
        },
        loc: {
          start: {
            line: 268,
            column: 76
          },
          end: {
            line: 279,
            column: 11
          }
        },
        line: 268
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 323,
            column: 11
          },
          end: {
            line: 323,
            column: 12
          }
        },
        loc: {
          start: {
            line: 323,
            column: 24
          },
          end: {
            line: 328,
            column: 9
          }
        },
        line: 323
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 360,
            column: 159
          },
          end: {
            line: 360,
            column: 160
          }
        },
        loc: {
          start: {
            line: 360,
            column: 167
          },
          end: {
            line: 393,
            column: 7
          }
        },
        line: 360
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 408,
            column: 9
          },
          end: {
            line: 408,
            column: 10
          }
        },
        loc: {
          start: {
            line: 408,
            column: 22
          },
          end: {
            line: 428,
            column: 7
          }
        },
        line: 408
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 413,
            column: 82
          },
          end: {
            line: 413,
            column: 83
          }
        },
        loc: {
          start: {
            line: 413,
            column: 90
          },
          end: {
            line: 427,
            column: 9
          }
        },
        line: 413
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 432,
            column: 28
          },
          end: {
            line: 432,
            column: 29
          }
        },
        loc: {
          start: {
            line: 432,
            column: 40
          },
          end: {
            line: 436,
            column: 3
          }
        },
        line: 432
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 438,
            column: 30
          },
          end: {
            line: 438,
            column: 31
          }
        },
        loc: {
          start: {
            line: 438,
            column: 42
          },
          end: {
            line: 445,
            column: 3
          }
        },
        line: 438
      },
      '12': {
        name: 'indicatorPortfolioEditCtrlvaleurpossible',
        decl: {
          start: {
            line: 486,
            column: 9
          },
          end: {
            line: 486,
            column: 49
          }
        },
        loc: {
          start: {
            line: 486,
            column: 142
          },
          end: {
            line: 519,
            column: 1
          }
        },
        line: 486
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 488,
            column: 25
          },
          end: {
            line: 488,
            column: 26
          }
        },
        loc: {
          start: {
            line: 488,
            column: 42
          },
          end: {
            line: 498,
            column: 3
          }
        },
        line: 488
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 499,
            column: 34
          },
          end: {
            line: 499,
            column: 35
          }
        },
        loc: {
          start: {
            line: 499,
            column: 46
          },
          end: {
            line: 515,
            column: 3
          }
        },
        line: 499
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 502,
            column: 113
          },
          end: {
            line: 502,
            column: 114
          }
        },
        loc: {
          start: {
            line: 502,
            column: 121
          },
          end: {
            line: 506,
            column: 7
          }
        },
        line: 502
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 516,
            column: 22
          },
          end: {
            line: 516,
            column: 23
          }
        },
        loc: {
          start: {
            line: 516,
            column: 34
          },
          end: {
            line: 518,
            column: 3
          }
        },
        line: 516
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 19,
            column: 4
          },
          end: {
            line: 21,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 19,
            column: 4
          },
          end: {
            line: 21,
            column: 5
          }
        }, {
          start: {
            line: 19,
            column: 4
          },
          end: {
            line: 21,
            column: 5
          }
        }],
        line: 19
      },
      '1': {
        loc: {
          start: {
            line: 25,
            column: 6
          },
          end: {
            line: 28,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 25,
            column: 6
          },
          end: {
            line: 28,
            column: 7
          }
        }, {
          start: {
            line: 25,
            column: 6
          },
          end: {
            line: 28,
            column: 7
          }
        }],
        line: 25
      },
      '2': {
        loc: {
          start: {
            line: 30,
            column: 4
          },
          end: {
            line: 32,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 30,
            column: 4
          },
          end: {
            line: 32,
            column: 5
          }
        }, {
          start: {
            line: 30,
            column: 4
          },
          end: {
            line: 32,
            column: 5
          }
        }],
        line: 30
      },
      '3': {
        loc: {
          start: {
            line: 33,
            column: 4
          },
          end: {
            line: 35,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 33,
            column: 4
          },
          end: {
            line: 35,
            column: 5
          }
        }, {
          start: {
            line: 33,
            column: 4
          },
          end: {
            line: 35,
            column: 5
          }
        }],
        line: 33
      },
      '4': {
        loc: {
          start: {
            line: 33,
            column: 8
          },
          end: {
            line: 33,
            column: 80
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 33,
            column: 8
          },
          end: {
            line: 33,
            column: 42
          }
        }, {
          start: {
            line: 33,
            column: 46
          },
          end: {
            line: 33,
            column: 80
          }
        }],
        line: 33
      },
      '5': {
        loc: {
          start: {
            line: 37,
            column: 4
          },
          end: {
            line: 50,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 37,
            column: 4
          },
          end: {
            line: 50,
            column: 5
          }
        }, {
          start: {
            line: 37,
            column: 4
          },
          end: {
            line: 50,
            column: 5
          }
        }],
        line: 37
      },
      '6': {
        loc: {
          start: {
            line: 40,
            column: 8
          },
          end: {
            line: 43,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 40,
            column: 8
          },
          end: {
            line: 43,
            column: 9
          }
        }, {
          start: {
            line: 40,
            column: 8
          },
          end: {
            line: 43,
            column: 9
          }
        }],
        line: 40
      },
      '7': {
        loc: {
          start: {
            line: 45,
            column: 6
          },
          end: {
            line: 49,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 45,
            column: 6
          },
          end: {
            line: 49,
            column: 7
          }
        }, {
          start: {
            line: 45,
            column: 6
          },
          end: {
            line: 49,
            column: 7
          }
        }],
        line: 45
      },
      '8': {
        loc: {
          start: {
            line: 46,
            column: 8
          },
          end: {
            line: 48,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 46,
            column: 8
          },
          end: {
            line: 48,
            column: 9
          }
        }, {
          start: {
            line: 46,
            column: 8
          },
          end: {
            line: 48,
            column: 9
          }
        }],
        line: 46
      },
      '9': {
        loc: {
          start: {
            line: 46,
            column: 14
          },
          end: {
            line: 46,
            column: 114
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 46,
            column: 15
          },
          end: {
            line: 46,
            column: 27
          }
        }, {
          start: {
            line: 46,
            column: 31
          },
          end: {
            line: 46,
            column: 43
          }
        }, {
          start: {
            line: 46,
            column: 49
          },
          end: {
            line: 46,
            column: 79
          }
        }, {
          start: {
            line: 46,
            column: 83
          },
          end: {
            line: 46,
            column: 113
          }
        }],
        line: 46
      },
      '10': {
        loc: {
          start: {
            line: 52,
            column: 4
          },
          end: {
            line: 56,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 52,
            column: 4
          },
          end: {
            line: 56,
            column: 5
          }
        }, {
          start: {
            line: 52,
            column: 4
          },
          end: {
            line: 56,
            column: 5
          }
        }],
        line: 52
      },
      '11': {
        loc: {
          start: {
            line: 53,
            column: 6
          },
          end: {
            line: 55,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 53,
            column: 6
          },
          end: {
            line: 55,
            column: 7
          }
        }, {
          start: {
            line: 53,
            column: 6
          },
          end: {
            line: 55,
            column: 7
          }
        }],
        line: 53
      },
      '12': {
        loc: {
          start: {
            line: 53,
            column: 12
          },
          end: {
            line: 53,
            column: 150
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 53,
            column: 13
          },
          end: {
            line: 53,
            column: 43
          }
        }, {
          start: {
            line: 53,
            column: 47
          },
          end: {
            line: 53,
            column: 77
          }
        }, {
          start: {
            line: 53,
            column: 83
          },
          end: {
            line: 53,
            column: 114
          }
        }, {
          start: {
            line: 53,
            column: 118
          },
          end: {
            line: 53,
            column: 149
          }
        }],
        line: 53
      },
      '13': {
        loc: {
          start: {
            line: 59,
            column: 4
          },
          end: {
            line: 61,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 59,
            column: 4
          },
          end: {
            line: 61,
            column: 5
          }
        }, {
          start: {
            line: 59,
            column: 4
          },
          end: {
            line: 61,
            column: 5
          }
        }],
        line: 59
      },
      '14': {
        loc: {
          start: {
            line: 63,
            column: 4
          },
          end: {
            line: 65,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 63,
            column: 4
          },
          end: {
            line: 65,
            column: 5
          }
        }, {
          start: {
            line: 63,
            column: 4
          },
          end: {
            line: 65,
            column: 5
          }
        }],
        line: 63
      },
      '15': {
        loc: {
          start: {
            line: 63,
            column: 9
          },
          end: {
            line: 63,
            column: 103
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 63,
            column: 9
          },
          end: {
            line: 63,
            column: 54
          }
        }, {
          start: {
            line: 63,
            column: 58
          },
          end: {
            line: 63,
            column: 103
          }
        }],
        line: 63
      },
      '16': {
        loc: {
          start: {
            line: 67,
            column: 4
          },
          end: {
            line: 69,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 4
          },
          end: {
            line: 69,
            column: 5
          }
        }, {
          start: {
            line: 67,
            column: 4
          },
          end: {
            line: 69,
            column: 5
          }
        }],
        line: 67
      },
      '17': {
        loc: {
          start: {
            line: 99,
            column: 6
          },
          end: {
            line: 111,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 99,
            column: 6
          },
          end: {
            line: 111,
            column: 7
          }
        }, {
          start: {
            line: 99,
            column: 6
          },
          end: {
            line: 111,
            column: 7
          }
        }],
        line: 99
      },
      '18': {
        loc: {
          start: {
            line: 100,
            column: 8
          },
          end: {
            line: 110,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 100,
            column: 8
          },
          end: {
            line: 110,
            column: 9
          }
        }, {
          start: {
            line: 100,
            column: 8
          },
          end: {
            line: 110,
            column: 9
          }
        }],
        line: 100
      },
      '19': {
        loc: {
          start: {
            line: 100,
            column: 12
          },
          end: {
            line: 100,
            column: 106
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 100,
            column: 12
          },
          end: {
            line: 100,
            column: 57
          }
        }, {
          start: {
            line: 100,
            column: 61
          },
          end: {
            line: 100,
            column: 106
          }
        }],
        line: 100
      },
      '20': {
        loc: {
          start: {
            line: 101,
            column: 10
          },
          end: {
            line: 107,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 101,
            column: 10
          },
          end: {
            line: 107,
            column: 11
          }
        }, {
          start: {
            line: 101,
            column: 10
          },
          end: {
            line: 107,
            column: 11
          }
        }],
        line: 101
      },
      '21': {
        loc: {
          start: {
            line: 102,
            column: 12
          },
          end: {
            line: 104,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 102,
            column: 12
          },
          end: {
            line: 104,
            column: 13
          }
        }, {
          start: {
            line: 102,
            column: 12
          },
          end: {
            line: 104,
            column: 13
          }
        }],
        line: 102
      },
      '22': {
        loc: {
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 116,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 116,
            column: 7
          }
        }, {
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 116,
            column: 7
          }
        }],
        line: 114
      },
      '23': {
        loc: {
          start: {
            line: 120,
            column: 6
          },
          end: {
            line: 122,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 120,
            column: 6
          },
          end: {
            line: 122,
            column: 7
          }
        }, {
          start: {
            line: 120,
            column: 6
          },
          end: {
            line: 122,
            column: 7
          }
        }],
        line: 120
      },
      '24': {
        loc: {
          start: {
            line: 123,
            column: 6
          },
          end: {
            line: 125,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 123,
            column: 6
          },
          end: {
            line: 125,
            column: 7
          }
        }, {
          start: {
            line: 123,
            column: 6
          },
          end: {
            line: 125,
            column: 7
          }
        }],
        line: 123
      },
      '25': {
        loc: {
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 128,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 128,
            column: 7
          }
        }, {
          start: {
            line: 126,
            column: 6
          },
          end: {
            line: 128,
            column: 7
          }
        }],
        line: 126
      },
      '26': {
        loc: {
          start: {
            line: 129,
            column: 6
          },
          end: {
            line: 131,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 129,
            column: 6
          },
          end: {
            line: 131,
            column: 7
          }
        }, {
          start: {
            line: 129,
            column: 6
          },
          end: {
            line: 131,
            column: 7
          }
        }],
        line: 129
      },
      '27': {
        loc: {
          start: {
            line: 135,
            column: 6
          },
          end: {
            line: 137,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 135,
            column: 6
          },
          end: {
            line: 137,
            column: 7
          }
        }, {
          start: {
            line: 135,
            column: 6
          },
          end: {
            line: 137,
            column: 7
          }
        }],
        line: 135
      },
      '28': {
        loc: {
          start: {
            line: 144,
            column: 6
          },
          end: {
            line: 146,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 144,
            column: 6
          },
          end: {
            line: 146,
            column: 7
          }
        }, {
          start: {
            line: 144,
            column: 6
          },
          end: {
            line: 146,
            column: 7
          }
        }],
        line: 144
      },
      '29': {
        loc: {
          start: {
            line: 147,
            column: 6
          },
          end: {
            line: 149,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 147,
            column: 6
          },
          end: {
            line: 149,
            column: 7
          }
        }, {
          start: {
            line: 147,
            column: 6
          },
          end: {
            line: 149,
            column: 7
          }
        }],
        line: 147
      },
      '30': {
        loc: {
          start: {
            line: 147,
            column: 10
          },
          end: {
            line: 147,
            column: 104
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 147,
            column: 10
          },
          end: {
            line: 147,
            column: 55
          }
        }, {
          start: {
            line: 147,
            column: 59
          },
          end: {
            line: 147,
            column: 104
          }
        }],
        line: 147
      },
      '31': {
        loc: {
          start: {
            line: 153,
            column: 6
          },
          end: {
            line: 155,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 153,
            column: 6
          },
          end: {
            line: 155,
            column: 7
          }
        }, {
          start: {
            line: 153,
            column: 6
          },
          end: {
            line: 155,
            column: 7
          }
        }],
        line: 153
      },
      '32': {
        loc: {
          start: {
            line: 156,
            column: 6
          },
          end: {
            line: 163,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 156,
            column: 6
          },
          end: {
            line: 163,
            column: 7
          }
        }, {
          start: {
            line: 156,
            column: 6
          },
          end: {
            line: 163,
            column: 7
          }
        }],
        line: 156
      },
      '33': {
        loc: {
          start: {
            line: 156,
            column: 10
          },
          end: {
            line: 156,
            column: 104
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 156,
            column: 10
          },
          end: {
            line: 156,
            column: 55
          }
        }, {
          start: {
            line: 156,
            column: 59
          },
          end: {
            line: 156,
            column: 104
          }
        }],
        line: 156
      },
      '34': {
        loc: {
          start: {
            line: 157,
            column: 8
          },
          end: {
            line: 162,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 157,
            column: 8
          },
          end: {
            line: 162,
            column: 9
          }
        }, {
          start: {
            line: 157,
            column: 8
          },
          end: {
            line: 162,
            column: 9
          }
        }],
        line: 157
      },
      '35': {
        loc: {
          start: {
            line: 157,
            column: 12
          },
          end: {
            line: 157,
            column: 106
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 157,
            column: 12
          },
          end: {
            line: 157,
            column: 57
          }
        }, {
          start: {
            line: 157,
            column: 61
          },
          end: {
            line: 157,
            column: 106
          }
        }],
        line: 157
      },
      '36': {
        loc: {
          start: {
            line: 158,
            column: 10
          },
          end: {
            line: 160,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 158,
            column: 10
          },
          end: {
            line: 160,
            column: 11
          }
        }, {
          start: {
            line: 158,
            column: 10
          },
          end: {
            line: 160,
            column: 11
          }
        }],
        line: 158
      },
      '37': {
        loc: {
          start: {
            line: 158,
            column: 14
          },
          end: {
            line: 158,
            column: 80
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 158,
            column: 14
          },
          end: {
            line: 158,
            column: 61
          }
        }, {
          start: {
            line: 158,
            column: 66
          },
          end: {
            line: 158,
            column: 79
          }
        }],
        line: 158
      },
      '38': {
        loc: {
          start: {
            line: 164,
            column: 6
          },
          end: {
            line: 166,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 164,
            column: 6
          },
          end: {
            line: 166,
            column: 7
          }
        }, {
          start: {
            line: 164,
            column: 6
          },
          end: {
            line: 166,
            column: 7
          }
        }],
        line: 164
      },
      '39': {
        loc: {
          start: {
            line: 164,
            column: 10
          },
          end: {
            line: 164,
            column: 76
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 164,
            column: 10
          },
          end: {
            line: 164,
            column: 57
          }
        }, {
          start: {
            line: 164,
            column: 62
          },
          end: {
            line: 164,
            column: 75
          }
        }],
        line: 164
      },
      '40': {
        loc: {
          start: {
            line: 188,
            column: 6
          },
          end: {
            line: 192,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 188,
            column: 6
          },
          end: {
            line: 192,
            column: 7
          }
        }, {
          start: {
            line: 188,
            column: 6
          },
          end: {
            line: 192,
            column: 7
          }
        }],
        line: 188
      },
      '41': {
        loc: {
          start: {
            line: 196,
            column: 6
          },
          end: {
            line: 198,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 196,
            column: 6
          },
          end: {
            line: 198,
            column: 7
          }
        }, {
          start: {
            line: 196,
            column: 6
          },
          end: {
            line: 198,
            column: 7
          }
        }],
        line: 196
      },
      '42': {
        loc: {
          start: {
            line: 203,
            column: 58
          },
          end: {
            line: 203,
            column: 112
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 203,
            column: 107
          },
          end: {
            line: 203,
            column: 108
          }
        }, {
          start: {
            line: 203,
            column: 111
          },
          end: {
            line: 203,
            column: 112
          }
        }],
        line: 203
      },
      '43': {
        loc: {
          start: {
            line: 203,
            column: 58
          },
          end: {
            line: 203,
            column: 104
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 203,
            column: 58
          },
          end: {
            line: 203,
            column: 79
          }
        }, {
          start: {
            line: 203,
            column: 83
          },
          end: {
            line: 203,
            column: 104
          }
        }],
        line: 203
      },
      '44': {
        loc: {
          start: {
            line: 220,
            column: 6
          },
          end: {
            line: 223,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 220,
            column: 6
          },
          end: {
            line: 223,
            column: 7
          }
        }, {
          start: {
            line: 220,
            column: 6
          },
          end: {
            line: 223,
            column: 7
          }
        }],
        line: 220
      },
      '45': {
        loc: {
          start: {
            line: 226,
            column: 8
          },
          end: {
            line: 229,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 226,
            column: 8
          },
          end: {
            line: 229,
            column: 9
          }
        }, {
          start: {
            line: 226,
            column: 8
          },
          end: {
            line: 229,
            column: 9
          }
        }],
        line: 226
      },
      '46': {
        loc: {
          start: {
            line: 232,
            column: 8
          },
          end: {
            line: 235,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 232,
            column: 8
          },
          end: {
            line: 235,
            column: 9
          }
        }, {
          start: {
            line: 232,
            column: 8
          },
          end: {
            line: 235,
            column: 9
          }
        }],
        line: 232
      },
      '47': {
        loc: {
          start: {
            line: 238,
            column: 8
          },
          end: {
            line: 241,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 238,
            column: 8
          },
          end: {
            line: 241,
            column: 9
          }
        }, {
          start: {
            line: 238,
            column: 8
          },
          end: {
            line: 241,
            column: 9
          }
        }],
        line: 238
      },
      '48': {
        loc: {
          start: {
            line: 244,
            column: 8
          },
          end: {
            line: 247,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 244,
            column: 8
          },
          end: {
            line: 247,
            column: 9
          }
        }, {
          start: {
            line: 244,
            column: 8
          },
          end: {
            line: 247,
            column: 9
          }
        }],
        line: 244
      },
      '49': {
        loc: {
          start: {
            line: 251,
            column: 24
          },
          end: {
            line: 251,
            column: 51
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 251,
            column: 24
          },
          end: {
            line: 251,
            column: 45
          }
        }, {
          start: {
            line: 251,
            column: 49
          },
          end: {
            line: 251,
            column: 51
          }
        }],
        line: 251
      },
      '50': {
        loc: {
          start: {
            line: 252,
            column: 8
          },
          end: {
            line: 254,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 252,
            column: 8
          },
          end: {
            line: 254,
            column: 9
          }
        }, {
          start: {
            line: 252,
            column: 8
          },
          end: {
            line: 254,
            column: 9
          }
        }],
        line: 252
      },
      '51': {
        loc: {
          start: {
            line: 260,
            column: 77
          },
          end: {
            line: 260,
            column: 89
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 260,
            column: 77
          },
          end: {
            line: 260,
            column: 84
          }
        }, {
          start: {
            line: 260,
            column: 88
          },
          end: {
            line: 260,
            column: 89
          }
        }],
        line: 260
      },
      '52': {
        loc: {
          start: {
            line: 266,
            column: 8
          },
          end: {
            line: 287,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 266,
            column: 8
          },
          end: {
            line: 287,
            column: 9
          }
        }, {
          start: {
            line: 266,
            column: 8
          },
          end: {
            line: 287,
            column: 9
          }
        }],
        line: 266
      },
      '53': {
        loc: {
          start: {
            line: 282,
            column: 10
          },
          end: {
            line: 284,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 282,
            column: 10
          },
          end: {
            line: 284,
            column: 11
          }
        }, {
          start: {
            line: 282,
            column: 10
          },
          end: {
            line: 284,
            column: 11
          }
        }],
        line: 282
      },
      '54': {
        loc: {
          start: {
            line: 282,
            column: 14
          },
          end: {
            line: 282,
            column: 94
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 282,
            column: 14
          },
          end: {
            line: 282,
            column: 43
          }
        }, {
          start: {
            line: 282,
            column: 47
          },
          end: {
            line: 282,
            column: 78
          }
        }, {
          start: {
            line: 282,
            column: 82
          },
          end: {
            line: 282,
            column: 94
          }
        }],
        line: 282
      },
      '55': {
        loc: {
          start: {
            line: 295,
            column: 6
          },
          end: {
            line: 297,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 295,
            column: 6
          },
          end: {
            line: 297,
            column: 7
          }
        }, {
          start: {
            line: 295,
            column: 6
          },
          end: {
            line: 297,
            column: 7
          }
        }],
        line: 295
      },
      '56': {
        loc: {
          start: {
            line: 298,
            column: 6
          },
          end: {
            line: 300,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 298,
            column: 6
          },
          end: {
            line: 300,
            column: 7
          }
        }, {
          start: {
            line: 298,
            column: 6
          },
          end: {
            line: 300,
            column: 7
          }
        }],
        line: 298
      },
      '57': {
        loc: {
          start: {
            line: 301,
            column: 6
          },
          end: {
            line: 303,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 301,
            column: 6
          },
          end: {
            line: 303,
            column: 7
          }
        }, {
          start: {
            line: 301,
            column: 6
          },
          end: {
            line: 303,
            column: 7
          }
        }],
        line: 301
      },
      '58': {
        loc: {
          start: {
            line: 304,
            column: 6
          },
          end: {
            line: 306,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 304,
            column: 6
          },
          end: {
            line: 306,
            column: 7
          }
        }, {
          start: {
            line: 304,
            column: 6
          },
          end: {
            line: 306,
            column: 7
          }
        }],
        line: 304
      },
      '59': {
        loc: {
          start: {
            line: 307,
            column: 6
          },
          end: {
            line: 309,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 307,
            column: 6
          },
          end: {
            line: 309,
            column: 7
          }
        }, {
          start: {
            line: 307,
            column: 6
          },
          end: {
            line: 309,
            column: 7
          }
        }],
        line: 307
      },
      '60': {
        loc: {
          start: {
            line: 312,
            column: 6
          },
          end: {
            line: 330,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 312,
            column: 6
          },
          end: {
            line: 330,
            column: 7
          }
        }, {
          start: {
            line: 312,
            column: 6
          },
          end: {
            line: 330,
            column: 7
          }
        }],
        line: 312
      },
      '61': {
        loc: {
          start: {
            line: 324,
            column: 10
          },
          end: {
            line: 326,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 324,
            column: 10
          },
          end: {
            line: 326,
            column: 11
          }
        }, {
          start: {
            line: 324,
            column: 10
          },
          end: {
            line: 326,
            column: 11
          }
        }],
        line: 324
      },
      '62': {
        loc: {
          start: {
            line: 334,
            column: 6
          },
          end: {
            line: 336,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 334,
            column: 6
          },
          end: {
            line: 336,
            column: 7
          }
        }, {
          start: {
            line: 334,
            column: 6
          },
          end: {
            line: 336,
            column: 7
          }
        }],
        line: 334
      },
      '63': {
        loc: {
          start: {
            line: 339,
            column: 8
          },
          end: {
            line: 341,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 339,
            column: 8
          },
          end: {
            line: 341,
            column: 9
          }
        }, {
          start: {
            line: 339,
            column: 8
          },
          end: {
            line: 341,
            column: 9
          }
        }],
        line: 339
      },
      '64': {
        loc: {
          start: {
            line: 339,
            column: 12
          },
          end: {
            line: 339,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 339,
            column: 12
          },
          end: {
            line: 339,
            column: 21
          }
        }, {
          start: {
            line: 339,
            column: 25
          },
          end: {
            line: 339,
            column: 59
          }
        }],
        line: 339
      },
      '65': {
        loc: {
          start: {
            line: 360,
            column: 107
          },
          end: {
            line: 360,
            column: 147
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 360,
            column: 117
          },
          end: {
            line: 360,
            column: 119
          }
        }, {
          start: {
            line: 360,
            column: 122
          },
          end: {
            line: 360,
            column: 147
          }
        }],
        line: 360
      },
      '66': {
        loc: {
          start: {
            line: 362,
            column: 8
          },
          end: {
            line: 391,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 362,
            column: 8
          },
          end: {
            line: 391,
            column: 9
          }
        }, {
          start: {
            line: 362,
            column: 8
          },
          end: {
            line: 391,
            column: 9
          }
        }],
        line: 362
      },
      '67': {
        loc: {
          start: {
            line: 364,
            column: 10
          },
          end: {
            line: 389,
            column: 11
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 365,
            column: 12
          },
          end: {
            line: 365,
            column: 21
          }
        }, {
          start: {
            line: 366,
            column: 12
          },
          end: {
            line: 368,
            column: 20
          }
        }, {
          start: {
            line: 370,
            column: 12
          },
          end: {
            line: 370,
            column: 20
          }
        }, {
          start: {
            line: 371,
            column: 12
          },
          end: {
            line: 373,
            column: 20
          }
        }, {
          start: {
            line: 375,
            column: 12
          },
          end: {
            line: 375,
            column: 20
          }
        }, {
          start: {
            line: 376,
            column: 12
          },
          end: {
            line: 377,
            column: 20
          }
        }, {
          start: {
            line: 379,
            column: 12
          },
          end: {
            line: 379,
            column: 20
          }
        }, {
          start: {
            line: 380,
            column: 12
          },
          end: {
            line: 382,
            column: 20
          }
        }, {
          start: {
            line: 383,
            column: 12
          },
          end: {
            line: 383,
            column: 21
          }
        }, {
          start: {
            line: 384,
            column: 12
          },
          end: {
            line: 386,
            column: 20
          }
        }, {
          start: {
            line: 388,
            column: 12
          },
          end: {
            line: 388,
            column: 20
          }
        }],
        line: 364
      },
      '68': {
        loc: {
          start: {
            line: 409,
            column: 8
          },
          end: {
            line: 411,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 409,
            column: 8
          },
          end: {
            line: 411,
            column: 9
          }
        }, {
          start: {
            line: 409,
            column: 8
          },
          end: {
            line: 411,
            column: 9
          }
        }],
        line: 409
      },
      '69': {
        loc: {
          start: {
            line: 414,
            column: 10
          },
          end: {
            line: 421,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 414,
            column: 10
          },
          end: {
            line: 421,
            column: 11
          }
        }, {
          start: {
            line: 414,
            column: 10
          },
          end: {
            line: 421,
            column: 11
          }
        }],
        line: 414
      },
      '70': {
        loc: {
          start: {
            line: 423,
            column: 10
          },
          end: {
            line: 425,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 423,
            column: 10
          },
          end: {
            line: 425,
            column: 11
          }
        }, {
          start: {
            line: 423,
            column: 10
          },
          end: {
            line: 425,
            column: 11
          }
        }],
        line: 423
      },
      '71': {
        loc: {
          start: {
            line: 440,
            column: 6
          },
          end: {
            line: 442,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 440,
            column: 6
          },
          end: {
            line: 442,
            column: 7
          }
        }, {
          start: {
            line: 440,
            column: 6
          },
          end: {
            line: 442,
            column: 7
          }
        }],
        line: 440
      },
      '72': {
        loc: {
          start: {
            line: 449,
            column: 6
          },
          end: {
            line: 458,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 449,
            column: 6
          },
          end: {
            line: 458,
            column: 7
          }
        }, {
          start: {
            line: 449,
            column: 6
          },
          end: {
            line: 458,
            column: 7
          }
        }],
        line: 449
      },
      '73': {
        loc: {
          start: {
            line: 453,
            column: 10
          },
          end: {
            line: 456,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 453,
            column: 10
          },
          end: {
            line: 456,
            column: 11
          }
        }, {
          start: {
            line: 453,
            column: 10
          },
          end: {
            line: 456,
            column: 11
          }
        }],
        line: 453
      },
      '74': {
        loc: {
          start: {
            line: 490,
            column: 6
          },
          end: {
            line: 492,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 490,
            column: 6
          },
          end: {
            line: 492,
            column: 7
          }
        }, {
          start: {
            line: 490,
            column: 6
          },
          end: {
            line: 492,
            column: 7
          }
        }],
        line: 490
      },
      '75': {
        loc: {
          start: {
            line: 490,
            column: 10
          },
          end: {
            line: 490,
            column: 150
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 490,
            column: 11
          },
          end: {
            line: 490,
            column: 76
          }
        }, {
          start: {
            line: 490,
            column: 81
          },
          end: {
            line: 490,
            column: 124
          }
        }, {
          start: {
            line: 490,
            column: 129
          },
          end: {
            line: 490,
            column: 139
          }
        }, {
          start: {
            line: 490,
            column: 143
          },
          end: {
            line: 490,
            column: 149
          }
        }],
        line: 490
      },
      '76': {
        loc: {
          start: {
            line: 493,
            column: 6
          },
          end: {
            line: 495,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 493,
            column: 6
          },
          end: {
            line: 495,
            column: 7
          }
        }, {
          start: {
            line: 493,
            column: 6
          },
          end: {
            line: 495,
            column: 7
          }
        }],
        line: 493
      },
      '77': {
        loc: {
          start: {
            line: 493,
            column: 10
          },
          end: {
            line: 493,
            column: 146
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 493,
            column: 11
          },
          end: {
            line: 493,
            column: 72
          }
        }, {
          start: {
            line: 493,
            column: 77
          },
          end: {
            line: 493,
            column: 120
          }
        }, {
          start: {
            line: 493,
            column: 125
          },
          end: {
            line: 493,
            column: 135
          }
        }, {
          start: {
            line: 493,
            column: 139
          },
          end: {
            line: 493,
            column: 145
          }
        }],
        line: 493
      },
      '78': {
        loc: {
          start: {
            line: 501,
            column: 4
          },
          end: {
            line: 514,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 501,
            column: 4
          },
          end: {
            line: 514,
            column: 5
          }
        }, {
          start: {
            line: 501,
            column: 4
          },
          end: {
            line: 514,
            column: 5
          }
        }],
        line: 501
      },
      '79': {
        loc: {
          start: {
            line: 508,
            column: 6
          },
          end: {
            line: 511,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 508,
            column: 6
          },
          end: {
            line: 511,
            column: 7
          }
        }, {
          start: {
            line: 508,
            column: 6
          },
          end: {
            line: 511,
            column: 7
          }
        }],
        line: 508
      },
      '80': {
        loc: {
          start: {
            line: 509,
            column: 21
          },
          end: {
            line: 509,
            column: 95
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 509,
            column: 21
          },
          end: {
            line: 509,
            column: 84
          }
        }, {
          start: {
            line: 509,
            column: 88
          },
          end: {
            line: 509,
            column: 95
          }
        }],
        line: 509
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0,
      '242': 0,
      '243': 0,
      '244': 0,
      '245': 0,
      '246': 0,
      '247': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0, 0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0, 0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0],
      '50': [0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0],
      '54': [0, 0, 0],
      '55': [0, 0],
      '56': [0, 0],
      '57': [0, 0],
      '58': [0, 0],
      '59': [0, 0],
      '60': [0, 0],
      '61': [0, 0],
      '62': [0, 0],
      '63': [0, 0],
      '64': [0, 0],
      '65': [0, 0],
      '66': [0, 0],
      '67': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      '68': [0, 0],
      '69': [0, 0],
      '70': [0, 0],
      '71': [0, 0],
      '72': [0, 0],
      '73': [0, 0],
      '74': [0, 0],
      '75': [0, 0, 0, 0],
      '76': [0, 0],
      '77': [0, 0, 0, 0],
      '78': [0, 0],
      '79': [0, 0],
      '80': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1hs25yilg4.s[0]++;
/**
 * Indicator  - controller
 */

angular.module('app').controller('indicatorPortfolioEdit_Ctrl', function ($scope, $stateParams, $state, $deltahttp, $deltaUnite, SweetAlert, $rootScope, $sce, $translate, $CRUDService, $log, $deltadate, $filter, FileUploader, $uibModal) {
  cov_1hs25yilg4.f[0]++;

  var PATH = (cov_1hs25yilg4.s[1]++, 'IndicateursGlobal');
  var PATH_PROJECT = (cov_1hs25yilg4.s[2]++, 'Projet');
  var PATHVP = (cov_1hs25yilg4.s[3]++, 'ValeursPossibleGlobal');
  var VIRGULE = (cov_1hs25yilg4.s[4]++, $translate.instant('COMMON.VIRGULE'));
  cov_1hs25yilg4.s[5]++;
  $scope.listProjet = [];
  cov_1hs25yilg4.s[6]++;
  $CRUDService.getAll(PATH_PROJECT, { get: 'all_short' }, function (data) {
    cov_1hs25yilg4.f[1]++;
    cov_1hs25yilg4.s[7]++;

    $scope.listProjet = data.map(function (d) {
      cov_1hs25yilg4.f[2]++;
      cov_1hs25yilg4.s[8]++;

      d.img = $deltahttp.getProjectRepository(d.id);
      cov_1hs25yilg4.s[9]++;
      return d;
    });
  });

  cov_1hs25yilg4.s[10]++;
  var __checkValidate = function __checkValidate(item) {
    cov_1hs25yilg4.f[3]++;
    cov_1hs25yilg4.s[11]++;

    if (item.id == 0) {
      cov_1hs25yilg4.b[0][0]++;
      cov_1hs25yilg4.s[12]++;

      return '0\t';
    } else {
      cov_1hs25yilg4.b[0][1]++;
    }
    var item_old = (cov_1hs25yilg4.s[13]++, null);
    // Search Old
    cov_1hs25yilg4.s[14]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = $scope.dataIndicateur[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _el = _step.value;
        cov_1hs25yilg4.s[15]++;

        if (_el.id == item.id) {
          cov_1hs25yilg4.b[1][0]++;
          cov_1hs25yilg4.s[16]++;

          item_old = _el;
          cov_1hs25yilg4.s[17]++;
          break;
        } else {
          cov_1hs25yilg4.b[1][1]++;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    cov_1hs25yilg4.s[18]++;
    if (item_old == null) {
      cov_1hs25yilg4.b[2][0]++;
      cov_1hs25yilg4.s[19]++;

      return '0\t';
    } else {
      cov_1hs25yilg4.b[2][1]++;
    }
    cov_1hs25yilg4.s[20]++;
    if ((cov_1hs25yilg4.b[4][0]++, item_old.Type_indicateur_G.id == 2) || (cov_1hs25yilg4.b[4][1]++, item_old.Type_indicateur_G.id == 4)) {
      cov_1hs25yilg4.b[3][0]++;
      cov_1hs25yilg4.s[21]++;

      return '0\t';
    } else {
      cov_1hs25yilg4.b[3][1]++;
    }
    // Unité
    cov_1hs25yilg4.s[22]++;
    if (item.ID_UNITE.id != item_old.ID_UNITE) {
      cov_1hs25yilg4.b[5][0]++;

      var oldType = (cov_1hs25yilg4.s[23]++, item.ID_UNITE.TYPE_UNITES);
      cov_1hs25yilg4.s[24]++;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = $scope.listData_unites_bksb[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var el = _step2.value;
          cov_1hs25yilg4.s[25]++;

          if (el.id == item_old.ID_UNITE) {
            cov_1hs25yilg4.b[6][0]++;
            cov_1hs25yilg4.s[26]++;

            oldType = el.TYPE_UNITES;
            cov_1hs25yilg4.s[27]++;
            break;
          } else {
            cov_1hs25yilg4.b[6][1]++;
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      cov_1hs25yilg4.s[28]++;
      if (oldType != item.ID_UNITE.TYPE_UNITES) {
        cov_1hs25yilg4.b[7][0]++;
        cov_1hs25yilg4.s[29]++;

        if (!(((cov_1hs25yilg4.b[9][0]++, oldType == 1) || (cov_1hs25yilg4.b[9][1]++, oldType == 2)) && ((cov_1hs25yilg4.b[9][2]++, item.ID_UNITE.TYPE_UNITES == 1) || (cov_1hs25yilg4.b[9][3]++, item.ID_UNITE.TYPE_UNITES == 2)))) {
          cov_1hs25yilg4.b[8][0]++;
          cov_1hs25yilg4.s[30]++;

          return '1\t' + $translate.instant('INDICATOR.MOD_UN');
        } else {
          cov_1hs25yilg4.b[8][1]++;
        }
      } else {
        cov_1hs25yilg4.b[7][1]++;
      }
    } else {
      cov_1hs25yilg4.b[5][1]++;
    }
    // Classe
    cov_1hs25yilg4.s[31]++;
    if (item.Type_indicateur_G.id != item_old.Type_indicateur_G) {
      cov_1hs25yilg4.b[10][0]++;
      cov_1hs25yilg4.s[32]++;

      if (!(((cov_1hs25yilg4.b[12][0]++, item.Type_indicateur_G.id == 1) || (cov_1hs25yilg4.b[12][1]++, item.Type_indicateur_G.id == 3)) && ((cov_1hs25yilg4.b[12][2]++, item_old.Type_indicateur_G == 1) || (cov_1hs25yilg4.b[12][3]++, item_old.Type_indicateur_G == 3)))) {
        cov_1hs25yilg4.b[11][0]++;
        cov_1hs25yilg4.s[33]++;

        return '2\t' + $translate.instant('INDICATOR.MOD_CLS');
      } else {
        cov_1hs25yilg4.b[11][1]++;
      }
    } else {
      cov_1hs25yilg4.b[10][1]++;
    }

    // Niveau Géographique
    cov_1hs25yilg4.s[34]++;
    if (item.CODE_NG.id != item_old.CODE_NG) {
      cov_1hs25yilg4.b[13][0]++;
      cov_1hs25yilg4.s[35]++;

      return '3\t' + $translate.instant('INDICATOR.MOD_NG');
    } else {
      cov_1hs25yilg4.b[13][1]++;
    }
    // Date Debut date Fin
    cov_1hs25yilg4.s[36]++;
    if ((cov_1hs25yilg4.b[15][0]++, item_old.DEB_EVAL_INDIC < item.DEB_EVAL_INDIC) || (cov_1hs25yilg4.b[15][1]++, item_old.FIN_EVAL_INDIC > item.FIN_EVAL_INDIC)) {
      cov_1hs25yilg4.b[14][0]++;
      cov_1hs25yilg4.s[37]++;

      return '4\t' + $translate.instant('INDICATOR.MOD_DATE');
    } else {
      cov_1hs25yilg4.b[14][1]++;
    }
    // Périodicité
    cov_1hs25yilg4.s[38]++;
    if (item.PERIOD_INDIC.id != item_old.PERIOD_INDIC) {
      cov_1hs25yilg4.b[16][0]++;
      cov_1hs25yilg4.s[39]++;

      return '5\t' + $translate.instant('INDICATOR.MOD_PER');
    } else {
      cov_1hs25yilg4.b[16][1]++;
    }
    cov_1hs25yilg4.s[40]++;
    return '0\t';
  };

  cov_1hs25yilg4.s[41]++;
  $scope.displayPicture = {
    value: true
  };

  cov_1hs25yilg4.s[42]++;
  $scope.controleLabel = {
    DISPLAY: $translate.instant('INDICATOR.DISPLAY_PL'),
    ADD: $translate.instant('COMMON.ADD'),
    SELECT_UNIT: $translate.instant('COMMON.SELECT_UNIT')
  };
  cov_1hs25yilg4.s[43]++;
  $scope.viewIndex = 1;
  cov_1hs25yilg4.s[44]++;
  $scope.lastId = 0;
  cov_1hs25yilg4.s[45]++;
  $scope.lastResult = 1;
  cov_1hs25yilg4.s[46]++;
  $scope.page = 1;
  cov_1hs25yilg4.s[47]++;
  $deltaUnite.addController($scope);
  // $scope.listData_unites_bksb = $stateParams.listUnit;
  cov_1hs25yilg4.s[48]++;
  $scope.dataIndicateur = [];
  // Mise à jour Indicateurs
  cov_1hs25yilg4.s[49]++;
  $scope.selectedItem = null;
  cov_1hs25yilg4.s[50]++;
  $scope.itemClass = null;
  cov_1hs25yilg4.s[51]++;
  $scope.itemAgrrega = null;
  cov_1hs25yilg4.s[52]++;
  $scope.lastIDVP = 0;
  cov_1hs25yilg4.s[53]++;
  $scope.isadd = false;
  cov_1hs25yilg4.s[54]++;
  $scope.listValeurPossible = [];
  cov_1hs25yilg4.s[55]++;
  $scope.indicateur = {
    changeClass: function changeClass() {
      cov_1hs25yilg4.s[56]++;

      // Simple et numérique
      if ($scope.selectedItem.Type_indicateur_G) {
        cov_1hs25yilg4.b[17][0]++;
        cov_1hs25yilg4.s[57]++;

        if ((cov_1hs25yilg4.b[19][0]++, $scope.selectedItem.Type_indicateur_G.id == 1) || (cov_1hs25yilg4.b[19][1]++, $scope.selectedItem.Type_indicateur_G.id == 3)) {
          cov_1hs25yilg4.b[18][0]++;
          cov_1hs25yilg4.s[58]++;

          if ($scope.selectedItem.ID_UNITE.TYPE_UNITES < 3) {
            cov_1hs25yilg4.b[20][0]++;
            cov_1hs25yilg4.s[59]++;

            if ($scope.selectedItem.AGREGATION_INDIC_G.id == 0) {
              cov_1hs25yilg4.b[21][0]++;
              cov_1hs25yilg4.s[60]++;

              $scope.selectedItem.AGREGATION_INDIC_G = this.listAggrega[1];
            } else {
              cov_1hs25yilg4.b[21][1]++;
            }
          } else {
            cov_1hs25yilg4.b[20][1]++;
            cov_1hs25yilg4.s[61]++;

            $scope.selectedItem.AGREGATION_INDIC_G = this.listAggrega[0];
          }
        } else {
          cov_1hs25yilg4.b[18][1]++;
          cov_1hs25yilg4.s[62]++;

          $scope.selectedItem.AGREGATION_INDIC_G = this.listAggrega[0];
        }
      } else {
        cov_1hs25yilg4.b[17][1]++;
      }
    },
    changeUnit: function changeUnit() {
      cov_1hs25yilg4.s[63]++;

      if ($scope.selectedItem.ID_UNITE.TYPE_UNITES >= 3) {
        cov_1hs25yilg4.b[22][0]++;
        cov_1hs25yilg4.s[64]++;

        $scope.selectedItem.Type_indicateur_G = this.listClass[0];
      } else {
        cov_1hs25yilg4.b[22][1]++;
      }
      cov_1hs25yilg4.s[65]++;
      this.changeClass();
    },
    isOtherTab: function isOtherTab(item) {
      cov_1hs25yilg4.s[66]++;

      if (item.ID_UNITE.TYPE_UNITES == 3) {
        cov_1hs25yilg4.b[23][0]++;
        cov_1hs25yilg4.s[67]++;

        return true;
      } else {
        cov_1hs25yilg4.b[23][1]++;
      }
      cov_1hs25yilg4.s[68]++;
      if (item.Type_indicateur_G.id == 2) {
        cov_1hs25yilg4.b[24][0]++;
        cov_1hs25yilg4.s[69]++;

        return true;
      } else {
        cov_1hs25yilg4.b[24][1]++;
      }
      cov_1hs25yilg4.s[70]++;
      if (item.Type_indicateur_G.id == 4) {
        cov_1hs25yilg4.b[25][0]++;
        cov_1hs25yilg4.s[71]++;

        return true;
      } else {
        cov_1hs25yilg4.b[25][1]++;
      }
      cov_1hs25yilg4.s[72]++;
      if (item.SPATIAL_) {
        cov_1hs25yilg4.b[26][0]++;
        cov_1hs25yilg4.s[73]++;

        return true;
      } else {
        cov_1hs25yilg4.b[26][1]++;
      }
      cov_1hs25yilg4.s[74]++;
      return false;
    },
    changepage: function changepage(index, canDo) {
      cov_1hs25yilg4.s[75]++;

      if (!canDo) {
        cov_1hs25yilg4.b[27][0]++;
        cov_1hs25yilg4.s[76]++;

        return;
      } else {
        cov_1hs25yilg4.b[27][1]++;
      }
      cov_1hs25yilg4.s[77]++;
      $scope.tab = index;
      /* if ($scope.tab == 3) {
        $scope.calculateSimple.opencomposite();
      } */
    },
    filtreClass: function filtreClass(value) {
      cov_1hs25yilg4.s[78]++;

      if (!$scope.selectedItem) {
        cov_1hs25yilg4.b[28][0]++;
        cov_1hs25yilg4.s[79]++;

        return false;
      } else {
        cov_1hs25yilg4.b[28][1]++;
      }
      cov_1hs25yilg4.s[80]++;
      if ((cov_1hs25yilg4.b[30][0]++, $scope.selectedItem.ID_UNITE.TYPE_UNITES == 1) || (cov_1hs25yilg4.b[30][1]++, $scope.selectedItem.ID_UNITE.TYPE_UNITES == 2)) {
        cov_1hs25yilg4.b[29][0]++;
        cov_1hs25yilg4.s[81]++;

        return true;
      } else {
        cov_1hs25yilg4.b[29][1]++;
      }
      cov_1hs25yilg4.s[82]++;
      return value.id == 1;
    },
    filtreAggregation: function filtreAggregation(value) {
      cov_1hs25yilg4.s[83]++;

      if (!$scope.selectedItem) {
        cov_1hs25yilg4.b[31][0]++;
        cov_1hs25yilg4.s[84]++;

        return false;
      } else {
        cov_1hs25yilg4.b[31][1]++;
      }
      cov_1hs25yilg4.s[85]++;
      if ((cov_1hs25yilg4.b[33][0]++, $scope.selectedItem.ID_UNITE.TYPE_UNITES == 1) || (cov_1hs25yilg4.b[33][1]++, $scope.selectedItem.ID_UNITE.TYPE_UNITES == 2)) {
        cov_1hs25yilg4.b[32][0]++;
        cov_1hs25yilg4.s[86]++;

        if ((cov_1hs25yilg4.b[35][0]++, $scope.selectedItem.Type_indicateur_G.id == 1) || (cov_1hs25yilg4.b[35][1]++, $scope.selectedItem.Type_indicateur_G.id == 3)) {
          cov_1hs25yilg4.b[34][0]++;
          cov_1hs25yilg4.s[87]++;

          if ((cov_1hs25yilg4.b[37][0]++, value == $scope.selectedItem.AGREGATION_INDIC_G) && (cov_1hs25yilg4.b[37][1]++, value.id <= 0)) {
            cov_1hs25yilg4.b[36][0]++;
            cov_1hs25yilg4.s[88]++;

            $scope.selectedItem.AGREGATION_INDIC_G = null;
          } else {
            cov_1hs25yilg4.b[36][1]++;
          }
          cov_1hs25yilg4.s[89]++;
          return value.id > 0;
        } else {
          cov_1hs25yilg4.b[34][1]++;
        }
      } else {
        cov_1hs25yilg4.b[32][1]++;
      }
      cov_1hs25yilg4.s[90]++;
      if ((cov_1hs25yilg4.b[39][0]++, value == $scope.selectedItem.AGREGATION_INDIC_G) && (cov_1hs25yilg4.b[39][1]++, value.id != 0)) {
        cov_1hs25yilg4.b[38][0]++;
        cov_1hs25yilg4.s[91]++;

        $scope.selectedItem.AGREGATION_INDIC_G = null;
      } else {
        cov_1hs25yilg4.b[38][1]++;
      }
      cov_1hs25yilg4.s[92]++;
      return value.id == 0;
    },

    listClass: [{ name: $translate.instant('INDICATOR.SIMPL'), id: '1' }, { name: $translate.instant('INDICATOR.CUMUL'), id: '3' }],
    listAggrega: [{ name: $translate.instant('INDICATOR.NONE'), id: '0' }, { name: $translate.instant('INDICATOR.SUM'), id: '1' }, { name: $translate.instant('INDICATOR.MOY'), id: '2' }, { name: $translate.instant('INDICATOR.MAXI'), id: '3' }, { name: $translate.instant('INDICATOR.MINI'), id: '4' }],
    listPeriod: [{ name: $translate.instant('COMMON.YEAR'), id: '1' }, { name: $translate.instant('COMMON.SEM'), id: '2' }, { name: $translate.instant('COMMON.TRIM'), id: '4' }, { name: $translate.instant('COMMON.MOIS'), id: '12' }],
    loadIndicateur: function loadIndicateur() {
      cov_1hs25yilg4.s[93]++;

      $scope.dataIndicateur = [];
      cov_1hs25yilg4.s[94]++;
      if ($stateParams.indicator.id == 0) {
        cov_1hs25yilg4.b[40][0]++;
        cov_1hs25yilg4.s[95]++;

        this.addNew();
      } else {
        cov_1hs25yilg4.b[40][1]++;
        cov_1hs25yilg4.s[96]++;

        $scope.indicateur.edit($stateParams.indicator);
      }
    },
    addNew: function addNew() {
      var unit = (cov_1hs25yilg4.s[97]++, $scope.listData_unites_bksb[0]);
      cov_1hs25yilg4.s[98]++;
      if ($scope.listData_unites_bksb.length > 1) {
        cov_1hs25yilg4.b[41][0]++;
        cov_1hs25yilg4.s[99]++;

        unit = $scope.listData_unites_bksb[1];
      } else {
        cov_1hs25yilg4.b[41][1]++;
      }
      cov_1hs25yilg4.s[100]++;
      $scope.listValeurPossible = [];
      cov_1hs25yilg4.s[101]++;
      $scope.indicateur.edit({
        id: 0,
        Type_indicateur_G: $scope.indicateur.listClass[0].id,
        AGREGATION_INDIC_G: $scope.indicateur.listAggrega[(cov_1hs25yilg4.b[43][0]++, unit.TYPE_UNITES == 3) || (cov_1hs25yilg4.b[43][1]++, unit.TYPE_UNITES == 3) ? (cov_1hs25yilg4.b[42][0]++, 4) : (cov_1hs25yilg4.b[42][1]++, 0)].id,
        DEB_EVAL_INDIC_G: $rootScope.DATELIMITE.DATE_DEB_PROJET,
        FIN_EVAL_INDIC_G: $rootScope.DATELIMITE.DATE_FIN_PROJET,
        PERIOD_INDIC_G: $scope.indicateur.listPeriod[3].id,
        CODE_INDIC_G: '',
        LIBELLE_C_INDIC_ENG_G: '',
        LIBELLE_C_INDIC_G: '',
        ID_UNITE: unit.id,
        LIST_ID_PROJECT: ''
      });
    },
    edit: function edit(itemP) {
      cov_1hs25yilg4.s[102]++;

      $scope.tab = 1;
      var item = (cov_1hs25yilg4.s[103]++, angular.copy(itemP));
      cov_1hs25yilg4.s[104]++;
      $scope.lastIDVP = 0;
      cov_1hs25yilg4.s[105]++;
      $scope.isadd = true;

      cov_1hs25yilg4.s[106]++;
      if (angular.isString(item.DEB_EVAL_INDIC_G)) {
        cov_1hs25yilg4.b[44][0]++;
        cov_1hs25yilg4.s[107]++;

        item.DEB_EVAL_INDIC_G = Date.newDate(item.DEB_EVAL_INDIC_G);
        cov_1hs25yilg4.s[108]++;
        item.FIN_EVAL_INDIC_G = Date.newDate(item.FIN_EVAL_INDIC_G);
      } else {
        cov_1hs25yilg4.b[44][1]++;
      }

      cov_1hs25yilg4.s[109]++;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = $scope.listData_unites_bksb[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var el = _step3.value;
          cov_1hs25yilg4.s[110]++;

          if (el.id == item.ID_UNITE) {
            cov_1hs25yilg4.b[45][0]++;
            cov_1hs25yilg4.s[111]++;

            item.ID_UNITE = el;
            cov_1hs25yilg4.s[112]++;
            break;
          } else {
            cov_1hs25yilg4.b[45][1]++;
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      cov_1hs25yilg4.s[113]++;
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = $scope.indicateur.listClass[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var _el2 = _step4.value;
          cov_1hs25yilg4.s[114]++;

          if (_el2.id == item.Type_indicateur_G) {
            cov_1hs25yilg4.b[46][0]++;
            cov_1hs25yilg4.s[115]++;

            item.Type_indicateur_G = _el2;
            cov_1hs25yilg4.s[116]++;
            break;
          } else {
            cov_1hs25yilg4.b[46][1]++;
          }
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }

      cov_1hs25yilg4.s[117]++;
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = $scope.indicateur.listAggrega[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var _el3 = _step5.value;
          cov_1hs25yilg4.s[118]++;

          if (_el3.id == item.AGREGATION_INDIC_G) {
            cov_1hs25yilg4.b[47][0]++;
            cov_1hs25yilg4.s[119]++;

            item.AGREGATION_INDIC_G = _el3;
            cov_1hs25yilg4.s[120]++;
            break;
          } else {
            cov_1hs25yilg4.b[47][1]++;
          }
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      cov_1hs25yilg4.s[121]++;
      var _iteratorNormalCompletion6 = true;
      var _didIteratorError6 = false;
      var _iteratorError6 = undefined;

      try {
        for (var _iterator6 = $scope.indicateur.listPeriod[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
          var _el4 = _step6.value;
          cov_1hs25yilg4.s[122]++;

          if (_el4.id == item.PERIOD_INDIC_G) {
            cov_1hs25yilg4.b[48][0]++;
            cov_1hs25yilg4.s[123]++;

            item.PERIOD_INDIC_G = _el4;
            cov_1hs25yilg4.s[124]++;
            break;
          } else {
            cov_1hs25yilg4.b[48][1]++;
          }
        }
      } catch (err) {
        _didIteratorError6 = true;
        _iteratorError6 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion6 && _iterator6.return) {
            _iterator6.return();
          }
        } finally {
          if (_didIteratorError6) {
            throw _iteratorError6;
          }
        }
      }

      var item_ = (cov_1hs25yilg4.s[125]++, {});
      cov_1hs25yilg4.s[126]++;
      var _iteratorNormalCompletion7 = true;
      var _didIteratorError7 = false;
      var _iteratorError7 = undefined;

      try {
        for (var _iterator7 = ((cov_1hs25yilg4.b[49][0]++, itemP.LIST_ID_PROJECT) || (cov_1hs25yilg4.b[49][1]++, '')).split(',')[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
          var _el5 = _step7.value;
          cov_1hs25yilg4.s[127]++;

          if (_el5 > 0) {
            cov_1hs25yilg4.b[50][0]++;
            cov_1hs25yilg4.s[128]++;

            item_[_el5] = true;
          } else {
            cov_1hs25yilg4.b[50][1]++;
          }
        }
      } catch (err) {
        _didIteratorError7 = true;
        _iteratorError7 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion7 && _iterator7.return) {
            _iterator7.return();
          }
        } finally {
          if (_didIteratorError7) {
            throw _iteratorError7;
          }
        }
      }

      cov_1hs25yilg4.s[129]++;
      item.LIST_ID_PROJECT = item_;
      cov_1hs25yilg4.s[130]++;
      $scope.isloading = true;
      cov_1hs25yilg4.s[131]++;
      $scope.lockedProject = {};
      cov_1hs25yilg4.s[132]++;
      $CRUDService.getAll('Indicateurs', { get: 'all_linked_indic_short', id: (cov_1hs25yilg4.b[51][0]++, item.id) || (cov_1hs25yilg4.b[51][1]++, 0) }, function (data__) {
        cov_1hs25yilg4.f[4]++;
        cov_1hs25yilg4.s[133]++;
        var _iteratorNormalCompletion8 = true;
        var _didIteratorError8 = false;
        var _iteratorError8 = undefined;

        try {
          for (var _iterator8 = data__[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
            var prj = _step8.value;
            cov_1hs25yilg4.s[134]++;

            $scope.lockedProject[prj] = true;
            cov_1hs25yilg4.s[135]++;
            item.LIST_ID_PROJECT[prj] = true;
          }
        } catch (err) {
          _didIteratorError8 = true;
          _iteratorError8 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion8 && _iterator8.return) {
              _iterator8.return();
            }
          } finally {
            if (_didIteratorError8) {
              throw _iteratorError8;
            }
          }
        }

        cov_1hs25yilg4.s[136]++;
        if (parseInt(item.ID_UNITE.TYPE_UNITES, 10) == 3) {
          cov_1hs25yilg4.b[52][0]++;
          cov_1hs25yilg4.s[137]++;

          $scope.isloading = true;
          cov_1hs25yilg4.s[138]++;
          $CRUDService.getAll(PATHVP, { get: 'all', indic: item.id }, function (data) {
            cov_1hs25yilg4.f[5]++;
            cov_1hs25yilg4.s[139]++;

            for (var i = data.length - 1; i >= 0; i--) {
              cov_1hs25yilg4.s[140]++;

              data[i].hideEditor = true;
              cov_1hs25yilg4.s[141]++;
              data[i].Ponderation_G = parseInt(data[i].Ponderation_G, 10);
            }
            cov_1hs25yilg4.s[142]++;
            $scope.listValeurPossible = data;
            cov_1hs25yilg4.s[143]++;
            $scope.isloading = false;
            cov_1hs25yilg4.s[144]++;
            item.imageUrl = $deltahttp.getIndicatorGlobalRespositoy(item.id);
            cov_1hs25yilg4.s[145]++;
            $scope.selectedItem = item;

            cov_1hs25yilg4.s[146]++;
            $scope.viewIndex = 2;
          });
        } else {
          cov_1hs25yilg4.b[52][1]++;
          cov_1hs25yilg4.s[147]++;

          $scope.isloading = false;
          cov_1hs25yilg4.s[148]++;
          if ((cov_1hs25yilg4.b[54][0]++, item.ID_UNITE.TYPE_UNITES < 3) && (cov_1hs25yilg4.b[54][1]++, item.AGREGATION_INDIC_G.id == 0) && (cov_1hs25yilg4.b[54][2]++, item.id == 0)) {
            cov_1hs25yilg4.b[53][0]++;
            cov_1hs25yilg4.s[149]++;

            item.AGREGATION_INDIC_G = $scope.indicateur.listAggrega[1];
          } else {
            cov_1hs25yilg4.b[53][1]++;
          }
          cov_1hs25yilg4.s[150]++;
          item.imageUrl = $deltahttp.getIndicatorGlobalRespositoy(item.id);
          cov_1hs25yilg4.s[151]++;
          $scope.selectedItem = item;
        }
      });
    },
    cancel: function cancel() {
      cov_1hs25yilg4.s[152]++;

      $rootScope.goToPrevious();
    },
    validate: function validate() {
      var itemP = (cov_1hs25yilg4.s[153]++, $scope.selectedItem);
      cov_1hs25yilg4.s[154]++;
      if (!itemP.AGREGATION_INDIC_G) {
        cov_1hs25yilg4.b[55][0]++;
        cov_1hs25yilg4.s[155]++;

        return;
      } else {
        cov_1hs25yilg4.b[55][1]++;
      }
      cov_1hs25yilg4.s[156]++;
      if (!itemP.Type_indicateur_G) {
        cov_1hs25yilg4.b[56][0]++;
        cov_1hs25yilg4.s[157]++;

        return;
      } else {
        cov_1hs25yilg4.b[56][1]++;
      }
      cov_1hs25yilg4.s[158]++;
      if (!itemP.PERIOD_INDIC_G) {
        cov_1hs25yilg4.b[57][0]++;
        cov_1hs25yilg4.s[159]++;

        return;
      } else {
        cov_1hs25yilg4.b[57][1]++;
      }
      cov_1hs25yilg4.s[160]++;
      if (!itemP.ID_UNITE) {
        cov_1hs25yilg4.b[58][0]++;
        cov_1hs25yilg4.s[161]++;

        return;
      } else {
        cov_1hs25yilg4.b[58][1]++;
      }
      cov_1hs25yilg4.s[162]++;
      if (itemP.DEB_EVAL_INDIC_G >= itemP.FIN_EVAL_INDIC_G) {
        cov_1hs25yilg4.b[59][0]++;
        cov_1hs25yilg4.s[163]++;

        return;
      } else {
        cov_1hs25yilg4.b[59][1]++;
      }

      var info = (cov_1hs25yilg4.s[164]++, __checkValidate(itemP).split('\t'));
      cov_1hs25yilg4.s[165]++;
      if (info[0] != 0) {
        cov_1hs25yilg4.b[60][0]++;
        cov_1hs25yilg4.s[166]++;

        SweetAlert.swal({
          title: $translate.instant('COMMON.CONFIRM'),
          text: info[1],
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#c8c8c8', cancelButtonColor: '#DD6B55',
          confirmButtonText: $translate.instant('COMMON.NO'),
          cancelButtonText: $translate.instant('COMMON.YES'),
          closeOnConfirm: true,
          closeOnCancel: true
        }, function (isconfirm) {
          cov_1hs25yilg4.f[6]++;
          cov_1hs25yilg4.s[167]++;

          if (isconfirm) {
            cov_1hs25yilg4.b[61][0]++;
            cov_1hs25yilg4.s[168]++;

            return;
          } else {
            cov_1hs25yilg4.b[61][1]++;
          }
          cov_1hs25yilg4.s[169]++;
          $scope.indicateur.__saving(itemP);
        });
        cov_1hs25yilg4.s[170]++;
        return;
      } else {
        cov_1hs25yilg4.b[60][1]++;
      }
      cov_1hs25yilg4.s[171]++;
      $scope.indicateur.__saving(itemP);
    },
    __saving: function __saving(itemP) {
      cov_1hs25yilg4.s[172]++;

      if (itemP.ID_UNITE.TYPE_UNITES != 3) {
        cov_1hs25yilg4.b[62][0]++;
        cov_1hs25yilg4.s[173]++;

        $scope.listValeurPossible = [];
      } else {
        cov_1hs25yilg4.b[62][1]++;
      }
      var proj = (cov_1hs25yilg4.s[174]++, [0]);
      cov_1hs25yilg4.s[175]++;
      for (var key in itemP.LIST_ID_PROJECT) {
        cov_1hs25yilg4.s[176]++;

        if ((cov_1hs25yilg4.b[64][0]++, key != '') && (cov_1hs25yilg4.b[64][1]++, itemP.LIST_ID_PROJECT[key] == true)) {
          cov_1hs25yilg4.b[63][0]++;
          cov_1hs25yilg4.s[177]++;

          proj.push(key);
        } else {
          cov_1hs25yilg4.b[63][1]++;
        }
      }
      cov_1hs25yilg4.s[178]++;
      proj.push(0);

      var item = (cov_1hs25yilg4.s[179]++, {
        id: itemP.id,
        Type_indicateur_G: itemP.Type_indicateur_G.id,
        AGREGATION_INDIC_G: itemP.AGREGATION_INDIC_G.id,
        DEB_EVAL_INDIC_G: $deltadate.format(itemP.DEB_EVAL_INDIC_G, 'yyyy-mm-dd'),
        FIN_EVAL_INDIC_G: $deltadate.format(itemP.FIN_EVAL_INDIC_G, 'yyyy-mm-dd'),
        PERIOD_INDIC_G: itemP.PERIOD_INDIC_G.id,
        CODE_INDIC_G: itemP.CODE_INDIC_G,
        LIST_ID_PROJECT: proj.join(','),
        LIBELLE_C_INDIC_ENG_G: itemP.LIBELLE_C_INDIC_ENG_G,
        LIBELLE_C_INDIC_G: itemP.LIBELLE_C_INDIC_G,
        ID_UNITE: itemP.ID_UNITE.id
      });
      cov_1hs25yilg4.s[180]++;
      $scope.isloading = true;
      cov_1hs25yilg4.s[181]++;
      $CRUDService.save(PATH, { action: 'save', valeur: $filter('json')(item), oper: 3, VP: $filter('json')(item.id ? (cov_1hs25yilg4.b[65][0]++, []) : (cov_1hs25yilg4.b[65][1]++, $scope.listValeurPossible)), res: 1 }, function (data) {
        cov_1hs25yilg4.f[7]++;
        cov_1hs25yilg4.s[182]++;

        $scope.isloading = false;
        cov_1hs25yilg4.s[183]++;
        if (data < 1) {
          cov_1hs25yilg4.b[66][0]++;

          var msg = (cov_1hs25yilg4.s[184]++, '');
          cov_1hs25yilg4.s[185]++;
          switch (data) {
            case -66:
              cov_1hs25yilg4.b[67][0]++;

            case '-66':
              cov_1hs25yilg4.b[67][1]++;
              cov_1hs25yilg4.s[186]++;


              break;

            case -1:
              cov_1hs25yilg4.b[67][2]++;

            case '-1':
              cov_1hs25yilg4.b[67][3]++;
              cov_1hs25yilg4.s[187]++;


              break;

            case -2:
              cov_1hs25yilg4.b[67][4]++;

            case '-2':
              cov_1hs25yilg4.b[67][5]++;
              cov_1hs25yilg4.s[188]++;

              break;

            case -3:
              cov_1hs25yilg4.b[67][6]++;

            case '-3':
              cov_1hs25yilg4.b[67][7]++;
              cov_1hs25yilg4.s[189]++;


              break;
            case -10:
              cov_1hs25yilg4.b[67][8]++;

            case '-10':
              cov_1hs25yilg4.b[67][9]++;
              cov_1hs25yilg4.s[190]++;


              break;

            default:
              cov_1hs25yilg4.b[67][10]++;

          }
          cov_1hs25yilg4.s[191]++;
          return;
        } else {
          cov_1hs25yilg4.b[66][1]++;
        }
        cov_1hs25yilg4.s[192]++;
        $scope.indicateur.cancel();
      });
    },
    deleteItem: function deleteItem(item) {
      cov_1hs25yilg4.s[193]++;

      SweetAlert.swal({
        title: $translate.instant('COMMON.CONFIRM'),
        text: $translate.instant('INDICATOR.CONFIRM_DELETE'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c8c8c8',
        cancelButtonColor: '#DD6B55',

        confirmButtonText: $translate.instant('COMMON.NO'),
        cancelButtonText: $translate.instant('COMMON.YES'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isconfirm) {
        cov_1hs25yilg4.f[8]++;
        cov_1hs25yilg4.s[194]++;

        if (isconfirm) {
          cov_1hs25yilg4.b[68][0]++;
          cov_1hs25yilg4.s[195]++;

          return;
        } else {
          cov_1hs25yilg4.b[68][1]++;
        }
        cov_1hs25yilg4.s[196]++;
        $scope.isloading = true;
        cov_1hs25yilg4.s[197]++;
        $CRUDService.delet(PATH, { action: 'supp', valeur: $filter('json')(item) }, function (data) {
          cov_1hs25yilg4.f[9]++;
          cov_1hs25yilg4.s[198]++;

          if (data < 0) {
            cov_1hs25yilg4.b[69][0]++;
            cov_1hs25yilg4.s[199]++;

            SweetAlert.swal({
              title: $translate.instant('COMMON.ERROR_DELETE'),
              text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
            });
            cov_1hs25yilg4.s[200]++;
            $scope.isloading = false;
            cov_1hs25yilg4.s[201]++;
            return;
          } else {
            cov_1hs25yilg4.b[69][1]++;
          }
          var itemP = (cov_1hs25yilg4.s[202]++, $filter('filter')($scope.dataIndicateur, { id: item.id })[0]);
          cov_1hs25yilg4.s[203]++;
          if (itemP) {
            cov_1hs25yilg4.b[70][0]++;
            cov_1hs25yilg4.s[204]++;

            $scope.dataIndicateur.splice($scope.dataIndicateur.indexOf(itemP), 1);
          } else {
            cov_1hs25yilg4.b[70][1]++;
          }
          cov_1hs25yilg4.s[205]++;
          $scope.isloading = false;
        });
      });
    }
  };

  cov_1hs25yilg4.s[206]++;
  $scope.selectAllProject = function () {
    cov_1hs25yilg4.f[10]++;
    cov_1hs25yilg4.s[207]++;
    var _iteratorNormalCompletion9 = true;
    var _didIteratorError9 = false;
    var _iteratorError9 = undefined;

    try {
      for (var _iterator9 = $scope.listProjet[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
        var proj = _step9.value;
        cov_1hs25yilg4.s[208]++;

        $scope.selectedItem.LIST_ID_PROJECT[proj.id] = true;
      }
    } catch (err) {
      _didIteratorError9 = true;
      _iteratorError9 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion9 && _iterator9.return) {
          _iterator9.return();
        }
      } finally {
        if (_didIteratorError9) {
          throw _iteratorError9;
        }
      }
    }
  };

  cov_1hs25yilg4.s[209]++;
  $scope.unSelectAllProject = function () {
    cov_1hs25yilg4.f[11]++;
    cov_1hs25yilg4.s[210]++;
    var _iteratorNormalCompletion10 = true;
    var _didIteratorError10 = false;
    var _iteratorError10 = undefined;

    try {
      for (var _iterator10 = $scope.listProjet[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
        var proj = _step10.value;
        cov_1hs25yilg4.s[211]++;

        if ($scope.lockedProject[proj.id]) {
          cov_1hs25yilg4.b[71][0]++;
          cov_1hs25yilg4.s[212]++;

          continue;
        } else {
          cov_1hs25yilg4.b[71][1]++;
        }
        cov_1hs25yilg4.s[213]++;
        $scope.selectedItem.LIST_ID_PROJECT[proj.id] = false;
      }
    } catch (err) {
      _didIteratorError10 = true;
      _iteratorError10 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion10 && _iterator10.return) {
          _iterator10.return();
        }
      } finally {
        if (_didIteratorError10) {
          throw _iteratorError10;
        }
      }
    }
  };

  cov_1hs25yilg4.s[214]++;
  $scope.possibleValue = {
    updateValue: function updateValue(item, add) {
      cov_1hs25yilg4.s[215]++;

      if (add) {
        cov_1hs25yilg4.b[72][0]++;
        cov_1hs25yilg4.s[216]++;

        $scope.listValeurPossible.push(item);
      } else {
        cov_1hs25yilg4.b[72][1]++;
        cov_1hs25yilg4.s[217]++;

        for (var i = $scope.listValeurPossible.length - 1; i >= 0; i--) {
          cov_1hs25yilg4.s[218]++;

          if ($scope.listValeurPossible[i].id == item.id) {
            cov_1hs25yilg4.b[73][0]++;
            cov_1hs25yilg4.s[219]++;

            $scope.listValeurPossible[i] = item;
            cov_1hs25yilg4.s[220]++;
            break;
          } else {
            cov_1hs25yilg4.b[73][1]++;
          }
        }
      }
    },
    addNewVP: function addNewVP() {
      cov_1hs25yilg4.s[221]++;

      this.editVP({
        id: 0,
        Ponderation_G: 0,
        Ma_valeur_G: '',
        ID_INDIC_G: $scope.selectedItem.id
      });
    },
    editVP: function editVP(item) {
      cov_1hs25yilg4.s[222]++;

      this.vpSelected = angular.copy(item);
      cov_1hs25yilg4.s[223]++;
      $uibModal.open({
        templateUrl: 'app/views/indicator_portfolio/edit/possiblevalue_edit.html',
        controller: indicatorPortfolioEditCtrlvaleurpossible,
        scope: $scope,
        size: 'sm_',
        backdrop: 'static',
        windowClass: 'animated fadeInRight left-modal '
      });
    }
  };
  cov_1hs25yilg4.s[224]++;
  $scope.displayPicture = {
    value: false
  };
  cov_1hs25yilg4.s[225]++;
  $scope.indicateur.loadIndicateur();
});

function indicatorPortfolioEditCtrlvaleurpossible($scope, $uibModalInstance, $deltadate, $rootScope, $log, $CRUDService, $filter, $translate) {
  cov_1hs25yilg4.f[12]++;

  var PATHVP = (cov_1hs25yilg4.s[226]++, 'ValeursPossibleGlobal');
  cov_1hs25yilg4.s[227]++;
  $scope.checkDoublons = function (index) {
    cov_1hs25yilg4.f[13]++;
    cov_1hs25yilg4.s[228]++;
    var _iteratorNormalCompletion11 = true;
    var _didIteratorError11 = false;
    var _iteratorError11 = undefined;

    try {
      for (var _iterator11 = $scope.listValeurPossible[Symbol.iterator](), _step11; !(_iteratorNormalCompletion11 = (_step11 = _iterator11.next()).done); _iteratorNormalCompletion11 = true) {
        var el = _step11.value;
        cov_1hs25yilg4.s[229]++;

        if ((cov_1hs25yilg4.b[75][0]++, el.Ponderation_G == $scope.possibleValue.vpSelected.Ponderation_G) && (cov_1hs25yilg4.b[75][1]++, el.id != $scope.possibleValue.vpSelected.id) && ((cov_1hs25yilg4.b[75][2]++, index == 1) || (cov_1hs25yilg4.b[75][3]++, !index))) {
          cov_1hs25yilg4.b[74][0]++;
          cov_1hs25yilg4.s[230]++;

          return true;
        } else {
          cov_1hs25yilg4.b[74][1]++;
        }
        cov_1hs25yilg4.s[231]++;
        if ((cov_1hs25yilg4.b[77][0]++, el.Ma_valeur_G == $scope.possibleValue.vpSelected.Ma_valeur_G) && (cov_1hs25yilg4.b[77][1]++, el.id != $scope.possibleValue.vpSelected.id) && ((cov_1hs25yilg4.b[77][2]++, index == 2) || (cov_1hs25yilg4.b[77][3]++, !index))) {
          cov_1hs25yilg4.b[76][0]++;
          cov_1hs25yilg4.s[232]++;

          return true;
        } else {
          cov_1hs25yilg4.b[76][1]++;
        }
      }
    } catch (err) {
      _didIteratorError11 = true;
      _iteratorError11 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion11 && _iterator11.return) {
          _iterator11.return();
        }
      } finally {
        if (_didIteratorError11) {
          throw _iteratorError11;
        }
      }
    }

    cov_1hs25yilg4.s[233]++;
    return false;
  };
  cov_1hs25yilg4.s[234]++;
  $scope.ValidateValeurPossible = function () {
    cov_1hs25yilg4.f[14]++;

    var add = (cov_1hs25yilg4.s[235]++, $scope.possibleValue.vpSelected.id == 0);
    cov_1hs25yilg4.s[236]++;
    if ($scope.selectedItem.id > 0) {
      cov_1hs25yilg4.b[78][0]++;
      cov_1hs25yilg4.s[237]++;

      $CRUDService.save(PATHVP, { action: 'MiseAJour', valeur: $filter('json')($scope.possibleValue.vpSelected) }, function (data) {
        cov_1hs25yilg4.f[15]++;
        cov_1hs25yilg4.s[238]++;

        $scope.possibleValue.vpSelected.id = data;
        cov_1hs25yilg4.s[239]++;
        $scope.possibleValue.updateValue($scope.possibleValue.vpSelected, add);
        cov_1hs25yilg4.s[240]++;
        $uibModalInstance.dismiss('cancel');
      });
    } else {
      cov_1hs25yilg4.b[78][1]++;
      cov_1hs25yilg4.s[241]++;

      if (add) {
        cov_1hs25yilg4.b[79][0]++;

        var last = (cov_1hs25yilg4.s[242]++, (cov_1hs25yilg4.b[80][0]++, $scope.listValeurPossible[$scope.listValeurPossible.length - 1]) || (cov_1hs25yilg4.b[80][1]++, { id: 0 }));
        cov_1hs25yilg4.s[243]++;
        $scope.possibleValue.vpSelected.id = last.id - 1;
      } else {
        cov_1hs25yilg4.b[79][1]++;
      }
      cov_1hs25yilg4.s[244]++;
      $scope.possibleValue.updateValue($scope.possibleValue.vpSelected, add);
      cov_1hs25yilg4.s[245]++;
      $uibModalInstance.dismiss('cancel');
    }
  };
  cov_1hs25yilg4.s[246]++;
  $scope.closeModal = function () {
    cov_1hs25yilg4.f[16]++;
    cov_1hs25yilg4.s[247]++;

    $uibModalInstance.dismiss('cancel');
  };
}
'use strict';

var cov_1xykcjwlqp = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/directives/tableSyncfusion.js',
      hash = 'a8da98ccc824ebe9dcb234b76ab665264ff2445e',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/directives/tableSyncfusion.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 316,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 315,
          column: 4
        }
      },
      '2': {
        start: {
          line: 49,
          column: 6
        },
        end: {
          line: 49,
          column: 25
        }
      },
      '3': {
        start: {
          line: 50,
          column: 6
        },
        end: {
          line: 50,
          column: 40
        }
      },
      '4': {
        start: {
          line: 51,
          column: 27
        },
        end: {
          line: 51,
          column: 31
        }
      },
      '5': {
        start: {
          line: 52,
          column: 21
        },
        end: {
          line: 237,
          column: 7
        }
      },
      '6': {
        start: {
          line: 83,
          column: 10
        },
        end: {
          line: 85,
          column: 11
        }
      },
      '7': {
        start: {
          line: 84,
          column: 12
        },
        end: {
          line: 84,
          column: 19
        }
      },
      '8': {
        start: {
          line: 86,
          column: 10
        },
        end: {
          line: 86,
          column: 40
        }
      },
      '9': {
        start: {
          line: 89,
          column: 10
        },
        end: {
          line: 91,
          column: 11
        }
      },
      '10': {
        start: {
          line: 90,
          column: 12
        },
        end: {
          line: 90,
          column: 19
        }
      },
      '11': {
        start: {
          line: 92,
          column: 10
        },
        end: {
          line: 92,
          column: 37
        }
      },
      '12': {
        start: {
          line: 95,
          column: 10
        },
        end: {
          line: 97,
          column: 11
        }
      },
      '13': {
        start: {
          line: 96,
          column: 12
        },
        end: {
          line: 96,
          column: 19
        }
      },
      '14': {
        start: {
          line: 98,
          column: 10
        },
        end: {
          line: 98,
          column: 39
        }
      },
      '15': {
        start: {
          line: 101,
          column: 10
        },
        end: {
          line: 103,
          column: 11
        }
      },
      '16': {
        start: {
          line: 102,
          column: 12
        },
        end: {
          line: 102,
          column: 19
        }
      },
      '17': {
        start: {
          line: 104,
          column: 10
        },
        end: {
          line: 104,
          column: 38
        }
      },
      '18': {
        start: {
          line: 107,
          column: 10
        },
        end: {
          line: 109,
          column: 11
        }
      },
      '19': {
        start: {
          line: 108,
          column: 12
        },
        end: {
          line: 108,
          column: 19
        }
      },
      '20': {
        start: {
          line: 110,
          column: 10
        },
        end: {
          line: 110,
          column: 43
        }
      },
      '21': {
        start: {
          line: 114,
          column: 10
        },
        end: {
          line: 116,
          column: 11
        }
      },
      '22': {
        start: {
          line: 115,
          column: 12
        },
        end: {
          line: 115,
          column: 19
        }
      },
      '23': {
        start: {
          line: 117,
          column: 10
        },
        end: {
          line: 117,
          column: 41
        }
      },
      '24': {
        start: {
          line: 120,
          column: 10
        },
        end: {
          line: 125,
          column: 11
        }
      },
      '25': {
        start: {
          line: 121,
          column: 12
        },
        end: {
          line: 123,
          column: 13
        }
      },
      '26': {
        start: {
          line: 122,
          column: 14
        },
        end: {
          line: 122,
          column: 88
        }
      },
      '27': {
        start: {
          line: 124,
          column: 12
        },
        end: {
          line: 124,
          column: 19
        }
      },
      '28': {
        start: {
          line: 128,
          column: 23
        },
        end: {
          line: 128,
          column: 78
        }
      },
      '29': {
        start: {
          line: 130,
          column: 10
        },
        end: {
          line: 137,
          column: 11
        }
      },
      '30': {
        start: {
          line: 131,
          column: 12
        },
        end: {
          line: 133,
          column: 13
        }
      },
      '31': {
        start: {
          line: 132,
          column: 14
        },
        end: {
          line: 132,
          column: 23
        }
      },
      '32': {
        start: {
          line: 134,
          column: 12
        },
        end: {
          line: 136,
          column: 13
        }
      },
      '33': {
        start: {
          line: 135,
          column: 14
        },
        end: {
          line: 135,
          column: 54
        }
      },
      '34': {
        start: {
          line: 139,
          column: 10
        },
        end: {
          line: 141,
          column: 11
        }
      },
      '35': {
        start: {
          line: 140,
          column: 12
        },
        end: {
          line: 140,
          column: 77
        }
      },
      '36': {
        start: {
          line: 144,
          column: 10
        },
        end: {
          line: 149,
          column: 11
        }
      },
      '37': {
        start: {
          line: 145,
          column: 12
        },
        end: {
          line: 147,
          column: 13
        }
      },
      '38': {
        start: {
          line: 146,
          column: 14
        },
        end: {
          line: 146,
          column: 88
        }
      },
      '39': {
        start: {
          line: 148,
          column: 12
        },
        end: {
          line: 148,
          column: 19
        }
      },
      '40': {
        start: {
          line: 152,
          column: 10
        },
        end: {
          line: 160,
          column: 11
        }
      },
      '41': {
        start: {
          line: 153,
          column: 12
        },
        end: {
          line: 155,
          column: 13
        }
      },
      '42': {
        start: {
          line: 154,
          column: 14
        },
        end: {
          line: 154,
          column: 23
        }
      },
      '43': {
        start: {
          line: 156,
          column: 23
        },
        end: {
          line: 156,
          column: 35
        }
      },
      '44': {
        start: {
          line: 157,
          column: 12
        },
        end: {
          line: 159,
          column: 13
        }
      },
      '45': {
        start: {
          line: 158,
          column: 14
        },
        end: {
          line: 158,
          column: 49
        }
      },
      '46': {
        start: {
          line: 162,
          column: 10
        },
        end: {
          line: 164,
          column: 11
        }
      },
      '47': {
        start: {
          line: 163,
          column: 12
        },
        end: {
          line: 163,
          column: 77
        }
      },
      '48': {
        start: {
          line: 167,
          column: 10
        },
        end: {
          line: 179,
          column: 11
        }
      },
      '49': {
        start: {
          line: 168,
          column: 31
        },
        end: {
          line: 168,
          column: 33
        }
      },
      '50': {
        start: {
          line: 169,
          column: 12
        },
        end: {
          line: 171,
          column: 13
        }
      },
      '51': {
        start: {
          line: 170,
          column: 14
        },
        end: {
          line: 170,
          column: 69
        }
      },
      '52': {
        start: {
          line: 172,
          column: 12
        },
        end: {
          line: 172,
          column: 34
        }
      },
      '53': {
        start: {
          line: 173,
          column: 12
        },
        end: {
          line: 177,
          column: 15
        }
      },
      '54': {
        start: {
          line: 174,
          column: 14
        },
        end: {
          line: 176,
          column: 15
        }
      },
      '55': {
        start: {
          line: 175,
          column: 16
        },
        end: {
          line: 175,
          column: 39
        }
      },
      '56': {
        start: {
          line: 178,
          column: 12
        },
        end: {
          line: 178,
          column: 63
        }
      },
      '57': {
        start: {
          line: 180,
          column: 10
        },
        end: {
          line: 182,
          column: 11
        }
      },
      '58': {
        start: {
          line: 181,
          column: 12
        },
        end: {
          line: 181,
          column: 19
        }
      },
      '59': {
        start: {
          line: 183,
          column: 10
        },
        end: {
          line: 183,
          column: 35
        }
      },
      '60': {
        start: {
          line: 184,
          column: 10
        },
        end: {
          line: 184,
          column: 35
        }
      },
      '61': {
        start: {
          line: 188,
          column: 10
        },
        end: {
          line: 190,
          column: 11
        }
      },
      '62': {
        start: {
          line: 189,
          column: 12
        },
        end: {
          line: 189,
          column: 19
        }
      },
      '63': {
        start: {
          line: 191,
          column: 10
        },
        end: {
          line: 191,
          column: 32
        }
      },
      '64': {
        start: {
          line: 195,
          column: 10
        },
        end: {
          line: 195,
          column: 65
        }
      },
      '65': {
        start: {
          line: 196,
          column: 10
        },
        end: {
          line: 198,
          column: 11
        }
      },
      '66': {
        start: {
          line: 197,
          column: 12
        },
        end: {
          line: 197,
          column: 19
        }
      },
      '67': {
        start: {
          line: 199,
          column: 10
        },
        end: {
          line: 199,
          column: 38
        }
      },
      '68': {
        start: {
          line: 203,
          column: 10
        },
        end: {
          line: 203,
          column: 65
        }
      },
      '69': {
        start: {
          line: 204,
          column: 10
        },
        end: {
          line: 206,
          column: 11
        }
      },
      '70': {
        start: {
          line: 205,
          column: 12
        },
        end: {
          line: 205,
          column: 19
        }
      },
      '71': {
        start: {
          line: 207,
          column: 10
        },
        end: {
          line: 207,
          column: 39
        }
      },
      '72': {
        start: {
          line: 211,
          column: 10
        },
        end: {
          line: 213,
          column: 11
        }
      },
      '73': {
        start: {
          line: 212,
          column: 12
        },
        end: {
          line: 212,
          column: 19
        }
      },
      '74': {
        start: {
          line: 214,
          column: 26
        },
        end: {
          line: 214,
          column: 39
        }
      },
      '75': {
        start: {
          line: 215,
          column: 10
        },
        end: {
          line: 215,
          column: 36
        }
      },
      '76': {
        start: {
          line: 216,
          column: 10
        },
        end: {
          line: 216,
          column: 39
        }
      },
      '77': {
        start: {
          line: 220,
          column: 10
        },
        end: {
          line: 222,
          column: 11
        }
      },
      '78': {
        start: {
          line: 221,
          column: 12
        },
        end: {
          line: 221,
          column: 19
        }
      },
      '79': {
        start: {
          line: 223,
          column: 10
        },
        end: {
          line: 223,
          column: 60
        }
      },
      '80': {
        start: {
          line: 226,
          column: 10
        },
        end: {
          line: 228,
          column: 11
        }
      },
      '81': {
        start: {
          line: 227,
          column: 12
        },
        end: {
          line: 227,
          column: 19
        }
      },
      '82': {
        start: {
          line: 229,
          column: 10
        },
        end: {
          line: 229,
          column: 57
        }
      },
      '83': {
        start: {
          line: 232,
          column: 10
        },
        end: {
          line: 234,
          column: 11
        }
      },
      '84': {
        start: {
          line: 233,
          column: 12
        },
        end: {
          line: 233,
          column: 19
        }
      },
      '85': {
        start: {
          line: 235,
          column: 10
        },
        end: {
          line: 235,
          column: 41
        }
      },
      '86': {
        start: {
          line: 238,
          column: 21
        },
        end: {
          line: 248,
          column: 7
        }
      },
      '87': {
        start: {
          line: 239,
          column: 8
        },
        end: {
          line: 247,
          column: 9
        }
      },
      '88': {
        start: {
          line: 240,
          column: 10
        },
        end: {
          line: 243,
          column: 18
        }
      },
      '89': {
        start: {
          line: 241,
          column: 12
        },
        end: {
          line: 241,
          column: 52
        }
      },
      '90': {
        start: {
          line: 242,
          column: 12
        },
        end: {
          line: 242,
          column: 45
        }
      },
      '91': {
        start: {
          line: 245,
          column: 10
        },
        end: {
          line: 245,
          column: 50
        }
      },
      '92': {
        start: {
          line: 246,
          column: 10
        },
        end: {
          line: 246,
          column: 43
        }
      },
      '93': {
        start: {
          line: 249,
          column: 6
        },
        end: {
          line: 253,
          column: 9
        }
      },
      '94': {
        start: {
          line: 250,
          column: 8
        },
        end: {
          line: 250,
          column: 38
        }
      },
      '95': {
        start: {
          line: 251,
          column: 8
        },
        end: {
          line: 251,
          column: 32
        }
      },
      '96': {
        start: {
          line: 252,
          column: 8
        },
        end: {
          line: 252,
          column: 45
        }
      },
      '97': {
        start: {
          line: 254,
          column: 6
        },
        end: {
          line: 266,
          column: 9
        }
      },
      '98': {
        start: {
          line: 255,
          column: 8
        },
        end: {
          line: 265,
          column: 9
        }
      },
      '99': {
        start: {
          line: 257,
          column: 12
        },
        end: {
          line: 257,
          column: 48
        }
      },
      '100': {
        start: {
          line: 258,
          column: 12
        },
        end: {
          line: 258,
          column: 18
        }
      },
      '101': {
        start: {
          line: 261,
          column: 12
        },
        end: {
          line: 261,
          column: 46
        }
      },
      '102': {
        start: {
          line: 262,
          column: 12
        },
        end: {
          line: 262,
          column: 18
        }
      },
      '103': {
        start: {
          line: 268,
          column: 6
        },
        end: {
          line: 275,
          column: 9
        }
      },
      '104': {
        start: {
          line: 269,
          column: 8
        },
        end: {
          line: 269,
          column: 37
        }
      },
      '105': {
        start: {
          line: 271,
          column: 8
        },
        end: {
          line: 274,
          column: 9
        }
      },
      '106': {
        start: {
          line: 272,
          column: 10
        },
        end: {
          line: 272,
          column: 50
        }
      },
      '107': {
        start: {
          line: 273,
          column: 10
        },
        end: {
          line: 273,
          column: 32
        }
      },
      '108': {
        start: {
          line: 277,
          column: 6
        },
        end: {
          line: 284,
          column: 9
        }
      },
      '109': {
        start: {
          line: 278,
          column: 8
        },
        end: {
          line: 278,
          column: 31
        }
      },
      '110': {
        start: {
          line: 280,
          column: 8
        },
        end: {
          line: 283,
          column: 9
        }
      },
      '111': {
        start: {
          line: 281,
          column: 10
        },
        end: {
          line: 281,
          column: 56
        }
      },
      '112': {
        start: {
          line: 282,
          column: 10
        },
        end: {
          line: 282,
          column: 32
        }
      },
      '113': {
        start: {
          line: 285,
          column: 6
        },
        end: {
          line: 292,
          column: 9
        }
      },
      '114': {
        start: {
          line: 286,
          column: 8
        },
        end: {
          line: 286,
          column: 38
        }
      },
      '115': {
        start: {
          line: 288,
          column: 8
        },
        end: {
          line: 291,
          column: 9
        }
      },
      '116': {
        start: {
          line: 289,
          column: 10
        },
        end: {
          line: 289,
          column: 63
        }
      },
      '117': {
        start: {
          line: 290,
          column: 10
        },
        end: {
          line: 290,
          column: 32
        }
      },
      '118': {
        start: {
          line: 293,
          column: 6
        },
        end: {
          line: 303,
          column: 9
        }
      },
      '119': {
        start: {
          line: 294,
          column: 8
        },
        end: {
          line: 294,
          column: 35
        }
      },
      '120': {
        start: {
          line: 296,
          column: 8
        },
        end: {
          line: 298,
          column: 9
        }
      },
      '121': {
        start: {
          line: 297,
          column: 10
        },
        end: {
          line: 297,
          column: 17
        }
      },
      '122': {
        start: {
          line: 299,
          column: 8
        },
        end: {
          line: 302,
          column: 9
        }
      },
      '123': {
        start: {
          line: 300,
          column: 10
        },
        end: {
          line: 300,
          column: 60
        }
      },
      '124': {
        start: {
          line: 301,
          column: 10
        },
        end: {
          line: 301,
          column: 32
        }
      },
      '125': {
        start: {
          line: 305,
          column: 6
        },
        end: {
          line: 312,
          column: 9
        }
      },
      '126': {
        start: {
          line: 306,
          column: 8
        },
        end: {
          line: 306,
          column: 29
        }
      },
      '127': {
        start: {
          line: 308,
          column: 8
        },
        end: {
          line: 311,
          column: 9
        }
      },
      '128': {
        start: {
          line: 309,
          column: 10
        },
        end: {
          line: 309,
          column: 41
        }
      },
      '129': {
        start: {
          line: 310,
          column: 10
        },
        end: {
          line: 310,
          column: 39
        }
      },
      '130': {
        start: {
          line: 313,
          column: 6
        },
        end: {
          line: 313,
          column: 15
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 51
          },
          end: {
            line: 1,
            column: 52
          }
        },
        loc: {
          start: {
            line: 1,
            column: 63
          },
          end: {
            line: 316,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 173,
            column: 46
          },
          end: {
            line: 173,
            column: 47
          }
        },
        loc: {
          start: {
            line: 173,
            column: 62
          },
          end: {
            line: 177,
            column: 13
          }
        },
        line: 173
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 238,
            column: 21
          },
          end: {
            line: 238,
            column: 22
          }
        },
        loc: {
          start: {
            line: 238,
            column: 33
          },
          end: {
            line: 248,
            column: 7
          }
        },
        line: 238
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 240,
            column: 19
          },
          end: {
            line: 240,
            column: 20
          }
        },
        loc: {
          start: {
            line: 240,
            column: 25
          },
          end: {
            line: 243,
            column: 11
          }
        },
        line: 240
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 249,
            column: 28
          },
          end: {
            line: 249,
            column: 29
          }
        },
        loc: {
          start: {
            line: 249,
            column: 34
          },
          end: {
            line: 253,
            column: 7
          }
        },
        line: 249
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 254,
            column: 43
          },
          end: {
            line: 254,
            column: 44
          }
        },
        loc: {
          start: {
            line: 254,
            column: 60
          },
          end: {
            line: 266,
            column: 7
          }
        },
        line: 254
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 268,
            column: 19
          },
          end: {
            line: 268,
            column: 20
          }
        },
        loc: {
          start: {
            line: 268,
            column: 28
          },
          end: {
            line: 270,
            column: 7
          }
        },
        line: 268
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 270,
            column: 9
          },
          end: {
            line: 270,
            column: 10
          }
        },
        loc: {
          start: {
            line: 270,
            column: 21
          },
          end: {
            line: 275,
            column: 7
          }
        },
        line: 270
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 277,
            column: 19
          },
          end: {
            line: 277,
            column: 20
          }
        },
        loc: {
          start: {
            line: 277,
            column: 28
          },
          end: {
            line: 279,
            column: 7
          }
        },
        line: 277
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 279,
            column: 9
          },
          end: {
            line: 279,
            column: 10
          }
        },
        loc: {
          start: {
            line: 279,
            column: 21
          },
          end: {
            line: 284,
            column: 7
          }
        },
        line: 279
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 285,
            column: 19
          },
          end: {
            line: 285,
            column: 20
          }
        },
        loc: {
          start: {
            line: 285,
            column: 28
          },
          end: {
            line: 287,
            column: 7
          }
        },
        line: 285
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 287,
            column: 9
          },
          end: {
            line: 287,
            column: 10
          }
        },
        loc: {
          start: {
            line: 287,
            column: 21
          },
          end: {
            line: 292,
            column: 7
          }
        },
        line: 287
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 293,
            column: 19
          },
          end: {
            line: 293,
            column: 20
          }
        },
        loc: {
          start: {
            line: 293,
            column: 28
          },
          end: {
            line: 295,
            column: 7
          }
        },
        line: 293
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 295,
            column: 9
          },
          end: {
            line: 295,
            column: 10
          }
        },
        loc: {
          start: {
            line: 295,
            column: 21
          },
          end: {
            line: 303,
            column: 7
          }
        },
        line: 295
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 305,
            column: 19
          },
          end: {
            line: 305,
            column: 20
          }
        },
        loc: {
          start: {
            line: 305,
            column: 28
          },
          end: {
            line: 307,
            column: 7
          }
        },
        line: 305
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 307,
            column: 9
          },
          end: {
            line: 307,
            column: 10
          }
        },
        loc: {
          start: {
            line: 307,
            column: 21
          },
          end: {
            line: 312,
            column: 7
          }
        },
        line: 307
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 55,
            column: 20
          },
          end: {
            line: 55,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 55,
            column: 20
          },
          end: {
            line: 55,
            column: 41
          }
        }, {
          start: {
            line: 55,
            column: 45
          },
          end: {
            line: 55,
            column: 47
          }
        }],
        line: 55
      },
      '1': {
        loc: {
          start: {
            line: 56,
            column: 22
          },
          end: {
            line: 56,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 56,
            column: 22
          },
          end: {
            line: 56,
            column: 40
          }
        }, {
          start: {
            line: 56,
            column: 44
          },
          end: {
            line: 56,
            column: 49
          }
        }],
        line: 56
      },
      '2': {
        loc: {
          start: {
            line: 57,
            column: 21
          },
          end: {
            line: 57,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 57,
            column: 21
          },
          end: {
            line: 57,
            column: 38
          }
        }, {
          start: {
            line: 57,
            column: 42
          },
          end: {
            line: 57,
            column: 47
          }
        }],
        line: 57
      },
      '3': {
        loc: {
          start: {
            line: 58,
            column: 24
          },
          end: {
            line: 58,
            column: 53
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 58,
            column: 24
          },
          end: {
            line: 58,
            column: 44
          }
        }, {
          start: {
            line: 58,
            column: 48
          },
          end: {
            line: 58,
            column: 53
          }
        }],
        line: 58
      },
      '4': {
        loc: {
          start: {
            line: 59,
            column: 26
          },
          end: {
            line: 59,
            column: 57
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 59,
            column: 26
          },
          end: {
            line: 59,
            column: 48
          }
        }, {
          start: {
            line: 59,
            column: 52
          },
          end: {
            line: 59,
            column: 57
          }
        }],
        line: 59
      },
      '5': {
        loc: {
          start: {
            line: 61,
            column: 24
          },
          end: {
            line: 61,
            column: 53
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 61,
            column: 24
          },
          end: {
            line: 61,
            column: 44
          }
        }, {
          start: {
            line: 61,
            column: 48
          },
          end: {
            line: 61,
            column: 53
          }
        }],
        line: 61
      },
      '6': {
        loc: {
          start: {
            line: 62,
            column: 23
          },
          end: {
            line: 62,
            column: 51
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 62,
            column: 23
          },
          end: {
            line: 62,
            column: 42
          }
        }, {
          start: {
            line: 62,
            column: 46
          },
          end: {
            line: 62,
            column: 51
          }
        }],
        line: 62
      },
      '7': {
        loc: {
          start: {
            line: 63,
            column: 22
          },
          end: {
            line: 63,
            column: 53
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 63,
            column: 22
          },
          end: {
            line: 63,
            column: 40
          }
        }, {
          start: {
            line: 63,
            column: 44
          },
          end: {
            line: 63,
            column: 53
          }
        }],
        line: 63
      },
      '8': {
        loc: {
          start: {
            line: 64,
            column: 22
          },
          end: {
            line: 64,
            column: 53
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 64,
            column: 22
          },
          end: {
            line: 64,
            column: 40
          }
        }, {
          start: {
            line: 64,
            column: 44
          },
          end: {
            line: 64,
            column: 53
          }
        }],
        line: 64
      },
      '9': {
        loc: {
          start: {
            line: 65,
            column: 23
          },
          end: {
            line: 65,
            column: 51
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 65,
            column: 23
          },
          end: {
            line: 65,
            column: 42
          }
        }, {
          start: {
            line: 65,
            column: 46
          },
          end: {
            line: 65,
            column: 51
          }
        }],
        line: 65
      },
      '10': {
        loc: {
          start: {
            line: 66,
            column: 19
          },
          end: {
            line: 66,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 66,
            column: 19
          },
          end: {
            line: 66,
            column: 34
          }
        }, {
          start: {
            line: 66,
            column: 38
          },
          end: {
            line: 66,
            column: 47
          }
        }],
        line: 66
      },
      '11': {
        loc: {
          start: {
            line: 68,
            column: 27
          },
          end: {
            line: 68,
            column: 63
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 68,
            column: 27
          },
          end: {
            line: 68,
            column: 50
          }
        }, {
          start: {
            line: 68,
            column: 54
          },
          end: {
            line: 68,
            column: 63
          }
        }],
        line: 68
      },
      '12': {
        loc: {
          start: {
            line: 69,
            column: 23
          },
          end: {
            line: 69,
            column: 55
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 69,
            column: 23
          },
          end: {
            line: 69,
            column: 42
          }
        }, {
          start: {
            line: 69,
            column: 46
          },
          end: {
            line: 69,
            column: 55
          }
        }],
        line: 69
      },
      '13': {
        loc: {
          start: {
            line: 70,
            column: 15
          },
          end: {
            line: 70,
            column: 39
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 70,
            column: 15
          },
          end: {
            line: 70,
            column: 26
          }
        }, {
          start: {
            line: 70,
            column: 30
          },
          end: {
            line: 70,
            column: 39
          }
        }],
        line: 70
      },
      '14': {
        loc: {
          start: {
            line: 71,
            column: 19
          },
          end: {
            line: 71,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 71,
            column: 19
          },
          end: {
            line: 71,
            column: 34
          }
        }, {
          start: {
            line: 71,
            column: 38
          },
          end: {
            line: 71,
            column: 47
          }
        }],
        line: 71
      },
      '15': {
        loc: {
          start: {
            line: 72,
            column: 16
          },
          end: {
            line: 72,
            column: 38
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 72,
            column: 16
          },
          end: {
            line: 72,
            column: 28
          }
        }, {
          start: {
            line: 72,
            column: 32
          },
          end: {
            line: 72,
            column: 38
          }
        }],
        line: 72
      },
      '16': {
        loc: {
          start: {
            line: 73,
            column: 17
          },
          end: {
            line: 73,
            column: 43
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 73,
            column: 17
          },
          end: {
            line: 73,
            column: 30
          }
        }, {
          start: {
            line: 73,
            column: 34
          },
          end: {
            line: 73,
            column: 43
          }
        }],
        line: 73
      },
      '17': {
        loc: {
          start: {
            line: 74,
            column: 25
          },
          end: {
            line: 74,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 74,
            column: 25
          },
          end: {
            line: 74,
            column: 46
          }
        }, {
          start: {
            line: 74,
            column: 50
          },
          end: {
            line: 74,
            column: 59
          }
        }],
        line: 74
      },
      '18': {
        loc: {
          start: {
            line: 75,
            column: 24
          },
          end: {
            line: 75,
            column: 106
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 75,
            column: 68
          },
          end: {
            line: 75,
            column: 72
          }
        }, {
          start: {
            line: 75,
            column: 76
          },
          end: {
            line: 75,
            column: 105
          }
        }],
        line: 75
      },
      '19': {
        loc: {
          start: {
            line: 75,
            column: 76
          },
          end: {
            line: 75,
            column: 105
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 75,
            column: 76
          },
          end: {
            line: 75,
            column: 96
          }
        }, {
          start: {
            line: 75,
            column: 100
          },
          end: {
            line: 75,
            column: 105
          }
        }],
        line: 75
      },
      '20': {
        loc: {
          start: {
            line: 77,
            column: 24
          },
          end: {
            line: 77,
            column: 115
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 77,
            column: 64
          },
          end: {
            line: 77,
            column: 73
          }
        }, {
          start: {
            line: 77,
            column: 76
          },
          end: {
            line: 77,
            column: 114
          }
        }],
        line: 77
      },
      '21': {
        loc: {
          start: {
            line: 77,
            column: 76
          },
          end: {
            line: 77,
            column: 114
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 77,
            column: 76
          },
          end: {
            line: 77,
            column: 96
          }
        }, {
          start: {
            line: 77,
            column: 100
          },
          end: {
            line: 77,
            column: 114
          }
        }],
        line: 77
      },
      '22': {
        loc: {
          start: {
            line: 78,
            column: 22
          },
          end: {
            line: 78,
            column: 71
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 78,
            column: 22
          },
          end: {
            line: 78,
            column: 40
          }
        }, {
          start: {
            line: 78,
            column: 44
          },
          end: {
            line: 78,
            column: 71
          }
        }],
        line: 78
      },
      '23': {
        loc: {
          start: {
            line: 79,
            column: 28
          },
          end: {
            line: 79,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 79,
            column: 28
          },
          end: {
            line: 79,
            column: 43
          }
        }, {
          start: {
            line: 79,
            column: 47
          },
          end: {
            line: 79,
            column: 49
          }
        }],
        line: 79
      },
      '24': {
        loc: {
          start: {
            line: 80,
            column: 26
          },
          end: {
            line: 80,
            column: 54
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 80,
            column: 26
          },
          end: {
            line: 80,
            column: 48
          }
        }, {
          start: {
            line: 80,
            column: 52
          },
          end: {
            line: 80,
            column: 54
          }
        }],
        line: 80
      },
      '25': {
        loc: {
          start: {
            line: 81,
            column: 17
          },
          end: {
            line: 81,
            column: 36
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 81,
            column: 17
          },
          end: {
            line: 81,
            column: 30
          }
        }, {
          start: {
            line: 81,
            column: 34
          },
          end: {
            line: 81,
            column: 36
          }
        }],
        line: 81
      },
      '26': {
        loc: {
          start: {
            line: 83,
            column: 10
          },
          end: {
            line: 85,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 83,
            column: 10
          },
          end: {
            line: 85,
            column: 11
          }
        }, {
          start: {
            line: 83,
            column: 10
          },
          end: {
            line: 85,
            column: 11
          }
        }],
        line: 83
      },
      '27': {
        loc: {
          start: {
            line: 89,
            column: 10
          },
          end: {
            line: 91,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 89,
            column: 10
          },
          end: {
            line: 91,
            column: 11
          }
        }, {
          start: {
            line: 89,
            column: 10
          },
          end: {
            line: 91,
            column: 11
          }
        }],
        line: 89
      },
      '28': {
        loc: {
          start: {
            line: 95,
            column: 10
          },
          end: {
            line: 97,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 95,
            column: 10
          },
          end: {
            line: 97,
            column: 11
          }
        }, {
          start: {
            line: 95,
            column: 10
          },
          end: {
            line: 97,
            column: 11
          }
        }],
        line: 95
      },
      '29': {
        loc: {
          start: {
            line: 101,
            column: 10
          },
          end: {
            line: 103,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 101,
            column: 10
          },
          end: {
            line: 103,
            column: 11
          }
        }, {
          start: {
            line: 101,
            column: 10
          },
          end: {
            line: 103,
            column: 11
          }
        }],
        line: 101
      },
      '30': {
        loc: {
          start: {
            line: 107,
            column: 10
          },
          end: {
            line: 109,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 107,
            column: 10
          },
          end: {
            line: 109,
            column: 11
          }
        }, {
          start: {
            line: 107,
            column: 10
          },
          end: {
            line: 109,
            column: 11
          }
        }],
        line: 107
      },
      '31': {
        loc: {
          start: {
            line: 114,
            column: 10
          },
          end: {
            line: 116,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 114,
            column: 10
          },
          end: {
            line: 116,
            column: 11
          }
        }, {
          start: {
            line: 114,
            column: 10
          },
          end: {
            line: 116,
            column: 11
          }
        }],
        line: 114
      },
      '32': {
        loc: {
          start: {
            line: 120,
            column: 10
          },
          end: {
            line: 125,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 120,
            column: 10
          },
          end: {
            line: 125,
            column: 11
          }
        }, {
          start: {
            line: 120,
            column: 10
          },
          end: {
            line: 125,
            column: 11
          }
        }],
        line: 120
      },
      '33': {
        loc: {
          start: {
            line: 121,
            column: 12
          },
          end: {
            line: 123,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 121,
            column: 12
          },
          end: {
            line: 123,
            column: 13
          }
        }, {
          start: {
            line: 121,
            column: 12
          },
          end: {
            line: 123,
            column: 13
          }
        }],
        line: 121
      },
      '34': {
        loc: {
          start: {
            line: 128,
            column: 23
          },
          end: {
            line: 128,
            column: 78
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 128,
            column: 53
          },
          end: {
            line: 128,
            column: 63
          }
        }, {
          start: {
            line: 128,
            column: 66
          },
          end: {
            line: 128,
            column: 78
          }
        }],
        line: 128
      },
      '35': {
        loc: {
          start: {
            line: 131,
            column: 12
          },
          end: {
            line: 133,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 131,
            column: 12
          },
          end: {
            line: 133,
            column: 13
          }
        }, {
          start: {
            line: 131,
            column: 12
          },
          end: {
            line: 133,
            column: 13
          }
        }],
        line: 131
      },
      '36': {
        loc: {
          start: {
            line: 134,
            column: 12
          },
          end: {
            line: 136,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 134,
            column: 12
          },
          end: {
            line: 136,
            column: 13
          }
        }, {
          start: {
            line: 134,
            column: 12
          },
          end: {
            line: 136,
            column: 13
          }
        }],
        line: 134
      },
      '37': {
        loc: {
          start: {
            line: 139,
            column: 10
          },
          end: {
            line: 141,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 139,
            column: 10
          },
          end: {
            line: 141,
            column: 11
          }
        }, {
          start: {
            line: 139,
            column: 10
          },
          end: {
            line: 141,
            column: 11
          }
        }],
        line: 139
      },
      '38': {
        loc: {
          start: {
            line: 144,
            column: 10
          },
          end: {
            line: 149,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 144,
            column: 10
          },
          end: {
            line: 149,
            column: 11
          }
        }, {
          start: {
            line: 144,
            column: 10
          },
          end: {
            line: 149,
            column: 11
          }
        }],
        line: 144
      },
      '39': {
        loc: {
          start: {
            line: 145,
            column: 12
          },
          end: {
            line: 147,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 145,
            column: 12
          },
          end: {
            line: 147,
            column: 13
          }
        }, {
          start: {
            line: 145,
            column: 12
          },
          end: {
            line: 147,
            column: 13
          }
        }],
        line: 145
      },
      '40': {
        loc: {
          start: {
            line: 153,
            column: 12
          },
          end: {
            line: 155,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 153,
            column: 12
          },
          end: {
            line: 155,
            column: 13
          }
        }, {
          start: {
            line: 153,
            column: 12
          },
          end: {
            line: 155,
            column: 13
          }
        }],
        line: 153
      },
      '41': {
        loc: {
          start: {
            line: 156,
            column: 23
          },
          end: {
            line: 156,
            column: 35
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 156,
            column: 23
          },
          end: {
            line: 156,
            column: 30
          }
        }, {
          start: {
            line: 156,
            column: 34
          },
          end: {
            line: 156,
            column: 35
          }
        }],
        line: 156
      },
      '42': {
        loc: {
          start: {
            line: 157,
            column: 12
          },
          end: {
            line: 159,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 157,
            column: 12
          },
          end: {
            line: 159,
            column: 13
          }
        }, {
          start: {
            line: 157,
            column: 12
          },
          end: {
            line: 159,
            column: 13
          }
        }],
        line: 157
      },
      '43': {
        loc: {
          start: {
            line: 162,
            column: 10
          },
          end: {
            line: 164,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 162,
            column: 10
          },
          end: {
            line: 164,
            column: 11
          }
        }, {
          start: {
            line: 162,
            column: 10
          },
          end: {
            line: 164,
            column: 11
          }
        }],
        line: 162
      },
      '44': {
        loc: {
          start: {
            line: 167,
            column: 10
          },
          end: {
            line: 179,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 167,
            column: 10
          },
          end: {
            line: 179,
            column: 11
          }
        }, {
          start: {
            line: 167,
            column: 10
          },
          end: {
            line: 179,
            column: 11
          }
        }],
        line: 167
      },
      '45': {
        loc: {
          start: {
            line: 169,
            column: 12
          },
          end: {
            line: 171,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 169,
            column: 12
          },
          end: {
            line: 171,
            column: 13
          }
        }, {
          start: {
            line: 169,
            column: 12
          },
          end: {
            line: 171,
            column: 13
          }
        }],
        line: 169
      },
      '46': {
        loc: {
          start: {
            line: 174,
            column: 14
          },
          end: {
            line: 176,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 174,
            column: 14
          },
          end: {
            line: 176,
            column: 15
          }
        }, {
          start: {
            line: 174,
            column: 14
          },
          end: {
            line: 176,
            column: 15
          }
        }],
        line: 174
      },
      '47': {
        loc: {
          start: {
            line: 180,
            column: 10
          },
          end: {
            line: 182,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 180,
            column: 10
          },
          end: {
            line: 182,
            column: 11
          }
        }, {
          start: {
            line: 180,
            column: 10
          },
          end: {
            line: 182,
            column: 11
          }
        }],
        line: 180
      },
      '48': {
        loc: {
          start: {
            line: 188,
            column: 10
          },
          end: {
            line: 190,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 188,
            column: 10
          },
          end: {
            line: 190,
            column: 11
          }
        }, {
          start: {
            line: 188,
            column: 10
          },
          end: {
            line: 190,
            column: 11
          }
        }],
        line: 188
      },
      '49': {
        loc: {
          start: {
            line: 196,
            column: 10
          },
          end: {
            line: 198,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 196,
            column: 10
          },
          end: {
            line: 198,
            column: 11
          }
        }, {
          start: {
            line: 196,
            column: 10
          },
          end: {
            line: 198,
            column: 11
          }
        }],
        line: 196
      },
      '50': {
        loc: {
          start: {
            line: 204,
            column: 10
          },
          end: {
            line: 206,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 204,
            column: 10
          },
          end: {
            line: 206,
            column: 11
          }
        }, {
          start: {
            line: 204,
            column: 10
          },
          end: {
            line: 206,
            column: 11
          }
        }],
        line: 204
      },
      '51': {
        loc: {
          start: {
            line: 211,
            column: 10
          },
          end: {
            line: 213,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 211,
            column: 10
          },
          end: {
            line: 213,
            column: 11
          }
        }, {
          start: {
            line: 211,
            column: 10
          },
          end: {
            line: 213,
            column: 11
          }
        }],
        line: 211
      },
      '52': {
        loc: {
          start: {
            line: 220,
            column: 10
          },
          end: {
            line: 222,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 220,
            column: 10
          },
          end: {
            line: 222,
            column: 11
          }
        }, {
          start: {
            line: 220,
            column: 10
          },
          end: {
            line: 222,
            column: 11
          }
        }],
        line: 220
      },
      '53': {
        loc: {
          start: {
            line: 226,
            column: 10
          },
          end: {
            line: 228,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 226,
            column: 10
          },
          end: {
            line: 228,
            column: 11
          }
        }, {
          start: {
            line: 226,
            column: 10
          },
          end: {
            line: 228,
            column: 11
          }
        }],
        line: 226
      },
      '54': {
        loc: {
          start: {
            line: 232,
            column: 10
          },
          end: {
            line: 234,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 232,
            column: 10
          },
          end: {
            line: 234,
            column: 11
          }
        }, {
          start: {
            line: 232,
            column: 10
          },
          end: {
            line: 234,
            column: 11
          }
        }],
        line: 232
      },
      '55': {
        loc: {
          start: {
            line: 239,
            column: 8
          },
          end: {
            line: 247,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 239,
            column: 8
          },
          end: {
            line: 247,
            column: 9
          }
        }, {
          start: {
            line: 239,
            column: 8
          },
          end: {
            line: 247,
            column: 9
          }
        }],
        line: 239
      },
      '56': {
        loc: {
          start: {
            line: 255,
            column: 8
          },
          end: {
            line: 265,
            column: 9
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 256,
            column: 10
          },
          end: {
            line: 258,
            column: 18
          }
        }, {
          start: {
            line: 260,
            column: 10
          },
          end: {
            line: 262,
            column: 18
          }
        }, {
          start: {
            line: 263,
            column: 10
          },
          end: {
            line: 263,
            column: 18
          }
        }],
        line: 255
      },
      '57': {
        loc: {
          start: {
            line: 271,
            column: 8
          },
          end: {
            line: 274,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 271,
            column: 8
          },
          end: {
            line: 274,
            column: 9
          }
        }, {
          start: {
            line: 271,
            column: 8
          },
          end: {
            line: 274,
            column: 9
          }
        }],
        line: 271
      },
      '58': {
        loc: {
          start: {
            line: 272,
            column: 35
          },
          end: {
            line: 272,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 272,
            column: 35
          },
          end: {
            line: 272,
            column: 43
          }
        }, {
          start: {
            line: 272,
            column: 47
          },
          end: {
            line: 272,
            column: 49
          }
        }],
        line: 272
      },
      '59': {
        loc: {
          start: {
            line: 280,
            column: 8
          },
          end: {
            line: 283,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 280,
            column: 8
          },
          end: {
            line: 283,
            column: 9
          }
        }, {
          start: {
            line: 280,
            column: 8
          },
          end: {
            line: 283,
            column: 9
          }
        }],
        line: 280
      },
      '60': {
        loc: {
          start: {
            line: 281,
            column: 34
          },
          end: {
            line: 281,
            column: 55
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 281,
            column: 34
          },
          end: {
            line: 281,
            column: 42
          }
        }, {
          start: {
            line: 281,
            column: 46
          },
          end: {
            line: 281,
            column: 55
          }
        }],
        line: 281
      },
      '61': {
        loc: {
          start: {
            line: 288,
            column: 8
          },
          end: {
            line: 291,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 288,
            column: 8
          },
          end: {
            line: 291,
            column: 9
          }
        }, {
          start: {
            line: 288,
            column: 8
          },
          end: {
            line: 291,
            column: 9
          }
        }],
        line: 288
      },
      '62': {
        loc: {
          start: {
            line: 289,
            column: 41
          },
          end: {
            line: 289,
            column: 62
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 289,
            column: 41
          },
          end: {
            line: 289,
            column: 49
          }
        }, {
          start: {
            line: 289,
            column: 53
          },
          end: {
            line: 289,
            column: 62
          }
        }],
        line: 289
      },
      '63': {
        loc: {
          start: {
            line: 296,
            column: 8
          },
          end: {
            line: 298,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 296,
            column: 8
          },
          end: {
            line: 298,
            column: 9
          }
        }, {
          start: {
            line: 296,
            column: 8
          },
          end: {
            line: 298,
            column: 9
          }
        }],
        line: 296
      },
      '64': {
        loc: {
          start: {
            line: 299,
            column: 8
          },
          end: {
            line: 302,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 299,
            column: 8
          },
          end: {
            line: 302,
            column: 9
          }
        }, {
          start: {
            line: 299,
            column: 8
          },
          end: {
            line: 302,
            column: 9
          }
        }],
        line: 299
      },
      '65': {
        loc: {
          start: {
            line: 300,
            column: 38
          },
          end: {
            line: 300,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 300,
            column: 38
          },
          end: {
            line: 300,
            column: 46
          }
        }, {
          start: {
            line: 300,
            column: 50
          },
          end: {
            line: 300,
            column: 59
          }
        }],
        line: 300
      },
      '66': {
        loc: {
          start: {
            line: 308,
            column: 8
          },
          end: {
            line: 311,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 308,
            column: 8
          },
          end: {
            line: 311,
            column: 9
          }
        }, {
          start: {
            line: 308,
            column: 8
          },
          end: {
            line: 311,
            column: 9
          }
        }],
        line: 308
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0],
      '50': [0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0],
      '54': [0, 0],
      '55': [0, 0],
      '56': [0, 0, 0],
      '57': [0, 0],
      '58': [0, 0],
      '59': [0, 0],
      '60': [0, 0],
      '61': [0, 0],
      '62': [0, 0],
      '63': [0, 0],
      '64': [0, 0],
      '65': [0, 0],
      '66': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1xykcjwlqp.s[0]++;
angular.module('app').directive('tableSyncfusion', function ($timeout) {
  cov_1xykcjwlqp.f[0]++;
  cov_1xykcjwlqp.s[1]++;

  return {
    restrict: 'A',
    scope: {
      temp: '=?',
      columns: '=',
      // aggregates: '=',
      tableDataSource: '=',
      contextMenuItems: '=?',
      allowPdfExport: '=?',
      allowExcelExport: '=?',
      editSettings: '=?',
      sortSettings: '=?',
      allowSorting: '=?',
      showColumnMenu: '=?',
      height: '=?',
      allowSelection: '=?',
      allowTextWrap: '=?',
      gridLines: '=?',
      frozenColumns: '=?',
      allowPaging: '=?',
      allowFiltering: '=?',
      toolbar: '=?',
      toolbarTemplate: '=?',
      pageSettings: '=?',
      filterSettings: '=?',
      tableDataSelected: '=?',
      rowHeight: '=?',
      selectionSettings: '=?',
      childGrid: '=?',
      selectItemChange: '&',
      recordClick: '&',
      recordDoubleClick: '&',
      columnSelected: '&',
      actionBegin: '&',
      commandClick: '&',
      queryCellInfo: '&',
      excelQueryCellInfo: '&',
      boundEvent: '&',
      pdfQueryCellInfo: '&',
      contextMenuClick: '&',
      contextMenuOpen: '&',
      cellSelected: '&',
      cellDeselected: '&',
      created: '&',
      allowResizing: '=?'
    },
    link: function link(scope, element, attrs) {
      cov_1xykcjwlqp.s[2]++;

      scope.table = null;
      cov_1xykcjwlqp.s[3]++;
      element.addClass('alternate-row');
      var selecthistoric = (cov_1xykcjwlqp.s[4]++, null);
      var params = (cov_1xykcjwlqp.s[5]++, {
        enable3D: true,
        enableCanvas: true,
        dataSource: (cov_1xykcjwlqp.b[0][0]++, scope.tableDataSource) || (cov_1xykcjwlqp.b[0][1]++, []),
        allowSorting: (cov_1xykcjwlqp.b[1][0]++, scope.allowSorting) || (cov_1xykcjwlqp.b[1][1]++, false),
        allowPaging: (cov_1xykcjwlqp.b[2][0]++, scope.allowPaging) || (cov_1xykcjwlqp.b[2][1]++, false),
        allowPdfExport: (cov_1xykcjwlqp.b[3][0]++, scope.allowPdfExport) || (cov_1xykcjwlqp.b[3][1]++, false),
        allowExcelExport: (cov_1xykcjwlqp.b[4][0]++, scope.allowExcelExport) || (cov_1xykcjwlqp.b[4][1]++, false),
        showColumnMenu: false, // scope.showColumnMenu || false,
        allowFiltering: (cov_1xykcjwlqp.b[5][0]++, scope.allowFiltering) || (cov_1xykcjwlqp.b[5][1]++, false),
        allowTextWrap: (cov_1xykcjwlqp.b[6][0]++, scope.allowTextWrap) || (cov_1xykcjwlqp.b[6][1]++, false),
        editSettings: (cov_1xykcjwlqp.b[7][0]++, scope.editSettings) || (cov_1xykcjwlqp.b[7][1]++, undefined),
        sortSettings: (cov_1xykcjwlqp.b[8][0]++, scope.sortSettings) || (cov_1xykcjwlqp.b[8][1]++, undefined),
        allowResizing: (cov_1xykcjwlqp.b[9][0]++, scope.allowResizing) || (cov_1xykcjwlqp.b[9][1]++, false),
        childGrid: (cov_1xykcjwlqp.b[10][0]++, scope.childGrid) || (cov_1xykcjwlqp.b[10][1]++, undefined),
        // aggregates: scope.aggregates || undefined,
        selectionSettings: (cov_1xykcjwlqp.b[11][0]++, scope.selectionSettings) || (cov_1xykcjwlqp.b[11][1]++, undefined),
        frozenColumns: (cov_1xykcjwlqp.b[12][0]++, scope.frozenColumns) || (cov_1xykcjwlqp.b[12][1]++, undefined),
        width: (cov_1xykcjwlqp.b[13][0]++, scope.width) || (cov_1xykcjwlqp.b[13][1]++, undefined),
        gridLines: (cov_1xykcjwlqp.b[14][0]++, scope.gridLines) || (cov_1xykcjwlqp.b[14][1]++, undefined),
        height: (cov_1xykcjwlqp.b[15][0]++, scope.height) || (cov_1xykcjwlqp.b[15][1]++, '100%'),
        toolbar: (cov_1xykcjwlqp.b[16][0]++, scope.toolbar) || (cov_1xykcjwlqp.b[16][1]++, undefined),
        toolbarTemplate: (cov_1xykcjwlqp.b[17][0]++, scope.toolbarTemplate) || (cov_1xykcjwlqp.b[17][1]++, undefined),
        allowSelection: angular.isUndefined(scope.allowSelection) ? (cov_1xykcjwlqp.b[18][0]++, true) : (cov_1xykcjwlqp.b[18][1]++, (cov_1xykcjwlqp.b[19][0]++, scope.allowSelection) || (cov_1xykcjwlqp.b[19][1]++, false)),
        // enableVirtualization: true,
        filterSettings: scope.filterSettings == 'filterbar' ? (cov_1xykcjwlqp.b[20][0]++, undefined) : (cov_1xykcjwlqp.b[20][1]++, (cov_1xykcjwlqp.b[21][0]++, scope.filterSettings) || (cov_1xykcjwlqp.b[21][1]++, { type: 'Menu' })),
        pageSettings: (cov_1xykcjwlqp.b[22][0]++, scope.pageSettings) || (cov_1xykcjwlqp.b[22][1]++, { pageSize: 6, pageCount: 3 }),
        rowHeight: Math.max((cov_1xykcjwlqp.b[23][0]++, scope.rowHeight) || (cov_1xykcjwlqp.b[23][1]++, 38), 48),
        contextMenuItems: (cov_1xykcjwlqp.b[24][0]++, scope.contextMenuItems) || (cov_1xykcjwlqp.b[24][1]++, []),
        columns: (cov_1xykcjwlqp.b[25][0]++, scope.columns) || (cov_1xykcjwlqp.b[25][1]++, []),
        contextMenuOpen: function contextMenuOpen(args) {
          cov_1xykcjwlqp.s[6]++;

          if (!scope.contextMenuOpen) {
            cov_1xykcjwlqp.b[26][0]++;
            cov_1xykcjwlqp.s[7]++;

            return;
          } else {
            cov_1xykcjwlqp.b[26][1]++;
          }
          cov_1xykcjwlqp.s[8]++;
          scope.contextMenuOpen({ args: args });
        },
        cellSelected: function cellSelected(args) {
          cov_1xykcjwlqp.s[9]++;

          if (!scope.cellSelected) {
            cov_1xykcjwlqp.b[27][0]++;
            cov_1xykcjwlqp.s[10]++;

            return;
          } else {
            cov_1xykcjwlqp.b[27][1]++;
          }
          cov_1xykcjwlqp.s[11]++;
          scope.cellSelected({ args: args });
        },
        cellDeselected: function cellDeselected(args) {
          cov_1xykcjwlqp.s[12]++;

          if (!scope.cellDeselected) {
            cov_1xykcjwlqp.b[28][0]++;
            cov_1xykcjwlqp.s[13]++;

            return;
          } else {
            cov_1xykcjwlqp.b[28][1]++;
          }
          cov_1xykcjwlqp.s[14]++;
          scope.cellDeselected({ args: args });
        },
        queryCellInfo: function queryCellInfo(args) {
          cov_1xykcjwlqp.s[15]++;

          if (!scope.queryCellInfo) {
            cov_1xykcjwlqp.b[29][0]++;
            cov_1xykcjwlqp.s[16]++;

            return;
          } else {
            cov_1xykcjwlqp.b[29][1]++;
          }
          cov_1xykcjwlqp.s[17]++;
          scope.queryCellInfo({ args: args });
        },
        excelQueryCellInfo: function excelQueryCellInfo(args) {
          cov_1xykcjwlqp.s[18]++;

          if (!scope.excelQueryCellInfo) {
            cov_1xykcjwlqp.b[30][0]++;
            cov_1xykcjwlqp.s[19]++;

            return;
          } else {
            cov_1xykcjwlqp.b[30][1]++;
          }
          cov_1xykcjwlqp.s[20]++;
          scope.excelQueryCellInfo({ args: args });
        },
        pdfQueryCellInfo: function pdfQueryCellInfo(args) {
          cov_1xykcjwlqp.s[21]++;

          if (!scope.pdfQueryCellInfo) {
            cov_1xykcjwlqp.b[31][0]++;
            cov_1xykcjwlqp.s[22]++;

            return;
          } else {
            cov_1xykcjwlqp.b[31][1]++;
          }
          cov_1xykcjwlqp.s[23]++;
          scope.pdfQueryCellInfo({ args: args });
        },
        rowSelected: function rowSelected(event) {
          cov_1xykcjwlqp.s[24]++;

          if (!scope.tableDataSelected) {
            cov_1xykcjwlqp.b[32][0]++;
            cov_1xykcjwlqp.s[25]++;

            if (scope.selectItemChange) {
              cov_1xykcjwlqp.b[33][0]++;
              cov_1xykcjwlqp.s[26]++;

              scope.selectItemChange({ selectedItems: scope.table.getSelectedRecords() });
            } else {
              cov_1xykcjwlqp.b[33][1]++;
            }
            cov_1xykcjwlqp.s[27]++;
            return;
          } else {
            cov_1xykcjwlqp.b[32][1]++;
          }
          // const selectedAll = angular.isArray(event.data) && scope.allowPaging && event.isInteracted;

          var data = (cov_1xykcjwlqp.s[28]++, angular.isArray(event.data) ? (cov_1xykcjwlqp.b[34][0]++, event.data) : (cov_1xykcjwlqp.b[34][1]++, [event.data]));

          cov_1xykcjwlqp.s[29]++;
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = /* selectedAll ? scope.table.dataSource : */data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var item = _step.value;
              cov_1xykcjwlqp.s[30]++;

              if (!item) {
                cov_1xykcjwlqp.b[35][0]++;
                cov_1xykcjwlqp.s[31]++;

                continue;
              } else {
                cov_1xykcjwlqp.b[35][1]++;
              }
              cov_1xykcjwlqp.s[32]++;
              if (item.id) {
                cov_1xykcjwlqp.b[36][0]++;
                cov_1xykcjwlqp.s[33]++;

                scope.tableDataSelected[item.id] = true;
              } else {
                cov_1xykcjwlqp.b[36][1]++;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          cov_1xykcjwlqp.s[34]++;
          if (scope.selectItemChange) {
            cov_1xykcjwlqp.b[37][0]++;
            cov_1xykcjwlqp.s[35]++;

            scope.selectItemChange({ selectedItems: scope.tableDataSelected });
          } else {
            cov_1xykcjwlqp.b[37][1]++;
          }
        },
        rowDeselected: function rowDeselected(event) {
          cov_1xykcjwlqp.s[36]++;

          if (!scope.tableDataSelected) {
            cov_1xykcjwlqp.b[38][0]++;
            cov_1xykcjwlqp.s[37]++;

            if (scope.selectItemChange) {
              cov_1xykcjwlqp.b[39][0]++;
              cov_1xykcjwlqp.s[38]++;

              scope.selectItemChange({ selectedItems: scope.table.getSelectedRecords() });
            } else {
              cov_1xykcjwlqp.b[39][1]++;
            }
            cov_1xykcjwlqp.s[39]++;
            return;
          } else {
            cov_1xykcjwlqp.b[38][1]++;
          }
          // const unselectedAll = event.data.length > 1 && scope.allowPaging && event.isInteracted;

          cov_1xykcjwlqp.s[40]++;
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = /* unselectedAll ? scope.table.dataSource : */event.data[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var item = _step2.value;
              cov_1xykcjwlqp.s[41]++;

              if (!item) {
                cov_1xykcjwlqp.b[40][0]++;
                cov_1xykcjwlqp.s[42]++;

                continue;
              } else {
                cov_1xykcjwlqp.b[40][1]++;
              }
              var id = (cov_1xykcjwlqp.s[43]++, (cov_1xykcjwlqp.b[41][0]++, item.id) || (cov_1xykcjwlqp.b[41][1]++, 0));
              cov_1xykcjwlqp.s[44]++;
              if (scope.tableDataSelected[id]) {
                cov_1xykcjwlqp.b[42][0]++;
                cov_1xykcjwlqp.s[45]++;

                delete scope.tableDataSelected[id];
              } else {
                cov_1xykcjwlqp.b[42][1]++;
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          cov_1xykcjwlqp.s[46]++;
          if (scope.selectItemChange) {
            cov_1xykcjwlqp.b[43][0]++;
            cov_1xykcjwlqp.s[47]++;

            scope.selectItemChange({ selectedItems: scope.tableDataSelected });
          } else {
            cov_1xykcjwlqp.b[43][1]++;
          }
        },
        dataBound: function dataBound(args) {
          cov_1xykcjwlqp.s[48]++;

          if (scope.tableDataSelected) {
            cov_1xykcjwlqp.b[44][0]++;

            var rowIndexes = (cov_1xykcjwlqp.s[49]++, []);
            cov_1xykcjwlqp.s[50]++;
            if (selecthistoric) {
              cov_1xykcjwlqp.b[45][0]++;
              cov_1xykcjwlqp.s[51]++;

              scope.tableDataSelected = angular.copy(selecthistoric);
            } else {
              cov_1xykcjwlqp.b[45][1]++;
            }
            cov_1xykcjwlqp.s[52]++;
            selecthistoric = null;
            cov_1xykcjwlqp.s[53]++;
            scope.table.getDataRows().forEach(function (row, index) {
              cov_1xykcjwlqp.f[1]++;
              cov_1xykcjwlqp.s[54]++;

              if (scope.tableDataSelected[row.cells[0].textContent]) {
                cov_1xykcjwlqp.b[46][0]++;
                cov_1xykcjwlqp.s[55]++;

                rowIndexes.push(index);
              } else {
                cov_1xykcjwlqp.b[46][1]++;
              }
            });
            cov_1xykcjwlqp.s[56]++;
            scope.table.selectionModule.selectRows(rowIndexes);
          } else {
            cov_1xykcjwlqp.b[44][1]++;
          }
          cov_1xykcjwlqp.s[57]++;
          if (!scope.boundEvent) {
            cov_1xykcjwlqp.b[47][0]++;
            cov_1xykcjwlqp.s[58]++;

            return;
          } else {
            cov_1xykcjwlqp.b[47][1]++;
          }
          cov_1xykcjwlqp.s[59]++;
          args.table = scope.table;
          cov_1xykcjwlqp.s[60]++;
          scope.boundEvent({ args: args });
        },
        created: function created(args) {
          cov_1xykcjwlqp.s[61]++;

          if (!scope.created) {
            cov_1xykcjwlqp.b[48][0]++;
            cov_1xykcjwlqp.s[62]++;

            return;
          } else {
            cov_1xykcjwlqp.b[48][1]++;
          }
          cov_1xykcjwlqp.s[63]++;
          scope.created({ args: args });
        },
        actionBegin: function actionBegin(action) {
          cov_1xykcjwlqp.s[64]++;

          selecthistoric = angular.copy(scope.tableDataSelected);
          cov_1xykcjwlqp.s[65]++;
          if (!scope.actionBegin) {
            cov_1xykcjwlqp.b[49][0]++;
            cov_1xykcjwlqp.s[66]++;

            return;
          } else {
            cov_1xykcjwlqp.b[49][1]++;
          }
          cov_1xykcjwlqp.s[67]++;
          scope.actionBegin({ action: action });
        },
        commandClick: function commandClick(action) {
          cov_1xykcjwlqp.s[68]++;

          selecthistoric = angular.copy(scope.tableDataSelected);
          cov_1xykcjwlqp.s[69]++;
          if (!scope.commandClick) {
            cov_1xykcjwlqp.b[50][0]++;
            cov_1xykcjwlqp.s[70]++;

            return;
          } else {
            cov_1xykcjwlqp.b[50][1]++;
          }
          cov_1xykcjwlqp.s[71]++;
          scope.commandClick({ action: action });
        },
        recordClick: function recordClick(event) {
          cov_1xykcjwlqp.s[72]++;

          if (!scope.recordClick) {
            cov_1xykcjwlqp.b[51][0]++;
            cov_1xykcjwlqp.s[73]++;

            return;
          } else {
            cov_1xykcjwlqp.b[51][1]++;
          }
          var rowData = (cov_1xykcjwlqp.s[74]++, event.rowData);
          cov_1xykcjwlqp.s[75]++;
          rowData.cell = event.cell;
          cov_1xykcjwlqp.s[76]++;
          scope.recordClick({ rowData: rowData });
        },
        recordDoubleClick: function recordDoubleClick(event) {
          cov_1xykcjwlqp.s[77]++;

          if (!scope.recordDoubleClick) {
            cov_1xykcjwlqp.b[52][0]++;
            cov_1xykcjwlqp.s[78]++;

            return;
          } else {
            cov_1xykcjwlqp.b[52][1]++;
          }
          cov_1xykcjwlqp.s[79]++;
          scope.recordDoubleClick({ rowData: event.rowData });
        },
        columnSelected: function columnSelected(event) {
          cov_1xykcjwlqp.s[80]++;

          if (!scope.columnSelected) {
            cov_1xykcjwlqp.b[53][0]++;
            cov_1xykcjwlqp.s[81]++;

            return;
          } else {
            cov_1xykcjwlqp.b[53][1]++;
          }
          cov_1xykcjwlqp.s[82]++;
          scope.columnSelected({ rowData: event.rowData });
        },
        contextMenuClick: function contextMenuClick(item) {
          cov_1xykcjwlqp.s[83]++;

          if (!scope.contextMenuClick) {
            cov_1xykcjwlqp.b[54][0]++;
            cov_1xykcjwlqp.s[84]++;

            return;
          } else {
            cov_1xykcjwlqp.b[54][1]++;
          }
          cov_1xykcjwlqp.s[85]++;
          scope.contextMenuClick({ item: item });
        }
      });
      cov_1xykcjwlqp.s[86]++;
      var render = function render() {
        cov_1xykcjwlqp.f[2]++;
        cov_1xykcjwlqp.s[87]++;

        if (scope.temp) {
          cov_1xykcjwlqp.b[55][0]++;
          cov_1xykcjwlqp.s[88]++;

          $timeout(function () {
            cov_1xykcjwlqp.f[3]++;
            cov_1xykcjwlqp.s[89]++;

            scope.table = new ej.grids.Grid(params);
            cov_1xykcjwlqp.s[90]++;
            scope.table.appendTo(element[0]);
          }, 150);
        } else {
          cov_1xykcjwlqp.b[55][1]++;
          cov_1xykcjwlqp.s[91]++;

          scope.table = new ej.grids.Grid(params);
          cov_1xykcjwlqp.s[92]++;
          scope.table.appendTo(element[0]);
        }
      };
      cov_1xykcjwlqp.s[93]++;
      scope.$on('$destroy', function () {
        cov_1xykcjwlqp.f[4]++;
        cov_1xykcjwlqp.s[94]++;

        $('.e-tooltip-wrap').remove();
        cov_1xykcjwlqp.s[95]++;
        $('.e-flmenu').remove();
        cov_1xykcjwlqp.s[96]++;
        $('.e-contextmenu-wrapper').remove();
      });
      cov_1xykcjwlqp.s[97]++;
      scope.$on('table_syncfusion_export', function (event, args) {
        cov_1xykcjwlqp.f[5]++;
        cov_1xykcjwlqp.s[98]++;

        switch (args.type.toUpperCase()) {
          case 'EXCEL':
            cov_1xykcjwlqp.b[56][0]++;
            cov_1xykcjwlqp.s[99]++;

            scope.table.excelExport(args.param);
            cov_1xykcjwlqp.s[100]++;
            break;

          case 'PDF':
            cov_1xykcjwlqp.b[56][1]++;
            cov_1xykcjwlqp.s[101]++;

            scope.table.pdfExport(args.param);
            cov_1xykcjwlqp.s[102]++;
            break;
          default:
            cov_1xykcjwlqp.b[56][2]++;


        }
      });

      cov_1xykcjwlqp.s[103]++;
      scope.$watch(function (scope) {
        cov_1xykcjwlqp.f[6]++;
        cov_1xykcjwlqp.s[104]++;

        return scope.tableDataSource;
      }, function (newValue) {
        cov_1xykcjwlqp.f[7]++;
        cov_1xykcjwlqp.s[105]++;

        if (scope.table) {
          cov_1xykcjwlqp.b[57][0]++;
          cov_1xykcjwlqp.s[106]++;

          scope.table.dataSource = (cov_1xykcjwlqp.b[58][0]++, newValue) || (cov_1xykcjwlqp.b[58][1]++, []);
          cov_1xykcjwlqp.s[107]++;
          scope.table.refresh();
        } else {
          cov_1xykcjwlqp.b[57][1]++;
        }
      });

      cov_1xykcjwlqp.s[108]++;
      scope.$watch(function (scope) {
        cov_1xykcjwlqp.f[8]++;
        cov_1xykcjwlqp.s[109]++;

        return scope.childGrid;
      }, function (newValue) {
        cov_1xykcjwlqp.f[9]++;
        cov_1xykcjwlqp.s[110]++;

        if (scope.table) {
          cov_1xykcjwlqp.b[59][0]++;
          cov_1xykcjwlqp.s[111]++;

          scope.table.childGrid = (cov_1xykcjwlqp.b[60][0]++, newValue) || (cov_1xykcjwlqp.b[60][1]++, undefined);
          cov_1xykcjwlqp.s[112]++;
          scope.table.refresh();
        } else {
          cov_1xykcjwlqp.b[59][1]++;
        }
      });
      cov_1xykcjwlqp.s[113]++;
      scope.$watch(function (scope) {
        cov_1xykcjwlqp.f[10]++;
        cov_1xykcjwlqp.s[114]++;

        return scope.contextMenuItems;
      }, function (newValue) {
        cov_1xykcjwlqp.f[11]++;
        cov_1xykcjwlqp.s[115]++;

        if (scope.table) {
          cov_1xykcjwlqp.b[61][0]++;
          cov_1xykcjwlqp.s[116]++;

          scope.table.contextMenuItems = (cov_1xykcjwlqp.b[62][0]++, newValue) || (cov_1xykcjwlqp.b[62][1]++, undefined);
          cov_1xykcjwlqp.s[117]++;
          scope.table.refresh();
        } else {
          cov_1xykcjwlqp.b[61][1]++;
        }
      });
      cov_1xykcjwlqp.s[118]++;
      scope.$watch(function (scope) {
        cov_1xykcjwlqp.f[12]++;
        cov_1xykcjwlqp.s[119]++;

        return scope.frozenColumns;
      }, function (newValue) {
        cov_1xykcjwlqp.f[13]++;
        cov_1xykcjwlqp.s[120]++;

        if (!scope.table) {
          cov_1xykcjwlqp.b[63][0]++;
          cov_1xykcjwlqp.s[121]++;

          return;
        } else {
          cov_1xykcjwlqp.b[63][1]++;
        }
        cov_1xykcjwlqp.s[122]++;
        if (scope.frozenColumns) {
          cov_1xykcjwlqp.b[64][0]++;
          cov_1xykcjwlqp.s[123]++;

          scope.table.frozenColumns = (cov_1xykcjwlqp.b[65][0]++, newValue) || (cov_1xykcjwlqp.b[65][1]++, undefined);
          cov_1xykcjwlqp.s[124]++;
          scope.table.refresh();
        } else {
          cov_1xykcjwlqp.b[64][1]++;
        }
      });

      cov_1xykcjwlqp.s[125]++;
      scope.$watch(function (scope) {
        cov_1xykcjwlqp.f[14]++;
        cov_1xykcjwlqp.s[126]++;

        return scope.columns;
      }, function (newValue) {
        cov_1xykcjwlqp.f[15]++;
        cov_1xykcjwlqp.s[127]++;

        if (scope.table) {
          cov_1xykcjwlqp.b[66][0]++;
          cov_1xykcjwlqp.s[128]++;

          scope.table.columns = newValue;
          cov_1xykcjwlqp.s[129]++;
          scope.table.refreshColumns();
        } else {
          cov_1xykcjwlqp.b[66][1]++;
        }
      });
      cov_1xykcjwlqp.s[130]++;
      render();
    }
  };
});
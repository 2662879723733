'use strict';

var cov_28b6aa4f3c = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/report_type/report_typeCtrl.js',
      hash = 'c92e8832ede7c68d2b97a0a5fac1a99e8db78541',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/report_type/report_typeCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 7,
          column: 0
        },
        end: {
          line: 181,
          column: 3
        }
      },
      '1': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 37
        }
      },
      '2': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 11,
          column: 3
        }
      },
      '3': {
        start: {
          line: 10,
          column: 4
        },
        end: {
          line: 10,
          column: 11
        }
      },
      '4': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 28
        }
      },
      '5': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 27
        }
      },
      '6': {
        start: {
          line: 14,
          column: 2
        },
        end: {
          line: 14,
          column: 31
        }
      },
      '7': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 17,
          column: 5
        }
      },
      '8': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 16,
          column: 130
        }
      },
      '9': {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 18,
          column: 41
        }
      },
      '10': {
        start: {
          line: 19,
          column: 2
        },
        end: {
          line: 19,
          column: 28
        }
      },
      '11': {
        start: {
          line: 20,
          column: 2
        },
        end: {
          line: 27,
          column: 4
        }
      },
      '12': {
        start: {
          line: 28,
          column: 2
        },
        end: {
          line: 28,
          column: 27
        }
      },
      '13': {
        start: {
          line: 29,
          column: 2
        },
        end: {
          line: 39,
          column: 4
        }
      },
      '14': {
        start: {
          line: 30,
          column: 4
        },
        end: {
          line: 38,
          column: 7
        }
      },
      '15': {
        start: {
          line: 40,
          column: 2
        },
        end: {
          line: 48,
          column: 4
        }
      },
      '16': {
        start: {
          line: 41,
          column: 4
        },
        end: {
          line: 43,
          column: 5
        }
      },
      '17': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 42,
          column: 19
        }
      },
      '18': {
        start: {
          line: 44,
          column: 4
        },
        end: {
          line: 46,
          column: 5
        }
      },
      '19': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 45,
          column: 19
        }
      },
      '20': {
        start: {
          line: 47,
          column: 4
        },
        end: {
          line: 47,
          column: 16
        }
      },
      '21': {
        start: {
          line: 49,
          column: 2
        },
        end: {
          line: 52,
          column: 4
        }
      },
      '22': {
        start: {
          line: 50,
          column: 4
        },
        end: {
          line: 50,
          column: 29
        }
      },
      '23': {
        start: {
          line: 51,
          column: 4
        },
        end: {
          line: 51,
          column: 31
        }
      },
      '24': {
        start: {
          line: 53,
          column: 2
        },
        end: {
          line: 66,
          column: 4
        }
      },
      '25': {
        start: {
          line: 54,
          column: 4
        },
        end: {
          line: 54,
          column: 27
        }
      },
      '26': {
        start: {
          line: 55,
          column: 4
        },
        end: {
          line: 55,
          column: 30
        }
      },
      '27': {
        start: {
          line: 57,
          column: 4
        },
        end: {
          line: 57,
          column: 45
        }
      },
      '28': {
        start: {
          line: 59,
          column: 4
        },
        end: {
          line: 59,
          column: 116
        }
      },
      '29': {
        start: {
          line: 60,
          column: 4
        },
        end: {
          line: 60,
          column: 124
        }
      },
      '30': {
        start: {
          line: 62,
          column: 4
        },
        end: {
          line: 62,
          column: 129
        }
      },
      '31': {
        start: {
          line: 64,
          column: 4
        },
        end: {
          line: 64,
          column: 24
        }
      },
      '32': {
        start: {
          line: 65,
          column: 4
        },
        end: {
          line: 65,
          column: 74
        }
      },
      '33': {
        start: {
          line: 67,
          column: 2
        },
        end: {
          line: 70,
          column: 4
        }
      },
      '34': {
        start: {
          line: 68,
          column: 4
        },
        end: {
          line: 68,
          column: 25
        }
      },
      '35': {
        start: {
          line: 69,
          column: 4
        },
        end: {
          line: 69,
          column: 30
        }
      },
      '36': {
        start: {
          line: 71,
          column: 2
        },
        end: {
          line: 117,
          column: 4
        }
      },
      '37': {
        start: {
          line: 72,
          column: 25
        },
        end: {
          line: 72,
          column: 58
        }
      },
      '38': {
        start: {
          line: 73,
          column: 4
        },
        end: {
          line: 73,
          column: 62
        }
      },
      '39': {
        start: {
          line: 74,
          column: 4
        },
        end: {
          line: 78,
          column: 5
        }
      },
      '40': {
        start: {
          line: 75,
          column: 6
        },
        end: {
          line: 75,
          column: 72
        }
      },
      '41': {
        start: {
          line: 77,
          column: 6
        },
        end: {
          line: 77,
          column: 37
        }
      },
      '42': {
        start: {
          line: 79,
          column: 4
        },
        end: {
          line: 79,
          column: 74
        }
      },
      '43': {
        start: {
          line: 80,
          column: 4
        },
        end: {
          line: 88,
          column: 5
        }
      },
      '44': {
        start: {
          line: 81,
          column: 6
        },
        end: {
          line: 85,
          column: 7
        }
      },
      '45': {
        start: {
          line: 82,
          column: 8
        },
        end: {
          line: 82,
          column: 57
        }
      },
      '46': {
        start: {
          line: 84,
          column: 8
        },
        end: {
          line: 84,
          column: 34
        }
      },
      '47': {
        start: {
          line: 87,
          column: 6
        },
        end: {
          line: 87,
          column: 32
        }
      },
      '48': {
        start: {
          line: 89,
          column: 18
        },
        end: {
          line: 89,
          column: 22
        }
      },
      '49': {
        start: {
          line: 90,
          column: 4
        },
        end: {
          line: 95,
          column: 5
        }
      },
      '50': {
        start: {
          line: 91,
          column: 6
        },
        end: {
          line: 91,
          column: 31
        }
      },
      '51': {
        start: {
          line: 92,
          column: 6
        },
        end: {
          line: 92,
          column: 65
        }
      },
      '52': {
        start: {
          line: 93,
          column: 6
        },
        end: {
          line: 93,
          column: 49
        }
      },
      '53': {
        start: {
          line: 94,
          column: 6
        },
        end: {
          line: 94,
          column: 44
        }
      },
      '54': {
        start: {
          line: 96,
          column: 4
        },
        end: {
          line: 98,
          column: 5
        }
      },
      '55': {
        start: {
          line: 97,
          column: 6
        },
        end: {
          line: 97,
          column: 30
        }
      },
      '56': {
        start: {
          line: 99,
          column: 4
        },
        end: {
          line: 99,
          column: 68
        }
      },
      '57': {
        start: {
          line: 100,
          column: 4
        },
        end: {
          line: 103,
          column: 5
        }
      },
      '58': {
        start: {
          line: 101,
          column: 6
        },
        end: {
          line: 101,
          column: 102
        }
      },
      '59': {
        start: {
          line: 102,
          column: 6
        },
        end: {
          line: 102,
          column: 34
        }
      },
      '60': {
        start: {
          line: 104,
          column: 4
        },
        end: {
          line: 115,
          column: 7
        }
      },
      '61': {
        start: {
          line: 105,
          column: 6
        },
        end: {
          line: 110,
          column: 7
        }
      },
      '62': {
        start: {
          line: 106,
          column: 8
        },
        end: {
          line: 106,
          column: 93
        }
      },
      '63': {
        start: {
          line: 107,
          column: 8
        },
        end: {
          line: 107,
          column: 36
        }
      },
      '64': {
        start: {
          line: 109,
          column: 8
        },
        end: {
          line: 109,
          column: 33
        }
      },
      '65': {
        start: {
          line: 111,
          column: 6
        },
        end: {
          line: 111,
          column: 29
        }
      },
      '66': {
        start: {
          line: 112,
          column: 6
        },
        end: {
          line: 112,
          column: 22
        }
      },
      '67': {
        start: {
          line: 113,
          column: 6
        },
        end: {
          line: 113,
          column: 42
        }
      },
      '68': {
        start: {
          line: 116,
          column: 4
        },
        end: {
          line: 116,
          column: 11
        }
      },
      '69': {
        start: {
          line: 118,
          column: 2
        },
        end: {
          line: 149,
          column: 4
        }
      },
      '70': {
        start: {
          line: 119,
          column: 4
        },
        end: {
          line: 148,
          column: 7
        }
      },
      '71': {
        start: {
          line: 131,
          column: 6
        },
        end: {
          line: 133,
          column: 7
        }
      },
      '72': {
        start: {
          line: 132,
          column: 8
        },
        end: {
          line: 132,
          column: 15
        }
      },
      '73': {
        start: {
          line: 134,
          column: 6
        },
        end: {
          line: 134,
          column: 30
        }
      },
      '74': {
        start: {
          line: 135,
          column: 27
        },
        end: {
          line: 135,
          column: 45
        }
      },
      '75': {
        start: {
          line: 136,
          column: 6
        },
        end: {
          line: 147,
          column: 9
        }
      },
      '76': {
        start: {
          line: 137,
          column: 8
        },
        end: {
          line: 137,
          column: 33
        }
      },
      '77': {
        start: {
          line: 139,
          column: 8
        },
        end: {
          line: 145,
          column: 9
        }
      },
      '78': {
        start: {
          line: 140,
          column: 10
        },
        end: {
          line: 143,
          column: 13
        }
      },
      '79': {
        start: {
          line: 144,
          column: 10
        },
        end: {
          line: 144,
          column: 17
        }
      },
      '80': {
        start: {
          line: 146,
          column: 8
        },
        end: {
          line: 146,
          column: 50
        }
      },
      '81': {
        start: {
          line: 152,
          column: 19
        },
        end: {
          line: 157,
          column: 4
        }
      },
      '82': {
        start: {
          line: 158,
          column: 2
        },
        end: {
          line: 163,
          column: 4
        }
      },
      '83': {
        start: {
          line: 159,
          column: 4
        },
        end: {
          line: 159,
          column: 41
        }
      },
      '84': {
        start: {
          line: 160,
          column: 4
        },
        end: {
          line: 160,
          column: 32
        }
      },
      '85': {
        start: {
          line: 161,
          column: 4
        },
        end: {
          line: 161,
          column: 34
        }
      },
      '86': {
        start: {
          line: 164,
          column: 2
        },
        end: {
          line: 169,
          column: 4
        }
      },
      '87': {
        start: {
          line: 165,
          column: 4
        },
        end: {
          line: 165,
          column: 29
        }
      },
      '88': {
        start: {
          line: 170,
          column: 2
        },
        end: {
          line: 172,
          column: 4
        }
      },
      '89': {
        start: {
          line: 171,
          column: 4
        },
        end: {
          line: 171,
          column: 29
        }
      },
      '90': {
        start: {
          line: 173,
          column: 2
        },
        end: {
          line: 180,
          column: 4
        }
      },
      '91': {
        start: {
          line: 174,
          column: 4
        },
        end: {
          line: 179,
          column: 7
        }
      },
      '92': {
        start: {
          line: 175,
          column: 6
        },
        end: {
          line: 178,
          column: 7
        }
      },
      '93': {
        start: {
          line: 176,
          column: 20
        },
        end: {
          line: 176,
          column: 57
        }
      },
      '94': {
        start: {
          line: 177,
          column: 8
        },
        end: {
          line: 177,
          column: 35
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 7,
            column: 52
          },
          end: {
            line: 7,
            column: 53
          }
        },
        loc: {
          start: {
            line: 7,
            column: 180
          },
          end: {
            line: 181,
            column: 1
          }
        },
        line: 7
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 15,
            column: 36
          },
          end: {
            line: 15,
            column: 37
          }
        },
        loc: {
          start: {
            line: 15,
            column: 42
          },
          end: {
            line: 17,
            column: 3
          }
        },
        line: 15
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 29,
            column: 15
          },
          end: {
            line: 29,
            column: 16
          }
        },
        loc: {
          start: {
            line: 29,
            column: 27
          },
          end: {
            line: 39,
            column: 3
          }
        },
        line: 29
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 40,
            column: 25
          },
          end: {
            line: 40,
            column: 26
          }
        },
        loc: {
          start: {
            line: 40,
            column: 49
          },
          end: {
            line: 48,
            column: 3
          }
        },
        line: 40
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 49,
            column: 20
          },
          end: {
            line: 49,
            column: 21
          }
        },
        loc: {
          start: {
            line: 49,
            column: 36
          },
          end: {
            line: 52,
            column: 3
          }
        },
        line: 49
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 53,
            column: 16
          },
          end: {
            line: 53,
            column: 17
          }
        },
        loc: {
          start: {
            line: 53,
            column: 32
          },
          end: {
            line: 66,
            column: 3
          }
        },
        line: 53
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 67,
            column: 18
          },
          end: {
            line: 67,
            column: 19
          }
        },
        loc: {
          start: {
            line: 67,
            column: 30
          },
          end: {
            line: 70,
            column: 3
          }
        },
        line: 67
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 71,
            column: 20
          },
          end: {
            line: 71,
            column: 21
          }
        },
        loc: {
          start: {
            line: 71,
            column: 32
          },
          end: {
            line: 117,
            column: 3
          }
        },
        line: 71
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 104,
            column: 97
          },
          end: {
            line: 104,
            column: 98
          }
        },
        loc: {
          start: {
            line: 104,
            column: 105
          },
          end: {
            line: 115,
            column: 5
          }
        },
        line: 104
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 118,
            column: 22
          },
          end: {
            line: 118,
            column: 23
          }
        },
        loc: {
          start: {
            line: 118,
            column: 38
          },
          end: {
            line: 149,
            column: 3
          }
        },
        line: 118
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 130,
            column: 7
          },
          end: {
            line: 130,
            column: 8
          }
        },
        loc: {
          start: {
            line: 130,
            column: 20
          },
          end: {
            line: 148,
            column: 5
          }
        },
        line: 130
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 136,
            column: 95
          },
          end: {
            line: 136,
            column: 96
          }
        },
        loc: {
          start: {
            line: 136,
            column: 103
          },
          end: {
            line: 147,
            column: 7
          }
        },
        line: 136
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 158,
            column: 31
          },
          end: {
            line: 158,
            column: 32
          }
        },
        loc: {
          start: {
            line: 158,
            column: 66
          },
          end: {
            line: 163,
            column: 3
          }
        },
        line: 158
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 164,
            column: 27
          },
          end: {
            line: 164,
            column: 28
          }
        },
        loc: {
          start: {
            line: 164,
            column: 74
          },
          end: {
            line: 169,
            column: 3
          }
        },
        line: 164
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 170,
            column: 25
          },
          end: {
            line: 170,
            column: 26
          }
        },
        loc: {
          start: {
            line: 170,
            column: 72
          },
          end: {
            line: 172,
            column: 3
          }
        },
        line: 170
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 173,
            column: 24
          },
          end: {
            line: 173,
            column: 25
          }
        },
        loc: {
          start: {
            line: 173,
            column: 39
          },
          end: {
            line: 180,
            column: 3
          }
        },
        line: 173
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 174,
            column: 61
          },
          end: {
            line: 174,
            column: 62
          }
        },
        loc: {
          start: {
            line: 174,
            column: 69
          },
          end: {
            line: 179,
            column: 5
          }
        },
        line: 174
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 9,
            column: 2
          },
          end: {
            line: 11,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 9,
            column: 2
          },
          end: {
            line: 11,
            column: 3
          }
        }, {
          start: {
            line: 9,
            column: 2
          },
          end: {
            line: 11,
            column: 3
          }
        }],
        line: 9
      },
      '1': {
        loc: {
          start: {
            line: 41,
            column: 4
          },
          end: {
            line: 43,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 41,
            column: 4
          },
          end: {
            line: 43,
            column: 5
          }
        }, {
          start: {
            line: 41,
            column: 4
          },
          end: {
            line: 43,
            column: 5
          }
        }],
        line: 41
      },
      '2': {
        loc: {
          start: {
            line: 44,
            column: 4
          },
          end: {
            line: 46,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 44,
            column: 4
          },
          end: {
            line: 46,
            column: 5
          }
        }, {
          start: {
            line: 44,
            column: 4
          },
          end: {
            line: 46,
            column: 5
          }
        }],
        line: 44
      },
      '3': {
        loc: {
          start: {
            line: 74,
            column: 4
          },
          end: {
            line: 78,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 74,
            column: 4
          },
          end: {
            line: 78,
            column: 5
          }
        }, {
          start: {
            line: 74,
            column: 4
          },
          end: {
            line: 78,
            column: 5
          }
        }],
        line: 74
      },
      '4': {
        loc: {
          start: {
            line: 80,
            column: 4
          },
          end: {
            line: 88,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 80,
            column: 4
          },
          end: {
            line: 88,
            column: 5
          }
        }, {
          start: {
            line: 80,
            column: 4
          },
          end: {
            line: 88,
            column: 5
          }
        }],
        line: 80
      },
      '5': {
        loc: {
          start: {
            line: 90,
            column: 4
          },
          end: {
            line: 95,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 90,
            column: 4
          },
          end: {
            line: 95,
            column: 5
          }
        }, {
          start: {
            line: 90,
            column: 4
          },
          end: {
            line: 95,
            column: 5
          }
        }],
        line: 90
      },
      '6': {
        loc: {
          start: {
            line: 96,
            column: 4
          },
          end: {
            line: 98,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 96,
            column: 4
          },
          end: {
            line: 98,
            column: 5
          }
        }, {
          start: {
            line: 96,
            column: 4
          },
          end: {
            line: 98,
            column: 5
          }
        }],
        line: 96
      },
      '7': {
        loc: {
          start: {
            line: 96,
            column: 8
          },
          end: {
            line: 96,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 96,
            column: 8
          },
          end: {
            line: 96,
            column: 28
          }
        }, {
          start: {
            line: 96,
            column: 32
          },
          end: {
            line: 96,
            column: 50
          }
        }],
        line: 96
      },
      '8': {
        loc: {
          start: {
            line: 99,
            column: 24
          },
          end: {
            line: 99,
            column: 66
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 99,
            column: 24
          },
          end: {
            line: 99,
            column: 44
          }
        }, {
          start: {
            line: 99,
            column: 48
          },
          end: {
            line: 99,
            column: 66
          }
        }],
        line: 99
      },
      '9': {
        loc: {
          start: {
            line: 100,
            column: 4
          },
          end: {
            line: 103,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 100,
            column: 4
          },
          end: {
            line: 103,
            column: 5
          }
        }, {
          start: {
            line: 100,
            column: 4
          },
          end: {
            line: 103,
            column: 5
          }
        }],
        line: 100
      },
      '10': {
        loc: {
          start: {
            line: 100,
            column: 8
          },
          end: {
            line: 100,
            column: 50
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 100,
            column: 8
          },
          end: {
            line: 100,
            column: 28
          }
        }, {
          start: {
            line: 100,
            column: 32
          },
          end: {
            line: 100,
            column: 50
          }
        }],
        line: 100
      },
      '11': {
        loc: {
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 110,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 110,
            column: 7
          }
        }, {
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 110,
            column: 7
          }
        }],
        line: 105
      },
      '12': {
        loc: {
          start: {
            line: 105,
            column: 10
          },
          end: {
            line: 105,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 105,
            column: 10
          },
          end: {
            line: 105,
            column: 30
          }
        }, {
          start: {
            line: 105,
            column: 34
          },
          end: {
            line: 105,
            column: 52
          }
        }],
        line: 105
      },
      '13': {
        loc: {
          start: {
            line: 131,
            column: 6
          },
          end: {
            line: 133,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 131,
            column: 6
          },
          end: {
            line: 133,
            column: 7
          }
        }, {
          start: {
            line: 131,
            column: 6
          },
          end: {
            line: 133,
            column: 7
          }
        }],
        line: 131
      },
      '14': {
        loc: {
          start: {
            line: 139,
            column: 8
          },
          end: {
            line: 145,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 139,
            column: 8
          },
          end: {
            line: 145,
            column: 9
          }
        }, {
          start: {
            line: 139,
            column: 8
          },
          end: {
            line: 145,
            column: 9
          }
        }],
        line: 139
      },
      '15': {
        loc: {
          start: {
            line: 175,
            column: 6
          },
          end: {
            line: 178,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 175,
            column: 6
          },
          end: {
            line: 178,
            column: 7
          }
        }, {
          start: {
            line: 175,
            column: 6
          },
          end: {
            line: 178,
            column: 7
          }
        }],
        line: 175
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_28b6aa4f3c.s[0]++;
/**
 * Created by BKSB on 03/03/2017.
 */
/**
 * MainCtrl - controller
 */
angular.module('app').controller('report_typeCtrl', function ($scope, $deltaReportType, $deltaActor, SweetAlert, $rootScope, $translate, $deltadate, $CRUDService, $filter, FileUploader) {
  cov_28b6aa4f3c.f[0]++;
  cov_28b6aa4f3c.s[1]++;

  $rootScope.processPageRight('5_1');
  cov_28b6aa4f3c.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_28b6aa4f3c.b[0][0]++;
    cov_28b6aa4f3c.s[3]++;

    return;
  } else {
    cov_28b6aa4f3c.b[0][1]++;
  }
  cov_28b6aa4f3c.s[4]++;
  $scope.isworkflow = false;
  cov_28b6aa4f3c.s[5]++;
  $scope.listProjects = [];
  cov_28b6aa4f3c.s[6]++;
  $scope.PATH = 'Type_Rapport';
  cov_28b6aa4f3c.s[7]++;
  $deltaActor.addController($scope, function () {
    cov_28b6aa4f3c.f[1]++;
    cov_28b6aa4f3c.s[8]++;

    $scope.listData_actors_bksb_typerapport = $filter('filter')($scope.listData_actors_bksb, $scope.withoutOtherActorData_bksb);
  });
  cov_28b6aa4f3c.s[9]++;
  $deltaReportType.addController($scope);
  cov_28b6aa4f3c.s[10]++;
  $scope.fileRapport = null;
  cov_28b6aa4f3c.s[11]++;
  $scope.listPeriodicite = [{ id: 12, label: $deltadate.getLabelPeriod(12) },
  // {id: 6, label: $deltadate.getLabelPeriod(6)},
  { id: 4, label: $deltadate.getLabelPeriod(4) },
  //  {id: 3, label: $deltadate.getLabelPeriod(3)},
  { id: 2, label: $deltadate.getLabelPeriod(2) }, { id: 1, label: $deltadate.getLabelPeriod(1) }];
  cov_28b6aa4f3c.s[12]++;
  $scope.selectedItem = {};
  cov_28b6aa4f3c.s[13]++;
  $scope.add = function () {
    cov_28b6aa4f3c.f[2]++;
    cov_28b6aa4f3c.s[14]++;

    $scope.edit({
      DELAIS_PROD: 1,
      LIBELLE_TYPE: '',
      PERIODE_RAPPORT: 4,
      DESTINATEUR_RAPPORT: '',
      IDACTEURS: $scope.listData_actors_bksb[1].id,
      IDACTEURS_REC: $scope.listData_actors_bksb[1].id,
      id: 0
    });
  };
  cov_28b6aa4f3c.s[15]++;
  $scope.__filterActor = function (item, search) {
    cov_28b6aa4f3c.f[3]++;
    cov_28b6aa4f3c.s[16]++;

    if (!$scope.withoutOtherActorData_bksb(item)) {
      cov_28b6aa4f3c.b[1][0]++;
      cov_28b6aa4f3c.s[17]++;

      return false;
    } else {
      cov_28b6aa4f3c.b[1][1]++;
    }
    cov_28b6aa4f3c.s[18]++;
    if (!$filter('filter')($scope.listData_actors_bksb, search)[0]) {
      cov_28b6aa4f3c.b[2][0]++;
      cov_28b6aa4f3c.s[19]++;

      return false;
    } else {
      cov_28b6aa4f3c.b[2][1]++;
    }
    cov_28b6aa4f3c.s[20]++;
    return true;
  };
  cov_28b6aa4f3c.s[21]++;
  $scope.workflow = function (item) {
    cov_28b6aa4f3c.f[4]++;
    cov_28b6aa4f3c.s[22]++;

    $scope.isworkflow = true;
    cov_28b6aa4f3c.s[23]++;
    $scope.selectedItem = item;
  };
  cov_28b6aa4f3c.s[24]++;
  $scope.edit = function (item) {
    cov_28b6aa4f3c.f[5]++;
    cov_28b6aa4f3c.s[25]++;

    $scope.fileName = null;
    cov_28b6aa4f3c.s[26]++;
    $scope.fileRapport = null;
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    cov_28b6aa4f3c.s[27]++;
    $scope.selectedItem = angular.copy(item); //
    // Acteur                                                                                                                          //
    cov_28b6aa4f3c.s[28]++;
    $scope.selectedItem.IDACTEURS = $filter('filter')($scope.listData_actors_bksb, { id: item.IDACTEURS }, true)[0]; //
    cov_28b6aa4f3c.s[29]++;
    $scope.selectedItem.IDACTEURS_REC = $filter('filter')($scope.listData_actors_bksb, { id: item.IDACTEURS_REC }, true)[0]; //
    // Période                                                                                                               //
    cov_28b6aa4f3c.s[30]++;
    $scope.selectedItem.PERIODE_RAPPORT = $filter('filter')($scope.listPeriodicite, { id: item.PERIODE_RAPPORT - 0 }, true)[0]; //
    // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    cov_28b6aa4f3c.s[31]++;
    $scope.isadd = true;
    cov_28b6aa4f3c.s[32]++;
    $scope.selectedItem.DELAIS_PROD = - -$scope.selectedItem.DELAIS_PROD;
  };
  cov_28b6aa4f3c.s[33]++;
  $scope.cancel = function () {
    cov_28b6aa4f3c.f[6]++;
    cov_28b6aa4f3c.s[34]++;

    $scope.isadd = false;
    cov_28b6aa4f3c.s[35]++;
    $scope.isworkflow = false;
  };
  cov_28b6aa4f3c.s[36]++;
  $scope.validate = function () {
    cov_28b6aa4f3c.f[7]++;

    var validateItem = (cov_28b6aa4f3c.s[37]++, angular.copy($scope.selectedItem));
    cov_28b6aa4f3c.s[38]++;
    validateItem.IDACTEURS = $scope.selectedItem.IDACTEURS.id;
    cov_28b6aa4f3c.s[39]++;
    if (validateItem.IDACTEURS_REC) {
      cov_28b6aa4f3c.b[3][0]++;
      cov_28b6aa4f3c.s[40]++;

      validateItem.IDACTEURS_REC = $scope.selectedItem.IDACTEURS_REC.id;
    } else {
      cov_28b6aa4f3c.b[3][1]++;
      cov_28b6aa4f3c.s[41]++;

      validateItem.IDACTEURS_REC = 0;
    }
    cov_28b6aa4f3c.s[42]++;
    validateItem.PERIODE_RAPPORT = $scope.selectedItem.PERIODE_RAPPORT.id;
    cov_28b6aa4f3c.s[43]++;
    if ($scope.selectedItem.file) {
      cov_28b6aa4f3c.b[4][0]++;
      cov_28b6aa4f3c.s[44]++;

      try {
        cov_28b6aa4f3c.s[45]++;

        validateItem.EXT = $scope.selectedItem.file.name;
      } catch (e) {
        cov_28b6aa4f3c.s[46]++;

        $scope.fileRapport = null;
      }
    } else {
      cov_28b6aa4f3c.b[4][1]++;
      cov_28b6aa4f3c.s[47]++;

      $scope.fileRapport = null;
    }
    var dataAPP = (cov_28b6aa4f3c.s[48]++, null);
    cov_28b6aa4f3c.s[49]++;
    if ($scope.fileRapport) {
      cov_28b6aa4f3c.b[5][0]++;
      cov_28b6aa4f3c.s[50]++;

      dataAPP = new FormData();
      cov_28b6aa4f3c.s[51]++;
      dataAPP.append('fileformatrapp', $scope.selectedItem.file);
      cov_28b6aa4f3c.s[52]++;
      dataAPP.append('action', 'loadformatFile');
      cov_28b6aa4f3c.s[53]++;
      dataAPP.append('id', validateItem.id);
    } else {
      cov_28b6aa4f3c.b[5][1]++;
    }
    cov_28b6aa4f3c.s[54]++;
    if ((cov_28b6aa4f3c.b[7][0]++, validateItem.id == 0) || (cov_28b6aa4f3c.b[7][1]++, $scope.fileRapport)) {
      cov_28b6aa4f3c.b[6][0]++;
      cov_28b6aa4f3c.s[55]++;

      $scope.isloading = true;
    } else {
      cov_28b6aa4f3c.b[6][1]++;
    }
    cov_28b6aa4f3c.s[56]++;
    $scope.isloading = (cov_28b6aa4f3c.b[8][0]++, validateItem.id == 0) || (cov_28b6aa4f3c.b[8][1]++, $scope.fileRapport);
    cov_28b6aa4f3c.s[57]++;
    if ((cov_28b6aa4f3c.b[10][0]++, validateItem.id != 0) && (cov_28b6aa4f3c.b[10][1]++, $scope.fileRapport)) {
      cov_28b6aa4f3c.b[9][0]++;
      cov_28b6aa4f3c.s[58]++;

      $scope.fileRapport.url = uploader.url + '&rapp=' + validateItem.id + '&ext=' + validateItem.EXT;
      cov_28b6aa4f3c.s[59]++;
      $scope.fileRapport.upload();
    } else {
      cov_28b6aa4f3c.b[9][1]++;
    }
    cov_28b6aa4f3c.s[60]++;
    $CRUDService.save($scope.PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function (data) {
      cov_28b6aa4f3c.f[8]++;
      cov_28b6aa4f3c.s[61]++;

      if ((cov_28b6aa4f3c.b[12][0]++, validateItem.id == 0) && (cov_28b6aa4f3c.b[12][1]++, $scope.fileRapport)) {
        cov_28b6aa4f3c.b[11][0]++;
        cov_28b6aa4f3c.s[62]++;

        $scope.fileRapport.url = uploader.url + '&rapp=' + data + '&ext=' + validateItem.EXT;
        cov_28b6aa4f3c.s[63]++;
        $scope.fileRapport.upload();
      } else {
        cov_28b6aa4f3c.b[11][1]++;
        cov_28b6aa4f3c.s[64]++;

        $scope.isloading = false;
      }
      cov_28b6aa4f3c.s[65]++;
      validateItem.id = data;
      cov_28b6aa4f3c.s[66]++;
      $scope.cancel();
      cov_28b6aa4f3c.s[67]++;
      $scope.loadingTypeReportData_bksb();
      // $deltaReportType.addData(validateItem,$scope.isloading);
    });
    cov_28b6aa4f3c.s[68]++;
    return;
  };
  cov_28b6aa4f3c.s[69]++;
  $scope.deleteItem = function (item) {
    cov_28b6aa4f3c.f[9]++;
    cov_28b6aa4f3c.s[70]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("REPORT_CAT.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_28b6aa4f3c.f[10]++;
      cov_28b6aa4f3c.s[71]++;

      if (isconfirm) {
        cov_28b6aa4f3c.b[13][0]++;
        cov_28b6aa4f3c.s[72]++;

        return;
      } else {
        cov_28b6aa4f3c.b[13][1]++;
      }
      cov_28b6aa4f3c.s[73]++;
      $scope.isloading = true;
      var validateItem = (cov_28b6aa4f3c.s[74]++, angular.copy(item));
      cov_28b6aa4f3c.s[75]++;
      $CRUDService.delet($scope.PATH, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_28b6aa4f3c.f[11]++;
        cov_28b6aa4f3c.s[76]++;

        $scope.isloading = false;

        cov_28b6aa4f3c.s[77]++;
        if (data < 0) {
          cov_28b6aa4f3c.b[14][0]++;
          cov_28b6aa4f3c.s[78]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_28b6aa4f3c.s[79]++;
          return;
        } else {
          cov_28b6aa4f3c.b[14][1]++;
        }
        cov_28b6aa4f3c.s[80]++;
        $deltaReportType.removeData(validateItem);
      });
    });
  };
  // uploadfile

  var uploader = (cov_28b6aa4f3c.s[81]++, $scope.uploader = new FileUploader({
    headers: $CRUDService.getHeader(),
    url: $CRUDService.getServerAdress() + 'web_server.php?action=loadformatFile&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + $scope.PATH,
    alias: 'fileformatrapp',
    removeAfterUpload: true
  }));
  cov_28b6aa4f3c.s[82]++;
  uploader.onAfterAddingFile = function (fileItem, a, b, c, d, e) {
    cov_28b6aa4f3c.f[12]++;
    cov_28b6aa4f3c.s[83]++;

    $scope.fileName = fileItem.file.name;
    cov_28b6aa4f3c.s[84]++;
    fileItem.url = uploader.url; // +"&indic="+fileItem.indic.id;
    cov_28b6aa4f3c.s[85]++;
    $scope.fileRapport = fileItem;
    // fileItem.upload();
  };
  cov_28b6aa4f3c.s[86]++;
  uploader.onSuccessItem = function (fileItem, response, status, headers) {
    cov_28b6aa4f3c.f[13]++;
    cov_28b6aa4f3c.s[87]++;

    $scope.isloading = false;
    // newImage = new Image();
    // newImage.src =$scope.PATHIMG+fileItem.indic.id+".jpg?time=" + new Date();
    // document.getElementById("theText_"+fileItem.indic.id).src = newImage.src;
  };
  cov_28b6aa4f3c.s[88]++;
  uploader.onErrorItem = function (fileItem, response, status, headers) {
    cov_28b6aa4f3c.f[14]++;
    cov_28b6aa4f3c.s[89]++;

    $scope.isloading = false;
  };
  cov_28b6aa4f3c.s[90]++;
  $scope.downloadFile = function (idd) {
    cov_28b6aa4f3c.f[15]++;
    cov_28b6aa4f3c.s[91]++;

    $CRUDService.getAll($scope.PATH, { get: 'file', id: idd }, function (data) {
      cov_28b6aa4f3c.f[16]++;
      cov_28b6aa4f3c.s[92]++;

      if (data != '') {
        cov_28b6aa4f3c.b[15][0]++;

        var dat = (cov_28b6aa4f3c.s[93]++, $CRUDService.getServerStrict() + data);
        cov_28b6aa4f3c.s[94]++;
        window.open(dat, '_blank');
      } else {
        cov_28b6aa4f3c.b[15][1]++;
      }
    });
  };
});
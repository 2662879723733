'use strict';

var cov_fzqkiguuz = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/user/usergroup/usergroupCtrl.js',
      hash = '71c08d8a72bd4d0f3b7f1640cc953f6662b7d230',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/user/usergroup/usergroupCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 5,
          column: 0
        },
        end: {
          line: 514,
          column: 3
        }
      },
      '1': {
        start: {
          line: 6,
          column: 15
        },
        end: {
          line: 6,
          column: 26
        }
      },
      '2': {
        start: {
          line: 7,
          column: 20
        },
        end: {
          line: 7,
          column: 26
        }
      },
      '3': {
        start: {
          line: 8,
          column: 22
        },
        end: {
          line: 8,
          column: 31
        }
      },
      '4': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 39
        }
      },
      '5': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 10,
          column: 42
        }
      },
      '6': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 39
        }
      },
      '7': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 23
        }
      },
      '8': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 16,
          column: 4
        }
      },
      '9': {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 204,
          column: 4
        }
      },
      '10': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 23,
          column: 7
        }
      },
      '11': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 22,
          column: 39
        }
      },
      '12': {
        start: {
          line: 33,
          column: 6
        },
        end: {
          line: 33,
          column: 33
        }
      },
      '13': {
        start: {
          line: 34,
          column: 6
        },
        end: {
          line: 36,
          column: 7
        }
      },
      '14': {
        start: {
          line: 35,
          column: 8
        },
        end: {
          line: 35,
          column: 15
        }
      },
      '15': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 37,
          column: 68
        }
      },
      '16': {
        start: {
          line: 38,
          column: 6
        },
        end: {
          line: 38,
          column: 71
        }
      },
      '17': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 40,
          column: 34
        }
      },
      '18': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 41,
          column: 32
        }
      },
      '19': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 43,
          column: 30
        }
      },
      '20': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 44,
          column: 29
        }
      },
      '21': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 51,
          column: 9
        }
      },
      '22': {
        start: {
          line: 46,
          column: 8
        },
        end: {
          line: 48,
          column: 9
        }
      },
      '23': {
        start: {
          line: 47,
          column: 10
        },
        end: {
          line: 47,
          column: 109
        }
      },
      '24': {
        start: {
          line: 49,
          column: 8
        },
        end: {
          line: 49,
          column: 37
        }
      },
      '25': {
        start: {
          line: 50,
          column: 8
        },
        end: {
          line: 50,
          column: 33
        }
      },
      '26': {
        start: {
          line: 54,
          column: 6
        },
        end: {
          line: 61,
          column: 9
        }
      },
      '27': {
        start: {
          line: 64,
          column: 6
        },
        end: {
          line: 64,
          column: 40
        }
      },
      '28': {
        start: {
          line: 65,
          column: 6
        },
        end: {
          line: 65,
          column: 42
        }
      },
      '29': {
        start: {
          line: 66,
          column: 6
        },
        end: {
          line: 66,
          column: 76
        }
      },
      '30': {
        start: {
          line: 68,
          column: 6
        },
        end: {
          line: 68,
          column: 28
        }
      },
      '31': {
        start: {
          line: 70,
          column: 6
        },
        end: {
          line: 70,
          column: 41
        }
      },
      '32': {
        start: {
          line: 73,
          column: 6
        },
        end: {
          line: 73,
          column: 44
        }
      },
      '33': {
        start: {
          line: 74,
          column: 6
        },
        end: {
          line: 74,
          column: 48
        }
      },
      '34': {
        start: {
          line: 75,
          column: 19
        },
        end: {
          line: 75,
          column: 45
        }
      },
      '35': {
        start: {
          line: 76,
          column: 21
        },
        end: {
          line: 76,
          column: 47
        }
      },
      '36': {
        start: {
          line: 77,
          column: 6
        },
        end: {
          line: 95,
          column: 7
        }
      },
      '37': {
        start: {
          line: 78,
          column: 8
        },
        end: {
          line: 80,
          column: 9
        }
      },
      '38': {
        start: {
          line: 79,
          column: 10
        },
        end: {
          line: 79,
          column: 19
        }
      },
      '39': {
        start: {
          line: 81,
          column: 18
        },
        end: {
          line: 81,
          column: 31
        }
      },
      '40': {
        start: {
          line: 82,
          column: 8
        },
        end: {
          line: 88,
          column: 9
        }
      },
      '41': {
        start: {
          line: 83,
          column: 10
        },
        end: {
          line: 87,
          column: 12
        }
      },
      '42': {
        start: {
          line: 89,
          column: 8
        },
        end: {
          line: 91,
          column: 9
        }
      },
      '43': {
        start: {
          line: 90,
          column: 10
        },
        end: {
          line: 90,
          column: 58
        }
      },
      '44': {
        start: {
          line: 92,
          column: 8
        },
        end: {
          line: 94,
          column: 9
        }
      },
      '45': {
        start: {
          line: 93,
          column: 10
        },
        end: {
          line: 93,
          column: 58
        }
      },
      '46': {
        start: {
          line: 96,
          column: 6
        },
        end: {
          line: 114,
          column: 7
        }
      },
      '47': {
        start: {
          line: 97,
          column: 8
        },
        end: {
          line: 99,
          column: 9
        }
      },
      '48': {
        start: {
          line: 98,
          column: 10
        },
        end: {
          line: 98,
          column: 19
        }
      },
      '49': {
        start: {
          line: 100,
          column: 18
        },
        end: {
          line: 100,
          column: 31
        }
      },
      '50': {
        start: {
          line: 101,
          column: 8
        },
        end: {
          line: 107,
          column: 9
        }
      },
      '51': {
        start: {
          line: 102,
          column: 10
        },
        end: {
          line: 106,
          column: 12
        }
      },
      '52': {
        start: {
          line: 108,
          column: 8
        },
        end: {
          line: 110,
          column: 9
        }
      },
      '53': {
        start: {
          line: 109,
          column: 10
        },
        end: {
          line: 109,
          column: 62
        }
      },
      '54': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 113,
          column: 9
        }
      },
      '55': {
        start: {
          line: 112,
          column: 10
        },
        end: {
          line: 112,
          column: 62
        }
      },
      '56': {
        start: {
          line: 119,
          column: 6
        },
        end: {
          line: 119,
          column: 27
        }
      },
      '57': {
        start: {
          line: 122,
          column: 24
        },
        end: {
          line: 122,
          column: 37
        }
      },
      '58': {
        start: {
          line: 123,
          column: 18
        },
        end: {
          line: 123,
          column: 93
        }
      },
      '59': {
        start: {
          line: 124,
          column: 6
        },
        end: {
          line: 126,
          column: 7
        }
      },
      '60': {
        start: {
          line: 125,
          column: 8
        },
        end: {
          line: 125,
          column: 15
        }
      },
      '61': {
        start: {
          line: 127,
          column: 6
        },
        end: {
          line: 127,
          column: 52
        }
      },
      '62': {
        start: {
          line: 129,
          column: 19
        },
        end: {
          line: 129,
          column: 22
        }
      },
      '63': {
        start: {
          line: 130,
          column: 6
        },
        end: {
          line: 134,
          column: 7
        }
      },
      '64': {
        start: {
          line: 131,
          column: 8
        },
        end: {
          line: 133,
          column: 9
        }
      },
      '65': {
        start: {
          line: 132,
          column: 10
        },
        end: {
          line: 132,
          column: 27
        }
      },
      '66': {
        start: {
          line: 135,
          column: 6
        },
        end: {
          line: 135,
          column: 48
        }
      },
      '67': {
        start: {
          line: 137,
          column: 19
        },
        end: {
          line: 137,
          column: 22
        }
      },
      '68': {
        start: {
          line: 138,
          column: 6
        },
        end: {
          line: 150,
          column: 7
        }
      },
      '69': {
        start: {
          line: 139,
          column: 18
        },
        end: {
          line: 139,
          column: 22
        }
      },
      '70': {
        start: {
          line: 140,
          column: 8
        },
        end: {
          line: 142,
          column: 9
        }
      },
      '71': {
        start: {
          line: 141,
          column: 10
        },
        end: {
          line: 141,
          column: 26
        }
      },
      '72': {
        start: {
          line: 143,
          column: 8
        },
        end: {
          line: 145,
          column: 9
        }
      },
      '73': {
        start: {
          line: 144,
          column: 10
        },
        end: {
          line: 144,
          column: 26
        }
      },
      '74': {
        start: {
          line: 146,
          column: 8
        },
        end: {
          line: 148,
          column: 9
        }
      },
      '75': {
        start: {
          line: 147,
          column: 10
        },
        end: {
          line: 147,
          column: 19
        }
      },
      '76': {
        start: {
          line: 149,
          column: 8
        },
        end: {
          line: 149,
          column: 23
        }
      },
      '77': {
        start: {
          line: 151,
          column: 6
        },
        end: {
          line: 151,
          column: 41
        }
      },
      '78': {
        start: {
          line: 153,
          column: 21
        },
        end: {
          line: 153,
          column: 24
        }
      },
      '79': {
        start: {
          line: 154,
          column: 6
        },
        end: {
          line: 166,
          column: 7
        }
      },
      '80': {
        start: {
          line: 155,
          column: 18
        },
        end: {
          line: 155,
          column: 22
        }
      },
      '81': {
        start: {
          line: 156,
          column: 8
        },
        end: {
          line: 158,
          column: 9
        }
      },
      '82': {
        start: {
          line: 157,
          column: 10
        },
        end: {
          line: 157,
          column: 26
        }
      },
      '83': {
        start: {
          line: 159,
          column: 8
        },
        end: {
          line: 161,
          column: 9
        }
      },
      '84': {
        start: {
          line: 160,
          column: 10
        },
        end: {
          line: 160,
          column: 26
        }
      },
      '85': {
        start: {
          line: 162,
          column: 8
        },
        end: {
          line: 164,
          column: 9
        }
      },
      '86': {
        start: {
          line: 163,
          column: 10
        },
        end: {
          line: 163,
          column: 19
        }
      },
      '87': {
        start: {
          line: 165,
          column: 8
        },
        end: {
          line: 165,
          column: 25
        }
      },
      '88': {
        start: {
          line: 167,
          column: 6
        },
        end: {
          line: 167,
          column: 43
        }
      },
      '89': {
        start: {
          line: 169,
          column: 6
        },
        end: {
          line: 169,
          column: 30
        }
      },
      '90': {
        start: {
          line: 170,
          column: 6
        },
        end: {
          line: 173,
          column: 9
        }
      },
      '91': {
        start: {
          line: 171,
          column: 8
        },
        end: {
          line: 171,
          column: 34
        }
      },
      '92': {
        start: {
          line: 172,
          column: 8
        },
        end: {
          line: 172,
          column: 34
        }
      },
      '93': {
        start: {
          line: 176,
          column: 6
        },
        end: {
          line: 202,
          column: 9
        }
      },
      '94': {
        start: {
          line: 188,
          column: 8
        },
        end: {
          line: 190,
          column: 9
        }
      },
      '95': {
        start: {
          line: 189,
          column: 10
        },
        end: {
          line: 189,
          column: 17
        }
      },
      '96': {
        start: {
          line: 191,
          column: 8
        },
        end: {
          line: 201,
          column: 11
        }
      },
      '97': {
        start: {
          line: 192,
          column: 10
        },
        end: {
          line: 199,
          column: 11
        }
      },
      '98': {
        start: {
          line: 193,
          column: 12
        },
        end: {
          line: 196,
          column: 15
        }
      },
      '99': {
        start: {
          line: 197,
          column: 12
        },
        end: {
          line: 197,
          column: 37
        }
      },
      '100': {
        start: {
          line: 198,
          column: 12
        },
        end: {
          line: 198,
          column: 19
        }
      },
      '101': {
        start: {
          line: 200,
          column: 10
        },
        end: {
          line: 200,
          column: 36
        }
      },
      '102': {
        start: {
          line: 206,
          column: 2
        },
        end: {
          line: 287,
          column: 4
        }
      },
      '103': {
        start: {
          line: 212,
          column: 6
        },
        end: {
          line: 257,
          column: 7
        }
      },
      '104': {
        start: {
          line: 213,
          column: 24
        },
        end: {
          line: 215,
          column: 10
        }
      },
      '105': {
        start: {
          line: 214,
          column: 10
        },
        end: {
          line: 214,
          column: 51
        }
      },
      '106': {
        start: {
          line: 217,
          column: 23
        },
        end: {
          line: 217,
          column: 40
        }
      },
      '107': {
        start: {
          line: 218,
          column: 8
        },
        end: {
          line: 220,
          column: 9
        }
      },
      '108': {
        start: {
          line: 219,
          column: 10
        },
        end: {
          line: 219,
          column: 50
        }
      },
      '109': {
        start: {
          line: 222,
          column: 24
        },
        end: {
          line: 222,
          column: 70
        }
      },
      '110': {
        start: {
          line: 224,
          column: 8
        },
        end: {
          line: 226,
          column: 9
        }
      },
      '111': {
        start: {
          line: 225,
          column: 10
        },
        end: {
          line: 225,
          column: 50
        }
      },
      '112': {
        start: {
          line: 228,
          column: 8
        },
        end: {
          line: 256,
          column: 11
        }
      },
      '113': {
        start: {
          line: 229,
          column: 10
        },
        end: {
          line: 236,
          column: 13
        }
      },
      '114': {
        start: {
          line: 231,
          column: 12
        },
        end: {
          line: 231,
          column: 58
        }
      },
      '115': {
        start: {
          line: 232,
          column: 12
        },
        end: {
          line: 234,
          column: 13
        }
      },
      '116': {
        start: {
          line: 233,
          column: 14
        },
        end: {
          line: 233,
          column: 35
        }
      },
      '117': {
        start: {
          line: 235,
          column: 12
        },
        end: {
          line: 235,
          column: 24
        }
      },
      '118': {
        start: {
          line: 239,
          column: 10
        },
        end: {
          line: 239,
          column: 37
        }
      },
      '119': {
        start: {
          line: 240,
          column: 10
        },
        end: {
          line: 253,
          column: 11
        }
      },
      '120': {
        start: {
          line: 247,
          column: 27
        },
        end: {
          line: 247,
          column: 130
        }
      },
      '121': {
        start: {
          line: 248,
          column: 12
        },
        end: {
          line: 252,
          column: 15
        }
      },
      '122': {
        start: {
          line: 255,
          column: 10
        },
        end: {
          line: 255,
          column: 40
        }
      },
      '123': {
        start: {
          line: 261,
          column: 6
        },
        end: {
          line: 261,
          column: 31
        }
      },
      '124': {
        start: {
          line: 262,
          column: 6
        },
        end: {
          line: 262,
          column: 25
        }
      },
      '125': {
        start: {
          line: 263,
          column: 6
        },
        end: {
          line: 271,
          column: 7
        }
      },
      '126': {
        start: {
          line: 264,
          column: 23
        },
        end: {
          line: 264,
          column: 84
        }
      },
      '127': {
        start: {
          line: 265,
          column: 8
        },
        end: {
          line: 270,
          column: 11
        }
      },
      '128': {
        start: {
          line: 272,
          column: 6
        },
        end: {
          line: 272,
          column: 27
        }
      },
      '129': {
        start: {
          line: 275,
          column: 6
        },
        end: {
          line: 275,
          column: 27
        }
      },
      '130': {
        start: {
          line: 278,
          column: 19
        },
        end: {
          line: 278,
          column: 22
        }
      },
      '131': {
        start: {
          line: 279,
          column: 6
        },
        end: {
          line: 283,
          column: 7
        }
      },
      '132': {
        start: {
          line: 280,
          column: 8
        },
        end: {
          line: 282,
          column: 9
        }
      },
      '133': {
        start: {
          line: 281,
          column: 10
        },
        end: {
          line: 281,
          column: 27
        }
      },
      '134': {
        start: {
          line: 284,
          column: 6
        },
        end: {
          line: 284,
          column: 56
        }
      },
      '135': {
        start: {
          line: 285,
          column: 6
        },
        end: {
          line: 285,
          column: 151
        }
      },
      '136': {
        start: {
          line: 289,
          column: 2
        },
        end: {
          line: 412,
          column: 4
        }
      },
      '137': {
        start: {
          line: 294,
          column: 6
        },
        end: {
          line: 299,
          column: 7
        }
      },
      '138': {
        start: {
          line: 295,
          column: 24
        },
        end: {
          line: 295,
          column: 46
        }
      },
      '139': {
        start: {
          line: 296,
          column: 8
        },
        end: {
          line: 298,
          column: 9
        }
      },
      '140': {
        start: {
          line: 297,
          column: 10
        },
        end: {
          line: 297,
          column: 32
        }
      },
      '141': {
        start: {
          line: 302,
          column: 6
        },
        end: {
          line: 302,
          column: 31
        }
      },
      '142': {
        start: {
          line: 303,
          column: 6
        },
        end: {
          line: 303,
          column: 29
        }
      },
      '143': {
        start: {
          line: 304,
          column: 19
        },
        end: {
          line: 304,
          column: 36
        }
      },
      '144': {
        start: {
          line: 305,
          column: 6
        },
        end: {
          line: 323,
          column: 7
        }
      },
      '145': {
        start: {
          line: 306,
          column: 8
        },
        end: {
          line: 308,
          column: 9
        }
      },
      '146': {
        start: {
          line: 307,
          column: 10
        },
        end: {
          line: 307,
          column: 19
        }
      },
      '147': {
        start: {
          line: 309,
          column: 18
        },
        end: {
          line: 309,
          column: 31
        }
      },
      '148': {
        start: {
          line: 310,
          column: 8
        },
        end: {
          line: 316,
          column: 9
        }
      },
      '149': {
        start: {
          line: 311,
          column: 10
        },
        end: {
          line: 315,
          column: 12
        }
      },
      '150': {
        start: {
          line: 317,
          column: 8
        },
        end: {
          line: 319,
          column: 9
        }
      },
      '151': {
        start: {
          line: 318,
          column: 10
        },
        end: {
          line: 318,
          column: 43
        }
      },
      '152': {
        start: {
          line: 320,
          column: 8
        },
        end: {
          line: 322,
          column: 9
        }
      },
      '153': {
        start: {
          line: 321,
          column: 10
        },
        end: {
          line: 321,
          column: 43
        }
      },
      '154': {
        start: {
          line: 325,
          column: 6
        },
        end: {
          line: 325,
          column: 27
        }
      },
      '155': {
        start: {
          line: 328,
          column: 6
        },
        end: {
          line: 328,
          column: 27
        }
      },
      '156': {
        start: {
          line: 331,
          column: 19
        },
        end: {
          line: 331,
          column: 22
        }
      },
      '157': {
        start: {
          line: 332,
          column: 6
        },
        end: {
          line: 344,
          column: 7
        }
      },
      '158': {
        start: {
          line: 333,
          column: 18
        },
        end: {
          line: 333,
          column: 22
        }
      },
      '159': {
        start: {
          line: 334,
          column: 8
        },
        end: {
          line: 336,
          column: 9
        }
      },
      '160': {
        start: {
          line: 335,
          column: 10
        },
        end: {
          line: 335,
          column: 26
        }
      },
      '161': {
        start: {
          line: 337,
          column: 8
        },
        end: {
          line: 339,
          column: 9
        }
      },
      '162': {
        start: {
          line: 338,
          column: 10
        },
        end: {
          line: 338,
          column: 26
        }
      },
      '163': {
        start: {
          line: 340,
          column: 8
        },
        end: {
          line: 342,
          column: 9
        }
      },
      '164': {
        start: {
          line: 341,
          column: 10
        },
        end: {
          line: 341,
          column: 19
        }
      },
      '165': {
        start: {
          line: 343,
          column: 8
        },
        end: {
          line: 343,
          column: 23
        }
      },
      '166': {
        start: {
          line: 345,
          column: 6
        },
        end: {
          line: 345,
          column: 49
        }
      },
      '167': {
        start: {
          line: 346,
          column: 6
        },
        end: {
          line: 346,
          column: 148
        }
      },
      '168': {
        start: {
          line: 350,
          column: 25
        },
        end: {
          line: 350,
          column: 27
        }
      },
      '169': {
        start: {
          line: 351,
          column: 27
        },
        end: {
          line: 353,
          column: 8
        }
      },
      '170': {
        start: {
          line: 352,
          column: 8
        },
        end: {
          line: 352,
          column: 40
        }
      },
      '171': {
        start: {
          line: 355,
          column: 6
        },
        end: {
          line: 357,
          column: 7
        }
      },
      '172': {
        start: {
          line: 356,
          column: 8
        },
        end: {
          line: 356,
          column: 21
        }
      },
      '173': {
        start: {
          line: 359,
          column: 6
        },
        end: {
          line: 363,
          column: 7
        }
      },
      '174': {
        start: {
          line: 360,
          column: 8
        },
        end: {
          line: 360,
          column: 56
        }
      },
      '175': {
        start: {
          line: 362,
          column: 8
        },
        end: {
          line: 362,
          column: 77
        }
      },
      '176': {
        start: {
          line: 365,
          column: 6
        },
        end: {
          line: 380,
          column: 7
        }
      },
      '177': {
        start: {
          line: 366,
          column: 8
        },
        end: {
          line: 366,
          column: 57
        }
      },
      '178': {
        start: {
          line: 368,
          column: 8
        },
        end: {
          line: 370,
          column: 9
        }
      },
      '179': {
        start: {
          line: 369,
          column: 10
        },
        end: {
          line: 369,
          column: 19
        }
      },
      '180': {
        start: {
          line: 372,
          column: 8
        },
        end: {
          line: 374,
          column: 9
        }
      },
      '181': {
        start: {
          line: 373,
          column: 10
        },
        end: {
          line: 373,
          column: 19
        }
      },
      '182': {
        start: {
          line: 376,
          column: 8
        },
        end: {
          line: 378,
          column: 9
        }
      },
      '183': {
        start: {
          line: 377,
          column: 10
        },
        end: {
          line: 377,
          column: 19
        }
      },
      '184': {
        start: {
          line: 379,
          column: 8
        },
        end: {
          line: 379,
          column: 20
        }
      },
      '185': {
        start: {
          line: 382,
          column: 6
        },
        end: {
          line: 382,
          column: 19
        }
      },
      '186': {
        start: {
          line: 386,
          column: 25
        },
        end: {
          line: 386,
          column: 27
        }
      },
      '187': {
        start: {
          line: 388,
          column: 24
        },
        end: {
          line: 388,
          column: 44
        }
      },
      '188': {
        start: {
          line: 389,
          column: 6
        },
        end: {
          line: 391,
          column: 7
        }
      },
      '189': {
        start: {
          line: 390,
          column: 8
        },
        end: {
          line: 390,
          column: 60
        }
      },
      '190': {
        start: {
          line: 393,
          column: 6
        },
        end: {
          line: 408,
          column: 7
        }
      },
      '191': {
        start: {
          line: 394,
          column: 8
        },
        end: {
          line: 394,
          column: 58
        }
      },
      '192': {
        start: {
          line: 396,
          column: 8
        },
        end: {
          line: 398,
          column: 9
        }
      },
      '193': {
        start: {
          line: 397,
          column: 10
        },
        end: {
          line: 397,
          column: 19
        }
      },
      '194': {
        start: {
          line: 400,
          column: 8
        },
        end: {
          line: 402,
          column: 9
        }
      },
      '195': {
        start: {
          line: 401,
          column: 10
        },
        end: {
          line: 401,
          column: 19
        }
      },
      '196': {
        start: {
          line: 404,
          column: 8
        },
        end: {
          line: 406,
          column: 9
        }
      },
      '197': {
        start: {
          line: 405,
          column: 10
        },
        end: {
          line: 405,
          column: 19
        }
      },
      '198': {
        start: {
          line: 407,
          column: 8
        },
        end: {
          line: 407,
          column: 20
        }
      },
      '199': {
        start: {
          line: 410,
          column: 6
        },
        end: {
          line: 410,
          column: 19
        }
      },
      '200': {
        start: {
          line: 414,
          column: 2
        },
        end: {
          line: 446,
          column: 4
        }
      },
      '201': {
        start: {
          line: 416,
          column: 6
        },
        end: {
          line: 444,
          column: 9
        }
      },
      '202': {
        start: {
          line: 417,
          column: 8
        },
        end: {
          line: 443,
          column: 11
        }
      },
      '203': {
        start: {
          line: 418,
          column: 20
        },
        end: {
          line: 418,
          column: 34
        }
      },
      '204': {
        start: {
          line: 419,
          column: 10
        },
        end: {
          line: 440,
          column: 11
        }
      },
      '205': {
        start: {
          line: 421,
          column: 14
        },
        end: {
          line: 421,
          column: 64
        }
      },
      '206': {
        start: {
          line: 422,
          column: 14
        },
        end: {
          line: 422,
          column: 20
        }
      },
      '207': {
        start: {
          line: 424,
          column: 14
        },
        end: {
          line: 424,
          column: 60
        }
      },
      '208': {
        start: {
          line: 425,
          column: 14
        },
        end: {
          line: 425,
          column: 20
        }
      },
      '209': {
        start: {
          line: 427,
          column: 14
        },
        end: {
          line: 427,
          column: 60
        }
      },
      '210': {
        start: {
          line: 428,
          column: 14
        },
        end: {
          line: 428,
          column: 20
        }
      },
      '211': {
        start: {
          line: 430,
          column: 14
        },
        end: {
          line: 430,
          column: 27
        }
      },
      '212': {
        start: {
          line: 431,
          column: 14
        },
        end: {
          line: 431,
          column: 20
        }
      },
      '213': {
        start: {
          line: 433,
          column: 14
        },
        end: {
          line: 433,
          column: 63
        }
      },
      '214': {
        start: {
          line: 434,
          column: 14
        },
        end: {
          line: 434,
          column: 20
        }
      },
      '215': {
        start: {
          line: 436,
          column: 14
        },
        end: {
          line: 436,
          column: 61
        }
      },
      '216': {
        start: {
          line: 437,
          column: 14
        },
        end: {
          line: 437,
          column: 20
        }
      },
      '217': {
        start: {
          line: 439,
          column: 14
        },
        end: {
          line: 439,
          column: 43
        }
      },
      '218': {
        start: {
          line: 441,
          column: 10
        },
        end: {
          line: 441,
          column: 37
        }
      },
      '219': {
        start: {
          line: 442,
          column: 10
        },
        end: {
          line: 442,
          column: 19
        }
      },
      '220': {
        start: {
          line: 448,
          column: 2
        },
        end: {
          line: 485,
          column: 4
        }
      },
      '221': {
        start: {
          line: 450,
          column: 6
        },
        end: {
          line: 450,
          column: 71
        }
      },
      '222': {
        start: {
          line: 451,
          column: 6
        },
        end: {
          line: 451,
          column: 28
        }
      },
      '223': {
        start: {
          line: 458,
          column: 6
        },
        end: {
          line: 458,
          column: 80
        }
      },
      '224': {
        start: {
          line: 458,
          column: 68
        },
        end: {
          line: 458,
          column: 77
        }
      },
      '225': {
        start: {
          line: 461,
          column: 6
        },
        end: {
          line: 461,
          column: 33
        }
      },
      '226': {
        start: {
          line: 464,
          column: 20
        },
        end: {
          line: 464,
          column: 60
        }
      },
      '227': {
        start: {
          line: 465,
          column: 6
        },
        end: {
          line: 469,
          column: 7
        }
      },
      '228': {
        start: {
          line: 466,
          column: 8
        },
        end: {
          line: 466,
          column: 46
        }
      },
      '229': {
        start: {
          line: 468,
          column: 8
        },
        end: {
          line: 468,
          column: 47
        }
      },
      '230': {
        start: {
          line: 472,
          column: 6
        },
        end: {
          line: 475,
          column: 9
        }
      },
      '231': {
        start: {
          line: 473,
          column: 8
        },
        end: {
          line: 473,
          column: 38
        }
      },
      '232': {
        start: {
          line: 474,
          column: 8
        },
        end: {
          line: 474,
          column: 83
        }
      },
      '233': {
        start: {
          line: 478,
          column: 6
        },
        end: {
          line: 482,
          column: 7
        }
      },
      '234': {
        start: {
          line: 479,
          column: 8
        },
        end: {
          line: 481,
          column: 9
        }
      },
      '235': {
        start: {
          line: 480,
          column: 10
        },
        end: {
          line: 480,
          column: 23
        }
      },
      '236': {
        start: {
          line: 483,
          column: 6
        },
        end: {
          line: 483,
          column: 18
        }
      },
      '237': {
        start: {
          line: 487,
          column: 2
        },
        end: {
          line: 487,
          column: 33
        }
      },
      '238': {
        start: {
          line: 489,
          column: 2
        },
        end: {
          line: 489,
          column: 24
        }
      },
      '239': {
        start: {
          line: 491,
          column: 2
        },
        end: {
          line: 491,
          column: 26
        }
      },
      '240': {
        start: {
          line: 493,
          column: 2
        },
        end: {
          line: 513,
          column: 5
        }
      },
      '241': {
        start: {
          line: 494,
          column: 4
        },
        end: {
          line: 500,
          column: 5
        }
      },
      '242': {
        start: {
          line: 495,
          column: 6
        },
        end: {
          line: 499,
          column: 9
        }
      },
      '243': {
        start: {
          line: 501,
          column: 4
        },
        end: {
          line: 507,
          column: 7
        }
      },
      '244': {
        start: {
          line: 502,
          column: 6
        },
        end: {
          line: 504,
          column: 7
        }
      },
      '245': {
        start: {
          line: 503,
          column: 8
        },
        end: {
          line: 503,
          column: 48
        }
      },
      '246': {
        start: {
          line: 506,
          column: 6
        },
        end: {
          line: 506,
          column: 72
        }
      },
      '247': {
        start: {
          line: 509,
          column: 4
        },
        end: {
          line: 511,
          column: 7
        }
      },
      '248': {
        start: {
          line: 510,
          column: 6
        },
        end: {
          line: 510,
          column: 64
        }
      },
      '249': {
        start: {
          line: 512,
          column: 4
        },
        end: {
          line: 512,
          column: 30
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 5,
            column: 50
          },
          end: {
            line: 5,
            column: 51
          }
        },
        loc: {
          start: {
            line: 5,
            column: 233
          },
          end: {
            line: 514,
            column: 1
          }
        },
        line: 5
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 45,
            column: 88
          },
          end: {
            line: 45,
            column: 89
          }
        },
        loc: {
          start: {
            line: 45,
            column: 96
          },
          end: {
            line: 51,
            column: 7
          }
        },
        line: 45
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 170,
            column: 131
          },
          end: {
            line: 170,
            column: 132
          }
        },
        loc: {
          start: {
            line: 170,
            column: 139
          },
          end: {
            line: 173,
            column: 7
          }
        },
        line: 170
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 187,
            column: 9
          },
          end: {
            line: 187,
            column: 10
          }
        },
        loc: {
          start: {
            line: 187,
            column: 22
          },
          end: {
            line: 202,
            column: 7
          }
        },
        line: 187
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 191,
            column: 84
          },
          end: {
            line: 191,
            column: 85
          }
        },
        loc: {
          start: {
            line: 191,
            column: 92
          },
          end: {
            line: 201,
            column: 9
          }
        },
        line: 191
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 213,
            column: 73
          },
          end: {
            line: 213,
            column: 74
          }
        },
        loc: {
          start: {
            line: 213,
            column: 80
          },
          end: {
            line: 215,
            column: 9
          }
        },
        line: 213
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 228,
            column: 47
          },
          end: {
            line: 228,
            column: 48
          }
        },
        loc: {
          start: {
            line: 228,
            column: 55
          },
          end: {
            line: 256,
            column: 9
          }
        },
        line: 228
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 229,
            column: 39
          },
          end: {
            line: 229,
            column: 40
          }
        },
        loc: {
          start: {
            line: 229,
            column: 47
          },
          end: {
            line: 236,
            column: 11
          }
        },
        line: 229
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 285,
            column: 139
          },
          end: {
            line: 285,
            column: 140
          }
        },
        loc: {
          start: {
            line: 285,
            column: 147
          },
          end: {
            line: 285,
            column: 149
          }
        },
        line: 285
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 346,
            column: 136
          },
          end: {
            line: 346,
            column: 137
          }
        },
        loc: {
          start: {
            line: 346,
            column: 144
          },
          end: {
            line: 346,
            column: 146
          }
        },
        line: 346
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 351,
            column: 71
          },
          end: {
            line: 351,
            column: 72
          }
        },
        loc: {
          start: {
            line: 351,
            column: 80
          },
          end: {
            line: 353,
            column: 7
          }
        },
        line: 351
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 359,
            column: 70
          },
          end: {
            line: 359,
            column: 71
          }
        },
        loc: {
          start: {
            line: 359,
            column: 79
          },
          end: {
            line: 361,
            column: 7
          }
        },
        line: 359
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 365,
            column: 63
          },
          end: {
            line: 365,
            column: 64
          }
        },
        loc: {
          start: {
            line: 365,
            column: 75
          },
          end: {
            line: 367,
            column: 7
          }
        },
        line: 365
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 393,
            column: 66
          },
          end: {
            line: 393,
            column: 67
          }
        },
        loc: {
          start: {
            line: 393,
            column: 78
          },
          end: {
            line: 395,
            column: 7
          }
        },
        line: 393
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 416,
            column: 61
          },
          end: {
            line: 416,
            column: 62
          }
        },
        loc: {
          start: {
            line: 416,
            column: 69
          },
          end: {
            line: 444,
            column: 7
          }
        },
        line: 416
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 417,
            column: 39
          },
          end: {
            line: 417,
            column: 40
          }
        },
        loc: {
          start: {
            line: 417,
            column: 48
          },
          end: {
            line: 443,
            column: 9
          }
        },
        line: 417
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 458,
            column: 57
          },
          end: {
            line: 458,
            column: 58
          }
        },
        loc: {
          start: {
            line: 458,
            column: 68
          },
          end: {
            line: 458,
            column: 77
          }
        },
        line: 458
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 472,
            column: 59
          },
          end: {
            line: 472,
            column: 60
          }
        },
        loc: {
          start: {
            line: 472,
            column: 67
          },
          end: {
            line: 475,
            column: 7
          }
        },
        line: 472
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 493,
            column: 69
          },
          end: {
            line: 493,
            column: 70
          }
        },
        loc: {
          start: {
            line: 493,
            column: 77
          },
          end: {
            line: 513,
            column: 3
          }
        },
        line: 493
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 501,
            column: 36
          },
          end: {
            line: 501,
            column: 37
          }
        },
        loc: {
          start: {
            line: 501,
            column: 60
          },
          end: {
            line: 507,
            column: 5
          }
        },
        line: 501
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 509,
            column: 60
          },
          end: {
            line: 509,
            column: 61
          }
        },
        loc: {
          start: {
            line: 509,
            column: 71
          },
          end: {
            line: 511,
            column: 5
          }
        },
        line: 509
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 34,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 34,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }, {
          start: {
            line: 34,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }],
        line: 34
      },
      '1': {
        loc: {
          start: {
            line: 47,
            column: 31
          },
          end: {
            line: 47,
            column: 108
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 47,
            column: 71
          },
          end: {
            line: 47,
            column: 87
          }
        }, {
          start: {
            line: 47,
            column: 90
          },
          end: {
            line: 47,
            column: 108
          }
        }],
        line: 47
      },
      '2': {
        loc: {
          start: {
            line: 78,
            column: 8
          },
          end: {
            line: 80,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 78,
            column: 8
          },
          end: {
            line: 80,
            column: 9
          }
        }, {
          start: {
            line: 78,
            column: 8
          },
          end: {
            line: 80,
            column: 9
          }
        }],
        line: 78
      },
      '3': {
        loc: {
          start: {
            line: 78,
            column: 12
          },
          end: {
            line: 78,
            column: 26
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 78,
            column: 12
          },
          end: {
            line: 78,
            column: 19
          }
        }, {
          start: {
            line: 78,
            column: 23
          },
          end: {
            line: 78,
            column: 26
          }
        }],
        line: 78
      },
      '4': {
        loc: {
          start: {
            line: 82,
            column: 8
          },
          end: {
            line: 88,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 82,
            column: 8
          },
          end: {
            line: 88,
            column: 9
          }
        }, {
          start: {
            line: 82,
            column: 8
          },
          end: {
            line: 88,
            column: 9
          }
        }],
        line: 82
      },
      '5': {
        loc: {
          start: {
            line: 89,
            column: 8
          },
          end: {
            line: 91,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 89,
            column: 8
          },
          end: {
            line: 91,
            column: 9
          }
        }, {
          start: {
            line: 89,
            column: 8
          },
          end: {
            line: 91,
            column: 9
          }
        }],
        line: 89
      },
      '6': {
        loc: {
          start: {
            line: 92,
            column: 8
          },
          end: {
            line: 94,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 92,
            column: 8
          },
          end: {
            line: 94,
            column: 9
          }
        }, {
          start: {
            line: 92,
            column: 8
          },
          end: {
            line: 94,
            column: 9
          }
        }],
        line: 92
      },
      '7': {
        loc: {
          start: {
            line: 97,
            column: 8
          },
          end: {
            line: 99,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 97,
            column: 8
          },
          end: {
            line: 99,
            column: 9
          }
        }, {
          start: {
            line: 97,
            column: 8
          },
          end: {
            line: 99,
            column: 9
          }
        }],
        line: 97
      },
      '8': {
        loc: {
          start: {
            line: 97,
            column: 12
          },
          end: {
            line: 97,
            column: 26
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 97,
            column: 12
          },
          end: {
            line: 97,
            column: 19
          }
        }, {
          start: {
            line: 97,
            column: 23
          },
          end: {
            line: 97,
            column: 26
          }
        }],
        line: 97
      },
      '9': {
        loc: {
          start: {
            line: 101,
            column: 8
          },
          end: {
            line: 107,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 101,
            column: 8
          },
          end: {
            line: 107,
            column: 9
          }
        }, {
          start: {
            line: 101,
            column: 8
          },
          end: {
            line: 107,
            column: 9
          }
        }],
        line: 101
      },
      '10': {
        loc: {
          start: {
            line: 108,
            column: 8
          },
          end: {
            line: 110,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 108,
            column: 8
          },
          end: {
            line: 110,
            column: 9
          }
        }, {
          start: {
            line: 108,
            column: 8
          },
          end: {
            line: 110,
            column: 9
          }
        }],
        line: 108
      },
      '11': {
        loc: {
          start: {
            line: 111,
            column: 8
          },
          end: {
            line: 113,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 111,
            column: 8
          },
          end: {
            line: 113,
            column: 9
          }
        }, {
          start: {
            line: 111,
            column: 8
          },
          end: {
            line: 113,
            column: 9
          }
        }],
        line: 111
      },
      '12': {
        loc: {
          start: {
            line: 124,
            column: 6
          },
          end: {
            line: 126,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 124,
            column: 6
          },
          end: {
            line: 126,
            column: 7
          }
        }, {
          start: {
            line: 124,
            column: 6
          },
          end: {
            line: 126,
            column: 7
          }
        }],
        line: 124
      },
      '13': {
        loc: {
          start: {
            line: 124,
            column: 10
          },
          end: {
            line: 124,
            column: 34
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 124,
            column: 10
          },
          end: {
            line: 124,
            column: 13
          }
        }, {
          start: {
            line: 124,
            column: 17
          },
          end: {
            line: 124,
            column: 34
          }
        }],
        line: 124
      },
      '14': {
        loc: {
          start: {
            line: 131,
            column: 8
          },
          end: {
            line: 133,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 131,
            column: 8
          },
          end: {
            line: 133,
            column: 9
          }
        }, {
          start: {
            line: 131,
            column: 8
          },
          end: {
            line: 133,
            column: 9
          }
        }],
        line: 131
      },
      '15': {
        loc: {
          start: {
            line: 140,
            column: 8
          },
          end: {
            line: 142,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 140,
            column: 8
          },
          end: {
            line: 142,
            column: 9
          }
        }, {
          start: {
            line: 140,
            column: 8
          },
          end: {
            line: 142,
            column: 9
          }
        }],
        line: 140
      },
      '16': {
        loc: {
          start: {
            line: 143,
            column: 8
          },
          end: {
            line: 145,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 143,
            column: 8
          },
          end: {
            line: 145,
            column: 9
          }
        }, {
          start: {
            line: 143,
            column: 8
          },
          end: {
            line: 145,
            column: 9
          }
        }],
        line: 143
      },
      '17': {
        loc: {
          start: {
            line: 146,
            column: 8
          },
          end: {
            line: 148,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 146,
            column: 8
          },
          end: {
            line: 148,
            column: 9
          }
        }, {
          start: {
            line: 146,
            column: 8
          },
          end: {
            line: 148,
            column: 9
          }
        }],
        line: 146
      },
      '18': {
        loc: {
          start: {
            line: 156,
            column: 8
          },
          end: {
            line: 158,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 156,
            column: 8
          },
          end: {
            line: 158,
            column: 9
          }
        }, {
          start: {
            line: 156,
            column: 8
          },
          end: {
            line: 158,
            column: 9
          }
        }],
        line: 156
      },
      '19': {
        loc: {
          start: {
            line: 159,
            column: 8
          },
          end: {
            line: 161,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 159,
            column: 8
          },
          end: {
            line: 161,
            column: 9
          }
        }, {
          start: {
            line: 159,
            column: 8
          },
          end: {
            line: 161,
            column: 9
          }
        }],
        line: 159
      },
      '20': {
        loc: {
          start: {
            line: 162,
            column: 8
          },
          end: {
            line: 164,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 162,
            column: 8
          },
          end: {
            line: 164,
            column: 9
          }
        }, {
          start: {
            line: 162,
            column: 8
          },
          end: {
            line: 164,
            column: 9
          }
        }],
        line: 162
      },
      '21': {
        loc: {
          start: {
            line: 188,
            column: 8
          },
          end: {
            line: 190,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 188,
            column: 8
          },
          end: {
            line: 190,
            column: 9
          }
        }, {
          start: {
            line: 188,
            column: 8
          },
          end: {
            line: 190,
            column: 9
          }
        }],
        line: 188
      },
      '22': {
        loc: {
          start: {
            line: 192,
            column: 10
          },
          end: {
            line: 199,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 192,
            column: 10
          },
          end: {
            line: 199,
            column: 11
          }
        }, {
          start: {
            line: 192,
            column: 10
          },
          end: {
            line: 199,
            column: 11
          }
        }],
        line: 192
      },
      '23': {
        loc: {
          start: {
            line: 212,
            column: 6
          },
          end: {
            line: 257,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 212,
            column: 6
          },
          end: {
            line: 257,
            column: 7
          }
        }, {
          start: {
            line: 212,
            column: 6
          },
          end: {
            line: 257,
            column: 7
          }
        }],
        line: 212
      },
      '24': {
        loc: {
          start: {
            line: 218,
            column: 8
          },
          end: {
            line: 220,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 218,
            column: 8
          },
          end: {
            line: 220,
            column: 9
          }
        }, {
          start: {
            line: 218,
            column: 8
          },
          end: {
            line: 220,
            column: 9
          }
        }],
        line: 218
      },
      '25': {
        loc: {
          start: {
            line: 222,
            column: 26
          },
          end: {
            line: 222,
            column: 65
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 222,
            column: 26
          },
          end: {
            line: 222,
            column: 54
          }
        }, {
          start: {
            line: 222,
            column: 58
          },
          end: {
            line: 222,
            column: 65
          }
        }],
        line: 222
      },
      '26': {
        loc: {
          start: {
            line: 224,
            column: 8
          },
          end: {
            line: 226,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 224,
            column: 8
          },
          end: {
            line: 226,
            column: 9
          }
        }, {
          start: {
            line: 224,
            column: 8
          },
          end: {
            line: 226,
            column: 9
          }
        }],
        line: 224
      },
      '27': {
        loc: {
          start: {
            line: 232,
            column: 12
          },
          end: {
            line: 234,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 232,
            column: 12
          },
          end: {
            line: 234,
            column: 13
          }
        }, {
          start: {
            line: 232,
            column: 12
          },
          end: {
            line: 234,
            column: 13
          }
        }],
        line: 232
      },
      '28': {
        loc: {
          start: {
            line: 280,
            column: 8
          },
          end: {
            line: 282,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 280,
            column: 8
          },
          end: {
            line: 282,
            column: 9
          }
        }, {
          start: {
            line: 280,
            column: 8
          },
          end: {
            line: 282,
            column: 9
          }
        }],
        line: 280
      },
      '29': {
        loc: {
          start: {
            line: 294,
            column: 6
          },
          end: {
            line: 299,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 294,
            column: 6
          },
          end: {
            line: 299,
            column: 7
          }
        }, {
          start: {
            line: 294,
            column: 6
          },
          end: {
            line: 299,
            column: 7
          }
        }],
        line: 294
      },
      '30': {
        loc: {
          start: {
            line: 295,
            column: 24
          },
          end: {
            line: 295,
            column: 46
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 295,
            column: 37
          },
          end: {
            line: 295,
            column: 40
          }
        }, {
          start: {
            line: 295,
            column: 43
          },
          end: {
            line: 295,
            column: 46
          }
        }],
        line: 295
      },
      '31': {
        loc: {
          start: {
            line: 296,
            column: 8
          },
          end: {
            line: 298,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 296,
            column: 8
          },
          end: {
            line: 298,
            column: 9
          }
        }, {
          start: {
            line: 296,
            column: 8
          },
          end: {
            line: 298,
            column: 9
          }
        }],
        line: 296
      },
      '32': {
        loc: {
          start: {
            line: 306,
            column: 8
          },
          end: {
            line: 308,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 306,
            column: 8
          },
          end: {
            line: 308,
            column: 9
          }
        }, {
          start: {
            line: 306,
            column: 8
          },
          end: {
            line: 308,
            column: 9
          }
        }],
        line: 306
      },
      '33': {
        loc: {
          start: {
            line: 306,
            column: 12
          },
          end: {
            line: 306,
            column: 26
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 306,
            column: 12
          },
          end: {
            line: 306,
            column: 19
          }
        }, {
          start: {
            line: 306,
            column: 23
          },
          end: {
            line: 306,
            column: 26
          }
        }],
        line: 306
      },
      '34': {
        loc: {
          start: {
            line: 310,
            column: 8
          },
          end: {
            line: 316,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 310,
            column: 8
          },
          end: {
            line: 316,
            column: 9
          }
        }, {
          start: {
            line: 310,
            column: 8
          },
          end: {
            line: 316,
            column: 9
          }
        }],
        line: 310
      },
      '35': {
        loc: {
          start: {
            line: 317,
            column: 8
          },
          end: {
            line: 319,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 317,
            column: 8
          },
          end: {
            line: 319,
            column: 9
          }
        }, {
          start: {
            line: 317,
            column: 8
          },
          end: {
            line: 319,
            column: 9
          }
        }],
        line: 317
      },
      '36': {
        loc: {
          start: {
            line: 320,
            column: 8
          },
          end: {
            line: 322,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 320,
            column: 8
          },
          end: {
            line: 322,
            column: 9
          }
        }, {
          start: {
            line: 320,
            column: 8
          },
          end: {
            line: 322,
            column: 9
          }
        }],
        line: 320
      },
      '37': {
        loc: {
          start: {
            line: 334,
            column: 8
          },
          end: {
            line: 336,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 334,
            column: 8
          },
          end: {
            line: 336,
            column: 9
          }
        }, {
          start: {
            line: 334,
            column: 8
          },
          end: {
            line: 336,
            column: 9
          }
        }],
        line: 334
      },
      '38': {
        loc: {
          start: {
            line: 337,
            column: 8
          },
          end: {
            line: 339,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 337,
            column: 8
          },
          end: {
            line: 339,
            column: 9
          }
        }, {
          start: {
            line: 337,
            column: 8
          },
          end: {
            line: 339,
            column: 9
          }
        }],
        line: 337
      },
      '39': {
        loc: {
          start: {
            line: 340,
            column: 8
          },
          end: {
            line: 342,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 340,
            column: 8
          },
          end: {
            line: 342,
            column: 9
          }
        }, {
          start: {
            line: 340,
            column: 8
          },
          end: {
            line: 342,
            column: 9
          }
        }],
        line: 340
      },
      '40': {
        loc: {
          start: {
            line: 351,
            column: 28
          },
          end: {
            line: 351,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 351,
            column: 28
          },
          end: {
            line: 351,
            column: 58
          }
        }, {
          start: {
            line: 351,
            column: 62
          },
          end: {
            line: 351,
            column: 64
          }
        }],
        line: 351
      },
      '41': {
        loc: {
          start: {
            line: 355,
            column: 6
          },
          end: {
            line: 357,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 355,
            column: 6
          },
          end: {
            line: 357,
            column: 7
          }
        }, {
          start: {
            line: 355,
            column: 6
          },
          end: {
            line: 357,
            column: 7
          }
        }],
        line: 355
      },
      '42': {
        loc: {
          start: {
            line: 362,
            column: 25
          },
          end: {
            line: 362,
            column: 43
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 362,
            column: 25
          },
          end: {
            line: 362,
            column: 37
          }
        }, {
          start: {
            line: 362,
            column: 41
          },
          end: {
            line: 362,
            column: 43
          }
        }],
        line: 362
      },
      '43': {
        loc: {
          start: {
            line: 368,
            column: 8
          },
          end: {
            line: 370,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 368,
            column: 8
          },
          end: {
            line: 370,
            column: 9
          }
        }, {
          start: {
            line: 368,
            column: 8
          },
          end: {
            line: 370,
            column: 9
          }
        }],
        line: 368
      },
      '44': {
        loc: {
          start: {
            line: 372,
            column: 8
          },
          end: {
            line: 374,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 372,
            column: 8
          },
          end: {
            line: 374,
            column: 9
          }
        }, {
          start: {
            line: 372,
            column: 8
          },
          end: {
            line: 374,
            column: 9
          }
        }],
        line: 372
      },
      '45': {
        loc: {
          start: {
            line: 376,
            column: 8
          },
          end: {
            line: 378,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 376,
            column: 8
          },
          end: {
            line: 378,
            column: 9
          }
        }, {
          start: {
            line: 376,
            column: 8
          },
          end: {
            line: 378,
            column: 9
          }
        }],
        line: 376
      },
      '46': {
        loc: {
          start: {
            line: 390,
            column: 25
          },
          end: {
            line: 390,
            column: 44
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 390,
            column: 25
          },
          end: {
            line: 390,
            column: 38
          }
        }, {
          start: {
            line: 390,
            column: 42
          },
          end: {
            line: 390,
            column: 44
          }
        }],
        line: 390
      },
      '47': {
        loc: {
          start: {
            line: 396,
            column: 8
          },
          end: {
            line: 398,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 396,
            column: 8
          },
          end: {
            line: 398,
            column: 9
          }
        }, {
          start: {
            line: 396,
            column: 8
          },
          end: {
            line: 398,
            column: 9
          }
        }],
        line: 396
      },
      '48': {
        loc: {
          start: {
            line: 400,
            column: 8
          },
          end: {
            line: 402,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 400,
            column: 8
          },
          end: {
            line: 402,
            column: 9
          }
        }, {
          start: {
            line: 400,
            column: 8
          },
          end: {
            line: 402,
            column: 9
          }
        }],
        line: 400
      },
      '49': {
        loc: {
          start: {
            line: 404,
            column: 8
          },
          end: {
            line: 406,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 404,
            column: 8
          },
          end: {
            line: 406,
            column: 9
          }
        }, {
          start: {
            line: 404,
            column: 8
          },
          end: {
            line: 406,
            column: 9
          }
        }],
        line: 404
      },
      '50': {
        loc: {
          start: {
            line: 419,
            column: 10
          },
          end: {
            line: 440,
            column: 11
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 420,
            column: 12
          },
          end: {
            line: 422,
            column: 20
          }
        }, {
          start: {
            line: 423,
            column: 12
          },
          end: {
            line: 425,
            column: 20
          }
        }, {
          start: {
            line: 426,
            column: 12
          },
          end: {
            line: 428,
            column: 20
          }
        }, {
          start: {
            line: 429,
            column: 12
          },
          end: {
            line: 431,
            column: 20
          }
        }, {
          start: {
            line: 432,
            column: 12
          },
          end: {
            line: 434,
            column: 20
          }
        }, {
          start: {
            line: 435,
            column: 12
          },
          end: {
            line: 437,
            column: 20
          }
        }, {
          start: {
            line: 438,
            column: 12
          },
          end: {
            line: 439,
            column: 43
          }
        }],
        line: 419
      },
      '51': {
        loc: {
          start: {
            line: 465,
            column: 6
          },
          end: {
            line: 469,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 465,
            column: 6
          },
          end: {
            line: 469,
            column: 7
          }
        }, {
          start: {
            line: 465,
            column: 6
          },
          end: {
            line: 469,
            column: 7
          }
        }],
        line: 465
      },
      '52': {
        loc: {
          start: {
            line: 478,
            column: 6
          },
          end: {
            line: 482,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 478,
            column: 6
          },
          end: {
            line: 482,
            column: 7
          }
        }, {
          start: {
            line: 478,
            column: 6
          },
          end: {
            line: 482,
            column: 7
          }
        }],
        line: 478
      },
      '53': {
        loc: {
          start: {
            line: 479,
            column: 8
          },
          end: {
            line: 481,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 479,
            column: 8
          },
          end: {
            line: 481,
            column: 9
          }
        }, {
          start: {
            line: 479,
            column: 8
          },
          end: {
            line: 481,
            column: 9
          }
        }],
        line: 479
      },
      '54': {
        loc: {
          start: {
            line: 494,
            column: 4
          },
          end: {
            line: 500,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 494,
            column: 4
          },
          end: {
            line: 500,
            column: 5
          }
        }, {
          start: {
            line: 494,
            column: 4
          },
          end: {
            line: 500,
            column: 5
          }
        }],
        line: 494
      },
      '55': {
        loc: {
          start: {
            line: 502,
            column: 6
          },
          end: {
            line: 504,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 502,
            column: 6
          },
          end: {
            line: 504,
            column: 7
          }
        }, {
          start: {
            line: 502,
            column: 6
          },
          end: {
            line: 504,
            column: 7
          }
        }],
        line: 502
      },
      '56': {
        loc: {
          start: {
            line: 503,
            column: 15
          },
          end: {
            line: 503,
            column: 47
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 503,
            column: 41
          },
          end: {
            line: 503,
            column: 43
          }
        }, {
          start: {
            line: 503,
            column: 46
          },
          end: {
            line: 503,
            column: 47
          }
        }],
        line: 503
      },
      '57': {
        loc: {
          start: {
            line: 506,
            column: 13
          },
          end: {
            line: 506,
            column: 71
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 506,
            column: 65
          },
          end: {
            line: 506,
            column: 66
          }
        }, {
          start: {
            line: 506,
            column: 69
          },
          end: {
            line: 506,
            column: 71
          }
        }],
        line: 506
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0,
      '242': 0,
      '243': 0,
      '244': 0,
      '245': 0,
      '246': 0,
      '247': 0,
      '248': 0,
      '249': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0],
      '50': [0, 0, 0, 0, 0, 0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0],
      '54': [0, 0],
      '55': [0, 0],
      '56': [0, 0],
      '57': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_fzqkiguuz.s[0]++;
/**
 * Created by BKSB on 18/04/2017.
 */

angular.module('app').controller('userGroupCtrl', function ($scope, $rootScope, SweetAlert, $deltaLogLevel, $deltaGeoLevel, $translate, $deltaLocation, $CRUDService, $otherHttpService, $deltahttp, $filter, $deltaPlanLogique, FileUploader) {
  cov_fzqkiguuz.f[0]++;

  var PATH = (cov_fzqkiguuz.s[1]++, 'UserGroup');
  var PATH_USER = (cov_fzqkiguuz.s[2]++, 'User');
  var PATH_PROFIL = (cov_fzqkiguuz.s[3]++, 'Profils');
  cov_fzqkiguuz.s[4]++;
  $deltaLocation.addController($scope);
  cov_fzqkiguuz.s[5]++;
  $deltaPlanLogique.addController($scope);
  cov_fzqkiguuz.s[6]++;
  $deltaGeoLevel.addController($scope);
  cov_fzqkiguuz.s[7]++;
  $scope.pageIndex = 1;
  cov_fzqkiguuz.s[8]++;
  $scope.__labels = {
    EDIT: $translate.instant('COMMON.EDIT'),
    DELETE: $translate.instant('COMMON.DELETE')
  };

  cov_fzqkiguuz.s[9]++;
  $scope.userGroup = {
    checkedAllLocation: {},
    selectAllUser: function selectAllUser() {
      cov_fzqkiguuz.s[10]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = $scope.users.listUser[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;
          cov_fzqkiguuz.s[11]++;

          item.checked = this.checkedAll;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },

    checkedAll: false,
    selected: null,
    user: {
      data: [],
      isloading: false
    },
    data: [],
    getAll: function getAll() {
      cov_fzqkiguuz.s[12]++;

      $scope.userGroup.data = [];
      cov_fzqkiguuz.s[13]++;
      if (!$scope.users.selectedProject) {
        cov_fzqkiguuz.b[0][0]++;
        cov_fzqkiguuz.s[14]++;

        return;
      } else {
        cov_fzqkiguuz.b[0][1]++;
      }
      cov_fzqkiguuz.s[15]++;
      $deltaLocation.removeAllData($scope.users.selectedProject.id);
      cov_fzqkiguuz.s[16]++;
      $deltaPlanLogique.removeAllData($scope.users.selectedProject.id);

      cov_fzqkiguuz.s[17]++;
      $scope.loadingPLData_bksb();
      cov_fzqkiguuz.s[18]++;
      $scope.loadingData_bksb();

      cov_fzqkiguuz.s[19]++;
      $scope.isloading = true;
      cov_fzqkiguuz.s[20]++;
      $scope.maxCodeSize = 0;
      cov_fzqkiguuz.s[21]++;
      $CRUDService.getAll(PATH, { get: 'all', p_bksb_: $scope.users.selectedProject.id }, function (data) {
        cov_fzqkiguuz.f[1]++;
        cov_fzqkiguuz.s[22]++;
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = data[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var item = _step2.value;
            cov_fzqkiguuz.s[23]++;

            $scope.maxCodeSize = item.CODE.length > $scope.maxCodeSize ? (cov_fzqkiguuz.b[1][0]++, item.CODE.length) : (cov_fzqkiguuz.b[1][1]++, $scope.maxCodeSize);
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        cov_fzqkiguuz.s[24]++;
        $scope.userGroup.data = data;
        cov_fzqkiguuz.s[25]++;
        $scope.isloading = false;
      });
    },
    newItem: function newItem() {
      cov_fzqkiguuz.s[26]++;

      this.edit({
        CODE: '',
        LABEL: '',
        USERLIST: '0;',
        id: 0,
        l: '0;',
        c: '0;'
      });
    },
    edit: function edit(item) {
      cov_fzqkiguuz.s[27]++;

      $scope.filter.selectedProfil = {};
      cov_fzqkiguuz.s[28]++;
      $scope.filter.selectedAgencies = [];
      cov_fzqkiguuz.s[29]++;
      $scope.filter.selectedProject[$scope.users.selectedProject.id] = true;

      cov_fzqkiguuz.s[30]++;
      $scope.users.getAll();

      cov_fzqkiguuz.s[31]++;
      this.selected = angular.copy(item);

      /* ************************** Localités ************************************ */
      cov_fzqkiguuz.s[32]++;
      $scope.userLocation.listSelected = {};
      cov_fzqkiguuz.s[33]++;
      $scope.userLocation.listCadreLogique = {};
      var data = (cov_fzqkiguuz.s[34]++, this.selected.l.split(';'));
      var datacl = (cov_fzqkiguuz.s[35]++, this.selected.c.split(';'));
      cov_fzqkiguuz.s[36]++;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = data[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var el = _step3.value;
          cov_fzqkiguuz.s[37]++;

          if ((cov_fzqkiguuz.b[3][0]++, el == 0) || (cov_fzqkiguuz.b[3][1]++, !el)) {
            cov_fzqkiguuz.b[2][0]++;
            cov_fzqkiguuz.s[38]++;

            continue;
          } else {
            cov_fzqkiguuz.b[2][1]++;
          }
          var t = (cov_fzqkiguuz.s[39]++, el.split('-'));
          cov_fzqkiguuz.s[40]++;
          if (!$scope.userLocation.listSelected[t[0]]) {
            cov_fzqkiguuz.b[4][0]++;
            cov_fzqkiguuz.s[41]++;

            $scope.userLocation.listSelected[String(t[0])] = {
              s: false,
              a: false,
              id: t[0]
            };
          } else {
            cov_fzqkiguuz.b[4][1]++;
          }
          cov_fzqkiguuz.s[42]++;
          if (t[1] == 1) {
            cov_fzqkiguuz.b[5][0]++;
            cov_fzqkiguuz.s[43]++;

            $scope.userLocation.listSelected[t[0]].s = true;
          } else {
            cov_fzqkiguuz.b[5][1]++;
          }
          cov_fzqkiguuz.s[44]++;
          if (t[1] == 2) {
            cov_fzqkiguuz.b[6][0]++;
            cov_fzqkiguuz.s[45]++;

            $scope.userLocation.listSelected[t[0]].a = true;
          } else {
            cov_fzqkiguuz.b[6][1]++;
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      cov_fzqkiguuz.s[46]++;
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = datacl[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var _el = _step4.value;
          cov_fzqkiguuz.s[47]++;

          if ((cov_fzqkiguuz.b[8][0]++, _el == 0) || (cov_fzqkiguuz.b[8][1]++, !_el)) {
            cov_fzqkiguuz.b[7][0]++;
            cov_fzqkiguuz.s[48]++;

            continue;
          } else {
            cov_fzqkiguuz.b[7][1]++;
          }
          var _t = (cov_fzqkiguuz.s[49]++, _el.split('-'));
          cov_fzqkiguuz.s[50]++;
          if (!$scope.userLocation.listCadreLogique[_t[0]]) {
            cov_fzqkiguuz.b[9][0]++;
            cov_fzqkiguuz.s[51]++;

            $scope.userLocation.listCadreLogique[String(_t[0])] = {
              s: false,
              a: false,
              id: _t[0]
            };
          } else {
            cov_fzqkiguuz.b[9][1]++;
          }
          cov_fzqkiguuz.s[52]++;
          if (_t[1] == 1) {
            cov_fzqkiguuz.b[10][0]++;
            cov_fzqkiguuz.s[53]++;

            $scope.userLocation.listCadreLogique[_t[0]].s = true;
          } else {
            cov_fzqkiguuz.b[10][1]++;
          }
          cov_fzqkiguuz.s[54]++;
          if (_t[1] == 2) {
            cov_fzqkiguuz.b[11][0]++;
            cov_fzqkiguuz.s[55]++;

            $scope.userLocation.listCadreLogique[_t[0]].a = true;
          } else {
            cov_fzqkiguuz.b[11][1]++;
          }
        }

        //* *************************************************************************/
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }
    },
    cancel: function cancel() {
      cov_fzqkiguuz.s[56]++;

      this.selected = null;
    },
    validate: function validate() {
      var usergroup = (cov_fzqkiguuz.s[57]++, this.selected);
      var dbl = (cov_fzqkiguuz.s[58]++, $filter('filter')($scope.userGroup.data, { CODE: usergroup.CODE }, true)[0]);
      cov_fzqkiguuz.s[59]++;
      if ((cov_fzqkiguuz.b[13][0]++, dbl) && (cov_fzqkiguuz.b[13][1]++, usergroup.id == 0)) {
        cov_fzqkiguuz.b[12][0]++;
        cov_fzqkiguuz.s[60]++;

        return;
      } else {
        cov_fzqkiguuz.b[12][1]++;
      }
      cov_fzqkiguuz.s[61]++;
      usergroup.CODE = usergroup.CODE.toUpperCase();
      //* ************************************* User *****************************************/
      var list = (cov_fzqkiguuz.s[62]++, [0]);
      cov_fzqkiguuz.s[63]++;
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = $scope.users.listUser[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var _el3 = _step5.value;
          cov_fzqkiguuz.s[64]++;

          if (_el3.checked) {
            cov_fzqkiguuz.b[14][0]++;
            cov_fzqkiguuz.s[65]++;

            list.push(_el3.id);
          } else {
            cov_fzqkiguuz.b[14][1]++;
          }
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      cov_fzqkiguuz.s[66]++;
      usergroup.USERLIST = list.join(';') + ';';
      //* ********************************* Localité ****************************************/
      var data = (cov_fzqkiguuz.s[67]++, [0]);
      cov_fzqkiguuz.s[68]++;
      for (var el in $scope.userLocation.listSelected) {
        var val = (cov_fzqkiguuz.s[69]++, null);
        cov_fzqkiguuz.s[70]++;
        if ($scope.userLocation.listSelected[el].s) {
          cov_fzqkiguuz.b[15][0]++;
          cov_fzqkiguuz.s[71]++;

          val = el + '-1';
        } else {
          cov_fzqkiguuz.b[15][1]++;
        }
        cov_fzqkiguuz.s[72]++;
        if ($scope.userLocation.listSelected[el].a) {
          cov_fzqkiguuz.b[16][0]++;
          cov_fzqkiguuz.s[73]++;

          val = el + '-2';
        } else {
          cov_fzqkiguuz.b[16][1]++;
        }
        cov_fzqkiguuz.s[74]++;
        if (!val) {
          cov_fzqkiguuz.b[17][0]++;
          cov_fzqkiguuz.s[75]++;

          continue;
        } else {
          cov_fzqkiguuz.b[17][1]++;
        }
        cov_fzqkiguuz.s[76]++;
        data.push(val);
      }
      cov_fzqkiguuz.s[77]++;
      usergroup.l = data.join(';') + ';';

      var datacl = (cov_fzqkiguuz.s[78]++, [0]);
      cov_fzqkiguuz.s[79]++;
      for (var _el2 in $scope.userLocation.listCadreLogique) {
        var _val = (cov_fzqkiguuz.s[80]++, null);
        cov_fzqkiguuz.s[81]++;
        if ($scope.userLocation.listCadreLogique[_el2].s) {
          cov_fzqkiguuz.b[18][0]++;
          cov_fzqkiguuz.s[82]++;

          _val = _el2 + '-1';
        } else {
          cov_fzqkiguuz.b[18][1]++;
        }
        cov_fzqkiguuz.s[83]++;
        if ($scope.userLocation.listCadreLogique[_el2].a) {
          cov_fzqkiguuz.b[19][0]++;
          cov_fzqkiguuz.s[84]++;

          _val = _el2 + '-2';
        } else {
          cov_fzqkiguuz.b[19][1]++;
        }
        cov_fzqkiguuz.s[85]++;
        if (!_val) {
          cov_fzqkiguuz.b[20][0]++;
          cov_fzqkiguuz.s[86]++;

          continue;
        } else {
          cov_fzqkiguuz.b[20][1]++;
        }
        cov_fzqkiguuz.s[87]++;
        datacl.push(_val);
      }
      cov_fzqkiguuz.s[88]++;
      usergroup.c = datacl.join(';') + ';';

      cov_fzqkiguuz.s[89]++;
      $scope.isloading = true;
      cov_fzqkiguuz.s[90]++;
      $CRUDService.save(PATH, { action: 'miseAJour', valeur: $filter('json')(usergroup), p_bksb_: $scope.users.selectedProject.id }, function (data) {
        cov_fzqkiguuz.f[2]++;
        cov_fzqkiguuz.s[91]++;

        $scope.userGroup.cancel();
        cov_fzqkiguuz.s[92]++;
        $scope.userGroup.getAll();
      });
    },
    deleteItem: function deleteItem(item) {
      cov_fzqkiguuz.s[93]++;

      SweetAlert.swal({
        title: $translate.instant('COMMON.CONFIRM'),
        // text: $translate.instant("GR_U.CONFIRM_DELETE"),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c8c8c8',

        confirmButtonText: $translate.instant('COMMON.NO'),
        cancelButtonText: $translate.instant('COMMON.YES'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isconfirm) {
        cov_fzqkiguuz.f[3]++;
        cov_fzqkiguuz.s[94]++;

        if (isconfirm) {
          cov_fzqkiguuz.b[21][0]++;
          cov_fzqkiguuz.s[95]++;

          return;
        } else {
          cov_fzqkiguuz.b[21][1]++;
        }
        cov_fzqkiguuz.s[96]++;
        $CRUDService.delet(PATH, { action: 'delete', valeur: $filter('json')(item) }, function (data) {
          cov_fzqkiguuz.f[4]++;
          cov_fzqkiguuz.s[97]++;

          if (data < 0) {
            cov_fzqkiguuz.b[22][0]++;
            cov_fzqkiguuz.s[98]++;

            SweetAlert.swal({
              title: $translate.instant('COMMON.ERROR_DELETE'),
              text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
            });
            cov_fzqkiguuz.s[99]++;
            $scope.isloading = false;
            cov_fzqkiguuz.s[100]++;
            return;
          } else {
            cov_fzqkiguuz.b[22][1]++;
          }
          cov_fzqkiguuz.s[101]++;
          $scope.userGroup.getAll();
        });
      });
    }
  };

  cov_fzqkiguuz.s[102]++;
  $scope.users = {
    data: [],
    isloading: false,
    listUser: [],
    selectedItem: null,
    getAll: function getAll() {
      cov_fzqkiguuz.s[103]++;

      if (!$scope.userGroup.user.isloading) {
        cov_fzqkiguuz.b[23][0]++;

        var profils = (cov_fzqkiguuz.s[104]++, Object.keys($scope.filter.selectedProfil).filter(function (key) {
          cov_fzqkiguuz.f[5]++;
          cov_fzqkiguuz.s[105]++;

          return $scope.filter.selectedProfil[key];
        }));

        var values = (cov_fzqkiguuz.s[106]++, { get: 'NonAdmin' });
        cov_fzqkiguuz.s[107]++;
        if (profils.length > 0) {
          cov_fzqkiguuz.b[24][0]++;
          cov_fzqkiguuz.s[108]++;

          values.profil = angular.toJson(profils);
        } else {
          cov_fzqkiguuz.b[24][1]++;
        }

        var projets = (cov_fzqkiguuz.s[109]++, [((cov_fzqkiguuz.b[25][0]++, $scope.users.selectedProject) || (cov_fzqkiguuz.b[25][1]++, { id: 0 })).id]);

        cov_fzqkiguuz.s[110]++;
        if (projets.length > 0) {
          cov_fzqkiguuz.b[26][0]++;
          cov_fzqkiguuz.s[111]++;

          values.projet = angular.toJson(projets);
        } else {
          cov_fzqkiguuz.b[26][1]++;
        }

        cov_fzqkiguuz.s[112]++;
        $CRUDService.getAll(PATH_USER, values, function (data) {
          cov_fzqkiguuz.f[6]++;
          cov_fzqkiguuz.s[113]++;

          $scope.users.data = data.map(function (user) {
            cov_fzqkiguuz.f[7]++;
            cov_fzqkiguuz.s[114]++;

            // eslint-disable-next-line angular/json-functions
            user.GROUP_USER = JSON.parse(user.GROUP_USER);
            cov_fzqkiguuz.s[115]++;
            if (!user.GROUP_USER) {
              cov_fzqkiguuz.b[27][0]++;
              cov_fzqkiguuz.s[116]++;

              user.GROUP_USER = {};
            } else {
              cov_fzqkiguuz.b[27][1]++;
            }
            cov_fzqkiguuz.s[117]++;
            return user;
          });

          /* ****************************** Utilisateur ******************************* */
          cov_fzqkiguuz.s[118]++;
          $scope.users.listUser = [];
          cov_fzqkiguuz.s[119]++;
          var _iteratorNormalCompletion6 = true;
          var _didIteratorError6 = false;
          var _iteratorError6 = undefined;

          try {
            for (var _iterator6 = $scope.users.data[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
              var el = _step6.value;

              /* if (!(el.GROUP_USER[$scope.users.selectedProject.id] == 4 ||
                // eslint-disable-next-line angular/definedundefined
                el.GROUP_USER[$scope.users.selectedProject.id] == undefined)) {
                continue;
              } */

              var check = (cov_fzqkiguuz.s[120]++, $scope.userGroup.selected.USERLIST.replace(';' + el.id + ';', '') != $scope.userGroup.selected.USERLIST);
              cov_fzqkiguuz.s[121]++;
              $scope.users.listUser.push({
                name: el.NOMUSER + ' ' + el.PRENOMUSER,
                checked: check,
                id: el.id
              });
            }
          } catch (err) {
            _didIteratorError6 = true;
            _iteratorError6 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion6 && _iterator6.return) {
                _iterator6.return();
              }
            } finally {
              if (_didIteratorError6) {
                throw _iteratorError6;
              }
            }
          }

          cov_fzqkiguuz.s[122]++;
          $scope.users.isloading = true;
        });
      } else {
        cov_fzqkiguuz.b[23][1]++;
      }
    },
    openUserList: function openUserList(item) {
      cov_fzqkiguuz.s[123]++;

      this.selectedItem = item;
      cov_fzqkiguuz.s[124]++;
      this.listUser = [];
      cov_fzqkiguuz.s[125]++;
      var _iteratorNormalCompletion7 = true;
      var _didIteratorError7 = false;
      var _iteratorError7 = undefined;

      try {
        for (var _iterator7 = $scope.users.data[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
          var el = _step7.value;

          var check = (cov_fzqkiguuz.s[126]++, item.USERLIST.replace(';' + el.id + ';', '') != item.USERLIST);
          cov_fzqkiguuz.s[127]++;
          this.listUser.push({
            name: el.NOMUSER + ' ' + el.PRENOMUSER,
            checked: check,
            id: el.id,
            imageUrl: $deltahttp.getUserRepository(el.id)
          });
        }
      } catch (err) {
        _didIteratorError7 = true;
        _iteratorError7 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion7 && _iterator7.return) {
            _iterator7.return();
          }
        } finally {
          if (_didIteratorError7) {
            throw _iteratorError7;
          }
        }
      }

      cov_fzqkiguuz.s[128]++;
      $scope.pageIndex = 2;
    },
    cancel: function cancel() {
      cov_fzqkiguuz.s[129]++;

      $scope.pageIndex = 1;
    },
    validate: function validate() {
      var list = (cov_fzqkiguuz.s[130]++, [0]);
      cov_fzqkiguuz.s[131]++;
      var _iteratorNormalCompletion8 = true;
      var _didIteratorError8 = false;
      var _iteratorError8 = undefined;

      try {
        for (var _iterator8 = this.listUser[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
          var el = _step8.value;
          cov_fzqkiguuz.s[132]++;

          if (el.checked) {
            cov_fzqkiguuz.b[28][0]++;
            cov_fzqkiguuz.s[133]++;

            list.push(el.id);
          } else {
            cov_fzqkiguuz.b[28][1]++;
          }
        }
      } catch (err) {
        _didIteratorError8 = true;
        _iteratorError8 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion8 && _iterator8.return) {
            _iterator8.return();
          }
        } finally {
          if (_didIteratorError8) {
            throw _iteratorError8;
          }
        }
      }

      cov_fzqkiguuz.s[134]++;
      this.selectedItem.USERLIST = list.join(';') + ';';
      cov_fzqkiguuz.s[135]++;
      $CRUDService.save(PATH, { action: 'miseAList', valeur: $filter('json')(this.selectedItem), p_bksb_: $scope.users.selectedProject.id }, function (data) {
        cov_fzqkiguuz.f[8]++;
      });
    }
  };

  cov_fzqkiguuz.s[136]++;
  $scope.userLocation = {
    selectedItem: null,
    listSelected: {},
    defaultValue: true,
    checkLocation: function checkLocation(item, key) {
      cov_fzqkiguuz.s[137]++;

      if (item[key]) {
        cov_fzqkiguuz.b[29][0]++;

        var overkey = (cov_fzqkiguuz.s[138]++, key == 's' ? (cov_fzqkiguuz.b[30][0]++, 'a') : (cov_fzqkiguuz.b[30][1]++, 's'));
        cov_fzqkiguuz.s[139]++;
        if (item[overkey]) {
          cov_fzqkiguuz.b[31][0]++;
          cov_fzqkiguuz.s[140]++;

          item[overkey] = false;
        } else {
          cov_fzqkiguuz.b[31][1]++;
        }
      } else {
        cov_fzqkiguuz.b[29][1]++;
      }
    },
    open: function open(item) {
      cov_fzqkiguuz.s[141]++;

      this.selectedItem = item;
      cov_fzqkiguuz.s[142]++;
      this.listSelected = {};
      var data = (cov_fzqkiguuz.s[143]++, item.l.split(';'));
      cov_fzqkiguuz.s[144]++;
      var _iteratorNormalCompletion9 = true;
      var _didIteratorError9 = false;
      var _iteratorError9 = undefined;

      try {
        for (var _iterator9 = data[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
          var el = _step9.value;
          cov_fzqkiguuz.s[145]++;

          if ((cov_fzqkiguuz.b[33][0]++, el == 0) || (cov_fzqkiguuz.b[33][1]++, !el)) {
            cov_fzqkiguuz.b[32][0]++;
            cov_fzqkiguuz.s[146]++;

            continue;
          } else {
            cov_fzqkiguuz.b[32][1]++;
          }
          var t = (cov_fzqkiguuz.s[147]++, el.split('-'));
          cov_fzqkiguuz.s[148]++;
          if (!this.listSelected[t[0]]) {
            cov_fzqkiguuz.b[34][0]++;
            cov_fzqkiguuz.s[149]++;

            this.listSelected[String(t[0])] = {
              s: false,
              a: false,
              id: t[0]
            };
          } else {
            cov_fzqkiguuz.b[34][1]++;
          }
          cov_fzqkiguuz.s[150]++;
          if (t[1] == 1) {
            cov_fzqkiguuz.b[35][0]++;
            cov_fzqkiguuz.s[151]++;

            this.listSelected[t[0]].s = true;
          } else {
            cov_fzqkiguuz.b[35][1]++;
          }
          cov_fzqkiguuz.s[152]++;
          if (t[1] == 2) {
            cov_fzqkiguuz.b[36][0]++;
            cov_fzqkiguuz.s[153]++;

            this.listSelected[t[0]].a = true;
          } else {
            cov_fzqkiguuz.b[36][1]++;
          }
        }
      } catch (err) {
        _didIteratorError9 = true;
        _iteratorError9 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion9 && _iterator9.return) {
            _iterator9.return();
          }
        } finally {
          if (_didIteratorError9) {
            throw _iteratorError9;
          }
        }
      }

      cov_fzqkiguuz.s[154]++;
      $scope.pageIndex = 3;
    },
    cancel: function cancel() {
      cov_fzqkiguuz.s[155]++;

      $scope.pageIndex = 1;
    },
    validate: function validate() {
      var data = (cov_fzqkiguuz.s[156]++, [0]);
      cov_fzqkiguuz.s[157]++;
      for (var el in this.listSelected) {
        var val = (cov_fzqkiguuz.s[158]++, null);
        cov_fzqkiguuz.s[159]++;
        if (this.listSelected[el].s) {
          cov_fzqkiguuz.b[37][0]++;
          cov_fzqkiguuz.s[160]++;

          val = el + '-1';
        } else {
          cov_fzqkiguuz.b[37][1]++;
        }
        cov_fzqkiguuz.s[161]++;
        if (this.listSelected[el].a) {
          cov_fzqkiguuz.b[38][0]++;
          cov_fzqkiguuz.s[162]++;

          val = el + '-2';
        } else {
          cov_fzqkiguuz.b[38][1]++;
        }
        cov_fzqkiguuz.s[163]++;
        if (!val) {
          cov_fzqkiguuz.b[39][0]++;
          cov_fzqkiguuz.s[164]++;

          continue;
        } else {
          cov_fzqkiguuz.b[39][1]++;
        }
        cov_fzqkiguuz.s[165]++;
        data.push(val);
      }
      cov_fzqkiguuz.s[166]++;
      this.selectedItem.l = data.join(';') + ';';
      cov_fzqkiguuz.s[167]++;
      $CRUDService.save(PATH, { action: 'miseAd', valeur: $filter('json')(this.selectedItem), p_bksb_: $scope.users.selectedProject.id }, function (data) {
        cov_fzqkiguuz.f[9]++;
      });
    },
    isSelected: function isSelected(item) {
      var parentCode = (cov_fzqkiguuz.s[168]++, []);
      var currentLevel = (cov_fzqkiguuz.s[169]++, ((cov_fzqkiguuz.b[40][0]++, $scope.listData_GeoLevels_bksb) || (cov_fzqkiguuz.b[40][1]++, [])).find(function (level) {
        cov_fzqkiguuz.f[10]++;
        cov_fzqkiguuz.s[170]++;

        return level.id == item.CODE_NG;
      }));

      cov_fzqkiguuz.s[171]++;
      if (!currentLevel) {
        cov_fzqkiguuz.b[41][0]++;
        cov_fzqkiguuz.s[172]++;

        return false;
      } else {
        cov_fzqkiguuz.b[41][1]++;
      }

      cov_fzqkiguuz.s[173]++;
      var _iteratorNormalCompletion10 = true;
      var _didIteratorError10 = false;
      var _iteratorError10 = undefined;

      try {
        for (var _iterator10 = $scope.listData_GeoLevels_bksb.filter(function (level) {
          cov_fzqkiguuz.f[11]++;
          cov_fzqkiguuz.s[174]++;

          return level.NIVEAU_NG < currentLevel.NIVEAU_NG;
        })[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
          var parentLevel = _step10.value;
          cov_fzqkiguuz.s[175]++;

          parentCode.push(((cov_fzqkiguuz.b[42][0]++, item.CODE_LC) || (cov_fzqkiguuz.b[42][1]++, '')).substr(0, parentLevel.LONG_NG));
        }
      } catch (err) {
        _didIteratorError10 = true;
        _iteratorError10 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion10 && _iterator10.return) {
            _iterator10.return();
          }
        } finally {
          if (_didIteratorError10) {
            throw _iteratorError10;
          }
        }
      }

      cov_fzqkiguuz.s[176]++;
      var _iteratorNormalCompletion11 = true;
      var _didIteratorError11 = false;
      var _iteratorError11 = undefined;

      try {
        for (var _iterator11 = $scope.listData_bksb.filter(function (location) {
          cov_fzqkiguuz.f[12]++;
          cov_fzqkiguuz.s[177]++;

          return parentCode.indexOf(location.CODE_LC) >= 0;
        })[Symbol.iterator](), _step11; !(_iteratorNormalCompletion11 = (_step11 = _iterator11.next()).done); _iteratorNormalCompletion11 = true) {
          var locationParent = _step11.value;
          cov_fzqkiguuz.s[178]++;

          if (angular.isUndefined($scope.userLocation.listSelected[locationParent.id])) {
            cov_fzqkiguuz.b[43][0]++;
            cov_fzqkiguuz.s[179]++;

            continue;
          } else {
            cov_fzqkiguuz.b[43][1]++;
          }

          cov_fzqkiguuz.s[180]++;
          if (angular.isUndefined($scope.userLocation.listSelected[locationParent.id].a)) {
            cov_fzqkiguuz.b[44][0]++;
            cov_fzqkiguuz.s[181]++;

            continue;
          } else {
            cov_fzqkiguuz.b[44][1]++;
          }

          cov_fzqkiguuz.s[182]++;
          if (!$scope.userLocation.listSelected[locationParent.id].a) {
            cov_fzqkiguuz.b[45][0]++;
            cov_fzqkiguuz.s[183]++;

            continue;
          } else {
            cov_fzqkiguuz.b[45][1]++;
          }
          cov_fzqkiguuz.s[184]++;
          return true;
        }
      } catch (err) {
        _didIteratorError11 = true;
        _iteratorError11 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion11 && _iterator11.return) {
            _iterator11.return();
          }
        } finally {
          if (_didIteratorError11) {
            throw _iteratorError11;
          }
        }
      }

      cov_fzqkiguuz.s[185]++;
      return false;
    },
    isLogframeSelected: function isLogframeSelected(item) {
      var parentCode = (cov_fzqkiguuz.s[186]++, []);

      var totalSize = (cov_fzqkiguuz.s[187]++, item.CODE_CLC.length);
      cov_fzqkiguuz.s[188]++;
      for (var i = totalSize - 1; i > 0; i--) {
        cov_fzqkiguuz.s[189]++;

        parentCode.push(((cov_fzqkiguuz.b[46][0]++, item.CODE_CLC) || (cov_fzqkiguuz.b[46][1]++, '')).substr(0, i));
      }

      cov_fzqkiguuz.s[190]++;
      var _iteratorNormalCompletion12 = true;
      var _didIteratorError12 = false;
      var _iteratorError12 = undefined;

      try {
        for (var _iterator12 = $scope.listData_pl_bksb.filter(function (location) {
          cov_fzqkiguuz.f[13]++;
          cov_fzqkiguuz.s[191]++;

          return parentCode.indexOf(location.CODE_CLC) >= 0;
        })[Symbol.iterator](), _step12; !(_iteratorNormalCompletion12 = (_step12 = _iterator12.next()).done); _iteratorNormalCompletion12 = true) {
          var locationParent = _step12.value;
          cov_fzqkiguuz.s[192]++;

          if (angular.isUndefined($scope.userLocation.listCadreLogique[locationParent.id])) {
            cov_fzqkiguuz.b[47][0]++;
            cov_fzqkiguuz.s[193]++;

            continue;
          } else {
            cov_fzqkiguuz.b[47][1]++;
          }

          cov_fzqkiguuz.s[194]++;
          if (angular.isUndefined($scope.userLocation.listCadreLogique[locationParent.id].a)) {
            cov_fzqkiguuz.b[48][0]++;
            cov_fzqkiguuz.s[195]++;

            continue;
          } else {
            cov_fzqkiguuz.b[48][1]++;
          }

          cov_fzqkiguuz.s[196]++;
          if (!$scope.userLocation.listCadreLogique[locationParent.id].a) {
            cov_fzqkiguuz.b[49][0]++;
            cov_fzqkiguuz.s[197]++;

            continue;
          } else {
            cov_fzqkiguuz.b[49][1]++;
          }
          cov_fzqkiguuz.s[198]++;
          return true;
        }
      } catch (err) {
        _didIteratorError12 = true;
        _iteratorError12 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion12 && _iterator12.return) {
            _iterator12.return();
          }
        } finally {
          if (_didIteratorError12) {
            throw _iteratorError12;
          }
        }
      }

      cov_fzqkiguuz.s[199]++;
      return false;
    }
  };

  cov_fzqkiguuz.s[200]++;
  $scope.profils = {
    getAll: function getAll() {
      cov_fzqkiguuz.s[201]++;

      $CRUDService.getAll(PATH_PROFIL, { get: 'all', all: 1 }, function (data) {
        cov_fzqkiguuz.f[14]++;
        cov_fzqkiguuz.s[202]++;

        $scope.profils.data = data.map(function (value) {
          cov_fzqkiguuz.f[15]++;

          var _ = (cov_fzqkiguuz.s[203]++, { id: value.id });
          cov_fzqkiguuz.s[204]++;
          switch (parseInt(value.NOT_DISPLAY)) {
            case 1:
              cov_fzqkiguuz.b[50][0]++;
              cov_fzqkiguuz.s[205]++;

              _.label = $translate.instant('G_U.STANDARD_USER');
              cov_fzqkiguuz.s[206]++;
              break;
            case 2:
              cov_fzqkiguuz.b[50][1]++;
              cov_fzqkiguuz.s[207]++;

              _.label = $translate.instant('G_U.VIEW_USER');
              cov_fzqkiguuz.s[208]++;
              break;
            case 3:
              cov_fzqkiguuz.b[50][2]++;
              cov_fzqkiguuz.s[209]++;

              _.label = $translate.instant('G_U.NO_ACCESS');
              cov_fzqkiguuz.s[210]++;
              break;
            case 4:
              cov_fzqkiguuz.b[50][3]++;
              cov_fzqkiguuz.s[211]++;

              _.label = ''; // $translate.instant('G_U.CUSTOM_USER');
              cov_fzqkiguuz.s[212]++;
              break;
            case 5:
              cov_fzqkiguuz.b[50][4]++;
              cov_fzqkiguuz.s[213]++;

              _.label = $translate.instant('G_U.COLLECT_USER');
              cov_fzqkiguuz.s[214]++;
              break;
            case 6:
              cov_fzqkiguuz.b[50][5]++;
              cov_fzqkiguuz.s[215]++;

              _.label = $translate.instant('G_U.ADMIN_USER');
              cov_fzqkiguuz.s[216]++;
              break;
            default:
              cov_fzqkiguuz.b[50][6]++;
              cov_fzqkiguuz.s[217]++;

              _.label = value.LABEL_PROFIL;
          }
          cov_fzqkiguuz.s[218]++;
          _.type = value.NOT_DISPLAY;
          cov_fzqkiguuz.s[219]++;
          return _;
        });
      });
    }
  };

  cov_fzqkiguuz.s[220]++;
  $scope.filter = {
    addProfil: function addProfil(profil) {
      cov_fzqkiguuz.s[221]++;

      this.selectedProfil[profil.id] = !this.selectedProfil[profil.id];
      cov_fzqkiguuz.s[222]++;
      $scope.users.getAll();
    },

    selectedProfil: {},
    selectedProject: {},
    agencies: [],
    selectedAgencies: [],
    selectAllAgencies: function selectAllAgencies() {
      cov_fzqkiguuz.s[223]++;

      this.selectedAgencies = $scope.filter.agencies.map(function (agency) {
        cov_fzqkiguuz.f[16]++;
        cov_fzqkiguuz.s[224]++;
        return agency.id;
      });
    },
    UnselectedAll: function UnselectedAll() {
      cov_fzqkiguuz.s[225]++;

      this.selectedAgencies = [];
    },
    addAgence: function addAgence(agency) {
      var index = (cov_fzqkiguuz.s[226]++, this.selectedAgencies.indexOf(agency.id));
      cov_fzqkiguuz.s[227]++;
      if (index < 0) {
        cov_fzqkiguuz.b[51][0]++;
        cov_fzqkiguuz.s[228]++;

        this.selectedAgencies.push(agency.id);
      } else {
        cov_fzqkiguuz.b[51][1]++;
        cov_fzqkiguuz.s[229]++;

        this.selectedAgencies.splice(index, 1);
      }
    },
    getAllAgencies: function getAllAgencies() {
      cov_fzqkiguuz.s[230]++;

      $CRUDService.getAll('AgenciesProject', { get: 'all' }, function (data) {
        cov_fzqkiguuz.f[17]++;
        cov_fzqkiguuz.s[231]++;

        $scope.filter.agencies = data;
        cov_fzqkiguuz.s[232]++;
        $scope.filter.selectedProject[$rootScope.currentProject.project.id] = true;
      });
    },
    processFilter: function processFilter(value) {
      cov_fzqkiguuz.s[233]++;

      if ($scope.filter.selectedAgencies.length > 0) {
        cov_fzqkiguuz.b[52][0]++;
        cov_fzqkiguuz.s[234]++;

        if ($scope.filter.selectedAgencies.indexOf(value.ID_AGENCY) < 0) {
          cov_fzqkiguuz.b[53][0]++;
          cov_fzqkiguuz.s[235]++;

          return false;
        } else {
          cov_fzqkiguuz.b[53][1]++;
        }
      } else {
        cov_fzqkiguuz.b[52][1]++;
      }
      cov_fzqkiguuz.s[236]++;
      return true;
    }
  };

  cov_fzqkiguuz.s[237]++;
  $scope.filter.getAllAgencies();

  cov_fzqkiguuz.s[238]++;
  $scope.users.getAll();

  cov_fzqkiguuz.s[239]++;
  $scope.profils.getAll();

  cov_fzqkiguuz.s[240]++;
  $CRUDService.getAll('Projet', { get: 'all_short', portfolio: true }, function (data) {
    cov_fzqkiguuz.f[18]++;
    cov_fzqkiguuz.s[241]++;

    if ($rootScope.currentProject.project.PORTFOLIO == 1) {
      cov_fzqkiguuz.b[54][0]++;
      cov_fzqkiguuz.s[242]++;

      data.push({
        LIBELLE_PROJET: $translate.instant('MENU_SHORT.DO'),
        id: $rootScope.currentProject.project.id,
        PORTFOLIO: -1
      });
    } else {
      cov_fzqkiguuz.b[54][1]++;
    }
    cov_fzqkiguuz.s[243]++;
    $scope.listProjects = data.sort(function (projectA, projectB) {
      cov_fzqkiguuz.f[19]++;
      cov_fzqkiguuz.s[244]++;

      if (projectA.PORTFOLIO != projectB.PORTFOLIO) {
        cov_fzqkiguuz.b[55][0]++;
        cov_fzqkiguuz.s[245]++;

        return projectA.PORTFOLIO == 1 ? (cov_fzqkiguuz.b[56][0]++, -1) : (cov_fzqkiguuz.b[56][1]++, 1);
      } else {
        cov_fzqkiguuz.b[55][1]++;
      }

      cov_fzqkiguuz.s[246]++;
      return projectA.LIBELLE_PROJET > projectB.LIBELLE_PROJET ? (cov_fzqkiguuz.b[57][0]++, 1) : (cov_fzqkiguuz.b[57][1]++, -1);
    });

    cov_fzqkiguuz.s[247]++;
    $scope.users.selectedProject = $scope.listProjects.find(function (project) {
      cov_fzqkiguuz.f[20]++;
      cov_fzqkiguuz.s[248]++;

      return project.id == $rootScope.currentProject.project.id;
    });
    cov_fzqkiguuz.s[249]++;
    $scope.userGroup.getAll();
  });
});
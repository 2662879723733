'use strict';

var cov_1hg27fiokd = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/dashboard_sig/dashboardSigCtrl.js',
      hash = '758b0d6257087f91eddd6627ab26eabd8b0e279f',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/dashboard_sig/dashboardSigCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 840,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 15
        },
        end: {
          line: 3,
          column: 28
        }
      },
      '2': {
        start: {
          line: 4,
          column: 19
        },
        end: {
          line: 4,
          column: 35
        }
      },
      '3': {
        start: {
          line: 5,
          column: 24
        },
        end: {
          line: 5,
          column: 34
        }
      },
      '4': {
        start: {
          line: 6,
          column: 24
        },
        end: {
          line: 12,
          column: 3
        }
      },
      '5': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 628,
          column: 4
        }
      },
      '6': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 56,
          column: 7
        }
      },
      '7': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 45,
          column: 9
        }
      },
      '8': {
        start: {
          line: 42,
          column: 10
        },
        end: {
          line: 42,
          column: 59
        }
      },
      '9': {
        start: {
          line: 44,
          column: 10
        },
        end: {
          line: 44,
          column: 50
        }
      },
      '10': {
        start: {
          line: 46,
          column: 13
        },
        end: {
          line: 56,
          column: 7
        }
      },
      '11': {
        start: {
          line: 47,
          column: 8
        },
        end: {
          line: 51,
          column: 9
        }
      },
      '12': {
        start: {
          line: 48,
          column: 10
        },
        end: {
          line: 48,
          column: 64
        }
      },
      '13': {
        start: {
          line: 50,
          column: 10
        },
        end: {
          line: 50,
          column: 55
        }
      },
      '14': {
        start: {
          line: 52,
          column: 13
        },
        end: {
          line: 56,
          column: 7
        }
      },
      '15': {
        start: {
          line: 53,
          column: 8
        },
        end: {
          line: 53,
          column: 44
        }
      },
      '16': {
        start: {
          line: 55,
          column: 8
        },
        end: {
          line: 55,
          column: 50
        }
      },
      '17': {
        start: {
          line: 68,
          column: 6
        },
        end: {
          line: 72,
          column: 7
        }
      },
      '18': {
        start: {
          line: 69,
          column: 8
        },
        end: {
          line: 71,
          column: 9
        }
      },
      '19': {
        start: {
          line: 70,
          column: 10
        },
        end: {
          line: 70,
          column: 52
        }
      },
      '20': {
        start: {
          line: 73,
          column: 6
        },
        end: {
          line: 73,
          column: 48
        }
      },
      '21': {
        start: {
          line: 76,
          column: 24
        },
        end: {
          line: 76,
          column: 42
        }
      },
      '22': {
        start: {
          line: 77,
          column: 6
        },
        end: {
          line: 77,
          column: 42
        }
      },
      '23': {
        start: {
          line: 79,
          column: 20
        },
        end: {
          line: 79,
          column: 82
        }
      },
      '24': {
        start: {
          line: 80,
          column: 6
        },
        end: {
          line: 88,
          column: 7
        }
      },
      '25': {
        start: {
          line: 81,
          column: 23
        },
        end: {
          line: 81,
          column: 44
        }
      },
      '26': {
        start: {
          line: 82,
          column: 21
        },
        end: {
          line: 82,
          column: 23
        }
      },
      '27': {
        start: {
          line: 83,
          column: 8
        },
        end: {
          line: 86,
          column: 9
        }
      },
      '28': {
        start: {
          line: 84,
          column: 20
        },
        end: {
          line: 84,
          column: 51
        }
      },
      '29': {
        start: {
          line: 85,
          column: 10
        },
        end: {
          line: 85,
          column: 52
        }
      },
      '30': {
        start: {
          line: 87,
          column: 8
        },
        end: {
          line: 87,
          column: 41
        }
      },
      '31': {
        start: {
          line: 89,
          column: 6
        },
        end: {
          line: 91,
          column: 7
        }
      },
      '32': {
        start: {
          line: 90,
          column: 8
        },
        end: {
          line: 90,
          column: 36
        }
      },
      '33': {
        start: {
          line: 92,
          column: 6
        },
        end: {
          line: 94,
          column: 7
        }
      },
      '34': {
        start: {
          line: 93,
          column: 8
        },
        end: {
          line: 93,
          column: 41
        }
      },
      '35': {
        start: {
          line: 96,
          column: 6
        },
        end: {
          line: 96,
          column: 25
        }
      },
      '36': {
        start: {
          line: 97,
          column: 6
        },
        end: {
          line: 99,
          column: 7
        }
      },
      '37': {
        start: {
          line: 98,
          column: 8
        },
        end: {
          line: 98,
          column: 28
        }
      },
      '38': {
        start: {
          line: 100,
          column: 24
        },
        end: {
          line: 100,
          column: 67
        }
      },
      '39': {
        start: {
          line: 101,
          column: 6
        },
        end: {
          line: 106,
          column: 57
        }
      },
      '40': {
        start: {
          line: 108,
          column: 19
        },
        end: {
          line: 108,
          column: 21
        }
      },
      '41': {
        start: {
          line: 109,
          column: 6
        },
        end: {
          line: 109,
          column: 97
        }
      },
      '42': {
        start: {
          line: 110,
          column: 6
        },
        end: {
          line: 110,
          column: 63
        }
      },
      '43': {
        start: {
          line: 111,
          column: 6
        },
        end: {
          line: 111,
          column: 97
        }
      },
      '44': {
        start: {
          line: 113,
          column: 6
        },
        end: {
          line: 113,
          column: 47
        }
      },
      '45': {
        start: {
          line: 115,
          column: 6
        },
        end: {
          line: 115,
          column: 37
        }
      },
      '46': {
        start: {
          line: 117,
          column: 6
        },
        end: {
          line: 117,
          column: 54
        }
      },
      '47': {
        start: {
          line: 119,
          column: 6
        },
        end: {
          line: 119,
          column: 43
        }
      },
      '48': {
        start: {
          line: 120,
          column: 6
        },
        end: {
          line: 120,
          column: 41
        }
      },
      '49': {
        start: {
          line: 121,
          column: 6
        },
        end: {
          line: 121,
          column: 45
        }
      },
      '50': {
        start: {
          line: 122,
          column: 6
        },
        end: {
          line: 122,
          column: 47
        }
      },
      '51': {
        start: {
          line: 123,
          column: 6
        },
        end: {
          line: 123,
          column: 45
        }
      },
      '52': {
        start: {
          line: 124,
          column: 6
        },
        end: {
          line: 124,
          column: 57
        }
      },
      '53': {
        start: {
          line: 132,
          column: 6
        },
        end: {
          line: 132,
          column: 30
        }
      },
      '54': {
        start: {
          line: 135,
          column: 6
        },
        end: {
          line: 140,
          column: 7
        }
      },
      '55': {
        start: {
          line: 136,
          column: 8
        },
        end: {
          line: 138,
          column: 11
        }
      },
      '56': {
        start: {
          line: 139,
          column: 8
        },
        end: {
          line: 139,
          column: 42
        }
      },
      '57': {
        start: {
          line: 141,
          column: 6
        },
        end: {
          line: 141,
          column: 36
        }
      },
      '58': {
        start: {
          line: 142,
          column: 21
        },
        end: {
          line: 142,
          column: 207
        }
      },
      '59': {
        start: {
          line: 143,
          column: 6
        },
        end: {
          line: 143,
          column: 49
        }
      },
      '60': {
        start: {
          line: 146,
          column: 6
        },
        end: {
          line: 208,
          column: 9
        }
      },
      '61': {
        start: {
          line: 147,
          column: 8
        },
        end: {
          line: 164,
          column: 11
        }
      },
      '62': {
        start: {
          line: 158,
          column: 10
        },
        end: {
          line: 160,
          column: 11
        }
      },
      '63': {
        start: {
          line: 159,
          column: 12
        },
        end: {
          line: 159,
          column: 58
        }
      },
      '64': {
        start: {
          line: 161,
          column: 10
        },
        end: {
          line: 161,
          column: 50
        }
      },
      '65': {
        start: {
          line: 162,
          column: 10
        },
        end: {
          line: 162,
          column: 50
        }
      },
      '66': {
        start: {
          line: 163,
          column: 10
        },
        end: {
          line: 163,
          column: 23
        }
      },
      '67': {
        start: {
          line: 166,
          column: 8
        },
        end: {
          line: 171,
          column: 9
        }
      },
      '68': {
        start: {
          line: 167,
          column: 10
        },
        end: {
          line: 169,
          column: 11
        }
      },
      '69': {
        start: {
          line: 168,
          column: 12
        },
        end: {
          line: 168,
          column: 21
        }
      },
      '70': {
        start: {
          line: 170,
          column: 10
        },
        end: {
          line: 170,
          column: 93
        }
      },
      '71': {
        start: {
          line: 172,
          column: 20
        },
        end: {
          line: 174,
          column: 23
        }
      },
      '72': {
        start: {
          line: 173,
          column: 10
        },
        end: {
          line: 173,
          column: 23
        }
      },
      '73': {
        start: {
          line: 175,
          column: 8
        },
        end: {
          line: 207,
          column: 9
        }
      },
      '74': {
        start: {
          line: 176,
          column: 10
        },
        end: {
          line: 206,
          column: 13
        }
      },
      '75': {
        start: {
          line: 177,
          column: 12
        },
        end: {
          line: 177,
          column: 52
        }
      },
      '76': {
        start: {
          line: 178,
          column: 12
        },
        end: {
          line: 202,
          column: 13
        }
      },
      '77': {
        start: {
          line: 179,
          column: 14
        },
        end: {
          line: 181,
          column: 15
        }
      },
      '78': {
        start: {
          line: 180,
          column: 16
        },
        end: {
          line: 180,
          column: 25
        }
      },
      '79': {
        start: {
          line: 182,
          column: 33
        },
        end: {
          line: 182,
          column: 58
        }
      },
      '80': {
        start: {
          line: 183,
          column: 14
        },
        end: {
          line: 183,
          column: 68
        }
      },
      '81': {
        start: {
          line: 184,
          column: 14
        },
        end: {
          line: 201,
          column: 15
        }
      },
      '82': {
        start: {
          line: 185,
          column: 16
        },
        end: {
          line: 200,
          column: 17
        }
      },
      '83': {
        start: {
          line: 186,
          column: 32
        },
        end: {
          line: 186,
          column: 70
        }
      },
      '84': {
        start: {
          line: 187,
          column: 18
        },
        end: {
          line: 187,
          column: 79
        }
      },
      '85': {
        start: {
          line: 189,
          column: 18
        },
        end: {
          line: 199,
          column: 21
        }
      },
      '86': {
        start: {
          line: 204,
          column: 12
        },
        end: {
          line: 204,
          column: 88
        }
      },
      '87': {
        start: {
          line: 205,
          column: 12
        },
        end: {
          line: 205,
          column: 50
        }
      },
      '88': {
        start: {
          line: 212,
          column: 17
        },
        end: {
          line: 212,
          column: 22
        }
      },
      '89': {
        start: {
          line: 213,
          column: 6
        },
        end: {
          line: 219,
          column: 7
        }
      },
      '90': {
        start: {
          line: 214,
          column: 8
        },
        end: {
          line: 218,
          column: 9
        }
      },
      '91': {
        start: {
          line: 215,
          column: 10
        },
        end: {
          line: 215,
          column: 49
        }
      },
      '92': {
        start: {
          line: 216,
          column: 10
        },
        end: {
          line: 216,
          column: 22
        }
      },
      '93': {
        start: {
          line: 217,
          column: 10
        },
        end: {
          line: 217,
          column: 16
        }
      },
      '94': {
        start: {
          line: 220,
          column: 6
        },
        end: {
          line: 222,
          column: 7
        }
      },
      '95': {
        start: {
          line: 221,
          column: 8
        },
        end: {
          line: 221,
          column: 32
        }
      },
      '96': {
        start: {
          line: 225,
          column: 6
        },
        end: {
          line: 237,
          column: 7
        }
      },
      '97': {
        start: {
          line: 226,
          column: 8
        },
        end: {
          line: 230,
          column: 11
        }
      },
      '98': {
        start: {
          line: 232,
          column: 8
        },
        end: {
          line: 236,
          column: 96
        }
      },
      '99': {
        start: {
          line: 238,
          column: 6
        },
        end: {
          line: 238,
          column: 45
        }
      },
      '100': {
        start: {
          line: 239,
          column: 6
        },
        end: {
          line: 239,
          column: 26
        }
      },
      '101': {
        start: {
          line: 242,
          column: 6
        },
        end: {
          line: 244,
          column: 7
        }
      },
      '102': {
        start: {
          line: 243,
          column: 8
        },
        end: {
          line: 243,
          column: 46
        }
      },
      '103': {
        start: {
          line: 245,
          column: 6
        },
        end: {
          line: 245,
          column: 49
        }
      },
      '104': {
        start: {
          line: 248,
          column: 6
        },
        end: {
          line: 250,
          column: 7
        }
      },
      '105': {
        start: {
          line: 249,
          column: 8
        },
        end: {
          line: 249,
          column: 45
        }
      },
      '106': {
        start: {
          line: 251,
          column: 6
        },
        end: {
          line: 251,
          column: 48
        }
      },
      '107': {
        start: {
          line: 254,
          column: 21
        },
        end: {
          line: 274,
          column: 7
        }
      },
      '108': {
        start: {
          line: 256,
          column: 10
        },
        end: {
          line: 261,
          column: 11
        }
      },
      '109': {
        start: {
          line: 257,
          column: 12
        },
        end: {
          line: 260,
          column: 17
        }
      },
      '110': {
        start: {
          line: 262,
          column: 10
        },
        end: {
          line: 262,
          column: 38
        }
      },
      '111': {
        start: {
          line: 265,
          column: 10
        },
        end: {
          line: 265,
          column: 48
        }
      },
      '112': {
        start: {
          line: 275,
          column: 18
        },
        end: {
          line: 275,
          column: 46
        }
      },
      '113': {
        start: {
          line: 276,
          column: 6
        },
        end: {
          line: 276,
          column: 41
        }
      },
      '114': {
        start: {
          line: 277,
          column: 6
        },
        end: {
          line: 280,
          column: 7
        }
      },
      '115': {
        start: {
          line: 278,
          column: 20
        },
        end: {
          line: 278,
          column: 36
        }
      },
      '116': {
        start: {
          line: 279,
          column: 8
        },
        end: {
          line: 279,
          column: 62
        }
      },
      '117': {
        start: {
          line: 281,
          column: 6
        },
        end: {
          line: 281,
          column: 26
        }
      },
      '118': {
        start: {
          line: 282,
          column: 6
        },
        end: {
          line: 282,
          column: 27
        }
      },
      '119': {
        start: {
          line: 283,
          column: 20
        },
        end: {
          line: 283,
          column: 47
        }
      },
      '120': {
        start: {
          line: 284,
          column: 6
        },
        end: {
          line: 284,
          column: 35
        }
      },
      '121': {
        start: {
          line: 285,
          column: 6
        },
        end: {
          line: 287,
          column: 9
        }
      },
      '122': {
        start: {
          line: 286,
          column: 8
        },
        end: {
          line: 286,
          column: 41
        }
      },
      '123': {
        start: {
          line: 288,
          column: 6
        },
        end: {
          line: 292,
          column: 7
        }
      },
      '124': {
        start: {
          line: 289,
          column: 8
        },
        end: {
          line: 291,
          column: 11
        }
      },
      '125': {
        start: {
          line: 290,
          column: 10
        },
        end: {
          line: 290,
          column: 74
        }
      },
      '126': {
        start: {
          line: 293,
          column: 6
        },
        end: {
          line: 293,
          column: 19
        }
      },
      '127': {
        start: {
          line: 296,
          column: 21
        },
        end: {
          line: 313,
          column: 7
        }
      },
      '128': {
        start: {
          line: 298,
          column: 10
        },
        end: {
          line: 303,
          column: 11
        }
      },
      '129': {
        start: {
          line: 299,
          column: 12
        },
        end: {
          line: 302,
          column: 17
        }
      },
      '130': {
        start: {
          line: 304,
          column: 10
        },
        end: {
          line: 304,
          column: 38
        }
      },
      '131': {
        start: {
          line: 314,
          column: 18
        },
        end: {
          line: 314,
          column: 46
        }
      },
      '132': {
        start: {
          line: 315,
          column: 6
        },
        end: {
          line: 315,
          column: 41
        }
      },
      '133': {
        start: {
          line: 316,
          column: 6
        },
        end: {
          line: 319,
          column: 7
        }
      },
      '134': {
        start: {
          line: 317,
          column: 20
        },
        end: {
          line: 317,
          column: 36
        }
      },
      '135': {
        start: {
          line: 318,
          column: 8
        },
        end: {
          line: 318,
          column: 62
        }
      },
      '136': {
        start: {
          line: 320,
          column: 6
        },
        end: {
          line: 320,
          column: 26
        }
      },
      '137': {
        start: {
          line: 321,
          column: 18
        },
        end: {
          line: 321,
          column: 22
        }
      },
      '138': {
        start: {
          line: 322,
          column: 6
        },
        end: {
          line: 333,
          column: 7
        }
      },
      '139': {
        start: {
          line: 323,
          column: 8
        },
        end: {
          line: 329,
          column: 11
        }
      },
      '140': {
        start: {
          line: 331,
          column: 8
        },
        end: {
          line: 331,
          column: 26
        }
      },
      '141': {
        start: {
          line: 332,
          column: 8
        },
        end: {
          line: 332,
          column: 48
        }
      },
      '142': {
        start: {
          line: 334,
          column: 6
        },
        end: {
          line: 334,
          column: 35
        }
      },
      '143': {
        start: {
          line: 335,
          column: 6
        },
        end: {
          line: 337,
          column: 9
        }
      },
      '144': {
        start: {
          line: 336,
          column: 8
        },
        end: {
          line: 336,
          column: 41
        }
      },
      '145': {
        start: {
          line: 338,
          column: 6
        },
        end: {
          line: 342,
          column: 7
        }
      },
      '146': {
        start: {
          line: 339,
          column: 8
        },
        end: {
          line: 341,
          column: 11
        }
      },
      '147': {
        start: {
          line: 340,
          column: 10
        },
        end: {
          line: 340,
          column: 74
        }
      },
      '148': {
        start: {
          line: 343,
          column: 6
        },
        end: {
          line: 343,
          column: 19
        }
      },
      '149': {
        start: {
          line: 346,
          column: 6
        },
        end: {
          line: 346,
          column: 43
        }
      },
      '150': {
        start: {
          line: 347,
          column: 6
        },
        end: {
          line: 347,
          column: 30
        }
      },
      '151': {
        start: {
          line: 348,
          column: 6
        },
        end: {
          line: 374,
          column: 9
        }
      },
      '152': {
        start: {
          line: 354,
          column: 8
        },
        end: {
          line: 373,
          column: 9
        }
      },
      '153': {
        start: {
          line: 355,
          column: 28
        },
        end: {
          line: 360,
          column: 12
        }
      },
      '154': {
        start: {
          line: 356,
          column: 12
        },
        end: {
          line: 358,
          column: 13
        }
      },
      '155': {
        start: {
          line: 357,
          column: 14
        },
        end: {
          line: 357,
          column: 27
        }
      },
      '156': {
        start: {
          line: 359,
          column: 12
        },
        end: {
          line: 359,
          column: 49
        }
      },
      '157': {
        start: {
          line: 361,
          column: 23
        },
        end: {
          line: 361,
          column: 65
        }
      },
      '158': {
        start: {
          line: 362,
          column: 10
        },
        end: {
          line: 362,
          column: 32
        }
      },
      '159': {
        start: {
          line: 363,
          column: 26
        },
        end: {
          line: 363,
          column: 68
        }
      },
      '160': {
        start: {
          line: 364,
          column: 10
        },
        end: {
          line: 370,
          column: 11
        }
      },
      '161': {
        start: {
          line: 365,
          column: 12
        },
        end: {
          line: 369,
          column: 13
        }
      },
      '162': {
        start: {
          line: 366,
          column: 14
        },
        end: {
          line: 366,
          column: 130
        }
      },
      '163': {
        start: {
          line: 367,
          column: 14
        },
        end: {
          line: 367,
          column: 102
        }
      },
      '164': {
        start: {
          line: 368,
          column: 14
        },
        end: {
          line: 368,
          column: 20
        }
      },
      '165': {
        start: {
          line: 372,
          column: 10
        },
        end: {
          line: 372,
          column: 63
        }
      },
      '166': {
        start: {
          line: 377,
          column: 6
        },
        end: {
          line: 425,
          column: 7
        }
      },
      '167': {
        start: {
          line: 378,
          column: 8
        },
        end: {
          line: 393,
          column: 9
        }
      },
      '168': {
        start: {
          line: 379,
          column: 10
        },
        end: {
          line: 381,
          column: 11
        }
      },
      '169': {
        start: {
          line: 380,
          column: 12
        },
        end: {
          line: 380,
          column: 47
        }
      },
      '170': {
        start: {
          line: 382,
          column: 10
        },
        end: {
          line: 384,
          column: 11
        }
      },
      '171': {
        start: {
          line: 383,
          column: 12
        },
        end: {
          line: 383,
          column: 47
        }
      },
      '172': {
        start: {
          line: 385,
          column: 10
        },
        end: {
          line: 387,
          column: 11
        }
      },
      '173': {
        start: {
          line: 386,
          column: 12
        },
        end: {
          line: 386,
          column: 49
        }
      },
      '174': {
        start: {
          line: 388,
          column: 10
        },
        end: {
          line: 390,
          column: 11
        }
      },
      '175': {
        start: {
          line: 389,
          column: 12
        },
        end: {
          line: 389,
          column: 55
        }
      },
      '176': {
        start: {
          line: 391,
          column: 10
        },
        end: {
          line: 391,
          column: 46
        }
      },
      '177': {
        start: {
          line: 392,
          column: 10
        },
        end: {
          line: 392,
          column: 17
        }
      },
      '178': {
        start: {
          line: 394,
          column: 8
        },
        end: {
          line: 394,
          column: 43
        }
      },
      '179': {
        start: {
          line: 395,
          column: 8
        },
        end: {
          line: 397,
          column: 11
        }
      },
      '180': {
        start: {
          line: 396,
          column: 10
        },
        end: {
          line: 396,
          column: 68
        }
      },
      '181': {
        start: {
          line: 398,
          column: 8
        },
        end: {
          line: 407,
          column: 9
        }
      },
      '182': {
        start: {
          line: 399,
          column: 21
        },
        end: {
          line: 399,
          column: 55
        }
      },
      '183': {
        start: {
          line: 400,
          column: 10
        },
        end: {
          line: 400,
          column: 49
        }
      },
      '184': {
        start: {
          line: 401,
          column: 10
        },
        end: {
          line: 406,
          column: 11
        }
      },
      '185': {
        start: {
          line: 402,
          column: 12
        },
        end: {
          line: 405,
          column: 13
        }
      },
      '186': {
        start: {
          line: 403,
          column: 14
        },
        end: {
          line: 403,
          column: 54
        }
      },
      '187': {
        start: {
          line: 404,
          column: 14
        },
        end: {
          line: 404,
          column: 20
        }
      },
      '188': {
        start: {
          line: 409,
          column: 8
        },
        end: {
          line: 413,
          column: 9
        }
      },
      '189': {
        start: {
          line: 410,
          column: 10
        },
        end: {
          line: 412,
          column: 11
        }
      },
      '190': {
        start: {
          line: 411,
          column: 12
        },
        end: {
          line: 411,
          column: 46
        }
      },
      '191': {
        start: {
          line: 415,
          column: 8
        },
        end: {
          line: 417,
          column: 9
        }
      },
      '192': {
        start: {
          line: 416,
          column: 10
        },
        end: {
          line: 416,
          column: 112
        }
      },
      '193': {
        start: {
          line: 418,
          column: 8
        },
        end: {
          line: 422,
          column: 104
        }
      },
      '194': {
        start: {
          line: 423,
          column: 8
        },
        end: {
          line: 423,
          column: 43
        }
      },
      '195': {
        start: {
          line: 424,
          column: 8
        },
        end: {
          line: 424,
          column: 26
        }
      },
      '196': {
        start: {
          line: 428,
          column: 6
        },
        end: {
          line: 428,
          column: 26
        }
      },
      '197': {
        start: {
          line: 429,
          column: 6
        },
        end: {
          line: 429,
          column: 33
        }
      },
      '198': {
        start: {
          line: 430,
          column: 18
        },
        end: {
          line: 430,
          column: 19
        }
      },
      '199': {
        start: {
          line: 431,
          column: 6
        },
        end: {
          line: 442,
          column: 7
        }
      },
      '200': {
        start: {
          line: 432,
          column: 8
        },
        end: {
          line: 432,
          column: 38
        }
      },
      '201': {
        start: {
          line: 433,
          column: 8
        },
        end: {
          line: 435,
          column: 9
        }
      },
      '202': {
        start: {
          line: 434,
          column: 10
        },
        end: {
          line: 434,
          column: 16
        }
      },
      '203': {
        start: {
          line: 436,
          column: 8
        },
        end: {
          line: 440,
          column: 9
        }
      },
      '204': {
        start: {
          line: 437,
          column: 10
        },
        end: {
          line: 437,
          column: 36
        }
      },
      '205': {
        start: {
          line: 438,
          column: 10
        },
        end: {
          line: 438,
          column: 39
        }
      },
      '206': {
        start: {
          line: 441,
          column: 8
        },
        end: {
          line: 441,
          column: 16
        }
      },
      '207': {
        start: {
          line: 443,
          column: 6
        },
        end: {
          line: 445,
          column: 7
        }
      },
      '208': {
        start: {
          line: 444,
          column: 8
        },
        end: {
          line: 444,
          column: 15
        }
      },
      '209': {
        start: {
          line: 446,
          column: 6
        },
        end: {
          line: 446,
          column: 29
        }
      },
      '210': {
        start: {
          line: 450,
          column: 6
        },
        end: {
          line: 452,
          column: 7
        }
      },
      '211': {
        start: {
          line: 451,
          column: 8
        },
        end: {
          line: 451,
          column: 15
        }
      },
      '212': {
        start: {
          line: 453,
          column: 6
        },
        end: {
          line: 453,
          column: 96
        }
      },
      '213': {
        start: {
          line: 454,
          column: 6
        },
        end: {
          line: 454,
          column: 27
        }
      },
      '214': {
        start: {
          line: 459,
          column: 6
        },
        end: {
          line: 459,
          column: 41
        }
      },
      '215': {
        start: {
          line: 462,
          column: 21
        },
        end: {
          line: 467,
          column: 7
        }
      },
      '216': {
        start: {
          line: 468,
          column: 6
        },
        end: {
          line: 472,
          column: 7
        }
      },
      '217': {
        start: {
          line: 469,
          column: 8
        },
        end: {
          line: 469,
          column: 26
        }
      },
      '218': {
        start: {
          line: 470,
          column: 8
        },
        end: {
          line: 470,
          column: 100
        }
      },
      '219': {
        start: {
          line: 471,
          column: 8
        },
        end: {
          line: 471,
          column: 96
        }
      },
      '220': {
        start: {
          line: 473,
          column: 6
        },
        end: {
          line: 477,
          column: 7
        }
      },
      '221': {
        start: {
          line: 474,
          column: 8
        },
        end: {
          line: 474,
          column: 26
        }
      },
      '222': {
        start: {
          line: 475,
          column: 8
        },
        end: {
          line: 475,
          column: 80
        }
      },
      '223': {
        start: {
          line: 476,
          column: 8
        },
        end: {
          line: 476,
          column: 76
        }
      },
      '224': {
        start: {
          line: 479,
          column: 6
        },
        end: {
          line: 479,
          column: 69
        }
      },
      '225': {
        start: {
          line: 480,
          column: 6
        },
        end: {
          line: 589,
          column: 9
        }
      },
      '226': {
        start: {
          line: 481,
          column: 23
        },
        end: {
          line: 481,
          column: 24
        }
      },
      '227': {
        start: {
          line: 482,
          column: 8
        },
        end: {
          line: 498,
          column: 9
        }
      },
      '228': {
        start: {
          line: 483,
          column: 10
        },
        end: {
          line: 486,
          column: 11
        }
      },
      '229': {
        start: {
          line: 484,
          column: 12
        },
        end: {
          line: 484,
          column: 27
        }
      },
      '230': {
        start: {
          line: 485,
          column: 12
        },
        end: {
          line: 485,
          column: 21
        }
      },
      '231': {
        start: {
          line: 487,
          column: 21
        },
        end: {
          line: 487,
          column: 25
        }
      },
      '232': {
        start: {
          line: 488,
          column: 10
        },
        end: {
          line: 493,
          column: 11
        }
      },
      '233': {
        start: {
          line: 489,
          column: 12
        },
        end: {
          line: 489,
          column: 33
        }
      },
      '234': {
        start: {
          line: 491,
          column: 12
        },
        end: {
          line: 491,
          column: 27
        }
      },
      '235': {
        start: {
          line: 492,
          column: 12
        },
        end: {
          line: 492,
          column: 21
        }
      },
      '236': {
        start: {
          line: 494,
          column: 10
        },
        end: {
          line: 494,
          column: 25
        }
      },
      '237': {
        start: {
          line: 495,
          column: 10
        },
        end: {
          line: 497,
          column: 11
        }
      },
      '238': {
        start: {
          line: 496,
          column: 12
        },
        end: {
          line: 496,
          column: 57
        }
      },
      '239': {
        start: {
          line: 500,
          column: 8
        },
        end: {
          line: 569,
          column: 9
        }
      },
      '240': {
        start: {
          line: 501,
          column: 10
        },
        end: {
          line: 503,
          column: 11
        }
      },
      '241': {
        start: {
          line: 502,
          column: 12
        },
        end: {
          line: 502,
          column: 21
        }
      },
      '242': {
        start: {
          line: 504,
          column: 23
        },
        end: {
          line: 504,
          column: 30
        }
      },
      '243': {
        start: {
          line: 505,
          column: 10
        },
        end: {
          line: 505,
          column: 44
        }
      },
      '244': {
        start: {
          line: 510,
          column: 31
        },
        end: {
          line: 518,
          column: 20
        }
      },
      '245': {
        start: {
          line: 571,
          column: 8
        },
        end: {
          line: 573,
          column: 9
        }
      },
      '246': {
        start: {
          line: 572,
          column: 10
        },
        end: {
          line: 572,
          column: 53
        }
      },
      '247': {
        start: {
          line: 575,
          column: 8
        },
        end: {
          line: 575,
          column: 81
        }
      },
      '248': {
        start: {
          line: 576,
          column: 8
        },
        end: {
          line: 587,
          column: 10
        }
      },
      '249': {
        start: {
          line: 577,
          column: 22
        },
        end: {
          line: 577,
          column: 60
        }
      },
      '250': {
        start: {
          line: 579,
          column: 10
        },
        end: {
          line: 585,
          column: 11
        }
      },
      '251': {
        start: {
          line: 580,
          column: 12
        },
        end: {
          line: 584,
          column: 169
        }
      },
      '252': {
        start: {
          line: 586,
          column: 10
        },
        end: {
          line: 586,
          column: 21
        }
      },
      '253': {
        start: {
          line: 588,
          column: 8
        },
        end: {
          line: 588,
          column: 75
        }
      },
      '254': {
        start: {
          line: 592,
          column: 19
        },
        end: {
          line: 592,
          column: 106
        }
      },
      '255': {
        start: {
          line: 595,
          column: 20
        },
        end: {
          line: 595,
          column: 79
        }
      },
      '256': {
        start: {
          line: 596,
          column: 6
        },
        end: {
          line: 612,
          column: 7
        }
      },
      '257': {
        start: {
          line: 598,
          column: 10
        },
        end: {
          line: 598,
          column: 107
        }
      },
      '258': {
        start: {
          line: 599,
          column: 10
        },
        end: {
          line: 599,
          column: 16
        }
      },
      '259': {
        start: {
          line: 601,
          column: 10
        },
        end: {
          line: 601,
          column: 110
        }
      },
      '260': {
        start: {
          line: 602,
          column: 10
        },
        end: {
          line: 602,
          column: 16
        }
      },
      '261': {
        start: {
          line: 604,
          column: 10
        },
        end: {
          line: 604,
          column: 110
        }
      },
      '262': {
        start: {
          line: 605,
          column: 10
        },
        end: {
          line: 605,
          column: 16
        }
      },
      '263': {
        start: {
          line: 607,
          column: 10
        },
        end: {
          line: 607,
          column: 110
        }
      },
      '264': {
        start: {
          line: 608,
          column: 10
        },
        end: {
          line: 608,
          column: 16
        }
      },
      '265': {
        start: {
          line: 610,
          column: 10
        },
        end: {
          line: 610,
          column: 107
        }
      },
      '266': {
        start: {
          line: 611,
          column: 10
        },
        end: {
          line: 611,
          column: 16
        }
      },
      '267': {
        start: {
          line: 613,
          column: 6
        },
        end: {
          line: 613,
          column: 124
        }
      },
      '268': {
        start: {
          line: 616,
          column: 6
        },
        end: {
          line: 618,
          column: 7
        }
      },
      '269': {
        start: {
          line: 617,
          column: 8
        },
        end: {
          line: 617,
          column: 49
        }
      },
      '270': {
        start: {
          line: 621,
          column: 6
        },
        end: {
          line: 621,
          column: 47
        }
      },
      '271': {
        start: {
          line: 622,
          column: 20
        },
        end: {
          line: 622,
          column: 85
        }
      },
      '272': {
        start: {
          line: 623,
          column: 6
        },
        end: {
          line: 623,
          column: 43
        }
      },
      '273': {
        start: {
          line: 624,
          column: 6
        },
        end: {
          line: 624,
          column: 45
        }
      },
      '274': {
        start: {
          line: 625,
          column: 6
        },
        end: {
          line: 625,
          column: 43
        }
      },
      '275': {
        start: {
          line: 629,
          column: 2
        },
        end: {
          line: 833,
          column: 4
        }
      },
      '276': {
        start: {
          line: 632,
          column: 6
        },
        end: {
          line: 634,
          column: 7
        }
      },
      '277': {
        start: {
          line: 633,
          column: 8
        },
        end: {
          line: 633,
          column: 15
        }
      },
      '278': {
        start: {
          line: 635,
          column: 6
        },
        end: {
          line: 639,
          column: 7
        }
      },
      '279': {
        start: {
          line: 636,
          column: 8
        },
        end: {
          line: 636,
          column: 84
        }
      },
      '280': {
        start: {
          line: 638,
          column: 8
        },
        end: {
          line: 638,
          column: 90
        }
      },
      '281': {
        start: {
          line: 643,
          column: 19
        },
        end: {
          line: 643,
          column: 106
        }
      },
      '282': {
        start: {
          line: 647,
          column: 6
        },
        end: {
          line: 666,
          column: 7
        }
      },
      '283': {
        start: {
          line: 649,
          column: 10
        },
        end: {
          line: 649,
          column: 107
        }
      },
      '284': {
        start: {
          line: 650,
          column: 10
        },
        end: {
          line: 650,
          column: 16
        }
      },
      '285': {
        start: {
          line: 652,
          column: 10
        },
        end: {
          line: 652,
          column: 110
        }
      },
      '286': {
        start: {
          line: 653,
          column: 10
        },
        end: {
          line: 653,
          column: 16
        }
      },
      '287': {
        start: {
          line: 655,
          column: 10
        },
        end: {
          line: 655,
          column: 110
        }
      },
      '288': {
        start: {
          line: 656,
          column: 10
        },
        end: {
          line: 656,
          column: 16
        }
      },
      '289': {
        start: {
          line: 658,
          column: 10
        },
        end: {
          line: 658,
          column: 110
        }
      },
      '290': {
        start: {
          line: 659,
          column: 10
        },
        end: {
          line: 659,
          column: 16
        }
      },
      '291': {
        start: {
          line: 661,
          column: 10
        },
        end: {
          line: 661,
          column: 110
        }
      },
      '292': {
        start: {
          line: 662,
          column: 10
        },
        end: {
          line: 662,
          column: 16
        }
      },
      '293': {
        start: {
          line: 664,
          column: 10
        },
        end: {
          line: 664,
          column: 107
        }
      },
      '294': {
        start: {
          line: 665,
          column: 10
        },
        end: {
          line: 665,
          column: 16
        }
      },
      '295': {
        start: {
          line: 667,
          column: 6
        },
        end: {
          line: 667,
          column: 124
        }
      },
      '296': {
        start: {
          line: 670,
          column: 6
        },
        end: {
          line: 672,
          column: 9
        }
      },
      '297': {
        start: {
          line: 671,
          column: 8
        },
        end: {
          line: 671,
          column: 51
        }
      },
      '298': {
        start: {
          line: 675,
          column: 24
        },
        end: {
          line: 675,
          column: 50
        }
      },
      '299': {
        start: {
          line: 677,
          column: 18
        },
        end: {
          line: 677,
          column: 59
        }
      },
      '300': {
        start: {
          line: 678,
          column: 6
        },
        end: {
          line: 699,
          column: 7
        }
      },
      '301': {
        start: {
          line: 679,
          column: 8
        },
        end: {
          line: 681,
          column: 9
        }
      },
      '302': {
        start: {
          line: 680,
          column: 10
        },
        end: {
          line: 680,
          column: 98
        }
      },
      '303': {
        start: {
          line: 682,
          column: 8
        },
        end: {
          line: 684,
          column: 9
        }
      },
      '304': {
        start: {
          line: 683,
          column: 10
        },
        end: {
          line: 683,
          column: 86
        }
      },
      '305': {
        start: {
          line: 686,
          column: 8
        },
        end: {
          line: 688,
          column: 9
        }
      },
      '306': {
        start: {
          line: 687,
          column: 10
        },
        end: {
          line: 687,
          column: 272
        }
      },
      '307': {
        start: {
          line: 690,
          column: 8
        },
        end: {
          line: 690,
          column: 80
        }
      },
      '308': {
        start: {
          line: 700,
          column: 6
        },
        end: {
          line: 700,
          column: 132
        }
      },
      '309': {
        start: {
          line: 704,
          column: 6
        },
        end: {
          line: 704,
          column: 39
        }
      },
      '310': {
        start: {
          line: 705,
          column: 25
        },
        end: {
          line: 705,
          column: 71
        }
      },
      '311': {
        start: {
          line: 706,
          column: 21
        },
        end: {
          line: 706,
          column: 125
        }
      },
      '312': {
        start: {
          line: 707,
          column: 6
        },
        end: {
          line: 807,
          column: 9
        }
      },
      '313': {
        start: {
          line: 708,
          column: 8
        },
        end: {
          line: 708,
          column: 54
        }
      },
      '314': {
        start: {
          line: 709,
          column: 20
        },
        end: {
          line: 709,
          column: 21
        }
      },
      '315': {
        start: {
          line: 710,
          column: 23
        },
        end: {
          line: 710,
          column: 29
        }
      },
      '316': {
        start: {
          line: 713,
          column: 21
        },
        end: {
          line: 713,
          column: 30
        }
      },
      '317': {
        start: {
          line: 722,
          column: 8
        },
        end: {
          line: 800,
          column: 9
        }
      },
      '318': {
        start: {
          line: 723,
          column: 20
        },
        end: {
          line: 723,
          column: 34
        }
      },
      '319': {
        start: {
          line: 724,
          column: 10
        },
        end: {
          line: 724,
          column: 44
        }
      },
      '320': {
        start: {
          line: 726,
          column: 10
        },
        end: {
          line: 799,
          column: 11
        }
      },
      '321': {
        start: {
          line: 727,
          column: 12
        },
        end: {
          line: 798,
          column: 13
        }
      },
      '322': {
        start: {
          line: 728,
          column: 14
        },
        end: {
          line: 797,
          column: 15
        }
      },
      '323': {
        start: {
          line: 729,
          column: 16
        },
        end: {
          line: 756,
          column: 17
        }
      },
      '324': {
        start: {
          line: 730,
          column: 30
        },
        end: {
          line: 730,
          column: 123
        }
      },
      '325': {
        start: {
          line: 731,
          column: 18
        },
        end: {
          line: 733,
          column: 19
        }
      },
      '326': {
        start: {
          line: 732,
          column: 20
        },
        end: {
          line: 732,
          column: 29
        }
      },
      '327': {
        start: {
          line: 734,
          column: 18
        },
        end: {
          line: 742,
          column: 21
        }
      },
      '328': {
        start: {
          line: 735,
          column: 20
        },
        end: {
          line: 737,
          column: 21
        }
      },
      '329': {
        start: {
          line: 736,
          column: 22
        },
        end: {
          line: 736,
          column: 35
        }
      },
      '330': {
        start: {
          line: 738,
          column: 20
        },
        end: {
          line: 740,
          column: 21
        }
      },
      '331': {
        start: {
          line: 739,
          column: 22
        },
        end: {
          line: 739,
          column: 35
        }
      },
      '332': {
        start: {
          line: 741,
          column: 20
        },
        end: {
          line: 741,
          column: 32
        }
      },
      '333': {
        start: {
          line: 743,
          column: 18
        },
        end: {
          line: 755,
          column: 21
        }
      },
      '334': {
        start: {
          line: 744,
          column: 34
        },
        end: {
          line: 747,
          column: 56
        }
      },
      '335': {
        start: {
          line: 748,
          column: 20
        },
        end: {
          line: 754,
          column: 23
        }
      },
      '336': {
        start: {
          line: 749,
          column: 22
        },
        end: {
          line: 753,
          column: 23
        }
      },
      '337': {
        start: {
          line: 750,
          column: 24
        },
        end: {
          line: 750,
          column: 135
        }
      },
      '338': {
        start: {
          line: 752,
          column: 24
        },
        end: {
          line: 752,
          column: 56
        }
      },
      '339': {
        start: {
          line: 757,
          column: 16
        },
        end: {
          line: 791,
          column: 17
        }
      },
      '340': {
        start: {
          line: 758,
          column: 32
        },
        end: {
          line: 758,
          column: 125
        }
      },
      '341': {
        start: {
          line: 759,
          column: 18
        },
        end: {
          line: 761,
          column: 19
        }
      },
      '342': {
        start: {
          line: 760,
          column: 20
        },
        end: {
          line: 760,
          column: 29
        }
      },
      '343': {
        start: {
          line: 762,
          column: 18
        },
        end: {
          line: 764,
          column: 19
        }
      },
      '344': {
        start: {
          line: 763,
          column: 20
        },
        end: {
          line: 763,
          column: 29
        }
      },
      '345': {
        start: {
          line: 765,
          column: 18
        },
        end: {
          line: 765,
          column: 40
        }
      },
      '346': {
        start: {
          line: 766,
          column: 18
        },
        end: {
          line: 766,
          column: 40
        }
      },
      '347': {
        start: {
          line: 767,
          column: 18
        },
        end: {
          line: 767,
          column: 26
        }
      },
      '348': {
        start: {
          line: 768,
          column: 18
        },
        end: {
          line: 790,
          column: 21
        }
      },
      '349': {
        start: {
          line: 779,
          column: 34
        },
        end: {
          line: 782,
          column: 56
        }
      },
      '350': {
        start: {
          line: 783,
          column: 20
        },
        end: {
          line: 789,
          column: 23
        }
      },
      '351': {
        start: {
          line: 784,
          column: 22
        },
        end: {
          line: 788,
          column: 23
        }
      },
      '352': {
        start: {
          line: 785,
          column: 24
        },
        end: {
          line: 785,
          column: 135
        }
      },
      '353': {
        start: {
          line: 787,
          column: 24
        },
        end: {
          line: 787,
          column: 56
        }
      },
      '354': {
        start: {
          line: 793,
          column: 16
        },
        end: {
          line: 793,
          column: 39
        }
      },
      '355': {
        start: {
          line: 794,
          column: 16
        },
        end: {
          line: 794,
          column: 62
        }
      },
      '356': {
        start: {
          line: 802,
          column: 8
        },
        end: {
          line: 805,
          column: 9
        }
      },
      '357': {
        start: {
          line: 803,
          column: 10
        },
        end: {
          line: 803,
          column: 29
        }
      },
      '358': {
        start: {
          line: 804,
          column: 10
        },
        end: {
          line: 804,
          column: 29
        }
      },
      '359': {
        start: {
          line: 806,
          column: 8
        },
        end: {
          line: 806,
          column: 50
        }
      },
      '360': {
        start: {
          line: 810,
          column: 6
        },
        end: {
          line: 813,
          column: 7
        }
      },
      '361': {
        start: {
          line: 811,
          column: 8
        },
        end: {
          line: 811,
          column: 47
        }
      },
      '362': {
        start: {
          line: 812,
          column: 8
        },
        end: {
          line: 812,
          column: 40
        }
      },
      '363': {
        start: {
          line: 815,
          column: 6
        },
        end: {
          line: 815,
          column: 30
        }
      },
      '364': {
        start: {
          line: 816,
          column: 6
        },
        end: {
          line: 816,
          column: 25
        }
      },
      '365': {
        start: {
          line: 817,
          column: 6
        },
        end: {
          line: 817,
          column: 24
        }
      },
      '366': {
        start: {
          line: 818,
          column: 6
        },
        end: {
          line: 831,
          column: 9
        }
      },
      '367': {
        start: {
          line: 819,
          column: 8
        },
        end: {
          line: 821,
          column: 11
        }
      },
      '368': {
        start: {
          line: 822,
          column: 8
        },
        end: {
          line: 822,
          column: 66
        }
      },
      '369': {
        start: {
          line: 823,
          column: 8
        },
        end: {
          line: 823,
          column: 47
        }
      },
      '370': {
        start: {
          line: 824,
          column: 8
        },
        end: {
          line: 824,
          column: 42
        }
      },
      '371': {
        start: {
          line: 825,
          column: 8
        },
        end: {
          line: 830,
          column: 9
        }
      },
      '372': {
        start: {
          line: 826,
          column: 10
        },
        end: {
          line: 826,
          column: 35
        }
      },
      '373': {
        start: {
          line: 827,
          column: 10
        },
        end: {
          line: 827,
          column: 71
        }
      },
      '374': {
        start: {
          line: 828,
          column: 10
        },
        end: {
          line: 828,
          column: 55
        }
      },
      '375': {
        start: {
          line: 829,
          column: 10
        },
        end: {
          line: 829,
          column: 48
        }
      },
      '376': {
        start: {
          line: 834,
          column: 2
        },
        end: {
          line: 837,
          column: 5
        }
      },
      '377': {
        start: {
          line: 835,
          column: 4
        },
        end: {
          line: 835,
          column: 72
        }
      },
      '378': {
        start: {
          line: 836,
          column: 4
        },
        end: {
          line: 836,
          column: 43
        }
      },
      '379': {
        start: {
          line: 838,
          column: 2
        },
        end: {
          line: 838,
          column: 39
        }
      },
      '380': {
        start: {
          line: 839,
          column: 2
        },
        end: {
          line: 839,
          column: 44
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 53
          },
          end: {
            line: 2,
            column: 54
          }
        },
        loc: {
          start: {
            line: 2,
            column: 184
          },
          end: {
            line: 840,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 146,
            column: 61
          },
          end: {
            line: 146,
            column: 62
          }
        },
        loc: {
          start: {
            line: 146,
            column: 69
          },
          end: {
            line: 208,
            column: 7
          }
        },
        line: 146
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 157,
            column: 29
          },
          end: {
            line: 157,
            column: 30
          }
        },
        loc: {
          start: {
            line: 157,
            column: 38
          },
          end: {
            line: 164,
            column: 9
          }
        },
        line: 157
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 172,
            column: 30
          },
          end: {
            line: 172,
            column: 31
          }
        },
        loc: {
          start: {
            line: 172,
            column: 36
          },
          end: {
            line: 174,
            column: 9
          }
        },
        line: 172
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 176,
            column: 69
          },
          end: {
            line: 176,
            column: 70
          }
        },
        loc: {
          start: {
            line: 176,
            column: 78
          },
          end: {
            line: 206,
            column: 11
          }
        },
        line: 176
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 285,
            column: 24
          },
          end: {
            line: 285,
            column: 25
          }
        },
        loc: {
          start: {
            line: 285,
            column: 29
          },
          end: {
            line: 287,
            column: 7
          }
        },
        line: 285
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 289,
            column: 24
          },
          end: {
            line: 289,
            column: 25
          }
        },
        loc: {
          start: {
            line: 289,
            column: 33
          },
          end: {
            line: 291,
            column: 9
          }
        },
        line: 289
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 335,
            column: 24
          },
          end: {
            line: 335,
            column: 25
          }
        },
        loc: {
          start: {
            line: 335,
            column: 29
          },
          end: {
            line: 337,
            column: 7
          }
        },
        line: 335
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 339,
            column: 24
          },
          end: {
            line: 339,
            column: 25
          }
        },
        loc: {
          start: {
            line: 339,
            column: 33
          },
          end: {
            line: 341,
            column: 9
          }
        },
        line: 339
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 353,
            column: 9
          },
          end: {
            line: 353,
            column: 10
          }
        },
        loc: {
          start: {
            line: 353,
            column: 17
          },
          end: {
            line: 374,
            column: 7
          }
        },
        line: 353
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 355,
            column: 110
          },
          end: {
            line: 355,
            column: 111
          }
        },
        loc: {
          start: {
            line: 355,
            column: 119
          },
          end: {
            line: 360,
            column: 11
          }
        },
        line: 355
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 395,
            column: 76
          },
          end: {
            line: 395,
            column: 77
          }
        },
        loc: {
          start: {
            line: 395,
            column: 85
          },
          end: {
            line: 397,
            column: 9
          }
        },
        line: 395
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 480,
            column: 46
          },
          end: {
            line: 480,
            column: 47
          }
        },
        loc: {
          start: {
            line: 480,
            column: 54
          },
          end: {
            line: 589,
            column: 7
          }
        },
        line: 480
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 576,
            column: 50
          },
          end: {
            line: 576,
            column: 51
          }
        },
        loc: {
          start: {
            line: 576,
            column: 65
          },
          end: {
            line: 587,
            column: 9
          }
        },
        line: 576
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 670,
            column: 73
          },
          end: {
            line: 670,
            column: 74
          }
        },
        loc: {
          start: {
            line: 670,
            column: 81
          },
          end: {
            line: 672,
            column: 7
          }
        },
        line: 670
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 707,
            column: 49
          },
          end: {
            line: 707,
            column: 50
          }
        },
        loc: {
          start: {
            line: 707,
            column: 57
          },
          end: {
            line: 807,
            column: 7
          }
        },
        line: 707
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 734,
            column: 39
          },
          end: {
            line: 734,
            column: 40
          }
        },
        loc: {
          start: {
            line: 734,
            column: 46
          },
          end: {
            line: 742,
            column: 19
          }
        },
        line: 734
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 743,
            column: 94
          },
          end: {
            line: 743,
            column: 95
          }
        },
        loc: {
          start: {
            line: 743,
            column: 107
          },
          end: {
            line: 755,
            column: 19
          }
        },
        line: 743
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 748,
            column: 123
          },
          end: {
            line: 748,
            column: 124
          }
        },
        loc: {
          start: {
            line: 748,
            column: 134
          },
          end: {
            line: 754,
            column: 21
          }
        },
        line: 748
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 772,
            column: 44
          },
          end: {
            line: 772,
            column: 45
          }
        },
        loc: {
          start: {
            line: 772,
            column: 57
          },
          end: {
            line: 790,
            column: 19
          }
        },
        line: 772
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 783,
            column: 123
          },
          end: {
            line: 783,
            column: 124
          }
        },
        loc: {
          start: {
            line: 783,
            column: 134
          },
          end: {
            line: 789,
            column: 21
          }
        },
        line: 783
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 818,
            column: 67
          },
          end: {
            line: 818,
            column: 68
          }
        },
        loc: {
          start: {
            line: 818,
            column: 75
          },
          end: {
            line: 831,
            column: 7
          }
        },
        line: 818
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 834,
            column: 39
          },
          end: {
            line: 834,
            column: 40
          }
        },
        loc: {
          start: {
            line: 834,
            column: 45
          },
          end: {
            line: 837,
            column: 3
          }
        },
        line: 834
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 40,
            column: 6
          },
          end: {
            line: 56,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 40,
            column: 6
          },
          end: {
            line: 56,
            column: 7
          }
        }, {
          start: {
            line: 40,
            column: 6
          },
          end: {
            line: 56,
            column: 7
          }
        }],
        line: 40
      },
      '1': {
        loc: {
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 45,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 45,
            column: 9
          }
        }, {
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 45,
            column: 9
          }
        }],
        line: 41
      },
      '2': {
        loc: {
          start: {
            line: 46,
            column: 13
          },
          end: {
            line: 56,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 46,
            column: 13
          },
          end: {
            line: 56,
            column: 7
          }
        }, {
          start: {
            line: 46,
            column: 13
          },
          end: {
            line: 56,
            column: 7
          }
        }],
        line: 46
      },
      '3': {
        loc: {
          start: {
            line: 47,
            column: 8
          },
          end: {
            line: 51,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 47,
            column: 8
          },
          end: {
            line: 51,
            column: 9
          }
        }, {
          start: {
            line: 47,
            column: 8
          },
          end: {
            line: 51,
            column: 9
          }
        }],
        line: 47
      },
      '4': {
        loc: {
          start: {
            line: 52,
            column: 13
          },
          end: {
            line: 56,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 52,
            column: 13
          },
          end: {
            line: 56,
            column: 7
          }
        }, {
          start: {
            line: 52,
            column: 13
          },
          end: {
            line: 56,
            column: 7
          }
        }],
        line: 52
      },
      '5': {
        loc: {
          start: {
            line: 69,
            column: 8
          },
          end: {
            line: 71,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 69,
            column: 8
          },
          end: {
            line: 71,
            column: 9
          }
        }, {
          start: {
            line: 69,
            column: 8
          },
          end: {
            line: 71,
            column: 9
          }
        }],
        line: 69
      },
      '6': {
        loc: {
          start: {
            line: 85,
            column: 21
          },
          end: {
            line: 85,
            column: 45
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 85,
            column: 37
          },
          end: {
            line: 85,
            column: 40
          }
        }, {
          start: {
            line: 85,
            column: 43
          },
          end: {
            line: 85,
            column: 45
          }
        }],
        line: 85
      },
      '7': {
        loc: {
          start: {
            line: 89,
            column: 6
          },
          end: {
            line: 91,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 89,
            column: 6
          },
          end: {
            line: 91,
            column: 7
          }
        }, {
          start: {
            line: 89,
            column: 6
          },
          end: {
            line: 91,
            column: 7
          }
        }],
        line: 89
      },
      '8': {
        loc: {
          start: {
            line: 92,
            column: 6
          },
          end: {
            line: 94,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 92,
            column: 6
          },
          end: {
            line: 94,
            column: 7
          }
        }, {
          start: {
            line: 92,
            column: 6
          },
          end: {
            line: 94,
            column: 7
          }
        }],
        line: 92
      },
      '9': {
        loc: {
          start: {
            line: 97,
            column: 6
          },
          end: {
            line: 99,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 97,
            column: 6
          },
          end: {
            line: 99,
            column: 7
          }
        }, {
          start: {
            line: 97,
            column: 6
          },
          end: {
            line: 99,
            column: 7
          }
        }],
        line: 97
      },
      '10': {
        loc: {
          start: {
            line: 135,
            column: 6
          },
          end: {
            line: 140,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 135,
            column: 6
          },
          end: {
            line: 140,
            column: 7
          }
        }, {
          start: {
            line: 135,
            column: 6
          },
          end: {
            line: 140,
            column: 7
          }
        }],
        line: 135
      },
      '11': {
        loc: {
          start: {
            line: 158,
            column: 10
          },
          end: {
            line: 160,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 158,
            column: 10
          },
          end: {
            line: 160,
            column: 11
          }
        }, {
          start: {
            line: 158,
            column: 10
          },
          end: {
            line: 160,
            column: 11
          }
        }],
        line: 158
      },
      '12': {
        loc: {
          start: {
            line: 167,
            column: 10
          },
          end: {
            line: 169,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 167,
            column: 10
          },
          end: {
            line: 169,
            column: 11
          }
        }, {
          start: {
            line: 167,
            column: 10
          },
          end: {
            line: 169,
            column: 11
          }
        }],
        line: 167
      },
      '13': {
        loc: {
          start: {
            line: 170,
            column: 59
          },
          end: {
            line: 170,
            column: 92
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 170,
            column: 59
          },
          end: {
            line: 170,
            column: 75
          }
        }, {
          start: {
            line: 170,
            column: 79
          },
          end: {
            line: 170,
            column: 92
          }
        }],
        line: 170
      },
      '14': {
        loc: {
          start: {
            line: 175,
            column: 8
          },
          end: {
            line: 207,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 175,
            column: 8
          },
          end: {
            line: 207,
            column: 9
          }
        }, {
          start: {
            line: 175,
            column: 8
          },
          end: {
            line: 207,
            column: 9
          }
        }],
        line: 175
      },
      '15': {
        loc: {
          start: {
            line: 179,
            column: 14
          },
          end: {
            line: 181,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 179,
            column: 14
          },
          end: {
            line: 181,
            column: 15
          }
        }, {
          start: {
            line: 179,
            column: 14
          },
          end: {
            line: 181,
            column: 15
          }
        }],
        line: 179
      },
      '16': {
        loc: {
          start: {
            line: 182,
            column: 33
          },
          end: {
            line: 182,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 182,
            column: 33
          },
          end: {
            line: 182,
            column: 52
          }
        }, {
          start: {
            line: 182,
            column: 56
          },
          end: {
            line: 182,
            column: 58
          }
        }],
        line: 182
      },
      '17': {
        loc: {
          start: {
            line: 185,
            column: 16
          },
          end: {
            line: 200,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 185,
            column: 16
          },
          end: {
            line: 200,
            column: 17
          }
        }, {
          start: {
            line: 185,
            column: 16
          },
          end: {
            line: 200,
            column: 17
          }
        }],
        line: 185
      },
      '18': {
        loc: {
          start: {
            line: 194,
            column: 27
          },
          end: {
            line: 194,
            column: 84
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 194,
            column: 40
          },
          end: {
            line: 194,
            column: 76
          }
        }, {
          start: {
            line: 194,
            column: 80
          },
          end: {
            line: 194,
            column: 84
          }
        }],
        line: 194
      },
      '19': {
        loc: {
          start: {
            line: 214,
            column: 8
          },
          end: {
            line: 218,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 214,
            column: 8
          },
          end: {
            line: 218,
            column: 9
          }
        }, {
          start: {
            line: 214,
            column: 8
          },
          end: {
            line: 218,
            column: 9
          }
        }],
        line: 214
      },
      '20': {
        loc: {
          start: {
            line: 220,
            column: 6
          },
          end: {
            line: 222,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 220,
            column: 6
          },
          end: {
            line: 222,
            column: 7
          }
        }, {
          start: {
            line: 220,
            column: 6
          },
          end: {
            line: 222,
            column: 7
          }
        }],
        line: 220
      },
      '21': {
        loc: {
          start: {
            line: 225,
            column: 6
          },
          end: {
            line: 237,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 225,
            column: 6
          },
          end: {
            line: 237,
            column: 7
          }
        }, {
          start: {
            line: 225,
            column: 6
          },
          end: {
            line: 237,
            column: 7
          }
        }],
        line: 225
      },
      '22': {
        loc: {
          start: {
            line: 242,
            column: 6
          },
          end: {
            line: 244,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 242,
            column: 6
          },
          end: {
            line: 244,
            column: 7
          }
        }, {
          start: {
            line: 242,
            column: 6
          },
          end: {
            line: 244,
            column: 7
          }
        }],
        line: 242
      },
      '23': {
        loc: {
          start: {
            line: 248,
            column: 6
          },
          end: {
            line: 250,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 248,
            column: 6
          },
          end: {
            line: 250,
            column: 7
          }
        }, {
          start: {
            line: 248,
            column: 6
          },
          end: {
            line: 250,
            column: 7
          }
        }],
        line: 248
      },
      '24': {
        loc: {
          start: {
            line: 256,
            column: 10
          },
          end: {
            line: 261,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 256,
            column: 10
          },
          end: {
            line: 261,
            column: 11
          }
        }, {
          start: {
            line: 256,
            column: 10
          },
          end: {
            line: 261,
            column: 11
          }
        }],
        line: 256
      },
      '25': {
        loc: {
          start: {
            line: 270,
            column: 13
          },
          end: {
            line: 270,
            column: 70
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 270,
            column: 26
          },
          end: {
            line: 270,
            column: 62
          }
        }, {
          start: {
            line: 270,
            column: 66
          },
          end: {
            line: 270,
            column: 70
          }
        }],
        line: 270
      },
      '26': {
        loc: {
          start: {
            line: 275,
            column: 19
          },
          end: {
            line: 275,
            column: 34
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 275,
            column: 19
          },
          end: {
            line: 275,
            column: 28
          }
        }, {
          start: {
            line: 275,
            column: 32
          },
          end: {
            line: 275,
            column: 34
          }
        }],
        line: 275
      },
      '27': {
        loc: {
          start: {
            line: 276,
            column: 16
          },
          end: {
            line: 276,
            column: 28
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 276,
            column: 16
          },
          end: {
            line: 276,
            column: 22
          }
        }, {
          start: {
            line: 276,
            column: 26
          },
          end: {
            line: 276,
            column: 28
          }
        }],
        line: 276
      },
      '28': {
        loc: {
          start: {
            line: 279,
            column: 16
          },
          end: {
            line: 279,
            column: 28
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 279,
            column: 16
          },
          end: {
            line: 279,
            column: 22
          }
        }, {
          start: {
            line: 279,
            column: 26
          },
          end: {
            line: 279,
            column: 28
          }
        }],
        line: 279
      },
      '29': {
        loc: {
          start: {
            line: 279,
            column: 41
          },
          end: {
            line: 279,
            column: 53
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 279,
            column: 41
          },
          end: {
            line: 279,
            column: 47
          }
        }, {
          start: {
            line: 279,
            column: 51
          },
          end: {
            line: 279,
            column: 53
          }
        }],
        line: 279
      },
      '30': {
        loc: {
          start: {
            line: 288,
            column: 6
          },
          end: {
            line: 292,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 288,
            column: 6
          },
          end: {
            line: 292,
            column: 7
          }
        }, {
          start: {
            line: 288,
            column: 6
          },
          end: {
            line: 292,
            column: 7
          }
        }],
        line: 288
      },
      '31': {
        loc: {
          start: {
            line: 298,
            column: 10
          },
          end: {
            line: 303,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 298,
            column: 10
          },
          end: {
            line: 303,
            column: 11
          }
        }, {
          start: {
            line: 298,
            column: 10
          },
          end: {
            line: 303,
            column: 11
          }
        }],
        line: 298
      },
      '32': {
        loc: {
          start: {
            line: 311,
            column: 13
          },
          end: {
            line: 311,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 311,
            column: 26
          },
          end: {
            line: 311,
            column: 55
          }
        }, {
          start: {
            line: 311,
            column: 59
          },
          end: {
            line: 311,
            column: 63
          }
        }],
        line: 311
      },
      '33': {
        loc: {
          start: {
            line: 314,
            column: 19
          },
          end: {
            line: 314,
            column: 34
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 314,
            column: 19
          },
          end: {
            line: 314,
            column: 28
          }
        }, {
          start: {
            line: 314,
            column: 32
          },
          end: {
            line: 314,
            column: 34
          }
        }],
        line: 314
      },
      '34': {
        loc: {
          start: {
            line: 315,
            column: 16
          },
          end: {
            line: 315,
            column: 28
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 315,
            column: 16
          },
          end: {
            line: 315,
            column: 22
          }
        }, {
          start: {
            line: 315,
            column: 26
          },
          end: {
            line: 315,
            column: 28
          }
        }],
        line: 315
      },
      '35': {
        loc: {
          start: {
            line: 318,
            column: 16
          },
          end: {
            line: 318,
            column: 28
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 318,
            column: 16
          },
          end: {
            line: 318,
            column: 22
          }
        }, {
          start: {
            line: 318,
            column: 26
          },
          end: {
            line: 318,
            column: 28
          }
        }],
        line: 318
      },
      '36': {
        loc: {
          start: {
            line: 318,
            column: 41
          },
          end: {
            line: 318,
            column: 53
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 318,
            column: 41
          },
          end: {
            line: 318,
            column: 47
          }
        }, {
          start: {
            line: 318,
            column: 51
          },
          end: {
            line: 318,
            column: 53
          }
        }],
        line: 318
      },
      '37': {
        loc: {
          start: {
            line: 322,
            column: 6
          },
          end: {
            line: 333,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 322,
            column: 6
          },
          end: {
            line: 333,
            column: 7
          }
        }, {
          start: {
            line: 322,
            column: 6
          },
          end: {
            line: 333,
            column: 7
          }
        }],
        line: 322
      },
      '38': {
        loc: {
          start: {
            line: 322,
            column: 11
          },
          end: {
            line: 322,
            column: 31
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 322,
            column: 11
          },
          end: {
            line: 322,
            column: 25
          }
        }, {
          start: {
            line: 322,
            column: 29
          },
          end: {
            line: 322,
            column: 31
          }
        }],
        line: 322
      },
      '39': {
        loc: {
          start: {
            line: 338,
            column: 6
          },
          end: {
            line: 342,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 338,
            column: 6
          },
          end: {
            line: 342,
            column: 7
          }
        }, {
          start: {
            line: 338,
            column: 6
          },
          end: {
            line: 342,
            column: 7
          }
        }],
        line: 338
      },
      '40': {
        loc: {
          start: {
            line: 351,
            column: 11
          },
          end: {
            line: 351,
            column: 57
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 351,
            column: 31
          },
          end: {
            line: 351,
            column: 53
          }
        }, {
          start: {
            line: 351,
            column: 56
          },
          end: {
            line: 351,
            column: 57
          }
        }],
        line: 351
      },
      '41': {
        loc: {
          start: {
            line: 356,
            column: 12
          },
          end: {
            line: 358,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 356,
            column: 12
          },
          end: {
            line: 358,
            column: 13
          }
        }, {
          start: {
            line: 356,
            column: 12
          },
          end: {
            line: 358,
            column: 13
          }
        }],
        line: 356
      },
      '42': {
        loc: {
          start: {
            line: 365,
            column: 12
          },
          end: {
            line: 369,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 365,
            column: 12
          },
          end: {
            line: 369,
            column: 13
          }
        }, {
          start: {
            line: 365,
            column: 12
          },
          end: {
            line: 369,
            column: 13
          }
        }],
        line: 365
      },
      '43': {
        loc: {
          start: {
            line: 377,
            column: 6
          },
          end: {
            line: 425,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 377,
            column: 6
          },
          end: {
            line: 425,
            column: 7
          }
        }, {
          start: {
            line: 377,
            column: 6
          },
          end: {
            line: 425,
            column: 7
          }
        }],
        line: 377
      },
      '44': {
        loc: {
          start: {
            line: 378,
            column: 8
          },
          end: {
            line: 393,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 378,
            column: 8
          },
          end: {
            line: 393,
            column: 9
          }
        }, {
          start: {
            line: 378,
            column: 8
          },
          end: {
            line: 393,
            column: 9
          }
        }],
        line: 378
      },
      '45': {
        loc: {
          start: {
            line: 379,
            column: 10
          },
          end: {
            line: 381,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 379,
            column: 10
          },
          end: {
            line: 381,
            column: 11
          }
        }, {
          start: {
            line: 379,
            column: 10
          },
          end: {
            line: 381,
            column: 11
          }
        }],
        line: 379
      },
      '46': {
        loc: {
          start: {
            line: 382,
            column: 10
          },
          end: {
            line: 384,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 382,
            column: 10
          },
          end: {
            line: 384,
            column: 11
          }
        }, {
          start: {
            line: 382,
            column: 10
          },
          end: {
            line: 384,
            column: 11
          }
        }],
        line: 382
      },
      '47': {
        loc: {
          start: {
            line: 385,
            column: 10
          },
          end: {
            line: 387,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 385,
            column: 10
          },
          end: {
            line: 387,
            column: 11
          }
        }, {
          start: {
            line: 385,
            column: 10
          },
          end: {
            line: 387,
            column: 11
          }
        }],
        line: 385
      },
      '48': {
        loc: {
          start: {
            line: 388,
            column: 10
          },
          end: {
            line: 390,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 388,
            column: 10
          },
          end: {
            line: 390,
            column: 11
          }
        }, {
          start: {
            line: 388,
            column: 10
          },
          end: {
            line: 390,
            column: 11
          }
        }],
        line: 388
      },
      '49': {
        loc: {
          start: {
            line: 398,
            column: 8
          },
          end: {
            line: 407,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 398,
            column: 8
          },
          end: {
            line: 407,
            column: 9
          }
        }, {
          start: {
            line: 398,
            column: 8
          },
          end: {
            line: 407,
            column: 9
          }
        }],
        line: 398
      },
      '50': {
        loc: {
          start: {
            line: 402,
            column: 12
          },
          end: {
            line: 405,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 402,
            column: 12
          },
          end: {
            line: 405,
            column: 13
          }
        }, {
          start: {
            line: 402,
            column: 12
          },
          end: {
            line: 405,
            column: 13
          }
        }],
        line: 402
      },
      '51': {
        loc: {
          start: {
            line: 410,
            column: 10
          },
          end: {
            line: 412,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 410,
            column: 10
          },
          end: {
            line: 412,
            column: 11
          }
        }, {
          start: {
            line: 410,
            column: 10
          },
          end: {
            line: 412,
            column: 11
          }
        }],
        line: 410
      },
      '52': {
        loc: {
          start: {
            line: 415,
            column: 8
          },
          end: {
            line: 417,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 415,
            column: 8
          },
          end: {
            line: 417,
            column: 9
          }
        }, {
          start: {
            line: 415,
            column: 8
          },
          end: {
            line: 417,
            column: 9
          }
        }],
        line: 415
      },
      '53': {
        loc: {
          start: {
            line: 433,
            column: 8
          },
          end: {
            line: 435,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 433,
            column: 8
          },
          end: {
            line: 435,
            column: 9
          }
        }, {
          start: {
            line: 433,
            column: 8
          },
          end: {
            line: 435,
            column: 9
          }
        }],
        line: 433
      },
      '54': {
        loc: {
          start: {
            line: 436,
            column: 8
          },
          end: {
            line: 440,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 436,
            column: 8
          },
          end: {
            line: 440,
            column: 9
          }
        }, {
          start: {
            line: 436,
            column: 8
          },
          end: {
            line: 440,
            column: 9
          }
        }],
        line: 436
      },
      '55': {
        loc: {
          start: {
            line: 443,
            column: 6
          },
          end: {
            line: 445,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 443,
            column: 6
          },
          end: {
            line: 445,
            column: 7
          }
        }, {
          start: {
            line: 443,
            column: 6
          },
          end: {
            line: 445,
            column: 7
          }
        }],
        line: 443
      },
      '56': {
        loc: {
          start: {
            line: 450,
            column: 6
          },
          end: {
            line: 452,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 450,
            column: 6
          },
          end: {
            line: 452,
            column: 7
          }
        }, {
          start: {
            line: 450,
            column: 6
          },
          end: {
            line: 452,
            column: 7
          }
        }],
        line: 450
      },
      '57': {
        loc: {
          start: {
            line: 450,
            column: 10
          },
          end: {
            line: 450,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 450,
            column: 10
          },
          end: {
            line: 450,
            column: 31
          }
        }, {
          start: {
            line: 450,
            column: 35
          },
          end: {
            line: 450,
            column: 52
          }
        }],
        line: 450
      },
      '58': {
        loc: {
          start: {
            line: 468,
            column: 6
          },
          end: {
            line: 472,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 468,
            column: 6
          },
          end: {
            line: 472,
            column: 7
          }
        }, {
          start: {
            line: 468,
            column: 6
          },
          end: {
            line: 472,
            column: 7
          }
        }],
        line: 468
      },
      '59': {
        loc: {
          start: {
            line: 473,
            column: 6
          },
          end: {
            line: 477,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 473,
            column: 6
          },
          end: {
            line: 477,
            column: 7
          }
        }, {
          start: {
            line: 473,
            column: 6
          },
          end: {
            line: 477,
            column: 7
          }
        }],
        line: 473
      },
      '60': {
        loc: {
          start: {
            line: 483,
            column: 10
          },
          end: {
            line: 486,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 483,
            column: 10
          },
          end: {
            line: 486,
            column: 11
          }
        }, {
          start: {
            line: 483,
            column: 10
          },
          end: {
            line: 486,
            column: 11
          }
        }],
        line: 483
      },
      '61': {
        loc: {
          start: {
            line: 483,
            column: 14
          },
          end: {
            line: 483,
            column: 54
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 483,
            column: 14
          },
          end: {
            line: 483,
            column: 31
          }
        }, {
          start: {
            line: 483,
            column: 35
          },
          end: {
            line: 483,
            column: 54
          }
        }],
        line: 483
      },
      '62': {
        loc: {
          start: {
            line: 495,
            column: 10
          },
          end: {
            line: 497,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 495,
            column: 10
          },
          end: {
            line: 497,
            column: 11
          }
        }, {
          start: {
            line: 495,
            column: 10
          },
          end: {
            line: 497,
            column: 11
          }
        }],
        line: 495
      },
      '63': {
        loc: {
          start: {
            line: 496,
            column: 23
          },
          end: {
            line: 496,
            column: 56
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 496,
            column: 41
          },
          end: {
            line: 496,
            column: 49
          }
        }, {
          start: {
            line: 496,
            column: 52
          },
          end: {
            line: 496,
            column: 56
          }
        }],
        line: 496
      },
      '64': {
        loc: {
          start: {
            line: 501,
            column: 10
          },
          end: {
            line: 503,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 501,
            column: 10
          },
          end: {
            line: 503,
            column: 11
          }
        }, {
          start: {
            line: 501,
            column: 10
          },
          end: {
            line: 503,
            column: 11
          }
        }],
        line: 501
      },
      '65': {
        loc: {
          start: {
            line: 571,
            column: 8
          },
          end: {
            line: 573,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 571,
            column: 8
          },
          end: {
            line: 573,
            column: 9
          }
        }, {
          start: {
            line: 571,
            column: 8
          },
          end: {
            line: 573,
            column: 9
          }
        }],
        line: 571
      },
      '66': {
        loc: {
          start: {
            line: 583,
            column: 17
          },
          end: {
            line: 583,
            column: 134
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 583,
            column: 56
          },
          end: {
            line: 583,
            column: 127
          }
        }, {
          start: {
            line: 583,
            column: 130
          },
          end: {
            line: 583,
            column: 134
          }
        }],
        line: 583
      },
      '67': {
        loc: {
          start: {
            line: 584,
            column: 15
          },
          end: {
            line: 584,
            column: 158
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 584,
            column: 52
          },
          end: {
            line: 584,
            column: 153
          }
        }, {
          start: {
            line: 584,
            column: 156
          },
          end: {
            line: 584,
            column: 158
          }
        }],
        line: 584
      },
      '68': {
        loc: {
          start: {
            line: 595,
            column: 21
          },
          end: {
            line: 595,
            column: 45
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 595,
            column: 40
          },
          end: {
            line: 595,
            column: 41
          }
        }, {
          start: {
            line: 595,
            column: 44
          },
          end: {
            line: 595,
            column: 45
          }
        }],
        line: 595
      },
      '69': {
        loc: {
          start: {
            line: 595,
            column: 50
          },
          end: {
            line: 595,
            column: 78
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 595,
            column: 73
          },
          end: {
            line: 595,
            column: 74
          }
        }, {
          start: {
            line: 595,
            column: 77
          },
          end: {
            line: 595,
            column: 78
          }
        }],
        line: 595
      },
      '70': {
        loc: {
          start: {
            line: 596,
            column: 6
          },
          end: {
            line: 612,
            column: 7
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 597,
            column: 8
          },
          end: {
            line: 599,
            column: 16
          }
        }, {
          start: {
            line: 600,
            column: 8
          },
          end: {
            line: 602,
            column: 16
          }
        }, {
          start: {
            line: 603,
            column: 8
          },
          end: {
            line: 605,
            column: 16
          }
        }, {
          start: {
            line: 606,
            column: 8
          },
          end: {
            line: 608,
            column: 16
          }
        }, {
          start: {
            line: 609,
            column: 8
          },
          end: {
            line: 611,
            column: 16
          }
        }],
        line: 596
      },
      '71': {
        loc: {
          start: {
            line: 616,
            column: 6
          },
          end: {
            line: 618,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 616,
            column: 6
          },
          end: {
            line: 618,
            column: 7
          }
        }, {
          start: {
            line: 616,
            column: 6
          },
          end: {
            line: 618,
            column: 7
          }
        }],
        line: 616
      },
      '72': {
        loc: {
          start: {
            line: 632,
            column: 6
          },
          end: {
            line: 634,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 632,
            column: 6
          },
          end: {
            line: 634,
            column: 7
          }
        }, {
          start: {
            line: 632,
            column: 6
          },
          end: {
            line: 634,
            column: 7
          }
        }],
        line: 632
      },
      '73': {
        loc: {
          start: {
            line: 635,
            column: 6
          },
          end: {
            line: 639,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 635,
            column: 6
          },
          end: {
            line: 639,
            column: 7
          }
        }, {
          start: {
            line: 635,
            column: 6
          },
          end: {
            line: 639,
            column: 7
          }
        }],
        line: 635
      },
      '74': {
        loc: {
          start: {
            line: 647,
            column: 6
          },
          end: {
            line: 666,
            column: 7
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 648,
            column: 8
          },
          end: {
            line: 650,
            column: 16
          }
        }, {
          start: {
            line: 651,
            column: 8
          },
          end: {
            line: 653,
            column: 16
          }
        }, {
          start: {
            line: 654,
            column: 8
          },
          end: {
            line: 656,
            column: 16
          }
        }, {
          start: {
            line: 657,
            column: 8
          },
          end: {
            line: 659,
            column: 16
          }
        }, {
          start: {
            line: 660,
            column: 8
          },
          end: {
            line: 662,
            column: 16
          }
        }, {
          start: {
            line: 663,
            column: 8
          },
          end: {
            line: 665,
            column: 16
          }
        }],
        line: 647
      },
      '75': {
        loc: {
          start: {
            line: 677,
            column: 31
          },
          end: {
            line: 677,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 677,
            column: 31
          },
          end: {
            line: 677,
            column: 43
          }
        }, {
          start: {
            line: 677,
            column: 47
          },
          end: {
            line: 677,
            column: 58
          }
        }],
        line: 677
      },
      '76': {
        loc: {
          start: {
            line: 679,
            column: 8
          },
          end: {
            line: 681,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 679,
            column: 8
          },
          end: {
            line: 681,
            column: 9
          }
        }, {
          start: {
            line: 679,
            column: 8
          },
          end: {
            line: 681,
            column: 9
          }
        }],
        line: 679
      },
      '77': {
        loc: {
          start: {
            line: 680,
            column: 28
          },
          end: {
            line: 680,
            column: 97
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 680,
            column: 46
          },
          end: {
            line: 680,
            column: 92
          }
        }, {
          start: {
            line: 680,
            column: 95
          },
          end: {
            line: 680,
            column: 97
          }
        }],
        line: 680
      },
      '78': {
        loc: {
          start: {
            line: 682,
            column: 8
          },
          end: {
            line: 684,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 682,
            column: 8
          },
          end: {
            line: 684,
            column: 9
          }
        }, {
          start: {
            line: 682,
            column: 8
          },
          end: {
            line: 684,
            column: 9
          }
        }],
        line: 682
      },
      '79': {
        loc: {
          start: {
            line: 683,
            column: 28
          },
          end: {
            line: 683,
            column: 85
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 683,
            column: 46
          },
          end: {
            line: 683,
            column: 80
          }
        }, {
          start: {
            line: 683,
            column: 83
          },
          end: {
            line: 683,
            column: 85
          }
        }],
        line: 683
      },
      '80': {
        loc: {
          start: {
            line: 686,
            column: 8
          },
          end: {
            line: 688,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 686,
            column: 8
          },
          end: {
            line: 688,
            column: 9
          }
        }, {
          start: {
            line: 686,
            column: 8
          },
          end: {
            line: 688,
            column: 9
          }
        }],
        line: 686
      },
      '81': {
        loc: {
          start: {
            line: 687,
            column: 244
          },
          end: {
            line: 687,
            column: 265
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 687,
            column: 244
          },
          end: {
            line: 687,
            column: 259
          }
        }, {
          start: {
            line: 687,
            column: 263
          },
          end: {
            line: 687,
            column: 265
          }
        }],
        line: 687
      },
      '82': {
        loc: {
          start: {
            line: 690,
            column: 57
          },
          end: {
            line: 690,
            column: 78
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 690,
            column: 57
          },
          end: {
            line: 690,
            column: 72
          }
        }, {
          start: {
            line: 690,
            column: 76
          },
          end: {
            line: 690,
            column: 78
          }
        }],
        line: 690
      },
      '83': {
        loc: {
          start: {
            line: 726,
            column: 10
          },
          end: {
            line: 799,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 726,
            column: 10
          },
          end: {
            line: 799,
            column: 11
          }
        }, {
          start: {
            line: 726,
            column: 10
          },
          end: {
            line: 799,
            column: 11
          }
        }],
        line: 726
      },
      '84': {
        loc: {
          start: {
            line: 729,
            column: 16
          },
          end: {
            line: 756,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 729,
            column: 16
          },
          end: {
            line: 756,
            column: 17
          }
        }, {
          start: {
            line: 729,
            column: 16
          },
          end: {
            line: 756,
            column: 17
          }
        }],
        line: 729
      },
      '85': {
        loc: {
          start: {
            line: 730,
            column: 30
          },
          end: {
            line: 730,
            column: 123
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 730,
            column: 68
          },
          end: {
            line: 730,
            column: 103
          }
        }, {
          start: {
            line: 730,
            column: 106
          },
          end: {
            line: 730,
            column: 123
          }
        }],
        line: 730
      },
      '86': {
        loc: {
          start: {
            line: 731,
            column: 18
          },
          end: {
            line: 733,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 731,
            column: 18
          },
          end: {
            line: 733,
            column: 19
          }
        }, {
          start: {
            line: 731,
            column: 18
          },
          end: {
            line: 733,
            column: 19
          }
        }],
        line: 731
      },
      '87': {
        loc: {
          start: {
            line: 735,
            column: 20
          },
          end: {
            line: 737,
            column: 21
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 735,
            column: 20
          },
          end: {
            line: 737,
            column: 21
          }
        }, {
          start: {
            line: 735,
            column: 20
          },
          end: {
            line: 737,
            column: 21
          }
        }],
        line: 735
      },
      '88': {
        loc: {
          start: {
            line: 738,
            column: 20
          },
          end: {
            line: 740,
            column: 21
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 738,
            column: 20
          },
          end: {
            line: 740,
            column: 21
          }
        }, {
          start: {
            line: 738,
            column: 20
          },
          end: {
            line: 740,
            column: 21
          }
        }],
        line: 738
      },
      '89': {
        loc: {
          start: {
            line: 749,
            column: 22
          },
          end: {
            line: 753,
            column: 23
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 749,
            column: 22
          },
          end: {
            line: 753,
            column: 23
          }
        }, {
          start: {
            line: 749,
            column: 22
          },
          end: {
            line: 753,
            column: 23
          }
        }],
        line: 749
      },
      '90': {
        loc: {
          start: {
            line: 757,
            column: 16
          },
          end: {
            line: 791,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 757,
            column: 16
          },
          end: {
            line: 791,
            column: 17
          }
        }, {
          start: {
            line: 757,
            column: 16
          },
          end: {
            line: 791,
            column: 17
          }
        }],
        line: 757
      },
      '91': {
        loc: {
          start: {
            line: 758,
            column: 32
          },
          end: {
            line: 758,
            column: 125
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 758,
            column: 70
          },
          end: {
            line: 758,
            column: 105
          }
        }, {
          start: {
            line: 758,
            column: 108
          },
          end: {
            line: 758,
            column: 125
          }
        }],
        line: 758
      },
      '92': {
        loc: {
          start: {
            line: 759,
            column: 18
          },
          end: {
            line: 761,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 759,
            column: 18
          },
          end: {
            line: 761,
            column: 19
          }
        }, {
          start: {
            line: 759,
            column: 18
          },
          end: {
            line: 761,
            column: 19
          }
        }],
        line: 759
      },
      '93': {
        loc: {
          start: {
            line: 762,
            column: 18
          },
          end: {
            line: 764,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 762,
            column: 18
          },
          end: {
            line: 764,
            column: 19
          }
        }, {
          start: {
            line: 762,
            column: 18
          },
          end: {
            line: 764,
            column: 19
          }
        }],
        line: 762
      },
      '94': {
        loc: {
          start: {
            line: 784,
            column: 22
          },
          end: {
            line: 788,
            column: 23
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 784,
            column: 22
          },
          end: {
            line: 788,
            column: 23
          }
        }, {
          start: {
            line: 784,
            column: 22
          },
          end: {
            line: 788,
            column: 23
          }
        }],
        line: 784
      },
      '95': {
        loc: {
          start: {
            line: 802,
            column: 8
          },
          end: {
            line: 805,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 802,
            column: 8
          },
          end: {
            line: 805,
            column: 9
          }
        }, {
          start: {
            line: 802,
            column: 8
          },
          end: {
            line: 805,
            column: 9
          }
        }],
        line: 802
      },
      '96': {
        loc: {
          start: {
            line: 810,
            column: 6
          },
          end: {
            line: 813,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 810,
            column: 6
          },
          end: {
            line: 813,
            column: 7
          }
        }, {
          start: {
            line: 810,
            column: 6
          },
          end: {
            line: 813,
            column: 7
          }
        }],
        line: 810
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0,
      '193': 0,
      '194': 0,
      '195': 0,
      '196': 0,
      '197': 0,
      '198': 0,
      '199': 0,
      '200': 0,
      '201': 0,
      '202': 0,
      '203': 0,
      '204': 0,
      '205': 0,
      '206': 0,
      '207': 0,
      '208': 0,
      '209': 0,
      '210': 0,
      '211': 0,
      '212': 0,
      '213': 0,
      '214': 0,
      '215': 0,
      '216': 0,
      '217': 0,
      '218': 0,
      '219': 0,
      '220': 0,
      '221': 0,
      '222': 0,
      '223': 0,
      '224': 0,
      '225': 0,
      '226': 0,
      '227': 0,
      '228': 0,
      '229': 0,
      '230': 0,
      '231': 0,
      '232': 0,
      '233': 0,
      '234': 0,
      '235': 0,
      '236': 0,
      '237': 0,
      '238': 0,
      '239': 0,
      '240': 0,
      '241': 0,
      '242': 0,
      '243': 0,
      '244': 0,
      '245': 0,
      '246': 0,
      '247': 0,
      '248': 0,
      '249': 0,
      '250': 0,
      '251': 0,
      '252': 0,
      '253': 0,
      '254': 0,
      '255': 0,
      '256': 0,
      '257': 0,
      '258': 0,
      '259': 0,
      '260': 0,
      '261': 0,
      '262': 0,
      '263': 0,
      '264': 0,
      '265': 0,
      '266': 0,
      '267': 0,
      '268': 0,
      '269': 0,
      '270': 0,
      '271': 0,
      '272': 0,
      '273': 0,
      '274': 0,
      '275': 0,
      '276': 0,
      '277': 0,
      '278': 0,
      '279': 0,
      '280': 0,
      '281': 0,
      '282': 0,
      '283': 0,
      '284': 0,
      '285': 0,
      '286': 0,
      '287': 0,
      '288': 0,
      '289': 0,
      '290': 0,
      '291': 0,
      '292': 0,
      '293': 0,
      '294': 0,
      '295': 0,
      '296': 0,
      '297': 0,
      '298': 0,
      '299': 0,
      '300': 0,
      '301': 0,
      '302': 0,
      '303': 0,
      '304': 0,
      '305': 0,
      '306': 0,
      '307': 0,
      '308': 0,
      '309': 0,
      '310': 0,
      '311': 0,
      '312': 0,
      '313': 0,
      '314': 0,
      '315': 0,
      '316': 0,
      '317': 0,
      '318': 0,
      '319': 0,
      '320': 0,
      '321': 0,
      '322': 0,
      '323': 0,
      '324': 0,
      '325': 0,
      '326': 0,
      '327': 0,
      '328': 0,
      '329': 0,
      '330': 0,
      '331': 0,
      '332': 0,
      '333': 0,
      '334': 0,
      '335': 0,
      '336': 0,
      '337': 0,
      '338': 0,
      '339': 0,
      '340': 0,
      '341': 0,
      '342': 0,
      '343': 0,
      '344': 0,
      '345': 0,
      '346': 0,
      '347': 0,
      '348': 0,
      '349': 0,
      '350': 0,
      '351': 0,
      '352': 0,
      '353': 0,
      '354': 0,
      '355': 0,
      '356': 0,
      '357': 0,
      '358': 0,
      '359': 0,
      '360': 0,
      '361': 0,
      '362': 0,
      '363': 0,
      '364': 0,
      '365': 0,
      '366': 0,
      '367': 0,
      '368': 0,
      '369': 0,
      '370': 0,
      '371': 0,
      '372': 0,
      '373': 0,
      '374': 0,
      '375': 0,
      '376': 0,
      '377': 0,
      '378': 0,
      '379': 0,
      '380': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0],
      '50': [0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0],
      '54': [0, 0],
      '55': [0, 0],
      '56': [0, 0],
      '57': [0, 0],
      '58': [0, 0],
      '59': [0, 0],
      '60': [0, 0],
      '61': [0, 0],
      '62': [0, 0],
      '63': [0, 0],
      '64': [0, 0],
      '65': [0, 0],
      '66': [0, 0],
      '67': [0, 0],
      '68': [0, 0],
      '69': [0, 0],
      '70': [0, 0, 0, 0, 0],
      '71': [0, 0],
      '72': [0, 0],
      '73': [0, 0],
      '74': [0, 0, 0, 0, 0, 0],
      '75': [0, 0],
      '76': [0, 0],
      '77': [0, 0],
      '78': [0, 0],
      '79': [0, 0],
      '80': [0, 0],
      '81': [0, 0],
      '82': [0, 0],
      '83': [0, 0],
      '84': [0, 0],
      '85': [0, 0],
      '86': [0, 0],
      '87': [0, 0],
      '88': [0, 0],
      '89': [0, 0],
      '90': [0, 0],
      '91': [0, 0],
      '92': [0, 0],
      '93': [0, 0],
      '94': [0, 0],
      '95': [0, 0],
      '96': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1hg27fiokd.s[0]++;

angular.module('app').controller('dashboardSigCtrl', function ($scope, $CRUDService, $deltaGeoLevel, $rootScope, $http, $translate, $log, $deltadate, $filter, $deltahttp, $otherHttpService) {
  cov_1hg27fiokd.f[0]++;

  var PATH = (cov_1hg27fiokd.s[1]++, 'Indicateurs');
  var PATH_DEC = (cov_1hg27fiokd.s[2]++, 'DecoupageIndic');
  var PATH_FORMULAR = (cov_1hg27fiokd.s[3]++, 'Formular');
  var defaultLegend = (cov_1hg27fiokd.s[4]++, [{ c: '#ffffcc', l: '', v: 0 }, { c: '#c2e699', l: '', v: 10 }, { c: '#78c679', l: '', v: 40 }, { c: '#31a354', l: '', v: 60 }, { c: '#006837', l: '', v: 80 }]);
  cov_1hg27fiokd.s[5]++;
  $scope.mapDashboard = {
    externalLayers: {},
    display: {
      data: false,
      formatting: false,
      legend: false,
      layer: false
    },
    delta: {
      options: {
        delta: true,
        display__: true
      }
    },
    error_tab: {},
    customLegend: {},
    loadingItem: 0,
    listIndicators: [],
    selectIndicator: null,
    selectLevel: null,
    requestPanel: false,
    legendMap: defaultLegend,
    listYear: [],
    period_type: '1',
    myMap: null,
    opacity: 1,
    toggleLayer: function toggleLayer(layer) {
      cov_1hg27fiokd.s[6]++;

      if (layer.options.fixe) {
        cov_1hg27fiokd.b[0][0]++;
        cov_1hg27fiokd.s[7]++;

        if (layer.options.display__) {
          cov_1hg27fiokd.b[1][0]++;
          cov_1hg27fiokd.s[8]++;

          layer.addTo($scope.mapDashboard.fixedLayerGroup);
        } else {
          cov_1hg27fiokd.b[1][1]++;
          cov_1hg27fiokd.s[9]++;

          this.fixedLayerGroup.removeLayer(layer);
        }
      } else {
          cov_1hg27fiokd.b[0][1]++;
          cov_1hg27fiokd.s[10]++;
          if (layer.options.delta) {
            cov_1hg27fiokd.b[2][0]++;
            cov_1hg27fiokd.s[11]++;

            if (layer.options.display__) {
              cov_1hg27fiokd.b[3][0]++;
              cov_1hg27fiokd.s[12]++;

              $scope.mapDashboard.deltaLayerGroup.addTo(this.myMap);
            } else {
              cov_1hg27fiokd.b[3][1]++;
              cov_1hg27fiokd.s[13]++;

              this.myMap.removeLayer(this.deltaLayerGroup);
            }
          } else {
              cov_1hg27fiokd.b[2][1]++;
              cov_1hg27fiokd.s[14]++;
              if (layer.options.display__) {
                cov_1hg27fiokd.b[4][0]++;
                cov_1hg27fiokd.s[15]++;

                layer.addTo(this.dynamicLayerGroup);
              } else {
                cov_1hg27fiokd.b[4][1]++;
                cov_1hg27fiokd.s[16]++;

                this.dynamicLayerGroup.removeLayer(layer);
              }
            }
        }
    },

    fixedLayerGroup: L.featureGroup([], {}),
    dynamicLayerGroup: L.featureGroup([], {}),
    deltaLayerGroup: L.featureGroup([], {}),
    flags: [],
    legend: {
      grades: [],
      labels: []
    },
    selectedLayers: [],
    getColor: function getColor(d) {
      cov_1hg27fiokd.s[17]++;

      for (var i = $scope.mapDashboard.legendMap.length - 1; i >= 0; i--) {
        cov_1hg27fiokd.s[18]++;

        if (d > $scope.mapDashboard.legendMap[i].v) {
          cov_1hg27fiokd.b[5][0]++;
          cov_1hg27fiokd.s[19]++;

          return $scope.mapDashboard.legendMap[i].c;
        } else {
          cov_1hg27fiokd.b[5][1]++;
        }
      }
      cov_1hg27fiokd.s[20]++;
      return $scope.mapDashboard.legendMap[0].c;
    },
    initializeMap: function initializeMap() {
      var newHeight = (cov_1hg27fiokd.s[21]++, $('body').height());
      cov_1hg27fiokd.s[22]++;
      $('#mapid').height(newHeight - 350);

      var flags = (cov_1hg27fiokd.s[23]++, angular.copy($rootScope.getCurrentProject().project.getFlag()));
      cov_1hg27fiokd.s[24]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = flags[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var flag = _step.value;

          var colors = (cov_1hg27fiokd.s[25]++, flag.color.split(','));
          var temp = (cov_1hg27fiokd.s[26]++, []);
          cov_1hg27fiokd.s[27]++;
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = colors[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var part = _step2.value;

              var l = (cov_1hg27fiokd.s[28]++, parseInt(part, 10).toString(16));
              cov_1hg27fiokd.s[29]++;
              temp.push((l.length == 1 ? (cov_1hg27fiokd.b[6][0]++, '0') : (cov_1hg27fiokd.b[6][1]++, '')) + l);
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          cov_1hg27fiokd.s[30]++;
          flag.color = '#' + temp.join('');
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_1hg27fiokd.s[31]++;
      if (flags[0]) {
        cov_1hg27fiokd.b[7][0]++;
        cov_1hg27fiokd.s[32]++;

        flags[0].max = 999999999999;
      } else {
        cov_1hg27fiokd.b[7][1]++;
      }
      cov_1hg27fiokd.s[33]++;
      if (flags.length > 0) {
        cov_1hg27fiokd.b[8][0]++;
        cov_1hg27fiokd.s[34]++;

        flags[flags.length - 1].min = -1;
      } else {
        cov_1hg27fiokd.b[8][1]++;
      }

      cov_1hg27fiokd.s[35]++;
      this.flags = flags;
      cov_1hg27fiokd.s[36]++;
      if (this.myMap) {
        cov_1hg27fiokd.b[9][0]++;
        cov_1hg27fiokd.s[37]++;

        this.myMap.remove();
      } else {
        cov_1hg27fiokd.b[9][1]++;
      }
      var grayscale = (cov_1hg27fiokd.s[38]++, L.tileLayer($scope.analyseMap.getMapUrl(4)));
      cov_1hg27fiokd.s[39]++;
      this.myMap = L.map('mapid', { fullscreenControl: {
          title: {
            false: $translate.instant('COMMON.FULLSCREEN'),
            true: $translate.instant('COMMON.EXIT_FULLSCREEN')
          }
        }, layers: [grayscale] }).setView([16.82, 11.3], 5);

      var base = (cov_1hg27fiokd.s[40]++, {});
      cov_1hg27fiokd.s[41]++;
      base[$translate.instant('COMMON.GRAYSCALE')] = L.tileLayer($scope.analyseMap.getMapUrl(1));
      cov_1hg27fiokd.s[42]++;
      base[$translate.instant('COMMON.STREETMAP')] = grayscale;
      cov_1hg27fiokd.s[43]++;
      base[$translate.instant('COMMON.SATELLITE')] = L.tileLayer($scope.analyseMap.getMapUrl(3));

      cov_1hg27fiokd.s[44]++;
      L.control.layers(base).addTo(this.myMap);

      cov_1hg27fiokd.s[45]++;
      $scope.analyseMap.initialise();

      cov_1hg27fiokd.s[46]++;
      this.background = L.tileLayer(this.getMapUrl()); // .addTo(this.myMap);

      cov_1hg27fiokd.s[47]++;
      this.dynamicLayerGroup.clearLayers();
      cov_1hg27fiokd.s[48]++;
      this.deltaLayerGroup.clearLayers();
      cov_1hg27fiokd.s[49]++;
      this.fixedLayerGroup.addTo(this.myMap);
      cov_1hg27fiokd.s[50]++;
      this.dynamicLayerGroup.addTo(this.myMap);
      cov_1hg27fiokd.s[51]++;
      this.deltaLayerGroup.addTo(this.myMap);
      cov_1hg27fiokd.s[52]++;
      this.foreround = L.tileLayer('').addTo(this.myMap);
      // this.background.setOpacity(4);
      /* if (this.layerGroup) {
        this.layerGroup.clearLayers();
        this.layerGroup = null;
      } */
      // this._getLayers();

      cov_1hg27fiokd.s[53]++;
      this.processIndicator();
    },
    _getLayers: function _getLayers() {
      cov_1hg27fiokd.s[54]++;

      if (!this.layerGroup) {
        cov_1hg27fiokd.b[10][0]++;
        cov_1hg27fiokd.s[55]++;

        this.layerGroup = L.layerGroup([], {
          attribution: '© <a href=\'https://www.mapbox.com/about/maps/\'>Mapbox</a> , © <a href=\'https://www.openstreetmap.org/copyright\'>OpenStreetMap</a>  <strong><a href=\'https://www.mapbox.com/map-feedback/\' target=\'_blank\'>Improve this map</a></strong>'
        });
        cov_1hg27fiokd.s[56]++;
        this.layerGroup.addTo(this.myMap);
      } else {
        cov_1hg27fiokd.b[10][1]++;
      }
      cov_1hg27fiokd.s[57]++;
      this.layerGroup.clearLayers();
      var mapstr = (cov_1hg27fiokd.s[58]++, 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmtzYiIsImEiOiJjaXQ5ZHlpM2gwMDJvMnNwaDAxZXFsbndrIn0.0W3tcj-HtlRSCJDcuxNRew');
      cov_1hg27fiokd.s[59]++;
      L.tileLayer(mapstr).addTo(this.layerGroup);
    },
    getInformation: function getInformation() {
      cov_1hg27fiokd.s[60]++;

      $otherHttpService.get(PATH, { get: 'all_indic_search' }, function (data) {
        cov_1hg27fiokd.f[1]++;
        cov_1hg27fiokd.s[61]++;

        $scope.mapDashboard.listIndicators = [{
          code: '',
          cu: '',
          dated: '2000-01-01',
          datef: '2100-01-01',
          fo: '2',
          id: 0,
          label: $translate.instant('COMMON.NONE'),
          ng: 0,
          period: '2'
        }].concat(data).map(function (value) {
          cov_1hg27fiokd.f[2]++;
          cov_1hg27fiokd.s[62]++;

          if (value.id > 0) {
            cov_1hg27fiokd.b[11][0]++;
            cov_1hg27fiokd.s[63]++;

            value.label = value.code + ': ' + value.label;
          } else {
            cov_1hg27fiokd.b[11][1]++;
          }
          cov_1hg27fiokd.s[64]++;
          value.dated = Date.newDate(value.dated);
          cov_1hg27fiokd.s[65]++;
          value.datef = Date.newDate(value.datef);
          cov_1hg27fiokd.s[66]++;
          return value;
        });

        cov_1hg27fiokd.s[67]++;
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = $scope.mapDashboard.listIndicators[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var indicator = _step3.value;
            cov_1hg27fiokd.s[68]++;

            if (indicator.id == 0) {
              cov_1hg27fiokd.b[12][0]++;
              cov_1hg27fiokd.s[69]++;

              continue;
            } else {
              cov_1hg27fiokd.b[12][1]++;
            }
            cov_1hg27fiokd.s[70]++;
            $scope.mapDashboard.customLegend[indicator.id] = (cov_1hg27fiokd.b[13][0]++, indicator.legend) || (cov_1hg27fiokd.b[13][1]++, defaultLegend);
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }

        var ids = (cov_1hg27fiokd.s[71]++, data.map(function (d_) {
          cov_1hg27fiokd.f[3]++;
          cov_1hg27fiokd.s[72]++;

          return d_.id;
        }).join(' , '));
        cov_1hg27fiokd.s[73]++;
        if (ids != '') {
          cov_1hg27fiokd.b[14][0]++;
          cov_1hg27fiokd.s[74]++;

          $otherHttpService.get('MapLayer', { get: 'all_by_id', ids: ids }, function (_data) {
            cov_1hg27fiokd.f[4]++;
            cov_1hg27fiokd.s[75]++;

            $scope.mapDashboard.selectedLayers = {};
            cov_1hg27fiokd.s[76]++;
            var _iteratorNormalCompletion4 = true;
            var _didIteratorError4 = false;
            var _iteratorError4 = undefined;

            try {
              for (var _iterator4 = $scope.mapDashboard.listIndicators[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                var indicator = _step4.value;
                cov_1hg27fiokd.s[77]++;

                if (indicator.id == 0) {
                  cov_1hg27fiokd.b[15][0]++;
                  cov_1hg27fiokd.s[78]++;

                  continue;
                } else {
                  cov_1hg27fiokd.b[15][1]++;
                }
                var layersList = (cov_1hg27fiokd.s[79]++, (cov_1hg27fiokd.b[16][0]++, _data[indicator.id]) || (cov_1hg27fiokd.b[16][1]++, []));
                cov_1hg27fiokd.s[80]++;
                $scope.mapDashboard.selectedLayers[indicator.id] = [];
                cov_1hg27fiokd.s[81]++;
                var _iteratorNormalCompletion5 = true;
                var _didIteratorError5 = false;
                var _iteratorError5 = undefined;

                try {
                  for (var _iterator5 = layersList[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                    var item = _step5.value;
                    cov_1hg27fiokd.s[82]++;

                    if (item.PERIODIC == 0) {
                      cov_1hg27fiokd.b[17][0]++;

                      var layer = (cov_1hg27fiokd.s[83]++, $scope.mapDashboard.getFixeLayer(item));
                      cov_1hg27fiokd.s[84]++;
                      $scope.mapDashboard.selectedLayers[indicator.id].push(layer);
                    } else {
                      cov_1hg27fiokd.b[17][1]++;
                      cov_1hg27fiokd.s[85]++;

                      $scope.mapDashboard.selectedLayers[indicator.id].push({
                        options: {
                          fixe: false,
                          id_: item.id,
                          label: item.LABEL,
                          pin: item.file ? (cov_1hg27fiokd.b[18][0]++, $deltahttp.getPinRespositoy(item.id)) : (cov_1hg27fiokd.b[18][1]++, null),
                          display__: true,
                          PROPERTY: item.PROPERTY,
                          DATA_LAYER: item.DATA_LAYER
                        }
                      });
                    }
                  }
                } catch (err) {
                  _didIteratorError5 = true;
                  _iteratorError5 = err;
                } finally {
                  try {
                    if (!_iteratorNormalCompletion5 && _iterator5.return) {
                      _iterator5.return();
                    }
                  } finally {
                    if (_didIteratorError5) {
                      throw _iteratorError5;
                    }
                  }
                }
              }
            } catch (err) {
              _didIteratorError4 = true;
              _iteratorError4 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion4 && _iterator4.return) {
                  _iterator4.return();
                }
              } finally {
                if (_didIteratorError4) {
                  throw _iteratorError4;
                }
              }
            }

            cov_1hg27fiokd.s[86]++;
            $scope.mapDashboard.selectIndicator = $scope.mapDashboard.listIndicators[0];
            cov_1hg27fiokd.s[87]++;
            $scope.mapDashboard.changeIndicator();
          });
        } else {
          cov_1hg27fiokd.b[14][1]++;
        }
      });
    },
    changeLevel: function changeLevel() {
      // NIVEAU_NG
      var find = (cov_1hg27fiokd.s[88]++, false);
      cov_1hg27fiokd.s[89]++;
      var _iteratorNormalCompletion6 = true;
      var _didIteratorError6 = false;
      var _iteratorError6 = undefined;

      try {
        for (var _iterator6 = $scope.mapDashboard.listgeo[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
          var item = _step6.value;
          cov_1hg27fiokd.s[90]++;

          if (item.NIVEAU_NG - 1 == $scope.mapDashboard.selectLevel.NIVEAU_NG) {
            cov_1hg27fiokd.b[19][0]++;
            cov_1hg27fiokd.s[91]++;

            $scope.mapDashboard.selectLevel = item;
            cov_1hg27fiokd.s[92]++;
            find = true;
            cov_1hg27fiokd.s[93]++;
            break;
          } else {
            cov_1hg27fiokd.b[19][1]++;
          }
        }
      } catch (err) {
        _didIteratorError6 = true;
        _iteratorError6 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion6 && _iterator6.return) {
            _iterator6.return();
          }
        } finally {
          if (_didIteratorError6) {
            throw _iteratorError6;
          }
        }
      }

      cov_1hg27fiokd.s[94]++;
      if (find) {
        cov_1hg27fiokd.b[20][0]++;
        cov_1hg27fiokd.s[95]++;

        this.processIndicator();
      } else {
        cov_1hg27fiokd.b[20][1]++;
      }
    },
    changeYear: function changeYear() {
      cov_1hg27fiokd.s[96]++;

      if (this.selectYear.id == 0) {
        cov_1hg27fiokd.b[21][0]++;
        cov_1hg27fiokd.s[97]++;

        this.listPeriod = [{
          begin: this.selectIndicator.datef,
          end: this.selectIndicator.datef,
          label: '_'
        }];
      } else {
        cov_1hg27fiokd.b[21][1]++;
        cov_1hg27fiokd.s[98]++;

        this.listPeriod = [/* {
                           begin: $deltadate.getBeginYear(this.selectYear.id),
                           end: $deltadate.getEndYear(this.selectYear.id),
                           label: $translate.instant('COMMON.ALL_E')
                           } */].concat($deltadate.getYearPeriod(this.selectYear.id, this.selectIndicator.period));
      }
      cov_1hg27fiokd.s[99]++;
      this.selectPeriod = this.listPeriod[0];
      cov_1hg27fiokd.s[100]++;
      this.changePeriod();
    },
    getPeriodicLayer: function getPeriodicLayer(item) {
      cov_1hg27fiokd.s[101]++;

      if (item.URL_.includes('arcgis')) {
        cov_1hg27fiokd.b[22][0]++;
        cov_1hg27fiokd.s[102]++;

        return this.getEsriLayer(item, false);
      } else {
        cov_1hg27fiokd.b[22][1]++;
      }
      cov_1hg27fiokd.s[103]++;
      return this.getGeoServerLayer(item, false);
    },
    getFixeLayer: function getFixeLayer(item) {
      cov_1hg27fiokd.s[104]++;

      if (item.URL_.includes('arcgis')) {
        cov_1hg27fiokd.b[23][0]++;
        cov_1hg27fiokd.s[105]++;

        return this.getEsriLayer(item, true);
      } else {
        cov_1hg27fiokd.b[23][1]++;
      }
      cov_1hg27fiokd.s[106]++;
      return this.getGeoServerLayer(item, true);
    },
    getEsriLayer: function getEsriLayer(item, fixe) {
      var result = (cov_1hg27fiokd.s[107]++, {
        pointToLayer: function pointToLayer(point, latlng) {
          cov_1hg27fiokd.s[108]++;

          if (item.file) {
            cov_1hg27fiokd.b[24][0]++;
            cov_1hg27fiokd.s[109]++;

            return new L.marker(latlng, { icon: L.icon({
                iconUrl: $deltahttp.getPinRespositoy(item.id),
                iconSize: [16, 16]
              }) });
          } else {
            cov_1hg27fiokd.b[24][1]++;
          }
          cov_1hg27fiokd.s[110]++;
          return new L.marker(latlng);
        },
        style: function style(a) {
          cov_1hg27fiokd.s[111]++;

          return { color: item.COLOR, weight: 2 };
        },

        fixe: fixe,
        id_: item.id,
        url_: item.URL_,
        pin: item.file ? (cov_1hg27fiokd.b[25][0]++, $deltahttp.getPinRespositoy(item.id)) : (cov_1hg27fiokd.b[25][1]++, null),
        label: item.LABEL,
        display__: true,
        PROPERTY: item.PROPERTY
      });
      var url = (cov_1hg27fiokd.s[112]++, ((cov_1hg27fiokd.b[26][0]++, item.URL_) || (cov_1hg27fiokd.b[26][1]++, '')).split('?'));
      cov_1hg27fiokd.s[113]++;
      url[1] = ((cov_1hg27fiokd.b[27][0]++, url[1]) || (cov_1hg27fiokd.b[27][1]++, '')).split('&');
      cov_1hg27fiokd.s[114]++;
      var _iteratorNormalCompletion7 = true;
      var _didIteratorError7 = false;
      var _iteratorError7 = undefined;

      try {
        for (var _iterator7 = url[1][Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
          var param = _step7.value;

          var key = (cov_1hg27fiokd.s[115]++, param.split('='));
          cov_1hg27fiokd.s[116]++;
          result[((cov_1hg27fiokd.b[28][0]++, key[0]) || (cov_1hg27fiokd.b[28][1]++, '')).trim()] = ((cov_1hg27fiokd.b[29][0]++, key[1]) || (cov_1hg27fiokd.b[29][1]++, '')).trim();
        }
      } catch (err) {
        _didIteratorError7 = true;
        _iteratorError7 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion7 && _iterator7.return) {
            _iterator7.return();
          }
        } finally {
          if (_didIteratorError7) {
            throw _iteratorError7;
          }
        }
      }

      cov_1hg27fiokd.s[117]++;
      result.url = url[0];
      cov_1hg27fiokd.s[118]++;
      result.where = '1=1';
      var layer = (cov_1hg27fiokd.s[119]++, L.esri.featureLayer(result));
      cov_1hg27fiokd.s[120]++;
      layer.options.loading = true;
      cov_1hg27fiokd.s[121]++;
      layer.on('load ', function (l) {
        cov_1hg27fiokd.f[5]++;
        cov_1hg27fiokd.s[122]++;

        l.target.options.loading = false;
      });
      cov_1hg27fiokd.s[123]++;
      if (item.DATA_LAYER == 1) {
        cov_1hg27fiokd.b[30][0]++;
        cov_1hg27fiokd.s[124]++;

        layer.bindPopup(function (layer) {
          cov_1hg27fiokd.f[6]++;
          cov_1hg27fiokd.s[125]++;

          return L.Util.template(item.PROPERTY, layer.feature.properties);
        });
      } else {
        cov_1hg27fiokd.b[30][1]++;
      }
      cov_1hg27fiokd.s[126]++;
      return layer;
    },
    getGeoServerLayer: function getGeoServerLayer(item, fixe) {
      var result = (cov_1hg27fiokd.s[127]++, {
        pointToLayer: function pointToLayer(point, latlng) {
          cov_1hg27fiokd.s[128]++;

          if (item.file) {
            cov_1hg27fiokd.b[31][0]++;
            cov_1hg27fiokd.s[129]++;

            return new L.marker(latlng, { icon: L.icon({
                iconUrl: $deltahttp.getPinRespositoy(item.id),
                iconSize: [16, 16]
              }) });
          } else {
            cov_1hg27fiokd.b[31][1]++;
          }
          cov_1hg27fiokd.s[130]++;
          return new L.marker(latlng);
        },

        fixe: fixe,
        id_: item.id,
        url_: item.URL_,
        label: item.LABEL,
        display__: true,
        pin: item.file ? (cov_1hg27fiokd.b[32][0]++, $deltahttp.getPinRespositoy()) : (cov_1hg27fiokd.b[32][1]++, null),
        PROPERTY: item.PROPERTY
      });
      var url = (cov_1hg27fiokd.s[131]++, ((cov_1hg27fiokd.b[33][0]++, item.URL_) || (cov_1hg27fiokd.b[33][1]++, '')).split('?'));
      cov_1hg27fiokd.s[132]++;
      url[1] = ((cov_1hg27fiokd.b[34][0]++, url[1]) || (cov_1hg27fiokd.b[34][1]++, '')).split('&');
      cov_1hg27fiokd.s[133]++;
      var _iteratorNormalCompletion8 = true;
      var _didIteratorError8 = false;
      var _iteratorError8 = undefined;

      try {
        for (var _iterator8 = url[1][Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
          var param = _step8.value;

          var key = (cov_1hg27fiokd.s[134]++, param.split('='));
          cov_1hg27fiokd.s[135]++;
          result[((cov_1hg27fiokd.b[35][0]++, key[0]) || (cov_1hg27fiokd.b[35][1]++, '')).trim()] = ((cov_1hg27fiokd.b[36][0]++, key[1]) || (cov_1hg27fiokd.b[36][1]++, '')).trim();
        }
      } catch (err) {
        _didIteratorError8 = true;
        _iteratorError8 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion8 && _iterator8.return) {
            _iterator8.return();
          }
        } finally {
          if (_didIteratorError8) {
            throw _iteratorError8;
          }
        }
      }

      cov_1hg27fiokd.s[136]++;
      result.url = url[0];
      var layer = (cov_1hg27fiokd.s[137]++, null);
      cov_1hg27fiokd.s[138]++;
      if (((cov_1hg27fiokd.b[38][0]++, result.service) || (cov_1hg27fiokd.b[38][1]++, '')).toLocaleUpperCase() == 'WMS') {
        cov_1hg27fiokd.b[37][0]++;
        cov_1hg27fiokd.s[139]++;

        layer = L.tileLayer.wms(item.URL_, {
          layers: '',
          format: 'image/png',
          transparent: true,
          version: '1.1.0',
          attribution: 'myattribution'
        });
      } else {
        cov_1hg27fiokd.b[37][1]++;
        cov_1hg27fiokd.s[140]++;

        result.tms = true;
        cov_1hg27fiokd.s[141]++;
        layer = L.tileLayer(result.url, result);
      }
      cov_1hg27fiokd.s[142]++;
      layer.options.loading = true;
      cov_1hg27fiokd.s[143]++;
      layer.on('load ', function (l) {
        cov_1hg27fiokd.f[7]++;
        cov_1hg27fiokd.s[144]++;

        l.target.options.loading = false;
      });
      cov_1hg27fiokd.s[145]++;
      if (item.DATA_LAYER == 1) {
        cov_1hg27fiokd.b[39][0]++;
        cov_1hg27fiokd.s[146]++;

        layer.bindPopup(function (layer) {
          cov_1hg27fiokd.f[8]++;
          cov_1hg27fiokd.s[147]++;

          return L.Util.template(item.PROPERTY, layer.feature.properties);
        });
      } else {
        cov_1hg27fiokd.b[39][1]++;
      }
      cov_1hg27fiokd.s[148]++;
      return layer;
    },
    changePeriod: function changePeriod() {
      cov_1hg27fiokd.s[149]++;

      this.dynamicLayerGroup.clearLayers();
      cov_1hg27fiokd.s[150]++;
      this.processIndicator();
      cov_1hg27fiokd.s[151]++;
      $otherHttpService.get('MapLayer', {
        get: 'all__', valid: 0,
        id_indic: this.selectIndicator.id,
        y: this.selectPeriod ? (cov_1hg27fiokd.b[40][0]++, this.selectPeriod.year) : (cov_1hg27fiokd.b[40][1]++, 0),
        p: this.selectPeriod.index
      }, function (data) {
        cov_1hg27fiokd.f[9]++;
        cov_1hg27fiokd.s[152]++;
        var _iteratorNormalCompletion9 = true;
        var _didIteratorError9 = false;
        var _iteratorError9 = undefined;

        try {
          var _loop = function _loop() {
            var layer = _step9.value;

            var baseLayer = (cov_1hg27fiokd.s[153]++, $scope.mapDashboard.selectedLayers[$scope.mapDashboard.selectIndicator.id].filter(function (value) {
              cov_1hg27fiokd.f[10]++;
              cov_1hg27fiokd.s[154]++;

              if (!value.options) {
                cov_1hg27fiokd.b[41][0]++;
                cov_1hg27fiokd.s[155]++;

                return false;
              } else {
                cov_1hg27fiokd.b[41][1]++;
              }
              cov_1hg27fiokd.s[156]++;
              return layer.id == value.options.id_;
            }));
            var item = (cov_1hg27fiokd.s[157]++, Object.assign(baseLayer[0].options, layer));
            cov_1hg27fiokd.s[158]++;
            item.URL_ = layer.url;
            var myLayer = (cov_1hg27fiokd.s[159]++, $scope.mapDashboard.getPeriodicLayer(item));
            cov_1hg27fiokd.s[160]++;
            for (var i = $scope.mapDashboard.selectedLayers[$scope.mapDashboard.selectIndicator.id].length - 1; i >= 0; i--) {
              cov_1hg27fiokd.s[161]++;

              if ($scope.mapDashboard.selectedLayers[$scope.mapDashboard.selectIndicator.id][i].options.id_ === myLayer.options.id_) {
                cov_1hg27fiokd.b[42][0]++;
                cov_1hg27fiokd.s[162]++;

                myLayer.options.label = $scope.mapDashboard.selectedLayers[$scope.mapDashboard.selectIndicator.id][i].options.label;
                cov_1hg27fiokd.s[163]++;
                $scope.mapDashboard.selectedLayers[$scope.mapDashboard.selectIndicator.id][i] = myLayer;
                cov_1hg27fiokd.s[164]++;
                break;
              } else {
                cov_1hg27fiokd.b[42][1]++;
              }
            }

            cov_1hg27fiokd.s[165]++;
            myLayer.addTo($scope.mapDashboard.dynamicLayerGroup);
          };

          for (var _iterator9 = data[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
            _loop();
          }
        } catch (err) {
          _didIteratorError9 = true;
          _iteratorError9 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion9 && _iterator9.return) {
              _iterator9.return();
            }
          } finally {
            if (_didIteratorError9) {
              throw _iteratorError9;
            }
          }
        }
      });
    },
    changeIndicator: function changeIndicator() {
      cov_1hg27fiokd.s[166]++;

      if ($scope.mapDashboard.selectIndicator) {
        cov_1hg27fiokd.b[43][0]++;
        cov_1hg27fiokd.s[167]++;

        if ($scope.mapDashboard.selectIndicator.id == 0) {
          cov_1hg27fiokd.b[44][0]++;
          cov_1hg27fiokd.s[168]++;

          if (this.fixedLayerGroup) {
            cov_1hg27fiokd.b[45][0]++;
            cov_1hg27fiokd.s[169]++;

            this.fixedLayerGroup.clearLayers();
          } else {
            cov_1hg27fiokd.b[45][1]++;
          }
          cov_1hg27fiokd.s[170]++;
          if (this.deltaLayerGroup) {
            cov_1hg27fiokd.b[46][0]++;
            cov_1hg27fiokd.s[171]++;

            this.deltaLayerGroup.clearLayers();
          } else {
            cov_1hg27fiokd.b[46][1]++;
          }
          cov_1hg27fiokd.s[172]++;
          if (this.dynamicLayerGroup) {
            cov_1hg27fiokd.b[47][0]++;
            cov_1hg27fiokd.s[173]++;

            this.dynamicLayerGroup.clearLayers();
          } else {
            cov_1hg27fiokd.b[47][1]++;
          }
          cov_1hg27fiokd.s[174]++;
          if ($scope.mapDashboard.legendControl) {
            cov_1hg27fiokd.b[48][0]++;
            cov_1hg27fiokd.s[175]++;

            $scope.mapDashboard.legendControl.remove();
          } else {
            cov_1hg27fiokd.b[48][1]++;
          }
          cov_1hg27fiokd.s[176]++;
          $scope.mapDashboard.period_type = 1;
          cov_1hg27fiokd.s[177]++;
          return;
        } else {
          cov_1hg27fiokd.b[44][1]++;
        }
        cov_1hg27fiokd.s[178]++;
        this.fixedLayerGroup.clearLayers();
        cov_1hg27fiokd.s[179]++;
        $scope.mapDashboard.listgeo = $scope.listData_GeoLevels_bksb.filter(function (value) {
          cov_1hg27fiokd.f[11]++;
          cov_1hg27fiokd.s[180]++;

          return value.id <= $scope.mapDashboard.selectIndicator.ng;
        });
        cov_1hg27fiokd.s[181]++;
        if ($scope.mapDashboard.selectLevel) {
          cov_1hg27fiokd.b[49][0]++;

          var id = (cov_1hg27fiokd.s[182]++, $scope.mapDashboard.selectLevel.id);
          cov_1hg27fiokd.s[183]++;
          $scope.mapDashboard.selectLevel = null;
          cov_1hg27fiokd.s[184]++;
          var _iteratorNormalCompletion10 = true;
          var _didIteratorError10 = false;
          var _iteratorError10 = undefined;

          try {
            for (var _iterator10 = $scope.mapDashboard.listgeo[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
              var level = _step10.value;
              cov_1hg27fiokd.s[185]++;

              if (level.id == id) {
                cov_1hg27fiokd.b[50][0]++;
                cov_1hg27fiokd.s[186]++;

                $scope.mapDashboard.selectLevel = level;
                cov_1hg27fiokd.s[187]++;
                break;
              } else {
                cov_1hg27fiokd.b[50][1]++;
              }
            }
          } catch (err) {
            _didIteratorError10 = true;
            _iteratorError10 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion10 && _iterator10.return) {
                _iterator10.return();
              }
            } finally {
              if (_didIteratorError10) {
                throw _iteratorError10;
              }
            }
          }
        } else {
          cov_1hg27fiokd.b[49][1]++;
        }

        cov_1hg27fiokd.s[188]++;
        var _iteratorNormalCompletion11 = true;
        var _didIteratorError11 = false;
        var _iteratorError11 = undefined;

        try {
          for (var _iterator11 = $scope.mapDashboard.selectedLayers[this.selectIndicator.id][Symbol.iterator](), _step11; !(_iteratorNormalCompletion11 = (_step11 = _iterator11.next()).done); _iteratorNormalCompletion11 = true) {
            var _layer = _step11.value;
            cov_1hg27fiokd.s[189]++;

            if (_layer.addTo) {
              cov_1hg27fiokd.b[51][0]++;
              cov_1hg27fiokd.s[190]++;

              _layer.addTo(this.fixedLayerGroup);
            } else {
              cov_1hg27fiokd.b[51][1]++;
            }
          }
        } catch (err) {
          _didIteratorError11 = true;
          _iteratorError11 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion11 && _iterator11.return) {
              _iterator11.return();
            }
          } finally {
            if (_didIteratorError11) {
              throw _iteratorError11;
            }
          }
        }

        cov_1hg27fiokd.s[191]++;
        if ($scope.mapDashboard.selectLevel == null) {
          cov_1hg27fiokd.b[52][0]++;
          cov_1hg27fiokd.s[192]++;

          $scope.mapDashboard.selectLevel = $scope.mapDashboard.listgeo[$scope.mapDashboard.listgeo.length - 1];
        } else {
          cov_1hg27fiokd.b[52][1]++;
        }
        cov_1hg27fiokd.s[193]++;
        this.listYear = [/* {
                         id: 0,
                         name: $translate.instant('COMMON.ALL_E'),
                         period: 1
                         } */].concat($deltadate.getYearBetween(this.selectIndicator.dated, this.selectIndicator.datef));
        cov_1hg27fiokd.s[194]++;
        this.selectYear = this.listYear[0];
        cov_1hg27fiokd.s[195]++;
        this.changeYear();
      } else {
        cov_1hg27fiokd.b[43][1]++;
      }
    },
    changeLegend: function changeLegend() {
      cov_1hg27fiokd.s[196]++;

      this.error_tab = {};
      cov_1hg27fiokd.s[197]++;
      this.error_overlap = false;
      var index = (cov_1hg27fiokd.s[198]++, 0);
      cov_1hg27fiokd.s[199]++;
      var _iteratorNormalCompletion12 = true;
      var _didIteratorError12 = false;
      var _iteratorError12 = undefined;

      try {
        for (var _iterator12 = this.customLegend[this.selectIndicator.id][Symbol.iterator](), _step12; !(_iteratorNormalCompletion12 = (_step12 = _iterator12.next()).done); _iteratorNormalCompletion12 = true) {
          var _i = _step12.value;
          cov_1hg27fiokd.s[200]++;

          this.error_tab[index] = false;
          cov_1hg27fiokd.s[201]++;
          if (index + 1 == this.customLegend[this.selectIndicator.id].length) {
            cov_1hg27fiokd.b[53][0]++;
            cov_1hg27fiokd.s[202]++;

            break;
          } else {
            cov_1hg27fiokd.b[53][1]++;
          }
          cov_1hg27fiokd.s[203]++;
          if (_i.v > this.customLegend[this.selectIndicator.id][index + 1].v) {
            cov_1hg27fiokd.b[54][0]++;
            cov_1hg27fiokd.s[204]++;

            this.error_overlap = true;
            cov_1hg27fiokd.s[205]++;
            this.error_tab[index] = true;
            // return;
          } else {
            cov_1hg27fiokd.b[54][1]++;
          }
          cov_1hg27fiokd.s[206]++;
          index++;
        }
      } catch (err) {
        _didIteratorError12 = true;
        _iteratorError12 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion12 && _iterator12.return) {
            _iterator12.return();
          }
        } finally {
          if (_didIteratorError12) {
            throw _iteratorError12;
          }
        }
      }

      cov_1hg27fiokd.s[207]++;
      if (this.error_overlap) {
        cov_1hg27fiokd.b[55][0]++;
        cov_1hg27fiokd.s[208]++;

        return;
      } else {
        cov_1hg27fiokd.b[55][1]++;
      }
      cov_1hg27fiokd.s[209]++;
      this.changeIndicator();
    },
    processIndicator: function processIndicator() {
      cov_1hg27fiokd.s[210]++;

      if ((cov_1hg27fiokd.b[57][0]++, !this.selectIndicator) || (cov_1hg27fiokd.b[57][1]++, !this.selectLevel)) {
        cov_1hg27fiokd.b[56][0]++;
        cov_1hg27fiokd.s[211]++;

        return;
      } else {
        cov_1hg27fiokd.b[56][1]++;
      }
      cov_1hg27fiokd.s[212]++;
      this.legendMap = $scope.mapDashboard.customLegend[$scope.mapDashboard.selectIndicator.id];
      cov_1hg27fiokd.s[213]++;
      this.loadingItem = 0;
      // this.layerGroup.clearLayers();
      /* if (!this.layerGroup) {
        return;
      } */
      cov_1hg27fiokd.s[214]++;
      this.deltaLayerGroup.clearLayers();
      // const mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmtzYiIsImEiOiJjaXQ5ZHlpM2gwMDJvMnNwaDAxZXFsbndrIn0.0W3tcj-HtlRSCJDcuxNRew';
      // L.tileLayer(mapstr).addTo(this.layerGroup);
      var params = (cov_1hg27fiokd.s[215]++, {
        CODE_NG: this.selectLevel.id,
        ID_INDIC: this.selectIndicator.id,
        get: 'suivi_n_g',
        valid: 0
      });
      cov_1hg27fiokd.s[216]++;
      if ($scope.mapDashboard.period_type == 2) {
        cov_1hg27fiokd.b[58][0]++;
        cov_1hg27fiokd.s[217]++;

        params.map = true;
        cov_1hg27fiokd.s[218]++;
        params.begin = $deltadate.format($deltadate.getBeginYear(this.selectYear.id), 'YYYY-MM-DD');
        cov_1hg27fiokd.s[219]++;
        params.end = $deltadate.format($deltadate.getEndYear(this.selectYear.id), 'YYYY-MM-DD');
      } else {
        cov_1hg27fiokd.b[58][1]++;
      }
      cov_1hg27fiokd.s[220]++;
      if ($scope.mapDashboard.period_type == 3) {
        cov_1hg27fiokd.b[59][0]++;
        cov_1hg27fiokd.s[221]++;

        params.map = true;
        cov_1hg27fiokd.s[222]++;
        params.begin = $deltadate.format(this.selectPeriod.begin, 'YYYY-MM-DD');
        cov_1hg27fiokd.s[223]++;
        params.end = $deltadate.format(this.selectPeriod.end, 'YYYY-MM-DD');
      } else {
        cov_1hg27fiokd.b[59][1]++;
      }

      cov_1hg27fiokd.s[224]++;
      $scope.limitMap = [{ lat: 90, lon: 180 }, { lat: -90, lon: -180 }];
      cov_1hg27fiokd.s[225]++;
      $otherHttpService.get(PATH_DEC, params, function (data) {
        cov_1hg27fiokd.f[12]++;

        var maxValue = (cov_1hg27fiokd.s[226]++, 0);
        cov_1hg27fiokd.s[227]++;
        var _iteratorNormalCompletion13 = true;
        var _didIteratorError13 = false;
        var _iteratorError13 = undefined;

        try {
          for (var _iterator13 = data[Symbol.iterator](), _step13; !(_iteratorNormalCompletion13 = (_step13 = _iterator13.next()).done); _iteratorNormalCompletion13 = true) {
            var item = _step13.value;
            cov_1hg27fiokd.s[228]++;

            if ((cov_1hg27fiokd.b[61][0]++, item.osm_id == -1) || (cov_1hg27fiokd.b[61][1]++, item.osm_id == null)) {
              cov_1hg27fiokd.b[60][0]++;
              cov_1hg27fiokd.s[229]++;

              item.vr = null;
              cov_1hg27fiokd.s[230]++;
              continue;
            } else {
              cov_1hg27fiokd.b[60][1]++;
            }
            var rate = (cov_1hg27fiokd.s[231]++, null);
            cov_1hg27fiokd.s[232]++;
            try {
              cov_1hg27fiokd.s[233]++;

              rate = eval(item.vr);
            } catch (e) {
              cov_1hg27fiokd.s[234]++;

              item.vr = null;
              cov_1hg27fiokd.s[235]++;
              continue;
            }
            cov_1hg27fiokd.s[236]++;
            item.vr = rate;
            cov_1hg27fiokd.s[237]++;
            if (angular.isNumber(rate)) {
              cov_1hg27fiokd.b[62][0]++;
              cov_1hg27fiokd.s[238]++;

              maxValue = maxValue > rate ? (cov_1hg27fiokd.b[63][0]++, maxValue) : (cov_1hg27fiokd.b[63][1]++, rate);
            } else {
              cov_1hg27fiokd.b[62][1]++;
            }
          }
        } catch (err) {
          _didIteratorError13 = true;
          _iteratorError13 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion13 && _iterator13.return) {
              _iterator13.return();
            }
          } finally {
            if (_didIteratorError13) {
              throw _iteratorError13;
            }
          }
        }

        cov_1hg27fiokd.s[239]++;
        var _iteratorNormalCompletion14 = true;
        var _didIteratorError14 = false;
        var _iteratorError14 = undefined;

        try {
          for (var _iterator14 = data[Symbol.iterator](), _step14; !(_iteratorNormalCompletion14 = (_step14 = _iterator14.next()).done); _iteratorNormalCompletion14 = true) {
            var _item = _step14.value;
            cov_1hg27fiokd.s[240]++;

            if (!_item.vr) {
              cov_1hg27fiokd.b[64][0]++;
              cov_1hg27fiokd.s[241]++;

              continue;
            } else {
              cov_1hg27fiokd.b[64][1]++;
            }
            var _rate = (cov_1hg27fiokd.s[242]++, _item.vr);
            cov_1hg27fiokd.s[243]++;
            $scope.mapDashboard.loadingItem++;
            /*          const display_html = '<h3>' + item.l + '</h3>' +
                        '<h5>' + $filter('number')(rate, 2) + ' ' + $scope.mapDashboard.selectIndicator.cu + '</h5>';
            */

            var display_html = (cov_1hg27fiokd.s[244]++, '<div style="overflow: auto;">' + '<div class="text-center"><h5 style="font-size: 14px">' + $scope.mapDashboard.selectIndicator.label + '</h5></div>' + '<div class="text-center m-b m-t">' + '<span style="font-size: 14px"><i class="fa fa-map-marker"></i> ' + _item.l + '</span>' + '</div>' + '<div style="display: flex; color: #71cf37">' + '<span style="font-size: 14px"><i class="fa fa-edit"></i> ' + $filter('number')(_rate, $scope.mapDashboard.selectIndicator.fo) + ' ' + $scope.mapDashboard.selectIndicator.cu + '</span>' + '</div>' + '</div>');
            /* $http({
               method: 'GET',
               url: 'https://nominatim.openstreetmap.org/reverse?format=json&polygon_geojson=1&osm_id=' + item.osm_id + '&osm_type=' + item.osm_type
             }).then(response => {
               const x = parseFloat(response.data.lat);
               const y = parseFloat(response.data.lon);
               $scope.limitMap[0].lat = $scope.limitMap[0].lat > x ? x : $scope.limitMap[0].lat;
               $scope.limitMap[0].lon = $scope.limitMap[0].lon > y ? y : $scope.limitMap[0].lon;
               $scope.limitMap[1].lat = $scope.limitMap[1].lat < x ? x : $scope.limitMap[1].lat;
               $scope.limitMap[1].lon = $scope.limitMap[1].lon < y ? y : $scope.limitMap[1].lon;
               const center = {
                 lat: ($scope.limitMap[1].lat + $scope.limitMap[0].lat) / 2,
                 lon: ($scope.limitMap[1].lon + $scope.limitMap[0].lon) / 2
               };
                this.myMap.setView([center.lat || 0, center.lon || 0]);
                $scope.mapDashboard.loadingItem--;
               L.geoJSON({
                 type: 'Feature',
                 properties: {label: display_html, value: rate},
                 geometry: response.data.geojson
               }, {
                 //  $scope.mapDashboard.changeLevel()
                 pointToLayer(point, latlng) {
                   return L.circle(latlng, {radius: 15000});
                 },
                 onEachFeature(feature, layer) {
                   layer.on({
                     dblclick() {
                       $scope.mapDashboard.changeLevel();
                     }
                   });
                 },
                 style(feature) {
                   return {
                     fillColor: $scope.mapDashboard.getColor(feature.properties.value),
                     weight: 2,
                     opacity: $scope.mapDashboard.opacity,
                     color: 'white',
                     dashArray: '3',
                     fillOpacity: Math.min($scope.mapDashboard.opacity, 0.5)
                   };
                 }
               }).bindPopup(layer => {
                 return layer.feature.properties.label;
               }).addTo($scope.mapDashboard.deltaLayerGroup);
             }, () => {
               $scope.mapDashboard.loadingItem--;
             }); */
          }
        } catch (err) {
          _didIteratorError14 = true;
          _iteratorError14 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion14 && _iterator14.return) {
              _iterator14.return();
            }
          } finally {
            if (_didIteratorError14) {
              throw _iteratorError14;
            }
          }
        }

        cov_1hg27fiokd.s[245]++;
        if ($scope.mapDashboard.legendControl) {
          cov_1hg27fiokd.b[65][0]++;
          cov_1hg27fiokd.s[246]++;

          $scope.mapDashboard.legendControl.remove();
        } else {
          cov_1hg27fiokd.b[65][1]++;
        }

        cov_1hg27fiokd.s[247]++;
        $scope.mapDashboard.legendControl = L.control({ position: 'bottomright' });
        cov_1hg27fiokd.s[248]++;
        $scope.mapDashboard.legendControl.onAdd = function (map) {
          cov_1hg27fiokd.f[13]++;

          var div = (cov_1hg27fiokd.s[249]++, L.DomUtil.create('div', 'info legend'));
          // loop through our density intervals and generate a label with a colored square for each interval
          cov_1hg27fiokd.s[250]++;
          for (var i = 0; i < $scope.mapDashboard.legendMap.length; i++) {
            cov_1hg27fiokd.s[251]++;

            div.innerHTML += '<i style="background:' + $scope.mapDashboard.legendMap[i].c + '"></i> ' + $filter('number')($scope.mapDashboard.legendMap[i].v) + ($scope.mapDashboard.legendMap[i + 1] ? (cov_1hg27fiokd.b[66][0]++, ' &ndash; ' + $filter('number')($scope.mapDashboard.legendMap[i + 1].v)) : (cov_1hg27fiokd.b[66][1]++, ' +')) + ($scope.mapDashboard.legendMap[i].l ? (cov_1hg27fiokd.b[67][0]++, ' <smal style="font-weight: normal; font-size: 10px" >(' + $scope.mapDashboard.legendMap[i].l + ')</smal>') : (cov_1hg27fiokd.b[67][1]++, '')) + '<br>';
          }
          cov_1hg27fiokd.s[252]++;
          return div;
        };
        cov_1hg27fiokd.s[253]++;
        $scope.mapDashboard.legendControl.addTo($scope.mapDashboard.myMap);
      });
    },
    getMapUrl: function getMapUrl() {
      var mapstr = (cov_1hg27fiokd.s[254]++, 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}');
      // L.tileLayer(mapstr).addTo(this.layerGroup);
      // let mapstr = '';
      var value = (cov_1hg27fiokd.s[255]++, (this.displayCity ? (cov_1hg27fiokd.b[68][0]++, 1) : (cov_1hg27fiokd.b[68][1]++, 0)) + (this.displaySatelite ? (cov_1hg27fiokd.b[69][0]++, 2) : (cov_1hg27fiokd.b[69][1]++, 0)));
      cov_1hg27fiokd.s[256]++;
      switch (value) {
        case 0:
          cov_1hg27fiokd.b[70][0]++;
          cov_1hg27fiokd.s[257]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}';
          cov_1hg27fiokd.s[258]++;
          break;
        case 1:
          cov_1hg27fiokd.b[70][1]++;
          cov_1hg27fiokd.s[259]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvs9jqk60fr91cscf0w98zth/tiles/256/{z}/{x}/{y}@2x';
          cov_1hg27fiokd.s[260]++;
          break;
        case 2:
          cov_1hg27fiokd.b[70][2]++;
          cov_1hg27fiokd.s[261]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbh28818ic1cmqcfaz1is2/tiles/256/{z}/{x}/{y}@2x';
          cov_1hg27fiokd.s[262]++;
          break;
        case 3:
          cov_1hg27fiokd.b[70][3]++;
          cov_1hg27fiokd.s[263]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbjkcx0mkz1cp3ot9cdfnk/tiles/256/{z}/{x}/{y}@2x';
          cov_1hg27fiokd.s[264]++;
          break;
        default:
          cov_1hg27fiokd.b[70][4]++;
          cov_1hg27fiokd.s[265]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cj8x1v3iv05pz2rrtc9ps1tui/tiles/256/{z}/{x}/{y}';
          cov_1hg27fiokd.s[266]++;
          break;
      }
      cov_1hg27fiokd.s[267]++;
      return mapstr + '?access_token=pk.eyJ1IjoiYmtzYiIsImEiOiJjaXQ5ZHlpM2gwMDJvMnNwaDAxZXFsbndrIn0.0W3tcj-HtlRSCJDcuxNRew';
    },
    changeBackground: function changeBackground() {
      cov_1hg27fiokd.s[268]++;

      if ($scope.mapDashboard.myMap) {
        cov_1hg27fiokd.b[71][0]++;
        cov_1hg27fiokd.s[269]++;

        this.background.setUrl(this.getMapUrl());
      } else {
        cov_1hg27fiokd.b[71][1]++;
      }
    },
    changeOpacity: function changeOpacity() {
      cov_1hg27fiokd.s[270]++;

      this.background.setOpacity(this.opacity);
      var style = (cov_1hg27fiokd.s[271]++, { opacity: this.opacity, fillOpacity: Math.min(this.opacity, 0.5) });
      cov_1hg27fiokd.s[272]++;
      this.fixedLayerGroup.setStyle(style);
      cov_1hg27fiokd.s[273]++;
      this.dynamicLayerGroup.setStyle(style);
      cov_1hg27fiokd.s[274]++;
      this.deltaLayerGroup.setStyle(style);
    }
  };
  cov_1hg27fiokd.s[275]++;
  $scope.analyseMap = {
    requestFields: {},
    toggleLayer: function toggleLayer(request) {
      cov_1hg27fiokd.s[276]++;

      if (!$scope.analyseMap.requestLayers[request.id]) {
        cov_1hg27fiokd.b[72][0]++;
        cov_1hg27fiokd.s[277]++;

        return;
      } else {
        cov_1hg27fiokd.b[72][1]++;
      }
      cov_1hg27fiokd.s[278]++;
      if (request.display__) {
        cov_1hg27fiokd.b[73][0]++;
        cov_1hg27fiokd.s[279]++;

        $scope.analyseMap.requestLayers[request.id].addTo($scope.layerGroupAnalyse);
      } else {
        cov_1hg27fiokd.b[73][1]++;
        cov_1hg27fiokd.s[280]++;

        $scope.layerGroupAnalyse.removeLayer($scope.analyseMap.requestLayers[request.id]);
      }
      // $scope.layerGroup
    },
    getMapUrl: function getMapUrl(value) {
      var mapstr = (cov_1hg27fiokd.s[281]++, 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}');
      // L.tileLayer(mapstr).addTo(this.layerGroup);
      // let mapstr = '';
      // const value = ($scope.displayCity ? 1 : 0) + ($scope.displaySatelite ? 2 : 0);
      cov_1hg27fiokd.s[282]++;
      switch (value) {
        case 0:
          cov_1hg27fiokd.b[74][0]++;
          cov_1hg27fiokd.s[283]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}';
          cov_1hg27fiokd.s[284]++;
          break;
        case 1:
          cov_1hg27fiokd.b[74][1]++;
          cov_1hg27fiokd.s[285]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvs9jqk60fr91cscf0w98zth/tiles/256/{z}/{x}/{y}@2x';
          cov_1hg27fiokd.s[286]++;
          break;
        case 2:
          cov_1hg27fiokd.b[74][2]++;
          cov_1hg27fiokd.s[287]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbh28818ic1cmqcfaz1is2/tiles/256/{z}/{x}/{y}@2x';
          cov_1hg27fiokd.s[288]++;
          break;
        case 3:
          cov_1hg27fiokd.b[74][3]++;
          cov_1hg27fiokd.s[289]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbjkcx0mkz1cp3ot9cdfnk/tiles/256/{z}/{x}/{y}@2x';
          cov_1hg27fiokd.s[290]++;
          break;
        case 4:
          cov_1hg27fiokd.b[74][4]++;
          cov_1hg27fiokd.s[291]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/ck0wcr1og05gz1cq7ik9y0ct5/tiles/256/{z}/{x}/{y}@2x';
          cov_1hg27fiokd.s[292]++;
          break;
        default:
          cov_1hg27fiokd.b[74][5]++;
          cov_1hg27fiokd.s[293]++;

          mapstr = 'https://api.mapbox.com/styles/v1/bksb/cj8x1v3iv05pz2rrtc9ps1tui/tiles/256/{z}/{x}/{y}';
          cov_1hg27fiokd.s[294]++;
          break;
      }
      cov_1hg27fiokd.s[295]++;
      return mapstr + '?access_token=pk.eyJ1IjoiYmtzYiIsImEiOiJjaXQ5ZHlpM2gwMDJvMnNwaDAxZXFsbndrIn0.0W3tcj-HtlRSCJDcuxNRew';
    },
    getFilterFields: function getFilterFields(id) {
      cov_1hg27fiokd.s[296]++;

      $CRUDService.getAll(PATH_FORMULAR, { get: 'all_filter_fields', id: id }, function (data) {
        cov_1hg27fiokd.f[14]++;
        cov_1hg27fiokd.s[297]++;

        $scope.analyseMap.requestFields[id] = data;
      });
    },
    formatData: function formatData(item, layer, requestFields) {
      var maxHeight = (cov_1hg27fiokd.s[298]++, $('#mapid').height() - 100);

      var label = (cov_1hg27fiokd.s[299]++, angular.copy((cov_1hg27fiokd.b[75][0]++, layer.format) || (cov_1hg27fiokd.b[75][1]++, $scope.mask)));
      cov_1hg27fiokd.s[300]++;
      var _iteratorNormalCompletion15 = true;
      var _didIteratorError15 = false;
      var _iteratorError15 = undefined;

      try {
        for (var _iterator15 = requestFields[Symbol.iterator](), _step15; !(_iteratorNormalCompletion15 = (_step15 = _iterator15.next()).done); _iteratorNormalCompletion15 = true) {
          var item_s = _step15.value;
          cov_1hg27fiokd.s[301]++;

          if (item_s.t == 3) {
            cov_1hg27fiokd.b[76][0]++;
            cov_1hg27fiokd.s[302]++;

            item[item_s.id] = item[item_s.id] ? (cov_1hg27fiokd.b[77][0]++, $filter('date')(item[item_s.id], 'mediumDate')) : (cov_1hg27fiokd.b[77][1]++, '');
          } else {
            cov_1hg27fiokd.b[76][1]++;
          }
          cov_1hg27fiokd.s[303]++;
          if (item_s.t == 4) {
            cov_1hg27fiokd.b[78][0]++;
            cov_1hg27fiokd.s[304]++;

            item[item_s.id] = item[item_s.id] ? (cov_1hg27fiokd.b[79][0]++, $filter('number')(item[item_s.id])) : (cov_1hg27fiokd.b[79][1]++, '');
          } else {
            cov_1hg27fiokd.b[78][1]++;
          }

          cov_1hg27fiokd.s[305]++;
          if (item_s.type == 8) {
            cov_1hg27fiokd.b[80][0]++;
            cov_1hg27fiokd.s[306]++;

            item[item_s.id] = '<img style="max-height: 50px; max-width: 100px; width: auto; height: auto" src="' + $deltahttp.getDataPath('Formular') + '?' + $CRUDService.getComplement(2, true) + '&get=picture&record=' + item.id + '&field=' + item_s.id + '&change=' + ((cov_1hg27fiokd.b[81][0]++, item[item_s.id]) || (cov_1hg27fiokd.b[81][1]++, '')) + '" />'; //     item[item_s.id] ? $filter('number')(item[item_s.id]) : '';
          } else {
            cov_1hg27fiokd.b[80][1]++;
          }

          cov_1hg27fiokd.s[307]++;
          label = label.replaceAll('${' + item_s.id + '}', (cov_1hg27fiokd.b[82][0]++, item[item_s.id]) || (cov_1hg27fiokd.b[82][1]++, ''));

          /* while (true) {
            const oldLabel = label;
            label = label.replace('${' + item_s.id + '}', item[item_s.id] || '');
            if (oldLabel == label) {
              break;
            }
          } */
        }
      } catch (err) {
        _didIteratorError15 = true;
        _iteratorError15 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion15 && _iterator15.return) {
            _iterator15.return();
          }
        } finally {
          if (_didIteratorError15) {
            throw _iteratorError15;
          }
        }
      }

      cov_1hg27fiokd.s[308]++;
      return '<div class="theme-scrollbar-slim map_modal_info" style="max-height: ' + maxHeight + 'px; overflow-y: auto">' + label + '</div>';
      // return label;
    },
    drawLayers: function drawLayers(request) {
      cov_1hg27fiokd.s[309]++;

      this.getFilterFields(request.ID);
      var pathMarker = (cov_1hg27fiokd.s[310]++, $deltahttp.getAnalysePinRepository(request.id));
      var params = (cov_1hg27fiokd.s[311]++, { get: 'all_r_simple_map', withMap: true, withData: 1, id: request.ID, id_request: request.id, export: 1 });
      cov_1hg27fiokd.s[312]++;
      $CRUDService.getAll(PATH_FORMULAR, params, function (data) {
        cov_1hg27fiokd.f[15]++;
        cov_1hg27fiokd.s[313]++;

        $scope.analyseMap.loading[request.id] = false;
        var count = (cov_1hg27fiokd.s[314]++, 0);
        var center = (cov_1hg27fiokd.s[315]++, [0, 0]);
        // $scope.analyseMap.requestLayers[request.id] = L.layerGroup();

        var mask = (cov_1hg27fiokd.s[316]++, data.mask);
        /*
                for (const item of data.rows) {
                  if (item.o) {
                    continue;
                  }
                  mask += '<p><b>' + item.caption + ': </b> ${' + item.name + '} </p>';
                } */

        cov_1hg27fiokd.s[317]++;
        var _iteratorNormalCompletion16 = true;
        var _didIteratorError16 = false;
        var _iteratorError16 = undefined;

        try {
          var _loop2 = function _loop2() {
            var layer = _step16.value;

            var l = (cov_1hg27fiokd.s[318]++, L.layerGroup());
            cov_1hg27fiokd.s[319]++;
            l.addTo($scope.layerGroupAnalyse);

            cov_1hg27fiokd.s[320]++;
            if (!layer.external) {
              cov_1hg27fiokd.b[83][0]++;
              cov_1hg27fiokd.s[321]++;
              var _iteratorNormalCompletion17 = true;
              var _didIteratorError17 = false;
              var _iteratorError17 = undefined;

              try {
                var _loop3 = function _loop3() {
                  var item = _step17.value;
                  cov_1hg27fiokd.s[322]++;

                  try {
                    cov_1hg27fiokd.s[323]++;

                    if (layer.type == 13) {
                      cov_1hg27fiokd.b[84][0]++;

                      var latlg = (cov_1hg27fiokd.s[324]++, angular.isString(item[layer.field]) ? (cov_1hg27fiokd.b[85][0]++, angular.fromJson(item[layer.field])) : (cov_1hg27fiokd.b[85][1]++, item[layer.field]));
                      cov_1hg27fiokd.s[325]++;
                      if (!angular.isArray(latlg)) {
                        cov_1hg27fiokd.b[86][0]++;
                        cov_1hg27fiokd.s[326]++;

                        return 'continue';
                      } else {
                        cov_1hg27fiokd.b[86][1]++;
                      }
                      cov_1hg27fiokd.s[327]++;
                      latlg = latlg.filter(function (lat) {
                        cov_1hg27fiokd.f[16]++;
                        cov_1hg27fiokd.s[328]++;

                        if (!angular.isArray(lat)) {
                          cov_1hg27fiokd.b[87][0]++;
                          cov_1hg27fiokd.s[329]++;

                          return false;
                        } else {
                          cov_1hg27fiokd.b[87][1]++;
                        }
                        cov_1hg27fiokd.s[330]++;
                        if (lat.length < 2) {
                          cov_1hg27fiokd.b[88][0]++;
                          cov_1hg27fiokd.s[331]++;

                          return false;
                        } else {
                          cov_1hg27fiokd.b[88][1]++;
                        }
                        cov_1hg27fiokd.s[332]++;
                        return true;
                      });
                      cov_1hg27fiokd.s[333]++;
                      L.polyline(latlg, { color: 'rgb(' + layer.color + ')' }).addTo(l).on('click', function (a, b, c) {
                        cov_1hg27fiokd.f[17]++;

                        var popup = (cov_1hg27fiokd.s[334]++, L.popup().setLatLng(a.latlng).setContent('<div class="spinner-example" ng-if="isloading"> <div class="sk-spinner sk-spinner-wave">  <div class="sk-rect1"></div>  <div class="sk-rect2"></div>   <div class="sk-rect3"></div>  <div class="sk-rect4"></div> <div class="sk-rect5"></div> </div> </div>').openOn($scope.mapDashboard.myMap));
                        cov_1hg27fiokd.s[335]++;
                        $CRUDService.getAll(PATH_FORMULAR, { get: 'single_record', withData: 1, id: request.ID, id_r: item.id }, function (records) {
                          cov_1hg27fiokd.f[18]++;
                          cov_1hg27fiokd.s[336]++;

                          if (records.length > 0) {
                            cov_1hg27fiokd.b[89][0]++;
                            cov_1hg27fiokd.s[337]++;

                            popup.setContent($scope.analyseMap.formatData(records[0], layer, $scope.analyseMap.requestFields[request.ID]));
                          } else {
                            cov_1hg27fiokd.b[89][1]++;
                            cov_1hg27fiokd.s[338]++;

                            popup.setContent('<div></div>');
                          }
                        });
                      });
                    } else {
                      cov_1hg27fiokd.b[84][1]++;
                    }
                    cov_1hg27fiokd.s[339]++;
                    if (layer.type == 11) {
                      cov_1hg27fiokd.b[90][0]++;

                      var _latlg = (cov_1hg27fiokd.s[340]++, angular.isString(item[layer.field]) ? (cov_1hg27fiokd.b[91][0]++, angular.fromJson(item[layer.field])) : (cov_1hg27fiokd.b[91][1]++, item[layer.field]));
                      cov_1hg27fiokd.s[341]++;
                      if (!angular.isArray(_latlg)) {
                        cov_1hg27fiokd.b[92][0]++;
                        cov_1hg27fiokd.s[342]++;

                        return 'continue';
                      } else {
                        cov_1hg27fiokd.b[92][1]++;
                      }
                      cov_1hg27fiokd.s[343]++;
                      if (_latlg.length < 2) {
                        cov_1hg27fiokd.b[93][0]++;
                        cov_1hg27fiokd.s[344]++;

                        return 'continue';
                      } else {
                        cov_1hg27fiokd.b[93][1]++;
                      }
                      cov_1hg27fiokd.s[345]++;
                      center[0] += _latlg[0];
                      cov_1hg27fiokd.s[346]++;
                      center[1] += _latlg[1];
                      cov_1hg27fiokd.s[347]++;
                      count++;
                      cov_1hg27fiokd.s[348]++;
                      L.marker(_latlg, { icon: L.icon({
                          iconUrl: layer.url,
                          iconSize: [24, 41], // size of the icon
                          shadowSize: [32, 32] // size of the shadow
                        }) }).addTo(l).on('click', function (a, b, c) {
                        cov_1hg27fiokd.f[19]++;

                        /*            L.popup()
                                      .setLatLng(a.latlng)
                                      .setContent($scope.formatData(item, layer))
                                      .openOn($scope.mymap);
                           */
                        var popup = (cov_1hg27fiokd.s[349]++, L.popup().setLatLng(a.latlng).setContent('<div class="spinner-example" ng-if="isloading"> <div class="sk-spinner sk-spinner-wave">  <div class="sk-rect1"></div>  <div class="sk-rect2"></div>   <div class="sk-rect3"></div>  <div class="sk-rect4"></div> <div class="sk-rect5"></div> </div> </div>').openOn($scope.mapDashboard.myMap));
                        cov_1hg27fiokd.s[350]++;
                        $CRUDService.getAll(PATH_FORMULAR, { get: 'single_record', withData: 1, id: request.ID, id_r: item.id }, function (records) {
                          cov_1hg27fiokd.f[20]++;
                          cov_1hg27fiokd.s[351]++;

                          if (records.length > 0) {
                            cov_1hg27fiokd.b[94][0]++;
                            cov_1hg27fiokd.s[352]++;

                            popup.setContent($scope.analyseMap.formatData(records[0], layer, $scope.analyseMap.requestFields[request.ID]));
                          } else {
                            cov_1hg27fiokd.b[94][1]++;
                            cov_1hg27fiokd.s[353]++;

                            popup.setContent('<div></div>');
                          }
                        });
                      });
                    } else {
                      cov_1hg27fiokd.b[90][1]++;
                    }

                    cov_1hg27fiokd.s[354]++;
                    layer.display__ = true;
                    cov_1hg27fiokd.s[355]++;
                    $scope.analyseMap.requestLayers[layer.id] = l;
                  } catch (ex) {}
                };

                for (var _iterator17 = data.data[Symbol.iterator](), _step17; !(_iteratorNormalCompletion17 = (_step17 = _iterator17.next()).done); _iteratorNormalCompletion17 = true) {
                  var _ret3 = _loop3();

                  if (_ret3 === 'continue') continue;
                }
              } catch (err) {
                _didIteratorError17 = true;
                _iteratorError17 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion17 && _iterator17.return) {
                    _iterator17.return();
                  }
                } finally {
                  if (_didIteratorError17) {
                    throw _iteratorError17;
                  }
                }
              }
            } else {
              cov_1hg27fiokd.b[83][1]++;
            }
          };

          for (var _iterator16 = request.MAP[Symbol.iterator](), _step16; !(_iteratorNormalCompletion16 = (_step16 = _iterator16.next()).done); _iteratorNormalCompletion16 = true) {
            _loop2();
          }
        } catch (err) {
          _didIteratorError16 = true;
          _iteratorError16 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion16 && _iterator16.return) {
              _iterator16.return();
            }
          } finally {
            if (_didIteratorError16) {
              throw _iteratorError16;
            }
          }
        }

        cov_1hg27fiokd.s[356]++;
        if (count > 1) {
          cov_1hg27fiokd.b[95][0]++;
          cov_1hg27fiokd.s[357]++;

          center[0] /= count;
          cov_1hg27fiokd.s[358]++;
          center[1] /= count;
        } else {
          cov_1hg27fiokd.b[95][1]++;
        }
        cov_1hg27fiokd.s[359]++;
        $scope.mapDashboard.myMap.setView(center);
      });
    },
    initialise: function initialise() {
      cov_1hg27fiokd.s[360]++;

      if ($scope.layerGroupAnalyse) {
        cov_1hg27fiokd.b[96][0]++;
        cov_1hg27fiokd.s[361]++;

        $scope.layerGroupAnalyse.clearLayers();
        cov_1hg27fiokd.s[362]++;
        $scope.layerGroupAnalyse = null;
      } else {
        cov_1hg27fiokd.b[96][1]++;
      }
      // Get All Map Analyse
      cov_1hg27fiokd.s[363]++;
      this.requestLayers = {};
      cov_1hg27fiokd.s[364]++;
      this.requests = [];
      cov_1hg27fiokd.s[365]++;
      this.loading = {};
      cov_1hg27fiokd.s[366]++;
      $CRUDService.getAll(PATH_FORMULAR, { get: 'all_map_request' }, function (data) {
        cov_1hg27fiokd.f[21]++;
        cov_1hg27fiokd.s[367]++;

        $scope.layerGroupAnalyse = L.layerGroup([], {
          attribution: '© <a href=\'https://www.mapbox.com/about/maps/\'>Mapbox</a> , © <a href=\'https://www.openstreetmap.org/copyright\'>OpenStreetMap</a>  <strong><a href=\'https://www.mapbox.com/map-feedback/\' target=\'_blank\'>Improve this map</a></strong>'
        });
        cov_1hg27fiokd.s[368]++;
        $scope.layerGroupAnalyse.addTo($scope.mapDashboard.myMap);
        cov_1hg27fiokd.s[369]++;
        $scope.layerGroupAnalyse.clearLayers();
        cov_1hg27fiokd.s[370]++;
        $scope.analyseMap.requests = data;
        cov_1hg27fiokd.s[371]++;
        var _iteratorNormalCompletion18 = true;
        var _didIteratorError18 = false;
        var _iteratorError18 = undefined;

        try {
          for (var _iterator18 = $scope.analyseMap.requests[Symbol.iterator](), _step18; !(_iteratorNormalCompletion18 = (_step18 = _iterator18.next()).done); _iteratorNormalCompletion18 = true) {
            var request = _step18.value;
            cov_1hg27fiokd.s[372]++;

            request.display__ = true;
            cov_1hg27fiokd.s[373]++;
            request.pin = $deltahttp.getAnalysePinRepository(request.id);
            cov_1hg27fiokd.s[374]++;
            $scope.analyseMap.loading[request.id] = true;
            cov_1hg27fiokd.s[375]++;
            $scope.analyseMap.drawLayers(request);
          }
        } catch (err) {
          _didIteratorError18 = true;
          _iteratorError18 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion18 && _iterator18.return) {
              _iterator18.return();
            }
          } finally {
            if (_didIteratorError18) {
              throw _iteratorError18;
            }
          }
        }
      });
    }
  };
  cov_1hg27fiokd.s[376]++;
  $deltaGeoLevel.addController($scope, function () {
    cov_1hg27fiokd.f[22]++;
    cov_1hg27fiokd.s[377]++;

    $scope.mapDashboard.selectLevel = $scope.listData_GeoLevels_bksb[0];
    cov_1hg27fiokd.s[378]++;
    $scope.mapDashboard.processIndicator();
  });
  cov_1hg27fiokd.s[379]++;
  $scope.mapDashboard.getInformation();
  cov_1hg27fiokd.s[380]++;
  $rootScope.performFullScreen('analytics');
});
'use strict';

var cov_h0tmammvh = function () {
		var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/risk/documents/doc_uploadCtrl.js',
		    hash = '46aa282bb017d0deead2e6356a3dcca5578778dc',
		    Function = function () {}.constructor,
		    global = new Function('return this')(),
		    gcv = '__coverage__',
		    coverageData = {
				path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/risk/documents/doc_uploadCtrl.js',
				statementMap: {
						'0': {
								start: {
										line: 3,
										column: 0
								},
								end: {
										line: 213,
										column: 3
								}
						},
						'1': {
								start: {
										line: 5,
										column: 2
								},
								end: {
										line: 5,
										column: 47
								}
						},
						'2': {
								start: {
										line: 6,
										column: 2
								},
								end: {
										line: 6,
										column: 49
								}
						},
						'3': {
								start: {
										line: 7,
										column: 2
								},
								end: {
										line: 7,
										column: 28
								}
						},
						'4': {
								start: {
										line: 10,
										column: 2
								},
								end: {
										line: 67,
										column: 4
								}
						},
						'5': {
								start: {
										line: 11,
										column: 4
								},
								end: {
										line: 22,
										column: 5
								}
						},
						'6': {
								start: {
										line: 12,
										column: 6
								},
								end: {
										line: 14,
										column: 7
								}
						},
						'7': {
								start: {
										line: 13,
										column: 8
								},
								end: {
										line: 13,
										column: 47
								}
						},
						'8': {
								start: {
										line: 16,
										column: 6
								},
								end: {
										line: 18,
										column: 7
								}
						},
						'9': {
								start: {
										line: 17,
										column: 8
								},
								end: {
										line: 17,
										column: 47
								}
						},
						'10': {
								start: {
										line: 19,
										column: 6
								},
								end: {
										line: 21,
										column: 7
								}
						},
						'11': {
								start: {
										line: 20,
										column: 8
								},
								end: {
										line: 20,
										column: 48
								}
						},
						'12': {
								start: {
										line: 23,
										column: 16
								},
								end: {
										line: 23,
										column: 57
								}
						},
						'13': {
								start: {
										line: 24,
										column: 16
								},
								end: {
										line: 24,
										column: 26
								}
						},
						'14': {
								start: {
										line: 25,
										column: 4
								},
								end: {
										line: 63,
										column: 5
								}
						},
						'15': {
								start: {
										line: 32,
										column: 8
								},
								end: {
										line: 32,
										column: 44
								}
						},
						'16': {
								start: {
										line: 45,
										column: 8
								},
								end: {
										line: 45,
										column: 45
								}
						},
						'17': {
								start: {
										line: 48,
										column: 8
								},
								end: {
										line: 48,
										column: 43
								}
						},
						'18': {
								start: {
										line: 58,
										column: 8
								},
								end: {
										line: 58,
										column: 45
								}
						},
						'19': {
								start: {
										line: 61,
										column: 8
								},
								end: {
										line: 61,
										column: 45
								}
						},
						'20': {
								start: {
										line: 69,
										column: 23
								},
								end: {
										line: 69,
										column: 34
								}
						},
						'21': {
								start: {
										line: 71,
										column: 4
								},
								end: {
										line: 76,
										column: 6
								}
						},
						'22': {
								start: {
										line: 78,
										column: 2
								},
								end: {
										line: 87,
										column: 4
								}
						},
						'23': {
								start: {
										line: 80,
										column: 4
								},
								end: {
										line: 80,
										column: 60
								}
						},
						'24': {
								start: {
										line: 81,
										column: 4
								},
								end: {
										line: 81,
										column: 31
								}
						},
						'25': {
								start: {
										line: 82,
										column: 23
								},
								end: {
										line: 82,
										column: 44
								}
						},
						'26': {
								start: {
										line: 83,
										column: 4
								},
								end: {
										line: 86,
										column: 7
								}
						},
						'27': {
								start: {
										line: 84,
										column: 6
								},
								end: {
										line: 84,
										column: 39
								}
						},
						'28': {
								start: {
										line: 85,
										column: 6
								},
								end: {
										line: 85,
										column: 41
								}
						},
						'29': {
								start: {
										line: 89,
										column: 2
								},
								end: {
										line: 174,
										column: 4
								}
						},
						'30': {
								start: {
										line: 95,
										column: 6
								},
								end: {
										line: 95,
										column: 20
								}
						},
						'31': {
								start: {
										line: 96,
										column: 12
								},
								end: {
										line: 96,
										column: 52
								}
						},
						'32': {
								start: {
										line: 97,
										column: 26
								},
								end: {
										line: 102,
										column: 13
								}
						},
						'33': {
								start: {
										line: 104,
										column: 6
								},
								end: {
										line: 106,
										column: 9
								}
						},
						'34': {
								start: {
										line: 105,
										column: 8
								},
								end: {
										line: 105,
										column: 37
								}
						},
						'35': {
								start: {
										line: 109,
										column: 6
								},
								end: {
										line: 109,
										column: 25
								}
						},
						'36': {
								start: {
										line: 110,
										column: 6
								},
								end: {
										line: 113,
										column: 8
								}
						},
						'37': {
								start: {
										line: 114,
										column: 6
								},
								end: {
										line: 114,
										column: 32
								}
						},
						'38': {
								start: {
										line: 117,
										column: 6
								},
								end: {
										line: 117,
										column: 25
								}
						},
						'39': {
								start: {
										line: 118,
										column: 6
								},
								end: {
										line: 118,
										column: 31
								}
						},
						'40': {
								start: {
										line: 121,
										column: 12
								},
								end: {
										line: 121,
										column: 31
								}
						},
						'41': {
								start: {
										line: 123,
										column: 6
								},
								end: {
										line: 131,
										column: 7
								}
						},
						'42': {
								start: {
										line: 124,
										column: 8
								},
								end: {
										line: 127,
										column: 9
								}
						},
						'43': {
								start: {
										line: 125,
										column: 10
								},
								end: {
										line: 125,
										column: 28
								}
						},
						'44': {
								start: {
										line: 126,
										column: 10
								},
								end: {
										line: 126,
										column: 17
								}
						},
						'45': {
								start: {
										line: 128,
										column: 13
								},
								end: {
										line: 131,
										column: 7
								}
						},
						'46': {
								start: {
										line: 129,
										column: 8
								},
								end: {
										line: 129,
										column: 26
								}
						},
						'47': {
								start: {
										line: 130,
										column: 8
								},
								end: {
										line: 130,
										column: 15
								}
						},
						'48': {
								start: {
										line: 133,
										column: 20
								},
								end: {
										line: 140,
										column: 7
								}
						},
						'49': {
								start: {
										line: 142,
										column: 6
								},
								end: {
										line: 144,
										column: 9
								}
						},
						'50': {
								start: {
										line: 143,
										column: 8
								},
								end: {
										line: 143,
										column: 34
								}
						},
						'51': {
								start: {
										line: 147,
										column: 6
								},
								end: {
										line: 149,
										column: 9
								}
						},
						'52': {
								start: {
										line: 148,
										column: 8
								},
								end: {
										line: 148,
										column: 34
								}
						},
						'53': {
								start: {
										line: 154,
										column: 6
								},
								end: {
										line: 157,
										column: 7
								}
						},
						'54': {
								start: {
										line: 155,
										column: 8
								},
								end: {
										line: 155,
										column: 45
								}
						},
						'55': {
								start: {
										line: 156,
										column: 8
								},
								end: {
										line: 156,
										column: 15
								}
						},
						'56': {
								start: {
										line: 158,
										column: 19
								},
								end: {
										line: 158,
										column: 65
								}
						},
						'57': {
								start: {
										line: 159,
										column: 6
								},
								end: {
										line: 162,
										column: 7
								}
						},
						'58': {
								start: {
										line: 160,
										column: 8
								},
								end: {
										line: 160,
										column: 49
								}
						},
						'59': {
								start: {
										line: 161,
										column: 8
								},
								end: {
										line: 161,
										column: 15
								}
						},
						'60': {
								start: {
										line: 163,
										column: 6
								},
								end: {
										line: 166,
										column: 7
								}
						},
						'61': {
								start: {
										line: 165,
										column: 8
								},
								end: {
										line: 165,
										column: 15
								}
						},
						'62': {
								start: {
										line: 167,
										column: 6
								},
								end: {
										line: 171,
										column: 9
								}
						},
						'63': {
								start: {
										line: 168,
										column: 8
								},
								end: {
										line: 170,
										column: 9
								}
						},
						'64': {
								start: {
										line: 169,
										column: 10
								},
								end: {
										line: 169,
										column: 74
								}
						},
						'65': {
								start: {
										line: 177,
										column: 23
								},
								end: {
										line: 182,
										column: 4
								}
						},
						'66': {
								start: {
										line: 184,
										column: 2
								},
								end: {
										line: 187,
										column: 4
								}
						},
						'67': {
								start: {
										line: 185,
										column: 4
								},
								end: {
										line: 185,
										column: 31
								}
						},
						'68': {
								start: {
										line: 186,
										column: 4
								},
								end: {
										line: 186,
										column: 22
								}
						},
						'69': {
								start: {
										line: 189,
										column: 2
								},
								end: {
										line: 195,
										column: 4
								}
						},
						'70': {
								start: {
										line: 190,
										column: 4
								},
								end: {
										line: 192,
										column: 5
								}
						},
						'71': {
								start: {
										line: 191,
										column: 6
								},
								end: {
										line: 191,
										column: 55
								}
						},
						'72': {
								start: {
										line: 194,
										column: 4
								},
								end: {
										line: 194,
										column: 32
								}
						},
						'73': {
								start: {
										line: 197,
										column: 2
								},
								end: {
										line: 199,
										column: 4
								}
						},
						'74': {
								start: {
										line: 198,
										column: 4
								},
								end: {
										line: 198,
										column: 32
								}
						},
						'75': {
								start: {
										line: 201,
										column: 2
								},
								end: {
										line: 203,
										column: 4
								}
						},
						'76': {
								start: {
										line: 202,
										column: 4
								},
								end: {
										line: 202,
										column: 74
								}
						},
						'77': {
								start: {
										line: 206,
										column: 2
								},
								end: {
										line: 208,
										column: 6
								}
						},
						'78': {
								start: {
										line: 207,
										column: 4
								},
								end: {
										line: 207,
										column: 37
								}
						},
						'79': {
								start: {
										line: 211,
										column: 4
								},
								end: {
										line: 211,
										column: 26
								}
						},
						'80': {
								start: {
										line: 212,
										column: 4
								},
								end: {
										line: 212,
										column: 30
								}
						}
				},
				fnMap: {
						'0': {
								name: '(anonymous_0)',
								decl: {
										start: {
												line: 3,
												column: 50
										},
										end: {
												line: 3,
												column: 51
										}
								},
								loc: {
										start: {
												line: 3,
												column: 351
										},
										end: {
												line: 213,
												column: 1
										}
								},
								line: 3
						},
						'1': {
								name: '(anonymous_1)',
								decl: {
										start: {
												line: 10,
												column: 24
										},
										end: {
												line: 10,
												column: 25
										}
								},
								loc: {
										start: {
												line: 10,
												column: 40
										},
										end: {
												line: 67,
												column: 3
										}
								},
								line: 10
						},
						'2': {
								name: '(anonymous_2)',
								decl: {
										start: {
												line: 78,
												column: 24
										},
										end: {
												line: 78,
												column: 25
										}
								},
								loc: {
										start: {
												line: 78,
												column: 40
										},
										end: {
												line: 87,
												column: 3
										}
								},
								line: 78
						},
						'3': {
								name: '(anonymous_3)',
								decl: {
										start: {
												line: 83,
												column: 46
										},
										end: {
												line: 83,
												column: 47
										}
								},
								loc: {
										start: {
												line: 83,
												column: 54
										},
										end: {
												line: 86,
												column: 5
										}
								},
								line: 83
						},
						'4': {
								name: '(anonymous_4)',
								decl: {
										start: {
												line: 104,
												column: 47
										},
										end: {
												line: 104,
												column: 48
										}
								},
								loc: {
										start: {
												line: 104,
												column: 55
										},
										end: {
												line: 106,
												column: 7
										}
								},
								line: 104
						},
						'5': {
								name: '(anonymous_5)',
								decl: {
										start: {
												line: 142,
												column: 107
										},
										end: {
												line: 142,
												column: 108
										}
								},
								loc: {
										start: {
												line: 142,
												column: 113
										},
										end: {
												line: 144,
												column: 7
										}
								},
								line: 142
						},
						'6': {
								name: '(anonymous_6)',
								decl: {
										start: {
												line: 147,
												column: 101
										},
										end: {
												line: 147,
												column: 102
										}
								},
								loc: {
										start: {
												line: 147,
												column: 107
										},
										end: {
												line: 149,
												column: 7
										}
								},
								line: 147
						},
						'7': {
								name: '(anonymous_7)',
								decl: {
										start: {
												line: 167,
												column: 92
										},
										end: {
												line: 167,
												column: 93
										}
								},
								loc: {
										start: {
												line: 167,
												column: 100
										},
										end: {
												line: 171,
												column: 7
										}
								},
								line: 167
						},
						'8': {
								name: '(anonymous_8)',
								decl: {
										start: {
												line: 184,
												column: 35
										},
										end: {
												line: 184,
												column: 36
										}
								},
								loc: {
										start: {
												line: 184,
												column: 55
										},
										end: {
												line: 187,
												column: 3
										}
								},
								line: 184
						},
						'9': {
								name: '(anonymous_9)',
								decl: {
										start: {
												line: 189,
												column: 31
										},
										end: {
												line: 189,
												column: 32
										}
								},
								loc: {
										start: {
												line: 189,
												column: 61
										},
										end: {
												line: 195,
												column: 3
										}
								},
								line: 189
						},
						'10': {
								name: '(anonymous_10)',
								decl: {
										start: {
												line: 197,
												column: 29
										},
										end: {
												line: 197,
												column: 30
										}
								},
								loc: {
										start: {
												line: 197,
												column: 49
										},
										end: {
												line: 199,
												column: 3
										}
								},
								line: 197
						},
						'11': {
								name: '(anonymous_11)',
								decl: {
										start: {
												line: 201,
												column: 24
										},
										end: {
												line: 201,
												column: 25
										}
								},
								loc: {
										start: {
												line: 201,
												column: 40
										},
										end: {
												line: 203,
												column: 3
										}
								},
								line: 201
						},
						'12': {
								name: '(anonymous_12)',
								decl: {
										start: {
												line: 206,
												column: 21
										},
										end: {
												line: 206,
												column: 22
										}
								},
								loc: {
										start: {
												line: 206,
												column: 33
										},
										end: {
												line: 208,
												column: 5
										}
								},
								line: 206
						}
				},
				branchMap: {
						'0': {
								loc: {
										start: {
												line: 11,
												column: 4
										},
										end: {
												line: 22,
												column: 5
										}
								},
								type: 'if',
								locations: [{
										start: {
												line: 11,
												column: 4
										},
										end: {
												line: 22,
												column: 5
										}
								}, {
										start: {
												line: 11,
												column: 4
										},
										end: {
												line: 22,
												column: 5
										}
								}],
								line: 11
						},
						'1': {
								loc: {
										start: {
												line: 12,
												column: 6
										},
										end: {
												line: 14,
												column: 7
										}
								},
								type: 'if',
								locations: [{
										start: {
												line: 12,
												column: 6
										},
										end: {
												line: 14,
												column: 7
										}
								}, {
										start: {
												line: 12,
												column: 6
										},
										end: {
												line: 14,
												column: 7
										}
								}],
								line: 12
						},
						'2': {
								loc: {
										start: {
												line: 12,
												column: 11
										},
										end: {
												line: 12,
												column: 25
										}
								},
								type: 'binary-expr',
								locations: [{
										start: {
												line: 12,
												column: 11
										},
										end: {
												line: 12,
												column: 19
										}
								}, {
										start: {
												line: 12,
												column: 23
										},
										end: {
												line: 12,
												column: 25
										}
								}],
								line: 12
						},
						'3': {
								loc: {
										start: {
												line: 16,
												column: 6
										},
										end: {
												line: 18,
												column: 7
										}
								},
								type: 'if',
								locations: [{
										start: {
												line: 16,
												column: 6
										},
										end: {
												line: 18,
												column: 7
										}
								}, {
										start: {
												line: 16,
												column: 6
										},
										end: {
												line: 18,
												column: 7
										}
								}],
								line: 16
						},
						'4': {
								loc: {
										start: {
												line: 19,
												column: 6
										},
										end: {
												line: 21,
												column: 7
										}
								},
								type: 'if',
								locations: [{
										start: {
												line: 19,
												column: 6
										},
										end: {
												line: 21,
												column: 7
										}
								}, {
										start: {
												line: 19,
												column: 6
										},
										end: {
												line: 21,
												column: 7
										}
								}],
								line: 19
						},
						'5': {
								loc: {
										start: {
												line: 23,
												column: 17
										},
										end: {
												line: 23,
												column: 45
										}
								},
								type: 'binary-expr',
								locations: [{
										start: {
												line: 23,
												column: 17
										},
										end: {
												line: 23,
												column: 33
										}
								}, {
										start: {
												line: 23,
												column: 37
										},
										end: {
												line: 23,
												column: 45
										}
								}],
								line: 23
						},
						'6': {
								loc: {
										start: {
												line: 25,
												column: 4
										},
										end: {
												line: 63,
												column: 5
										}
								},
								type: 'switch',
								locations: [{
										start: {
												line: 26,
												column: 6
										},
										end: {
												line: 26,
												column: 17
										}
								}, {
										start: {
												line: 27,
												column: 6
										},
										end: {
												line: 27,
												column: 18
										}
								}, {
										start: {
												line: 28,
												column: 6
										},
										end: {
												line: 28,
												column: 18
										}
								}, {
										start: {
												line: 29,
												column: 6
										},
										end: {
												line: 29,
												column: 17
										}
								}, {
										start: {
												line: 30,
												column: 6
										},
										end: {
												line: 30,
												column: 18
										}
								}, {
										start: {
												line: 31,
												column: 6
										},
										end: {
												line: 32,
												column: 44
										}
								}, {
										start: {
												line: 34,
												column: 6
										},
										end: {
												line: 34,
												column: 17
										}
								}, {
										start: {
												line: 35,
												column: 6
										},
										end: {
												line: 35,
												column: 17
										}
								}, {
										start: {
												line: 36,
												column: 6
										},
										end: {
												line: 36,
												column: 17
										}
								}, {
										start: {
												line: 37,
												column: 6
										},
										end: {
												line: 37,
												column: 17
										}
								}, {
										start: {
												line: 38,
												column: 6
										},
										end: {
												line: 38,
												column: 17
										}
								}, {
										start: {
												line: 39,
												column: 6
										},
										end: {
												line: 39,
												column: 18
										}
								}, {
										start: {
												line: 40,
												column: 6
										},
										end: {
												line: 40,
												column: 17
										}
								}, {
										start: {
												line: 41,
												column: 6
										},
										end: {
												line: 41,
												column: 18
										}
								}, {
										start: {
												line: 42,
												column: 6
										},
										end: {
												line: 42,
												column: 18
										}
								}, {
										start: {
												line: 43,
												column: 6
										},
										end: {
												line: 43,
												column: 18
										}
								}, {
										start: {
												line: 44,
												column: 6
										},
										end: {
												line: 45,
												column: 45
										}
								}, {
										start: {
												line: 47,
												column: 6
										},
										end: {
												line: 48,
												column: 43
										}
								}, {
										start: {
												line: 50,
												column: 6
										},
										end: {
												line: 50,
												column: 18
										}
								}, {
										start: {
												line: 51,
												column: 6
										},
										end: {
												line: 51,
												column: 17
										}
								}, {
										start: {
												line: 52,
												column: 6
										},
										end: {
												line: 52,
												column: 17
										}
								}, {
										start: {
												line: 53,
												column: 6
										},
										end: {
												line: 53,
												column: 17
										}
								}, {
										start: {
												line: 54,
												column: 6
										},
										end: {
												line: 54,
												column: 18
										}
								}, {
										start: {
												line: 55,
												column: 6
										},
										end: {
												line: 55,
												column: 17
										}
								}, {
										start: {
												line: 56,
												column: 6
										},
										end: {
												line: 56,
												column: 17
										}
								}, {
										start: {
												line: 57,
												column: 6
										},
										end: {
												line: 58,
												column: 45
										}
								}, {
										start: {
												line: 60,
												column: 6
										},
										end: {
												line: 61,
												column: 45
										}
								}],
								line: 25
						},
						'7': {
								loc: {
										start: {
												line: 25,
												column: 13
										},
										end: {
												line: 25,
												column: 31
										}
								},
								type: 'binary-expr',
								locations: [{
										start: {
												line: 25,
												column: 13
										},
										end: {
												line: 25,
												column: 25
										}
								}, {
										start: {
												line: 25,
												column: 29
										},
										end: {
												line: 25,
												column: 31
										}
								}],
								line: 25
						},
						'8': {
								loc: {
										start: {
												line: 73,
												column: 14
										},
										end: {
												line: 73,
												column: 80
										}
								},
								type: 'cond-expr',
								locations: [{
										start: {
												line: 73,
												column: 44
										},
										end: {
												line: 73,
												column: 76
										}
								}, {
										start: {
												line: 73,
												column: 79
										},
										end: {
												line: 73,
												column: 80
										}
								}],
								line: 73
						},
						'9': {
								loc: {
										start: {
												line: 75,
												column: 14
										},
										end: {
												line: 75,
												column: 80
										}
								},
								type: 'cond-expr',
								locations: [{
										start: {
												line: 75,
												column: 44
										},
										end: {
												line: 75,
												column: 76
										}
								}, {
										start: {
												line: 75,
												column: 79
										},
										end: {
												line: 75,
												column: 80
										}
								}],
								line: 75
						},
						'10': {
								loc: {
										start: {
												line: 123,
												column: 6
										},
										end: {
												line: 131,
												column: 7
										}
								},
								type: 'if',
								locations: [{
										start: {
												line: 123,
												column: 6
										},
										end: {
												line: 131,
												column: 7
										}
								}, {
										start: {
												line: 123,
												column: 6
										},
										end: {
												line: 131,
												column: 7
										}
								}],
								line: 123
						},
						'11': {
								loc: {
										start: {
												line: 124,
												column: 8
										},
										end: {
												line: 127,
												column: 9
										}
								},
								type: 'if',
								locations: [{
										start: {
												line: 124,
												column: 8
										},
										end: {
												line: 127,
												column: 9
										}
								}, {
										start: {
												line: 124,
												column: 8
										},
										end: {
												line: 127,
												column: 9
										}
								}],
								line: 124
						},
						'12': {
								loc: {
										start: {
												line: 124,
												column: 12
										},
										end: {
												line: 124,
												column: 61
										}
								},
								type: 'binary-expr',
								locations: [{
										start: {
												line: 124,
												column: 12
										},
										end: {
												line: 124,
												column: 34
										}
								}, {
										start: {
												line: 124,
												column: 38
										},
										end: {
												line: 124,
												column: 61
										}
								}],
								line: 124
						},
						'13': {
								loc: {
										start: {
												line: 128,
												column: 13
										},
										end: {
												line: 131,
												column: 7
										}
								},
								type: 'if',
								locations: [{
										start: {
												line: 128,
												column: 13
										},
										end: {
												line: 131,
												column: 7
										}
								}, {
										start: {
												line: 128,
												column: 13
										},
										end: {
												line: 131,
												column: 7
										}
								}],
								line: 128
						},
						'14': {
								loc: {
										start: {
												line: 134,
												column: 13
										},
										end: {
												line: 134,
												column: 69
										}
								},
								type: 'cond-expr',
								locations: [{
										start: {
												line: 134,
												column: 43
										},
										end: {
												line: 134,
												column: 45
										}
								}, {
										start: {
												line: 134,
												column: 48
										},
										end: {
												line: 134,
												column: 69
										}
								}],
								line: 134
						},
						'15': {
								loc: {
										start: {
												line: 135,
												column: 14
										},
										end: {
												line: 135,
												column: 71
										}
								},
								type: 'cond-expr',
								locations: [{
										start: {
												line: 135,
												column: 44
										},
										end: {
												line: 135,
												column: 46
										}
								}, {
										start: {
												line: 135,
												column: 49
										},
										end: {
												line: 135,
												column: 71
										}
								}],
								line: 135
						},
						'16': {
								loc: {
										start: {
												line: 136,
												column: 28
										},
										end: {
												line: 136,
												column: 90
										}
								},
								type: 'cond-expr',
								locations: [{
										start: {
												line: 136,
												column: 58
										},
										end: {
												line: 136,
												column: 86
										}
								}, {
										start: {
												line: 136,
												column: 89
										},
										end: {
												line: 136,
												column: 90
										}
								}],
								line: 136
						},
						'17': {
								loc: {
										start: {
												line: 154,
												column: 6
										},
										end: {
												line: 157,
												column: 7
										}
								},
								type: 'if',
								locations: [{
										start: {
												line: 154,
												column: 6
										},
										end: {
												line: 157,
												column: 7
										}
								}, {
										start: {
												line: 154,
												column: 6
										},
										end: {
												line: 157,
												column: 7
										}
								}],
								line: 154
						},
						'18': {
								loc: {
										start: {
												line: 159,
												column: 6
										},
										end: {
												line: 162,
												column: 7
										}
								},
								type: 'if',
								locations: [{
										start: {
												line: 159,
												column: 6
										},
										end: {
												line: 162,
												column: 7
										}
								}, {
										start: {
												line: 159,
												column: 6
										},
										end: {
												line: 162,
												column: 7
										}
								}],
								line: 159
						},
						'19': {
								loc: {
										start: {
												line: 163,
												column: 6
										},
										end: {
												line: 166,
												column: 7
										}
								},
								type: 'if',
								locations: [{
										start: {
												line: 163,
												column: 6
										},
										end: {
												line: 166,
												column: 7
										}
								}, {
										start: {
												line: 163,
												column: 6
										},
										end: {
												line: 166,
												column: 7
										}
								}],
								line: 163
						},
						'20': {
								loc: {
										start: {
												line: 168,
												column: 8
										},
										end: {
												line: 170,
												column: 9
										}
								},
								type: 'if',
								locations: [{
										start: {
												line: 168,
												column: 8
										},
										end: {
												line: 170,
												column: 9
										}
								}, {
										start: {
												line: 168,
												column: 8
										},
										end: {
												line: 170,
												column: 9
										}
								}],
								line: 168
						},
						'21': {
								loc: {
										start: {
												line: 190,
												column: 4
										},
										end: {
												line: 192,
												column: 5
										}
								},
								type: 'if',
								locations: [{
										start: {
												line: 190,
												column: 4
										},
										end: {
												line: 192,
												column: 5
										}
								}, {
										start: {
												line: 190,
												column: 4
										},
										end: {
												line: 192,
												column: 5
										}
								}],
								line: 190
						}
				},
				s: {
						'0': 0,
						'1': 0,
						'2': 0,
						'3': 0,
						'4': 0,
						'5': 0,
						'6': 0,
						'7': 0,
						'8': 0,
						'9': 0,
						'10': 0,
						'11': 0,
						'12': 0,
						'13': 0,
						'14': 0,
						'15': 0,
						'16': 0,
						'17': 0,
						'18': 0,
						'19': 0,
						'20': 0,
						'21': 0,
						'22': 0,
						'23': 0,
						'24': 0,
						'25': 0,
						'26': 0,
						'27': 0,
						'28': 0,
						'29': 0,
						'30': 0,
						'31': 0,
						'32': 0,
						'33': 0,
						'34': 0,
						'35': 0,
						'36': 0,
						'37': 0,
						'38': 0,
						'39': 0,
						'40': 0,
						'41': 0,
						'42': 0,
						'43': 0,
						'44': 0,
						'45': 0,
						'46': 0,
						'47': 0,
						'48': 0,
						'49': 0,
						'50': 0,
						'51': 0,
						'52': 0,
						'53': 0,
						'54': 0,
						'55': 0,
						'56': 0,
						'57': 0,
						'58': 0,
						'59': 0,
						'60': 0,
						'61': 0,
						'62': 0,
						'63': 0,
						'64': 0,
						'65': 0,
						'66': 0,
						'67': 0,
						'68': 0,
						'69': 0,
						'70': 0,
						'71': 0,
						'72': 0,
						'73': 0,
						'74': 0,
						'75': 0,
						'76': 0,
						'77': 0,
						'78': 0,
						'79': 0,
						'80': 0
				},
				f: {
						'0': 0,
						'1': 0,
						'2': 0,
						'3': 0,
						'4': 0,
						'5': 0,
						'6': 0,
						'7': 0,
						'8': 0,
						'9': 0,
						'10': 0,
						'11': 0,
						'12': 0
				},
				b: {
						'0': [0, 0],
						'1': [0, 0],
						'2': [0, 0],
						'3': [0, 0],
						'4': [0, 0],
						'5': [0, 0],
						'6': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
						'7': [0, 0],
						'8': [0, 0],
						'9': [0, 0],
						'10': [0, 0],
						'11': [0, 0],
						'12': [0, 0],
						'13': [0, 0],
						'14': [0, 0],
						'15': [0, 0],
						'16': [0, 0],
						'17': [0, 0],
						'18': [0, 0],
						'19': [0, 0],
						'20': [0, 0],
						'21': [0, 0]
				},
				_coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
		},
		    coverage = global[gcv] || (global[gcv] = {});

		if (coverage[path] && coverage[path].hash === hash) {
				return coverage[path];
		}

		coverageData.hash = hash;
		return coverage[path] = coverageData;
}();

cov_h0tmammvh.s[0]++;
/* eslint-disable linebreak-style */
/* eslint-disable indent */
angular.module('app').controller('docUploadCtrl', function ($scope, $deltaTypeActivity, $window, $translate, $deltaNumber, FileUploader, SweetAlert, $uibModal, $deltahttp, $stateParams, $state, $deltaPlanLogique, $deltaGeoLevel, $deltaUnite, $deltaActor, $deltaLogLevel, $deltaFinancSource, $CRUDService, $deltaPlanMarches, $rootScope, $deltadate, $filter) {
		cov_h0tmammvh.f[0]++;
		cov_h0tmammvh.s[1]++;


		$scope.opening = [true, false, false, false];
		cov_h0tmammvh.s[2]++;
		$scope.lastUpdate = angular.toJson(new Date());
		cov_h0tmammvh.s[3]++;
		$scope.editloading = true;

		cov_h0tmammvh.s[4]++;
		$scope.getImageFile = function (item) {
				cov_h0tmammvh.f[1]++;
				cov_h0tmammvh.s[5]++;

				if (item.IDRAPPORTS == 0) {
						cov_h0tmammvh.b[0][0]++;
						cov_h0tmammvh.s[6]++;

						if (((cov_h0tmammvh.b[2][0]++, item.URL) || (cov_h0tmammvh.b[2][1]++, '')).replace($deltahttp.getActivityDocumentRepository(), '') == item.URL) {
								cov_h0tmammvh.b[1][0]++;
								cov_h0tmammvh.s[7]++;

								return './assets/img/file/dropbox.png';
						} else {
								cov_h0tmammvh.b[1][1]++;
						}
				} else {
						cov_h0tmammvh.b[0][1]++;
						cov_h0tmammvh.s[8]++;

						if (item.TYPE_RAPPORT == 2) {
								cov_h0tmammvh.b[3][0]++;
								cov_h0tmammvh.s[9]++;

								return './assets/img/file/dropbox.png';
						} else {
								cov_h0tmammvh.b[3][1]++;
						}
						cov_h0tmammvh.s[10]++;
						if (item.TYPE_RAPPORT == 1) {
								cov_h0tmammvh.b[4][0]++;
								cov_h0tmammvh.s[11]++;

								return './assets/img/file/editfile.png';
						} else {
								cov_h0tmammvh.b[4][1]++;
						}
				}
				var ext = (cov_h0tmammvh.s[12]++, ((cov_h0tmammvh.b[5][0]++, item.CHEMIN_RAPP) || (cov_h0tmammvh.b[5][1]++, item.URL)).split('.'));
				var len = (cov_h0tmammvh.s[13]++, ext.length);
				cov_h0tmammvh.s[14]++;
				switch (((cov_h0tmammvh.b[7][0]++, ext[len - 1]) || (cov_h0tmammvh.b[7][1]++, '')).toLowerCase()) {
						case 'doc':
								cov_h0tmammvh.b[6][0]++;

						case 'docm':
								cov_h0tmammvh.b[6][1]++;

						case 'docx':
								cov_h0tmammvh.b[6][2]++;

						case 'dot':
								cov_h0tmammvh.b[6][3]++;

						case 'dotm':
								cov_h0tmammvh.b[6][4]++;

						case 'dotx':
								cov_h0tmammvh.b[6][5]++;
								cov_h0tmammvh.s[15]++;

								return './assets/img/file/word.png';

						case 'csv':
								cov_h0tmammvh.b[6][6]++;

						case 'dbf':
								cov_h0tmammvh.b[6][7]++;

						case 'dif':
								cov_h0tmammvh.b[6][8]++;

						case 'ods':
								cov_h0tmammvh.b[6][9]++;

						case 'xla':
								cov_h0tmammvh.b[6][10]++;

						case 'xlam':
								cov_h0tmammvh.b[6][11]++;

						case 'xls':
								cov_h0tmammvh.b[6][12]++;

						case 'xlsb':
								cov_h0tmammvh.b[6][13]++;

						case 'xlsm':
								cov_h0tmammvh.b[6][14]++;

						case 'xlsx':
								cov_h0tmammvh.b[6][15]++;

						case 'xml':
								cov_h0tmammvh.b[6][16]++;
								cov_h0tmammvh.s[16]++;

								return './assets/img/file/excel.png';

						case 'pdf':
								cov_h0tmammvh.b[6][17]++;
								cov_h0tmammvh.s[17]++;

								return './assets/img/file/pdf.png';

						case 'jpeg':
								cov_h0tmammvh.b[6][18]++;

						case 'jpg':
								cov_h0tmammvh.b[6][19]++;

						case 'png':
								cov_h0tmammvh.b[6][20]++;

						case 'gif':
								cov_h0tmammvh.b[6][21]++;

						case 'tiff':
								cov_h0tmammvh.b[6][22]++;

						case 'psd':
								cov_h0tmammvh.b[6][23]++;

						case 'eps':
								cov_h0tmammvh.b[6][24]++;

						case 'ai':
								cov_h0tmammvh.b[6][25]++;
								cov_h0tmammvh.s[18]++;

								return './assets/img/file/image.png';

						default:
								cov_h0tmammvh.b[6][26]++;
								cov_h0tmammvh.s[19]++;

								return './assets/img/file/blank.png';

				}

				// item.CHEMIN_RAPP
				/* item.TYPE_RAPPORT == 2?'fa-link' : 'fa-file-o' */
		};

		var PATHDOCUMENT = (cov_h0tmammvh.s[20]++, 'Documents');

		cov_h0tmammvh.s[21]++;
		$scope.OTHER_DOC_UPLOAD = {
				//1-Risques && 2-incident  $rootScope.OTHER_DOC_UPLOAD.type
				type: $rootScope.OTHER_DOC_UPLOAD ? (cov_h0tmammvh.b[8][0]++, $rootScope.OTHER_DOC_UPLOAD.type) : (cov_h0tmammvh.b[8][1]++, 0),
				//id le l'élément cible  $rootScope.OTHER_DOC_UPLOAD.id_p
				id_p: $rootScope.OTHER_DOC_UPLOAD ? (cov_h0tmammvh.b[9][0]++, $rootScope.OTHER_DOC_UPLOAD.id_p) : (cov_h0tmammvh.b[9][1]++, 0)
		};

		cov_h0tmammvh.s[22]++;
		$scope.initOverview = function (item) {
				cov_h0tmammvh.f[2]++;
				cov_h0tmammvh.s[23]++;


				$scope.project = $rootScope.getCurrentProject().project;
				cov_h0tmammvh.s[24]++;
				$scope.editloading = false;
				var params = (cov_h0tmammvh.s[25]++, { get: 'all', id: 0 });
				cov_h0tmammvh.s[26]++;
				$CRUDService.getAll(PATHDOCUMENT, params, function (data) {
						cov_h0tmammvh.f[3]++;
						cov_h0tmammvh.s[27]++;

						$scope.documents.rapports = data;
						cov_h0tmammvh.s[28]++;
						$scope.documents.rapports.push({});
				});
		};

		cov_h0tmammvh.s[29]++;
		$scope.documents = {
				data: { data: [], documents: {} },
				rapports: [],
				labelDocument: $translate.instant('ACTIVITY.DOCUMENT'),
				itemDocument: null,
				getAll: function getAll() {
						cov_h0tmammvh.s[30]++;

						this.cancel();
						cov_h0tmammvh.s[31]++;
						this.data = { data: [], documents: {} };
						var value = (cov_h0tmammvh.s[32]++, {
								get: 'OtherDocumentAll',
								id: 0,
								id_p: $scope.OTHER_DOC_UPLOAD.id_p,
								type: $scope.OTHER_DOC_UPLOAD.type
						});

						cov_h0tmammvh.s[33]++;
						$CRUDService.getAll(PATHDOCUMENT, value, function (data) {
								cov_h0tmammvh.f[4]++;
								cov_h0tmammvh.s[34]++;

								$scope.documents.data = data;
						});
				},
				add: function add() {
						cov_h0tmammvh.s[35]++;

						this.error = false;
						cov_h0tmammvh.s[36]++;
						this.itemDocument = {
								rapport: this.rapports[0],
								type: 1
						};
						cov_h0tmammvh.s[37]++;
						$scope.documents.type = 1;
				},
				cancel: function cancel() {
						cov_h0tmammvh.s[38]++;

						this.error = false;
						cov_h0tmammvh.s[39]++;
						this.itemDocument = null;
				},
				validate: function validate() {
						cov_h0tmammvh.s[40]++;

						this.error = false;
						//Document de base documentaire
						cov_h0tmammvh.s[41]++;
						if (this.itemDocument.type != 1) {
								cov_h0tmammvh.b[10][0]++;
								cov_h0tmammvh.s[42]++;

								if ((cov_h0tmammvh.b[12][0]++, !this.itemDocument.url) || (cov_h0tmammvh.b[12][1]++, !this.itemDocument.name)) {
										cov_h0tmammvh.b[11][0]++;
										cov_h0tmammvh.s[43]++;

										this.error = true;
										cov_h0tmammvh.s[44]++;
										return;
								} else {
										cov_h0tmammvh.b[11][1]++;
								}
						} else {
										cov_h0tmammvh.b[10][1]++;
										cov_h0tmammvh.s[45]++;
										if (!this.itemDocument.rapport) {
												cov_h0tmammvh.b[13][0]++;
												cov_h0tmammvh.s[46]++;

												this.error = true;
												cov_h0tmammvh.s[47]++;
												return;
										} else {
												cov_h0tmammvh.b[13][1]++;
										}
								}var value = (cov_h0tmammvh.s[48]++, {
								URL: this.itemDocument.type == 1 ? (cov_h0tmammvh.b[14][0]++, '') : (cov_h0tmammvh.b[14][1]++, this.itemDocument.url),
								NAME: this.itemDocument.type == 1 ? (cov_h0tmammvh.b[15][0]++, '') : (cov_h0tmammvh.b[15][1]++, this.itemDocument.name),
								IDRAPPORTS: this.itemDocument.type == 1 ? (cov_h0tmammvh.b[16][0]++, this.itemDocument.rapport.id) : (cov_h0tmammvh.b[16][1]++, 0),
								ID_PARENT: $scope.OTHER_DOC_UPLOAD.id_p,
								TYPE: $scope.OTHER_DOC_UPLOAD.type,
								id: 0
						});

						cov_h0tmammvh.s[49]++;
						$CRUDService.save(PATHDOCUMENT, { action: 'OtherDocumentMiseAjour', valeur: angular.toJson(value) }, function () {
								cov_h0tmammvh.f[5]++;
								cov_h0tmammvh.s[50]++;

								$scope.documents.getAll();
						});
				},
				deleteRapport: function deleteRapport(item) {
						cov_h0tmammvh.s[51]++;

						$CRUDService.save(PATHDOCUMENT, { action: 'OtherDocumentSupp', valeur: angular.toJson(item) }, function () {
								cov_h0tmammvh.f[6]++;
								cov_h0tmammvh.s[52]++;

								$scope.documents.getAll();
						});
				},
				downloadItem: function downloadItem(document) {
						cov_h0tmammvh.s[53]++;

						/* documents.data.documents['_'+document.IDRAPPORTS] */

						if (document.IDRAPPORTS == 0) {
								cov_h0tmammvh.b[17][0]++;
								cov_h0tmammvh.s[54]++;

								$window.open(document.URL, '_blank');
								cov_h0tmammvh.s[55]++;
								return;
						} else {
								cov_h0tmammvh.b[17][1]++;
						}
						var item = (cov_h0tmammvh.s[56]++, this.data.documents['_' + document.IDRAPPORTS]);
						cov_h0tmammvh.s[57]++;
						if (item.TYPE_RAPPORT == 2) {
								cov_h0tmammvh.b[18][0]++;
								cov_h0tmammvh.s[58]++;

								$window.open(item.CHEMIN_RAPP, '_blank');
								cov_h0tmammvh.s[59]++;
								return;
						} else {
								cov_h0tmammvh.b[18][1]++;
						}
						cov_h0tmammvh.s[60]++;
						if (item.TYPE_RAPPORT == 1) {
								cov_h0tmammvh.b[19][0]++;
								cov_h0tmammvh.s[61]++;

								// $state.go('document.preview', {id: item.id});
								return;
						} else {
								cov_h0tmammvh.b[19][1]++;
						}
						cov_h0tmammvh.s[62]++;
						$CRUDService.getAll(PATHDOCUMENT, { download: true, c: item.Reference, id: item.id }, function (data) {
								cov_h0tmammvh.f[7]++;
								cov_h0tmammvh.s[63]++;

								if (data.f != '') {
										cov_h0tmammvh.b[20][0]++;
										cov_h0tmammvh.s[64]++;

										$window.open($CRUDService.getServerStrict() + data.f, '_blank');
								} else {
										cov_h0tmammvh.b[20][1]++;
								}
						});
				}
		};

		var uploaderFile = (cov_h0tmammvh.s[65]++, $scope.uploaderFile = new FileUploader({
				headers: $CRUDService.getHeader(),
				url: $CRUDService.getServerAdress() + 'web_server.php?action=loadexterndocument&method=1&' + $CRUDService.getComplement() + '&li_bksb=' + PATHDOCUMENT + '&type=' + $scope.OTHER_DOC_UPLOAD.type + '&id_p=' + $scope.OTHER_DOC_UPLOAD.type.id_p,
				alias: 'data',
				removeAfterUpload: true
		}));

		cov_h0tmammvh.s[66]++;
		uploaderFile.onAfterAddingFile = function (fileItem) {
				cov_h0tmammvh.f[8]++;
				cov_h0tmammvh.s[67]++;

				$scope.loading_file = true;
				cov_h0tmammvh.s[68]++;
				fileItem.upload();
		};

		cov_h0tmammvh.s[69]++;
		uploaderFile.onSuccessItem = function (fileItem, response) {
				cov_h0tmammvh.f[9]++;
				cov_h0tmammvh.s[70]++;

				if (response.success == 1) {
						cov_h0tmammvh.b[21][0]++;
						cov_h0tmammvh.s[71]++;

						$scope.documents.itemDocument.url = response.url;
				} else {
						cov_h0tmammvh.b[21][1]++;
				}

				cov_h0tmammvh.s[72]++;
				$scope.loading_file = false;
		};

		cov_h0tmammvh.s[73]++;
		uploaderFile.onErrorItem = function (fileItem) {
				cov_h0tmammvh.f[10]++;
				cov_h0tmammvh.s[74]++;

				$scope.loading_file = false;
		};

		cov_h0tmammvh.s[75]++;
		$scope.getCodeError = function (item) {
				cov_h0tmammvh.f[11]++;
				cov_h0tmammvh.s[76]++;

				return $translate.instant('COMMON.ERROR.CODE_ERROR', { value: item });
		};

		cov_h0tmammvh.s[77]++;
		$scope.loadImage = function () {
				cov_h0tmammvh.f[12]++;
				cov_h0tmammvh.s[78]++;

				$('#loadimage').trigger('click');
		};

		//Exec
		cov_h0tmammvh.s[79]++;
		$scope.initOverview();
		cov_h0tmammvh.s[80]++;
		$scope.documents.getAll();
});
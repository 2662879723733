'use strict';

var cov_2db9wedv66 = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/user/privileges/privilegesCtrl.js',
      hash = '4f3e2cd2977a33ee44db71c189607cdd75a1bfc9',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/user/privileges/privilegesCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 5,
          column: 0
        },
        end: {
          line: 338,
          column: 3
        }
      },
      '1': {
        start: {
          line: 6,
          column: 20
        },
        end: {
          line: 6,
          column: 26
        }
      },
      '2': {
        start: {
          line: 7,
          column: 21
        },
        end: {
          line: 7,
          column: 29
        }
      },
      '3': {
        start: {
          line: 8,
          column: 22
        },
        end: {
          line: 8,
          column: 31
        }
      },
      '4': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 31,
          column: 4
        }
      },
      '5': {
        start: {
          line: 11,
          column: 6
        },
        end: {
          line: 11,
          column: 30
        }
      },
      '6': {
        start: {
          line: 12,
          column: 6
        },
        end: {
          line: 23,
          column: 9
        }
      },
      '7': {
        start: {
          line: 13,
          column: 8
        },
        end: {
          line: 21,
          column: 11
        }
      },
      '8': {
        start: {
          line: 14,
          column: 10
        },
        end: {
          line: 14,
          column: 61
        }
      },
      '9': {
        start: {
          line: 16,
          column: 10
        },
        end: {
          line: 16,
          column: 56
        }
      },
      '10': {
        start: {
          line: 17,
          column: 10
        },
        end: {
          line: 19,
          column: 11
        }
      },
      '11': {
        start: {
          line: 18,
          column: 12
        },
        end: {
          line: 18,
          column: 33
        }
      },
      '12': {
        start: {
          line: 20,
          column: 10
        },
        end: {
          line: 20,
          column: 22
        }
      },
      '13': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 22,
          column: 33
        }
      },
      '14': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 29,
          column: 7
        }
      },
      '15': {
        start: {
          line: 28,
          column: 8
        },
        end: {
          line: 28,
          column: 84
        }
      },
      '16': {
        start: {
          line: 32,
          column: 2
        },
        end: {
          line: 35,
          column: 4
        }
      },
      '17': {
        start: {
          line: 36,
          column: 2
        },
        end: {
          line: 298,
          column: 4
        }
      },
      '18': {
        start: {
          line: 41,
          column: 20
        },
        end: {
          line: 41,
          column: 39
        }
      },
      '19': {
        start: {
          line: 42,
          column: 6
        },
        end: {
          line: 60,
          column: 7
        }
      },
      '20': {
        start: {
          line: 43,
          column: 8
        },
        end: {
          line: 59,
          column: 9
        }
      },
      '21': {
        start: {
          line: 44,
          column: 10
        },
        end: {
          line: 46,
          column: 11
        }
      },
      '22': {
        start: {
          line: 45,
          column: 12
        },
        end: {
          line: 45,
          column: 21
        }
      },
      '23': {
        start: {
          line: 48,
          column: 10
        },
        end: {
          line: 58,
          column: 11
        }
      },
      '24': {
        start: {
          line: 49,
          column: 12
        },
        end: {
          line: 51,
          column: 13
        }
      },
      '25': {
        start: {
          line: 50,
          column: 14
        },
        end: {
          line: 50,
          column: 64
        }
      },
      '26': {
        start: {
          line: 52,
          column: 12
        },
        end: {
          line: 52,
          column: 71
        }
      },
      '27': {
        start: {
          line: 54,
          column: 12
        },
        end: {
          line: 56,
          column: 13
        }
      },
      '28': {
        start: {
          line: 55,
          column: 14
        },
        end: {
          line: 55,
          column: 81
        }
      },
      '29': {
        start: {
          line: 57,
          column: 12
        },
        end: {
          line: 57,
          column: 88
        }
      },
      '30': {
        start: {
          line: 63,
          column: 6
        },
        end: {
          line: 93,
          column: 9
        }
      },
      '31': {
        start: {
          line: 75,
          column: 8
        },
        end: {
          line: 77,
          column: 9
        }
      },
      '32': {
        start: {
          line: 76,
          column: 10
        },
        end: {
          line: 76,
          column: 17
        }
      },
      '33': {
        start: {
          line: 78,
          column: 8
        },
        end: {
          line: 78,
          column: 32
        }
      },
      '34': {
        start: {
          line: 79,
          column: 8
        },
        end: {
          line: 79,
          column: 35
        }
      },
      '35': {
        start: {
          line: 80,
          column: 8
        },
        end: {
          line: 80,
          column: 35
        }
      },
      '36': {
        start: {
          line: 81,
          column: 8
        },
        end: {
          line: 92,
          column: 11
        }
      },
      '37': {
        start: {
          line: 82,
          column: 10
        },
        end: {
          line: 89,
          column: 11
        }
      },
      '38': {
        start: {
          line: 83,
          column: 12
        },
        end: {
          line: 86,
          column: 15
        }
      },
      '39': {
        start: {
          line: 87,
          column: 12
        },
        end: {
          line: 87,
          column: 37
        }
      },
      '40': {
        start: {
          line: 88,
          column: 12
        },
        end: {
          line: 88,
          column: 19
        }
      },
      '41': {
        start: {
          line: 90,
          column: 10
        },
        end: {
          line: 90,
          column: 34
        }
      },
      '42': {
        start: {
          line: 91,
          column: 10
        },
        end: {
          line: 91,
          column: 34
        }
      },
      '43': {
        start: {
          line: 96,
          column: 6
        },
        end: {
          line: 96,
          column: 31
        }
      },
      '44': {
        start: {
          line: 100,
          column: 6
        },
        end: {
          line: 100,
          column: 30
        }
      },
      '45': {
        start: {
          line: 101,
          column: 6
        },
        end: {
          line: 101,
          column: 29
        }
      },
      '46': {
        start: {
          line: 102,
          column: 6
        },
        end: {
          line: 108,
          column: 9
        }
      },
      '47': {
        start: {
          line: 103,
          column: 8
        },
        end: {
          line: 105,
          column: 9
        }
      },
      '48': {
        start: {
          line: 104,
          column: 10
        },
        end: {
          line: 104,
          column: 125
        }
      },
      '49': {
        start: {
          line: 106,
          column: 8
        },
        end: {
          line: 106,
          column: 35
        }
      },
      '50': {
        start: {
          line: 107,
          column: 8
        },
        end: {
          line: 107,
          column: 33
        }
      },
      '51': {
        start: {
          line: 111,
          column: 6
        },
        end: {
          line: 117,
          column: 16
        }
      },
      '52': {
        start: {
          line: 120,
          column: 6
        },
        end: {
          line: 122,
          column: 7
        }
      },
      '53': {
        start: {
          line: 121,
          column: 8
        },
        end: {
          line: 121,
          column: 15
        }
      },
      '54': {
        start: {
          line: 123,
          column: 6
        },
        end: {
          line: 123,
          column: 40
        }
      },
      '55': {
        start: {
          line: 124,
          column: 6
        },
        end: {
          line: 124,
          column: 40
        }
      },
      '56': {
        start: {
          line: 125,
          column: 19
        },
        end: {
          line: 125,
          column: 50
        }
      },
      '57': {
        start: {
          line: 128,
          column: 6
        },
        end: {
          line: 136,
          column: 7
        }
      },
      '58': {
        start: {
          line: 129,
          column: 22
        },
        end: {
          line: 129,
          column: 47
        }
      },
      '59': {
        start: {
          line: 131,
          column: 18
        },
        end: {
          line: 131,
          column: 78
        }
      },
      '60': {
        start: {
          line: 132,
          column: 8
        },
        end: {
          line: 134,
          column: 9
        }
      },
      '61': {
        start: {
          line: 133,
          column: 10
        },
        end: {
          line: 133,
          column: 19
        }
      },
      '62': {
        start: {
          line: 135,
          column: 8
        },
        end: {
          line: 135,
          column: 45
        }
      },
      '63': {
        start: {
          line: 138,
          column: 6
        },
        end: {
          line: 144,
          column: 7
        }
      },
      '64': {
        start: {
          line: 139,
          column: 22
        },
        end: {
          line: 139,
          column: 47
        }
      },
      '65': {
        start: {
          line: 140,
          column: 8
        },
        end: {
          line: 142,
          column: 9
        }
      },
      '66': {
        start: {
          line: 141,
          column: 10
        },
        end: {
          line: 141,
          column: 19
        }
      },
      '67': {
        start: {
          line: 143,
          column: 8
        },
        end: {
          line: 143,
          column: 35
        }
      },
      '68': {
        start: {
          line: 145,
          column: 6
        },
        end: {
          line: 145,
          column: 40
        }
      },
      '69': {
        start: {
          line: 146,
          column: 6
        },
        end: {
          line: 146,
          column: 40
        }
      },
      '70': {
        start: {
          line: 147,
          column: 6
        },
        end: {
          line: 149,
          column: 7
        }
      },
      '71': {
        start: {
          line: 148,
          column: 8
        },
        end: {
          line: 148,
          column: 32
        }
      },
      '72': {
        start: {
          line: 150,
          column: 6
        },
        end: {
          line: 164,
          column: 9
        }
      },
      '73': {
        start: {
          line: 151,
          column: 8
        },
        end: {
          line: 151,
          column: 33
        }
      },
      '74': {
        start: {
          line: 152,
          column: 8
        },
        end: {
          line: 162,
          column: 9
        }
      },
      '75': {
        start: {
          line: 153,
          column: 10
        },
        end: {
          line: 158,
          column: 11
        }
      },
      '76': {
        start: {
          line: 154,
          column: 12
        },
        end: {
          line: 157,
          column: 13
        }
      },
      '77': {
        start: {
          line: 155,
          column: 14
        },
        end: {
          line: 155,
          column: 46
        }
      },
      '78': {
        start: {
          line: 156,
          column: 14
        },
        end: {
          line: 156,
          column: 20
        }
      },
      '79': {
        start: {
          line: 160,
          column: 10
        },
        end: {
          line: 160,
          column: 25
        }
      },
      '80': {
        start: {
          line: 161,
          column: 10
        },
        end: {
          line: 161,
          column: 41
        }
      },
      '81': {
        start: {
          line: 163,
          column: 8
        },
        end: {
          line: 163,
          column: 43
        }
      },
      '82': {
        start: {
          line: 167,
          column: 6
        },
        end: {
          line: 167,
          column: 44
        }
      },
      '83': {
        start: {
          line: 168,
          column: 6
        },
        end: {
          line: 249,
          column: 7
        }
      },
      '84': {
        start: {
          line: 169,
          column: 8
        },
        end: {
          line: 248,
          column: 11
        }
      },
      '85': {
        start: {
          line: 250,
          column: 6
        },
        end: {
          line: 250,
          column: 45
        }
      },
      '86': {
        start: {
          line: 251,
          column: 6
        },
        end: {
          line: 251,
          column: 72
        }
      },
      '87': {
        start: {
          line: 252,
          column: 6
        },
        end: {
          line: 252,
          column: 72
        }
      },
      '88': {
        start: {
          line: 254,
          column: 6
        },
        end: {
          line: 254,
          column: 46
        }
      },
      '89': {
        start: {
          line: 255,
          column: 19
        },
        end: {
          line: 255,
          column: 21
        }
      },
      '90': {
        start: {
          line: 256,
          column: 19
        },
        end: {
          line: 256,
          column: 57
        }
      },
      '91': {
        start: {
          line: 257,
          column: 6
        },
        end: {
          line: 260,
          column: 7
        }
      },
      '92': {
        start: {
          line: 258,
          column: 18
        },
        end: {
          line: 258,
          column: 31
        }
      },
      '93': {
        start: {
          line: 259,
          column: 8
        },
        end: {
          line: 259,
          column: 26
        }
      },
      '94': {
        start: {
          line: 261,
          column: 6
        },
        end: {
          line: 288,
          column: 7
        }
      },
      '95': {
        start: {
          line: 262,
          column: 8
        },
        end: {
          line: 287,
          column: 9
        }
      },
      '96': {
        start: {
          line: 264,
          column: 12
        },
        end: {
          line: 264,
          column: 74
        }
      },
      '97': {
        start: {
          line: 265,
          column: 12
        },
        end: {
          line: 265,
          column: 18
        }
      },
      '98': {
        start: {
          line: 267,
          column: 12
        },
        end: {
          line: 267,
          column: 73
        }
      },
      '99': {
        start: {
          line: 268,
          column: 12
        },
        end: {
          line: 268,
          column: 18
        }
      },
      '100': {
        start: {
          line: 270,
          column: 12
        },
        end: {
          line: 270,
          column: 73
        }
      },
      '101': {
        start: {
          line: 271,
          column: 12
        },
        end: {
          line: 271,
          column: 18
        }
      },
      '102': {
        start: {
          line: 273,
          column: 12
        },
        end: {
          line: 273,
          column: 73
        }
      },
      '103': {
        start: {
          line: 274,
          column: 12
        },
        end: {
          line: 274,
          column: 18
        }
      },
      '104': {
        start: {
          line: 276,
          column: 12
        },
        end: {
          line: 276,
          column: 73
        }
      },
      '105': {
        start: {
          line: 277,
          column: 12
        },
        end: {
          line: 277,
          column: 18
        }
      },
      '106': {
        start: {
          line: 279,
          column: 12
        },
        end: {
          line: 279,
          column: 72
        }
      },
      '107': {
        start: {
          line: 280,
          column: 12
        },
        end: {
          line: 280,
          column: 18
        }
      },
      '108': {
        start: {
          line: 282,
          column: 12
        },
        end: {
          line: 282,
          column: 72
        }
      },
      '109': {
        start: {
          line: 283,
          column: 12
        },
        end: {
          line: 283,
          column: 18
        }
      },
      '110': {
        start: {
          line: 285,
          column: 12
        },
        end: {
          line: 285,
          column: 75
        }
      },
      '111': {
        start: {
          line: 286,
          column: 12
        },
        end: {
          line: 286,
          column: 18
        }
      },
      '112': {
        start: {
          line: 290,
          column: 6
        },
        end: {
          line: 290,
          column: 46
        }
      },
      '113': {
        start: {
          line: 291,
          column: 6
        },
        end: {
          line: 293,
          column: 7
        }
      },
      '114': {
        start: {
          line: 292,
          column: 8
        },
        end: {
          line: 292,
          column: 54
        }
      },
      '115': {
        start: {
          line: 296,
          column: 6
        },
        end: {
          line: 296,
          column: 31
        }
      },
      '116': {
        start: {
          line: 300,
          column: 2
        },
        end: {
          line: 300,
          column: 33
        }
      },
      '117': {
        start: {
          line: 302,
          column: 2
        },
        end: {
          line: 337,
          column: 5
        }
      },
      '118': {
        start: {
          line: 303,
          column: 24
        },
        end: {
          line: 305,
          column: 6
        }
      },
      '119': {
        start: {
          line: 304,
          column: 6
        },
        end: {
          line: 304,
          column: 36
        }
      },
      '120': {
        start: {
          line: 307,
          column: 4
        },
        end: {
          line: 310,
          column: 5
        }
      },
      '121': {
        start: {
          line: 308,
          column: 6
        },
        end: {
          line: 308,
          column: 35
        }
      },
      '122': {
        start: {
          line: 309,
          column: 6
        },
        end: {
          line: 309,
          column: 71
        }
      },
      '123': {
        start: {
          line: 311,
          column: 4
        },
        end: {
          line: 327,
          column: 7
        }
      },
      '124': {
        start: {
          line: 312,
          column: 6
        },
        end: {
          line: 314,
          column: 7
        }
      },
      '125': {
        start: {
          line: 313,
          column: 8
        },
        end: {
          line: 313,
          column: 18
        }
      },
      '126': {
        start: {
          line: 315,
          column: 6
        },
        end: {
          line: 317,
          column: 7
        }
      },
      '127': {
        start: {
          line: 316,
          column: 8
        },
        end: {
          line: 316,
          column: 17
        }
      },
      '128': {
        start: {
          line: 318,
          column: 6
        },
        end: {
          line: 320,
          column: 7
        }
      },
      '129': {
        start: {
          line: 319,
          column: 8
        },
        end: {
          line: 319,
          column: 18
        }
      },
      '130': {
        start: {
          line: 322,
          column: 6
        },
        end: {
          line: 324,
          column: 7
        }
      },
      '131': {
        start: {
          line: 323,
          column: 8
        },
        end: {
          line: 323,
          column: 17
        }
      },
      '132': {
        start: {
          line: 326,
          column: 6
        },
        end: {
          line: 326,
          column: 15
        }
      },
      '133': {
        start: {
          line: 328,
          column: 4
        },
        end: {
          line: 330,
          column: 7
        }
      },
      '134': {
        start: {
          line: 329,
          column: 6
        },
        end: {
          line: 329,
          column: 85
        }
      },
      '135': {
        start: {
          line: 332,
          column: 4
        },
        end: {
          line: 334,
          column: 5
        }
      },
      '136': {
        start: {
          line: 333,
          column: 6
        },
        end: {
          line: 333,
          column: 50
        }
      },
      '137': {
        start: {
          line: 336,
          column: 4
        },
        end: {
          line: 336,
          column: 28
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 5,
            column: 51
          },
          end: {
            line: 5,
            column: 52
          }
        },
        loc: {
          start: {
            line: 5,
            column: 166
          },
          end: {
            line: 338,
            column: 1
          }
        },
        line: 5
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 12,
            column: 56
          },
          end: {
            line: 12,
            column: 57
          }
        },
        loc: {
          start: {
            line: 12,
            column: 64
          },
          end: {
            line: 23,
            column: 7
          }
        },
        line: 12
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 13,
            column: 43
          },
          end: {
            line: 13,
            column: 44
          }
        },
        loc: {
          start: {
            line: 13,
            column: 51
          },
          end: {
            line: 21,
            column: 9
          }
        },
        line: 13
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 74,
            column: 9
          },
          end: {
            line: 74,
            column: 10
          }
        },
        loc: {
          start: {
            line: 74,
            column: 22
          },
          end: {
            line: 93,
            column: 7
          }
        },
        line: 74
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 81,
            column: 89
          },
          end: {
            line: 81,
            column: 90
          }
        },
        loc: {
          start: {
            line: 81,
            column: 97
          },
          end: {
            line: 92,
            column: 9
          }
        },
        line: 81
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 102,
            column: 53
          },
          end: {
            line: 102,
            column: 54
          }
        },
        loc: {
          start: {
            line: 102,
            column: 61
          },
          end: {
            line: 108,
            column: 7
          }
        },
        line: 102
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 150,
            column: 138
          },
          end: {
            line: 150,
            column: 139
          }
        },
        loc: {
          start: {
            line: 150,
            column: 146
          },
          end: {
            line: 164,
            column: 7
          }
        },
        line: 150
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 302,
            column: 69
          },
          end: {
            line: 302,
            column: 70
          }
        },
        loc: {
          start: {
            line: 302,
            column: 77
          },
          end: {
            line: 337,
            column: 3
          }
        },
        line: 302
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 303,
            column: 34
          },
          end: {
            line: 303,
            column: 35
          }
        },
        loc: {
          start: {
            line: 303,
            column: 45
          },
          end: {
            line: 305,
            column: 5
          }
        },
        line: 303
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 311,
            column: 36
          },
          end: {
            line: 311,
            column: 37
          }
        },
        loc: {
          start: {
            line: 311,
            column: 60
          },
          end: {
            line: 327,
            column: 5
          }
        },
        line: 311
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 328,
            column: 65
          },
          end: {
            line: 328,
            column: 66
          }
        },
        loc: {
          start: {
            line: 328,
            column: 76
          },
          end: {
            line: 330,
            column: 5
          }
        },
        line: 328
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 17,
            column: 10
          },
          end: {
            line: 19,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 17,
            column: 10
          },
          end: {
            line: 19,
            column: 11
          }
        }, {
          start: {
            line: 17,
            column: 10
          },
          end: {
            line: 19,
            column: 11
          }
        }],
        line: 17
      },
      '1': {
        loc: {
          start: {
            line: 44,
            column: 10
          },
          end: {
            line: 46,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 44,
            column: 10
          },
          end: {
            line: 46,
            column: 11
          }
        }, {
          start: {
            line: 44,
            column: 10
          },
          end: {
            line: 46,
            column: 11
          }
        }],
        line: 44
      },
      '2': {
        loc: {
          start: {
            line: 48,
            column: 10
          },
          end: {
            line: 58,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 48,
            column: 10
          },
          end: {
            line: 58,
            column: 11
          }
        }, {
          start: {
            line: 48,
            column: 10
          },
          end: {
            line: 58,
            column: 11
          }
        }],
        line: 48
      },
      '3': {
        loc: {
          start: {
            line: 49,
            column: 12
          },
          end: {
            line: 51,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 49,
            column: 12
          },
          end: {
            line: 51,
            column: 13
          }
        }, {
          start: {
            line: 49,
            column: 12
          },
          end: {
            line: 51,
            column: 13
          }
        }],
        line: 49
      },
      '4': {
        loc: {
          start: {
            line: 54,
            column: 12
          },
          end: {
            line: 56,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 54,
            column: 12
          },
          end: {
            line: 56,
            column: 13
          }
        }, {
          start: {
            line: 54,
            column: 12
          },
          end: {
            line: 56,
            column: 13
          }
        }],
        line: 54
      },
      '5': {
        loc: {
          start: {
            line: 75,
            column: 8
          },
          end: {
            line: 77,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 75,
            column: 8
          },
          end: {
            line: 77,
            column: 9
          }
        }, {
          start: {
            line: 75,
            column: 8
          },
          end: {
            line: 77,
            column: 9
          }
        }],
        line: 75
      },
      '6': {
        loc: {
          start: {
            line: 82,
            column: 10
          },
          end: {
            line: 89,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 82,
            column: 10
          },
          end: {
            line: 89,
            column: 11
          }
        }, {
          start: {
            line: 82,
            column: 10
          },
          end: {
            line: 89,
            column: 11
          }
        }],
        line: 82
      },
      '7': {
        loc: {
          start: {
            line: 104,
            column: 31
          },
          end: {
            line: 104,
            column: 124
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 104,
            column: 79
          },
          end: {
            line: 104,
            column: 103
          }
        }, {
          start: {
            line: 104,
            column: 106
          },
          end: {
            line: 104,
            column: 124
          }
        }],
        line: 104
      },
      '8': {
        loc: {
          start: {
            line: 120,
            column: 6
          },
          end: {
            line: 122,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 120,
            column: 6
          },
          end: {
            line: 122,
            column: 7
          }
        }, {
          start: {
            line: 120,
            column: 6
          },
          end: {
            line: 122,
            column: 7
          }
        }],
        line: 120
      },
      '9': {
        loc: {
          start: {
            line: 131,
            column: 19
          },
          end: {
            line: 131,
            column: 35
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 131,
            column: 30
          },
          end: {
            line: 131,
            column: 31
          }
        }, {
          start: {
            line: 131,
            column: 34
          },
          end: {
            line: 131,
            column: 35
          }
        }],
        line: 131
      },
      '10': {
        loc: {
          start: {
            line: 131,
            column: 40
          },
          end: {
            line: 131,
            column: 56
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 131,
            column: 51
          },
          end: {
            line: 131,
            column: 52
          }
        }, {
          start: {
            line: 131,
            column: 55
          },
          end: {
            line: 131,
            column: 56
          }
        }],
        line: 131
      },
      '11': {
        loc: {
          start: {
            line: 131,
            column: 61
          },
          end: {
            line: 131,
            column: 77
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 131,
            column: 72
          },
          end: {
            line: 131,
            column: 73
          }
        }, {
          start: {
            line: 131,
            column: 76
          },
          end: {
            line: 131,
            column: 77
          }
        }],
        line: 131
      },
      '12': {
        loc: {
          start: {
            line: 132,
            column: 8
          },
          end: {
            line: 134,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 132,
            column: 8
          },
          end: {
            line: 134,
            column: 9
          }
        }, {
          start: {
            line: 132,
            column: 8
          },
          end: {
            line: 134,
            column: 9
          }
        }],
        line: 132
      },
      '13': {
        loc: {
          start: {
            line: 140,
            column: 8
          },
          end: {
            line: 142,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 140,
            column: 8
          },
          end: {
            line: 142,
            column: 9
          }
        }, {
          start: {
            line: 140,
            column: 8
          },
          end: {
            line: 142,
            column: 9
          }
        }],
        line: 140
      },
      '14': {
        loc: {
          start: {
            line: 147,
            column: 6
          },
          end: {
            line: 149,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 147,
            column: 6
          },
          end: {
            line: 149,
            column: 7
          }
        }, {
          start: {
            line: 147,
            column: 6
          },
          end: {
            line: 149,
            column: 7
          }
        }],
        line: 147
      },
      '15': {
        loc: {
          start: {
            line: 152,
            column: 8
          },
          end: {
            line: 162,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 152,
            column: 8
          },
          end: {
            line: 162,
            column: 9
          }
        }, {
          start: {
            line: 152,
            column: 8
          },
          end: {
            line: 162,
            column: 9
          }
        }],
        line: 152
      },
      '16': {
        loc: {
          start: {
            line: 154,
            column: 12
          },
          end: {
            line: 157,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 154,
            column: 12
          },
          end: {
            line: 157,
            column: 13
          }
        }, {
          start: {
            line: 154,
            column: 12
          },
          end: {
            line: 157,
            column: 13
          }
        }],
        line: 154
      },
      '17': {
        loc: {
          start: {
            line: 168,
            column: 6
          },
          end: {
            line: 249,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 168,
            column: 6
          },
          end: {
            line: 249,
            column: 7
          }
        }, {
          start: {
            line: 168,
            column: 6
          },
          end: {
            line: 249,
            column: 7
          }
        }],
        line: 168
      },
      '18': {
        loc: {
          start: {
            line: 205,
            column: 19
          },
          end: {
            line: 205,
            column: 154
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 205,
            column: 55
          },
          end: {
            line: 205,
            column: 149
          }
        }, {
          start: {
            line: 205,
            column: 152
          },
          end: {
            line: 205,
            column: 154
          }
        }],
        line: 205
      },
      '19': {
        loc: {
          start: {
            line: 206,
            column: 20
          },
          end: {
            line: 206,
            column: 160
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 206,
            column: 59
          },
          end: {
            line: 206,
            column: 155
          }
        }, {
          start: {
            line: 206,
            column: 158
          },
          end: {
            line: 206,
            column: 160
          }
        }],
        line: 206
      },
      '20': {
        loc: {
          start: {
            line: 207,
            column: 20
          },
          end: {
            line: 207,
            column: 160
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 207,
            column: 59
          },
          end: {
            line: 207,
            column: 155
          }
        }, {
          start: {
            line: 207,
            column: 158
          },
          end: {
            line: 207,
            column: 160
          }
        }],
        line: 207
      },
      '21': {
        loc: {
          start: {
            line: 251,
            column: 37
          },
          end: {
            line: 251,
            column: 70
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 251,
            column: 37
          },
          end: {
            line: 251,
            column: 64
          }
        }, {
          start: {
            line: 251,
            column: 68
          },
          end: {
            line: 251,
            column: 70
          }
        }],
        line: 251
      },
      '22': {
        loc: {
          start: {
            line: 252,
            column: 37
          },
          end: {
            line: 252,
            column: 70
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 252,
            column: 37
          },
          end: {
            line: 252,
            column: 64
          }
        }, {
          start: {
            line: 252,
            column: 68
          },
          end: {
            line: 252,
            column: 70
          }
        }],
        line: 252
      },
      '23': {
        loc: {
          start: {
            line: 262,
            column: 8
          },
          end: {
            line: 287,
            column: 9
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 263,
            column: 10
          },
          end: {
            line: 265,
            column: 18
          }
        }, {
          start: {
            line: 266,
            column: 10
          },
          end: {
            line: 268,
            column: 18
          }
        }, {
          start: {
            line: 269,
            column: 10
          },
          end: {
            line: 271,
            column: 18
          }
        }, {
          start: {
            line: 272,
            column: 10
          },
          end: {
            line: 274,
            column: 18
          }
        }, {
          start: {
            line: 275,
            column: 10
          },
          end: {
            line: 277,
            column: 18
          }
        }, {
          start: {
            line: 278,
            column: 10
          },
          end: {
            line: 280,
            column: 18
          }
        }, {
          start: {
            line: 281,
            column: 10
          },
          end: {
            line: 283,
            column: 18
          }
        }, {
          start: {
            line: 284,
            column: 10
          },
          end: {
            line: 286,
            column: 18
          }
        }],
        line: 262
      },
      '24': {
        loc: {
          start: {
            line: 307,
            column: 4
          },
          end: {
            line: 310,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 307,
            column: 4
          },
          end: {
            line: 310,
            column: 5
          }
        }, {
          start: {
            line: 307,
            column: 4
          },
          end: {
            line: 310,
            column: 5
          }
        }],
        line: 307
      },
      '25': {
        loc: {
          start: {
            line: 312,
            column: 6
          },
          end: {
            line: 314,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 312,
            column: 6
          },
          end: {
            line: 314,
            column: 7
          }
        }, {
          start: {
            line: 312,
            column: 6
          },
          end: {
            line: 314,
            column: 7
          }
        }],
        line: 312
      },
      '26': {
        loc: {
          start: {
            line: 315,
            column: 6
          },
          end: {
            line: 317,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 315,
            column: 6
          },
          end: {
            line: 317,
            column: 7
          }
        }, {
          start: {
            line: 315,
            column: 6
          },
          end: {
            line: 317,
            column: 7
          }
        }],
        line: 315
      },
      '27': {
        loc: {
          start: {
            line: 318,
            column: 6
          },
          end: {
            line: 320,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 318,
            column: 6
          },
          end: {
            line: 320,
            column: 7
          }
        }, {
          start: {
            line: 318,
            column: 6
          },
          end: {
            line: 320,
            column: 7
          }
        }],
        line: 318
      },
      '28': {
        loc: {
          start: {
            line: 322,
            column: 6
          },
          end: {
            line: 324,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 322,
            column: 6
          },
          end: {
            line: 324,
            column: 7
          }
        }, {
          start: {
            line: 322,
            column: 6
          },
          end: {
            line: 324,
            column: 7
          }
        }],
        line: 322
      },
      '29': {
        loc: {
          start: {
            line: 329,
            column: 28
          },
          end: {
            line: 329,
            column: 80
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 329,
            column: 28
          },
          end: {
            line: 329,
            column: 69
          }
        }, {
          start: {
            line: 329,
            column: 73
          },
          end: {
            line: 329,
            column: 80
          }
        }],
        line: 329
      },
      '30': {
        loc: {
          start: {
            line: 329,
            column: 29
          },
          end: {
            line: 329,
            column: 60
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 329,
            column: 29
          },
          end: {
            line: 329,
            column: 54
          }
        }, {
          start: {
            line: 329,
            column: 58
          },
          end: {
            line: 329,
            column: 60
          }
        }],
        line: 329
      },
      '31': {
        loc: {
          start: {
            line: 332,
            column: 4
          },
          end: {
            line: 334,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 332,
            column: 4
          },
          end: {
            line: 334,
            column: 5
          }
        }, {
          start: {
            line: 332,
            column: 4
          },
          end: {
            line: 334,
            column: 5
          }
        }],
        line: 332
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0, 0, 0, 0, 0, 0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2db9wedv66.s[0]++;
/**
 * Created by BKSB on 16/04/2017.
 */

angular.module('app').controller('privilegesCtrl', function ($scope, $rootScope, SweetAlert, $translate, $deltaActor, $CRUDService, $otherHttpService, $deltahttp, $filter) {
  cov_2db9wedv66.f[0]++;

  var PATH_USER = (cov_2db9wedv66.s[1]++, 'User');
  var PATH_RIGHT = (cov_2db9wedv66.s[2]++, 'Droits');
  var PATH_PROFIL = (cov_2db9wedv66.s[3]++, 'Profils');
  cov_2db9wedv66.s[4]++;
  $scope.privileges = {
    getAllUser: function getAllUser() {
      cov_2db9wedv66.s[5]++;

      $scope.isloading = true;
      cov_2db9wedv66.s[6]++;
      $CRUDService.getAll(PATH_USER, { get: 'NonAdmin' }, function (data) {
        cov_2db9wedv66.f[1]++;
        cov_2db9wedv66.s[7]++;

        $scope.privileges.users = data.map(function (user) {
          cov_2db9wedv66.f[2]++;
          cov_2db9wedv66.s[8]++;

          user.imageUrl = $deltahttp.getUserPicture(user.id);
          // eslint-disable-next-line angular/json-functions
          cov_2db9wedv66.s[9]++;
          user.GROUP_USER = JSON.parse(user.GROUP_USER);
          cov_2db9wedv66.s[10]++;
          if (!user.GROUP_USER) {
            cov_2db9wedv66.b[0][0]++;
            cov_2db9wedv66.s[11]++;

            user.GROUP_USER = {};
          } else {
            cov_2db9wedv66.b[0][1]++;
          }
          cov_2db9wedv66.s[12]++;
          return user;
        });
        cov_2db9wedv66.s[13]++;
        $scope.isloading = false;
      });
    },

    checkedAllUser: false,
    checkAllUser: function checkAllUser() {
      cov_2db9wedv66.s[14]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = $scope.privileges.users[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;
          cov_2db9wedv66.s[15]++;

          $scope.profils.selectedItem.USER_LIST__TABLE[item.id] = this.checkedAllUser;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }
  };
  cov_2db9wedv66.s[16]++;
  $scope.__labels = {
    EDIT: $translate.instant('COMMON.EDIT'),
    DELETE: $translate.instant('COMMON.DELETE')
  };
  cov_2db9wedv66.s[17]++;
  $scope.profils = {
    data: null,
    selectedItem: null,

    checkAll: function checkAll(type) {
      var value = (cov_2db9wedv66.s[18]++, this.rightall[type]);
      cov_2db9wedv66.s[19]++;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = $scope.moduleList[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var module = _step2.value;
          cov_2db9wedv66.s[20]++;
          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = module.items[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var menu = _step3.value;
              cov_2db9wedv66.s[21]++;

              if (!menu.blocks[type]) {
                cov_2db9wedv66.b[1][0]++;
                cov_2db9wedv66.s[22]++;

                continue;
              } else {
                cov_2db9wedv66.b[1][1]++;
              }

              cov_2db9wedv66.s[23]++;
              if (menu.id_) {
                cov_2db9wedv66.b[2][0]++;
                cov_2db9wedv66.s[24]++;

                if (!this.selectedItem.RIGHT_STR__TABLE[menu.id_]) {
                  cov_2db9wedv66.b[3][0]++;
                  cov_2db9wedv66.s[25]++;

                  this.selectedItem.RIGHT_STR__TABLE[menu.id_] = {};
                } else {
                  cov_2db9wedv66.b[3][1]++;
                }
                cov_2db9wedv66.s[26]++;
                this.selectedItem.RIGHT_STR__TABLE[menu.id_][type] = value;
              } else {
                cov_2db9wedv66.b[2][1]++;
                cov_2db9wedv66.s[27]++;

                if (!this.selectedItem.RIGHT_STR__TABLE[module.id + '_' + menu.id]) {
                  cov_2db9wedv66.b[4][0]++;
                  cov_2db9wedv66.s[28]++;

                  this.selectedItem.RIGHT_STR__TABLE[module.id + '_' + menu.id] = {};
                } else {
                  cov_2db9wedv66.b[4][1]++;
                }
                cov_2db9wedv66.s[29]++;
                this.selectedItem.RIGHT_STR__TABLE[module.id + '_' + menu.id][type] = value;
              }
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    },
    deleteItem: function deleteItem(item) {
      cov_2db9wedv66.s[30]++;

      SweetAlert.swal({
        title: $translate.instant('COMMON.CONFIRM'),
        // text: $translate.instant("RIGHT.CONFIRM_DELETE"),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c8c8c8',

        confirmButtonText: $translate.instant('COMMON.NO'),
        cancelButtonText: $translate.instant('COMMON.YES'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isconfirm) {
        cov_2db9wedv66.f[3]++;
        cov_2db9wedv66.s[31]++;

        if (isconfirm) {
          cov_2db9wedv66.b[5][0]++;
          cov_2db9wedv66.s[32]++;

          return;
        } else {
          cov_2db9wedv66.b[5][1]++;
        }
        cov_2db9wedv66.s[33]++;
        $scope.isloading = true;
        cov_2db9wedv66.s[34]++;
        item.USER_LIST = undefined;
        cov_2db9wedv66.s[35]++;
        item.RIGHT_STR = undefined;
        cov_2db9wedv66.s[36]++;
        $CRUDService.delet(PATH_PROFIL, { action: 'supp', valeur: $filter('json')(item) }, function (data) {
          cov_2db9wedv66.f[4]++;
          cov_2db9wedv66.s[37]++;

          if (data < 0) {
            cov_2db9wedv66.b[6][0]++;
            cov_2db9wedv66.s[38]++;

            SweetAlert.swal({
              title: $translate.instant('COMMON.ERROR_DELETE'),
              text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
            });
            cov_2db9wedv66.s[39]++;
            $scope.isloading = false;
            cov_2db9wedv66.s[40]++;
            return;
          } else {
            cov_2db9wedv66.b[6][1]++;
          }
          cov_2db9wedv66.s[41]++;
          $scope.profils.cancel();
          cov_2db9wedv66.s[42]++;
          $scope.profils.getAll();
        });
      });
    },
    getAll: function getAll() {
      cov_2db9wedv66.s[43]++;

      $scope.profils.data = [];
      /* if (!$scope.privileges.selectedProject) {
        return;
      } */
      cov_2db9wedv66.s[44]++;
      $scope.isloading = true;
      cov_2db9wedv66.s[45]++;
      $scope.maxCodeSize = 0;
      cov_2db9wedv66.s[46]++;
      $CRUDService.getAll(PATH_PROFIL, { get: 'all' }, function (data) {
        cov_2db9wedv66.f[5]++;
        cov_2db9wedv66.s[47]++;
        var _iteratorNormalCompletion4 = true;
        var _didIteratorError4 = false;
        var _iteratorError4 = undefined;

        try {
          for (var _iterator4 = data[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
            var item = _step4.value;
            cov_2db9wedv66.s[48]++;

            $scope.maxCodeSize = item.LABEL_PROFIL.length > $scope.maxCodeSize ? (cov_2db9wedv66.b[7][0]++, item.LABEL_PROFIL.length) : (cov_2db9wedv66.b[7][1]++, $scope.maxCodeSize);
          }
        } catch (err) {
          _didIteratorError4 = true;
          _iteratorError4 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion4 && _iterator4.return) {
              _iterator4.return();
            }
          } finally {
            if (_didIteratorError4) {
              throw _iteratorError4;
            }
          }
        }

        cov_2db9wedv66.s[49]++;
        $scope.profils.data = data;
        cov_2db9wedv66.s[50]++;
        $scope.isloading = false;
      });
    },
    addItem: function addItem(label) {
      cov_2db9wedv66.s[51]++;

      this.edit({
        id: 0,
        LABEL_PROFIL: '',
        DESCRIPTION_PROFIL: '',
        USER_LIST: '',
        RIGHT_STR: ''
      }, label);
    },
    validate: function validate() {
      cov_2db9wedv66.s[52]++;

      if ($scope.isloading) {
        cov_2db9wedv66.b[8][0]++;
        cov_2db9wedv66.s[53]++;

        return;
      } else {
        cov_2db9wedv66.b[8][1]++;
      }
      cov_2db9wedv66.s[54]++;
      this.selectedItem.RIGHT_STR = ';';
      cov_2db9wedv66.s[55]++;
      this.selectedItem.USER_LIST = ';';
      var item = (cov_2db9wedv66.s[56]++, angular.copy(this.selectedItem));

      // Droit
      cov_2db9wedv66.s[57]++;
      for (var el in item.RIGHT_STR__TABLE) {
        var value = (cov_2db9wedv66.s[58]++, item.RIGHT_STR__TABLE[el]);

        var d = (cov_2db9wedv66.s[59]++, (value[0] ? (cov_2db9wedv66.b[9][0]++, 1) : (cov_2db9wedv66.b[9][1]++, 0)) + (value[1] ? (cov_2db9wedv66.b[10][0]++, 2) : (cov_2db9wedv66.b[10][1]++, 0)) + (value[2] ? (cov_2db9wedv66.b[11][0]++, 4) : (cov_2db9wedv66.b[11][1]++, 0)));
        cov_2db9wedv66.s[60]++;
        if (d == 0) {
          cov_2db9wedv66.b[12][0]++;
          cov_2db9wedv66.s[61]++;

          continue;
        } else {
          cov_2db9wedv66.b[12][1]++;
        }
        cov_2db9wedv66.s[62]++;
        item.RIGHT_STR += el + ':' + d + ';';
      }
      // User
      cov_2db9wedv66.s[63]++;
      for (var _el in item.USER_LIST__TABLE) {
        var _value = (cov_2db9wedv66.s[64]++, item.USER_LIST__TABLE[_el]);
        cov_2db9wedv66.s[65]++;
        if (!_value) {
          cov_2db9wedv66.b[13][0]++;
          cov_2db9wedv66.s[66]++;

          continue;
        } else {
          cov_2db9wedv66.b[13][1]++;
        }
        cov_2db9wedv66.s[67]++;
        item.USER_LIST += _el + ';';
      }
      cov_2db9wedv66.s[68]++;
      item.USER_LIST__TABLE = undefined;
      cov_2db9wedv66.s[69]++;
      item.RIGHT_STR__TABLE = undefined;
      cov_2db9wedv66.s[70]++;
      if (item.id == 0) {
        cov_2db9wedv66.b[14][0]++;
        cov_2db9wedv66.s[71]++;

        $scope.isloading = true;
      } else {
        cov_2db9wedv66.b[14][1]++;
      }
      cov_2db9wedv66.s[72]++;
      $CRUDService.save(PATH_PROFIL, { action: 'miseAJour', valeur: $filter('json')(item), p_bksb_: $scope.privileges.selectedProject.id }, function (data) {
        cov_2db9wedv66.f[6]++;
        cov_2db9wedv66.s[73]++;

        $scope.isloading = false;
        cov_2db9wedv66.s[74]++;
        if (item.id != 0) {
          cov_2db9wedv66.b[15][0]++;
          cov_2db9wedv66.s[75]++;

          for (var ind = $scope.profils.data.length - 1; ind >= 0; ind--) {
            cov_2db9wedv66.s[76]++;

            if ($scope.profils.data[ind].id == data) {
              cov_2db9wedv66.b[16][0]++;
              cov_2db9wedv66.s[77]++;

              $scope.profils.data[ind] = item;
              cov_2db9wedv66.s[78]++;
              break;
            } else {
              cov_2db9wedv66.b[16][1]++;
            }
          }
        } else {
          cov_2db9wedv66.b[15][1]++;
          cov_2db9wedv66.s[79]++;

          item.id = data;
          cov_2db9wedv66.s[80]++;
          $scope.profils.data.push(item);
        }
        cov_2db9wedv66.s[81]++;
        $scope.profils.selectedItem = null;
      });
    },
    edit: function edit(item, labelPL) {
      cov_2db9wedv66.s[82]++;

      this.rightall = [false, false, false];
      cov_2db9wedv66.s[83]++;
      if (!$scope.moduleList) {
        cov_2db9wedv66.b[17][0]++;
        cov_2db9wedv66.s[84]++;

        $scope.moduleList = [{
          id: 1,
          open: true,
          name: $translate.instant('MENU.PROJECT'),
          items: [{ id: 1, name: $translate.instant('MENU.PROJECT'), blocks: [false, true, true], portfolio: true }]
        }].concat([{
          id: 1,
          open: true,
          name: $translate.instant('MENU.D_B'),
          items: [{ id: 5, name: labelPL, blocks: [true, true, true] }, { id: 14, name: $translate.instant('MENU.AGENCIES'), blocks: [true, true, true] }, { id: 4, name: $translate.instant('MENU.P_G'), blocks: [true, true, true], portfolio: true }, { id: 3, name: $translate.instant('MENU.AC'), blocks: [true, true, true] }, { id: 8, name: $translate.instant('MENU.S_F'), blocks: [true, true, true], portfolio: true }, { id: 12, name: $translate.instant('MENU.FINANCING'), blocks: [true, true, true] }, { id: 11, name: $translate.instant('MENU.C_B'), blocks: [true, true, true] }, { id: 2, name: $translate.instant('MENU.UN'), blocks: [true, true, true] }, { id: 6, id_: '3_6', name: $translate.instant('MENU.C_I'), blocks: [true, true, true] }, { id: 6, name: $translate.instant('MENU.FL'), blocks: [true, true, true] }, { id: 7, name: $translate.instant('MENU.ALERT'), blocks: [true, true, false] }, { id: 15, name: $translate.instant('HEADER.TITLE'), blocks: [true, true, false] }]
        }, {
          id: 2,
          name: $translate.instant('MENU.PROJECT_MGT'),
          open: true,
          items: [{ id: 2, name: $translate.instant('MENU_SHORT.ACTIVITY_PLAN_BUDGET'), blocks: [true, true, false] }, { id: 5, name: $translate.instant('MENU_SHORT.FUNDS'), blocks: [true, true, true] }, { id: 4, name: $translate.instant('MENU.C_GANTT'), blocks: [true, true, true] }, { id: 7, name: $translate.instant('MENU.ACTIVITY_REPORT'), blocks: [true, true, true] }].concat($rootScope.complementModules.risk ? (cov_2db9wedv66.b[18][0]++, [{ id: 3, name: $translate.instant('MENU_SHORT.RISKS_MANAGEMENT'), blocks: [true, true, true] }]) : (cov_2db9wedv66.b[18][1]++, [])).concat($rootScope.complementModules.problem ? (cov_2db9wedv66.b[19][0]++, [{ id: 8, name: $translate.instant('MENU_SHORT.PROBLEM_MANAGEMENT'), blocks: [true, true, true] }]) : (cov_2db9wedv66.b[19][1]++, [])).concat($rootScope.complementModules.mission ? (cov_2db9wedv66.b[20][0]++, [{ id: 6, name: $translate.instant('MENU_SHORT.MISSION_MANAGEMENT'), blocks: [true, true, true] }]) : (cov_2db9wedv66.b[20][1]++, [])).concat([{ id: 9, id_: '5_1', name: $translate.instant('MENU.REPORT_PLAN'), blocks: [true, true, true] }, { id: 2, id_: '5_2', name: $translate.instant('MENU.REPORT_TRACKING'), blocks: [true, true, false] }])
        }, {
          id: 3,
          open: true,
          name: $translate.instant('MENU.P_CL'),
          items: [
          // {id: 1, name: $translate.instant('MENU.T_I'), blocks: [true, true, true]},
          { id: 2, name: $translate.instant('MENU.IN'), blocks: [true, true, true] }, { id: 3, name: $translate.instant('FRAME_RESULT.TITLE_1'), blocks: [true, false, false] }, { id: 7, name: $translate.instant('FRAME_RESULT.TITLE_2'), blocks: [true, false, false] }]
        }, {
          id: 6,
          open: true,
          name: $translate.instant('MENU.FORMULAR'),
          items: [{ id: 1, name: $translate.instant('MENU.DATA'), blocks: [true, true, true] /* ,
                                                                                             {id: 2, name: $translate.instant('MENU.COLLECT_DATA'), blocks: [true, false, false]},
                                                                                             {id: 3, name: $translate.instant('MENU.ANALYZE_DATA'), blocks: [true, true, true]} */
          }]
        }, {
          id: 4,
          open: true,
          name: $translate.instant('MENU.ANALYTICS'),
          items: [
          // {id: 1, name: $translate.instant('MENU.ANALYSE'), blocks: [true, true, true]},
          { id: 2, name: $translate.instant('MENU.DASHBOARD'), blocks: [true, true, true] /* ,
                                                                                          {id: 3, name: $translate.instant('MENU.ANALYTICS_GIS'), blocks: [true, false, false]} */
          }]
        }, {
          id: 5,
          open: true,
          name: $translate.instant('MENU.B_D'),
          items: [{ id: 3, name: $translate.instant('MENU.C_D'), blocks: [true, true, true] }, { id: 4, name: $translate.instant('MENU.PH'), blocks: [true, true, true] /* ,
                                                                                                                                                                        {id: 5, name: $translate.instant('MENU.COMMUNITIES'), blocks: [true, true, true]} */
          }]
        }]);
      } else {
        cov_2db9wedv66.b[17][1]++;
      }
      cov_2db9wedv66.s[85]++;
      this.selectedItem = angular.copy(item);
      cov_2db9wedv66.s[86]++;
      this.selectedItem.RIGHT_STR = (cov_2db9wedv66.b[21][0]++, this.selectedItem.RIGHT_STR) || (cov_2db9wedv66.b[21][1]++, '');
      cov_2db9wedv66.s[87]++;
      this.selectedItem.USER_LIST = (cov_2db9wedv66.b[22][0]++, this.selectedItem.USER_LIST) || (cov_2db9wedv66.b[22][1]++, '');
      // Parametrage des droits
      cov_2db9wedv66.s[88]++;
      this.selectedItem.RIGHT_STR__TABLE = {};
      var list = (cov_2db9wedv66.s[89]++, []);
      var data = (cov_2db9wedv66.s[90]++, this.selectedItem.RIGHT_STR.split(';'));
      cov_2db9wedv66.s[91]++;
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = data[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var _el2 = _step5.value;

          var c = (cov_2db9wedv66.s[92]++, _el2.split(':'));
          cov_2db9wedv66.s[93]++;
          list[c[0]] = c[1];
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      cov_2db9wedv66.s[94]++;
      for (var el in list) {
        cov_2db9wedv66.s[95]++;

        switch (eval(list[el])) {
          case 1:
            cov_2db9wedv66.b[23][0]++;
            cov_2db9wedv66.s[96]++;

            this.selectedItem.RIGHT_STR__TABLE[el] = [true, false, false];
            cov_2db9wedv66.s[97]++;
            break;
          case 2:
            cov_2db9wedv66.b[23][1]++;
            cov_2db9wedv66.s[98]++;

            this.selectedItem.RIGHT_STR__TABLE[el] = [true, true, false];
            cov_2db9wedv66.s[99]++;
            break;
          case 3:
            cov_2db9wedv66.b[23][2]++;
            cov_2db9wedv66.s[100]++;

            this.selectedItem.RIGHT_STR__TABLE[el] = [true, true, false];
            cov_2db9wedv66.s[101]++;
            break;
          case 4:
            cov_2db9wedv66.b[23][3]++;
            cov_2db9wedv66.s[102]++;

            this.selectedItem.RIGHT_STR__TABLE[el] = [true, false, true];
            cov_2db9wedv66.s[103]++;
            break;
          case 5:
            cov_2db9wedv66.b[23][4]++;
            cov_2db9wedv66.s[104]++;

            this.selectedItem.RIGHT_STR__TABLE[el] = [true, false, true];
            cov_2db9wedv66.s[105]++;
            break;
          case 6:
            cov_2db9wedv66.b[23][5]++;
            cov_2db9wedv66.s[106]++;

            this.selectedItem.RIGHT_STR__TABLE[el] = [true, true, true];
            cov_2db9wedv66.s[107]++;
            break;
          case 7:
            cov_2db9wedv66.b[23][6]++;
            cov_2db9wedv66.s[108]++;

            this.selectedItem.RIGHT_STR__TABLE[el] = [true, true, true];
            cov_2db9wedv66.s[109]++;
            break;
          default:
            cov_2db9wedv66.b[23][7]++;
            cov_2db9wedv66.s[110]++;

            this.selectedItem.RIGHT_STR__TABLE[el] = [false, false, false];
            cov_2db9wedv66.s[111]++;
            break;
        }
      }
      // parametrage des utilisateurs
      cov_2db9wedv66.s[112]++;
      this.selectedItem.USER_LIST__TABLE = {};
      cov_2db9wedv66.s[113]++;
      var _iteratorNormalCompletion6 = true;
      var _didIteratorError6 = false;
      var _iteratorError6 = undefined;

      try {
        for (var _iterator6 = this.selectedItem.USER_LIST.split(';')[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
          var _el3 = _step6.value;
          cov_2db9wedv66.s[114]++;

          this.selectedItem.USER_LIST__TABLE[_el3] = true;
        }
      } catch (err) {
        _didIteratorError6 = true;
        _iteratorError6 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion6 && _iterator6.return) {
            _iterator6.return();
          }
        } finally {
          if (_didIteratorError6) {
            throw _iteratorError6;
          }
        }
      }
    },
    cancel: function cancel() {
      cov_2db9wedv66.s[115]++;

      this.selectedItem = null;
    }
  };

  cov_2db9wedv66.s[116]++;
  $scope.privileges.getAllUser();

  cov_2db9wedv66.s[117]++;
  $CRUDService.getAll('Projet', { get: 'all_short', portfolio: true }, function (data) {
    cov_2db9wedv66.f[7]++;

    var baseProject = (cov_2db9wedv66.s[118]++, data.find(function (project) {
      cov_2db9wedv66.f[8]++;
      cov_2db9wedv66.s[119]++;

      return project.PORTFOLIO == 1;
    }));

    cov_2db9wedv66.s[120]++;
    if (baseProject) {
      cov_2db9wedv66.b[24][0]++;
      cov_2db9wedv66.s[121]++;

      baseProject.portfolio = true;
      cov_2db9wedv66.s[122]++;
      baseProject.LIBELLE_PROJET = $translate.instant('MENU_SHORT.DO');
    } else {
      cov_2db9wedv66.b[24][1]++;
    }
    cov_2db9wedv66.s[123]++;
    $scope.listProjects = data.sort(function (projectA, projectB) {
      cov_2db9wedv66.f[9]++;
      cov_2db9wedv66.s[124]++;

      if (projectA.portfolio) {
        cov_2db9wedv66.b[25][0]++;
        cov_2db9wedv66.s[125]++;

        return -1;
      } else {
        cov_2db9wedv66.b[25][1]++;
      }
      cov_2db9wedv66.s[126]++;
      if (projectB.portfolio) {
        cov_2db9wedv66.b[26][0]++;
        cov_2db9wedv66.s[127]++;

        return 1;
      } else {
        cov_2db9wedv66.b[26][1]++;
      }
      cov_2db9wedv66.s[128]++;
      if (projectA.CODE_PRJ > projectB.CODE_PRJ) {
        cov_2db9wedv66.b[27][0]++;
        cov_2db9wedv66.s[129]++;

        return -1;
      } else {
        cov_2db9wedv66.b[27][1]++;
      }

      cov_2db9wedv66.s[130]++;
      if (projectA.CODE_PRJ < projectB.CODE_PRJ) {
        cov_2db9wedv66.b[28][0]++;
        cov_2db9wedv66.s[131]++;

        return 1;
      } else {
        cov_2db9wedv66.b[28][1]++;
      }

      cov_2db9wedv66.s[132]++;
      return 0;
    });
    cov_2db9wedv66.s[133]++;
    $scope.privileges.selectedProject = $scope.listProjects.find(function (project) {
      cov_2db9wedv66.f[10]++;
      cov_2db9wedv66.s[134]++;

      return project.id == ((cov_2db9wedv66.b[29][0]++, ((cov_2db9wedv66.b[30][0]++, $rootScope.currentProject) || (cov_2db9wedv66.b[30][1]++, {})).project) || (cov_2db9wedv66.b[29][1]++, { id: 0 })).id;
    });

    cov_2db9wedv66.s[135]++;
    if (!$scope.privileges.selectedProject) {
      cov_2db9wedv66.b[31][0]++;
      cov_2db9wedv66.s[136]++;

      $scope.privileges.selectedProject = { id: 0 };
    } else {
      cov_2db9wedv66.b[31][1]++;
    }

    cov_2db9wedv66.s[137]++;
    $scope.profils.getAll();
  });
});
'use strict';

var cov_s3cqqww5u = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/missions/missionCtrl.js',
      hash = '51f2f444b2207f7b46aaf58fd17e19bf3597314e',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/missions/missionCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 349,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 3,
          column: 37
        }
      },
      '2': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '3': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 5,
          column: 11
        }
      },
      '4': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 27
        }
      },
      '5': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 10,
          column: 36
        }
      },
      '6': {
        start: {
          line: 12,
          column: 15
        },
        end: {
          line: 12,
          column: 24
        }
      },
      '7': {
        start: {
          line: 13,
          column: 19
        },
        end: {
          line: 13,
          column: 30
        }
      },
      '8': {
        start: {
          line: 14,
          column: 20
        },
        end: {
          line: 14,
          column: 34
        }
      },
      '9': {
        start: {
          line: 16,
          column: 18
        },
        end: {
          line: 16,
          column: 48
        }
      },
      '10': {
        start: {
          line: 17,
          column: 2
        },
        end: {
          line: 17,
          column: 43
        }
      },
      '11': {
        start: {
          line: 19,
          column: 2
        },
        end: {
          line: 19,
          column: 41
        }
      },
      '12': {
        start: {
          line: 21,
          column: 2
        },
        end: {
          line: 21,
          column: 18
        }
      },
      '13': {
        start: {
          line: 22,
          column: 2
        },
        end: {
          line: 22,
          column: 27
        }
      },
      '14': {
        start: {
          line: 24,
          column: 2
        },
        end: {
          line: 38,
          column: 5
        }
      },
      '15': {
        start: {
          line: 25,
          column: 4
        },
        end: {
          line: 37,
          column: 7
        }
      },
      '16': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 26,
          column: 36
        }
      },
      '17': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 27,
          column: 38
        }
      },
      '18': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 36,
          column: 7
        }
      },
      '19': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 35,
          column: 9
        }
      },
      '20': {
        start: {
          line: 30,
          column: 10
        },
        end: {
          line: 30,
          column: 73
        }
      },
      '21': {
        start: {
          line: 32,
          column: 10
        },
        end: {
          line: 34,
          column: 42
        }
      },
      '22': {
        start: {
          line: 33,
          column: 12
        },
        end: {
          line: 33,
          column: 68
        }
      },
      '23': {
        start: {
          line: 40,
          column: 2
        },
        end: {
          line: 45,
          column: 5
        }
      },
      '24': {
        start: {
          line: 41,
          column: 4
        },
        end: {
          line: 44,
          column: 7
        }
      },
      '25': {
        start: {
          line: 47,
          column: 2
        },
        end: {
          line: 47,
          column: 22
        }
      },
      '26': {
        start: {
          line: 49,
          column: 2
        },
        end: {
          line: 54,
          column: 5
        }
      },
      '27': {
        start: {
          line: 50,
          column: 4
        },
        end: {
          line: 52,
          column: 5
        }
      },
      '28': {
        start: {
          line: 51,
          column: 6
        },
        end: {
          line: 51,
          column: 13
        }
      },
      '29': {
        start: {
          line: 53,
          column: 4
        },
        end: {
          line: 53,
          column: 24
        }
      },
      '30': {
        start: {
          line: 56,
          column: 2
        },
        end: {
          line: 111,
          column: 4
        }
      },
      '31': {
        start: {
          line: 92,
          column: 6
        },
        end: {
          line: 94,
          column: 7
        }
      },
      '32': {
        start: {
          line: 93,
          column: 8
        },
        end: {
          line: 93,
          column: 42
        }
      },
      '33': {
        start: {
          line: 97,
          column: 6
        },
        end: {
          line: 100,
          column: 7
        }
      },
      '34': {
        start: {
          line: 98,
          column: 8
        },
        end: {
          line: 98,
          column: 29
        }
      },
      '35': {
        start: {
          line: 99,
          column: 8
        },
        end: {
          line: 99,
          column: 25
        }
      },
      '36': {
        start: {
          line: 101,
          column: 6
        },
        end: {
          line: 104,
          column: 7
        }
      },
      '37': {
        start: {
          line: 102,
          column: 8
        },
        end: {
          line: 102,
          column: 29
        }
      },
      '38': {
        start: {
          line: 103,
          column: 8
        },
        end: {
          line: 103,
          column: 40
        }
      },
      '39': {
        start: {
          line: 105,
          column: 6
        },
        end: {
          line: 108,
          column: 7
        }
      },
      '40': {
        start: {
          line: 106,
          column: 8
        },
        end: {
          line: 106,
          column: 29
        }
      },
      '41': {
        start: {
          line: 107,
          column: 8
        },
        end: {
          line: 107,
          column: 42
        }
      },
      '42': {
        start: {
          line: 113,
          column: 2
        },
        end: {
          line: 136,
          column: 5
        }
      },
      '43': {
        start: {
          line: 114,
          column: 4
        },
        end: {
          line: 114,
          column: 28
        }
      },
      '44': {
        start: {
          line: 115,
          column: 4
        },
        end: {
          line: 135,
          column: 7
        }
      },
      '45': {
        start: {
          line: 116,
          column: 6
        },
        end: {
          line: 133,
          column: 9
        }
      },
      '46': {
        start: {
          line: 117,
          column: 8
        },
        end: {
          line: 117,
          column: 52
        }
      },
      '47': {
        start: {
          line: 118,
          column: 8
        },
        end: {
          line: 118,
          column: 52
        }
      },
      '48': {
        start: {
          line: 119,
          column: 28
        },
        end: {
          line: 119,
          column: 54
        }
      },
      '49': {
        start: {
          line: 121,
          column: 26
        },
        end: {
          line: 123,
          column: 10
        }
      },
      '50': {
        start: {
          line: 122,
          column: 10
        },
        end: {
          line: 122,
          column: 43
        }
      },
      '51': {
        start: {
          line: 124,
          column: 8
        },
        end: {
          line: 129,
          column: 9
        }
      },
      '52': {
        start: {
          line: 125,
          column: 10
        },
        end: {
          line: 125,
          column: 40
        }
      },
      '53': {
        start: {
          line: 126,
          column: 10
        },
        end: {
          line: 126,
          column: 66
        }
      },
      '54': {
        start: {
          line: 128,
          column: 10
        },
        end: {
          line: 128,
          column: 32
        }
      },
      '55': {
        start: {
          line: 130,
          column: 8
        },
        end: {
          line: 130,
          column: 90
        }
      },
      '56': {
        start: {
          line: 130,
          column: 57
        },
        end: {
          line: 130,
          column: 75
        }
      },
      '57': {
        start: {
          line: 131,
          column: 8
        },
        end: {
          line: 131,
          column: 53
        }
      },
      '58': {
        start: {
          line: 132,
          column: 8
        },
        end: {
          line: 132,
          column: 20
        }
      },
      '59': {
        start: {
          line: 134,
          column: 6
        },
        end: {
          line: 134,
          column: 31
        }
      },
      '60': {
        start: {
          line: 138,
          column: 2
        },
        end: {
          line: 172,
          column: 4
        }
      },
      '61': {
        start: {
          line: 139,
          column: 4
        },
        end: {
          line: 141,
          column: 5
        }
      },
      '62': {
        start: {
          line: 140,
          column: 6
        },
        end: {
          line: 140,
          column: 19
        }
      },
      '63': {
        start: {
          line: 142,
          column: 4
        },
        end: {
          line: 144,
          column: 5
        }
      },
      '64': {
        start: {
          line: 143,
          column: 6
        },
        end: {
          line: 143,
          column: 19
        }
      },
      '65': {
        start: {
          line: 146,
          column: 4
        },
        end: {
          line: 148,
          column: 5
        }
      },
      '66': {
        start: {
          line: 147,
          column: 6
        },
        end: {
          line: 147,
          column: 19
        }
      },
      '67': {
        start: {
          line: 150,
          column: 16
        },
        end: {
          line: 150,
          column: 43
        }
      },
      '68': {
        start: {
          line: 151,
          column: 16
        },
        end: {
          line: 151,
          column: 26
        }
      },
      '69': {
        start: {
          line: 152,
          column: 4
        },
        end: {
          line: 171,
          column: 5
        }
      },
      '70': {
        start: {
          line: 161,
          column: 8
        },
        end: {
          line: 165,
          column: 11
        }
      },
      '71': {
        start: {
          line: 162,
          column: 10
        },
        end: {
          line: 164,
          column: 11
        }
      },
      '72': {
        start: {
          line: 163,
          column: 12
        },
        end: {
          line: 163,
          column: 82
        }
      },
      '73': {
        start: {
          line: 166,
          column: 8
        },
        end: {
          line: 166,
          column: 14
        }
      },
      '74': {
        start: {
          line: 169,
          column: 8
        },
        end: {
          line: 169,
          column: 15
        }
      },
      '75': {
        start: {
          line: 173,
          column: 2
        },
        end: {
          line: 230,
          column: 5
        }
      },
      '76': {
        start: {
          line: 174,
          column: 4
        },
        end: {
          line: 189,
          column: 5
        }
      },
      '77': {
        start: {
          line: 175,
          column: 6
        },
        end: {
          line: 187,
          column: 9
        }
      },
      '78': {
        start: {
          line: 188,
          column: 6
        },
        end: {
          line: 188,
          column: 13
        }
      },
      '79': {
        start: {
          line: 191,
          column: 4
        },
        end: {
          line: 191,
          column: 47
        }
      },
      '80': {
        start: {
          line: 192,
          column: 4
        },
        end: {
          line: 192,
          column: 28
        }
      },
      '81': {
        start: {
          line: 193,
          column: 4
        },
        end: {
          line: 229,
          column: 7
        }
      },
      '82': {
        start: {
          line: 194,
          column: 6
        },
        end: {
          line: 194,
          column: 31
        }
      },
      '83': {
        start: {
          line: 196,
          column: 6
        },
        end: {
          line: 196,
          column: 75
        }
      },
      '84': {
        start: {
          line: 198,
          column: 6
        },
        end: {
          line: 200,
          column: 7
        }
      },
      '85': {
        start: {
          line: 199,
          column: 8
        },
        end: {
          line: 199,
          column: 117
        }
      },
      '86': {
        start: {
          line: 202,
          column: 6
        },
        end: {
          line: 202,
          column: 40
        }
      },
      '87': {
        start: {
          line: 204,
          column: 20
        },
        end: {
          line: 204,
          column: 53
        }
      },
      '88': {
        start: {
          line: 206,
          column: 6
        },
        end: {
          line: 210,
          column: 21
        }
      },
      '89': {
        start: {
          line: 207,
          column: 8
        },
        end: {
          line: 207,
          column: 44
        }
      },
      '90': {
        start: {
          line: 209,
          column: 8
        },
        end: {
          line: 209,
          column: 37
        }
      },
      '91': {
        start: {
          line: 212,
          column: 21
        },
        end: {
          line: 212,
          column: 52
        }
      },
      '92': {
        start: {
          line: 213,
          column: 6
        },
        end: {
          line: 217,
          column: 21
        }
      },
      '93': {
        start: {
          line: 214,
          column: 8
        },
        end: {
          line: 214,
          column: 45
        }
      },
      '94': {
        start: {
          line: 216,
          column: 8
        },
        end: {
          line: 216,
          column: 37
        }
      },
      '95': {
        start: {
          line: 219,
          column: 6
        },
        end: {
          line: 226,
          column: 9
        }
      },
      '96': {
        start: {
          line: 228,
          column: 6
        },
        end: {
          line: 228,
          column: 65
        }
      },
      '97': {
        start: {
          line: 232,
          column: 2
        },
        end: {
          line: 246,
          column: 5
        }
      },
      '98': {
        start: {
          line: 233,
          column: 4
        },
        end: {
          line: 245,
          column: 7
        }
      },
      '99': {
        start: {
          line: 248,
          column: 2
        },
        end: {
          line: 294,
          column: 5
        }
      },
      '100': {
        start: {
          line: 249,
          column: 4
        },
        end: {
          line: 249,
          column: 30
        }
      },
      '101': {
        start: {
          line: 250,
          column: 25
        },
        end: {
          line: 250,
          column: 43
        }
      },
      '102': {
        start: {
          line: 251,
          column: 4
        },
        end: {
          line: 253,
          column: 51
        }
      },
      '103': {
        start: {
          line: 252,
          column: 6
        },
        end: {
          line: 252,
          column: 52
        }
      },
      '104': {
        start: {
          line: 255,
          column: 4
        },
        end: {
          line: 257,
          column: 56
        }
      },
      '105': {
        start: {
          line: 256,
          column: 6
        },
        end: {
          line: 256,
          column: 51
        }
      },
      '106': {
        start: {
          line: 260,
          column: 4
        },
        end: {
          line: 260,
          column: 60
        }
      },
      '107': {
        start: {
          line: 261,
          column: 20
        },
        end: {
          line: 261,
          column: 22
        }
      },
      '108': {
        start: {
          line: 262,
          column: 4
        },
        end: {
          line: 273,
          column: 5
        }
      },
      '109': {
        start: {
          line: 263,
          column: 6
        },
        end: {
          line: 268,
          column: 9
        }
      },
      '110': {
        start: {
          line: 264,
          column: 8
        },
        end: {
          line: 266,
          column: 9
        }
      },
      '111': {
        start: {
          line: 265,
          column: 10
        },
        end: {
          line: 265,
          column: 48
        }
      },
      '112': {
        start: {
          line: 267,
          column: 8
        },
        end: {
          line: 267,
          column: 35
        }
      },
      '113': {
        start: {
          line: 270,
          column: 6
        },
        end: {
          line: 272,
          column: 9
        }
      },
      '114': {
        start: {
          line: 271,
          column: 8
        },
        end: {
          line: 271,
          column: 64
        }
      },
      '115': {
        start: {
          line: 274,
          column: 4
        },
        end: {
          line: 278,
          column: 7
        }
      },
      '116': {
        start: {
          line: 275,
          column: 6
        },
        end: {
          line: 275,
          column: 77
        }
      },
      '117': {
        start: {
          line: 275,
          column: 53
        },
        end: {
          line: 275,
          column: 74
        }
      },
      '118': {
        start: {
          line: 277,
          column: 6
        },
        end: {
          line: 277,
          column: 19
        }
      },
      '119': {
        start: {
          line: 280,
          column: 4
        },
        end: {
          line: 282,
          column: 5
        }
      },
      '120': {
        start: {
          line: 281,
          column: 6
        },
        end: {
          line: 281,
          column: 89
        }
      },
      '121': {
        start: {
          line: 284,
          column: 4
        },
        end: {
          line: 284,
          column: 39
        }
      },
      '122': {
        start: {
          line: 285,
          column: 4
        },
        end: {
          line: 293,
          column: 7
        }
      },
      '123': {
        start: {
          line: 296,
          column: 2
        },
        end: {
          line: 336,
          column: 5
        }
      },
      '124': {
        start: {
          line: 297,
          column: 4
        },
        end: {
          line: 335,
          column: 7
        }
      },
      '125': {
        start: {
          line: 309,
          column: 6
        },
        end: {
          line: 311,
          column: 7
        }
      },
      '126': {
        start: {
          line: 310,
          column: 8
        },
        end: {
          line: 310,
          column: 15
        }
      },
      '127': {
        start: {
          line: 312,
          column: 6
        },
        end: {
          line: 312,
          column: 30
        }
      },
      '128': {
        start: {
          line: 313,
          column: 27
        },
        end: {
          line: 316,
          column: 7
        }
      },
      '129': {
        start: {
          line: 323,
          column: 6
        },
        end: {
          line: 334,
          column: 9
        }
      },
      '130': {
        start: {
          line: 324,
          column: 8
        },
        end: {
          line: 333,
          column: 9
        }
      },
      '131': {
        start: {
          line: 325,
          column: 10
        },
        end: {
          line: 329,
          column: 13
        }
      },
      '132': {
        start: {
          line: 330,
          column: 10
        },
        end: {
          line: 330,
          column: 35
        }
      },
      '133': {
        start: {
          line: 332,
          column: 10
        },
        end: {
          line: 332,
          column: 26
        }
      },
      '134': {
        start: {
          line: 338,
          column: 2
        },
        end: {
          line: 340,
          column: 5
        }
      },
      '135': {
        start: {
          line: 339,
          column: 4
        },
        end: {
          line: 339,
          column: 31
        }
      },
      '136': {
        start: {
          line: 342,
          column: 2
        },
        end: {
          line: 346,
          column: 5
        }
      },
      '137': {
        start: {
          line: 343,
          column: 4
        },
        end: {
          line: 345,
          column: 7
        }
      },
      '138': {
        start: {
          line: 347,
          column: 2
        },
        end: {
          line: 347,
          column: 20
        }
      },
      '139': {
        start: {
          line: 348,
          column: 2
        },
        end: {
          line: 348,
          column: 18
        }
      },
      '140': {
        start: {
          line: 352,
          column: 19
        },
        end: {
          line: 352,
          column: 30
        }
      },
      '141': {
        start: {
          line: 353,
          column: 2
        },
        end: {
          line: 355,
          column: 5
        }
      },
      '142': {
        start: {
          line: 354,
          column: 4
        },
        end: {
          line: 354,
          column: 40
        }
      },
      '143': {
        start: {
          line: 356,
          column: 2
        },
        end: {
          line: 356,
          column: 31
        }
      },
      '144': {
        start: {
          line: 357,
          column: 2
        },
        end: {
          line: 374,
          column: 4
        }
      },
      '145': {
        start: {
          line: 362,
          column: 4
        },
        end: {
          line: 365,
          column: 5
        }
      },
      '146': {
        start: {
          line: 363,
          column: 6
        },
        end: {
          line: 363,
          column: 47
        }
      },
      '147': {
        start: {
          line: 364,
          column: 6
        },
        end: {
          line: 364,
          column: 13
        }
      },
      '148': {
        start: {
          line: 366,
          column: 4
        },
        end: {
          line: 373,
          column: 7
        }
      },
      '149': {
        start: {
          line: 367,
          column: 6
        },
        end: {
          line: 367,
          column: 22
        }
      },
      '150': {
        start: {
          line: 368,
          column: 6
        },
        end: {
          line: 368,
          column: 75
        }
      },
      '151': {
        start: {
          line: 370,
          column: 6
        },
        end: {
          line: 372,
          column: 7
        }
      },
      '152': {
        start: {
          line: 371,
          column: 8
        },
        end: {
          line: 371,
          column: 72
        }
      },
      '153': {
        start: {
          line: 378,
          column: 15
        },
        end: {
          line: 378,
          column: 24
        }
      },
      '154': {
        start: {
          line: 379,
          column: 2
        },
        end: {
          line: 379,
          column: 28
        }
      },
      '155': {
        start: {
          line: 380,
          column: 2
        },
        end: {
          line: 421,
          column: 4
        }
      },
      '156': {
        start: {
          line: 381,
          column: 25
        },
        end: {
          line: 381,
          column: 58
        }
      },
      '157': {
        start: {
          line: 382,
          column: 4
        },
        end: {
          line: 382,
          column: 26
        }
      },
      '158': {
        start: {
          line: 383,
          column: 4
        },
        end: {
          line: 386,
          column: 5
        }
      },
      '159': {
        start: {
          line: 384,
          column: 6
        },
        end: {
          line: 384,
          column: 28
        }
      },
      '160': {
        start: {
          line: 385,
          column: 6
        },
        end: {
          line: 385,
          column: 13
        }
      },
      '161': {
        start: {
          line: 388,
          column: 4
        },
        end: {
          line: 391,
          column: 5
        }
      },
      '162': {
        start: {
          line: 389,
          column: 6
        },
        end: {
          line: 389,
          column: 28
        }
      },
      '163': {
        start: {
          line: 390,
          column: 6
        },
        end: {
          line: 390,
          column: 13
        }
      },
      '164': {
        start: {
          line: 393,
          column: 4
        },
        end: {
          line: 396,
          column: 5
        }
      },
      '165': {
        start: {
          line: 394,
          column: 6
        },
        end: {
          line: 394,
          column: 28
        }
      },
      '166': {
        start: {
          line: 395,
          column: 6
        },
        end: {
          line: 395,
          column: 13
        }
      },
      '167': {
        start: {
          line: 398,
          column: 4
        },
        end: {
          line: 398,
          column: 83
        }
      },
      '168': {
        start: {
          line: 399,
          column: 4
        },
        end: {
          line: 399,
          column: 83
        }
      },
      '169': {
        start: {
          line: 401,
          column: 4
        },
        end: {
          line: 401,
          column: 67
        }
      },
      '170': {
        start: {
          line: 402,
          column: 4
        },
        end: {
          line: 402,
          column: 73
        }
      },
      '171': {
        start: {
          line: 403,
          column: 4
        },
        end: {
          line: 403,
          column: 67
        }
      },
      '172': {
        start: {
          line: 405,
          column: 4
        },
        end: {
          line: 405,
          column: 65
        }
      },
      '173': {
        start: {
          line: 406,
          column: 4
        },
        end: {
          line: 406,
          column: 55
        }
      },
      '174': {
        start: {
          line: 408,
          column: 4
        },
        end: {
          line: 410,
          column: 5
        }
      },
      '175': {
        start: {
          line: 409,
          column: 6
        },
        end: {
          line: 409,
          column: 59
        }
      },
      '176': {
        start: {
          line: 411,
          column: 29
        },
        end: {
          line: 411,
          column: 55
        }
      },
      '177': {
        start: {
          line: 412,
          column: 4
        },
        end: {
          line: 412,
          column: 81
        }
      },
      '178': {
        start: {
          line: 412,
          column: 63
        },
        end: {
          line: 412,
          column: 78
        }
      },
      '179': {
        start: {
          line: 413,
          column: 4
        },
        end: {
          line: 415,
          column: 19
        }
      },
      '180': {
        start: {
          line: 414,
          column: 6
        },
        end: {
          line: 414,
          column: 22
        }
      },
      '181': {
        start: {
          line: 417,
          column: 4
        },
        end: {
          line: 420,
          column: 7
        }
      },
      '182': {
        start: {
          line: 418,
          column: 6
        },
        end: {
          line: 418,
          column: 26
        }
      },
      '183': {
        start: {
          line: 419,
          column: 6
        },
        end: {
          line: 419,
          column: 22
        }
      },
      '184': {
        start: {
          line: 423,
          column: 2
        },
        end: {
          line: 439,
          column: 5
        }
      },
      '185': {
        start: {
          line: 424,
          column: 4
        },
        end: {
          line: 438,
          column: 7
        }
      },
      '186': {
        start: {
          line: 435,
          column: 6
        },
        end: {
          line: 437,
          column: 7
        }
      },
      '187': {
        start: {
          line: 436,
          column: 8
        },
        end: {
          line: 436,
          column: 46
        }
      },
      '188': {
        start: {
          line: 441,
          column: 2
        },
        end: {
          line: 443,
          column: 4
        }
      },
      '189': {
        start: {
          line: 442,
          column: 4
        },
        end: {
          line: 442,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 48
          },
          end: {
            line: 2,
            column: 49
          }
        },
        loc: {
          start: {
            line: 2,
            column: 225
          },
          end: {
            line: 349,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 24,
            column: 23
          },
          end: {
            line: 24,
            column: 24
          }
        },
        loc: {
          start: {
            line: 24,
            column: 29
          },
          end: {
            line: 38,
            column: 3
          }
        },
        line: 24
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 25,
            column: 49
          },
          end: {
            line: 25,
            column: 50
          }
        },
        loc: {
          start: {
            line: 25,
            column: 57
          },
          end: {
            line: 37,
            column: 5
          }
        },
        line: 25
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 32,
            column: 75
          },
          end: {
            line: 32,
            column: 76
          }
        },
        loc: {
          start: {
            line: 32,
            column: 83
          },
          end: {
            line: 34,
            column: 11
          }
        },
        line: 32
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 40,
            column: 27
          },
          end: {
            line: 40,
            column: 28
          }
        },
        loc: {
          start: {
            line: 40,
            column: 35
          },
          end: {
            line: 45,
            column: 3
          }
        },
        line: 40
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 49,
            column: 37
          },
          end: {
            line: 49,
            column: 38
          }
        },
        loc: {
          start: {
            line: 49,
            column: 54
          },
          end: {
            line: 54,
            column: 3
          }
        },
        line: 49
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 113,
            column: 19
          },
          end: {
            line: 113,
            column: 20
          }
        },
        loc: {
          start: {
            line: 113,
            column: 25
          },
          end: {
            line: 136,
            column: 3
          }
        },
        line: 113
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 115,
            column: 44
          },
          end: {
            line: 115,
            column: 45
          }
        },
        loc: {
          start: {
            line: 115,
            column: 52
          },
          end: {
            line: 135,
            column: 5
          }
        },
        line: 115
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 116,
            column: 37
          },
          end: {
            line: 116,
            column: 38
          }
        },
        loc: {
          start: {
            line: 116,
            column: 45
          },
          end: {
            line: 133,
            column: 7
          }
        },
        line: 116
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 121,
            column: 44
          },
          end: {
            line: 121,
            column: 45
          }
        },
        loc: {
          start: {
            line: 121,
            column: 53
          },
          end: {
            line: 123,
            column: 9
          }
        },
        line: 121
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 130,
            column: 46
          },
          end: {
            line: 130,
            column: 47
          }
        },
        loc: {
          start: {
            line: 130,
            column: 57
          },
          end: {
            line: 130,
            column: 75
          }
        },
        line: 130
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 138,
            column: 19
          },
          end: {
            line: 138,
            column: 20
          }
        },
        loc: {
          start: {
            line: 138,
            column: 35
          },
          end: {
            line: 172,
            column: 3
          }
        },
        line: 138
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 161,
            column: 88
          },
          end: {
            line: 161,
            column: 89
          }
        },
        loc: {
          start: {
            line: 161,
            column: 96
          },
          end: {
            line: 165,
            column: 9
          }
        },
        line: 161
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 173,
            column: 23
          },
          end: {
            line: 173,
            column: 24
          }
        },
        loc: {
          start: {
            line: 173,
            column: 34
          },
          end: {
            line: 230,
            column: 3
          }
        },
        line: 173
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 185,
            column: 9
          },
          end: {
            line: 185,
            column: 10
          }
        },
        loc: {
          start: {
            line: 185,
            column: 22
          },
          end: {
            line: 187,
            column: 7
          }
        },
        line: 185
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 193,
            column: 89
          },
          end: {
            line: 193,
            column: 90
          }
        },
        loc: {
          start: {
            line: 193,
            column: 97
          },
          end: {
            line: 229,
            column: 5
          }
        },
        line: 193
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 206,
            column: 67
          },
          end: {
            line: 206,
            column: 68
          }
        },
        loc: {
          start: {
            line: 206,
            column: 76
          },
          end: {
            line: 208,
            column: 7
          }
        },
        line: 206
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 208,
            column: 13
          },
          end: {
            line: 208,
            column: 14
          }
        },
        loc: {
          start: {
            line: 208,
            column: 22
          },
          end: {
            line: 210,
            column: 7
          }
        },
        line: 208
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 213,
            column: 68
          },
          end: {
            line: 213,
            column: 69
          }
        },
        loc: {
          start: {
            line: 213,
            column: 77
          },
          end: {
            line: 215,
            column: 7
          }
        },
        line: 213
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 215,
            column: 13
          },
          end: {
            line: 215,
            column: 14
          }
        },
        loc: {
          start: {
            line: 215,
            column: 22
          },
          end: {
            line: 217,
            column: 7
          }
        },
        line: 215
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 232,
            column: 20
          },
          end: {
            line: 232,
            column: 21
          }
        },
        loc: {
          start: {
            line: 232,
            column: 26
          },
          end: {
            line: 246,
            column: 3
          }
        },
        line: 232
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 248,
            column: 21
          },
          end: {
            line: 248,
            column: 22
          }
        },
        loc: {
          start: {
            line: 248,
            column: 29
          },
          end: {
            line: 294,
            column: 3
          }
        },
        line: 248
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 251,
            column: 63
          },
          end: {
            line: 251,
            column: 64
          }
        },
        loc: {
          start: {
            line: 251,
            column: 71
          },
          end: {
            line: 253,
            column: 5
          }
        },
        line: 251
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 255,
            column: 63
          },
          end: {
            line: 255,
            column: 64
          }
        },
        loc: {
          start: {
            line: 255,
            column: 71
          },
          end: {
            line: 257,
            column: 5
          }
        },
        line: 255
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 263,
            column: 43
          },
          end: {
            line: 263,
            column: 44
          }
        },
        loc: {
          start: {
            line: 263,
            column: 53
          },
          end: {
            line: 268,
            column: 7
          }
        },
        line: 263
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 270,
            column: 64
          },
          end: {
            line: 270,
            column: 65
          }
        },
        loc: {
          start: {
            line: 270,
            column: 73
          },
          end: {
            line: 272,
            column: 7
          }
        },
        line: 270
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 274,
            column: 75
          },
          end: {
            line: 274,
            column: 76
          }
        },
        loc: {
          start: {
            line: 274,
            column: 84
          },
          end: {
            line: 276,
            column: 5
          }
        },
        line: 274
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 275,
            column: 46
          },
          end: {
            line: 275,
            column: 47
          }
        },
        loc: {
          start: {
            line: 275,
            column: 53
          },
          end: {
            line: 275,
            column: 74
          }
        },
        line: 275
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 276,
            column: 14
          },
          end: {
            line: 276,
            column: 15
          }
        },
        loc: {
          start: {
            line: 276,
            column: 23
          },
          end: {
            line: 278,
            column: 5
          }
        },
        line: 276
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 296,
            column: 23
          },
          end: {
            line: 296,
            column: 24
          }
        },
        loc: {
          start: {
            line: 296,
            column: 31
          },
          end: {
            line: 336,
            column: 3
          }
        },
        line: 296
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 308,
            column: 7
          },
          end: {
            line: 308,
            column: 8
          }
        },
        loc: {
          start: {
            line: 308,
            column: 20
          },
          end: {
            line: 335,
            column: 5
          }
        },
        line: 308
      },
      '31': {
        name: '(anonymous_31)',
        decl: {
          start: {
            line: 323,
            column: 88
          },
          end: {
            line: 323,
            column: 89
          }
        },
        loc: {
          start: {
            line: 323,
            column: 96
          },
          end: {
            line: 334,
            column: 7
          }
        },
        line: 323
      },
      '32': {
        name: '(anonymous_32)',
        decl: {
          start: {
            line: 338,
            column: 23
          },
          end: {
            line: 338,
            column: 24
          }
        },
        loc: {
          start: {
            line: 338,
            column: 29
          },
          end: {
            line: 340,
            column: 3
          }
        },
        line: 338
      },
      '33': {
        name: '(anonymous_33)',
        decl: {
          start: {
            line: 342,
            column: 21
          },
          end: {
            line: 342,
            column: 22
          }
        },
        loc: {
          start: {
            line: 342,
            column: 27
          },
          end: {
            line: 346,
            column: 3
          }
        },
        line: 342
      },
      '34': {
        name: '(anonymous_34)',
        decl: {
          start: {
            line: 343,
            column: 53
          },
          end: {
            line: 343,
            column: 54
          }
        },
        loc: {
          start: {
            line: 343,
            column: 62
          },
          end: {
            line: 345,
            column: 5
          }
        },
        line: 343
      },
      '35': {
        name: 'MissionsCtrl_document',
        decl: {
          start: {
            line: 351,
            column: 9
          },
          end: {
            line: 351,
            column: 30
          }
        },
        loc: {
          start: {
            line: 351,
            column: 87
          },
          end: {
            line: 375,
            column: 1
          }
        },
        line: 351
      },
      '36': {
        name: '(anonymous_36)',
        decl: {
          start: {
            line: 353,
            column: 24
          },
          end: {
            line: 353,
            column: 25
          }
        },
        loc: {
          start: {
            line: 353,
            column: 30
          },
          end: {
            line: 355,
            column: 3
          }
        },
        line: 353
      },
      '37': {
        name: '(anonymous_37)',
        decl: {
          start: {
            line: 357,
            column: 24
          },
          end: {
            line: 357,
            column: 25
          }
        },
        loc: {
          start: {
            line: 357,
            column: 40
          },
          end: {
            line: 374,
            column: 3
          }
        },
        line: 357
      },
      '38': {
        name: '(anonymous_38)',
        decl: {
          start: {
            line: 366,
            column: 84
          },
          end: {
            line: 366,
            column: 85
          }
        },
        loc: {
          start: {
            line: 366,
            column: 92
          },
          end: {
            line: 373,
            column: 5
          }
        },
        line: 366
      },
      '39': {
        name: 'MissionsCtrl_edit',
        decl: {
          start: {
            line: 377,
            column: 9
          },
          end: {
            line: 377,
            column: 26
          }
        },
        loc: {
          start: {
            line: 377,
            column: 133
          },
          end: {
            line: 444,
            column: 1
          }
        },
        line: 377
      },
      '40': {
        name: '(anonymous_40)',
        decl: {
          start: {
            line: 380,
            column: 24
          },
          end: {
            line: 380,
            column: 25
          }
        },
        loc: {
          start: {
            line: 380,
            column: 36
          },
          end: {
            line: 421,
            column: 3
          }
        },
        line: 380
      },
      '41': {
        name: '(anonymous_41)',
        decl: {
          start: {
            line: 412,
            column: 52
          },
          end: {
            line: 412,
            column: 53
          }
        },
        loc: {
          start: {
            line: 412,
            column: 63
          },
          end: {
            line: 412,
            column: 78
          }
        },
        line: 412
      },
      '42': {
        name: '(anonymous_42)',
        decl: {
          start: {
            line: 413,
            column: 56
          },
          end: {
            line: 413,
            column: 57
          }
        },
        loc: {
          start: {
            line: 413,
            column: 65
          },
          end: {
            line: 415,
            column: 5
          }
        },
        line: 413
      },
      '43': {
        name: '(anonymous_43)',
        decl: {
          start: {
            line: 417,
            column: 90
          },
          end: {
            line: 417,
            column: 91
          }
        },
        loc: {
          start: {
            line: 417,
            column: 96
          },
          end: {
            line: 420,
            column: 5
          }
        },
        line: 417
      },
      '44': {
        name: '(anonymous_44)',
        decl: {
          start: {
            line: 423,
            column: 30
          },
          end: {
            line: 423,
            column: 31
          }
        },
        loc: {
          start: {
            line: 423,
            column: 36
          },
          end: {
            line: 439,
            column: 3
          }
        },
        line: 423
      },
      '45': {
        name: '(anonymous_45)',
        decl: {
          start: {
            line: 434,
            column: 7
          },
          end: {
            line: 434,
            column: 8
          }
        },
        loc: {
          start: {
            line: 434,
            column: 15
          },
          end: {
            line: 438,
            column: 5
          }
        },
        line: 434
      },
      '46': {
        name: '(anonymous_46)',
        decl: {
          start: {
            line: 441,
            column: 22
          },
          end: {
            line: 441,
            column: 23
          }
        },
        loc: {
          start: {
            line: 441,
            column: 34
          },
          end: {
            line: 443,
            column: 3
          }
        },
        line: 441
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        }, {
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        }],
        line: 4
      },
      '1': {
        loc: {
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }, {
          start: {
            line: 28,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }],
        line: 28
      },
      '2': {
        loc: {
          start: {
            line: 29,
            column: 8
          },
          end: {
            line: 35,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 29,
            column: 8
          },
          end: {
            line: 35,
            column: 9
          }
        }, {
          start: {
            line: 29,
            column: 8
          },
          end: {
            line: 35,
            column: 9
          }
        }],
        line: 29
      },
      '3': {
        loc: {
          start: {
            line: 29,
            column: 12
          },
          end: {
            line: 29,
            column: 91
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 29,
            column: 12
          },
          end: {
            line: 29,
            column: 47
          }
        }, {
          start: {
            line: 29,
            column: 51
          },
          end: {
            line: 29,
            column: 91
          }
        }],
        line: 29
      },
      '4': {
        loc: {
          start: {
            line: 32,
            column: 47
          },
          end: {
            line: 34,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 32,
            column: 47
          },
          end: {
            line: 34,
            column: 12
          }
        }, {
          start: {
            line: 34,
            column: 16
          },
          end: {
            line: 34,
            column: 41
          }
        }],
        line: 32
      },
      '5': {
        loc: {
          start: {
            line: 50,
            column: 4
          },
          end: {
            line: 52,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 50,
            column: 4
          },
          end: {
            line: 52,
            column: 5
          }
        }, {
          start: {
            line: 50,
            column: 4
          },
          end: {
            line: 52,
            column: 5
          }
        }],
        line: 50
      },
      '6': {
        loc: {
          start: {
            line: 57,
            column: 14
          },
          end: {
            line: 57,
            column: 61
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 57,
            column: 42
          },
          end: {
            line: 57,
            column: 49
          }
        }, {
          start: {
            line: 57,
            column: 52
          },
          end: {
            line: 57,
            column: 61
          }
        }],
        line: 57
      },
      '7': {
        loc: {
          start: {
            line: 87,
            column: 19
          },
          end: {
            line: 87,
            column: 132
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 87,
            column: 47
          },
          end: {
            line: 87,
            column: 127
          }
        }, {
          start: {
            line: 87,
            column: 130
          },
          end: {
            line: 87,
            column: 132
          }
        }],
        line: 87
      },
      '8': {
        loc: {
          start: {
            line: 88,
            column: 18
          },
          end: {
            line: 88,
            column: 134
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 88,
            column: 46
          },
          end: {
            line: 88,
            column: 129
          }
        }, {
          start: {
            line: 88,
            column: 132
          },
          end: {
            line: 88,
            column: 134
          }
        }],
        line: 88
      },
      '9': {
        loc: {
          start: {
            line: 92,
            column: 6
          },
          end: {
            line: 94,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 92,
            column: 6
          },
          end: {
            line: 94,
            column: 7
          }
        }, {
          start: {
            line: 92,
            column: 6
          },
          end: {
            line: 94,
            column: 7
          }
        }],
        line: 92
      },
      '10': {
        loc: {
          start: {
            line: 97,
            column: 6
          },
          end: {
            line: 100,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 97,
            column: 6
          },
          end: {
            line: 100,
            column: 7
          }
        }, {
          start: {
            line: 97,
            column: 6
          },
          end: {
            line: 100,
            column: 7
          }
        }],
        line: 97
      },
      '11': {
        loc: {
          start: {
            line: 101,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 101,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        }, {
          start: {
            line: 101,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        }],
        line: 101
      },
      '12': {
        loc: {
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        }, {
          start: {
            line: 105,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        }],
        line: 105
      },
      '13': {
        loc: {
          start: {
            line: 124,
            column: 8
          },
          end: {
            line: 129,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 124,
            column: 8
          },
          end: {
            line: 129,
            column: 9
          }
        }, {
          start: {
            line: 124,
            column: 8
          },
          end: {
            line: 129,
            column: 9
          }
        }],
        line: 124
      },
      '14': {
        loc: {
          start: {
            line: 139,
            column: 4
          },
          end: {
            line: 141,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 139,
            column: 4
          },
          end: {
            line: 141,
            column: 5
          }
        }, {
          start: {
            line: 139,
            column: 4
          },
          end: {
            line: 141,
            column: 5
          }
        }],
        line: 139
      },
      '15': {
        loc: {
          start: {
            line: 142,
            column: 4
          },
          end: {
            line: 144,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 142,
            column: 4
          },
          end: {
            line: 144,
            column: 5
          }
        }, {
          start: {
            line: 142,
            column: 4
          },
          end: {
            line: 144,
            column: 5
          }
        }],
        line: 142
      },
      '16': {
        loc: {
          start: {
            line: 146,
            column: 4
          },
          end: {
            line: 148,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 146,
            column: 4
          },
          end: {
            line: 148,
            column: 5
          }
        }, {
          start: {
            line: 146,
            column: 4
          },
          end: {
            line: 148,
            column: 5
          }
        }],
        line: 146
      },
      '17': {
        loc: {
          start: {
            line: 152,
            column: 4
          },
          end: {
            line: 171,
            column: 5
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 153,
            column: 6
          },
          end: {
            line: 153,
            column: 18
          }
        }, {
          start: {
            line: 154,
            column: 6
          },
          end: {
            line: 154,
            column: 17
          }
        }, {
          start: {
            line: 155,
            column: 6
          },
          end: {
            line: 155,
            column: 17
          }
        }, {
          start: {
            line: 156,
            column: 6
          },
          end: {
            line: 156,
            column: 17
          }
        }, {
          start: {
            line: 157,
            column: 6
          },
          end: {
            line: 157,
            column: 18
          }
        }, {
          start: {
            line: 158,
            column: 6
          },
          end: {
            line: 158,
            column: 17
          }
        }, {
          start: {
            line: 159,
            column: 6
          },
          end: {
            line: 159,
            column: 17
          }
        }, {
          start: {
            line: 160,
            column: 6
          },
          end: {
            line: 166,
            column: 14
          }
        }, {
          start: {
            line: 168,
            column: 6
          },
          end: {
            line: 169,
            column: 15
          }
        }],
        line: 152
      },
      '18': {
        loc: {
          start: {
            line: 152,
            column: 13
          },
          end: {
            line: 152,
            column: 31
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 152,
            column: 13
          },
          end: {
            line: 152,
            column: 25
          }
        }, {
          start: {
            line: 152,
            column: 29
          },
          end: {
            line: 152,
            column: 31
          }
        }],
        line: 152
      },
      '19': {
        loc: {
          start: {
            line: 162,
            column: 10
          },
          end: {
            line: 164,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 162,
            column: 10
          },
          end: {
            line: 164,
            column: 11
          }
        }, {
          start: {
            line: 162,
            column: 10
          },
          end: {
            line: 164,
            column: 11
          }
        }],
        line: 162
      },
      '20': {
        loc: {
          start: {
            line: 174,
            column: 4
          },
          end: {
            line: 189,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 174,
            column: 4
          },
          end: {
            line: 189,
            column: 5
          }
        }, {
          start: {
            line: 174,
            column: 4
          },
          end: {
            line: 189,
            column: 5
          }
        }],
        line: 174
      },
      '21': {
        loc: {
          start: {
            line: 198,
            column: 6
          },
          end: {
            line: 200,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 198,
            column: 6
          },
          end: {
            line: 200,
            column: 7
          }
        }, {
          start: {
            line: 198,
            column: 6
          },
          end: {
            line: 200,
            column: 7
          }
        }],
        line: 198
      },
      '22': {
        loc: {
          start: {
            line: 199,
            column: 42
          },
          end: {
            line: 199,
            column: 65
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 199,
            column: 42
          },
          end: {
            line: 199,
            column: 59
          }
        }, {
          start: {
            line: 199,
            column: 63
          },
          end: {
            line: 199,
            column: 65
          }
        }],
        line: 199
      },
      '23': {
        loc: {
          start: {
            line: 204,
            column: 21
          },
          end: {
            line: 204,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 204,
            column: 21
          },
          end: {
            line: 204,
            column: 35
          }
        }, {
          start: {
            line: 204,
            column: 39
          },
          end: {
            line: 204,
            column: 41
          }
        }],
        line: 204
      },
      '24': {
        loc: {
          start: {
            line: 212,
            column: 22
          },
          end: {
            line: 212,
            column: 40
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 212,
            column: 22
          },
          end: {
            line: 212,
            column: 34
          }
        }, {
          start: {
            line: 212,
            column: 38
          },
          end: {
            line: 212,
            column: 40
          }
        }],
        line: 212
      },
      '25': {
        loc: {
          start: {
            line: 251,
            column: 35
          },
          end: {
            line: 253,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 251,
            column: 35
          },
          end: {
            line: 253,
            column: 6
          }
        }, {
          start: {
            line: 253,
            column: 10
          },
          end: {
            line: 253,
            column: 35
          }
        }, {
          start: {
            line: 253,
            column: 39
          },
          end: {
            line: 253,
            column: 49
          }
        }],
        line: 251
      },
      '26': {
        loc: {
          start: {
            line: 255,
            column: 30
          },
          end: {
            line: 257,
            column: 54
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 255,
            column: 30
          },
          end: {
            line: 257,
            column: 6
          }
        }, {
          start: {
            line: 257,
            column: 10
          },
          end: {
            line: 257,
            column: 40
          }
        }, {
          start: {
            line: 257,
            column: 44
          },
          end: {
            line: 257,
            column: 54
          }
        }],
        line: 255
      },
      '27': {
        loc: {
          start: {
            line: 260,
            column: 28
          },
          end: {
            line: 260,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 260,
            column: 28
          },
          end: {
            line: 260,
            column: 52
          }
        }, {
          start: {
            line: 260,
            column: 56
          },
          end: {
            line: 260,
            column: 58
          }
        }],
        line: 260
      },
      '28': {
        loc: {
          start: {
            line: 262,
            column: 4
          },
          end: {
            line: 273,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 262,
            column: 4
          },
          end: {
            line: 273,
            column: 5
          }
        }, {
          start: {
            line: 262,
            column: 4
          },
          end: {
            line: 273,
            column: 5
          }
        }],
        line: 262
      },
      '29': {
        loc: {
          start: {
            line: 264,
            column: 8
          },
          end: {
            line: 266,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 264,
            column: 8
          },
          end: {
            line: 266,
            column: 9
          }
        }, {
          start: {
            line: 264,
            column: 8
          },
          end: {
            line: 266,
            column: 9
          }
        }],
        line: 264
      },
      '30': {
        loc: {
          start: {
            line: 274,
            column: 30
          },
          end: {
            line: 274,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 274,
            column: 30
          },
          end: {
            line: 274,
            column: 52
          }
        }, {
          start: {
            line: 274,
            column: 56
          },
          end: {
            line: 274,
            column: 58
          }
        }],
        line: 274
      },
      '31': {
        loc: {
          start: {
            line: 280,
            column: 4
          },
          end: {
            line: 282,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 280,
            column: 4
          },
          end: {
            line: 282,
            column: 5
          }
        }, {
          start: {
            line: 280,
            column: 4
          },
          end: {
            line: 282,
            column: 5
          }
        }],
        line: 280
      },
      '32': {
        loc: {
          start: {
            line: 309,
            column: 6
          },
          end: {
            line: 311,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 309,
            column: 6
          },
          end: {
            line: 311,
            column: 7
          }
        }, {
          start: {
            line: 309,
            column: 6
          },
          end: {
            line: 311,
            column: 7
          }
        }],
        line: 309
      },
      '33': {
        loc: {
          start: {
            line: 324,
            column: 8
          },
          end: {
            line: 333,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 324,
            column: 8
          },
          end: {
            line: 333,
            column: 9
          }
        }, {
          start: {
            line: 324,
            column: 8
          },
          end: {
            line: 333,
            column: 9
          }
        }],
        line: 324
      },
      '34': {
        loc: {
          start: {
            line: 362,
            column: 4
          },
          end: {
            line: 365,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 362,
            column: 4
          },
          end: {
            line: 365,
            column: 5
          }
        }, {
          start: {
            line: 362,
            column: 4
          },
          end: {
            line: 365,
            column: 5
          }
        }],
        line: 362
      },
      '35': {
        loc: {
          start: {
            line: 370,
            column: 6
          },
          end: {
            line: 372,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 370,
            column: 6
          },
          end: {
            line: 372,
            column: 7
          }
        }, {
          start: {
            line: 370,
            column: 6
          },
          end: {
            line: 372,
            column: 7
          }
        }],
        line: 370
      },
      '36': {
        loc: {
          start: {
            line: 383,
            column: 4
          },
          end: {
            line: 386,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 383,
            column: 4
          },
          end: {
            line: 386,
            column: 5
          }
        }, {
          start: {
            line: 383,
            column: 4
          },
          end: {
            line: 386,
            column: 5
          }
        }],
        line: 383
      },
      '37': {
        loc: {
          start: {
            line: 383,
            column: 9
          },
          end: {
            line: 383,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 383,
            column: 9
          },
          end: {
            line: 383,
            column: 35
          }
        }, {
          start: {
            line: 383,
            column: 39
          },
          end: {
            line: 383,
            column: 41
          }
        }],
        line: 383
      },
      '38': {
        loc: {
          start: {
            line: 388,
            column: 4
          },
          end: {
            line: 391,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 388,
            column: 4
          },
          end: {
            line: 391,
            column: 5
          }
        }, {
          start: {
            line: 388,
            column: 4
          },
          end: {
            line: 391,
            column: 5
          }
        }],
        line: 388
      },
      '39': {
        loc: {
          start: {
            line: 388,
            column: 9
          },
          end: {
            line: 388,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 388,
            column: 9
          },
          end: {
            line: 388,
            column: 47
          }
        }, {
          start: {
            line: 388,
            column: 51
          },
          end: {
            line: 388,
            column: 52
          }
        }],
        line: 388
      },
      '40': {
        loc: {
          start: {
            line: 388,
            column: 10
          },
          end: {
            line: 388,
            column: 43
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 388,
            column: 10
          },
          end: {
            line: 388,
            column: 37
          }
        }, {
          start: {
            line: 388,
            column: 41
          },
          end: {
            line: 388,
            column: 43
          }
        }],
        line: 388
      },
      '41': {
        loc: {
          start: {
            line: 393,
            column: 4
          },
          end: {
            line: 396,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 393,
            column: 4
          },
          end: {
            line: 396,
            column: 5
          }
        }, {
          start: {
            line: 393,
            column: 4
          },
          end: {
            line: 396,
            column: 5
          }
        }],
        line: 393
      },
      '42': {
        loc: {
          start: {
            line: 402,
            column: 33
          },
          end: {
            line: 402,
            column: 64
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 402,
            column: 33
          },
          end: {
            line: 402,
            column: 58
          }
        }, {
          start: {
            line: 402,
            column: 62
          },
          end: {
            line: 402,
            column: 64
          }
        }],
        line: 402
      },
      '43': {
        loc: {
          start: {
            line: 403,
            column: 30
          },
          end: {
            line: 403,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 403,
            column: 30
          },
          end: {
            line: 403,
            column: 52
          }
        }, {
          start: {
            line: 403,
            column: 56
          },
          end: {
            line: 403,
            column: 58
          }
        }],
        line: 403
      },
      '44': {
        loc: {
          start: {
            line: 408,
            column: 4
          },
          end: {
            line: 410,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 408,
            column: 4
          },
          end: {
            line: 410,
            column: 5
          }
        }, {
          start: {
            line: 408,
            column: 4
          },
          end: {
            line: 410,
            column: 5
          }
        }],
        line: 408
      },
      '45': {
        loc: {
          start: {
            line: 435,
            column: 6
          },
          end: {
            line: 437,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 435,
            column: 6
          },
          end: {
            line: 437,
            column: 7
          }
        }, {
          start: {
            line: 435,
            column: 6
          },
          end: {
            line: 437,
            column: 7
          }
        }],
        line: 435
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0, 0, 0, 0, 0, 0, 0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0, 0],
      '26': [0, 0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_s3cqqww5u.s[0]++;

angular.module('app').controller('MissionCtrl', function ($scope, SweetAlert, $uibModal, $translate, $CRUDService, $deltaDocumentType, $deltahttp, $otherHttpService, $location, $filter, $rootScope, FileUploader, $deltaActor, $log) {
  cov_s3cqqww5u.f[0]++;
  cov_s3cqqww5u.s[1]++;

  $rootScope.processPageRight('2_6');
  cov_s3cqqww5u.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_s3cqqww5u.b[0][0]++;
    cov_s3cqqww5u.s[3]++;

    return;
  } else {
    cov_s3cqqww5u.b[0][1]++;
  }

  cov_s3cqqww5u.s[4]++;
  $scope.listMissions = [];

  cov_s3cqqww5u.s[5]++;
  $deltaActor.addController($scope);

  var PATH = (cov_s3cqqww5u.s[6]++, 'Mission');
  var PATH_DOC = (cov_s3cqqww5u.s[7]++, 'Documents');
  var PATH_TYPE = (cov_s3cqqww5u.s[8]++, 'Type_Mission');

  var project = (cov_s3cqqww5u.s[9]++, $rootScope.getCurrentProject());
  cov_s3cqqww5u.s[10]++;
  $deltaDocumentType.addController($scope);

  cov_s3cqqww5u.s[11]++;
  $scope.id_t = Date.newDate().getTime();

  cov_s3cqqww5u.s[12]++;
  $scope.page = 1;
  cov_s3cqqww5u.s[13]++;
  $scope.listMissions = [];

  cov_s3cqqww5u.s[14]++;
  $scope.getAllType = function () {
    cov_s3cqqww5u.f[1]++;
    cov_s3cqqww5u.s[15]++;

    $CRUDService.getAll(PATH_TYPE, { get: 'all' }, function (data) {
      cov_s3cqqww5u.f[2]++;
      cov_s3cqqww5u.s[16]++;

      $scope.listTypeMission = data;
      cov_s3cqqww5u.s[17]++;
      $scope.listTypeMission.push({});
      cov_s3cqqww5u.s[18]++;
      if ($scope.validateItem) {
        cov_s3cqqww5u.b[1][0]++;
        cov_s3cqqww5u.s[19]++;

        if ((cov_s3cqqww5u.b[3][0]++, !$scope.validateItem.IDTYPE_MISSION) || (cov_s3cqqww5u.b[3][1]++, $scope.validateItem.IDTYPE_MISSION == {})) {
          cov_s3cqqww5u.b[2][0]++;
          cov_s3cqqww5u.s[20]++;

          $scope.validateItem.IDTYPE_MISSION = $scope.listTypeMission[0];
        } else {
          cov_s3cqqww5u.b[2][1]++;
          cov_s3cqqww5u.s[21]++;

          $scope.validateItem.IDTYPE_MISSION = (cov_s3cqqww5u.b[4][0]++, $scope.listTypeMission.find(function (type) {
            cov_s3cqqww5u.f[3]++;
            cov_s3cqqww5u.s[22]++;

            return type.id == $scope.validateItem.IDTYPE_MISSION.id;
          })) || (cov_s3cqqww5u.b[4][1]++, $scope.listTypeMission[0]);
        }
      } else {
        cov_s3cqqww5u.b[1][1]++;
      }
    });
  };

  cov_s3cqqww5u.s[23]++;
  $scope.customAllItems = function (type) {
    cov_s3cqqww5u.f[4]++;
    cov_s3cqqww5u.s[24]++;

    $rootScope.updateCustomFields({ type: type, withColor: true, mapping: {
        name: 'LIBELLE_TYPE_MISSION'
      }, link: PATH_TYPE, title: 'MENU_SHORT.MISSION_TYPES'
    });
  };

  cov_s3cqqww5u.s[25]++;
  $scope.getAllType();

  cov_s3cqqww5u.s[26]++;
  $scope.$on('update_custom_fields', function (evt, params) {
    cov_s3cqqww5u.f[5]++;
    cov_s3cqqww5u.s[27]++;

    if (params.type != 4) {
      cov_s3cqqww5u.b[5][0]++;
      cov_s3cqqww5u.s[28]++;

      return;
    } else {
      cov_s3cqqww5u.b[5][1]++;
    }
    cov_s3cqqww5u.s[29]++;
    $scope.getAllType();
  });

  cov_s3cqqww5u.s[30]++;
  $scope.params = {
    toolbar: $rootScope.currentRight.u ? (cov_s3cqqww5u.b[6][0]++, ['Add']) : (cov_s3cqqww5u.b[6][1]++, undefined),
    editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, allowEditOnDblClick: false },

    columns: [{ field: 'TITLE_MISSION', width: 350, headerText: $translate.instant('MISSIONS.TITLE_MISSION'), type: 'string' }, { field: 'Objet_Mis', width: 350, headerText: $translate.instant('MISSIONS.OBJECT_MISSION'), type: 'string' }, { field: 'type', width: 350, headerText: $translate.instant('MISSIONS.TYPE'), type: 'string', filter: { type: 'CheckBox' } }, { field: 'LIEU_MISSION', width: 250, headerText: $translate.instant('MISSIONS.LIEU_MISSION'), type: 'string' }, { field: 'Date_D_P', width: 150, headerText: $translate.instant('MISSIONS.DATE_BEGIN'), format: 'yMd' }, { field: 'Date_F_P', width: 150, headerText: $translate.instant('MISSIONS.DATE_END'), format: 'yMd' }, { field: 'responsable', width: 250, headerText: $translate.instant('MISSIONS.RESPONSIBLE'), filter: { type: 'CheckBox' }, type: 'string' }, { field: 'currentMembers', width: 350, headerText: $translate.instant('MISSIONS.MEMBERS'), type: 'string' },

    // type: $translate.instant('MISSIONS.REPORT'),
    /**/
    { headerText: $translate.instant('MISSIONS.REPORT'), width: 120,
      commands: [{ type: $translate.instant('MISSIONS.REPORT'), id: 'file', buttonOption: { iconCss: ' e-icons e-file', cssClass: 'e-flat' } }, { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }]
    }, { headerText: '', width: 120,
      commands: ($rootScope.currentRight.u ? (cov_s3cqqww5u.b[7][0]++, [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } }]) : (cov_s3cqqww5u.b[7][1]++, [])).concat($rootScope.currentRight.d ? (cov_s3cqqww5u.b[8][0]++, [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }]) : (cov_s3cqqww5u.b[8][1]++, []))
    }],
    commandClick: function commandClick(action) {
      cov_s3cqqww5u.s[31]++;

      if (action.commandColumn.type === $translate.instant('MISSIONS.REPORT')) {
        cov_s3cqqww5u.b[9][0]++;
        cov_s3cqqww5u.s[32]++;

        $scope.openReport(action.rowData);
      } else {
        cov_s3cqqww5u.b[9][1]++;
      }
    },
    actionBegin: function actionBegin(action) {
      cov_s3cqqww5u.s[33]++;

      if (action.requestType === 'add') {
        cov_s3cqqww5u.b[10][0]++;
        cov_s3cqqww5u.s[34]++;

        action.cancel = true;
        cov_s3cqqww5u.s[35]++;
        $scope.addItem();
      } else {
        cov_s3cqqww5u.b[10][1]++;
      }
      cov_s3cqqww5u.s[36]++;
      if (action.requestType === 'beginEdit') {
        cov_s3cqqww5u.b[11][0]++;
        cov_s3cqqww5u.s[37]++;

        action.cancel = true;
        cov_s3cqqww5u.s[38]++;
        $scope.editItem(action.rowData);
      } else {
        cov_s3cqqww5u.b[11][1]++;
      }
      cov_s3cqqww5u.s[39]++;
      if (action.requestType === 'delete') {
        cov_s3cqqww5u.b[12][0]++;
        cov_s3cqqww5u.s[40]++;

        action.cancel = true;
        cov_s3cqqww5u.s[41]++;
        $scope.deleteItem(action.data[0]);
      } else {
        cov_s3cqqww5u.b[12][1]++;
      }
    }
  };

  cov_s3cqqww5u.s[42]++;
  $scope.getAll = function () {
    cov_s3cqqww5u.f[6]++;
    cov_s3cqqww5u.s[43]++;

    $scope.isloading = true;
    cov_s3cqqww5u.s[44]++;
    $CRUDService.getAll(PATH, { get: 'all' }, function (data) {
      cov_s3cqqww5u.f[7]++;
      cov_s3cqqww5u.s[45]++;

      $scope.listMissions = data.map(function (item) {
        cov_s3cqqww5u.f[8]++;
        cov_s3cqqww5u.s[46]++;

        item.Date_D_P = Date.newDate(item.Date_D_P);
        cov_s3cqqww5u.s[47]++;
        item.Date_F_P = Date.newDate(item.Date_F_P);
        var itemmembers = (cov_s3cqqww5u.s[48]++, angular.copy(item.members));

        var actorItem = (cov_s3cqqww5u.s[49]++, item.members.find(function (actor) {
          cov_s3cqqww5u.f[9]++;
          cov_s3cqqww5u.s[50]++;

          return actor.IS_RESPONSIBLE == 1;
        }));
        cov_s3cqqww5u.s[51]++;
        if (actorItem) {
          cov_s3cqqww5u.b[13][0]++;
          cov_s3cqqww5u.s[52]++;

          item.IDACTEURS = actorItem.id;
          cov_s3cqqww5u.s[53]++;
          item.members.splice(item.members.indexOf(actorItem), 1);
        } else {
          cov_s3cqqww5u.b[13][1]++;
          cov_s3cqqww5u.s[54]++;

          item.IDACTEURS = -100;
        }
        cov_s3cqqww5u.s[55]++;
        item.currentMembers = itemmembers.map(function (member) {
          cov_s3cqqww5u.f[10]++;
          cov_s3cqqww5u.s[56]++;
          return member.RESPONSIBLE;
        }).join(' ; ');
        cov_s3cqqww5u.s[57]++;
        item.itemmembers = angular.copy(itemmembers);
        cov_s3cqqww5u.s[58]++;
        return item;
      });
      cov_s3cqqww5u.s[59]++;
      $scope.isloading = false;
    });
  };

  cov_s3cqqww5u.s[60]++;
  $scope.getLink = function (item) {
    cov_s3cqqww5u.f[11]++;
    cov_s3cqqww5u.s[61]++;

    if (item.PATH) {
      cov_s3cqqww5u.b[14][0]++;
      cov_s3cqqww5u.s[62]++;

      return false;
    } else {
      cov_s3cqqww5u.b[14][1]++;
    }
    cov_s3cqqww5u.s[63]++;
    if (item.TYPE_RAPPORT == 2) {
      cov_s3cqqww5u.b[15][0]++;
      cov_s3cqqww5u.s[64]++;

      return false;
    } else {
      cov_s3cqqww5u.b[15][1]++;
    }

    cov_s3cqqww5u.s[65]++;
    if (item.TYPE_RAPPORT == 1) {
      cov_s3cqqww5u.b[16][0]++;
      cov_s3cqqww5u.s[66]++;

      return false;
    } else {
      cov_s3cqqww5u.b[16][1]++;
    }

    var ext = (cov_s3cqqww5u.s[67]++, item.CHEMIN_RAPP.split('.'));
    var len = (cov_s3cqqww5u.s[68]++, ext.length);
    cov_s3cqqww5u.s[69]++;
    switch (((cov_s3cqqww5u.b[18][0]++, ext[len - 1]) || (cov_s3cqqww5u.b[18][1]++, '')).toLowerCase()) {
      case 'jpeg':
        cov_s3cqqww5u.b[17][0]++;

      case 'jpg':
        cov_s3cqqww5u.b[17][1]++;

      case 'png':
        cov_s3cqqww5u.b[17][2]++;

      case 'gif':
        cov_s3cqqww5u.b[17][3]++;

      case 'tiff':
        cov_s3cqqww5u.b[17][4]++;

      case 'psd':
        cov_s3cqqww5u.b[17][5]++;

      case 'eps':
        cov_s3cqqww5u.b[17][6]++;

      case 'ai':
        cov_s3cqqww5u.b[17][7]++;
        cov_s3cqqww5u.s[70]++;

        $CRUDService.getAll(PATH_DOC, { download: true, c: item.Reference, id: item.id }, function (data) {
          cov_s3cqqww5u.f[12]++;
          cov_s3cqqww5u.s[71]++;

          if (data.f != '') {
            cov_s3cqqww5u.b[19][0]++;
            cov_s3cqqww5u.s[72]++;

            $scope.detailItem.imageLink = $CRUDService.getServerStrict() + data.f;
          } else {
            cov_s3cqqww5u.b[19][1]++;
          }
        });
        cov_s3cqqww5u.s[73]++;
        break;

      default:
        cov_s3cqqww5u.b[17][8]++;
        cov_s3cqqww5u.s[74]++;

        return;

    }
  };
  cov_s3cqqww5u.s[75]++;
  $scope.openReport = function (mission) {
    cov_s3cqqww5u.f[13]++;
    cov_s3cqqww5u.s[76]++;

    if (!mission.IDRAPPORTS) {
      cov_s3cqqww5u.b[20][0]++;
      cov_s3cqqww5u.s[77]++;

      SweetAlert.swal({
        title: '', // $translate.instant('COMMON.CONFIRM'),
        text: $translate.instant('MISSIONS.NO_REPORT'),
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#c8c8c8',

        confirmButtonText: $translate.instant('COMMON.VALIDATE'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isconfirm) {
        cov_s3cqqww5u.f[14]++;
      });
      cov_s3cqqww5u.s[78]++;
      return;
    } else {
      cov_s3cqqww5u.b[20][1]++;
    }

    cov_s3cqqww5u.s[79]++;
    $scope.LASTUPDATE = new Date().getTime();
    cov_s3cqqww5u.s[80]++;
    $scope.imageLink = null;
    cov_s3cqqww5u.s[81]++;
    $CRUDService.getAll(PATH_DOC, { get: 'single', simple: true, id: mission.IDRAPPORTS }, function (data) {
      cov_s3cqqww5u.f[15]++;
      cov_s3cqqww5u.s[82]++;

      $scope.detailItem = data;

      cov_s3cqqww5u.s[83]++;
      $scope.canProcessDetailsAction = $rootScope.connectedUser.superAdmin;

      cov_s3cqqww5u.s[84]++;
      if (!$scope.canProcessDetailsAction) {
        cov_s3cqqww5u.b[21][0]++;
        cov_s3cqqww5u.s[85]++;

        $scope.canProcessDetailsAction = ((cov_s3cqqww5u.b[22][0]++, data.LIST_ACTEURS) || (cov_s3cqqww5u.b[22][1]++, '')).includes(';' + $rootScope.connectedUser.id + ';');
      } else {
        cov_s3cqqww5u.b[21][1]++;
      }

      cov_s3cqqww5u.s[86]++;
      $scope.getLink($scope.detailItem);

      var types = (cov_s3cqqww5u.s[87]++, ((cov_s3cqqww5u.b[23][0]++, data.IDTYPEDOC) || (cov_s3cqqww5u.b[23][1]++, '')).split(';'));

      cov_s3cqqww5u.s[88]++;
      $scope.detailItem.TYPE = $scope.listData_typeDoc_bksb.filter(function (value) {
        cov_s3cqqww5u.f[16]++;
        cov_s3cqqww5u.s[89]++;

        return types.indexOf(value.id) >= 0;
      }).map(function (value) {
        cov_s3cqqww5u.f[17]++;
        cov_s3cqqww5u.s[90]++;

        return value.LIBELLE_TYPEDOC;
      }).join(' ; ');

      var themes = (cov_s3cqqww5u.s[91]++, ((cov_s3cqqww5u.b[24][0]++, data.IDTHEME) || (cov_s3cqqww5u.b[24][1]++, '')).split(';'));
      cov_s3cqqww5u.s[92]++;
      $scope.detailItem.THEME = $scope.listData_typeDoc_bksb.filter(function (value) {
        cov_s3cqqww5u.f[18]++;
        cov_s3cqqww5u.s[93]++;

        return themes.indexOf(value.id) >= 0;
      }).map(function (value) {
        cov_s3cqqww5u.f[19]++;
        cov_s3cqqww5u.s[94]++;

        return value.LIBELLE_TYPEDOC;
      }).join(' ; ');

      cov_s3cqqww5u.s[95]++;
      $uibModal.open({
        templateUrl: 'app/views/missions/preview_document.html',
        controller: MissionsCtrl_document,
        scope: $scope,
        size: 'lg_',
        backdrop: 'static',
        windowClass: 'animated fadeInRight left-modal'
      });

      cov_s3cqqww5u.s[96]++;
      $CRUDService.save(PATH_DOC, { action: 'view', id: data.id });
    });
  };

  cov_s3cqqww5u.s[97]++;
  $scope.addItem = function () {
    cov_s3cqqww5u.f[20]++;
    cov_s3cqqww5u.s[98]++;

    $scope.editItem({
      NUMERO_ORDR_MISS: '',
      LIEU_MISSION: '',
      TITLE_MISSION: '',
      Objet_Mis: '',
      IDTYPE_MISSION: null,
      Date_D_P: Date.newDate(),
      Date_F_P: Date.newDate(),
      members: [],
      IDACTEURS: -100,
      SEND_PERS: '',
      id: 0
    });
  };

  cov_s3cqqww5u.s[99]++;
  $scope.editItem = function (item) {
    cov_s3cqqww5u.f[21]++;
    cov_s3cqqww5u.s[100]++;

    $scope.error_code = false;
    var validateItem = (cov_s3cqqww5u.s[101]++, angular.copy(item));
    cov_s3cqqww5u.s[102]++;
    validateItem.IDTYPE_MISSION = (cov_s3cqqww5u.b[25][0]++, $scope.listTypeMission.find(function (type) {
      cov_s3cqqww5u.f[22]++;
      cov_s3cqqww5u.s[103]++;

      return type.id == validateItem.IDTYPE_MISSION;
    })) || (cov_s3cqqww5u.b[25][1]++, $scope.listTypeMission[0]) || (cov_s3cqqww5u.b[25][2]++, { id: null });

    cov_s3cqqww5u.s[104]++;
    validateItem.IDACTEURS = (cov_s3cqqww5u.b[26][0]++, $scope.listData_actors_bksb.find(function (type) {
      cov_s3cqqww5u.f[23]++;
      cov_s3cqqww5u.s[105]++;

      return type.id == validateItem.responsableId;
    })) || (cov_s3cqqww5u.b[26][1]++, $scope.listData_actors_bksb[0]) || (cov_s3cqqww5u.b[26][2]++, { id: null });
    // const idMembers = validateItem.itemmembers.map(member => (parseInt(member.id, 10)));
    // const idMembers = validateItem.members.map(member => (parseInt(member.id, 10)));
    cov_s3cqqww5u.s[106]++;
    validateItem.members = (cov_s3cqqww5u.b[27][0]++, validateItem.itemmembers) || (cov_s3cqqww5u.b[27][1]++, []);
    var idMembers = (cov_s3cqqww5u.s[107]++, []);
    cov_s3cqqww5u.s[108]++;
    if (validateItem.members.length > 0) {
      cov_s3cqqww5u.b[28][0]++;
      cov_s3cqqww5u.s[109]++;

      idMembers = validateItem.members.map(function (member) {
        cov_s3cqqww5u.f[24]++;
        cov_s3cqqww5u.s[110]++;

        if (angular.isDefined(member.IDACTEURS)) {
          cov_s3cqqww5u.b[29][0]++;
          cov_s3cqqww5u.s[111]++;

          return parseInt(member.IDACTEURS, 10);
        } else {
          cov_s3cqqww5u.b[29][1]++;
        }
        cov_s3cqqww5u.s[112]++;
        return parseInt(member.id);
      });

      cov_s3cqqww5u.s[113]++;
      validateItem.members = $scope.listData_actors_bksb.filter(function (actor) {
        cov_s3cqqww5u.f[25]++;
        cov_s3cqqww5u.s[114]++;

        return idMembers.indexOf(parseInt(actor.id, 10)) >= 0;
      });
    } else {
      cov_s3cqqww5u.b[28][1]++;
    }
    cov_s3cqqww5u.s[115]++;
    validateItem.SEND_PERS = ((cov_s3cqqww5u.b[30][0]++, validateItem.SEND_PERS) || (cov_s3cqqww5u.b[30][1]++, '')).split(';').map(function (value) {
      cov_s3cqqww5u.f[26]++;
      cov_s3cqqww5u.s[116]++;

      return $scope.listData_actors_bksb.find(function (ac) {
        cov_s3cqqww5u.f[27]++;
        cov_s3cqqww5u.s[117]++;
        return ac.id == value.trim();
      });
    }).filter(function (value) {
      cov_s3cqqww5u.f[28]++;
      cov_s3cqqww5u.s[118]++;

      return value;
    });

    cov_s3cqqww5u.s[119]++;
    if (validateItem.report) {
      cov_s3cqqww5u.b[31][0]++;
      cov_s3cqqww5u.s[120]++;

      validateItem.IDRAPPORTS = { id: validateItem.IDRAPPORTS, name: validateItem.report };
    } else {
      cov_s3cqqww5u.b[31][1]++;
    }

    cov_s3cqqww5u.s[121]++;
    $scope.validateItem = validateItem;
    cov_s3cqqww5u.s[122]++;
    $uibModal.open({
      templateUrl: 'app/views/missions/edit.html',
      controller: MissionsCtrl_edit,
      scope: $scope,
      size: 'mdx_',
      // size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_s3cqqww5u.s[123]++;
  $scope.deleteItem = function (item) {
    cov_s3cqqww5u.f[29]++;
    cov_s3cqqww5u.s[124]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_s3cqqww5u.f[30]++;
      cov_s3cqqww5u.s[125]++;

      if (isconfirm) {
        cov_s3cqqww5u.b[32][0]++;
        cov_s3cqqww5u.s[126]++;

        return;
      } else {
        cov_s3cqqww5u.b[32][1]++;
      }
      cov_s3cqqww5u.s[127]++;
      $scope.isloading = true;
      var validateItem = (cov_s3cqqww5u.s[128]++, {
        id: item.id,
        NUMERO_ORDR_MISS: item.NUMERO_ORDR_MISS
      });
      // SweetAlert.swal({
      // title: $translate.instant('COMMON.ERROR_DELETE'),
      // text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
      // type: 'warning'
      // });
      // comment
      cov_s3cqqww5u.s[129]++;
      $CRUDService.delet(PATH, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_s3cqqww5u.f[31]++;
        cov_s3cqqww5u.s[130]++;

        if (data < 0) {
          cov_s3cqqww5u.b[33][0]++;
          cov_s3cqqww5u.s[131]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
            type: 'warning'
          });
          cov_s3cqqww5u.s[132]++;
          $scope.isloading = false;
        } else {
          cov_s3cqqww5u.b[33][1]++;
          cov_s3cqqww5u.s[133]++;

          $scope.getAll();
        }
      });
    });
  };

  cov_s3cqqww5u.s[134]++;
  $scope.cancelItem = function () {
    cov_s3cqqww5u.f[32]++;
    cov_s3cqqww5u.s[135]++;

    $scope.validateItem = null;
  };

  cov_s3cqqww5u.s[136]++;
  $scope.addEvent = function () {
    cov_s3cqqww5u.f[33]++;
    cov_s3cqqww5u.s[137]++;

    $('link_open_report_' + $scope.id_t).on('click', function (event) {
      cov_s3cqqww5u.f[34]++;
    });
  };
  cov_s3cqqww5u.s[138]++;
  $scope.addEvent();
  cov_s3cqqww5u.s[139]++;
  $scope.getAll();
});

function MissionsCtrl_document($scope, $uibModalInstance, $CRUDService, $window, $log) {
  cov_s3cqqww5u.f[35]++;

  var PATH_DOC = (cov_s3cqqww5u.s[140]++, 'Documents');
  cov_s3cqqww5u.s[141]++;
  $scope.closeDetail = function () {
    cov_s3cqqww5u.f[36]++;
    cov_s3cqqww5u.s[142]++;

    $uibModalInstance.dismiss('cancel');
  };
  cov_s3cqqww5u.s[143]++;
  $scope.isModalPreview = true;
  cov_s3cqqww5u.s[144]++;
  $scope.downloadItem = function (item) {
    cov_s3cqqww5u.f[37]++;
    cov_s3cqqww5u.s[145]++;

    /* if (item.TYPE_RAPPORT == 1) {
      $state.go('document.preview', {id: item.id});
      return;
    } */
    if (item.TYPE_RAPPORT == 2) {
      cov_s3cqqww5u.b[34][0]++;
      cov_s3cqqww5u.s[146]++;

      $window.open(item.CHEMIN_RAPP, '_blank');
      cov_s3cqqww5u.s[147]++;
      return;
    } else {
      cov_s3cqqww5u.b[34][1]++;
    }
    cov_s3cqqww5u.s[148]++;
    $CRUDService.getAll(PATH_DOC, { download: true, c: item.Reference, id: item.id }, function (data) {
      cov_s3cqqww5u.f[38]++;
      cov_s3cqqww5u.s[149]++;

      item.DOWNLOAD++;
      cov_s3cqqww5u.s[150]++;
      $CRUDService.save(PATH_DOC, { action: 'download_count', id: item.id });

      cov_s3cqqww5u.s[151]++;
      if (data.f != '') {
        cov_s3cqqww5u.b[35][0]++;
        cov_s3cqqww5u.s[152]++;

        $window.open($CRUDService.getServerStrict() + data.f, '_blank');
      } else {
        cov_s3cqqww5u.b[35][1]++;
      }
    });
  };
}

function MissionsCtrl_edit($scope, $uibModalInstance, $filter, $CRUDService, $rootScope, $deltadate, $translate, FileUploader, $log) {
  cov_s3cqqww5u.f[39]++;

  var PATH = (cov_s3cqqww5u.s[153]++, 'Mission');
  cov_s3cqqww5u.s[154]++;
  $scope.error_code = false;
  cov_s3cqqww5u.s[155]++;
  $scope.setValidData = function () {
    cov_s3cqqww5u.f[40]++;

    var validateItem = (cov_s3cqqww5u.s[156]++, angular.copy($scope.validateItem));
    cov_s3cqqww5u.s[157]++;
    $scope.error_code = 0;
    cov_s3cqqww5u.s[158]++;
    if (((cov_s3cqqww5u.b[37][0]++, validateItem.TITLE_MISSION) || (cov_s3cqqww5u.b[37][1]++, '')).trim().length == 0) {
      cov_s3cqqww5u.b[36][0]++;
      cov_s3cqqww5u.s[159]++;

      $scope.error_code = 1;
      cov_s3cqqww5u.s[160]++;
      return;
    } else {
      cov_s3cqqww5u.b[36][1]++;
    }

    cov_s3cqqww5u.s[161]++;
    if (((cov_s3cqqww5u.b[39][0]++, ((cov_s3cqqww5u.b[40][0]++, validateItem.IDTYPE_MISSION) || (cov_s3cqqww5u.b[40][1]++, {})).id) || (cov_s3cqqww5u.b[39][1]++, 0)) < 1) {
      cov_s3cqqww5u.b[38][0]++;
      cov_s3cqqww5u.s[162]++;

      $scope.error_code = 2;
      cov_s3cqqww5u.s[163]++;
      return;
    } else {
      cov_s3cqqww5u.b[38][1]++;
    }

    cov_s3cqqww5u.s[164]++;
    if (validateItem.Date_D_P.valueOf() > validateItem.Date_F_P.valueOf()) {
      cov_s3cqqww5u.b[41][0]++;
      cov_s3cqqww5u.s[165]++;

      $scope.error_code = 3;
      cov_s3cqqww5u.s[166]++;
      return;
    } else {
      cov_s3cqqww5u.b[41][1]++;
    }

    cov_s3cqqww5u.s[167]++;
    validateItem.Date_D_P = $deltadate.format(validateItem.Date_D_P, 'YYYY-MM-DD');
    cov_s3cqqww5u.s[168]++;
    validateItem.Date_F_P = $deltadate.format(validateItem.Date_F_P, 'YYYY-MM-DD');

    cov_s3cqqww5u.s[169]++;
    validateItem.TITLE_MISSION = validateItem.TITLE_MISSION.trim();
    cov_s3cqqww5u.s[170]++;
    validateItem.LIEU_MISSION = ((cov_s3cqqww5u.b[42][0]++, validateItem.LIEU_MISSION) || (cov_s3cqqww5u.b[42][1]++, '')).trim();
    cov_s3cqqww5u.s[171]++;
    validateItem.Objet_Mis = ((cov_s3cqqww5u.b[43][0]++, validateItem.Objet_Mis) || (cov_s3cqqww5u.b[43][1]++, '')).trim();

    cov_s3cqqww5u.s[172]++;
    validateItem.IDTYPE_MISSION = validateItem.IDTYPE_MISSION.id;
    cov_s3cqqww5u.s[173]++;
    validateItem.IDACTEURS = validateItem.IDACTEURS.id;

    cov_s3cqqww5u.s[174]++;
    if (validateItem.IDRAPPORTS) {
      cov_s3cqqww5u.b[44][0]++;
      cov_s3cqqww5u.s[175]++;

      validateItem.IDRAPPORTS = validateItem.IDRAPPORTS.id;
    } else {
      cov_s3cqqww5u.b[44][1]++;
    }
    var tmp_validateItem = (cov_s3cqqww5u.s[176]++, angular.copy(validateItem));
    cov_s3cqqww5u.s[177]++;
    validateItem.members = validateItem.members.map(function (member) {
      cov_s3cqqww5u.f[41]++;
      cov_s3cqqww5u.s[178]++;
      return { id: member.id };
    });
    cov_s3cqqww5u.s[179]++;
    validateItem.SEND_PERS = validateItem.SEND_PERS.map(function (value) {
      cov_s3cqqww5u.f[42]++;
      cov_s3cqqww5u.s[180]++;

      return value.id;
    }).join(' ; ');

    cov_s3cqqww5u.s[181]++;
    $CRUDService.save(PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function () {
      cov_s3cqqww5u.f[43]++;
      cov_s3cqqww5u.s[182]++;

      $scope.closeModal();
      cov_s3cqqww5u.s[183]++;
      $scope.getAll();
    });
  };

  cov_s3cqqww5u.s[184]++;
  $scope.openDocumentPanel = function () {
    cov_s3cqqww5u.f[44]++;
    cov_s3cqqww5u.s[185]++;

    $rootScope.openFolderPanel({
      parentIdMapping: 'parentID',
      hasChildMapping: 'hasChildren',
      title: 'MENU_SHORT.B_D',
      columns: [{
        field: 'text',
        headerText: $translate.instant('MENU_SHORT.B_D').toUpperCase(),
        type: 'string', clipMode: 'EllipsisWithTooltip' }]
    }, function (data) {
      cov_s3cqqww5u.f[45]++;
      cov_s3cqqww5u.s[186]++;

      if (data) {
        cov_s3cqqww5u.b[45][0]++;
        cov_s3cqqww5u.s[187]++;

        $scope.validateItem.IDRAPPORTS = data;
      } else {
        cov_s3cqqww5u.b[45][1]++;
      }
    });
  };

  cov_s3cqqww5u.s[188]++;
  $scope.closeModal = function () {
    cov_s3cqqww5u.f[46]++;
    cov_s3cqqww5u.s[189]++;

    $uibModalInstance.dismiss('cancel');
  };
}
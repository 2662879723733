'use strict';

var cov_2gfk1diyle = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/directives/dashboard-widget.js',
      hash = '9933cf669ec520e2da70fa854627825a2cb83092',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/directives/dashboard-widget.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 86,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 85,
          column: 4
        }
      },
      '2': {
        start: {
          line: 14,
          column: 6
        },
        end: {
          line: 14,
          column: 67
        }
      },
      '3': {
        start: {
          line: 15,
          column: 19
        },
        end: {
          line: 15,
          column: 28
        }
      },
      '4': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 59,
          column: 8
        }
      },
      '5': {
        start: {
          line: 17,
          column: 8
        },
        end: {
          line: 38,
          column: 9
        }
      },
      '6': {
        start: {
          line: 18,
          column: 10
        },
        end: {
          line: 37,
          column: 11
        }
      },
      '7': {
        start: {
          line: 20,
          column: 14
        },
        end: {
          line: 20,
          column: 26
        }
      },
      '8': {
        start: {
          line: 22,
          column: 14
        },
        end: {
          line: 22,
          column: 26
        }
      },
      '9': {
        start: {
          line: 24,
          column: 14
        },
        end: {
          line: 24,
          column: 26
        }
      },
      '10': {
        start: {
          line: 26,
          column: 14
        },
        end: {
          line: 26,
          column: 26
        }
      },
      '11': {
        start: {
          line: 28,
          column: 14
        },
        end: {
          line: 28,
          column: 26
        }
      },
      '12': {
        start: {
          line: 30,
          column: 14
        },
        end: {
          line: 30,
          column: 26
        }
      },
      '13': {
        start: {
          line: 32,
          column: 14
        },
        end: {
          line: 32,
          column: 26
        }
      },
      '14': {
        start: {
          line: 34,
          column: 14
        },
        end: {
          line: 34,
          column: 26
        }
      },
      '15': {
        start: {
          line: 36,
          column: 14
        },
        end: {
          line: 36,
          column: 26
        }
      },
      '16': {
        start: {
          line: 39,
          column: 8
        },
        end: {
          line: 58,
          column: 9
        }
      },
      '17': {
        start: {
          line: 41,
          column: 12
        },
        end: {
          line: 41,
          column: 24
        }
      },
      '18': {
        start: {
          line: 43,
          column: 12
        },
        end: {
          line: 43,
          column: 24
        }
      },
      '19': {
        start: {
          line: 45,
          column: 12
        },
        end: {
          line: 45,
          column: 24
        }
      },
      '20': {
        start: {
          line: 47,
          column: 12
        },
        end: {
          line: 47,
          column: 24
        }
      },
      '21': {
        start: {
          line: 49,
          column: 12
        },
        end: {
          line: 49,
          column: 24
        }
      },
      '22': {
        start: {
          line: 51,
          column: 12
        },
        end: {
          line: 51,
          column: 24
        }
      },
      '23': {
        start: {
          line: 53,
          column: 12
        },
        end: {
          line: 53,
          column: 24
        }
      },
      '24': {
        start: {
          line: 55,
          column: 12
        },
        end: {
          line: 55,
          column: 24
        }
      },
      '25': {
        start: {
          line: 57,
          column: 12
        },
        end: {
          line: 57,
          column: 24
        }
      },
      '26': {
        start: {
          line: 60,
          column: 6
        },
        end: {
          line: 83,
          column: 11
        }
      },
      '27': {
        start: {
          line: 61,
          column: 8
        },
        end: {
          line: 82,
          column: 11
        }
      },
      '28': {
        start: {
          line: 62,
          column: 10
        },
        end: {
          line: 80,
          column: 11
        }
      },
      '29': {
        start: {
          line: 63,
          column: 12
        },
        end: {
          line: 79,
          column: 13
        }
      },
      '30': {
        start: {
          line: 64,
          column: 14
        },
        end: {
          line: 70,
          column: 15
        }
      },
      '31': {
        start: {
          line: 65,
          column: 16
        },
        end: {
          line: 69,
          column: 17
        }
      },
      '32': {
        start: {
          line: 66,
          column: 18
        },
        end: {
          line: 66,
          column: 56
        }
      },
      '33': {
        start: {
          line: 68,
          column: 18
        },
        end: {
          line: 68,
          column: 40
        }
      },
      '34': {
        start: {
          line: 71,
          column: 14
        },
        end: {
          line: 74,
          column: 15
        }
      },
      '35': {
        start: {
          line: 72,
          column: 16
        },
        end: {
          line: 72,
          column: 59
        }
      },
      '36': {
        start: {
          line: 73,
          column: 16
        },
        end: {
          line: 73,
          column: 94
        }
      },
      '37': {
        start: {
          line: 75,
          column: 14
        },
        end: {
          line: 78,
          column: 15
        }
      },
      '38': {
        start: {
          line: 76,
          column: 16
        },
        end: {
          line: 76,
          column: 59
        }
      },
      '39': {
        start: {
          line: 77,
          column: 16
        },
        end: {
          line: 77,
          column: 94
        }
      },
      '40': {
        start: {
          line: 81,
          column: 10
        },
        end: {
          line: 81,
          column: 29
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 51
          },
          end: {
            line: 2,
            column: 52
          }
        },
        loc: {
          start: {
            line: 2,
            column: 57
          },
          end: {
            line: 86,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 16,
            column: 31
          },
          end: {
            line: 16,
            column: 32
          }
        },
        loc: {
          start: {
            line: 16,
            column: 48
          },
          end: {
            line: 59,
            column: 7
          }
        },
        line: 16
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 60,
            column: 7
          },
          end: {
            line: 60,
            column: 8
          }
        },
        loc: {
          start: {
            line: 60,
            column: 19
          },
          end: {
            line: 83,
            column: 7
          }
        },
        line: 60
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 61,
            column: 185
          },
          end: {
            line: 61,
            column: 186
          }
        },
        loc: {
          start: {
            line: 61,
            column: 193
          },
          end: {
            line: 82,
            column: 9
          }
        },
        line: 61
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 17,
            column: 8
          },
          end: {
            line: 38,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 17,
            column: 8
          },
          end: {
            line: 38,
            column: 9
          }
        }, {
          start: {
            line: 17,
            column: 8
          },
          end: {
            line: 38,
            column: 9
          }
        }],
        line: 17
      },
      '1': {
        loc: {
          start: {
            line: 18,
            column: 10
          },
          end: {
            line: 37,
            column: 11
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 19,
            column: 12
          },
          end: {
            line: 19,
            column: 19
          }
        }, {
          start: {
            line: 19,
            column: 20
          },
          end: {
            line: 20,
            column: 26
          }
        }, {
          start: {
            line: 21,
            column: 12
          },
          end: {
            line: 21,
            column: 19
          }
        }, {
          start: {
            line: 21,
            column: 20
          },
          end: {
            line: 22,
            column: 26
          }
        }, {
          start: {
            line: 23,
            column: 12
          },
          end: {
            line: 23,
            column: 19
          }
        }, {
          start: {
            line: 23,
            column: 20
          },
          end: {
            line: 24,
            column: 26
          }
        }, {
          start: {
            line: 25,
            column: 12
          },
          end: {
            line: 25,
            column: 19
          }
        }, {
          start: {
            line: 25,
            column: 20
          },
          end: {
            line: 26,
            column: 26
          }
        }, {
          start: {
            line: 27,
            column: 12
          },
          end: {
            line: 27,
            column: 19
          }
        }, {
          start: {
            line: 27,
            column: 20
          },
          end: {
            line: 28,
            column: 26
          }
        }, {
          start: {
            line: 29,
            column: 12
          },
          end: {
            line: 29,
            column: 19
          }
        }, {
          start: {
            line: 29,
            column: 20
          },
          end: {
            line: 30,
            column: 26
          }
        }, {
          start: {
            line: 31,
            column: 12
          },
          end: {
            line: 31,
            column: 19
          }
        }, {
          start: {
            line: 31,
            column: 20
          },
          end: {
            line: 32,
            column: 26
          }
        }, {
          start: {
            line: 33,
            column: 12
          },
          end: {
            line: 33,
            column: 19
          }
        }, {
          start: {
            line: 33,
            column: 20
          },
          end: {
            line: 34,
            column: 26
          }
        }, {
          start: {
            line: 35,
            column: 12
          },
          end: {
            line: 36,
            column: 26
          }
        }],
        line: 18
      },
      '2': {
        loc: {
          start: {
            line: 39,
            column: 8
          },
          end: {
            line: 58,
            column: 9
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 40,
            column: 10
          },
          end: {
            line: 40,
            column: 17
          }
        }, {
          start: {
            line: 40,
            column: 18
          },
          end: {
            line: 41,
            column: 24
          }
        }, {
          start: {
            line: 42,
            column: 10
          },
          end: {
            line: 42,
            column: 17
          }
        }, {
          start: {
            line: 42,
            column: 18
          },
          end: {
            line: 43,
            column: 24
          }
        }, {
          start: {
            line: 44,
            column: 10
          },
          end: {
            line: 44,
            column: 17
          }
        }, {
          start: {
            line: 44,
            column: 18
          },
          end: {
            line: 45,
            column: 24
          }
        }, {
          start: {
            line: 46,
            column: 10
          },
          end: {
            line: 46,
            column: 17
          }
        }, {
          start: {
            line: 46,
            column: 18
          },
          end: {
            line: 47,
            column: 24
          }
        }, {
          start: {
            line: 48,
            column: 10
          },
          end: {
            line: 48,
            column: 17
          }
        }, {
          start: {
            line: 48,
            column: 18
          },
          end: {
            line: 49,
            column: 24
          }
        }, {
          start: {
            line: 50,
            column: 10
          },
          end: {
            line: 50,
            column: 17
          }
        }, {
          start: {
            line: 50,
            column: 18
          },
          end: {
            line: 51,
            column: 24
          }
        }, {
          start: {
            line: 52,
            column: 10
          },
          end: {
            line: 52,
            column: 17
          }
        }, {
          start: {
            line: 52,
            column: 18
          },
          end: {
            line: 53,
            column: 24
          }
        }, {
          start: {
            line: 54,
            column: 10
          },
          end: {
            line: 54,
            column: 17
          }
        }, {
          start: {
            line: 54,
            column: 18
          },
          end: {
            line: 55,
            column: 24
          }
        }, {
          start: {
            line: 56,
            column: 10
          },
          end: {
            line: 57,
            column: 24
          }
        }],
        line: 39
      },
      '3': {
        loc: {
          start: {
            line: 61,
            column: 125
          },
          end: {
            line: 61,
            column: 181
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 61,
            column: 151
          },
          end: {
            line: 61,
            column: 176
          }
        }, {
          start: {
            line: 61,
            column: 179
          },
          end: {
            line: 61,
            column: 181
          }
        }],
        line: 61
      },
      '4': {
        loc: {
          start: {
            line: 62,
            column: 10
          },
          end: {
            line: 80,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 62,
            column: 10
          },
          end: {
            line: 80,
            column: 11
          }
        }, {
          start: {
            line: 62,
            column: 10
          },
          end: {
            line: 80,
            column: 11
          }
        }],
        line: 62
      },
      '5': {
        loc: {
          start: {
            line: 71,
            column: 14
          },
          end: {
            line: 74,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 71,
            column: 14
          },
          end: {
            line: 74,
            column: 15
          }
        }, {
          start: {
            line: 71,
            column: 14
          },
          end: {
            line: 74,
            column: 15
          }
        }],
        line: 71
      },
      '6': {
        loc: {
          start: {
            line: 71,
            column: 18
          },
          end: {
            line: 71,
            column: 62
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 71,
            column: 18
          },
          end: {
            line: 71,
            column: 38
          }
        }, {
          start: {
            line: 71,
            column: 42
          },
          end: {
            line: 71,
            column: 62
          }
        }],
        line: 71
      },
      '7': {
        loc: {
          start: {
            line: 73,
            column: 31
          },
          end: {
            line: 73,
            column: 93
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 73,
            column: 51
          },
          end: {
            line: 73,
            column: 55
          }
        }, {
          start: {
            line: 73,
            column: 59
          },
          end: {
            line: 73,
            column: 92
          }
        }],
        line: 73
      },
      '8': {
        loc: {
          start: {
            line: 75,
            column: 14
          },
          end: {
            line: 78,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 75,
            column: 14
          },
          end: {
            line: 78,
            column: 15
          }
        }, {
          start: {
            line: 75,
            column: 14
          },
          end: {
            line: 78,
            column: 15
          }
        }],
        line: 75
      },
      '9': {
        loc: {
          start: {
            line: 75,
            column: 18
          },
          end: {
            line: 75,
            column: 62
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 75,
            column: 18
          },
          end: {
            line: 75,
            column: 38
          }
        }, {
          start: {
            line: 75,
            column: 42
          },
          end: {
            line: 75,
            column: 62
          }
        }],
        line: 75
      },
      '10': {
        loc: {
          start: {
            line: 77,
            column: 31
          },
          end: {
            line: 77,
            column: 93
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 77,
            column: 51
          },
          end: {
            line: 77,
            column: 55
          }
        }, {
          start: {
            line: 77,
            column: 59
          },
          end: {
            line: 77,
            column: 92
          }
        }],
        line: 77
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      '2': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2gfk1diyle.s[0]++;

angular.module('app').directive('dashboardWidget', function () {
  cov_2gfk1diyle.f[0]++;
  cov_2gfk1diyle.s[1]++;

  return {
    restrict: 'E',
    scope: {
      data: '=',
      analyse: '=',
      titleDashboard: '@',
      height: '@'
    },
    templateUrl: 'app/views/common/dashboard-widget.html',

    controller: function controller($scope, $CRUDService, $rootScope) {
      cov_2gfk1diyle.s[2]++;

      $scope.ISINCLUDEDRAPPORT = $rootScope.getISINCLUDEDRAPPORT();
      var PATH = (cov_2gfk1diyle.s[3]++, 'Analyse');
      cov_2gfk1diyle.s[4]++;
      $scope.getCorrectIndex = function (index) {
        cov_2gfk1diyle.f[1]++;
        cov_2gfk1diyle.s[5]++;

        if ($scope.analyse.ID_INDIC) {
          cov_2gfk1diyle.b[0][0]++;
          cov_2gfk1diyle.s[6]++;

          switch (index) {
            case 8:
              cov_2gfk1diyle.b[1][0]++;
            case '8':
              cov_2gfk1diyle.b[1][1]++;
              cov_2gfk1diyle.s[7]++;

              return 'cp';
            case 7:
              cov_2gfk1diyle.b[1][2]++;
            case '7':
              cov_2gfk1diyle.b[1][3]++;
              cov_2gfk1diyle.s[8]++;

              return 'cr';
            case 6:
              cov_2gfk1diyle.b[1][4]++;
            case '6':
              cov_2gfk1diyle.b[1][5]++;
              cov_2gfk1diyle.s[9]++;

              return 'ce';
            case 2:
              cov_2gfk1diyle.b[1][6]++;
            case '2':
              cov_2gfk1diyle.b[1][7]++;
              cov_2gfk1diyle.s[10]++;

              return 've';
            case 4:
              cov_2gfk1diyle.b[1][8]++;
            case '4':
              cov_2gfk1diyle.b[1][9]++;
              cov_2gfk1diyle.s[11]++;

              return 'vp';
            case 3:
              cov_2gfk1diyle.b[1][10]++;
            case '3':
              cov_2gfk1diyle.b[1][11]++;
              cov_2gfk1diyle.s[12]++;

              return 'vr';
            case 5:
              cov_2gfk1diyle.b[1][12]++;
            case '5':
              cov_2gfk1diyle.b[1][13]++;
              cov_2gfk1diyle.s[13]++;

              return 'tc';
            case 1:
              cov_2gfk1diyle.b[1][14]++;
            case '1':
              cov_2gfk1diyle.b[1][15]++;
              cov_2gfk1diyle.s[14]++;

              return 'tr';
            default:
              cov_2gfk1diyle.b[1][16]++;
              cov_2gfk1diyle.s[15]++;

              return 'tr';
          }
        } else {
          cov_2gfk1diyle.b[0][1]++;
        }
        cov_2gfk1diyle.s[16]++;
        switch (index) {
          case 8:
            cov_2gfk1diyle.b[2][0]++;
          case '8':
            cov_2gfk1diyle.b[2][1]++;
            cov_2gfk1diyle.s[17]++;

            return 'BB';
          case 7:
            cov_2gfk1diyle.b[2][2]++;
          case '7':
            cov_2gfk1diyle.b[2][3]++;
            cov_2gfk1diyle.s[18]++;

            return 'CB';
          case 6:
            cov_2gfk1diyle.b[2][4]++;
          case '6':
            cov_2gfk1diyle.b[2][5]++;
            cov_2gfk1diyle.s[19]++;

            return 'EB';
          case 2:
            cov_2gfk1diyle.b[2][6]++;
          case '2':
            cov_2gfk1diyle.b[2][7]++;
            cov_2gfk1diyle.s[20]++;

            return 'EP';
          case 4:
            cov_2gfk1diyle.b[2][8]++;
          case '4':
            cov_2gfk1diyle.b[2][9]++;
            cov_2gfk1diyle.s[21]++;

            return 'PP';
          case 3:
            cov_2gfk1diyle.b[2][10]++;
          case '3':
            cov_2gfk1diyle.b[2][11]++;
            cov_2gfk1diyle.s[22]++;

            return 'RP';
          case 5:
            cov_2gfk1diyle.b[2][12]++;
          case '5':
            cov_2gfk1diyle.b[2][13]++;
            cov_2gfk1diyle.s[23]++;

            return 'TB';
          case 1:
            cov_2gfk1diyle.b[2][14]++;
          case '1':
            cov_2gfk1diyle.b[2][15]++;
            cov_2gfk1diyle.s[24]++;

            return 'TP';
          default:
            cov_2gfk1diyle.b[2][16]++;
            cov_2gfk1diyle.s[25]++;

            return 'TP';
        }
      };
      cov_2gfk1diyle.s[26]++;
      (function () {
        cov_2gfk1diyle.f[2]++;
        cov_2gfk1diyle.s[27]++;

        $CRUDService.getAll(PATH, Object.assign({ get: 'widget', ida: $scope.analyse.id, p_bksb_: $scope.analyse.ID_PROJET }, $scope.analyse.ID_INDIC ? (cov_2gfk1diyle.b[3][0]++, { get: 'widget_indicator' }) : (cov_2gfk1diyle.b[3][1]++, {})), function (data) {
          cov_2gfk1diyle.f[3]++;
          cov_2gfk1diyle.s[28]++;

          if ($scope.analyse.ID_INDIC) {
            cov_2gfk1diyle.b[4][0]++;
            cov_2gfk1diyle.s[29]++;

            for (var key in data) {
              cov_2gfk1diyle.s[30]++;
              var _arr = ['vp', 'vr', 'cp', 'cr'];

              for (var _i = 0; _i < _arr.length; _i++) {
                var in_ = _arr[_i];cov_2gfk1diyle.s[31]++;

                try {
                  cov_2gfk1diyle.s[32]++;

                  data[key][in_] = eval(data[key][in_]);
                } catch (e) {
                  cov_2gfk1diyle.s[33]++;

                  data[key][in_] = null;
                }
              }
              cov_2gfk1diyle.s[34]++;
              if ((cov_2gfk1diyle.b[6][0]++, data[key].vp != null) && (cov_2gfk1diyle.b[6][1]++, data[key].vr != null)) {
                cov_2gfk1diyle.b[5][0]++;
                cov_2gfk1diyle.s[35]++;

                data[key].ve = data[key].vr - data[key].vp;
                cov_2gfk1diyle.s[36]++;
                data[key].tr = data[key].vp == 0 ? (cov_2gfk1diyle.b[7][0]++, null) : (cov_2gfk1diyle.b[7][1]++, 100 * data[key].vr / data[key].vp);
              } else {
                cov_2gfk1diyle.b[5][1]++;
              }
              cov_2gfk1diyle.s[37]++;
              if ((cov_2gfk1diyle.b[9][0]++, data[key].cp != null) && (cov_2gfk1diyle.b[9][1]++, data[key].cr != null)) {
                cov_2gfk1diyle.b[8][0]++;
                cov_2gfk1diyle.s[38]++;

                data[key].ce = data[key].cp - data[key].cr;
                cov_2gfk1diyle.s[39]++;
                data[key].tc = data[key].cp == 0 ? (cov_2gfk1diyle.b[10][0]++, null) : (cov_2gfk1diyle.b[10][1]++, 100 * data[key].cr / data[key].cp);
              } else {
                cov_2gfk1diyle.b[8][1]++;
              }
            }
          } else {
            cov_2gfk1diyle.b[4][1]++;
          }
          cov_2gfk1diyle.s[40]++;
          $scope.data = data;
        });
      })();
    }
  };
});
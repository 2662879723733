'use strict';

var cov_fec70ckmz = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/audits/audits.js',
      hash = '401a70f203453dfc76cd40c499b4652f2c053998',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/audits/audits.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 239,
          column: 3
        }
      },
      '1': {
        start: {
          line: 7,
          column: 15
        },
        end: {
          line: 7,
          column: 21
        }
      },
      '2': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 24
        }
      },
      '3': {
        start: {
          line: 9,
          column: 17
        },
        end: {
          line: 9,
          column: 27
        }
      },
      '4': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 10,
          column: 39
        }
      },
      '5': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 111,
          column: 4
        }
      },
      '6': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 64
        }
      },
      '7': {
        start: {
          line: 19,
          column: 57
        },
        end: {
          line: 19,
          column: 62
        }
      },
      '8': {
        start: {
          line: 25,
          column: 20
        },
        end: {
          line: 25,
          column: 52
        }
      },
      '9': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 30,
          column: 7
        }
      },
      '10': {
        start: {
          line: 27,
          column: 8
        },
        end: {
          line: 27,
          column: 38
        }
      },
      '11': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 29,
          column: 44
        }
      },
      '12': {
        start: {
          line: 35,
          column: 20
        },
        end: {
          line: 35,
          column: 55
        }
      },
      '13': {
        start: {
          line: 36,
          column: 6
        },
        end: {
          line: 40,
          column: 7
        }
      },
      '14': {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 37,
          column: 41
        }
      },
      '15': {
        start: {
          line: 39,
          column: 8
        },
        end: {
          line: 39,
          column: 45
        }
      },
      '16': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 44,
          column: 63
        }
      },
      '17': {
        start: {
          line: 44,
          column: 56
        },
        end: {
          line: 44,
          column: 61
        }
      },
      '18': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 61,
          column: 7
        }
      },
      '19': {
        start: {
          line: 50,
          column: 10
        },
        end: {
          line: 50,
          column: 34
        }
      },
      '20': {
        start: {
          line: 52,
          column: 10
        },
        end: {
          line: 52,
          column: 16
        }
      },
      '21': {
        start: {
          line: 54,
          column: 10
        },
        end: {
          line: 54,
          column: 35
        }
      },
      '22': {
        start: {
          line: 55,
          column: 10
        },
        end: {
          line: 55,
          column: 16
        }
      },
      '23': {
        start: {
          line: 57,
          column: 10
        },
        end: {
          line: 57,
          column: 37
        }
      },
      '24': {
        start: {
          line: 58,
          column: 10
        },
        end: {
          line: 58,
          column: 16
        }
      },
      '25': {
        start: {
          line: 66,
          column: 6
        },
        end: {
          line: 70,
          column: 7
        }
      },
      '26': {
        start: {
          line: 67,
          column: 8
        },
        end: {
          line: 69,
          column: 9
        }
      },
      '27': {
        start: {
          line: 68,
          column: 10
        },
        end: {
          line: 68,
          column: 23
        }
      },
      '28': {
        start: {
          line: 71,
          column: 6
        },
        end: {
          line: 83,
          column: 7
        }
      },
      '29': {
        start: {
          line: 72,
          column: 19
        },
        end: {
          line: 72,
          column: 24
        }
      },
      '30': {
        start: {
          line: 73,
          column: 8
        },
        end: {
          line: 79,
          column: 9
        }
      },
      '31': {
        start: {
          line: 74,
          column: 24
        },
        end: {
          line: 74,
          column: 56
        }
      },
      '32': {
        start: {
          line: 75,
          column: 10
        },
        end: {
          line: 78,
          column: 11
        }
      },
      '33': {
        start: {
          line: 76,
          column: 12
        },
        end: {
          line: 76,
          column: 24
        }
      },
      '34': {
        start: {
          line: 77,
          column: 12
        },
        end: {
          line: 77,
          column: 18
        }
      },
      '35': {
        start: {
          line: 80,
          column: 8
        },
        end: {
          line: 82,
          column: 9
        }
      },
      '36': {
        start: {
          line: 81,
          column: 10
        },
        end: {
          line: 81,
          column: 23
        }
      },
      '37': {
        start: {
          line: 84,
          column: 6
        },
        end: {
          line: 96,
          column: 7
        }
      },
      '38': {
        start: {
          line: 85,
          column: 19
        },
        end: {
          line: 85,
          column: 24
        }
      },
      '39': {
        start: {
          line: 86,
          column: 8
        },
        end: {
          line: 92,
          column: 9
        }
      },
      '40': {
        start: {
          line: 87,
          column: 24
        },
        end: {
          line: 87,
          column: 57
        }
      },
      '41': {
        start: {
          line: 88,
          column: 10
        },
        end: {
          line: 91,
          column: 11
        }
      },
      '42': {
        start: {
          line: 89,
          column: 12
        },
        end: {
          line: 89,
          column: 24
        }
      },
      '43': {
        start: {
          line: 90,
          column: 12
        },
        end: {
          line: 90,
          column: 18
        }
      },
      '44': {
        start: {
          line: 93,
          column: 8
        },
        end: {
          line: 95,
          column: 9
        }
      },
      '45': {
        start: {
          line: 94,
          column: 10
        },
        end: {
          line: 94,
          column: 23
        }
      },
      '46': {
        start: {
          line: 97,
          column: 17
        },
        end: {
          line: 97,
          column: 78
        }
      },
      '47': {
        start: {
          line: 98,
          column: 6
        },
        end: {
          line: 100,
          column: 7
        }
      },
      '48': {
        start: {
          line: 99,
          column: 8
        },
        end: {
          line: 99,
          column: 20
        }
      },
      '49': {
        start: {
          line: 101,
          column: 6
        },
        end: {
          line: 101,
          column: 77
        }
      },
      '50': {
        start: {
          line: 102,
          column: 6
        },
        end: {
          line: 104,
          column: 7
        }
      },
      '51': {
        start: {
          line: 103,
          column: 8
        },
        end: {
          line: 103,
          column: 20
        }
      },
      '52': {
        start: {
          line: 105,
          column: 6
        },
        end: {
          line: 105,
          column: 80
        }
      },
      '53': {
        start: {
          line: 106,
          column: 6
        },
        end: {
          line: 108,
          column: 7
        }
      },
      '54': {
        start: {
          line: 107,
          column: 8
        },
        end: {
          line: 107,
          column: 20
        }
      },
      '55': {
        start: {
          line: 109,
          column: 6
        },
        end: {
          line: 109,
          column: 19
        }
      },
      '56': {
        start: {
          line: 112,
          column: 2
        },
        end: {
          line: 112,
          column: 25
        }
      },
      '57': {
        start: {
          line: 113,
          column: 2
        },
        end: {
          line: 113,
          column: 26
        }
      },
      '58': {
        start: {
          line: 114,
          column: 2
        },
        end: {
          line: 139,
          column: 4
        }
      },
      '59': {
        start: {
          line: 115,
          column: 4
        },
        end: {
          line: 115,
          column: 31
        }
      },
      '60': {
        start: {
          line: 116,
          column: 4
        },
        end: {
          line: 116,
          column: 22
        }
      },
      '61': {
        start: {
          line: 117,
          column: 4
        },
        end: {
          line: 121,
          column: 6
        }
      },
      '62': {
        start: {
          line: 122,
          column: 18
        },
        end: {
          line: 122,
          column: 66
        }
      },
      '63': {
        start: {
          line: 122,
          column: 58
        },
        end: {
          line: 122,
          column: 65
        }
      },
      '64': {
        start: {
          line: 123,
          column: 20
        },
        end: {
          line: 123,
          column: 73
        }
      },
      '65': {
        start: {
          line: 123,
          column: 63
        },
        end: {
          line: 123,
          column: 72
        }
      },
      '66': {
        start: {
          line: 125,
          column: 22
        },
        end: {
          line: 125,
          column: 91
        }
      },
      '67': {
        start: {
          line: 126,
          column: 4
        },
        end: {
          line: 126,
          column: 31
        }
      },
      '68': {
        start: {
          line: 128,
          column: 20
        },
        end: {
          line: 128,
          column: 87
        }
      },
      '69': {
        start: {
          line: 129,
          column: 4
        },
        end: {
          line: 129,
          column: 32
        }
      },
      '70': {
        start: {
          line: 131,
          column: 4
        },
        end: {
          line: 136,
          column: 6
        }
      },
      '71': {
        start: {
          line: 137,
          column: 4
        },
        end: {
          line: 137,
          column: 25
        }
      },
      '72': {
        start: {
          line: 141,
          column: 2
        },
        end: {
          line: 141,
          column: 119
        }
      },
      '73': {
        start: {
          line: 142,
          column: 2
        },
        end: {
          line: 142,
          column: 35
        }
      },
      '74': {
        start: {
          line: 146,
          column: 2
        },
        end: {
          line: 200,
          column: 4
        }
      },
      '75': {
        start: {
          line: 147,
          column: 4
        },
        end: {
          line: 199,
          column: 5
        }
      },
      '76': {
        start: {
          line: 148,
          column: 23
        },
        end: {
          line: 148,
          column: 57
        }
      },
      '77': {
        start: {
          line: 149,
          column: 21
        },
        end: {
          line: 149,
          column: 53
        }
      },
      '78': {
        start: {
          line: 150,
          column: 21
        },
        end: {
          line: 150,
          column: 53
        }
      },
      '79': {
        start: {
          line: 151,
          column: 21
        },
        end: {
          line: 151,
          column: 53
        }
      },
      '80': {
        start: {
          line: 152,
          column: 26
        },
        end: {
          line: 152,
          column: 63
        }
      },
      '81': {
        start: {
          line: 153,
          column: 24
        },
        end: {
          line: 153,
          column: 59
        }
      },
      '82': {
        start: {
          line: 154,
          column: 26
        },
        end: {
          line: 154,
          column: 63
        }
      },
      '83': {
        start: {
          line: 155,
          column: 23
        },
        end: {
          line: 155,
          column: 57
        }
      },
      '84': {
        start: {
          line: 156,
          column: 23
        },
        end: {
          line: 156,
          column: 57
        }
      },
      '85': {
        start: {
          line: 157,
          column: 27
        },
        end: {
          line: 157,
          column: 65
        }
      },
      '86': {
        start: {
          line: 158,
          column: 27
        },
        end: {
          line: 158,
          column: 65
        }
      },
      '87': {
        start: {
          line: 159,
          column: 28
        },
        end: {
          line: 159,
          column: 67
        }
      },
      '88': {
        start: {
          line: 160,
          column: 29
        },
        end: {
          line: 160,
          column: 69
        }
      },
      '89': {
        start: {
          line: 161,
          column: 35
        },
        end: {
          line: 161,
          column: 81
        }
      },
      '90': {
        start: {
          line: 162,
          column: 41
        },
        end: {
          line: 162,
          column: 93
        }
      },
      '91': {
        start: {
          line: 163,
          column: 26
        },
        end: {
          line: 163,
          column: 63
        }
      },
      '92': {
        start: {
          line: 164,
          column: 22
        },
        end: {
          line: 164,
          column: 55
        }
      },
      '93': {
        start: {
          line: 165,
          column: 24
        },
        end: {
          line: 165,
          column: 59
        }
      },
      '94': {
        start: {
          line: 166,
          column: 26
        },
        end: {
          line: 166,
          column: 63
        }
      },
      '95': {
        start: {
          line: 167,
          column: 30
        },
        end: {
          line: 167,
          column: 71
        }
      },
      '96': {
        start: {
          line: 168,
          column: 23
        },
        end: {
          line: 168,
          column: 57
        }
      },
      '97': {
        start: {
          line: 169,
          column: 23
        },
        end: {
          line: 169,
          column: 57
        }
      },
      '98': {
        start: {
          line: 170,
          column: 34
        },
        end: {
          line: 170,
          column: 79
        }
      },
      '99': {
        start: {
          line: 171,
          column: 26
        },
        end: {
          line: 171,
          column: 63
        }
      },
      '100': {
        start: {
          line: 172,
          column: 23
        },
        end: {
          line: 172,
          column: 57
        }
      },
      '101': {
        start: {
          line: 173,
          column: 22
        },
        end: {
          line: 173,
          column: 55
        }
      },
      '102': {
        start: {
          line: 174,
          column: 29
        },
        end: {
          line: 174,
          column: 69
        }
      },
      '103': {
        start: {
          line: 175,
          column: 19
        },
        end: {
          line: 175,
          column: 49
        }
      },
      '104': {
        start: {
          line: 176,
          column: 24
        },
        end: {
          line: 176,
          column: 59
        }
      },
      '105': {
        start: {
          line: 177,
          column: 25
        },
        end: {
          line: 177,
          column: 61
        }
      },
      '106': {
        start: {
          line: 178,
          column: 29
        },
        end: {
          line: 178,
          column: 69
        }
      },
      '107': {
        start: {
          line: 179,
          column: 31
        },
        end: {
          line: 179,
          column: 73
        }
      },
      '108': {
        start: {
          line: 180,
          column: 28
        },
        end: {
          line: 180,
          column: 65
        }
      },
      '109': {
        start: {
          line: 181,
          column: 36
        },
        end: {
          line: 181,
          column: 73
        }
      },
      '110': {
        start: {
          line: 182,
          column: 25
        },
        end: {
          line: 182,
          column: 60
        }
      },
      '111': {
        start: {
          line: 183,
          column: 32
        },
        end: {
          line: 183,
          column: 70
        }
      },
      '112': {
        start: {
          line: 184,
          column: 23
        },
        end: {
          line: 184,
          column: 56
        }
      },
      '113': {
        start: {
          line: 185,
          column: 26
        },
        end: {
          line: 185,
          column: 64
        }
      },
      '114': {
        start: {
          line: 186,
          column: 23
        },
        end: {
          line: 186,
          column: 56
        }
      },
      '115': {
        start: {
          line: 187,
          column: 22
        },
        end: {
          line: 187,
          column: 52
        }
      },
      '116': {
        start: {
          line: 189,
          column: 21
        },
        end: {
          line: 189,
          column: 46
        }
      },
      '117': {
        start: {
          line: 190,
          column: 8
        },
        end: {
          line: 194,
          column: 9
        }
      },
      '118': {
        start: {
          line: 191,
          column: 10
        },
        end: {
          line: 193,
          column: 11
        }
      },
      '119': {
        start: {
          line: 192,
          column: 12
        },
        end: {
          line: 192,
          column: 45
        }
      },
      '120': {
        start: {
          line: 195,
          column: 8
        },
        end: {
          line: 195,
          column: 47
        }
      },
      '121': {
        start: {
          line: 202,
          column: 2
        },
        end: {
          line: 210,
          column: 4
        }
      },
      '122': {
        start: {
          line: 203,
          column: 4
        },
        end: {
          line: 203,
          column: 32
        }
      },
      '123': {
        start: {
          line: 205,
          column: 17
        },
        end: {
          line: 205,
          column: 40
        }
      },
      '124': {
        start: {
          line: 207,
          column: 4
        },
        end: {
          line: 207,
          column: 103
        }
      },
      '125': {
        start: {
          line: 209,
          column: 4
        },
        end: {
          line: 209,
          column: 20
        }
      },
      '126': {
        start: {
          line: 212,
          column: 2
        },
        end: {
          line: 232,
          column: 4
        }
      },
      '127': {
        start: {
          line: 213,
          column: 4
        },
        end: {
          line: 213,
          column: 28
        }
      },
      '128': {
        start: {
          line: 214,
          column: 4
        },
        end: {
          line: 231,
          column: 7
        }
      },
      '129': {
        start: {
          line: 215,
          column: 6
        },
        end: {
          line: 215,
          column: 31
        }
      },
      '130': {
        start: {
          line: 216,
          column: 6
        },
        end: {
          line: 221,
          column: 16
        }
      },
      '131': {
        start: {
          line: 217,
          column: 21
        },
        end: {
          line: 217,
          column: 69
        }
      },
      '132': {
        start: {
          line: 218,
          column: 8
        },
        end: {
          line: 218,
          column: 34
        }
      },
      '133': {
        start: {
          line: 219,
          column: 8
        },
        end: {
          line: 219,
          column: 71
        }
      },
      '134': {
        start: {
          line: 220,
          column: 8
        },
        end: {
          line: 220,
          column: 21
        }
      },
      '135': {
        start: {
          line: 224,
          column: 6
        },
        end: {
          line: 224,
          column: 24
        }
      },
      '136': {
        start: {
          line: 225,
          column: 20
        },
        end: {
          line: 225,
          column: 53
        }
      },
      '137': {
        start: {
          line: 226,
          column: 24
        },
        end: {
          line: 226,
          column: 115
        }
      },
      '138': {
        start: {
          line: 227,
          column: 6
        },
        end: {
          line: 229,
          column: 7
        }
      },
      '139': {
        start: {
          line: 228,
          column: 8
        },
        end: {
          line: 228,
          column: 29
        }
      },
      '140': {
        start: {
          line: 230,
          column: 6
        },
        end: {
          line: 230,
          column: 40
        }
      },
      '141': {
        start: {
          line: 234,
          column: 2
        },
        end: {
          line: 238,
          column: 5
        }
      },
      '142': {
        start: {
          line: 235,
          column: 4
        },
        end: {
          line: 235,
          column: 28
        }
      },
      '143': {
        start: {
          line: 236,
          column: 4
        },
        end: {
          line: 236,
          column: 35
        }
      },
      '144': {
        start: {
          line: 237,
          column: 4
        },
        end: {
          line: 237,
          column: 25
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 47
          },
          end: {
            line: 2,
            column: 48
          }
        },
        loc: {
          start: {
            line: 2,
            column: 206
          },
          end: {
            line: 239,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 19,
            column: 48
          },
          end: {
            line: 19,
            column: 49
          }
        },
        loc: {
          start: {
            line: 19,
            column: 57
          },
          end: {
            line: 19,
            column: 62
          }
        },
        line: 19
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 44,
            column: 47
          },
          end: {
            line: 44,
            column: 48
          }
        },
        loc: {
          start: {
            line: 44,
            column: 56
          },
          end: {
            line: 44,
            column: 61
          }
        },
        line: 44
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 114,
            column: 23
          },
          end: {
            line: 114,
            column: 24
          }
        },
        loc: {
          start: {
            line: 114,
            column: 35
          },
          end: {
            line: 139,
            column: 3
          }
        },
        line: 114
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 122,
            column: 50
          },
          end: {
            line: 122,
            column: 51
          }
        },
        loc: {
          start: {
            line: 122,
            column: 58
          },
          end: {
            line: 122,
            column: 65
          }
        },
        line: 122
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 123,
            column: 53
          },
          end: {
            line: 123,
            column: 54
          }
        },
        loc: {
          start: {
            line: 123,
            column: 63
          },
          end: {
            line: 123,
            column: 72
          }
        },
        line: 123
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 146,
            column: 24
          },
          end: {
            line: 146,
            column: 25
          }
        },
        loc: {
          start: {
            line: 146,
            column: 54
          },
          end: {
            line: 200,
            column: 3
          }
        },
        line: 146
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 202,
            column: 22
          },
          end: {
            line: 202,
            column: 23
          }
        },
        loc: {
          start: {
            line: 202,
            column: 39
          },
          end: {
            line: 210,
            column: 3
          }
        },
        line: 202
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 212,
            column: 18
          },
          end: {
            line: 212,
            column: 19
          }
        },
        loc: {
          start: {
            line: 212,
            column: 30
          },
          end: {
            line: 232,
            column: 3
          }
        },
        line: 212
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 214,
            column: 121
          },
          end: {
            line: 214,
            column: 122
          }
        },
        loc: {
          start: {
            line: 214,
            column: 129
          },
          end: {
            line: 231,
            column: 5
          }
        },
        line: 214
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 216,
            column: 79
          },
          end: {
            line: 216,
            column: 80
          }
        },
        loc: {
          start: {
            line: 216,
            column: 88
          },
          end: {
            line: 221,
            column: 7
          }
        },
        line: 216
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 234,
            column: 42
          },
          end: {
            line: 234,
            column: 43
          }
        },
        loc: {
          start: {
            line: 234,
            column: 50
          },
          end: {
            line: 238,
            column: 3
          }
        },
        line: 234
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 26,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 26,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        }, {
          start: {
            line: 26,
            column: 6
          },
          end: {
            line: 30,
            column: 7
          }
        }],
        line: 26
      },
      '1': {
        loc: {
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        }, {
          start: {
            line: 36,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        }],
        line: 36
      },
      '2': {
        loc: {
          start: {
            line: 48,
            column: 6
          },
          end: {
            line: 61,
            column: 7
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 49,
            column: 8
          },
          end: {
            line: 52,
            column: 16
          }
        }, {
          start: {
            line: 53,
            column: 8
          },
          end: {
            line: 55,
            column: 16
          }
        }, {
          start: {
            line: 56,
            column: 8
          },
          end: {
            line: 58,
            column: 16
          }
        }, {
          start: {
            line: 59,
            column: 8
          },
          end: {
            line: 59,
            column: 16
          }
        }],
        line: 48
      },
      '3': {
        loc: {
          start: {
            line: 66,
            column: 6
          },
          end: {
            line: 70,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 66,
            column: 6
          },
          end: {
            line: 70,
            column: 7
          }
        }, {
          start: {
            line: 66,
            column: 6
          },
          end: {
            line: 70,
            column: 7
          }
        }],
        line: 66
      },
      '4': {
        loc: {
          start: {
            line: 67,
            column: 8
          },
          end: {
            line: 69,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 8
          },
          end: {
            line: 69,
            column: 9
          }
        }, {
          start: {
            line: 67,
            column: 8
          },
          end: {
            line: 69,
            column: 9
          }
        }],
        line: 67
      },
      '5': {
        loc: {
          start: {
            line: 71,
            column: 6
          },
          end: {
            line: 83,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 71,
            column: 6
          },
          end: {
            line: 83,
            column: 7
          }
        }, {
          start: {
            line: 71,
            column: 6
          },
          end: {
            line: 83,
            column: 7
          }
        }],
        line: 71
      },
      '6': {
        loc: {
          start: {
            line: 75,
            column: 10
          },
          end: {
            line: 78,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 75,
            column: 10
          },
          end: {
            line: 78,
            column: 11
          }
        }, {
          start: {
            line: 75,
            column: 10
          },
          end: {
            line: 78,
            column: 11
          }
        }],
        line: 75
      },
      '7': {
        loc: {
          start: {
            line: 80,
            column: 8
          },
          end: {
            line: 82,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 80,
            column: 8
          },
          end: {
            line: 82,
            column: 9
          }
        }, {
          start: {
            line: 80,
            column: 8
          },
          end: {
            line: 82,
            column: 9
          }
        }],
        line: 80
      },
      '8': {
        loc: {
          start: {
            line: 84,
            column: 6
          },
          end: {
            line: 96,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 84,
            column: 6
          },
          end: {
            line: 96,
            column: 7
          }
        }, {
          start: {
            line: 84,
            column: 6
          },
          end: {
            line: 96,
            column: 7
          }
        }],
        line: 84
      },
      '9': {
        loc: {
          start: {
            line: 88,
            column: 10
          },
          end: {
            line: 91,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 88,
            column: 10
          },
          end: {
            line: 91,
            column: 11
          }
        }, {
          start: {
            line: 88,
            column: 10
          },
          end: {
            line: 91,
            column: 11
          }
        }],
        line: 88
      },
      '10': {
        loc: {
          start: {
            line: 93,
            column: 8
          },
          end: {
            line: 95,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 93,
            column: 8
          },
          end: {
            line: 95,
            column: 9
          }
        }, {
          start: {
            line: 93,
            column: 8
          },
          end: {
            line: 95,
            column: 9
          }
        }],
        line: 93
      },
      '11': {
        loc: {
          start: {
            line: 98,
            column: 6
          },
          end: {
            line: 100,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 98,
            column: 6
          },
          end: {
            line: 100,
            column: 7
          }
        }, {
          start: {
            line: 98,
            column: 6
          },
          end: {
            line: 100,
            column: 7
          }
        }],
        line: 98
      },
      '12': {
        loc: {
          start: {
            line: 102,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 102,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        }, {
          start: {
            line: 102,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        }],
        line: 102
      },
      '13': {
        loc: {
          start: {
            line: 106,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 106,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        }, {
          start: {
            line: 106,
            column: 6
          },
          end: {
            line: 108,
            column: 7
          }
        }],
        line: 106
      },
      '14': {
        loc: {
          start: {
            line: 146,
            column: 40
          },
          end: {
            line: 146,
            column: 52
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 146,
            column: 48
          },
          end: {
            line: 146,
            column: 52
          }
        }],
        line: 146
      },
      '15': {
        loc: {
          start: {
            line: 147,
            column: 4
          },
          end: {
            line: 199,
            column: 5
          }
        },
        type: 'switch',
        locations: [{
          start: {
            line: 148,
            column: 6
          },
          end: {
            line: 148,
            column: 57
          }
        }, {
          start: {
            line: 149,
            column: 6
          },
          end: {
            line: 149,
            column: 53
          }
        }, {
          start: {
            line: 150,
            column: 6
          },
          end: {
            line: 150,
            column: 53
          }
        }, {
          start: {
            line: 151,
            column: 6
          },
          end: {
            line: 151,
            column: 53
          }
        }, {
          start: {
            line: 152,
            column: 6
          },
          end: {
            line: 152,
            column: 63
          }
        }, {
          start: {
            line: 153,
            column: 6
          },
          end: {
            line: 153,
            column: 59
          }
        }, {
          start: {
            line: 154,
            column: 6
          },
          end: {
            line: 154,
            column: 63
          }
        }, {
          start: {
            line: 155,
            column: 6
          },
          end: {
            line: 155,
            column: 57
          }
        }, {
          start: {
            line: 156,
            column: 6
          },
          end: {
            line: 156,
            column: 57
          }
        }, {
          start: {
            line: 157,
            column: 6
          },
          end: {
            line: 157,
            column: 65
          }
        }, {
          start: {
            line: 158,
            column: 6
          },
          end: {
            line: 158,
            column: 65
          }
        }, {
          start: {
            line: 159,
            column: 6
          },
          end: {
            line: 159,
            column: 67
          }
        }, {
          start: {
            line: 160,
            column: 6
          },
          end: {
            line: 160,
            column: 69
          }
        }, {
          start: {
            line: 161,
            column: 6
          },
          end: {
            line: 161,
            column: 81
          }
        }, {
          start: {
            line: 162,
            column: 6
          },
          end: {
            line: 162,
            column: 93
          }
        }, {
          start: {
            line: 163,
            column: 6
          },
          end: {
            line: 163,
            column: 63
          }
        }, {
          start: {
            line: 164,
            column: 6
          },
          end: {
            line: 164,
            column: 55
          }
        }, {
          start: {
            line: 165,
            column: 6
          },
          end: {
            line: 165,
            column: 59
          }
        }, {
          start: {
            line: 166,
            column: 6
          },
          end: {
            line: 166,
            column: 63
          }
        }, {
          start: {
            line: 167,
            column: 6
          },
          end: {
            line: 167,
            column: 71
          }
        }, {
          start: {
            line: 168,
            column: 6
          },
          end: {
            line: 168,
            column: 57
          }
        }, {
          start: {
            line: 169,
            column: 6
          },
          end: {
            line: 169,
            column: 57
          }
        }, {
          start: {
            line: 170,
            column: 6
          },
          end: {
            line: 170,
            column: 79
          }
        }, {
          start: {
            line: 171,
            column: 6
          },
          end: {
            line: 171,
            column: 63
          }
        }, {
          start: {
            line: 172,
            column: 6
          },
          end: {
            line: 172,
            column: 57
          }
        }, {
          start: {
            line: 173,
            column: 6
          },
          end: {
            line: 173,
            column: 55
          }
        }, {
          start: {
            line: 174,
            column: 6
          },
          end: {
            line: 174,
            column: 69
          }
        }, {
          start: {
            line: 175,
            column: 6
          },
          end: {
            line: 175,
            column: 49
          }
        }, {
          start: {
            line: 176,
            column: 6
          },
          end: {
            line: 176,
            column: 59
          }
        }, {
          start: {
            line: 177,
            column: 6
          },
          end: {
            line: 177,
            column: 61
          }
        }, {
          start: {
            line: 178,
            column: 6
          },
          end: {
            line: 178,
            column: 69
          }
        }, {
          start: {
            line: 179,
            column: 6
          },
          end: {
            line: 179,
            column: 73
          }
        }, {
          start: {
            line: 180,
            column: 6
          },
          end: {
            line: 180,
            column: 65
          }
        }, {
          start: {
            line: 181,
            column: 6
          },
          end: {
            line: 181,
            column: 73
          }
        }, {
          start: {
            line: 182,
            column: 6
          },
          end: {
            line: 182,
            column: 60
          }
        }, {
          start: {
            line: 183,
            column: 6
          },
          end: {
            line: 183,
            column: 70
          }
        }, {
          start: {
            line: 184,
            column: 6
          },
          end: {
            line: 184,
            column: 56
          }
        }, {
          start: {
            line: 185,
            column: 6
          },
          end: {
            line: 185,
            column: 64
          }
        }, {
          start: {
            line: 186,
            column: 6
          },
          end: {
            line: 186,
            column: 56
          }
        }, {
          start: {
            line: 187,
            column: 6
          },
          end: {
            line: 187,
            column: 52
          }
        }, {
          start: {
            line: 188,
            column: 6
          },
          end: {
            line: 195,
            column: 47
          }
        }, {
          start: {
            line: 197,
            column: 6
          },
          end: {
            line: 197,
            column: 14
          }
        }],
        line: 147
      },
      '16': {
        loc: {
          start: {
            line: 189,
            column: 22
          },
          end: {
            line: 189,
            column: 33
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 189,
            column: 22
          },
          end: {
            line: 189,
            column: 27
          }
        }, {
          start: {
            line: 189,
            column: 31
          },
          end: {
            line: 189,
            column: 33
          }
        }],
        line: 189
      },
      '17': {
        loc: {
          start: {
            line: 190,
            column: 8
          },
          end: {
            line: 194,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 190,
            column: 8
          },
          end: {
            line: 194,
            column: 9
          }
        }, {
          start: {
            line: 190,
            column: 8
          },
          end: {
            line: 194,
            column: 9
          }
        }],
        line: 190
      },
      '18': {
        loc: {
          start: {
            line: 191,
            column: 10
          },
          end: {
            line: 193,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 191,
            column: 10
          },
          end: {
            line: 193,
            column: 11
          }
        }, {
          start: {
            line: 191,
            column: 10
          },
          end: {
            line: 193,
            column: 11
          }
        }],
        line: 191
      },
      '19': {
        loc: {
          start: {
            line: 226,
            column: 24
          },
          end: {
            line: 226,
            column: 115
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 226,
            column: 42
          },
          end: {
            line: 226,
            column: 43
          }
        }, {
          start: {
            line: 226,
            column: 47
          },
          end: {
            line: 226,
            column: 114
          }
        }],
        line: 226
      },
      '20': {
        loc: {
          start: {
            line: 226,
            column: 95
          },
          end: {
            line: 226,
            column: 113
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 226,
            column: 108
          },
          end: {
            line: 226,
            column: 109
          }
        }, {
          start: {
            line: 226,
            column: 112
          },
          end: {
            line: 226,
            column: 113
          }
        }],
        line: 226
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0, 0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0],
      '15': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_fec70ckmz.s[0]++;

angular.module('app').controller('auditsCtrl', function ($scope, SweetAlert, $http, $deltadate, $translate, $CRUDService, $otherHttpService, $deltahttp, $location, $deltaActor, $filter, FileUploader, $rootScope) {
  cov_fec70ckmz.f[0]++;

  /* $rootScope.processPageRight('1_3');
  if ($rootScope.global_access_page_denied) {
    return;
  } */
  var PATH = (cov_fec70ckmz.s[1]++, 'User');
  cov_fec70ckmz.s[2]++;
  $scope.listUsers = [];
  var actual = (cov_fec70ckmz.s[3]++, new Date());
  cov_fec70ckmz.s[4]++;
  actual.setDate(actual.getDate() - 8);
  cov_fec70ckmz.s[5]++;
  $scope.filter = {
    fromDate: actual,
    toDate: new Date(),
    searchText: '',
    selectedAction: [],
    selectedUsers: [],
    /** USER */
    selectAllUsers: function selectAllUsers() {
      cov_fec70ckmz.s[6]++;

      this.selectedUsers = $scope.listUsers.map(function (value) {
        cov_fec70ckmz.f[1]++;
        cov_fec70ckmz.s[7]++;
        return value;
      });

      // $scope.initProcess();
      // this.processFilter();
    },
    addUser: function addUser(user) {
      var index = (cov_fec70ckmz.s[8]++, this.selectedUsers.indexOf(user));
      cov_fec70ckmz.s[9]++;
      if (index < 0) {
        cov_fec70ckmz.b[0][0]++;
        cov_fec70ckmz.s[10]++;

        this.selectedUsers.push(user);
      } else {
        cov_fec70ckmz.b[0][1]++;
        cov_fec70ckmz.s[11]++;

        this.selectedUsers.splice(index, 1);
      }
      // $scope.initProcess();
    },

    /** ACTIONS */
    addAction: function addAction(action) {
      var index = (cov_fec70ckmz.s[12]++, this.selectedAction.indexOf(action));
      cov_fec70ckmz.s[13]++;
      if (index < 0) {
        cov_fec70ckmz.b[1][0]++;
        cov_fec70ckmz.s[14]++;

        this.selectedAction.push(action);
      } else {
        cov_fec70ckmz.b[1][1]++;
        cov_fec70ckmz.s[15]++;

        this.selectedAction.splice(index, 1);
      }
      // this.processFilter();
    },
    selectAllActions: function selectAllActions() {
      cov_fec70ckmz.s[16]++;

      this.selectedAction = $scope.actions.map(function (value) {
        cov_fec70ckmz.f[2]++;
        cov_fec70ckmz.s[17]++;
        return value;
      });
    },

    /** Unselected all */
    UnselectedAll: function UnselectedAll(condition) {
      cov_fec70ckmz.s[18]++;

      switch (condition) {
        case 1:
          cov_fec70ckmz.b[2][0]++;
          cov_fec70ckmz.s[19]++;

          this.selectedUsers = [];
          // $scope.initProcess();
          cov_fec70ckmz.s[20]++;
          break;
        case 2:
          cov_fec70ckmz.b[2][1]++;
          cov_fec70ckmz.s[21]++;

          this.selectedAction = [];
          cov_fec70ckmz.s[22]++;
          break;
        case 3:
          cov_fec70ckmz.b[2][2]++;
          cov_fec70ckmz.s[23]++;

          this.selectedAgencies = [];
          cov_fec70ckmz.s[24]++;
          break;
        default:
          cov_fec70ckmz.b[2][3]++;


      }
      // this.processFilter();
    },
    processFilter: function processFilter(value) {
      cov_fec70ckmz.s[25]++;

      if ($scope.filter.selectedAgencies.length > 0) {
        cov_fec70ckmz.b[3][0]++;
        cov_fec70ckmz.s[26]++;

        if ($scope.filter.selectedAgencies.indexOf(value.ID_AGENCY) < 0) {
          cov_fec70ckmz.b[4][0]++;
          cov_fec70ckmz.s[27]++;

          return false;
        } else {
          cov_fec70ckmz.b[4][1]++;
        }
      } else {
        cov_fec70ckmz.b[3][1]++;
      }
      cov_fec70ckmz.s[28]++;
      if ($scope.filter.selectedGroups.length > 0) {
        cov_fec70ckmz.b[5][0]++;

        var find = (cov_fec70ckmz.s[29]++, false);
        cov_fec70ckmz.s[30]++;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = $scope.filter.selectedGroups[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var group = _step.value;

            var index = (cov_fec70ckmz.s[31]++, group.USERLIST.indexOf(value.id));
            cov_fec70ckmz.s[32]++;
            if (index >= 0) {
              cov_fec70ckmz.b[6][0]++;
              cov_fec70ckmz.s[33]++;

              find = true;
              cov_fec70ckmz.s[34]++;
              break;
            } else {
              cov_fec70ckmz.b[6][1]++;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        cov_fec70ckmz.s[35]++;
        if (!find) {
          cov_fec70ckmz.b[7][0]++;
          cov_fec70ckmz.s[36]++;

          return false;
        } else {
          cov_fec70ckmz.b[7][1]++;
        }
      } else {
        cov_fec70ckmz.b[5][1]++;
      }
      cov_fec70ckmz.s[37]++;
      if ($scope.filter.selectedProfil.length > 0) {
        cov_fec70ckmz.b[8][0]++;

        var _find = (cov_fec70ckmz.s[38]++, false);
        cov_fec70ckmz.s[39]++;
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = $scope.filter.selectedProfil[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var _group = _step2.value;

            var _index = (cov_fec70ckmz.s[40]++, _group.USER_LIST.indexOf(value.id));
            cov_fec70ckmz.s[41]++;
            if (_index >= 0) {
              cov_fec70ckmz.b[9][0]++;
              cov_fec70ckmz.s[42]++;

              _find = true;
              cov_fec70ckmz.s[43]++;
              break;
            } else {
              cov_fec70ckmz.b[9][1]++;
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        cov_fec70ckmz.s[44]++;
        if (!_find) {
          cov_fec70ckmz.b[10][0]++;
          cov_fec70ckmz.s[45]++;

          return false;
        } else {
          cov_fec70ckmz.b[10][1]++;
        }
      } else {
        cov_fec70ckmz.b[8][1]++;
      }
      var rest = (cov_fec70ckmz.s[46]++, $filter('filter')([value], { LOGIN: $scope.filter.searchText }));
      cov_fec70ckmz.s[47]++;
      if (rest.length > 0) {
        cov_fec70ckmz.b[11][0]++;
        cov_fec70ckmz.s[48]++;

        return true;
      } else {
        cov_fec70ckmz.b[11][1]++;
      }
      cov_fec70ckmz.s[49]++;
      rest = $filter('filter')([value], { NOMUSER: $scope.filter.searchText });
      cov_fec70ckmz.s[50]++;
      if (rest.length > 0) {
        cov_fec70ckmz.b[12][0]++;
        cov_fec70ckmz.s[51]++;

        return true;
      } else {
        cov_fec70ckmz.b[12][1]++;
      }
      cov_fec70ckmz.s[52]++;
      rest = $filter('filter')([value], { PRENOMUSER: $scope.filter.searchText });
      cov_fec70ckmz.s[53]++;
      if (rest.length > 0) {
        cov_fec70ckmz.b[13][0]++;
        cov_fec70ckmz.s[54]++;

        return true;
      } else {
        cov_fec70ckmz.b[13][1]++;
      }
      cov_fec70ckmz.s[55]++;
      return false;
    }
  };
  cov_fec70ckmz.s[56]++;
  $scope.itembyPage = 30;
  cov_fec70ckmz.s[57]++;
  $scope.pagedisplay = 10;
  cov_fec70ckmz.s[58]++;
  $scope.initProcess = function () {
    cov_fec70ckmz.f[3]++;
    cov_fec70ckmz.s[59]++;

    $scope.listAuditUsers = [];
    cov_fec70ckmz.s[60]++;
    $scope.limit = 11;
    cov_fec70ckmz.s[61]++;
    $scope.pagging = {
      page: 1,
      totalPage: 1,
      items: 20
    };
    var user_ = (cov_fec70ckmz.s[62]++, $scope.filter.selectedUsers.map(function (user) {
      cov_fec70ckmz.f[4]++;
      cov_fec70ckmz.s[63]++;
      return user.id;
    }));
    var action_ = (cov_fec70ckmz.s[64]++, $scope.filter.selectedAction.map(function (action) {
      cov_fec70ckmz.f[5]++;
      cov_fec70ckmz.s[65]++;
      return action.id;
    }));

    var fromDate = (cov_fec70ckmz.s[66]++, Date.newDate($deltadate.format($scope.filter.fromDate, 'YYYY-mm-dd')));
    cov_fec70ckmz.s[67]++;
    fromDate.setHours(0, 0, 0);

    var toDate = (cov_fec70ckmz.s[68]++, Date.newDate($deltadate.format($scope.filter.toDate, 'YYYY-mm-dd')));
    cov_fec70ckmz.s[69]++;
    toDate.setHours(23, 59, 59);

    cov_fec70ckmz.s[70]++;
    $scope.params = {
      users: user_.join(' , '),
      action: action_.join(' , '),
      date_from: fromDate.getTime() / 1000,
      date_to: toDate.getTime() / 1000
    };
    cov_fec70ckmz.s[71]++;
    $scope.changePage(1);
    // $scope.getAll();
  };

  cov_fec70ckmz.s[72]++;
  $scope.actions = [{ id: 1, label: 'AUDITS.CREATE' }, { id: 2, label: 'AUDITS.UPDATE' }, { id: 3, label: 'AUDITS.DELETE' }];
  cov_fec70ckmz.s[73]++;
  $scope.filter.selectAllActions();

  // $translate.instant();

  cov_fec70ckmz.s[74]++;
  $scope.getTypeLabel = function (type) {
    var label = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_fec70ckmz.b[14][0]++, null);
    cov_fec70ckmz.f[6]++;
    cov_fec70ckmz.s[75]++;

    switch (type) {
      case 'Agencies':
        cov_fec70ckmz.b[15][0]++;
        cov_fec70ckmz.s[76]++;
        return 'AUDITS.ENTITIES.AGENCIES';
      case 'Projet':
        cov_fec70ckmz.b[15][1]++;
        cov_fec70ckmz.s[77]++;
        return 'AUDITS.ENTITIES.PROJET';
      case 'Unites':
        cov_fec70ckmz.b[15][2]++;
        cov_fec70ckmz.s[78]++;
        return 'AUDITS.ENTITIES.UNITES';
      case 'ACTEUR':
        cov_fec70ckmz.b[15][3]++;
        cov_fec70ckmz.s[79]++;
        return 'AUDITS.ENTITIES.ACTEUR';
      case 'Financement':
        cov_fec70ckmz.b[15][4]++;
        cov_fec70ckmz.s[80]++;
        return 'AUDITS.ENTITIES.FINANCEMENT';
      case 'NiveauGeo':
        cov_fec70ckmz.b[15][5]++;
        cov_fec70ckmz.s[81]++;
        return 'AUDITS.ENTITIES.NIVEAUGEO';
      case 'ChampsPerso':
        cov_fec70ckmz.b[15][6]++;
        cov_fec70ckmz.s[82]++;
        return 'AUDITS.ENTITIES.CHAMPSPERSO';
      case 'Localite':
        cov_fec70ckmz.b[15][7]++;
        cov_fec70ckmz.s[83]++;
        return 'AUDITS.ENTITIES.LOCALITE';
      case 'NiveauCl':
        cov_fec70ckmz.b[15][8]++;
        cov_fec70ckmz.s[84]++;
        return 'AUDITS.ENTITIES.NIVEAUCL';
      case 'CadreLogique':
        cov_fec70ckmz.b[15][9]++;
        cov_fec70ckmz.s[85]++;
        return 'AUDITS.ENTITIES.CADRELOGIQUE';
      case 'TypeActivity':
        cov_fec70ckmz.b[15][10]++;
        cov_fec70ckmz.s[86]++;
        return 'AUDITS.ENTITIES.TYPEACTIVITY';
      case 'DernierNiveau':
        cov_fec70ckmz.b[15][11]++;
        cov_fec70ckmz.s[87]++;
        return 'AUDITS.ENTITIES.DERNIERNIVEAU';
      case 'TypeIndicateur':
        cov_fec70ckmz.b[15][12]++;
        cov_fec70ckmz.s[88]++;
        return 'AUDITS.ENTITIES.TYPEINDICATEUR';
      case 'IndicateursCategorie':
        cov_fec70ckmz.b[15][13]++;
        cov_fec70ckmz.s[89]++;
        return 'AUDITS.ENTITIES.INDICATEURSCATEGORIE';
      case 'IndicateursCategorieOption':
        cov_fec70ckmz.b[15][14]++;
        cov_fec70ckmz.s[90]++;
        return 'AUDITS.ENTITIES.INDICATEURSCATEGORIEOPTION';
      case 'Indicateurs':
        cov_fec70ckmz.b[15][15]++;
        cov_fec70ckmz.s[91]++;
        return 'AUDITS.ENTITIES.INDICATEURS';
      case 'Analyse':
        cov_fec70ckmz.b[15][16]++;
        cov_fec70ckmz.s[92]++;
        return 'AUDITS.ENTITIES.ANALYSE';
      case 'Dashboard':
        cov_fec70ckmz.b[15][17]++;
        cov_fec70ckmz.s[93]++;
        return 'AUDITS.ENTITIES.DASHBOARD';
      case 'TypeRapport':
        cov_fec70ckmz.b[15][18]++;
        cov_fec70ckmz.s[94]++;
        return 'AUDITS.ENTITIES.TYPERAPPORT';
      case 'CalendarRapport':
        cov_fec70ckmz.b[15][19]++;
        cov_fec70ckmz.s[95]++;
        return 'AUDITS.ENTITIES.CALENDARRAPPORT';
      case 'Classeur':
        cov_fec70ckmz.b[15][20]++;
        cov_fec70ckmz.s[96]++;
        return 'AUDITS.ENTITIES.CLASSEUR';
      case 'Rapports':
        cov_fec70ckmz.b[15][21]++;
        cov_fec70ckmz.s[97]++;
        return 'AUDITS.ENTITIES.RAPPORTS';
      case 'ClasseurPhototheque':
        cov_fec70ckmz.b[15][22]++;
        cov_fec70ckmz.s[98]++;
        return 'AUDITS.ENTITIES.CLASSEURPHOTOTHEQUE';
      case 'Phototheque':
        cov_fec70ckmz.b[15][23]++;
        cov_fec70ckmz.s[99]++;
        return 'AUDITS.ENTITIES.PHOTOTHEQUE';
      case 'Formular':
        cov_fec70ckmz.b[15][24]++;
        cov_fec70ckmz.s[100]++;
        return 'AUDITS.ENTITIES.FORMULAR';
      case 'Records':
        cov_fec70ckmz.b[15][25]++;
        cov_fec70ckmz.s[101]++;
        return 'AUDITS.ENTITIES.RECORDS';
      case 'FormlarRequest':
        cov_fec70ckmz.b[15][26]++;
        cov_fec70ckmz.s[102]++;
        return 'AUDITS.ENTITIES.FORMLARREQUEST';
      case 'USER':
        cov_fec70ckmz.b[15][27]++;
        cov_fec70ckmz.s[103]++;
        return 'AUDITS.ENTITIES.USER';
      case 'GroupUser':
        cov_fec70ckmz.b[15][28]++;
        cov_fec70ckmz.s[104]++;
        return 'AUDITS.ENTITIES.GROUPUSER';
      case 'ProfilUser':
        cov_fec70ckmz.b[15][29]++;
        cov_fec70ckmz.s[105]++;
        return 'AUDITS.ENTITIES.PROFILUSER';
      case 'CategoryBudget':
        cov_fec70ckmz.b[15][30]++;
        cov_fec70ckmz.s[106]++;
        return 'AUDITS.ENTITIES.CATEGORYBUDGET';
      case 'DistributeAmount':
        cov_fec70ckmz.b[15][31]++;
        cov_fec70ckmz.s[107]++;
        return 'AUDITS.ENTITIES.DISTRIBUTEAMOUNT';
      case 'Planification':
        cov_fec70ckmz.b[15][32]++;
        cov_fec70ckmz.s[108]++;
        return 'AUDITS.ENTITIES.GLOBAL_PLAN';
      case 'PlanificationAnnuelle':
        cov_fec70ckmz.b[15][33]++;
        cov_fec70ckmz.s[109]++;
        return 'AUDITS.ENTITIES.ANNUAL_PLAN';
      case 'Agreements':
        cov_fec70ckmz.b[15][34]++;
        cov_fec70ckmz.s[110]++;
        return 'AUDITS.ENTITIES.AGREEMENT';
      case 'FundsDisbursement':
        cov_fec70ckmz.b[15][35]++;
        cov_fec70ckmz.s[111]++;
        return 'AUDITS.ENTITIES.DISBURSEMENT';
      case 'Missions':
        cov_fec70ckmz.b[15][36]++;
        cov_fec70ckmz.s[112]++;
        return 'AUDITS.ENTITIES.MISSION';
      case 'TypeMission':
        cov_fec70ckmz.b[15][37]++;
        cov_fec70ckmz.s[113]++;
        return 'AUDITS.ENTITIES.TYPE_MISSION';
      case 'Problems':
        cov_fec70ckmz.b[15][38]++;
        cov_fec70ckmz.s[114]++;
        return 'AUDITS.ENTITIES.PROBLEM';
      case 'Risques':
        cov_fec70ckmz.b[15][39]++;
        cov_fec70ckmz.s[115]++;
        return 'AUDITS.ENTITIES.RISK';
      case 'Relever_b_niv':
        cov_fec70ckmz.b[15][40]++;

        var list = (cov_fec70ckmz.s[116]++, ((cov_fec70ckmz.b[16][0]++, label) || (cov_fec70ckmz.b[16][1]++, '')).split('\t'));
        cov_fec70ckmz.s[117]++;
        if (list.length > 0) {
          cov_fec70ckmz.b[17][0]++;
          cov_fec70ckmz.s[118]++;

          if (list[list.length - 1] == 2) {
            cov_fec70ckmz.b[18][0]++;
            cov_fec70ckmz.s[119]++;

            return 'AUDITS.ENTITIES.REALIZE';
          } else {
            cov_fec70ckmz.b[18][1]++;
          }
        } else {
          cov_fec70ckmz.b[17][1]++;
        }
        cov_fec70ckmz.s[120]++;
        return 'AUDITS.ENTITIES.PERIODIC_PLAN';

      default:
        cov_fec70ckmz.b[15][41]++;


    }
  };

  cov_fec70ckmz.s[121]++;
  $scope.changePage = function (index) {
    cov_fec70ckmz.f[7]++;
    cov_fec70ckmz.s[122]++;

    $scope.pagging.page = index;

    var page = (cov_fec70ckmz.s[123]++, $scope.pagging.page - 1);

    cov_fec70ckmz.s[124]++;
    $scope.limitMin = (page - page % $scope.pagedisplay) / $scope.pagedisplay * $scope.pagedisplay;

    cov_fec70ckmz.s[125]++;
    $scope.getAll();
  };

  cov_fec70ckmz.s[126]++;
  $scope.getAll = function () {
    cov_fec70ckmz.f[8]++;
    cov_fec70ckmz.s[127]++;

    $scope.isloading = true;
    cov_fec70ckmz.s[128]++;
    $CRUDService.getAll(PATH, Object.assign({ get: 'all_audit', pagging: angular.toJson($scope.pagging) }, $scope.params), function (data) {
      cov_fec70ckmz.f[9]++;
      cov_fec70ckmz.s[129]++;

      $scope.isloading = false;
      cov_fec70ckmz.s[130]++;
      $scope.listAuditUsers = /* $scope.listAuditUsers.concat( */data.data.map(function (value) {
        cov_fec70ckmz.f[10]++;

        var date = (cov_fec70ckmz.s[131]++, new Date(parseInt(value.date_action, 10) * 1000));
        cov_fec70ckmz.s[132]++;
        value.date_action_ = date;
        cov_fec70ckmz.s[133]++;
        value.labelType = $scope.getTypeLabel(value.type, value.label);
        cov_fec70ckmz.s[134]++;
        return value;
      }) /* ) */;
      // $scope.filter.selectAllUsers();

      cov_fec70ckmz.s[135]++;
      $scope.pages = [];
      var rest = (cov_fec70ckmz.s[136]++, data.count % $scope.pagging.items);
      var totalPage = (cov_fec70ckmz.s[137]++, data.count == 0 ? (cov_fec70ckmz.b[19][0]++, 1) : (cov_fec70ckmz.b[19][1]++, (data.count - rest) / $scope.pagging.items + (rest === 0 ? (cov_fec70ckmz.b[20][0]++, 0) : (cov_fec70ckmz.b[20][1]++, 1))));
      cov_fec70ckmz.s[138]++;
      for (var i = 1; i <= totalPage; i++) {
        cov_fec70ckmz.s[139]++;

        $scope.pages.push(i);
      }
      cov_fec70ckmz.s[140]++;
      $scope.pagging.count = data.count;
    });
  };

  cov_fec70ckmz.s[141]++;
  $CRUDService.getAll(PATH, { get: 'all' }, function (data) {
    cov_fec70ckmz.f[11]++;
    cov_fec70ckmz.s[142]++;

    $scope.listUsers = data;
    cov_fec70ckmz.s[143]++;
    $scope.filter.selectAllUsers();
    cov_fec70ckmz.s[144]++;
    $scope.initProcess();
  });
});
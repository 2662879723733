'use strict';

var cov_18abuug8se = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/modules/number.js',
      hash = '266f58f96fdf7d38718a52dc11a3c5a351a5aa8c',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/modules/number.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 47,
          column: 6
        }
      },
      '1': {
        start: {
          line: 3,
          column: 4
        },
        end: {
          line: 46,
          column: 6
        }
      },
      '2': {
        start: {
          line: 6,
          column: 29
        },
        end: {
          line: 6,
          column: 70
        }
      },
      '3': {
        start: {
          line: 7,
          column: 8
        },
        end: {
          line: 9,
          column: 9
        }
      },
      '4': {
        start: {
          line: 8,
          column: 10
        },
        end: {
          line: 8,
          column: 30
        }
      },
      '5': {
        start: {
          line: 10,
          column: 8
        },
        end: {
          line: 12,
          column: 9
        }
      },
      '6': {
        start: {
          line: 11,
          column: 10
        },
        end: {
          line: 11,
          column: 30
        }
      },
      '7': {
        start: {
          line: 13,
          column: 24
        },
        end: {
          line: 13,
          column: 76
        }
      },
      '8': {
        start: {
          line: 14,
          column: 22
        },
        end: {
          line: 14,
          column: 56
        }
      },
      '9': {
        start: {
          line: 16,
          column: 8
        },
        end: {
          line: 16,
          column: 31
        }
      },
      '10': {
        start: {
          line: 21,
          column: 28
        },
        end: {
          line: 21,
          column: 74
        }
      },
      '11': {
        start: {
          line: 22,
          column: 8
        },
        end: {
          line: 24,
          column: 9
        }
      },
      '12': {
        start: {
          line: 23,
          column: 10
        },
        end: {
          line: 23,
          column: 48
        }
      },
      '13': {
        start: {
          line: 25,
          column: 8
        },
        end: {
          line: 25,
          column: 27
        }
      },
      '14': {
        start: {
          line: 28,
          column: 8
        },
        end: {
          line: 38,
          column: 9
        }
      },
      '15': {
        start: {
          line: 29,
          column: 10
        },
        end: {
          line: 31,
          column: 11
        }
      },
      '16': {
        start: {
          line: 30,
          column: 12
        },
        end: {
          line: 30,
          column: 32
        }
      },
      '17': {
        start: {
          line: 33,
          column: 10
        },
        end: {
          line: 35,
          column: 11
        }
      },
      '18': {
        start: {
          line: 34,
          column: 12
        },
        end: {
          line: 34,
          column: 32
        }
      },
      '19': {
        start: {
          line: 37,
          column: 10
        },
        end: {
          line: 37,
          column: 23
        }
      },
      '20': {
        start: {
          line: 39,
          column: 21
        },
        end: {
          line: 39,
          column: 33
        }
      },
      '21': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 43,
          column: 9
        }
      },
      '22': {
        start: {
          line: 41,
          column: 10
        },
        end: {
          line: 41,
          column: 71
        }
      },
      '23': {
        start: {
          line: 44,
          column: 8
        },
        end: {
          line: 44,
          column: 64
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 45
          },
          end: {
            line: 2,
            column: 46
          }
        },
        loc: {
          start: {
            line: 2,
            column: 68
          },
          end: {
            line: 47,
            column: 3
          }
        },
        line: 2
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 5,
            column: 26
          },
          end: {
            line: 5,
            column: 45
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 5,
            column: 41
          },
          end: {
            line: 5,
            column: 45
          }
        }],
        line: 5
      },
      '1': {
        loc: {
          start: {
            line: 5,
            column: 47
          },
          end: {
            line: 5,
            column: 60
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 5,
            column: 56
          },
          end: {
            line: 5,
            column: 60
          }
        }],
        line: 5
      },
      '2': {
        loc: {
          start: {
            line: 7,
            column: 8
          },
          end: {
            line: 9,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 7,
            column: 8
          },
          end: {
            line: 9,
            column: 9
          }
        }, {
          start: {
            line: 7,
            column: 8
          },
          end: {
            line: 9,
            column: 9
          }
        }],
        line: 7
      },
      '3': {
        loc: {
          start: {
            line: 7,
            column: 12
          },
          end: {
            line: 7,
            column: 70
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 7,
            column: 12
          },
          end: {
            line: 7,
            column: 33
          }
        }, {
          start: {
            line: 7,
            column: 37
          },
          end: {
            line: 7,
            column: 70
          }
        }],
        line: 7
      },
      '4': {
        loc: {
          start: {
            line: 10,
            column: 8
          },
          end: {
            line: 12,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 10,
            column: 8
          },
          end: {
            line: 12,
            column: 9
          }
        }, {
          start: {
            line: 10,
            column: 8
          },
          end: {
            line: 12,
            column: 9
          }
        }],
        line: 10
      },
      '5': {
        loc: {
          start: {
            line: 13,
            column: 37
          },
          end: {
            line: 13,
            column: 75
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 13,
            column: 55
          },
          end: {
            line: 13,
            column: 61
          }
        }, {
          start: {
            line: 13,
            column: 64
          },
          end: {
            line: 13,
            column: 75
          }
        }],
        line: 13
      },
      '6': {
        loc: {
          start: {
            line: 20,
            column: 34
          },
          end: {
            line: 20,
            column: 47
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 20,
            column: 43
          },
          end: {
            line: 20,
            column: 47
          }
        }],
        line: 20
      },
      '7': {
        loc: {
          start: {
            line: 20,
            column: 49
          },
          end: {
            line: 20,
            column: 68
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 20,
            column: 64
          },
          end: {
            line: 20,
            column: 68
          }
        }],
        line: 20
      },
      '8': {
        loc: {
          start: {
            line: 22,
            column: 8
          },
          end: {
            line: 24,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 22,
            column: 8
          },
          end: {
            line: 24,
            column: 9
          }
        }, {
          start: {
            line: 22,
            column: 8
          },
          end: {
            line: 24,
            column: 9
          }
        }],
        line: 22
      },
      '9': {
        loc: {
          start: {
            line: 27,
            column: 29
          },
          end: {
            line: 27,
            column: 48
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 27,
            column: 44
          },
          end: {
            line: 27,
            column: 48
          }
        }],
        line: 27
      },
      '10': {
        loc: {
          start: {
            line: 28,
            column: 8
          },
          end: {
            line: 38,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 28,
            column: 8
          },
          end: {
            line: 38,
            column: 9
          }
        }, {
          start: {
            line: 28,
            column: 8
          },
          end: {
            line: 38,
            column: 9
          }
        }],
        line: 28
      },
      '11': {
        loc: {
          start: {
            line: 29,
            column: 10
          },
          end: {
            line: 31,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 29,
            column: 10
          },
          end: {
            line: 31,
            column: 11
          }
        }, {
          start: {
            line: 29,
            column: 10
          },
          end: {
            line: 31,
            column: 11
          }
        }],
        line: 29
      },
      '12': {
        loc: {
          start: {
            line: 33,
            column: 10
          },
          end: {
            line: 35,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 33,
            column: 10
          },
          end: {
            line: 35,
            column: 11
          }
        }, {
          start: {
            line: 33,
            column: 10
          },
          end: {
            line: 35,
            column: 11
          }
        }],
        line: 33
      },
      '13': {
        loc: {
          start: {
            line: 44,
            column: 15
          },
          end: {
            line: 44,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 44,
            column: 42
          },
          end: {
            line: 44,
            column: 48
          }
        }, {
          start: {
            line: 44,
            column: 51
          },
          end: {
            line: 44,
            column: 63
          }
        }],
        line: 44
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0
    },
    f: {
      '0': 0
    },
    b: {
      '0': [0],
      '1': [0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0],
      '7': [0],
      '8': [0, 0],
      '9': [0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_18abuug8se.s[0]++;
angular.module('deltaNumber', []).factory('$deltaNumber', ['$q', '$filter', function ($q, $filter) {
  cov_18abuug8se.f[0]++;
  cov_18abuug8se.s[1]++;

  return {
    format: 2,
    formatNumber: function formatNumber(value) {
      var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_18abuug8se.b[0][0]++, null);
      var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : (cov_18abuug8se.b[1][0]++, null);

      var currentValue = (cov_18abuug8se.s[2]++, this.convertToNumber(value, defaultValue));
      cov_18abuug8se.s[3]++;
      if ((cov_18abuug8se.b[3][0]++, currentValue === null) || (cov_18abuug8se.b[3][1]++, angular.isUndefined(currentValue))) {
        cov_18abuug8se.b[2][0]++;
        cov_18abuug8se.s[4]++;

        return defaultValue;
      } else {
        cov_18abuug8se.b[2][1]++;
      }
      cov_18abuug8se.s[5]++;
      if (!angular.isNumber(currentValue)) {
        cov_18abuug8se.b[4][0]++;
        cov_18abuug8se.s[6]++;

        return defaultValue;
      } else {
        cov_18abuug8se.b[4][1]++;
      }
      var value__ = (cov_18abuug8se.s[7]++, Math.pow(10, format !== null ? (cov_18abuug8se.b[5][0]++, format) : (cov_18abuug8se.b[5][1]++, this.format)));
      var floor = (cov_18abuug8se.s[8]++, Math.round(currentValue * value__));

      cov_18abuug8se.s[9]++;
      return floor / value__;

      // return (floor - (value__ * currentValue) > 0 ? (floor - 1) : floor) / value__;
    },
    formatNumberToString: function formatNumberToString(value) {
      var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_18abuug8se.b[6][0]++, null);
      var defaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : (cov_18abuug8se.b[7][0]++, null);

      var formatValue = (cov_18abuug8se.s[10]++, this.formatNumber(value, defaultValue, format));
      cov_18abuug8se.s[11]++;
      if (angular.isNumber(formatValue)) {
        cov_18abuug8se.b[8][0]++;
        cov_18abuug8se.s[12]++;

        return $filter('number')(formatValue);
      } else {
        cov_18abuug8se.b[8][1]++;
      }
      cov_18abuug8se.s[13]++;
      return formatValue;
    },
    convertToNumber: function convertToNumber(value) {
      var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (cov_18abuug8se.b[9][0]++, null);
      cov_18abuug8se.s[14]++;

      if (angular.isNumber(value)) {
        cov_18abuug8se.b[10][0]++;
        cov_18abuug8se.s[15]++;

        if (!isFinite(value)) {
          cov_18abuug8se.b[11][0]++;
          cov_18abuug8se.s[16]++;

          return defaultValue;
        } else {
          cov_18abuug8se.b[11][1]++;
        }

        cov_18abuug8se.s[17]++;
        if (isNaN(value)) {
          cov_18abuug8se.b[12][0]++;
          cov_18abuug8se.s[18]++;

          return defaultValue;
        } else {
          cov_18abuug8se.b[12][1]++;
        }

        cov_18abuug8se.s[19]++;
        return value;
      } else {
        cov_18abuug8se.b[10][1]++;
      }
      var result = (cov_18abuug8se.s[20]++, defaultValue);
      cov_18abuug8se.s[21]++;
      try {
        cov_18abuug8se.s[22]++;

        result = eval((' ' + value + ' ').replaceAll(' 00 ', ' 0 '));
      } catch (e) {}
      cov_18abuug8se.s[23]++;
      return angular.isNumber(result) ? (cov_18abuug8se.b[13][0]++, result) : (cov_18abuug8se.b[13][1]++, defaultValue);
    }
  };
}]);
'use strict';

var cov_ne8pzfso1 = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/directives/tree-view.js',
      hash = '760b898b9f4c1284e2db073e2c5cccbae0f1bf25',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/directives/tree-view.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 79,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 78,
          column: 4
        }
      },
      '2': {
        start: {
          line: 24,
          column: 18
        },
        end: {
          line: 24,
          column: 22
        }
      },
      '3': {
        start: {
          line: 25,
          column: 21
        },
        end: {
          line: 51,
          column: 7
        }
      },
      '4': {
        start: {
          line: 34,
          column: 10
        },
        end: {
          line: 36,
          column: 11
        }
      },
      '5': {
        start: {
          line: 35,
          column: 12
        },
        end: {
          line: 35,
          column: 19
        }
      },
      '6': {
        start: {
          line: 37,
          column: 10
        },
        end: {
          line: 37,
          column: 38
        }
      },
      '7': {
        start: {
          line: 40,
          column: 10
        },
        end: {
          line: 42,
          column: 11
        }
      },
      '8': {
        start: {
          line: 41,
          column: 12
        },
        end: {
          line: 41,
          column: 19
        }
      },
      '9': {
        start: {
          line: 43,
          column: 10
        },
        end: {
          line: 43,
          column: 50
        }
      },
      '10': {
        start: {
          line: 46,
          column: 10
        },
        end: {
          line: 48,
          column: 11
        }
      },
      '11': {
        start: {
          line: 47,
          column: 12
        },
        end: {
          line: 47,
          column: 19
        }
      },
      '12': {
        start: {
          line: 49,
          column: 10
        },
        end: {
          line: 49,
          column: 41
        }
      },
      '13': {
        start: {
          line: 53,
          column: 21
        },
        end: {
          line: 56,
          column: 7
        }
      },
      '14': {
        start: {
          line: 54,
          column: 8
        },
        end: {
          line: 54,
          column: 49
        }
      },
      '15': {
        start: {
          line: 55,
          column: 8
        },
        end: {
          line: 55,
          column: 35
        }
      },
      '16': {
        start: {
          line: 57,
          column: 6
        },
        end: {
          line: 61,
          column: 9
        }
      },
      '17': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 58,
          column: 38
        }
      },
      '18': {
        start: {
          line: 59,
          column: 8
        },
        end: {
          line: 59,
          column: 32
        }
      },
      '19': {
        start: {
          line: 60,
          column: 8
        },
        end: {
          line: 60,
          column: 45
        }
      },
      '20': {
        start: {
          line: 63,
          column: 6
        },
        end: {
          line: 68,
          column: 9
        }
      },
      '21': {
        start: {
          line: 64,
          column: 8
        },
        end: {
          line: 64,
          column: 37
        }
      },
      '22': {
        start: {
          line: 66,
          column: 8
        },
        end: {
          line: 66,
          column: 42
        }
      },
      '23': {
        start: {
          line: 67,
          column: 8
        },
        end: {
          line: 67,
          column: 24
        }
      },
      '24': {
        start: {
          line: 70,
          column: 6
        },
        end: {
          line: 75,
          column: 9
        }
      },
      '25': {
        start: {
          line: 71,
          column: 8
        },
        end: {
          line: 71,
          column: 29
        }
      },
      '26': {
        start: {
          line: 73,
          column: 8
        },
        end: {
          line: 73,
          column: 33
        }
      },
      '27': {
        start: {
          line: 74,
          column: 8
        },
        end: {
          line: 74,
          column: 24
        }
      },
      '28': {
        start: {
          line: 76,
          column: 6
        },
        end: {
          line: 76,
          column: 15
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 49
          },
          end: {
            line: 1,
            column: 50
          }
        },
        loc: {
          start: {
            line: 1,
            column: 55
          },
          end: {
            line: 79,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 53,
            column: 21
          },
          end: {
            line: 53,
            column: 22
          }
        },
        loc: {
          start: {
            line: 53,
            column: 33
          },
          end: {
            line: 56,
            column: 7
          }
        },
        line: 53
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 57,
            column: 28
          },
          end: {
            line: 57,
            column: 29
          }
        },
        loc: {
          start: {
            line: 57,
            column: 34
          },
          end: {
            line: 61,
            column: 7
          }
        },
        line: 57
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 63,
            column: 19
          },
          end: {
            line: 63,
            column: 20
          }
        },
        loc: {
          start: {
            line: 63,
            column: 28
          },
          end: {
            line: 65,
            column: 7
          }
        },
        line: 63
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 65,
            column: 9
          },
          end: {
            line: 65,
            column: 10
          }
        },
        loc: {
          start: {
            line: 65,
            column: 21
          },
          end: {
            line: 68,
            column: 7
          }
        },
        line: 65
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 70,
            column: 19
          },
          end: {
            line: 70,
            column: 20
          }
        },
        loc: {
          start: {
            line: 70,
            column: 28
          },
          end: {
            line: 72,
            column: 7
          }
        },
        line: 70
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 72,
            column: 9
          },
          end: {
            line: 72,
            column: 10
          }
        },
        loc: {
          start: {
            line: 72,
            column: 21
          },
          end: {
            line: 75,
            column: 7
          }
        },
        line: 72
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 29,
            column: 20
          },
          end: {
            line: 29,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 29,
            column: 20
          },
          end: {
            line: 29,
            column: 41
          }
        }, {
          start: {
            line: 29,
            column: 45
          },
          end: {
            line: 29,
            column: 47
          }
        }],
        line: 29
      },
      '1': {
        loc: {
          start: {
            line: 31,
            column: 16
          },
          end: {
            line: 31,
            column: 38
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 31,
            column: 16
          },
          end: {
            line: 31,
            column: 28
          }
        }, {
          start: {
            line: 31,
            column: 32
          },
          end: {
            line: 31,
            column: 38
          }
        }],
        line: 31
      },
      '2': {
        loc: {
          start: {
            line: 32,
            column: 17
          },
          end: {
            line: 32,
            column: 36
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 32,
            column: 17
          },
          end: {
            line: 32,
            column: 30
          }
        }, {
          start: {
            line: 32,
            column: 34
          },
          end: {
            line: 32,
            column: 36
          }
        }],
        line: 32
      },
      '3': {
        loc: {
          start: {
            line: 34,
            column: 10
          },
          end: {
            line: 36,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 34,
            column: 10
          },
          end: {
            line: 36,
            column: 11
          }
        }, {
          start: {
            line: 34,
            column: 10
          },
          end: {
            line: 36,
            column: 11
          }
        }],
        line: 34
      },
      '4': {
        loc: {
          start: {
            line: 40,
            column: 10
          },
          end: {
            line: 42,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 40,
            column: 10
          },
          end: {
            line: 42,
            column: 11
          }
        }, {
          start: {
            line: 40,
            column: 10
          },
          end: {
            line: 42,
            column: 11
          }
        }],
        line: 40
      },
      '5': {
        loc: {
          start: {
            line: 46,
            column: 10
          },
          end: {
            line: 48,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 46,
            column: 10
          },
          end: {
            line: 48,
            column: 11
          }
        }, {
          start: {
            line: 46,
            column: 10
          },
          end: {
            line: 48,
            column: 11
          }
        }],
        line: 46
      },
      '6': {
        loc: {
          start: {
            line: 66,
            column: 27
          },
          end: {
            line: 66,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 66,
            column: 27
          },
          end: {
            line: 66,
            column: 35
          }
        }, {
          start: {
            line: 66,
            column: 39
          },
          end: {
            line: 66,
            column: 41
          }
        }],
        line: 66
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_ne8pzfso1.s[0]++;
angular.module('app').directive('deltaTreeView', function () {
  cov_ne8pzfso1.f[0]++;
  cov_ne8pzfso1.s[1]++;

  return {
    /* {id: ‘id’, text: ‘text’, dataSource: [], child: ‘child’,
    parentID: ‘parentID’, hasChildren: ‘hasChildren’, expanded: ‘expanded’,
    htmlAttributes: ‘htmlAttributes’, iconCss: ‘iconCss’, imageUrl: ‘imageUrl’,
    isChecked: ‘isChecked’, query: null, selected: ‘selected’,
    tableName: null, tooltip: ‘tooltip’, navigateUrl: ‘navigateUrl’} */

    restrict: 'A',
    scope: {
      idMapping: '=?',
      tableDataSource: '=?',
      columns: '=?',
      parentIdMapping: '=?',
      hasChildMapping: '=?',
      title: '=?',

      // parentID: '@?',
      recordClick: '&',
      actionBegin: '&',
      contextMenuClick: '&'
    },
    link: function link(scope, element, attrs) {
      var table = (cov_ne8pzfso1.s[2]++, null);
      var params = (cov_ne8pzfso1.s[3]++, {
        idMapping: 'id',
        parentIdMapping: scope.parentIdMapping,
        hasChildMapping: scope.hasChildMapping,
        dataSource: (cov_ne8pzfso1.b[0][0]++, scope.tableDataSource) || (cov_ne8pzfso1.b[0][1]++, []),
        treeColumnIndex: 0,
        height: (cov_ne8pzfso1.b[1][0]++, scope.height) || (cov_ne8pzfso1.b[1][1]++, '100%'),
        columns: (cov_ne8pzfso1.b[2][0]++, scope.columns) || (cov_ne8pzfso1.b[2][1]++, []),
        actionBegin: function actionBegin(action) {
          cov_ne8pzfso1.s[4]++;

          if (!scope.actionBegin) {
            cov_ne8pzfso1.b[3][0]++;
            cov_ne8pzfso1.s[5]++;

            return;
          } else {
            cov_ne8pzfso1.b[3][1]++;
          }
          cov_ne8pzfso1.s[6]++;
          scope.actionBegin({ action: action });
        },
        rowSelected: function rowSelected(args) {
          cov_ne8pzfso1.s[7]++;

          if (!scope.recordClick) {
            cov_ne8pzfso1.b[4][0]++;
            cov_ne8pzfso1.s[8]++;

            return;
          } else {
            cov_ne8pzfso1.b[4][1]++;
          }
          cov_ne8pzfso1.s[9]++;
          scope.recordClick({ rowData: args.data });
        },
        contextMenuClick: function contextMenuClick(item) {
          cov_ne8pzfso1.s[10]++;

          if (!scope.contextMenuClick) {
            cov_ne8pzfso1.b[5][0]++;
            cov_ne8pzfso1.s[11]++;

            return;
          } else {
            cov_ne8pzfso1.b[5][1]++;
          }
          cov_ne8pzfso1.s[12]++;
          scope.contextMenuClick({ item: item });
        }
      });

      cov_ne8pzfso1.s[13]++;
      var render = function render() {
        cov_ne8pzfso1.f[1]++;
        cov_ne8pzfso1.s[14]++;

        table = new ej.treegrid.TreeGrid(params);
        cov_ne8pzfso1.s[15]++;
        table.appendTo(element[0]);
      };
      cov_ne8pzfso1.s[16]++;
      scope.$on('$destroy', function () {
        cov_ne8pzfso1.f[2]++;
        cov_ne8pzfso1.s[17]++;

        $('.e-tooltip-wrap').remove();
        cov_ne8pzfso1.s[18]++;
        $('.e-flmenu').remove();
        cov_ne8pzfso1.s[19]++;
        $('.e-contextmenu-wrapper').remove();
      });

      cov_ne8pzfso1.s[20]++;
      scope.$watch(function (scope) {
        cov_ne8pzfso1.f[3]++;
        cov_ne8pzfso1.s[21]++;

        return scope.tableDataSource;
      }, function (newValue) {
        cov_ne8pzfso1.f[4]++;
        cov_ne8pzfso1.s[22]++;

        table.dataSource = (cov_ne8pzfso1.b[6][0]++, newValue) || (cov_ne8pzfso1.b[6][1]++, []);
        cov_ne8pzfso1.s[23]++;
        table.refresh();
      });

      cov_ne8pzfso1.s[24]++;
      scope.$watch(function (scope) {
        cov_ne8pzfso1.f[5]++;
        cov_ne8pzfso1.s[25]++;

        return scope.columns;
      }, function (newValue) {
        cov_ne8pzfso1.f[6]++;
        cov_ne8pzfso1.s[26]++;

        table.columns = newValue;
        cov_ne8pzfso1.s[27]++;
        table.refresh();
      });
      cov_ne8pzfso1.s[28]++;
      render();
    }
  };
});
'use strict';

var cov_1v6u8gr80 = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/risk/recommandation_risk.js',
      hash = 'e3c2147a4621298405f39a354a9132f1e6341a83',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/risk/recommandation_risk.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 218,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 3,
          column: 37
        }
      },
      '2': {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 6,
          column: 3
        }
      },
      '3': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 5,
          column: 11
        }
      },
      '4': {
        start: {
          line: 7,
          column: 15
        },
        end: {
          line: 7,
          column: 31
        }
      },
      '5': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 32
        }
      },
      '6': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 13,
          column: 4
        }
      },
      '7': {
        start: {
          line: 11,
          column: 4
        },
        end: {
          line: 11,
          column: 51
        }
      },
      '8': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 12,
          column: 21
        }
      },
      '9': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 36
        }
      },
      '10': {
        start: {
          line: 17,
          column: 2
        },
        end: {
          line: 20,
          column: 4
        }
      },
      '11': {
        start: {
          line: 22,
          column: 18
        },
        end: {
          line: 22,
          column: 48
        }
      },
      '12': {
        start: {
          line: 24,
          column: 2
        },
        end: {
          line: 24,
          column: 141
        }
      },
      '13': {
        start: {
          line: 25,
          column: 2
        },
        end: {
          line: 25,
          column: 143
        }
      },
      '14': {
        start: {
          line: 27,
          column: 2
        },
        end: {
          line: 27,
          column: 41
        }
      },
      '15': {
        start: {
          line: 29,
          column: 2
        },
        end: {
          line: 29,
          column: 18
        }
      },
      '16': {
        start: {
          line: 30,
          column: 2
        },
        end: {
          line: 30,
          column: 33
        }
      },
      '17': {
        start: {
          line: 32,
          column: 2
        },
        end: {
          line: 36,
          column: 4
        }
      },
      '18': {
        start: {
          line: 38,
          column: 2
        },
        end: {
          line: 95,
          column: 5
        }
      },
      '19': {
        start: {
          line: 39,
          column: 20
        },
        end: {
          line: 44,
          column: 5
        }
      },
      '20': {
        start: {
          line: 46,
          column: 4
        },
        end: {
          line: 51,
          column: 5
        }
      },
      '21': {
        start: {
          line: 47,
          column: 6
        },
        end: {
          line: 50,
          column: 9
        }
      },
      '22': {
        start: {
          line: 53,
          column: 4
        },
        end: {
          line: 72,
          column: 6
        }
      },
      '23': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 61,
          column: 9
        }
      },
      '24': {
        start: {
          line: 59,
          column: 10
        },
        end: {
          line: 59,
          column: 31
        }
      },
      '25': {
        start: {
          line: 60,
          column: 10
        },
        end: {
          line: 60,
          column: 27
        }
      },
      '26': {
        start: {
          line: 62,
          column: 8
        },
        end: {
          line: 66,
          column: 9
        }
      },
      '27': {
        start: {
          line: 63,
          column: 10
        },
        end: {
          line: 63,
          column: 31
        }
      },
      '28': {
        start: {
          line: 64,
          column: 10
        },
        end: {
          line: 64,
          column: 42
        }
      },
      '29': {
        start: {
          line: 65,
          column: 10
        },
        end: {
          line: 65,
          column: 39
        }
      },
      '30': {
        start: {
          line: 67,
          column: 8
        },
        end: {
          line: 70,
          column: 9
        }
      },
      '31': {
        start: {
          line: 68,
          column: 10
        },
        end: {
          line: 68,
          column: 31
        }
      },
      '32': {
        start: {
          line: 69,
          column: 10
        },
        end: {
          line: 69,
          column: 44
        }
      },
      '33': {
        start: {
          line: 74,
          column: 4
        },
        end: {
          line: 74,
          column: 28
        }
      },
      '34': {
        start: {
          line: 75,
          column: 4
        },
        end: {
          line: 94,
          column: 7
        }
      },
      '35': {
        start: {
          line: 76,
          column: 6
        },
        end: {
          line: 91,
          column: 9
        }
      },
      '36': {
        start: {
          line: 77,
          column: 8
        },
        end: {
          line: 79,
          column: 11
        }
      },
      '37': {
        start: {
          line: 78,
          column: 10
        },
        end: {
          line: 78,
          column: 40
        }
      },
      '38': {
        start: {
          line: 80,
          column: 8
        },
        end: {
          line: 80,
          column: 52
        }
      },
      '39': {
        start: {
          line: 82,
          column: 20
        },
        end: {
          line: 84,
          column: 10
        }
      },
      '40': {
        start: {
          line: 83,
          column: 10
        },
        end: {
          line: 83,
          column: 26
        }
      },
      '41': {
        start: {
          line: 86,
          column: 8
        },
        end: {
          line: 88,
          column: 61
        }
      },
      '42': {
        start: {
          line: 87,
          column: 10
        },
        end: {
          line: 87,
          column: 44
        }
      },
      '43': {
        start: {
          line: 88,
          column: 25
        },
        end: {
          line: 88,
          column: 46
        }
      },
      '44': {
        start: {
          line: 90,
          column: 8
        },
        end: {
          line: 90,
          column: 20
        }
      },
      '45': {
        start: {
          line: 93,
          column: 6
        },
        end: {
          line: 93,
          column: 31
        }
      },
      '46': {
        start: {
          line: 97,
          column: 2
        },
        end: {
          line: 113,
          column: 5
        }
      },
      '47': {
        start: {
          line: 98,
          column: 4
        },
        end: {
          line: 112,
          column: 5
        }
      },
      '48': {
        start: {
          line: 99,
          column: 6
        },
        end: {
          line: 111,
          column: 9
        }
      },
      '49': {
        start: {
          line: 115,
          column: 2
        },
        end: {
          line: 126,
          column: 5
        }
      },
      '50': {
        start: {
          line: 116,
          column: 4
        },
        end: {
          line: 125,
          column: 7
        }
      },
      '51': {
        start: {
          line: 128,
          column: 2
        },
        end: {
          line: 144,
          column: 5
        }
      },
      '52': {
        start: {
          line: 129,
          column: 4
        },
        end: {
          line: 129,
          column: 30
        }
      },
      '53': {
        start: {
          line: 130,
          column: 25
        },
        end: {
          line: 130,
          column: 43
        }
      },
      '54': {
        start: {
          line: 132,
          column: 16
        },
        end: {
          line: 134,
          column: 6
        }
      },
      '55': {
        start: {
          line: 133,
          column: 6
        },
        end: {
          line: 133,
          column: 22
        }
      },
      '56': {
        start: {
          line: 136,
          column: 4
        },
        end: {
          line: 138,
          column: 7
        }
      },
      '57': {
        start: {
          line: 137,
          column: 6
        },
        end: {
          line: 137,
          column: 40
        }
      },
      '58': {
        start: {
          line: 140,
          column: 4
        },
        end: {
          line: 142,
          column: 7
        }
      },
      '59': {
        start: {
          line: 141,
          column: 6
        },
        end: {
          line: 141,
          column: 36
        }
      },
      '60': {
        start: {
          line: 143,
          column: 4
        },
        end: {
          line: 143,
          column: 39
        }
      },
      '61': {
        start: {
          line: 146,
          column: 2
        },
        end: {
          line: 170,
          column: 4
        }
      },
      '62': {
        start: {
          line: 147,
          column: 25
        },
        end: {
          line: 157,
          column: 5
        }
      },
      '63': {
        start: {
          line: 154,
          column: 8
        },
        end: {
          line: 154,
          column: 30
        }
      },
      '64': {
        start: {
          line: 159,
          column: 4
        },
        end: {
          line: 159,
          column: 26
        }
      },
      '65': {
        start: {
          line: 160,
          column: 4
        },
        end: {
          line: 163,
          column: 5
        }
      },
      '66': {
        start: {
          line: 161,
          column: 6
        },
        end: {
          line: 161,
          column: 28
        }
      },
      '67': {
        start: {
          line: 162,
          column: 6
        },
        end: {
          line: 162,
          column: 13
        }
      },
      '68': {
        start: {
          line: 164,
          column: 4
        },
        end: {
          line: 164,
          column: 28
        }
      },
      '69': {
        start: {
          line: 165,
          column: 4
        },
        end: {
          line: 169,
          column: 7
        }
      },
      '70': {
        start: {
          line: 166,
          column: 6
        },
        end: {
          line: 166,
          column: 26
        }
      },
      '71': {
        start: {
          line: 167,
          column: 6
        },
        end: {
          line: 167,
          column: 22
        }
      },
      '72': {
        start: {
          line: 168,
          column: 6
        },
        end: {
          line: 168,
          column: 36
        }
      },
      '73': {
        start: {
          line: 172,
          column: 2
        },
        end: {
          line: 211,
          column: 5
        }
      },
      '74': {
        start: {
          line: 173,
          column: 4
        },
        end: {
          line: 210,
          column: 7
        }
      },
      '75': {
        start: {
          line: 185,
          column: 6
        },
        end: {
          line: 187,
          column: 7
        }
      },
      '76': {
        start: {
          line: 186,
          column: 8
        },
        end: {
          line: 186,
          column: 15
        }
      },
      '77': {
        start: {
          line: 188,
          column: 6
        },
        end: {
          line: 188,
          column: 30
        }
      },
      '78': {
        start: {
          line: 189,
          column: 27
        },
        end: {
          line: 192,
          column: 7
        }
      },
      '79': {
        start: {
          line: 198,
          column: 6
        },
        end: {
          line: 209,
          column: 9
        }
      },
      '80': {
        start: {
          line: 199,
          column: 8
        },
        end: {
          line: 208,
          column: 9
        }
      },
      '81': {
        start: {
          line: 200,
          column: 10
        },
        end: {
          line: 204,
          column: 13
        }
      },
      '82': {
        start: {
          line: 205,
          column: 10
        },
        end: {
          line: 205,
          column: 35
        }
      },
      '83': {
        start: {
          line: 207,
          column: 10
        },
        end: {
          line: 207,
          column: 26
        }
      },
      '84': {
        start: {
          line: 213,
          column: 2
        },
        end: {
          line: 215,
          column: 5
        }
      },
      '85': {
        start: {
          line: 214,
          column: 4
        },
        end: {
          line: 214,
          column: 31
        }
      },
      '86': {
        start: {
          line: 216,
          column: 2
        },
        end: {
          line: 216,
          column: 22
        }
      },
      '87': {
        start: {
          line: 217,
          column: 2
        },
        end: {
          line: 217,
          column: 18
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 55
          },
          end: {
            line: 2,
            column: 56
          }
        },
        loc: {
          start: {
            line: 2,
            column: 151
          },
          end: {
            line: 218,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 10,
            column: 32
          },
          end: {
            line: 10,
            column: 33
          }
        },
        loc: {
          start: {
            line: 10,
            column: 44
          },
          end: {
            line: 13,
            column: 3
          }
        },
        line: 10
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 38,
            column: 19
          },
          end: {
            line: 38,
            column: 20
          }
        },
        loc: {
          start: {
            line: 38,
            column: 25
          },
          end: {
            line: 95,
            column: 3
          }
        },
        line: 38
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 75,
            column: 80
          },
          end: {
            line: 75,
            column: 81
          }
        },
        loc: {
          start: {
            line: 75,
            column: 88
          },
          end: {
            line: 94,
            column: 5
          }
        },
        line: 75
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 76,
            column: 43
          },
          end: {
            line: 76,
            column: 44
          }
        },
        loc: {
          start: {
            line: 76,
            column: 51
          },
          end: {
            line: 91,
            column: 7
          }
        },
        line: 76
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 77,
            column: 45
          },
          end: {
            line: 77,
            column: 46
          }
        },
        loc: {
          start: {
            line: 77,
            column: 53
          },
          end: {
            line: 79,
            column: 9
          }
        },
        line: 77
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 82,
            column: 42
          },
          end: {
            line: 82,
            column: 43
          }
        },
        loc: {
          start: {
            line: 82,
            column: 51
          },
          end: {
            line: 84,
            column: 9
          }
        },
        line: 82
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 86,
            column: 68
          },
          end: {
            line: 86,
            column: 69
          }
        },
        loc: {
          start: {
            line: 86,
            column: 77
          },
          end: {
            line: 88,
            column: 9
          }
        },
        line: 86
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 88,
            column: 15
          },
          end: {
            line: 88,
            column: 16
          }
        },
        loc: {
          start: {
            line: 88,
            column: 25
          },
          end: {
            line: 88,
            column: 46
          }
        },
        line: 88
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 97,
            column: 23
          },
          end: {
            line: 97,
            column: 24
          }
        },
        loc: {
          start: {
            line: 97,
            column: 34
          },
          end: {
            line: 113,
            column: 3
          }
        },
        line: 97
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 109,
            column: 9
          },
          end: {
            line: 109,
            column: 10
          }
        },
        loc: {
          start: {
            line: 109,
            column: 22
          },
          end: {
            line: 111,
            column: 7
          }
        },
        line: 109
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 115,
            column: 20
          },
          end: {
            line: 115,
            column: 21
          }
        },
        loc: {
          start: {
            line: 115,
            column: 26
          },
          end: {
            line: 126,
            column: 3
          }
        },
        line: 115
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 128,
            column: 21
          },
          end: {
            line: 128,
            column: 22
          }
        },
        loc: {
          start: {
            line: 128,
            column: 29
          },
          end: {
            line: 144,
            column: 3
          }
        },
        line: 128
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 132,
            column: 38
          },
          end: {
            line: 132,
            column: 39
          }
        },
        loc: {
          start: {
            line: 132,
            column: 47
          },
          end: {
            line: 134,
            column: 5
          }
        },
        line: 132
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 136,
            column: 67
          },
          end: {
            line: 136,
            column: 68
          }
        },
        loc: {
          start: {
            line: 136,
            column: 76
          },
          end: {
            line: 138,
            column: 5
          }
        },
        line: 136
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 140,
            column: 49
          },
          end: {
            line: 140,
            column: 50
          }
        },
        loc: {
          start: {
            line: 140,
            column: 57
          },
          end: {
            line: 142,
            column: 5
          }
        },
        line: 140
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 146,
            column: 24
          },
          end: {
            line: 146,
            column: 25
          }
        },
        loc: {
          start: {
            line: 146,
            column: 30
          },
          end: {
            line: 170,
            column: 3
          }
        },
        line: 146
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 153,
            column: 57
          },
          end: {
            line: 153,
            column: 58
          }
        },
        loc: {
          start: {
            line: 153,
            column: 66
          },
          end: {
            line: 155,
            column: 7
          }
        },
        line: 153
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 165,
            column: 90
          },
          end: {
            line: 165,
            column: 91
          }
        },
        loc: {
          start: {
            line: 165,
            column: 96
          },
          end: {
            line: 169,
            column: 5
          }
        },
        line: 165
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 172,
            column: 23
          },
          end: {
            line: 172,
            column: 24
          }
        },
        loc: {
          start: {
            line: 172,
            column: 31
          },
          end: {
            line: 211,
            column: 3
          }
        },
        line: 172
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 184,
            column: 7
          },
          end: {
            line: 184,
            column: 8
          }
        },
        loc: {
          start: {
            line: 184,
            column: 20
          },
          end: {
            line: 210,
            column: 5
          }
        },
        line: 184
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 198,
            column: 88
          },
          end: {
            line: 198,
            column: 89
          }
        },
        loc: {
          start: {
            line: 198,
            column: 96
          },
          end: {
            line: 209,
            column: 7
          }
        },
        line: 198
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 213,
            column: 23
          },
          end: {
            line: 213,
            column: 24
          }
        },
        loc: {
          start: {
            line: 213,
            column: 29
          },
          end: {
            line: 215,
            column: 3
          }
        },
        line: 213
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        }, {
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 6,
            column: 3
          }
        }],
        line: 4
      },
      '1': {
        loc: {
          start: {
            line: 24,
            column: 19
          },
          end: {
            line: 24,
            column: 140
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 24,
            column: 19
          },
          end: {
            line: 24,
            column: 44
          }
        }, {
          start: {
            line: 24,
            column: 49
          },
          end: {
            line: 24,
            column: 139
          }
        }],
        line: 24
      },
      '2': {
        loc: {
          start: {
            line: 24,
            column: 49
          },
          end: {
            line: 24,
            column: 139
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 24,
            column: 102
          },
          end: {
            line: 24,
            column: 106
          }
        }, {
          start: {
            line: 24,
            column: 109
          },
          end: {
            line: 24,
            column: 139
          }
        }],
        line: 24
      },
      '3': {
        loc: {
          start: {
            line: 25,
            column: 21
          },
          end: {
            line: 25,
            column: 142
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 25,
            column: 21
          },
          end: {
            line: 25,
            column: 46
          }
        }, {
          start: {
            line: 25,
            column: 51
          },
          end: {
            line: 25,
            column: 141
          }
        }],
        line: 25
      },
      '4': {
        loc: {
          start: {
            line: 25,
            column: 51
          },
          end: {
            line: 25,
            column: 141
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 25,
            column: 104
          },
          end: {
            line: 25,
            column: 108
          }
        }, {
          start: {
            line: 25,
            column: 111
          },
          end: {
            line: 25,
            column: 141
          }
        }],
        line: 25
      },
      '5': {
        loc: {
          start: {
            line: 46,
            column: 4
          },
          end: {
            line: 51,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 46,
            column: 4
          },
          end: {
            line: 51,
            column: 5
          }
        }, {
          start: {
            line: 46,
            column: 4
          },
          end: {
            line: 51,
            column: 5
          }
        }],
        line: 46
      },
      '6': {
        loc: {
          start: {
            line: 46,
            column: 9
          },
          end: {
            line: 46,
            column: 99
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 46,
            column: 62
          },
          end: {
            line: 46,
            column: 66
          }
        }, {
          start: {
            line: 46,
            column: 69
          },
          end: {
            line: 46,
            column: 99
          }
        }],
        line: 46
      },
      '7': {
        loc: {
          start: {
            line: 48,
            column: 28
          },
          end: {
            line: 48,
            column: 141
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 48,
            column: 56
          },
          end: {
            line: 48,
            column: 136
          }
        }, {
          start: {
            line: 48,
            column: 139
          },
          end: {
            line: 48,
            column: 141
          }
        }],
        line: 48
      },
      '8': {
        loc: {
          start: {
            line: 49,
            column: 18
          },
          end: {
            line: 49,
            column: 134
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 49,
            column: 46
          },
          end: {
            line: 49,
            column: 129
          }
        }, {
          start: {
            line: 49,
            column: 132
          },
          end: {
            line: 49,
            column: 134
          }
        }],
        line: 49
      },
      '9': {
        loc: {
          start: {
            line: 54,
            column: 16
          },
          end: {
            line: 54,
            column: 52
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 54,
            column: 33
          },
          end: {
            line: 54,
            column: 40
          }
        }, {
          start: {
            line: 54,
            column: 43
          },
          end: {
            line: 54,
            column: 52
          }
        }],
        line: 54
      },
      '10': {
        loc: {
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 61,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 61,
            column: 9
          }
        }, {
          start: {
            line: 58,
            column: 8
          },
          end: {
            line: 61,
            column: 9
          }
        }],
        line: 58
      },
      '11': {
        loc: {
          start: {
            line: 62,
            column: 8
          },
          end: {
            line: 66,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 62,
            column: 8
          },
          end: {
            line: 66,
            column: 9
          }
        }, {
          start: {
            line: 62,
            column: 8
          },
          end: {
            line: 66,
            column: 9
          }
        }],
        line: 62
      },
      '12': {
        loc: {
          start: {
            line: 67,
            column: 8
          },
          end: {
            line: 70,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 8
          },
          end: {
            line: 70,
            column: 9
          }
        }, {
          start: {
            line: 67,
            column: 8
          },
          end: {
            line: 70,
            column: 9
          }
        }],
        line: 67
      },
      '13': {
        loc: {
          start: {
            line: 98,
            column: 4
          },
          end: {
            line: 112,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 98,
            column: 4
          },
          end: {
            line: 112,
            column: 5
          }
        }, {
          start: {
            line: 98,
            column: 4
          },
          end: {
            line: 112,
            column: 5
          }
        }],
        line: 98
      },
      '14': {
        loc: {
          start: {
            line: 160,
            column: 4
          },
          end: {
            line: 163,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 160,
            column: 4
          },
          end: {
            line: 163,
            column: 5
          }
        }, {
          start: {
            line: 160,
            column: 4
          },
          end: {
            line: 163,
            column: 5
          }
        }],
        line: 160
      },
      '15': {
        loc: {
          start: {
            line: 160,
            column: 9
          },
          end: {
            line: 160,
            column: 46
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 160,
            column: 9
          },
          end: {
            line: 160,
            column: 40
          }
        }, {
          start: {
            line: 160,
            column: 44
          },
          end: {
            line: 160,
            column: 46
          }
        }],
        line: 160
      },
      '16': {
        loc: {
          start: {
            line: 185,
            column: 6
          },
          end: {
            line: 187,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 185,
            column: 6
          },
          end: {
            line: 187,
            column: 7
          }
        }, {
          start: {
            line: 185,
            column: 6
          },
          end: {
            line: 187,
            column: 7
          }
        }],
        line: 185
      },
      '17': {
        loc: {
          start: {
            line: 199,
            column: 8
          },
          end: {
            line: 208,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 199,
            column: 8
          },
          end: {
            line: 208,
            column: 9
          }
        }, {
          start: {
            line: 199,
            column: 8
          },
          end: {
            line: 208,
            column: 9
          }
        }],
        line: 199
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1v6u8gr80.s[0]++;

angular.module('app').controller('RecommendationCtrl', function ($scope, SweetAlert, $translate, $deltadate, $CRUDService, $filter, $deltaActor, $rootScope) {
  cov_1v6u8gr80.f[0]++;
  cov_1v6u8gr80.s[1]++;

  $rootScope.processPageRight('2_3');
  cov_1v6u8gr80.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_1v6u8gr80.b[0][0]++;
    cov_1v6u8gr80.s[3]++;

    return;
  } else {
    cov_1v6u8gr80.b[0][1]++;
  }
  var PATH = (cov_1v6u8gr80.s[4]++, 'Recommendation');

  cov_1v6u8gr80.s[5]++;
  $scope.view_edit_form = false;
  cov_1v6u8gr80.s[6]++;
  $scope.click_view_edit_form = function () {
    cov_1v6u8gr80.f[1]++;
    cov_1v6u8gr80.s[7]++;

    $scope.view_edit_form = !$scope.view_edit_form;
    cov_1v6u8gr80.s[8]++;
    $scope.addItem();
  };

  cov_1v6u8gr80.s[9]++;
  $deltaActor.addController($scope);

  cov_1v6u8gr80.s[10]++;
  $scope.externParams = {
    id: localStorage.getItem('SELECTED_RISK'),
    type: 1
  };

  var project = (cov_1v6u8gr80.s[11]++, $rootScope.getCurrentProject());

  cov_1v6u8gr80.s[12]++;
  $scope.canEdit = (cov_1v6u8gr80.b[1][0]++, $rootScope.currentRight.u) && (cov_1v6u8gr80.b[1][1]++, angular.isUndefined($scope.recommendationViewOnly) ? (cov_1v6u8gr80.b[2][0]++, true) : (cov_1v6u8gr80.b[2][1]++, !$scope.recommendationViewOnly));
  cov_1v6u8gr80.s[13]++;
  $scope.canDelete = (cov_1v6u8gr80.b[3][0]++, $rootScope.currentRight.d) && (cov_1v6u8gr80.b[3][1]++, angular.isUndefined($scope.recommendationViewOnly) ? (cov_1v6u8gr80.b[4][0]++, true) : (cov_1v6u8gr80.b[4][1]++, !$scope.recommendationViewOnly));

  cov_1v6u8gr80.s[14]++;
  $scope.id_t = Date.newDate().getTime();

  cov_1v6u8gr80.s[15]++;
  $scope.page = 1;
  cov_1v6u8gr80.s[16]++;
  $scope.listRecommendation = [];

  cov_1v6u8gr80.s[17]++;
  $scope.listStatus = [{ id: 1, name: $translate.instant('RECOMMENDATION.STATUS_LIST.NOT_EXECUTED') }, { id: 2, name: $translate.instant('RECOMMENDATION.STATUS_LIST.IN_PROGRESS') }, { id: 3, name: $translate.instant('RECOMMENDATION.STATUS_LIST.EXECUTED') }];

  cov_1v6u8gr80.s[18]++;
  $scope.getAll = function () {
    cov_1v6u8gr80.f[2]++;

    var columns = (cov_1v6u8gr80.s[19]++, [{ field: 'DESCRIPTION_RECOMM', width: 350, headerText: $translate.instant('RECOMMENDATION.DESCRIPTION'), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'responsiblesLabel', width: 250, headerText: $translate.instant('RECOMMENDATION.RESPONSIBLE'), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'DATE_END', width: 140, headerText: $translate.instant('RECOMMENDATION.DEADLINE'), format: 'yMd', clipMode: 'EllipsisWithTooltip', textAlign: 'Center' }, { field: 'status.name', width: 150, headerText: $translate.instant('RECOMMENDATION.STATUS'), filter: { type: 'CheckBox' }, type: 'string', clipMode: 'EllipsisWithTooltip' }]);

    cov_1v6u8gr80.s[20]++;
    if (angular.isUndefined($scope.recommendationViewOnly) ? (cov_1v6u8gr80.b[6][0]++, true) : (cov_1v6u8gr80.b[6][1]++, !$scope.recommendationViewOnly)) {
      cov_1v6u8gr80.b[5][0]++;
      cov_1v6u8gr80.s[21]++;

      columns.push({ headerText: '', width: 100,
        commands: [].concat($rootScope.currentRight.u ? (cov_1v6u8gr80.b[7][0]++, [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } }]) : (cov_1v6u8gr80.b[7][1]++, [])).concat($rootScope.currentRight.d ? (cov_1v6u8gr80.b[8][0]++, [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }]) : (cov_1v6u8gr80.b[8][1]++, []))
      });
    } else {
      cov_1v6u8gr80.b[5][1]++;
    }

    cov_1v6u8gr80.s[22]++;
    $scope.params = {
      toolbar: $scope.canEdit ? (cov_1v6u8gr80.b[9][0]++, ['Add']) : (cov_1v6u8gr80.b[9][1]++, undefined),
      editSettings: { allowEditing: $scope.canEdit, allowAdding: $scope.canEdit, allowDeleting: $scope.canDelete, allowEditOnDblClick: false },
      columns: columns,
      actionBegin: function actionBegin(action) {
        cov_1v6u8gr80.s[23]++;

        if (action.requestType === 'add') {
          cov_1v6u8gr80.b[10][0]++;
          cov_1v6u8gr80.s[24]++;

          action.cancel = true;
          cov_1v6u8gr80.s[25]++;
          $scope.addItem();
        } else {
          cov_1v6u8gr80.b[10][1]++;
        }
        cov_1v6u8gr80.s[26]++;
        if (action.requestType === 'beginEdit') {
          cov_1v6u8gr80.b[11][0]++;
          cov_1v6u8gr80.s[27]++;

          action.cancel = true;
          cov_1v6u8gr80.s[28]++;
          $scope.editItem(action.rowData);
          cov_1v6u8gr80.s[29]++;
          $scope.view_edit_form = true;
        } else {
          cov_1v6u8gr80.b[11][1]++;
        }
        cov_1v6u8gr80.s[30]++;
        if (action.requestType === 'delete') {
          cov_1v6u8gr80.b[12][0]++;
          cov_1v6u8gr80.s[31]++;

          action.cancel = true;
          cov_1v6u8gr80.s[32]++;
          $scope.deleteItem(action.data[0]);
        } else {
          cov_1v6u8gr80.b[12][1]++;
        }
      }
    };

    cov_1v6u8gr80.s[33]++;
    $scope.isloading = true;
    cov_1v6u8gr80.s[34]++;
    $CRUDService.getAll(PATH, Object.assign({ get: 'all' }, $scope.externParams), function (data) {
      cov_1v6u8gr80.f[3]++;
      cov_1v6u8gr80.s[35]++;

      $scope.listRecommendation = data.map(function (item) {
        cov_1v6u8gr80.f[4]++;
        cov_1v6u8gr80.s[36]++;

        item.status = $scope.listStatus.find(function (type) {
          cov_1v6u8gr80.f[5]++;
          cov_1v6u8gr80.s[37]++;

          return type.id == item.STATUS;
        });
        cov_1v6u8gr80.s[38]++;
        item.DATE_END = Date.newDate(item.DATE_END);

        var ids = (cov_1v6u8gr80.s[39]++, item.responsibles.map(function (actor) {
          cov_1v6u8gr80.f[6]++;
          cov_1v6u8gr80.s[40]++;

          return actor.id;
        }));

        cov_1v6u8gr80.s[41]++;
        item.responsiblesLabel = $scope.listData_actors_bksb.filter(function (actor) {
          cov_1v6u8gr80.f[7]++;
          cov_1v6u8gr80.s[42]++;

          return ids.indexOf(actor.id) >= 0;
        }).map(function (actor) {
          cov_1v6u8gr80.f[8]++;
          cov_1v6u8gr80.s[43]++;
          return actor.FONCTION_ACTEUR;
        }).join(' ; ');

        cov_1v6u8gr80.s[44]++;
        return item;
      });

      cov_1v6u8gr80.s[45]++;
      $scope.isloading = false;
    });
  };

  cov_1v6u8gr80.s[46]++;
  $scope.openReport = function (mission) {
    cov_1v6u8gr80.f[9]++;
    cov_1v6u8gr80.s[47]++;

    if (!mission.IDRAPPORTS) {
      cov_1v6u8gr80.b[13][0]++;
      cov_1v6u8gr80.s[48]++;

      SweetAlert.swal({
        title: '', // $translate.instant('COMMON.CONFIRM'),
        text: $translate.instant('MISSIONS.NO_REPORT'),
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#c8c8c8',

        confirmButtonText: $translate.instant('COMMON.VALIDATE'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isconfirm) {
        cov_1v6u8gr80.f[10]++;
      });
    } else {
      cov_1v6u8gr80.b[13][1]++;
    }
  };

  cov_1v6u8gr80.s[49]++;
  $scope.addItem = function () {
    cov_1v6u8gr80.f[11]++;
    cov_1v6u8gr80.s[50]++;

    $scope.editItem({
      DESCRIPTION_RECOMM: '',
      responsibles: [],
      status: $scope.listStatus[0],
      STATUS: $scope.listStatus[0].id,
      DATE_END: Date.newDate(),
      TYPE_RECOMMENDATION: $scope.externParams.type,
      LINKED_ID: $scope.externParams.id,
      id: 0
    });
  };

  cov_1v6u8gr80.s[51]++;
  $scope.editItem = function (item) {
    cov_1v6u8gr80.f[12]++;
    cov_1v6u8gr80.s[52]++;

    $scope.error_code = false;
    var validateItem = (cov_1v6u8gr80.s[53]++, angular.copy(item));

    var ids = (cov_1v6u8gr80.s[54]++, item.responsibles.map(function (actor) {
      cov_1v6u8gr80.f[13]++;
      cov_1v6u8gr80.s[55]++;

      return actor.id;
    }));

    cov_1v6u8gr80.s[56]++;
    validateItem.responsibles = $scope.listData_actors_bksb.filter(function (actor) {
      cov_1v6u8gr80.f[14]++;
      cov_1v6u8gr80.s[57]++;

      return ids.indexOf(actor.id) >= 0;
    });

    cov_1v6u8gr80.s[58]++;
    validateItem.status = $scope.listStatus.find(function (type) {
      cov_1v6u8gr80.f[15]++;
      cov_1v6u8gr80.s[59]++;

      return type.id == item.STATUS;
    });
    cov_1v6u8gr80.s[60]++;
    $scope.validateItem = validateItem;
  };

  cov_1v6u8gr80.s[61]++;
  $scope.validateData = function () {
    cov_1v6u8gr80.f[16]++;

    var validateItem = (cov_1v6u8gr80.s[62]++, {
      DESCRIPTION_RECOMM: $scope.validateItem.DESCRIPTION_RECOMM.trim(),
      STATUS: $scope.validateItem.status.id,
      DATE_END: $deltadate.format($scope.validateItem.DATE_END, 'YYYY-MM-DD'),
      TYPE_RECOMMENDATION: $scope.validateItem.TYPE_RECOMMENDATION,
      LINKED_ID: $scope.validateItem.LINKED_ID,
      responsibles: $scope.validateItem.responsibles.map(function (actor) {
        cov_1v6u8gr80.f[17]++;
        cov_1v6u8gr80.s[63]++;

        return { id: actor.id };
      }),
      id: $scope.validateItem.id
    });

    cov_1v6u8gr80.s[64]++;
    $scope.error_code = 0;
    cov_1v6u8gr80.s[65]++;
    if (((cov_1v6u8gr80.b[15][0]++, validateItem.DESCRIPTION_RECOMM) || (cov_1v6u8gr80.b[15][1]++, '')).trim().length == 0) {
      cov_1v6u8gr80.b[14][0]++;
      cov_1v6u8gr80.s[66]++;

      $scope.error_code = 1;
      cov_1v6u8gr80.s[67]++;
      return;
    } else {
      cov_1v6u8gr80.b[14][1]++;
    }
    cov_1v6u8gr80.s[68]++;
    $scope.isloading = true;
    cov_1v6u8gr80.s[69]++;
    $CRUDService.save(PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function () {
      cov_1v6u8gr80.f[18]++;
      cov_1v6u8gr80.s[70]++;

      $scope.cancelItem();
      cov_1v6u8gr80.s[71]++;
      $scope.getAll();
      cov_1v6u8gr80.s[72]++;
      $scope.view_edit_form = false;
    });
  };

  cov_1v6u8gr80.s[73]++;
  $scope.deleteItem = function (item) {
    cov_1v6u8gr80.f[19]++;
    cov_1v6u8gr80.s[74]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_1v6u8gr80.f[20]++;
      cov_1v6u8gr80.s[75]++;

      if (isconfirm) {
        cov_1v6u8gr80.b[16][0]++;
        cov_1v6u8gr80.s[76]++;

        return;
      } else {
        cov_1v6u8gr80.b[16][1]++;
      }
      cov_1v6u8gr80.s[77]++;
      $scope.isloading = true;
      var validateItem = (cov_1v6u8gr80.s[78]++, {
        id: item.id,
        NUMERO_ORDR_MISS: item.NUMERO_ORDR_MISS
      });
      /* SweetAlert.swal({
        title: $translate.instant('COMMON.ERROR_DELETE'),
        text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
        type: 'warning'
      }); */
      cov_1v6u8gr80.s[79]++;
      $CRUDService.delet(PATH, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_1v6u8gr80.f[21]++;
        cov_1v6u8gr80.s[80]++;

        if (data < 0) {
          cov_1v6u8gr80.b[17][0]++;
          cov_1v6u8gr80.s[81]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
            type: 'warning'
          });
          cov_1v6u8gr80.s[82]++;
          $scope.isloading = false;
        } else {
          cov_1v6u8gr80.b[17][1]++;
          cov_1v6u8gr80.s[83]++;

          $scope.getAll();
        }
      });
    });
  };

  cov_1v6u8gr80.s[84]++;
  $scope.cancelItem = function () {
    cov_1v6u8gr80.f[22]++;
    cov_1v6u8gr80.s[85]++;

    $scope.validateItem = null;
  };
  cov_1v6u8gr80.s[86]++;
  $scope.cancelItem();
  cov_1v6u8gr80.s[87]++;
  $scope.getAll();
});
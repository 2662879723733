'use strict';

var cov_5z7pp605i = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/financing/FinancingCtrl.js',
      hash = '0ee6d8d3986329292235409e5db20ded0d1f758c',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/financing/FinancingCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 3,
          column: 41
        }
      },
      '1': {
        start: {
          line: 4,
          column: 29
        },
        end: {
          line: 4,
          column: 43
        }
      },
      '2': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 16,
          column: 5
        }
      },
      '3': {
        start: {
          line: 6,
          column: 4
        },
        end: {
          line: 15,
          column: 6
        }
      },
      '4': {
        start: {
          line: 13,
          column: 8
        },
        end: {
          line: 13,
          column: 20
        }
      },
      '5': {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 32,
          column: 5
        }
      },
      '6': {
        start: {
          line: 19,
          column: 15
        },
        end: {
          line: 19,
          column: 33
        }
      },
      '7': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 27,
          column: 5
        }
      },
      '8': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 26,
          column: 8
        }
      },
      '9': {
        start: {
          line: 28,
          column: 4
        },
        end: {
          line: 30,
          column: 7
        }
      },
      '10': {
        start: {
          line: 29,
          column: 6
        },
        end: {
          line: 29,
          column: 51
        }
      },
      '11': {
        start: {
          line: 31,
          column: 4
        },
        end: {
          line: 31,
          column: 32
        }
      },
      '12': {
        start: {
          line: 34,
          column: 2
        },
        end: {
          line: 76,
          column: 4
        }
      },
      '13': {
        start: {
          line: 35,
          column: 25
        },
        end: {
          line: 35,
          column: 43
        }
      },
      '14': {
        start: {
          line: 36,
          column: 4
        },
        end: {
          line: 36,
          column: 30
        }
      },
      '15': {
        start: {
          line: 37,
          column: 4
        },
        end: {
          line: 37,
          column: 30
        }
      },
      '16': {
        start: {
          line: 38,
          column: 4
        },
        end: {
          line: 40,
          column: 5
        }
      },
      '17': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 39,
          column: 13
        }
      },
      '18': {
        start: {
          line: 42,
          column: 4
        },
        end: {
          line: 44,
          column: 5
        }
      },
      '19': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 43,
          column: 13
        }
      },
      '20': {
        start: {
          line: 46,
          column: 4
        },
        end: {
          line: 48,
          column: 5
        }
      },
      '21': {
        start: {
          line: 47,
          column: 6
        },
        end: {
          line: 47,
          column: 13
        }
      },
      '22': {
        start: {
          line: 50,
          column: 4
        },
        end: {
          line: 53,
          column: 5
        }
      },
      '23': {
        start: {
          line: 51,
          column: 6
        },
        end: {
          line: 51,
          column: 31
        }
      },
      '24': {
        start: {
          line: 52,
          column: 6
        },
        end: {
          line: 52,
          column: 13
        }
      },
      '25': {
        start: {
          line: 55,
          column: 16
        },
        end: {
          line: 57,
          column: 6
        }
      },
      '26': {
        start: {
          line: 56,
          column: 6
        },
        end: {
          line: 56,
          column: 116
        }
      },
      '27': {
        start: {
          line: 59,
          column: 4
        },
        end: {
          line: 62,
          column: 5
        }
      },
      '28': {
        start: {
          line: 60,
          column: 6
        },
        end: {
          line: 60,
          column: 31
        }
      },
      '29': {
        start: {
          line: 61,
          column: 6
        },
        end: {
          line: 61,
          column: 13
        }
      },
      '30': {
        start: {
          line: 64,
          column: 4
        },
        end: {
          line: 64,
          column: 59
        }
      },
      '31': {
        start: {
          line: 65,
          column: 4
        },
        end: {
          line: 65,
          column: 45
        }
      },
      '32': {
        start: {
          line: 67,
          column: 4
        },
        end: {
          line: 67,
          column: 91
        }
      },
      '33': {
        start: {
          line: 68,
          column: 4
        },
        end: {
          line: 68,
          column: 87
        }
      },
      '34': {
        start: {
          line: 70,
          column: 4
        },
        end: {
          line: 70,
          column: 28
        }
      },
      '35': {
        start: {
          line: 71,
          column: 4
        },
        end: {
          line: 75,
          column: 7
        }
      },
      '36': {
        start: {
          line: 72,
          column: 6
        },
        end: {
          line: 72,
          column: 112
        }
      },
      '37': {
        start: {
          line: 73,
          column: 6
        },
        end: {
          line: 73,
          column: 22
        }
      },
      '38': {
        start: {
          line: 74,
          column: 6
        },
        end: {
          line: 74,
          column: 26
        }
      },
      '39': {
        start: {
          line: 77,
          column: 2
        },
        end: {
          line: 79,
          column: 4
        }
      },
      '40': {
        start: {
          line: 78,
          column: 4
        },
        end: {
          line: 78,
          column: 40
        }
      },
      '41': {
        start: {
          line: 81,
          column: 2
        },
        end: {
          line: 85,
          column: 5
        }
      },
      '42': {
        start: {
          line: 82,
          column: 4
        },
        end: {
          line: 82,
          column: 108
        }
      },
      '43': {
        start: {
          line: 83,
          column: 4
        },
        end: {
          line: 83,
          column: 113
        }
      },
      '44': {
        start: {
          line: 87,
          column: 2
        },
        end: {
          line: 102,
          column: 5
        }
      },
      '45': {
        start: {
          line: 88,
          column: 4
        },
        end: {
          line: 88,
          column: 29
        }
      },
      '46': {
        start: {
          line: 89,
          column: 4
        },
        end: {
          line: 91,
          column: 5
        }
      },
      '47': {
        start: {
          line: 90,
          column: 6
        },
        end: {
          line: 90,
          column: 13
        }
      },
      '48': {
        start: {
          line: 92,
          column: 4
        },
        end: {
          line: 101,
          column: 7
        }
      },
      '49': {
        start: {
          line: 93,
          column: 19
        },
        end: {
          line: 93,
          column: 37
        }
      },
      '50': {
        start: {
          line: 94,
          column: 6
        },
        end: {
          line: 94,
          column: 34
        }
      },
      '51': {
        start: {
          line: 96,
          column: 6
        },
        end: {
          line: 100,
          column: 7
        }
      },
      '52': {
        start: {
          line: 97,
          column: 8
        },
        end: {
          line: 99,
          column: 11
        }
      },
      '53': {
        start: {
          line: 98,
          column: 10
        },
        end: {
          line: 98,
          column: 58
        }
      },
      '54': {
        start: {
          line: 104,
          column: 2
        },
        end: {
          line: 109,
          column: 5
        }
      },
      '55': {
        start: {
          line: 105,
          column: 4
        },
        end: {
          line: 107,
          column: 5
        }
      },
      '56': {
        start: {
          line: 106,
          column: 6
        },
        end: {
          line: 106,
          column: 13
        }
      },
      '57': {
        start: {
          line: 108,
          column: 4
        },
        end: {
          line: 108,
          column: 29
        }
      },
      '58': {
        start: {
          line: 111,
          column: 2
        },
        end: {
          line: 127,
          column: 4
        }
      },
      '59': {
        start: {
          line: 112,
          column: 4
        },
        end: {
          line: 126,
          column: 7
        }
      },
      '60': {
        start: {
          line: 113,
          column: 17
        },
        end: {
          line: 113,
          column: 35
        }
      },
      '61': {
        start: {
          line: 114,
          column: 6
        },
        end: {
          line: 121,
          column: 7
        }
      },
      '62': {
        start: {
          line: 115,
          column: 8
        },
        end: {
          line: 120,
          column: 10
        }
      },
      '63': {
        start: {
          line: 122,
          column: 6
        },
        end: {
          line: 124,
          column: 9
        }
      },
      '64': {
        start: {
          line: 123,
          column: 8
        },
        end: {
          line: 123,
          column: 53
        }
      },
      '65': {
        start: {
          line: 125,
          column: 6
        },
        end: {
          line: 125,
          column: 34
        }
      },
      '66': {
        start: {
          line: 130,
          column: 0
        },
        end: {
          line: 306,
          column: 3
        }
      },
      '67': {
        start: {
          line: 131,
          column: 2
        },
        end: {
          line: 131,
          column: 38
        }
      },
      '68': {
        start: {
          line: 132,
          column: 2
        },
        end: {
          line: 134,
          column: 3
        }
      },
      '69': {
        start: {
          line: 133,
          column: 4
        },
        end: {
          line: 133,
          column: 11
        }
      },
      '70': {
        start: {
          line: 135,
          column: 29
        },
        end: {
          line: 135,
          column: 43
        }
      },
      '71': {
        start: {
          line: 136,
          column: 2
        },
        end: {
          line: 136,
          column: 21
        }
      },
      '72': {
        start: {
          line: 137,
          column: 2
        },
        end: {
          line: 137,
          column: 22
        }
      },
      '73': {
        start: {
          line: 138,
          column: 2
        },
        end: {
          line: 138,
          column: 27
        }
      },
      '74': {
        start: {
          line: 139,
          column: 2
        },
        end: {
          line: 139,
          column: 26
        }
      },
      '75': {
        start: {
          line: 140,
          column: 2
        },
        end: {
          line: 140,
          column: 29
        }
      },
      '76': {
        start: {
          line: 141,
          column: 2
        },
        end: {
          line: 141,
          column: 49
        }
      },
      '77': {
        start: {
          line: 142,
          column: 18
        },
        end: {
          line: 142,
          column: 48
        }
      },
      '78': {
        start: {
          line: 143,
          column: 17
        },
        end: {
          line: 143,
          column: 19
        }
      },
      '79': {
        start: {
          line: 144,
          column: 2
        },
        end: {
          line: 147,
          column: 4
        }
      },
      '80': {
        start: {
          line: 149,
          column: 2
        },
        end: {
          line: 155,
          column: 5
        }
      },
      '81': {
        start: {
          line: 150,
          column: 4
        },
        end: {
          line: 154,
          column: 7
        }
      },
      '82': {
        start: {
          line: 157,
          column: 2
        },
        end: {
          line: 190,
          column: 4
        }
      },
      '83': {
        start: {
          line: 177,
          column: 6
        },
        end: {
          line: 180,
          column: 7
        }
      },
      '84': {
        start: {
          line: 178,
          column: 8
        },
        end: {
          line: 178,
          column: 29
        }
      },
      '85': {
        start: {
          line: 179,
          column: 8
        },
        end: {
          line: 179,
          column: 21
        }
      },
      '86': {
        start: {
          line: 181,
          column: 6
        },
        end: {
          line: 184,
          column: 7
        }
      },
      '87': {
        start: {
          line: 182,
          column: 8
        },
        end: {
          line: 182,
          column: 29
        }
      },
      '88': {
        start: {
          line: 183,
          column: 8
        },
        end: {
          line: 183,
          column: 36
        }
      },
      '89': {
        start: {
          line: 185,
          column: 6
        },
        end: {
          line: 188,
          column: 7
        }
      },
      '90': {
        start: {
          line: 186,
          column: 8
        },
        end: {
          line: 186,
          column: 29
        }
      },
      '91': {
        start: {
          line: 187,
          column: 8
        },
        end: {
          line: 187,
          column: 45
        }
      },
      '92': {
        start: {
          line: 192,
          column: 2
        },
        end: {
          line: 192,
          column: 27
        }
      },
      '93': {
        start: {
          line: 194,
          column: 2
        },
        end: {
          line: 194,
          column: 28
        }
      },
      '94': {
        start: {
          line: 196,
          column: 2
        },
        end: {
          line: 198,
          column: 5
        }
      },
      '95': {
        start: {
          line: 197,
          column: 4
        },
        end: {
          line: 197,
          column: 31
        }
      },
      '96': {
        start: {
          line: 200,
          column: 2
        },
        end: {
          line: 215,
          column: 4
        }
      },
      '97': {
        start: {
          line: 201,
          column: 4
        },
        end: {
          line: 201,
          column: 28
        }
      },
      '98': {
        start: {
          line: 202,
          column: 4
        },
        end: {
          line: 214,
          column: 7
        }
      },
      '99': {
        start: {
          line: 203,
          column: 6
        },
        end: {
          line: 210,
          column: 7
        }
      },
      '100': {
        start: {
          line: 204,
          column: 8
        },
        end: {
          line: 204,
          column: 42
        }
      },
      '101': {
        start: {
          line: 205,
          column: 8
        },
        end: {
          line: 205,
          column: 56
        }
      },
      '102': {
        start: {
          line: 206,
          column: 8
        },
        end: {
          line: 206,
          column: 52
        }
      },
      '103': {
        start: {
          line: 207,
          column: 8
        },
        end: {
          line: 207,
          column: 46
        }
      },
      '104': {
        start: {
          line: 209,
          column: 8
        },
        end: {
          line: 209,
          column: 51
        }
      },
      '105': {
        start: {
          line: 211,
          column: 6
        },
        end: {
          line: 211,
          column: 32
        }
      },
      '106': {
        start: {
          line: 212,
          column: 6
        },
        end: {
          line: 212,
          column: 31
        }
      },
      '107': {
        start: {
          line: 213,
          column: 6
        },
        end: {
          line: 213,
          column: 53
        }
      },
      '108': {
        start: {
          line: 216,
          column: 2
        },
        end: {
          line: 216,
          column: 18
        }
      },
      '109': {
        start: {
          line: 218,
          column: 2
        },
        end: {
          line: 222,
          column: 4
        }
      },
      '110': {
        start: {
          line: 219,
          column: 4
        },
        end: {
          line: 219,
          column: 22
        }
      },
      '111': {
        start: {
          line: 220,
          column: 4
        },
        end: {
          line: 220,
          column: 24
        }
      },
      '112': {
        start: {
          line: 221,
          column: 4
        },
        end: {
          line: 221,
          column: 21
        }
      },
      '113': {
        start: {
          line: 224,
          column: 2
        },
        end: {
          line: 235,
          column: 4
        }
      },
      '114': {
        start: {
          line: 225,
          column: 4
        },
        end: {
          line: 234,
          column: 7
        }
      },
      '115': {
        start: {
          line: 237,
          column: 2
        },
        end: {
          line: 254,
          column: 4
        }
      },
      '116': {
        start: {
          line: 238,
          column: 25
        },
        end: {
          line: 238,
          column: 43
        }
      },
      '117': {
        start: {
          line: 240,
          column: 4
        },
        end: {
          line: 242,
          column: 7
        }
      },
      '118': {
        start: {
          line: 241,
          column: 6
        },
        end: {
          line: 241,
          column: 44
        }
      },
      '119': {
        start: {
          line: 244,
          column: 4
        },
        end: {
          line: 244,
          column: 39
        }
      },
      '120': {
        start: {
          line: 246,
          column: 4
        },
        end: {
          line: 253,
          column: 7
        }
      },
      '121': {
        start: {
          line: 256,
          column: 2
        },
        end: {
          line: 260,
          column: 4
        }
      },
      '122': {
        start: {
          line: 257,
          column: 4
        },
        end: {
          line: 257,
          column: 23
        }
      },
      '123': {
        start: {
          line: 258,
          column: 4
        },
        end: {
          line: 258,
          column: 24
        }
      },
      '124': {
        start: {
          line: 259,
          column: 4
        },
        end: {
          line: 259,
          column: 29
        }
      },
      '125': {
        start: {
          line: 262,
          column: 2
        },
        end: {
          line: 265,
          column: 4
        }
      },
      '126': {
        start: {
          line: 263,
          column: 4
        },
        end: {
          line: 263,
          column: 28
        }
      },
      '127': {
        start: {
          line: 264,
          column: 4
        },
        end: {
          line: 264,
          column: 31
        }
      },
      '128': {
        start: {
          line: 267,
          column: 2
        },
        end: {
          line: 302,
          column: 4
        }
      },
      '129': {
        start: {
          line: 268,
          column: 4
        },
        end: {
          line: 301,
          column: 7
        }
      },
      '130': {
        start: {
          line: 280,
          column: 6
        },
        end: {
          line: 282,
          column: 7
        }
      },
      '131': {
        start: {
          line: 281,
          column: 8
        },
        end: {
          line: 281,
          column: 15
        }
      },
      '132': {
        start: {
          line: 283,
          column: 6
        },
        end: {
          line: 283,
          column: 30
        }
      },
      '133': {
        start: {
          line: 284,
          column: 27
        },
        end: {
          line: 284,
          column: 45
        }
      },
      '134': {
        start: {
          line: 285,
          column: 6
        },
        end: {
          line: 300,
          column: 9
        }
      },
      '135': {
        start: {
          line: 286,
          column: 8
        },
        end: {
          line: 293,
          column: 9
        }
      },
      '136': {
        start: {
          line: 287,
          column: 10
        },
        end: {
          line: 290,
          column: 13
        }
      },
      '137': {
        start: {
          line: 291,
          column: 10
        },
        end: {
          line: 291,
          column: 35
        }
      },
      '138': {
        start: {
          line: 292,
          column: 10
        },
        end: {
          line: 292,
          column: 17
        }
      },
      '139': {
        start: {
          line: 298,
          column: 8
        },
        end: {
          line: 298,
          column: 27
        }
      },
      '140': {
        start: {
          line: 299,
          column: 8
        },
        end: {
          line: 299,
          column: 24
        }
      },
      '141': {
        start: {
          line: 303,
          column: 2
        },
        end: {
          line: 305,
          column: 4
        }
      }
    },
    fnMap: {
      '0': {
        name: 'Financing_edit',
        decl: {
          start: {
            line: 2,
            column: 9
          },
          end: {
            line: 2,
            column: 23
          }
        },
        loc: {
          start: {
            line: 2,
            column: 122
          },
          end: {
            line: 128,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 5,
            column: 50
          },
          end: {
            line: 5,
            column: 51
          }
        },
        loc: {
          start: {
            line: 5,
            column: 58
          },
          end: {
            line: 16,
            column: 3
          }
        },
        line: 5
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 18,
            column: 68
          },
          end: {
            line: 18,
            column: 69
          }
        },
        loc: {
          start: {
            line: 18,
            column: 77
          },
          end: {
            line: 32,
            column: 3
          }
        },
        line: 18
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 28,
            column: 41
          },
          end: {
            line: 28,
            column: 42
          }
        },
        loc: {
          start: {
            line: 28,
            column: 51
          },
          end: {
            line: 30,
            column: 5
          }
        },
        line: 28
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 34,
            column: 24
          },
          end: {
            line: 34,
            column: 25
          }
        },
        loc: {
          start: {
            line: 34,
            column: 40
          },
          end: {
            line: 76,
            column: 3
          }
        },
        line: 34
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 55,
            column: 40
          },
          end: {
            line: 55,
            column: 41
          }
        },
        loc: {
          start: {
            line: 55,
            column: 49
          },
          end: {
            line: 57,
            column: 5
          }
        },
        line: 55
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 71,
            column: 97
          },
          end: {
            line: 71,
            column: 98
          }
        },
        loc: {
          start: {
            line: 71,
            column: 103
          },
          end: {
            line: 75,
            column: 5
          }
        },
        line: 71
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 77,
            column: 22
          },
          end: {
            line: 77,
            column: 23
          }
        },
        loc: {
          start: {
            line: 77,
            column: 34
          },
          end: {
            line: 79,
            column: 3
          }
        },
        line: 77
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 81,
            column: 27
          },
          end: {
            line: 81,
            column: 28
          }
        },
        loc: {
          start: {
            line: 81,
            column: 33
          },
          end: {
            line: 85,
            column: 3
          }
        },
        line: 81
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 87,
            column: 37
          },
          end: {
            line: 87,
            column: 38
          }
        },
        loc: {
          start: {
            line: 87,
            column: 54
          },
          end: {
            line: 102,
            column: 3
          }
        },
        line: 87
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 92,
            column: 70
          },
          end: {
            line: 92,
            column: 71
          }
        },
        loc: {
          start: {
            line: 92,
            column: 79
          },
          end: {
            line: 101,
            column: 5
          }
        },
        line: 92
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 97,
            column: 45
          },
          end: {
            line: 97,
            column: 46
          }
        },
        loc: {
          start: {
            line: 97,
            column: 55
          },
          end: {
            line: 99,
            column: 9
          }
        },
        line: 97
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 104,
            column: 37
          },
          end: {
            line: 104,
            column: 38
          }
        },
        loc: {
          start: {
            line: 104,
            column: 54
          },
          end: {
            line: 109,
            column: 3
          }
        },
        line: 104
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 111,
            column: 27
          },
          end: {
            line: 111,
            column: 28
          }
        },
        loc: {
          start: {
            line: 111,
            column: 39
          },
          end: {
            line: 127,
            column: 3
          }
        },
        line: 111
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 112,
            column: 70
          },
          end: {
            line: 112,
            column: 71
          }
        },
        loc: {
          start: {
            line: 112,
            column: 79
          },
          end: {
            line: 126,
            column: 5
          }
        },
        line: 112
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 122,
            column: 43
          },
          end: {
            line: 122,
            column: 44
          }
        },
        loc: {
          start: {
            line: 122,
            column: 53
          },
          end: {
            line: 124,
            column: 7
          }
        },
        line: 122
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 130,
            column: 50
          },
          end: {
            line: 130,
            column: 51
          }
        },
        loc: {
          start: {
            line: 130,
            column: 186
          },
          end: {
            line: 306,
            column: 1
          }
        },
        line: 130
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 149,
            column: 22
          },
          end: {
            line: 149,
            column: 23
          }
        },
        loc: {
          start: {
            line: 149,
            column: 28
          },
          end: {
            line: 155,
            column: 3
          }
        },
        line: 149
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 196,
            column: 51
          },
          end: {
            line: 196,
            column: 52
          }
        },
        loc: {
          start: {
            line: 196,
            column: 59
          },
          end: {
            line: 198,
            column: 3
          }
        },
        line: 196
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 200,
            column: 18
          },
          end: {
            line: 200,
            column: 19
          }
        },
        loc: {
          start: {
            line: 200,
            column: 30
          },
          end: {
            line: 215,
            column: 3
          }
        },
        line: 200
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 202,
            column: 51
          },
          end: {
            line: 202,
            column: 52
          }
        },
        loc: {
          start: {
            line: 202,
            column: 59
          },
          end: {
            line: 214,
            column: 5
          }
        },
        line: 202
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 218,
            column: 18
          },
          end: {
            line: 218,
            column: 19
          }
        },
        loc: {
          start: {
            line: 218,
            column: 30
          },
          end: {
            line: 222,
            column: 3
          }
        },
        line: 218
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 224,
            column: 15
          },
          end: {
            line: 224,
            column: 16
          }
        },
        loc: {
          start: {
            line: 224,
            column: 27
          },
          end: {
            line: 235,
            column: 3
          }
        },
        line: 224
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 237,
            column: 16
          },
          end: {
            line: 237,
            column: 17
          }
        },
        loc: {
          start: {
            line: 237,
            column: 32
          },
          end: {
            line: 254,
            column: 3
          }
        },
        line: 237
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 240,
            column: 56
          },
          end: {
            line: 240,
            column: 57
          }
        },
        loc: {
          start: {
            line: 240,
            column: 66
          },
          end: {
            line: 242,
            column: 5
          }
        },
        line: 240
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 256,
            column: 21
          },
          end: {
            line: 256,
            column: 22
          }
        },
        loc: {
          start: {
            line: 256,
            column: 33
          },
          end: {
            line: 260,
            column: 3
          }
        },
        line: 256
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 262,
            column: 22
          },
          end: {
            line: 262,
            column: 23
          }
        },
        loc: {
          start: {
            line: 262,
            column: 46
          },
          end: {
            line: 265,
            column: 3
          }
        },
        line: 262
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 267,
            column: 25
          },
          end: {
            line: 267,
            column: 26
          }
        },
        loc: {
          start: {
            line: 267,
            column: 41
          },
          end: {
            line: 302,
            column: 3
          }
        },
        line: 267
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 279,
            column: 7
          },
          end: {
            line: 279,
            column: 8
          }
        },
        loc: {
          start: {
            line: 279,
            column: 20
          },
          end: {
            line: 301,
            column: 5
          }
        },
        line: 279
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 285,
            column: 95
          },
          end: {
            line: 285,
            column: 96
          }
        },
        loc: {
          start: {
            line: 285,
            column: 103
          },
          end: {
            line: 300,
            column: 7
          }
        },
        line: 285
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 303,
            column: 18
          },
          end: {
            line: 303,
            column: 19
          }
        },
        loc: {
          start: {
            line: 303,
            column: 33
          },
          end: {
            line: 305,
            column: 3
          }
        },
        line: 303
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 20,
            column: 4
          },
          end: {
            line: 27,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 20,
            column: 4
          },
          end: {
            line: 27,
            column: 5
          }
        }, {
          start: {
            line: 20,
            column: 4
          },
          end: {
            line: 27,
            column: 5
          }
        }],
        line: 20
      },
      '1': {
        loc: {
          start: {
            line: 38,
            column: 4
          },
          end: {
            line: 40,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 38,
            column: 4
          },
          end: {
            line: 40,
            column: 5
          }
        }, {
          start: {
            line: 38,
            column: 4
          },
          end: {
            line: 40,
            column: 5
          }
        }],
        line: 38
      },
      '2': {
        loc: {
          start: {
            line: 42,
            column: 4
          },
          end: {
            line: 44,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 42,
            column: 4
          },
          end: {
            line: 44,
            column: 5
          }
        }, {
          start: {
            line: 42,
            column: 4
          },
          end: {
            line: 44,
            column: 5
          }
        }],
        line: 42
      },
      '3': {
        loc: {
          start: {
            line: 42,
            column: 8
          },
          end: {
            line: 42,
            column: 79
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 42,
            column: 8
          },
          end: {
            line: 42,
            column: 42
          }
        }, {
          start: {
            line: 42,
            column: 46
          },
          end: {
            line: 42,
            column: 79
          }
        }],
        line: 42
      },
      '4': {
        loc: {
          start: {
            line: 46,
            column: 4
          },
          end: {
            line: 48,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 46,
            column: 4
          },
          end: {
            line: 48,
            column: 5
          }
        }, {
          start: {
            line: 46,
            column: 4
          },
          end: {
            line: 48,
            column: 5
          }
        }],
        line: 46
      },
      '5': {
        loc: {
          start: {
            line: 50,
            column: 4
          },
          end: {
            line: 53,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 50,
            column: 4
          },
          end: {
            line: 53,
            column: 5
          }
        }, {
          start: {
            line: 50,
            column: 4
          },
          end: {
            line: 53,
            column: 5
          }
        }],
        line: 50
      },
      '6': {
        loc: {
          start: {
            line: 56,
            column: 13
          },
          end: {
            line: 56,
            column: 115
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 56,
            column: 13
          },
          end: {
            line: 56,
            column: 41
          }
        }, {
          start: {
            line: 56,
            column: 45
          },
          end: {
            line: 56,
            column: 115
          }
        }],
        line: 56
      },
      '7': {
        loc: {
          start: {
            line: 59,
            column: 4
          },
          end: {
            line: 62,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 59,
            column: 4
          },
          end: {
            line: 62,
            column: 5
          }
        }, {
          start: {
            line: 59,
            column: 4
          },
          end: {
            line: 62,
            column: 5
          }
        }],
        line: 59
      },
      '8': {
        loc: {
          start: {
            line: 72,
            column: 20
          },
          end: {
            line: 72,
            column: 110
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 72,
            column: 33
          },
          end: {
            line: 72,
            column: 69
          }
        }, {
          start: {
            line: 72,
            column: 72
          },
          end: {
            line: 72,
            column: 110
          }
        }],
        line: 72
      },
      '9': {
        loc: {
          start: {
            line: 89,
            column: 4
          },
          end: {
            line: 91,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 89,
            column: 4
          },
          end: {
            line: 91,
            column: 5
          }
        }, {
          start: {
            line: 89,
            column: 4
          },
          end: {
            line: 91,
            column: 5
          }
        }],
        line: 89
      },
      '10': {
        loc: {
          start: {
            line: 96,
            column: 6
          },
          end: {
            line: 100,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 96,
            column: 6
          },
          end: {
            line: 100,
            column: 7
          }
        }, {
          start: {
            line: 96,
            column: 6
          },
          end: {
            line: 100,
            column: 7
          }
        }],
        line: 96
      },
      '11': {
        loc: {
          start: {
            line: 105,
            column: 4
          },
          end: {
            line: 107,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 105,
            column: 4
          },
          end: {
            line: 107,
            column: 5
          }
        }, {
          start: {
            line: 105,
            column: 4
          },
          end: {
            line: 107,
            column: 5
          }
        }],
        line: 105
      },
      '12': {
        loc: {
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 121,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 121,
            column: 7
          }
        }, {
          start: {
            line: 114,
            column: 6
          },
          end: {
            line: 121,
            column: 7
          }
        }],
        line: 114
      },
      '13': {
        loc: {
          start: {
            line: 132,
            column: 2
          },
          end: {
            line: 134,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 132,
            column: 2
          },
          end: {
            line: 134,
            column: 3
          }
        }, {
          start: {
            line: 132,
            column: 2
          },
          end: {
            line: 134,
            column: 3
          }
        }],
        line: 132
      },
      '14': {
        loc: {
          start: {
            line: 158,
            column: 14
          },
          end: {
            line: 158,
            column: 61
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 158,
            column: 42
          },
          end: {
            line: 158,
            column: 49
          }
        }, {
          start: {
            line: 158,
            column: 52
          },
          end: {
            line: 158,
            column: 61
          }
        }],
        line: 158
      },
      '15': {
        loc: {
          start: {
            line: 172,
            column: 28
          },
          end: {
            line: 172,
            column: 141
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 172,
            column: 56
          },
          end: {
            line: 172,
            column: 136
          }
        }, {
          start: {
            line: 172,
            column: 139
          },
          end: {
            line: 172,
            column: 141
          }
        }],
        line: 172
      },
      '16': {
        loc: {
          start: {
            line: 173,
            column: 18
          },
          end: {
            line: 173,
            column: 134
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 173,
            column: 46
          },
          end: {
            line: 173,
            column: 129
          }
        }, {
          start: {
            line: 173,
            column: 132
          },
          end: {
            line: 173,
            column: 134
          }
        }],
        line: 173
      },
      '17': {
        loc: {
          start: {
            line: 177,
            column: 6
          },
          end: {
            line: 180,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 177,
            column: 6
          },
          end: {
            line: 180,
            column: 7
          }
        }, {
          start: {
            line: 177,
            column: 6
          },
          end: {
            line: 180,
            column: 7
          }
        }],
        line: 177
      },
      '18': {
        loc: {
          start: {
            line: 181,
            column: 6
          },
          end: {
            line: 184,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 181,
            column: 6
          },
          end: {
            line: 184,
            column: 7
          }
        }, {
          start: {
            line: 181,
            column: 6
          },
          end: {
            line: 184,
            column: 7
          }
        }],
        line: 181
      },
      '19': {
        loc: {
          start: {
            line: 185,
            column: 6
          },
          end: {
            line: 188,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 185,
            column: 6
          },
          end: {
            line: 188,
            column: 7
          }
        }, {
          start: {
            line: 185,
            column: 6
          },
          end: {
            line: 188,
            column: 7
          }
        }],
        line: 185
      },
      '20': {
        loc: {
          start: {
            line: 227,
            column: 20
          },
          end: {
            line: 227,
            column: 53
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 227,
            column: 20
          },
          end: {
            line: 227,
            column: 42
          }
        }, {
          start: {
            line: 227,
            column: 46
          },
          end: {
            line: 227,
            column: 53
          }
        }],
        line: 227
      },
      '21': {
        loc: {
          start: {
            line: 280,
            column: 6
          },
          end: {
            line: 282,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 280,
            column: 6
          },
          end: {
            line: 282,
            column: 7
          }
        }, {
          start: {
            line: 280,
            column: 6
          },
          end: {
            line: 282,
            column: 7
          }
        }],
        line: 280
      },
      '22': {
        loc: {
          start: {
            line: 286,
            column: 8
          },
          end: {
            line: 293,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 286,
            column: 8
          },
          end: {
            line: 293,
            column: 9
          }
        }, {
          start: {
            line: 286,
            column: 8
          },
          end: {
            line: 293,
            column: 9
          }
        }],
        line: 286
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

function Financing_edit($scope, $uibModalInstance, $deltahttp, $filter, $CRUDService, $deltadate, $translate, $rootScope) {
  cov_5z7pp605i.f[0]++;
  cov_5z7pp605i.s[0]++;

  $scope.autoCompleteGroupOptions = null;
  var PATH_CUSTOM_FIELDS = (cov_5z7pp605i.s[1]++, 'CustomFields');
  cov_5z7pp605i.s[2]++;
  $CRUDService.getAll('Projet', { get: 'devises' }, function (_data) {
    cov_5z7pp605i.f[1]++;
    cov_5z7pp605i.s[3]++;

    $scope.autoCompleteGroupOptions = {
      minimumChars: 0,
      containerCssClass: 'white-bg',
      selectedCssClass: 'selected-item-auto-complete',
      noMatchTemplateEnabled: false,
      activateOnFocus: true,
      data: function data(searchText) {
        cov_5z7pp605i.s[4]++;
        // const data = $filter('filter')($scope.listActors, {CATEGORIE_ACTEUR: searchText});
        return _data;
      }
    };
  });

  cov_5z7pp605i.s[5]++;
  $CRUDService.getAll(PATH_CUSTOM_FIELDS, { get: 'single', type: 3 }, function (data_) {
    cov_5z7pp605i.f[2]++;

    var data = (cov_5z7pp605i.s[6]++, data_.VALUES_FIELD);
    cov_5z7pp605i.s[7]++;
    if (!angular.isObject(data)) {
      cov_5z7pp605i.b[0][0]++;
      cov_5z7pp605i.s[8]++;

      data = [{ id: 1, name: $translate.instant('FINANCING.TYPES.GRANT') }, { id: 2, name: $translate.instant('FINANCING.TYPES.LOAN') }, { id: 3, name: $translate.instant('FINANCING.TYPES.DONATION') }, { id: 4, name: $translate.instant('FINANCING.TYPES.BUDGET_SUPPORT') }];
    } else {
      cov_5z7pp605i.b[0][1]++;
    }
    cov_5z7pp605i.s[9]++;
    $scope.validateItem.TYPE = data.find(function (status) {
      cov_5z7pp605i.f[3]++;
      cov_5z7pp605i.s[10]++;

      return status.id == $scope.validateItem.TYPE;
    });
    cov_5z7pp605i.s[11]++;
    $scope.typeFinancing = data;
  });

  cov_5z7pp605i.s[12]++;
  $scope.setValidData = function (item) {
    cov_5z7pp605i.f[4]++;

    var validateItem = (cov_5z7pp605i.s[13]++, angular.copy(item));
    cov_5z7pp605i.s[14]++;
    $scope.error_code = false;
    cov_5z7pp605i.s[15]++;
    $scope.error_date = false;
    cov_5z7pp605i.s[16]++;
    if (!validateItem.AGREEMENT) {
      cov_5z7pp605i.b[1][0]++;
      cov_5z7pp605i.s[17]++;

      return;
    } else {
      cov_5z7pp605i.b[1][1]++;
    }

    cov_5z7pp605i.s[18]++;
    if ((cov_5z7pp605i.b[3][0]++, validateItem.AGREEMENT.length > 50) || (cov_5z7pp605i.b[3][1]++, validateItem.AGREEMENT.length < 1)) {
      cov_5z7pp605i.b[2][0]++;
      cov_5z7pp605i.s[19]++;

      return;
    } else {
      cov_5z7pp605i.b[2][1]++;
    }

    cov_5z7pp605i.s[20]++;
    if (!validateItem.IDSOURCEFIN) {
      cov_5z7pp605i.b[4][0]++;
      cov_5z7pp605i.s[21]++;

      return;
    } else {
      cov_5z7pp605i.b[4][1]++;
    }

    cov_5z7pp605i.s[22]++;
    if (validateItem.APPROVE_DATE > validateItem.CLOSE_DATE) {
      cov_5z7pp605i.b[5][0]++;
      cov_5z7pp605i.s[23]++;

      $scope.error_date = true;
      cov_5z7pp605i.s[24]++;
      return;
    } else {
      cov_5z7pp605i.b[5][1]++;
    }

    var dbl = (cov_5z7pp605i.s[25]++, $scope.MyDataListe.find(function (value) {
      cov_5z7pp605i.f[5]++;
      cov_5z7pp605i.s[26]++;

      return (cov_5z7pp605i.b[6][0]++, value.id !== validateItem.id) && (cov_5z7pp605i.b[6][1]++, validateItem.AGREEMENT.toUpperCase() === value.AGREEMENT.toUpperCase());
    }));

    cov_5z7pp605i.s[27]++;
    if (dbl) {
      cov_5z7pp605i.b[7][0]++;
      cov_5z7pp605i.s[28]++;

      $scope.error_code = true;
      cov_5z7pp605i.s[29]++;
      return;
    } else {
      cov_5z7pp605i.b[7][1]++;
    }

    cov_5z7pp605i.s[30]++;
    validateItem.IDSOURCEFIN = validateItem.IDSOURCEFIN.id;
    cov_5z7pp605i.s[31]++;
    validateItem.TYPE = validateItem.TYPE.id;

    cov_5z7pp605i.s[32]++;
    validateItem.APPROVE_DATE = $deltadate.format(validateItem.APPROVE_DATE, 'YYYY-MM-DD');
    cov_5z7pp605i.s[33]++;
    validateItem.CLOSE_DATE = $deltadate.format(validateItem.CLOSE_DATE, 'YYYY-MM-DD');

    cov_5z7pp605i.s[34]++;
    $scope.isloading = true;
    cov_5z7pp605i.s[35]++;
    $CRUDService.save($scope.PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function () {
      cov_5z7pp605i.f[6]++;
      cov_5z7pp605i.s[36]++;

      $scope.notify($scope.add ? (cov_5z7pp605i.b[8][0]++, $translate.instant('COMMON.MSG_ADD')) : (cov_5z7pp605i.b[8][1]++, $translate.instant('COMMON.MSG_MODIF')));
      cov_5z7pp605i.s[37]++;
      $scope.getAll();
      cov_5z7pp605i.s[38]++;
      $scope.closeModal();
    });
  };
  cov_5z7pp605i.s[39]++;
  $scope.closeModal = function () {
    cov_5z7pp605i.f[7]++;
    cov_5z7pp605i.s[40]++;

    $uibModalInstance.dismiss('cancel');
  };

  cov_5z7pp605i.s[41]++;
  $scope.customAllItems = function () {
    cov_5z7pp605i.f[8]++;
    cov_5z7pp605i.s[42]++;

    $rootScope.updateCustomFields({ type: 3, withColor: false, title: $translate.instant('FINANCING.TYPE') });
    cov_5z7pp605i.s[43]++;
    $rootScope.deleteCustomFieldsEvent({ type: 3, withColor: false, title: $translate.instant('FINANCING.TYPE') });
    // delete_custom_fields
  };

  cov_5z7pp605i.s[44]++;
  $scope.$on('update_custom_fields', function (evt, params) {
    cov_5z7pp605i.f[9]++;
    cov_5z7pp605i.s[45]++;

    $scope.allCustomsField();
    cov_5z7pp605i.s[46]++;
    if (!(params.type == 3)) {
      cov_5z7pp605i.b[9][0]++;
      cov_5z7pp605i.s[47]++;

      return;
    } else {
      cov_5z7pp605i.b[9][1]++;
    }
    cov_5z7pp605i.s[48]++;
    $CRUDService.getAll(PATH_CUSTOM_FIELDS, { type: 3, get: 'single' }, function (data_) {
      cov_5z7pp605i.f[10]++;

      var data = (cov_5z7pp605i.s[49]++, data_.VALUES_FIELD);
      cov_5z7pp605i.s[50]++;
      $scope.typeFinancing = data;

      cov_5z7pp605i.s[51]++;
      if (_typeof($scope.validateItem.TYPE) != undefined) {
        cov_5z7pp605i.b[10][0]++;
        cov_5z7pp605i.s[52]++;

        $scope.validateItem.TYPE = data.find(function (status) {
          cov_5z7pp605i.f[11]++;
          cov_5z7pp605i.s[53]++;

          return status.id == $scope.validateItem.TYPE.id;
        });
      } else {
        cov_5z7pp605i.b[10][1]++;
      }
    });
  });

  cov_5z7pp605i.s[54]++;
  $scope.$on('delete_custom_fields', function (evt, params) {
    cov_5z7pp605i.f[12]++;
    cov_5z7pp605i.s[55]++;

    if (!(params.type == 3)) {
      cov_5z7pp605i.b[11][0]++;
      cov_5z7pp605i.s[56]++;

      return;
    } else {
      cov_5z7pp605i.b[11][1]++;
    }
    cov_5z7pp605i.s[57]++;
    $scope.allCustomsField();
  });

  cov_5z7pp605i.s[58]++;
  $scope.allCustomsField = function () {
    cov_5z7pp605i.f[13]++;
    cov_5z7pp605i.s[59]++;

    $CRUDService.getAll(PATH_CUSTOM_FIELDS, { get: 'single', type: 3 }, function (data_) {
      cov_5z7pp605i.f[14]++;

      var data = (cov_5z7pp605i.s[60]++, data_.VALUES_FIELD);
      cov_5z7pp605i.s[61]++;
      if (!angular.isObject(data)) {
        cov_5z7pp605i.b[12][0]++;
        cov_5z7pp605i.s[62]++;

        data = [{ id: 1, name: $translate.instant('FINANCING.TYPES.GRANT') }, { id: 2, name: $translate.instant('FINANCING.TYPES.LOAN') }, { id: 3, name: $translate.instant('FINANCING.TYPES.DONATION') }, { id: 4, name: $translate.instant('FINANCING.TYPES.BUDGET_SUPPORT') }];
      } else {
        cov_5z7pp605i.b[12][1]++;
      }
      cov_5z7pp605i.s[63]++;
      $scope.validateItem.TYPE = data.find(function (status) {
        cov_5z7pp605i.f[15]++;
        cov_5z7pp605i.s[64]++;

        return status.id == $scope.validateItem.TYPE;
      });
      cov_5z7pp605i.s[65]++;
      $scope.typeFinancing = data;
    });
  };
}

cov_5z7pp605i.s[66]++;
angular.module('app').controller('FinancingCtrl', function ($scope, SweetAlert, $deltadate, $deltahttp, $translate, $CRUDService, $otherHttpService, $location, $filter, $rootScope, $uibModal) {
  cov_5z7pp605i.f[16]++;
  cov_5z7pp605i.s[67]++;

  $rootScope.processPageRight('1_12');
  cov_5z7pp605i.s[68]++;
  if ($rootScope.global_access_page_denied) {
    cov_5z7pp605i.b[13][0]++;
    cov_5z7pp605i.s[69]++;

    return;
  } else {
    cov_5z7pp605i.b[13][1]++;
  }
  var PATH_CUSTOM_FIELDS = (cov_5z7pp605i.s[70]++, 'CustomFields');
  cov_5z7pp605i.s[71]++;
  $scope.add = false;
  cov_5z7pp605i.s[72]++;
  $scope.edit = false;
  cov_5z7pp605i.s[73]++;
  $scope.btnAction = false;
  cov_5z7pp605i.s[74]++;
  $scope.MyDataListe = [];
  cov_5z7pp605i.s[75]++;
  $scope.PATH = 'Agreements';
  cov_5z7pp605i.s[76]++;
  $scope.lastUpdate = angular.toJson(new Date());
  var project = (cov_5z7pp605i.s[77]++, $rootScope.getCurrentProject());
  var notify = (cov_5z7pp605i.s[78]++, '');
  cov_5z7pp605i.s[79]++;
  $scope.__labels = {
    EDIT: $translate.instant('COMMON.EDIT'),
    DELETE: $translate.instant('COMMON.DELETE')
  };

  cov_5z7pp605i.s[80]++;
  $scope.printData = function () {
    cov_5z7pp605i.f[17]++;
    cov_5z7pp605i.s[81]++;

    $rootScope.printReport({
      link: $scope.PATH,
      params: { get: 'print' },
      title: $translate.instant('MENU_SHORT.FINANCING')
    });
  };

  cov_5z7pp605i.s[82]++;
  $scope.params = {
    toolbar: $rootScope.currentRight.u ? (cov_5z7pp605i.b[14][0]++, ['Add']) : (cov_5z7pp605i.b[14][1]++, undefined),
    editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, allowEditOnDblClick: false },
    columns: [{ field: 'AGREEMENT', headerText: $translate.instant('FINANCING.AGREEMENT'), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'SOURCEFIN.LIBELLE_FIN', headerText: $translate.instant('FINANCING.SOURCE'), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'AMOUNT', width: 150, headerText: $translate.instant('FN_SFIN.AMOUNT'), format: 'N',
      type: 'number', textAlign: 'Right', headerTextAlign: 'Left', clipMode: 'EllipsisWithTooltip' }, { field: 'CURRENCY', width: 150, headerText: $translate.instant('FN_SFIN.CURRENCY'), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'EXCHANGE', width: 150, headerText: $translate.instant('FN_SFIN.EXCHANGE_RATE'), format: 'N',
      type: 'number', textAlign: 'Right', headerTextAlign: 'Left', clipMode: 'EllipsisWithTooltip' }, { field: 'EXCHANGE_RATE', width: 150, headerText: $translate.instant('FN_SFIN.EQUIV', { CURRENCY: $scope.globalCurrency }), format: 'N2',
      type: 'number', textAlign: 'Right', headerTextAlign: 'Left', clipMode: 'Ellipsis' }, { headerText: '', width: 120,
      commands: [].concat($rootScope.currentRight.u ? (cov_5z7pp605i.b[15][0]++, [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } }]) : (cov_5z7pp605i.b[15][1]++, [])).concat($rootScope.currentRight.d ? (cov_5z7pp605i.b[16][0]++, [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }]) : (cov_5z7pp605i.b[16][1]++, []))
    }],
    actionBegin: function actionBegin(action) {
      cov_5z7pp605i.s[83]++;

      if (action.requestType === 'add') {
        cov_5z7pp605i.b[17][0]++;
        cov_5z7pp605i.s[84]++;

        action.cancel = true;
        cov_5z7pp605i.s[85]++;
        $scope.add();
      } else {
        cov_5z7pp605i.b[17][1]++;
      }
      cov_5z7pp605i.s[86]++;
      if (action.requestType === 'beginEdit') {
        cov_5z7pp605i.b[18][0]++;
        cov_5z7pp605i.s[87]++;

        action.cancel = true;
        cov_5z7pp605i.s[88]++;
        $scope.edit(action.rowData);
      } else {
        cov_5z7pp605i.b[18][1]++;
      }
      cov_5z7pp605i.s[89]++;
      if (action.requestType === 'delete') {
        cov_5z7pp605i.b[19][0]++;
        cov_5z7pp605i.s[90]++;

        action.cancel = true;
        cov_5z7pp605i.s[91]++;
        $scope.setDeleteData(action.data[0]);
      } else {
        cov_5z7pp605i.b[19][1]++;
      }
    }
  };

  cov_5z7pp605i.s[92]++;
  $scope.financements = [];

  cov_5z7pp605i.s[93]++;
  $scope.typeFinancing = [];

  cov_5z7pp605i.s[94]++;
  $CRUDService.getAll('Financement', { get: 'all' }, function (data) {
    cov_5z7pp605i.f[18]++;
    cov_5z7pp605i.s[95]++;

    $scope.financements = data;
  });

  cov_5z7pp605i.s[96]++;
  $scope.getAll = function () {
    cov_5z7pp605i.f[19]++;
    cov_5z7pp605i.s[97]++;

    $scope.isloading = true;
    cov_5z7pp605i.s[98]++;
    $CRUDService.getAll($scope.PATH, { get: 'all' }, function (data) {
      cov_5z7pp605i.f[20]++;
      cov_5z7pp605i.s[99]++;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var el = _step.value;
          cov_5z7pp605i.s[100]++;

          el.AMOUNT = parseFloat(el.AMOUNT);
          cov_5z7pp605i.s[101]++;
          el.APPROVE_DATE = Date.newDate(el.APPROVE_DATE);
          cov_5z7pp605i.s[102]++;
          el.CLOSE_DATE = Date.newDate(el.CLOSE_DATE);
          cov_5z7pp605i.s[103]++;
          el.EXCHANGE = parseFloat(el.EXCHANGE);

          cov_5z7pp605i.s[104]++;
          el.EXCHANGE_RATE = el.AMOUNT * el.EXCHANGE;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      cov_5z7pp605i.s[105]++;
      $scope.MyDataListe = data;
      cov_5z7pp605i.s[106]++;
      $scope.isloading = false;
      cov_5z7pp605i.s[107]++;
      $scope.lastUpdate = angular.toJson(new Date());
    });
  };
  cov_5z7pp605i.s[108]++;
  $scope.getAll();

  cov_5z7pp605i.s[109]++;
  $scope.setAdd = function () {
    cov_5z7pp605i.f[21]++;
    cov_5z7pp605i.s[110]++;

    $scope.add = true;
    cov_5z7pp605i.s[111]++;
    $scope.edit = false;
    cov_5z7pp605i.s[112]++;
    $scope.setEdit();
  };

  cov_5z7pp605i.s[113]++;
  $scope.add = function () {
    cov_5z7pp605i.f[22]++;
    cov_5z7pp605i.s[114]++;

    $scope.edit({
      AGREEMENT: '',
      IDSOURCEFIN: ((cov_5z7pp605i.b[20][0]++, $scope.financements[0]) || (cov_5z7pp605i.b[20][1]++, { id: 0 })).id,
      id: 0,
      AMOUNT: 0,
      CURRENCY: '',
      EXCHANGE: 0,
      APPROVE_DATE: Date.newDate(),
      CLOSE_DATE: Date.newDate()
    });
  };

  cov_5z7pp605i.s[115]++;
  $scope.edit = function (item) {
    cov_5z7pp605i.f[23]++;

    var validateItem = (cov_5z7pp605i.s[116]++, angular.copy(item));

    cov_5z7pp605i.s[117]++;
    validateItem.IDSOURCEFIN = $scope.financements.find(function (source) {
      cov_5z7pp605i.f[24]++;
      cov_5z7pp605i.s[118]++;

      return source.id === item.IDSOURCEFIN;
    });

    cov_5z7pp605i.s[119]++;
    $scope.validateItem = validateItem;

    cov_5z7pp605i.s[120]++;
    $uibModal.open({
      templateUrl: 'app/views/financing/edit.html',
      controller: Financing_edit,
      scope: $scope,
      size: 'md_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_5z7pp605i.s[121]++;
  $scope.setCancel = function () {
    cov_5z7pp605i.f[25]++;
    cov_5z7pp605i.s[122]++;

    $scope.add = false;
    cov_5z7pp605i.s[123]++;
    $scope.edit = false;
    cov_5z7pp605i.s[124]++;
    $scope.btnAction = false;
  };

  cov_5z7pp605i.s[125]++;
  $scope.selectUnit = function (SelectedData) {
    cov_5z7pp605i.f[26]++;
    cov_5z7pp605i.s[126]++;

    $scope.btnAction = true;
    cov_5z7pp605i.s[127]++;
    $scope.item = SelectedData;
  };

  cov_5z7pp605i.s[128]++;
  $scope.setDeleteData = function (item) {
    cov_5z7pp605i.f[27]++;
    cov_5z7pp605i.s[129]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_SFIN.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_5z7pp605i.f[28]++;
      cov_5z7pp605i.s[130]++;

      if (isconfirm) {
        cov_5z7pp605i.b[21][0]++;
        cov_5z7pp605i.s[131]++;

        return;
      } else {
        cov_5z7pp605i.b[21][1]++;
      }
      cov_5z7pp605i.s[132]++;
      $scope.isloading = true;
      var validateItem = (cov_5z7pp605i.s[133]++, angular.copy(item));
      cov_5z7pp605i.s[134]++;
      $CRUDService.delet($scope.PATH, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_5z7pp605i.f[29]++;
        cov_5z7pp605i.s[135]++;

        if (data < 0) {
          cov_5z7pp605i.b[22][0]++;
          cov_5z7pp605i.s[136]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_5z7pp605i.s[137]++;
          $scope.isloading = false;
          cov_5z7pp605i.s[138]++;
          return;
        } else {
          cov_5z7pp605i.b[22][1]++;
        }
        /* notify({
        message:$translate.instant("COMMON.MSG_DEL"),
        classes:'alert-info'
        }); */
        cov_5z7pp605i.s[139]++;
        $scope.setCancel();
        cov_5z7pp605i.s[140]++;
        $scope.getAll();
      });
    });
  };
  cov_5z7pp605i.s[141]++;
  $scope.notify = function (MSG) {
    cov_5z7pp605i.f[30]++;
  };
});
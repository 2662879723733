'use strict';

var cov_228x03pj37 = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/indicator_visualization/IndicatorVisualization.js',
      hash = 'ddfdd0fd96219a1a21ab984a7d7eaf9f1ac54915',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/indicator_visualization/IndicatorVisualization.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 143,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 2,
          column: 37
        }
      },
      '2': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 5,
          column: 3
        }
      },
      '3': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 4,
          column: 11
        }
      },
      '4': {
        start: {
          line: 7,
          column: 15
        },
        end: {
          line: 7,
          column: 28
        }
      },
      '5': {
        start: {
          line: 8,
          column: 19
        },
        end: {
          line: 8,
          column: 35
        }
      },
      '6': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 136,
          column: 4
        }
      },
      '7': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 19,
          column: 7
        }
      },
      '8': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 18,
          column: 28
        }
      },
      '9': {
        start: {
          line: 20,
          column: 6
        },
        end: {
          line: 20,
          column: 60
        }
      },
      '10': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 40,
          column: 7
        }
      },
      '11': {
        start: {
          line: 38,
          column: 8
        },
        end: {
          line: 38,
          column: 38
        }
      },
      '12': {
        start: {
          line: 39,
          column: 8
        },
        end: {
          line: 39,
          column: 31
        }
      },
      '13': {
        start: {
          line: 41,
          column: 6
        },
        end: {
          line: 41,
          column: 24
        }
      },
      '14': {
        start: {
          line: 44,
          column: 6
        },
        end: {
          line: 49,
          column: 7
        }
      },
      '15': {
        start: {
          line: 45,
          column: 8
        },
        end: {
          line: 47,
          column: 11
        }
      },
      '16': {
        start: {
          line: 48,
          column: 8
        },
        end: {
          line: 48,
          column: 42
        }
      },
      '17': {
        start: {
          line: 50,
          column: 6
        },
        end: {
          line: 50,
          column: 36
        }
      },
      '18': {
        start: {
          line: 51,
          column: 21
        },
        end: {
          line: 51,
          column: 207
        }
      },
      '19': {
        start: {
          line: 52,
          column: 6
        },
        end: {
          line: 52,
          column: 49
        }
      },
      '20': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 59,
          column: 9
        }
      },
      '21': {
        start: {
          line: 56,
          column: 8
        },
        end: {
          line: 56,
          column: 50
        }
      },
      '22': {
        start: {
          line: 57,
          column: 8
        },
        end: {
          line: 57,
          column: 54
        }
      },
      '23': {
        start: {
          line: 58,
          column: 8
        },
        end: {
          line: 58,
          column: 47
        }
      },
      '24': {
        start: {
          line: 62,
          column: 6
        },
        end: {
          line: 64,
          column: 7
        }
      },
      '25': {
        start: {
          line: 63,
          column: 8
        },
        end: {
          line: 63,
          column: 15
        }
      },
      '26': {
        start: {
          line: 66,
          column: 6
        },
        end: {
          line: 66,
          column: 36
        }
      },
      '27': {
        start: {
          line: 67,
          column: 21
        },
        end: {
          line: 67,
          column: 207
        }
      },
      '28': {
        start: {
          line: 68,
          column: 6
        },
        end: {
          line: 68,
          column: 49
        }
      },
      '29': {
        start: {
          line: 69,
          column: 21
        },
        end: {
          line: 74,
          column: 7
        }
      },
      '30': {
        start: {
          line: 75,
          column: 6
        },
        end: {
          line: 133,
          column: 9
        }
      },
      '31': {
        start: {
          line: 76,
          column: 22
        },
        end: {
          line: 76,
          column: 84
        }
      },
      '32': {
        start: {
          line: 77,
          column: 8
        },
        end: {
          line: 85,
          column: 9
        }
      },
      '33': {
        start: {
          line: 78,
          column: 25
        },
        end: {
          line: 78,
          column: 46
        }
      },
      '34': {
        start: {
          line: 79,
          column: 23
        },
        end: {
          line: 79,
          column: 25
        }
      },
      '35': {
        start: {
          line: 80,
          column: 10
        },
        end: {
          line: 83,
          column: 11
        }
      },
      '36': {
        start: {
          line: 81,
          column: 22
        },
        end: {
          line: 81,
          column: 53
        }
      },
      '37': {
        start: {
          line: 82,
          column: 12
        },
        end: {
          line: 82,
          column: 54
        }
      },
      '38': {
        start: {
          line: 84,
          column: 10
        },
        end: {
          line: 84,
          column: 43
        }
      },
      '39': {
        start: {
          line: 86,
          column: 8
        },
        end: {
          line: 88,
          column: 9
        }
      },
      '40': {
        start: {
          line: 87,
          column: 10
        },
        end: {
          line: 87,
          column: 38
        }
      },
      '41': {
        start: {
          line: 89,
          column: 8
        },
        end: {
          line: 91,
          column: 9
        }
      },
      '42': {
        start: {
          line: 90,
          column: 10
        },
        end: {
          line: 90,
          column: 43
        }
      },
      '43': {
        start: {
          line: 92,
          column: 8
        },
        end: {
          line: 132,
          column: 9
        }
      },
      '44': {
        start: {
          line: 93,
          column: 21
        },
        end: {
          line: 93,
          column: 25
        }
      },
      '45': {
        start: {
          line: 94,
          column: 10
        },
        end: {
          line: 98,
          column: 11
        }
      },
      '46': {
        start: {
          line: 95,
          column: 12
        },
        end: {
          line: 95,
          column: 73
        }
      },
      '47': {
        start: {
          line: 97,
          column: 12
        },
        end: {
          line: 97,
          column: 21
        }
      },
      '48': {
        start: {
          line: 99,
          column: 21
        },
        end: {
          line: 99,
          column: 29
        }
      },
      '49': {
        start: {
          line: 100,
          column: 10
        },
        end: {
          line: 105,
          column: 11
        }
      },
      '50': {
        start: {
          line: 101,
          column: 12
        },
        end: {
          line: 104,
          column: 13
        }
      },
      '51': {
        start: {
          line: 102,
          column: 14
        },
        end: {
          line: 102,
          column: 23
        }
      },
      '52': {
        start: {
          line: 103,
          column: 14
        },
        end: {
          line: 103,
          column: 20
        }
      },
      '53': {
        start: {
          line: 106,
          column: 31
        },
        end: {
          line: 106,
          column: 100
        }
      },
      '54': {
        start: {
          line: 138,
          column: 2
        },
        end: {
          line: 138,
          column: 39
        }
      },
      '55': {
        start: {
          line: 139,
          column: 2
        },
        end: {
          line: 142,
          column: 5
        }
      },
      '56': {
        start: {
          line: 140,
          column: 4
        },
        end: {
          line: 140,
          column: 72
        }
      },
      '57': {
        start: {
          line: 141,
          column: 4
        },
        end: {
          line: 141,
          column: 43
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 63
          },
          end: {
            line: 1,
            column: 64
          }
        },
        loc: {
          start: {
            line: 1,
            column: 188
          },
          end: {
            line: 143,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 55,
            column: 59
          },
          end: {
            line: 55,
            column: 60
          }
        },
        loc: {
          start: {
            line: 55,
            column: 67
          },
          end: {
            line: 59,
            column: 7
          }
        },
        line: 55
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 75,
            column: 44
          },
          end: {
            line: 75,
            column: 45
          }
        },
        loc: {
          start: {
            line: 75,
            column: 52
          },
          end: {
            line: 133,
            column: 7
          }
        },
        line: 75
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 139,
            column: 39
          },
          end: {
            line: 139,
            column: 40
          }
        },
        loc: {
          start: {
            line: 139,
            column: 45
          },
          end: {
            line: 142,
            column: 3
          }
        },
        line: 139
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        }, {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        }],
        line: 3
      },
      '1': {
        loc: {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }, {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }],
        line: 17
      },
      '2': {
        loc: {
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        }, {
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 40,
            column: 7
          }
        }],
        line: 37
      },
      '3': {
        loc: {
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 49,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 49,
            column: 7
          }
        }, {
          start: {
            line: 44,
            column: 6
          },
          end: {
            line: 49,
            column: 7
          }
        }],
        line: 44
      },
      '4': {
        loc: {
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 64,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 64,
            column: 7
          }
        }, {
          start: {
            line: 62,
            column: 6
          },
          end: {
            line: 64,
            column: 7
          }
        }],
        line: 62
      },
      '5': {
        loc: {
          start: {
            line: 62,
            column: 10
          },
          end: {
            line: 62,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 62,
            column: 10
          },
          end: {
            line: 62,
            column: 31
          }
        }, {
          start: {
            line: 62,
            column: 35
          },
          end: {
            line: 62,
            column: 52
          }
        }],
        line: 62
      },
      '6': {
        loc: {
          start: {
            line: 82,
            column: 23
          },
          end: {
            line: 82,
            column: 47
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 82,
            column: 39
          },
          end: {
            line: 82,
            column: 42
          }
        }, {
          start: {
            line: 82,
            column: 45
          },
          end: {
            line: 82,
            column: 47
          }
        }],
        line: 82
      },
      '7': {
        loc: {
          start: {
            line: 86,
            column: 8
          },
          end: {
            line: 88,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 86,
            column: 8
          },
          end: {
            line: 88,
            column: 9
          }
        }, {
          start: {
            line: 86,
            column: 8
          },
          end: {
            line: 88,
            column: 9
          }
        }],
        line: 86
      },
      '8': {
        loc: {
          start: {
            line: 89,
            column: 8
          },
          end: {
            line: 91,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 89,
            column: 8
          },
          end: {
            line: 91,
            column: 9
          }
        }, {
          start: {
            line: 89,
            column: 8
          },
          end: {
            line: 91,
            column: 9
          }
        }],
        line: 89
      },
      '9': {
        loc: {
          start: {
            line: 101,
            column: 12
          },
          end: {
            line: 104,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 101,
            column: 12
          },
          end: {
            line: 104,
            column: 13
          }
        }, {
          start: {
            line: 101,
            column: 12
          },
          end: {
            line: 104,
            column: 13
          }
        }],
        line: 101
      },
      '10': {
        loc: {
          start: {
            line: 101,
            column: 16
          },
          end: {
            line: 101,
            column: 45
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 101,
            column: 16
          },
          end: {
            line: 101,
            column: 29
          }
        }, {
          start: {
            line: 101,
            column: 33
          },
          end: {
            line: 101,
            column: 45
          }
        }],
        line: 101
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_228x03pj37.s[0]++;
angular.module('app').controller('indicatorVisualizationCtrl', function ($scope, $CRUDService, $deltaGeoLevel, $rootScope, $http, $translate, $log, $deltadate, $filter, FileUploader, $uibModal) {
  cov_228x03pj37.f[0]++;
  cov_228x03pj37.s[1]++;

  $rootScope.processPageRight('3_5');
  cov_228x03pj37.s[2]++;
  if ($rootScope.global_access_page_denied) {
    cov_228x03pj37.b[0][0]++;
    cov_228x03pj37.s[3]++;

    return;
  } else {
    cov_228x03pj37.b[0][1]++;
  }

  var PATH = (cov_228x03pj37.s[4]++, 'Indicateurs');
  var PATH_DEC = (cov_228x03pj37.s[5]++, 'DecoupageIndic');

  cov_228x03pj37.s[6]++;
  $scope.mapDashboard = {
    listIndicators: [],
    selectIndicator: null,
    selectLevel: null,
    requestPanel: false,
    mymap: null,
    initializeMap: function initializeMap() {
      cov_228x03pj37.s[7]++;

      if (this.mymap) {
        cov_228x03pj37.b[1][0]++;
        cov_228x03pj37.s[8]++;

        this.mymap.remove();
      } else {
        cov_228x03pj37.b[1][1]++;
      }
      cov_228x03pj37.s[9]++;
      this.mymap = L.map('mapid').setView([16.82, 11.3], 5);
      /* const localitySearch = $rootScope.getCurrentProject().project.PAYS.name;
       $http({
         method: 'GET',
         url: 'https://nominatim.openstreetmap.org/search?q=' + localitySearch + '&format=json'
       }).then(response => {
         let data = $filter('filter')(response.data, {class: 'boundary'}, true);
         if (data.length == 0) {
           data = $filter('filter')(response.data, {class: 'place'}, true);
         }
         if (data.length == 0) {
           return;
         }
         this.mymap.setView([parseFloat(data[0].lat), parseFloat(data[0].lon)], 6);
       }, () => {
        }); */
      cov_228x03pj37.s[10]++;
      if (this.layerGroup) {
        cov_228x03pj37.b[2][0]++;
        cov_228x03pj37.s[11]++;

        this.layerGroup.clearLayers();
        cov_228x03pj37.s[12]++;
        this.layerGroup = null;
      } else {
        cov_228x03pj37.b[2][1]++;
      }
      cov_228x03pj37.s[13]++;
      this._getLayers();
    },
    _getLayers: function _getLayers() {
      cov_228x03pj37.s[14]++;

      if (!this.layerGroup) {
        cov_228x03pj37.b[3][0]++;
        cov_228x03pj37.s[15]++;

        this.layerGroup = L.layerGroup([], {
          attribution: '© <a href=\'https://www.mapbox.com/about/maps/\'>Mapbox</a> , © <a href=\'https://www.openstreetmap.org/copyright\'>OpenStreetMap</a>  <strong><a href=\'https://www.mapbox.com/map-feedback/\' target=\'_blank\'>Improve this map</a></strong>'
        });
        cov_228x03pj37.s[16]++;
        this.layerGroup.addTo(this.mymap);
      } else {
        cov_228x03pj37.b[3][1]++;
      }
      cov_228x03pj37.s[17]++;
      this.layerGroup.clearLayers();
      var mapstr = (cov_228x03pj37.s[18]++, 'https://api.mapbox.com/styles/v1/bksb/cjl6cbt060nb92rlvec7i4fh9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmtzYiIsImEiOiJjaXQ5ZHlpM2gwMDJvMnNwaDAxZXFsbndrIn0.0W3tcj-HtlRSCJDcuxNRew');
      cov_228x03pj37.s[19]++;
      L.tileLayer(mapstr).addTo(this.layerGroup);
    },
    getInformation: function getInformation() {
      cov_228x03pj37.s[20]++;

      $CRUDService.getAll(PATH, { get: 'all_indic_search' }, function (data) {
        cov_228x03pj37.f[1]++;
        cov_228x03pj37.s[21]++;

        $scope.mapDashboard.listIndicators = data;
        cov_228x03pj37.s[22]++;
        $scope.mapDashboard.selectIndicator = data[0];
        cov_228x03pj37.s[23]++;
        $scope.mapDashboard.processIndicator();
      });
    },
    processIndicator: function processIndicator() {
      cov_228x03pj37.s[24]++;

      if ((cov_228x03pj37.b[5][0]++, !this.selectIndicator) || (cov_228x03pj37.b[5][1]++, !this.selectLevel)) {
        cov_228x03pj37.b[4][0]++;
        cov_228x03pj37.s[25]++;

        return;
      } else {
        cov_228x03pj37.b[4][1]++;
      }

      cov_228x03pj37.s[26]++;
      this.layerGroup.clearLayers();
      var mapstr = (cov_228x03pj37.s[27]++, 'https://api.mapbox.com/styles/v1/bksb/cjl6cbt060nb92rlvec7i4fh9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmtzYiIsImEiOiJjaXQ5ZHlpM2gwMDJvMnNwaDAxZXFsbndrIn0.0W3tcj-HtlRSCJDcuxNRew');
      cov_228x03pj37.s[28]++;
      L.tileLayer(mapstr).addTo(this.layerGroup);
      var params = (cov_228x03pj37.s[29]++, {
        CODE_NG: this.selectLevel.id,
        ID_INDIC: this.selectIndicator.id,
        get: 'suivi_n_g',
        valid: 0
      });
      cov_228x03pj37.s[30]++;
      $CRUDService.getAll(PATH_DEC, params, function (data) {
        cov_228x03pj37.f[2]++;

        var flags = (cov_228x03pj37.s[31]++, angular.copy($rootScope.getCurrentProject().project.getFlag()));
        cov_228x03pj37.s[32]++;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = flags[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var flag = _step.value;

            var colors = (cov_228x03pj37.s[33]++, flag.color.split(','));
            var temp = (cov_228x03pj37.s[34]++, []);
            cov_228x03pj37.s[35]++;
            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
              for (var _iterator3 = colors[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                var part = _step3.value;

                var l = (cov_228x03pj37.s[36]++, parseInt(part, 10).toString(16));
                cov_228x03pj37.s[37]++;
                temp.push((l.length == 1 ? (cov_228x03pj37.b[6][0]++, '0') : (cov_228x03pj37.b[6][1]++, '')) + l);
              }
            } catch (err) {
              _didIteratorError3 = true;
              _iteratorError3 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion3 && _iterator3.return) {
                  _iterator3.return();
                }
              } finally {
                if (_didIteratorError3) {
                  throw _iteratorError3;
                }
              }
            }

            cov_228x03pj37.s[38]++;
            flag.color = '#' + temp.join('');
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        cov_228x03pj37.s[39]++;
        if (flags[0]) {
          cov_228x03pj37.b[7][0]++;
          cov_228x03pj37.s[40]++;

          flags[0].max = 999999999999;
        } else {
          cov_228x03pj37.b[7][1]++;
        }
        cov_228x03pj37.s[41]++;
        if (flags.length > 0) {
          cov_228x03pj37.b[8][0]++;
          cov_228x03pj37.s[42]++;

          flags[flags.length - 1].min = -1;
        } else {
          cov_228x03pj37.b[8][1]++;
        }
        cov_228x03pj37.s[43]++;
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = data[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var item = _step2.value;

            var rate = (cov_228x03pj37.s[44]++, null);
            cov_228x03pj37.s[45]++;
            try {
              cov_228x03pj37.s[46]++;

              rate = eval('100 * (' + item.vr + ') / ( ' + item.vp + ' )');
            } catch (e) {
              cov_228x03pj37.s[47]++;

              continue;
            }
            var _flag = (cov_228x03pj37.s[48]++, flags[0]);
            cov_228x03pj37.s[49]++;
            var _iteratorNormalCompletion4 = true;
            var _didIteratorError4 = false;
            var _iteratorError4 = undefined;

            try {
              for (var _iterator4 = flags[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                var f = _step4.value;
                cov_228x03pj37.s[50]++;

                if ((cov_228x03pj37.b[10][0]++, f.min <= rate) && (cov_228x03pj37.b[10][1]++, f.max > rate)) {
                  cov_228x03pj37.b[9][0]++;
                  cov_228x03pj37.s[51]++;

                  _flag = f;
                  cov_228x03pj37.s[52]++;
                  break;
                } else {
                  cov_228x03pj37.b[9][1]++;
                }
              }
            } catch (err) {
              _didIteratorError4 = true;
              _iteratorError4 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion4 && _iterator4.return) {
                  _iterator4.return();
                }
              } finally {
                if (_didIteratorError4) {
                  throw _iteratorError4;
                }
              }
            }

            var display_html = (cov_228x03pj37.s[53]++, '<h3>' + item.l + '</h3><h5>' + $filter('number')(rate, 2) + '%</h5>');
            /* $http({
              method: 'GET',
              url: 'https://nominatim.openstreetmap.org/reverse?format=json&polygon_geojson=1&osm_id=' + item.osm_id + '&osm_type=' + item.osm_type
            }).then(response => {
              L.geoJSON({
                type: 'Feature',
                properties: {label: display_html},
                geometry: response.data.geojson
              }, {
                pointToLayer(point, latlng) {
                  return L.circle(latlng, {radius: 15000});
                },
                style() {
                  return {color: flag.color};
                }
              }).bindPopup(layer => {
                return layer.feature.properties.label;
              }).bindTooltip(layer => {
                return layer.feature.properties.label;
              }).addTo(this.layerGroup);
            }, () => {
              if (!(item.x != 0 && item.y != 0)) {
                L.circle([item.x, item.y], {radius: 15000}).addTo(this.layerGroup);
              }
            }); */
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      });
    }
  };

  cov_228x03pj37.s[54]++;
  $scope.mapDashboard.getInformation();
  cov_228x03pj37.s[55]++;
  $deltaGeoLevel.addController($scope, function () {
    cov_228x03pj37.f[3]++;
    cov_228x03pj37.s[56]++;

    $scope.mapDashboard.selectLevel = $scope.listData_GeoLevels_bksb[0];
    cov_228x03pj37.s[57]++;
    $scope.mapDashboard.processIndicator();
  });
});
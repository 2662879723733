'use strict';

var cov_2j7vy5vcng = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/user/custom_right/customRightCtrl.js',
      hash = '6766fe9f0c1b05fcd0bc751bebbc58f1e6607776',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/user/custom_right/customRightCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3,
          column: 3
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 52
          },
          end: {
            line: 1,
            column: 53
          }
        },
        loc: {
          start: {
            line: 1,
            column: 167
          },
          end: {
            line: 3,
            column: 1
          }
        },
        line: 1
      }
    },
    branchMap: {},
    s: {
      '0': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2j7vy5vcng.s[0]++;
angular.module('app').controller('customRightCtrl', function ($scope, $rootScope, SweetAlert, $translate, $deltaActor, $CRUDService, $otherHttpService, $deltahttp, $filter) {
  cov_2j7vy5vcng.f[0]++;
});
'use strict';

var cov_17zd38sbbq = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/directives/tableTreeSyncfusion.js',
      hash = '9f79e0e1bc820578af04ad4069212ac8fac94bb4',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/directives/tableTreeSyncfusion.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 172,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 171,
          column: 4
        }
      },
      '2': {
        start: {
          line: 33,
          column: 18
        },
        end: {
          line: 33,
          column: 22
        }
      },
      '3': {
        start: {
          line: 35,
          column: 21
        },
        end: {
          line: 138,
          column: 7
        }
      },
      '4': {
        start: {
          line: 53,
          column: 10
        },
        end: {
          line: 55,
          column: 11
        }
      },
      '5': {
        start: {
          line: 54,
          column: 12
        },
        end: {
          line: 54,
          column: 19
        }
      },
      '6': {
        start: {
          line: 56,
          column: 10
        },
        end: {
          line: 56,
          column: 38
        }
      },
      '7': {
        start: {
          line: 60,
          column: 10
        },
        end: {
          line: 62,
          column: 11
        }
      },
      '8': {
        start: {
          line: 61,
          column: 12
        },
        end: {
          line: 61,
          column: 19
        }
      },
      '9': {
        start: {
          line: 63,
          column: 10
        },
        end: {
          line: 63,
          column: 38
        }
      },
      '10': {
        start: {
          line: 66,
          column: 10
        },
        end: {
          line: 68,
          column: 11
        }
      },
      '11': {
        start: {
          line: 67,
          column: 12
        },
        end: {
          line: 67,
          column: 19
        }
      },
      '12': {
        start: {
          line: 69,
          column: 10
        },
        end: {
          line: 69,
          column: 43
        }
      },
      '13': {
        start: {
          line: 73,
          column: 10
        },
        end: {
          line: 75,
          column: 11
        }
      },
      '14': {
        start: {
          line: 74,
          column: 12
        },
        end: {
          line: 74,
          column: 19
        }
      },
      '15': {
        start: {
          line: 76,
          column: 10
        },
        end: {
          line: 76,
          column: 41
        }
      },
      '16': {
        start: {
          line: 79,
          column: 10
        },
        end: {
          line: 81,
          column: 11
        }
      },
      '17': {
        start: {
          line: 80,
          column: 12
        },
        end: {
          line: 80,
          column: 19
        }
      },
      '18': {
        start: {
          line: 82,
          column: 10
        },
        end: {
          line: 82,
          column: 41
        }
      },
      '19': {
        start: {
          line: 86,
          column: 10
        },
        end: {
          line: 86,
          column: 37
        }
      },
      '20': {
        start: {
          line: 89,
          column: 10
        },
        end: {
          line: 89,
          column: 39
        }
      },
      '21': {
        start: {
          line: 92,
          column: 10
        },
        end: {
          line: 104,
          column: 11
        }
      },
      '22': {
        start: {
          line: 93,
          column: 31
        },
        end: {
          line: 93,
          column: 33
        }
      },
      '23': {
        start: {
          line: 94,
          column: 12
        },
        end: {
          line: 96,
          column: 13
        }
      },
      '24': {
        start: {
          line: 95,
          column: 14
        },
        end: {
          line: 95,
          column: 69
        }
      },
      '25': {
        start: {
          line: 97,
          column: 12
        },
        end: {
          line: 97,
          column: 34
        }
      },
      '26': {
        start: {
          line: 98,
          column: 12
        },
        end: {
          line: 102,
          column: 15
        }
      },
      '27': {
        start: {
          line: 99,
          column: 14
        },
        end: {
          line: 101,
          column: 15
        }
      },
      '28': {
        start: {
          line: 100,
          column: 16
        },
        end: {
          line: 100,
          column: 39
        }
      },
      '29': {
        start: {
          line: 103,
          column: 12
        },
        end: {
          line: 103,
          column: 63
        }
      },
      '30': {
        start: {
          line: 105,
          column: 10
        },
        end: {
          line: 107,
          column: 11
        }
      },
      '31': {
        start: {
          line: 106,
          column: 12
        },
        end: {
          line: 106,
          column: 19
        }
      },
      '32': {
        start: {
          line: 108,
          column: 10
        },
        end: {
          line: 108,
          column: 35
        }
      },
      '33': {
        start: {
          line: 109,
          column: 10
        },
        end: {
          line: 109,
          column: 35
        }
      },
      '34': {
        start: {
          line: 112,
          column: 10
        },
        end: {
          line: 114,
          column: 11
        }
      },
      '35': {
        start: {
          line: 113,
          column: 12
        },
        end: {
          line: 113,
          column: 19
        }
      },
      '36': {
        start: {
          line: 115,
          column: 10
        },
        end: {
          line: 115,
          column: 32
        }
      },
      '37': {
        start: {
          line: 119,
          column: 10
        },
        end: {
          line: 121,
          column: 11
        }
      },
      '38': {
        start: {
          line: 120,
          column: 12
        },
        end: {
          line: 120,
          column: 19
        }
      },
      '39': {
        start: {
          line: 122,
          column: 10
        },
        end: {
          line: 122,
          column: 39
        }
      },
      '40': {
        start: {
          line: 125,
          column: 10
        },
        end: {
          line: 127,
          column: 11
        }
      },
      '41': {
        start: {
          line: 126,
          column: 12
        },
        end: {
          line: 126,
          column: 19
        }
      },
      '42': {
        start: {
          line: 128,
          column: 26
        },
        end: {
          line: 128,
          column: 39
        }
      },
      '43': {
        start: {
          line: 129,
          column: 10
        },
        end: {
          line: 129,
          column: 36
        }
      },
      '44': {
        start: {
          line: 130,
          column: 10
        },
        end: {
          line: 130,
          column: 39
        }
      },
      '45': {
        start: {
          line: 133,
          column: 10
        },
        end: {
          line: 135,
          column: 11
        }
      },
      '46': {
        start: {
          line: 134,
          column: 12
        },
        end: {
          line: 134,
          column: 19
        }
      },
      '47': {
        start: {
          line: 136,
          column: 10
        },
        end: {
          line: 136,
          column: 60
        }
      },
      '48': {
        start: {
          line: 140,
          column: 21
        },
        end: {
          line: 143,
          column: 7
        }
      },
      '49': {
        start: {
          line: 141,
          column: 8
        },
        end: {
          line: 141,
          column: 49
        }
      },
      '50': {
        start: {
          line: 142,
          column: 8
        },
        end: {
          line: 142,
          column: 35
        }
      },
      '51': {
        start: {
          line: 144,
          column: 6
        },
        end: {
          line: 148,
          column: 9
        }
      },
      '52': {
        start: {
          line: 145,
          column: 8
        },
        end: {
          line: 145,
          column: 38
        }
      },
      '53': {
        start: {
          line: 146,
          column: 8
        },
        end: {
          line: 146,
          column: 32
        }
      },
      '54': {
        start: {
          line: 147,
          column: 8
        },
        end: {
          line: 147,
          column: 45
        }
      },
      '55': {
        start: {
          line: 150,
          column: 6
        },
        end: {
          line: 155,
          column: 9
        }
      },
      '56': {
        start: {
          line: 151,
          column: 8
        },
        end: {
          line: 151,
          column: 37
        }
      },
      '57': {
        start: {
          line: 153,
          column: 8
        },
        end: {
          line: 153,
          column: 42
        }
      },
      '58': {
        start: {
          line: 154,
          column: 8
        },
        end: {
          line: 154,
          column: 24
        }
      },
      '59': {
        start: {
          line: 163,
          column: 6
        },
        end: {
          line: 168,
          column: 9
        }
      },
      '60': {
        start: {
          line: 164,
          column: 8
        },
        end: {
          line: 164,
          column: 29
        }
      },
      '61': {
        start: {
          line: 166,
          column: 8
        },
        end: {
          line: 166,
          column: 33
        }
      },
      '62': {
        start: {
          line: 167,
          column: 8
        },
        end: {
          line: 167,
          column: 24
        }
      },
      '63': {
        start: {
          line: 169,
          column: 6
        },
        end: {
          line: 169,
          column: 15
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 55
          },
          end: {
            line: 1,
            column: 56
          }
        },
        loc: {
          start: {
            line: 1,
            column: 61
          },
          end: {
            line: 172,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 98,
            column: 46
          },
          end: {
            line: 98,
            column: 47
          }
        },
        loc: {
          start: {
            line: 98,
            column: 62
          },
          end: {
            line: 102,
            column: 13
          }
        },
        line: 98
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 140,
            column: 21
          },
          end: {
            line: 140,
            column: 22
          }
        },
        loc: {
          start: {
            line: 140,
            column: 33
          },
          end: {
            line: 143,
            column: 7
          }
        },
        line: 140
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 144,
            column: 28
          },
          end: {
            line: 144,
            column: 29
          }
        },
        loc: {
          start: {
            line: 144,
            column: 34
          },
          end: {
            line: 148,
            column: 7
          }
        },
        line: 144
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 150,
            column: 19
          },
          end: {
            line: 150,
            column: 20
          }
        },
        loc: {
          start: {
            line: 150,
            column: 28
          },
          end: {
            line: 152,
            column: 7
          }
        },
        line: 150
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 152,
            column: 9
          },
          end: {
            line: 152,
            column: 10
          }
        },
        loc: {
          start: {
            line: 152,
            column: 21
          },
          end: {
            line: 155,
            column: 7
          }
        },
        line: 152
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 163,
            column: 19
          },
          end: {
            line: 163,
            column: 20
          }
        },
        loc: {
          start: {
            line: 163,
            column: 28
          },
          end: {
            line: 165,
            column: 7
          }
        },
        line: 163
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 165,
            column: 9
          },
          end: {
            line: 165,
            column: 10
          }
        },
        loc: {
          start: {
            line: 165,
            column: 21
          },
          end: {
            line: 168,
            column: 7
          }
        },
        line: 165
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 39,
            column: 20
          },
          end: {
            line: 39,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 39,
            column: 20
          },
          end: {
            line: 39,
            column: 41
          }
        }, {
          start: {
            line: 39,
            column: 45
          },
          end: {
            line: 39,
            column: 47
          }
        }],
        line: 39
      },
      '1': {
        loc: {
          start: {
            line: 40,
            column: 22
          },
          end: {
            line: 40,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 40,
            column: 22
          },
          end: {
            line: 40,
            column: 40
          }
        }, {
          start: {
            line: 40,
            column: 44
          },
          end: {
            line: 40,
            column: 49
          }
        }],
        line: 40
      },
      '2': {
        loc: {
          start: {
            line: 41,
            column: 21
          },
          end: {
            line: 41,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 41,
            column: 21
          },
          end: {
            line: 41,
            column: 38
          }
        }, {
          start: {
            line: 41,
            column: 42
          },
          end: {
            line: 41,
            column: 47
          }
        }],
        line: 41
      },
      '3': {
        loc: {
          start: {
            line: 42,
            column: 24
          },
          end: {
            line: 42,
            column: 53
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 42,
            column: 24
          },
          end: {
            line: 42,
            column: 44
          }
        }, {
          start: {
            line: 42,
            column: 48
          },
          end: {
            line: 42,
            column: 53
          }
        }],
        line: 42
      },
      '4': {
        loc: {
          start: {
            line: 43,
            column: 22
          },
          end: {
            line: 43,
            column: 53
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 43,
            column: 22
          },
          end: {
            line: 43,
            column: 40
          }
        }, {
          start: {
            line: 43,
            column: 44
          },
          end: {
            line: 43,
            column: 53
          }
        }],
        line: 43
      },
      '5': {
        loc: {
          start: {
            line: 44,
            column: 16
          },
          end: {
            line: 44,
            column: 38
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 44,
            column: 16
          },
          end: {
            line: 44,
            column: 28
          }
        }, {
          start: {
            line: 44,
            column: 32
          },
          end: {
            line: 44,
            column: 38
          }
        }],
        line: 44
      },
      '6': {
        loc: {
          start: {
            line: 45,
            column: 17
          },
          end: {
            line: 45,
            column: 43
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 45,
            column: 17
          },
          end: {
            line: 45,
            column: 30
          }
        }, {
          start: {
            line: 45,
            column: 34
          },
          end: {
            line: 45,
            column: 43
          }
        }],
        line: 45
      },
      '7': {
        loc: {
          start: {
            line: 46,
            column: 19
          },
          end: {
            line: 46,
            column: 47
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 46,
            column: 19
          },
          end: {
            line: 46,
            column: 34
          }
        }, {
          start: {
            line: 46,
            column: 38
          },
          end: {
            line: 46,
            column: 47
          }
        }],
        line: 46
      },
      '8': {
        loc: {
          start: {
            line: 47,
            column: 24
          },
          end: {
            line: 47,
            column: 62
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 47,
            column: 24
          },
          end: {
            line: 47,
            column: 44
          }
        }, {
          start: {
            line: 47,
            column: 48
          },
          end: {
            line: 47,
            column: 62
          }
        }],
        line: 47
      },
      '9': {
        loc: {
          start: {
            line: 48,
            column: 22
          },
          end: {
            line: 48,
            column: 58
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 48,
            column: 22
          },
          end: {
            line: 48,
            column: 40
          }
        }, {
          start: {
            line: 48,
            column: 44
          },
          end: {
            line: 48,
            column: 58
          }
        }],
        line: 48
      },
      '10': {
        loc: {
          start: {
            line: 49,
            column: 19
          },
          end: {
            line: 49,
            column: 40
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 49,
            column: 19
          },
          end: {
            line: 49,
            column: 34
          }
        }, {
          start: {
            line: 49,
            column: 38
          },
          end: {
            line: 49,
            column: 40
          }
        }],
        line: 49
      },
      '11': {
        loc: {
          start: {
            line: 50,
            column: 26
          },
          end: {
            line: 50,
            column: 54
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 50,
            column: 26
          },
          end: {
            line: 50,
            column: 48
          }
        }, {
          start: {
            line: 50,
            column: 52
          },
          end: {
            line: 50,
            column: 54
          }
        }],
        line: 50
      },
      '12': {
        loc: {
          start: {
            line: 51,
            column: 17
          },
          end: {
            line: 51,
            column: 36
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 51,
            column: 17
          },
          end: {
            line: 51,
            column: 30
          }
        }, {
          start: {
            line: 51,
            column: 34
          },
          end: {
            line: 51,
            column: 36
          }
        }],
        line: 51
      },
      '13': {
        loc: {
          start: {
            line: 53,
            column: 10
          },
          end: {
            line: 55,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 53,
            column: 10
          },
          end: {
            line: 55,
            column: 11
          }
        }, {
          start: {
            line: 53,
            column: 10
          },
          end: {
            line: 55,
            column: 11
          }
        }],
        line: 53
      },
      '14': {
        loc: {
          start: {
            line: 60,
            column: 10
          },
          end: {
            line: 62,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 60,
            column: 10
          },
          end: {
            line: 62,
            column: 11
          }
        }, {
          start: {
            line: 60,
            column: 10
          },
          end: {
            line: 62,
            column: 11
          }
        }],
        line: 60
      },
      '15': {
        loc: {
          start: {
            line: 66,
            column: 10
          },
          end: {
            line: 68,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 66,
            column: 10
          },
          end: {
            line: 68,
            column: 11
          }
        }, {
          start: {
            line: 66,
            column: 10
          },
          end: {
            line: 68,
            column: 11
          }
        }],
        line: 66
      },
      '16': {
        loc: {
          start: {
            line: 73,
            column: 10
          },
          end: {
            line: 75,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 73,
            column: 10
          },
          end: {
            line: 75,
            column: 11
          }
        }, {
          start: {
            line: 73,
            column: 10
          },
          end: {
            line: 75,
            column: 11
          }
        }],
        line: 73
      },
      '17': {
        loc: {
          start: {
            line: 79,
            column: 10
          },
          end: {
            line: 81,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 79,
            column: 10
          },
          end: {
            line: 81,
            column: 11
          }
        }, {
          start: {
            line: 79,
            column: 10
          },
          end: {
            line: 81,
            column: 11
          }
        }],
        line: 79
      },
      '18': {
        loc: {
          start: {
            line: 92,
            column: 10
          },
          end: {
            line: 104,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 92,
            column: 10
          },
          end: {
            line: 104,
            column: 11
          }
        }, {
          start: {
            line: 92,
            column: 10
          },
          end: {
            line: 104,
            column: 11
          }
        }],
        line: 92
      },
      '19': {
        loc: {
          start: {
            line: 94,
            column: 12
          },
          end: {
            line: 96,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 94,
            column: 12
          },
          end: {
            line: 96,
            column: 13
          }
        }, {
          start: {
            line: 94,
            column: 12
          },
          end: {
            line: 96,
            column: 13
          }
        }],
        line: 94
      },
      '20': {
        loc: {
          start: {
            line: 99,
            column: 14
          },
          end: {
            line: 101,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 99,
            column: 14
          },
          end: {
            line: 101,
            column: 15
          }
        }, {
          start: {
            line: 99,
            column: 14
          },
          end: {
            line: 101,
            column: 15
          }
        }],
        line: 99
      },
      '21': {
        loc: {
          start: {
            line: 105,
            column: 10
          },
          end: {
            line: 107,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 105,
            column: 10
          },
          end: {
            line: 107,
            column: 11
          }
        }, {
          start: {
            line: 105,
            column: 10
          },
          end: {
            line: 107,
            column: 11
          }
        }],
        line: 105
      },
      '22': {
        loc: {
          start: {
            line: 112,
            column: 10
          },
          end: {
            line: 114,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 112,
            column: 10
          },
          end: {
            line: 114,
            column: 11
          }
        }, {
          start: {
            line: 112,
            column: 10
          },
          end: {
            line: 114,
            column: 11
          }
        }],
        line: 112
      },
      '23': {
        loc: {
          start: {
            line: 119,
            column: 10
          },
          end: {
            line: 121,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 119,
            column: 10
          },
          end: {
            line: 121,
            column: 11
          }
        }, {
          start: {
            line: 119,
            column: 10
          },
          end: {
            line: 121,
            column: 11
          }
        }],
        line: 119
      },
      '24': {
        loc: {
          start: {
            line: 125,
            column: 10
          },
          end: {
            line: 127,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 125,
            column: 10
          },
          end: {
            line: 127,
            column: 11
          }
        }, {
          start: {
            line: 125,
            column: 10
          },
          end: {
            line: 127,
            column: 11
          }
        }],
        line: 125
      },
      '25': {
        loc: {
          start: {
            line: 133,
            column: 10
          },
          end: {
            line: 135,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 133,
            column: 10
          },
          end: {
            line: 135,
            column: 11
          }
        }, {
          start: {
            line: 133,
            column: 10
          },
          end: {
            line: 135,
            column: 11
          }
        }],
        line: 133
      },
      '26': {
        loc: {
          start: {
            line: 153,
            column: 27
          },
          end: {
            line: 153,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 153,
            column: 27
          },
          end: {
            line: 153,
            column: 35
          }
        }, {
          start: {
            line: 153,
            column: 39
          },
          end: {
            line: 153,
            column: 41
          }
        }],
        line: 153
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_17zd38sbbq.s[0]++;
angular.module('app').directive('tableTreeSyncfusion', function () {
  cov_17zd38sbbq.f[0]++;
  cov_17zd38sbbq.s[1]++;

  return {
    restrict: 'A',
    scope: {
      columns: '=',
      idMapping: '@?',
      parentIdMapping: '@?',
      treeColumnIndex: '=?',
      rowHeight: '=?',
      tableDataSource: '=',
      contextMenuItems: '=?',
      editSettings: '=?',
      allowSorting: '=?',
      height: '=?',
      allowPaging: '=?',
      allowFiltering: '=?',
      toolbar: '=?',
      pageSettings: '=?',
      gridLines: '=?',
      filterSettings: '=?',
      tableDataSelected: '=?',
      queryCellInfo: '&',
      excelQueryCellInfo: '&',
      commandClick: '&',
      recordClick: '&',
      actionBegin: '&',
      contextMenuClick: '&',
      rowSelected: '&',
      rowDeselected: '&',
      recordDoubleClick: '&'
    },
    link: function link(scope, element, attrs) {
      var table = (cov_17zd38sbbq.s[2]++, null);

      var params = (cov_17zd38sbbq.s[3]++, {
        treeColumnIndex: scope.treeColumnIndex,
        idMapping: scope.idMapping,
        parentIdMapping: scope.parentIdMapping,
        dataSource: (cov_17zd38sbbq.b[0][0]++, scope.tableDataSource) || (cov_17zd38sbbq.b[0][1]++, []),
        allowSorting: (cov_17zd38sbbq.b[1][0]++, scope.allowSorting) || (cov_17zd38sbbq.b[1][1]++, false),
        allowPaging: (cov_17zd38sbbq.b[2][0]++, scope.allowPaging) || (cov_17zd38sbbq.b[2][1]++, false),
        allowFiltering: (cov_17zd38sbbq.b[3][0]++, scope.allowFiltering) || (cov_17zd38sbbq.b[3][1]++, false),
        editSettings: (cov_17zd38sbbq.b[4][0]++, scope.editSettings) || (cov_17zd38sbbq.b[4][1]++, undefined),
        height: (cov_17zd38sbbq.b[5][0]++, scope.height) || (cov_17zd38sbbq.b[5][1]++, '100%'),
        toolbar: (cov_17zd38sbbq.b[6][0]++, scope.toolbar) || (cov_17zd38sbbq.b[6][1]++, undefined),
        gridLines: (cov_17zd38sbbq.b[7][0]++, scope.gridLines) || (cov_17zd38sbbq.b[7][1]++, undefined),
        filterSettings: (cov_17zd38sbbq.b[8][0]++, scope.filterSettings) || (cov_17zd38sbbq.b[8][1]++, { type: 'Menu' }),
        pageSettings: (cov_17zd38sbbq.b[9][0]++, scope.pageSettings) || (cov_17zd38sbbq.b[9][1]++, { pageSize: 15 }),
        rowHeight: (cov_17zd38sbbq.b[10][0]++, scope.rowHeight) || (cov_17zd38sbbq.b[10][1]++, 38),
        contextMenuItems: (cov_17zd38sbbq.b[11][0]++, scope.contextMenuItems) || (cov_17zd38sbbq.b[11][1]++, []),
        columns: (cov_17zd38sbbq.b[12][0]++, scope.columns) || (cov_17zd38sbbq.b[12][1]++, []),
        actionBegin: function actionBegin(action) {
          cov_17zd38sbbq.s[4]++;

          if (!scope.actionBegin) {
            cov_17zd38sbbq.b[13][0]++;
            cov_17zd38sbbq.s[5]++;

            return;
          } else {
            cov_17zd38sbbq.b[13][1]++;
          }
          cov_17zd38sbbq.s[6]++;
          scope.actionBegin({ action: action });
        },
        queryCellInfo: function queryCellInfo(args) {
          cov_17zd38sbbq.s[7]++;

          if (!scope.queryCellInfo) {
            cov_17zd38sbbq.b[14][0]++;
            cov_17zd38sbbq.s[8]++;

            return;
          } else {
            cov_17zd38sbbq.b[14][1]++;
          }
          cov_17zd38sbbq.s[9]++;
          scope.queryCellInfo({ args: args });
        },
        excelQueryCellInfo: function excelQueryCellInfo(args) {
          cov_17zd38sbbq.s[10]++;

          if (!scope.excelQueryCellInfo) {
            cov_17zd38sbbq.b[15][0]++;
            cov_17zd38sbbq.s[11]++;

            return;
          } else {
            cov_17zd38sbbq.b[15][1]++;
          }
          cov_17zd38sbbq.s[12]++;
          scope.excelQueryCellInfo({ args: args });
        },
        pdfQueryCellInfo: function pdfQueryCellInfo(args) {
          cov_17zd38sbbq.s[13]++;

          if (!scope.pdfQueryCellInfo) {
            cov_17zd38sbbq.b[16][0]++;
            cov_17zd38sbbq.s[14]++;

            return;
          } else {
            cov_17zd38sbbq.b[16][1]++;
          }
          cov_17zd38sbbq.s[15]++;
          scope.pdfQueryCellInfo({ args: args });
        },
        contextMenuClick: function contextMenuClick(item) {
          cov_17zd38sbbq.s[16]++;

          if (!scope.contextMenuClick) {
            cov_17zd38sbbq.b[17][0]++;
            cov_17zd38sbbq.s[17]++;

            return;
          } else {
            cov_17zd38sbbq.b[17][1]++;
          }
          cov_17zd38sbbq.s[18]++;
          scope.contextMenuClick({ item: item });
        },
        rowSelected: function rowSelected(event) {
          cov_17zd38sbbq.s[19]++;

          scope.rowSelected({ event: event });
        },
        rowDeselected: function rowDeselected(event) {
          cov_17zd38sbbq.s[20]++;

          scope.rowDeselected({ event: event });
        },
        dataBound: function dataBound(args) {
          cov_17zd38sbbq.s[21]++;

          if (scope.tableDataSelected) {
            cov_17zd38sbbq.b[18][0]++;

            var rowIndexes = (cov_17zd38sbbq.s[22]++, []);
            cov_17zd38sbbq.s[23]++;
            if (selecthistoric) {
              cov_17zd38sbbq.b[19][0]++;
              cov_17zd38sbbq.s[24]++;

              scope.tableDataSelected = angular.copy(selecthistoric);
            } else {
              cov_17zd38sbbq.b[19][1]++;
            }
            cov_17zd38sbbq.s[25]++;
            selecthistoric = null;
            cov_17zd38sbbq.s[26]++;
            scope.table.getDataRows().forEach(function (row, index) {
              cov_17zd38sbbq.f[1]++;
              cov_17zd38sbbq.s[27]++;

              if (scope.tableDataSelected[row.cells[0].textContent]) {
                cov_17zd38sbbq.b[20][0]++;
                cov_17zd38sbbq.s[28]++;

                rowIndexes.push(index);
              } else {
                cov_17zd38sbbq.b[20][1]++;
              }
            });
            cov_17zd38sbbq.s[29]++;
            scope.table.selectionModule.selectRows(rowIndexes);
          } else {
            cov_17zd38sbbq.b[18][1]++;
          }
          cov_17zd38sbbq.s[30]++;
          if (!scope.boundEvent) {
            cov_17zd38sbbq.b[21][0]++;
            cov_17zd38sbbq.s[31]++;

            return;
          } else {
            cov_17zd38sbbq.b[21][1]++;
          }
          cov_17zd38sbbq.s[32]++;
          args.table = scope.table;
          cov_17zd38sbbq.s[33]++;
          scope.boundEvent({ args: args });
        },
        created: function created(args) {
          cov_17zd38sbbq.s[34]++;

          if (!scope.created) {
            cov_17zd38sbbq.b[22][0]++;
            cov_17zd38sbbq.s[35]++;

            return;
          } else {
            cov_17zd38sbbq.b[22][1]++;
          }
          cov_17zd38sbbq.s[36]++;
          scope.created({ args: args });
        },
        commandClick: function commandClick(action) {
          cov_17zd38sbbq.s[37]++;

          // selecthistoric = angular.copy(scope.tableDataSelected);
          if (!scope.commandClick) {
            cov_17zd38sbbq.b[23][0]++;
            cov_17zd38sbbq.s[38]++;

            return;
          } else {
            cov_17zd38sbbq.b[23][1]++;
          }
          cov_17zd38sbbq.s[39]++;
          scope.commandClick({ action: action });
        },
        recordClick: function recordClick(event) {
          cov_17zd38sbbq.s[40]++;

          if (!scope.recordClick) {
            cov_17zd38sbbq.b[24][0]++;
            cov_17zd38sbbq.s[41]++;

            return;
          } else {
            cov_17zd38sbbq.b[24][1]++;
          }
          var rowData = (cov_17zd38sbbq.s[42]++, event.rowData);
          cov_17zd38sbbq.s[43]++;
          rowData.cell = event.cell;
          cov_17zd38sbbq.s[44]++;
          scope.recordClick({ rowData: rowData });
        },
        recordDoubleClick: function recordDoubleClick(event) {
          cov_17zd38sbbq.s[45]++;

          if (!scope.recordDoubleClick) {
            cov_17zd38sbbq.b[25][0]++;
            cov_17zd38sbbq.s[46]++;

            return;
          } else {
            cov_17zd38sbbq.b[25][1]++;
          }
          cov_17zd38sbbq.s[47]++;
          scope.recordDoubleClick({ rowData: event.rowData });
        }
      });

      cov_17zd38sbbq.s[48]++;
      var render = function render() {
        cov_17zd38sbbq.f[2]++;
        cov_17zd38sbbq.s[49]++;

        table = new ej.treegrid.TreeGrid(params);
        cov_17zd38sbbq.s[50]++;
        table.appendTo(element[0]);
      };
      cov_17zd38sbbq.s[51]++;
      scope.$on('$destroy', function () {
        cov_17zd38sbbq.f[3]++;
        cov_17zd38sbbq.s[52]++;

        $('.e-tooltip-wrap').remove();
        cov_17zd38sbbq.s[53]++;
        $('.e-flmenu').remove();
        cov_17zd38sbbq.s[54]++;
        $('.e-contextmenu-wrapper').remove();
      });

      cov_17zd38sbbq.s[55]++;
      scope.$watch(function (scope) {
        cov_17zd38sbbq.f[4]++;
        cov_17zd38sbbq.s[56]++;

        return scope.tableDataSource;
      }, function (newValue) {
        cov_17zd38sbbq.f[5]++;
        cov_17zd38sbbq.s[57]++;

        table.dataSource = (cov_17zd38sbbq.b[26][0]++, newValue) || (cov_17zd38sbbq.b[26][1]++, []);
        cov_17zd38sbbq.s[58]++;
        table.refresh();
      });
      /*
            scope.$watch(scope => {
              return scope.dataSelected;
            }, newValue => {
              table.refresh();
            }); */

      cov_17zd38sbbq.s[59]++;
      scope.$watch(function (scope) {
        cov_17zd38sbbq.f[6]++;
        cov_17zd38sbbq.s[60]++;

        return scope.columns;
      }, function (newValue) {
        cov_17zd38sbbq.f[7]++;
        cov_17zd38sbbq.s[61]++;

        table.columns = newValue;
        cov_17zd38sbbq.s[62]++;
        table.refresh();
      });
      cov_17zd38sbbq.s[63]++;
      render();
    }
  };
});
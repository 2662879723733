'use strict';

var cov_lq88blkgc = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/directives/deltaTooltip.js',
      hash = '30271af201c4fee71f7d2436d0f674af6913d56a',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/directives/deltaTooltip.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 16,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 15,
          column: 4
        }
      },
      '2': {
        start: {
          line: 9,
          column: 22
        },
        end: {
          line: 12,
          column: 8
        }
      },
      '3': {
        start: {
          line: 13,
          column: 6
        },
        end: {
          line: 13,
          column: 35
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 48
          },
          end: {
            line: 1,
            column: 49
          }
        },
        loc: {
          start: {
            line: 1,
            column: 60
          },
          end: {
            line: 16,
            column: 1
          }
        },
        line: 1
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_lq88blkgc.s[0]++;
angular.module('app').directive('deltaTooltip', function ($timeout) {
  cov_lq88blkgc.f[0]++;
  cov_lq88blkgc.s[1]++;

  return {
    restrict: 'A',
    scope: {
      title: '=',
      position: '=?'
    },
    link: function link(scope, element) {
      var tooltip = (cov_lq88blkgc.s[2]++, new ej.popups.Tooltip({
        content: scope.title,
        position: scope.position
      }));
      cov_lq88blkgc.s[3]++;
      tooltip.appendTo(element[0]);
    }
  };
});
"use strict";

var cov_z0vmspbf8 = function () {
  var path = "/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/analytics/analyticsCtrl.js",
      hash = "b2a41d48413494cd5342c341823dfcdceb77ba3b",
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = "__coverage__",
      coverageData = {
    path: "/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/analytics/analyticsCtrl.js",
    statementMap: {},
    fnMap: {},
    branchMap: {},
    s: {},
    f: {},
    b: {},
    _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();
'use strict';

var cov_irciqsoe9 = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/shared/logframe_template/logFrameTemplate.js',
      hash = '857b71ce453e40b2e31018cfdedb49e5d9c21ddd',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/shared/logframe_template/logFrameTemplate.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 386,
          column: 5
        }
      },
      '1': {
        start: {
          line: 3,
          column: 32
        },
        end: {
          line: 20,
          column: 5
        }
      },
      '2': {
        start: {
          line: 4,
          column: 21
        },
        end: {
          line: 7,
          column: 9
        }
      },
      '3': {
        start: {
          line: 9,
          column: 6
        },
        end: {
          line: 18,
          column: 7
        }
      },
      '4': {
        start: {
          line: 10,
          column: 8
        },
        end: {
          line: 12,
          column: 9
        }
      },
      '5': {
        start: {
          line: 11,
          column: 10
        },
        end: {
          line: 11,
          column: 19
        }
      },
      '6': {
        start: {
          line: 13,
          column: 8
        },
        end: {
          line: 15,
          column: 9
        }
      },
      '7': {
        start: {
          line: 14,
          column: 10
        },
        end: {
          line: 14,
          column: 19
        }
      },
      '8': {
        start: {
          line: 17,
          column: 8
        },
        end: {
          line: 17,
          column: 20
        }
      },
      '9': {
        start: {
          line: 19,
          column: 6
        },
        end: {
          line: 19,
          column: 45
        }
      },
      '10': {
        start: {
          line: 21,
          column: 17
        },
        end: {
          line: 21,
          column: 34
        }
      },
      '11': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 52,
          column: 6
        }
      },
      '12': {
        start: {
          line: 25,
          column: 8
        },
        end: {
          line: 34,
          column: 11
        }
      },
      '13': {
        start: {
          line: 26,
          column: 10
        },
        end: {
          line: 30,
          column: 15
        }
      },
      '14': {
        start: {
          line: 28,
          column: 14
        },
        end: {
          line: 28,
          column: 56
        }
      },
      '15': {
        start: {
          line: 29,
          column: 14
        },
        end: {
          line: 29,
          column: 27
        }
      },
      '16': {
        start: {
          line: 31,
          column: 10
        },
        end: {
          line: 33,
          column: 11
        }
      },
      '17': {
        start: {
          line: 32,
          column: 12
        },
        end: {
          line: 32,
          column: 26
        }
      },
      '18': {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 39,
          column: 11
        }
      },
      '19': {
        start: {
          line: 38,
          column: 10
        },
        end: {
          line: 38,
          column: 33
        }
      },
      '20': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 44,
          column: 11
        }
      },
      '21': {
        start: {
          line: 43,
          column: 10
        },
        end: {
          line: 43,
          column: 37
        }
      },
      '22': {
        start: {
          line: 47,
          column: 8
        },
        end: {
          line: 49,
          column: 11
        }
      },
      '23': {
        start: {
          line: 48,
          column: 10
        },
        end: {
          line: 48,
          column: 19
        }
      },
      '24': {
        start: {
          line: 55,
          column: 4
        },
        end: {
          line: 128,
          column: 6
        }
      },
      '25': {
        start: {
          line: 64,
          column: 19
        },
        end: {
          line: 64,
          column: 25
        }
      },
      '26': {
        start: {
          line: 65,
          column: 8
        },
        end: {
          line: 65,
          column: 73
        }
      },
      '27': {
        start: {
          line: 66,
          column: 8
        },
        end: {
          line: 124,
          column: 10
        }
      },
      '28': {
        start: {
          line: 68,
          column: 27
        },
        end: {
          line: 71,
          column: 15
        }
      },
      '29': {
        start: {
          line: 73,
          column: 12
        },
        end: {
          line: 82,
          column: 13
        }
      },
      '30': {
        start: {
          line: 74,
          column: 14
        },
        end: {
          line: 76,
          column: 15
        }
      },
      '31': {
        start: {
          line: 75,
          column: 16
        },
        end: {
          line: 75,
          column: 25
        }
      },
      '32': {
        start: {
          line: 77,
          column: 14
        },
        end: {
          line: 79,
          column: 15
        }
      },
      '33': {
        start: {
          line: 78,
          column: 16
        },
        end: {
          line: 78,
          column: 25
        }
      },
      '34': {
        start: {
          line: 81,
          column: 14
        },
        end: {
          line: 81,
          column: 26
        }
      },
      '35': {
        start: {
          line: 83,
          column: 12
        },
        end: {
          line: 83,
          column: 51
        }
      },
      '36': {
        start: {
          line: 86,
          column: 12
        },
        end: {
          line: 86,
          column: 49
        }
      },
      '37': {
        start: {
          line: 89,
          column: 12
        },
        end: {
          line: 102,
          column: 15
        }
      },
      '38': {
        start: {
          line: 92,
          column: 16
        },
        end: {
          line: 95,
          column: 18
        }
      },
      '39': {
        start: {
          line: 93,
          column: 18
        },
        end: {
          line: 93,
          column: 54
        }
      },
      '40': {
        start: {
          line: 94,
          column: 18
        },
        end: {
          line: 94,
          column: 31
        }
      },
      '41': {
        start: {
          line: 105,
          column: 12
        },
        end: {
          line: 107,
          column: 14
        }
      },
      '42': {
        start: {
          line: 108,
          column: 12
        },
        end: {
          line: 108,
          column: 30
        }
      },
      '43': {
        start: {
          line: 109,
          column: 12
        },
        end: {
          line: 122,
          column: 15
        }
      },
      '44': {
        start: {
          line: 110,
          column: 14
        },
        end: {
          line: 110,
          column: 29
        }
      },
      '45': {
        start: {
          line: 111,
          column: 14
        },
        end: {
          line: 111,
          column: 33
        }
      },
      '46': {
        start: {
          line: 113,
          column: 25
        },
        end: {
          line: 115,
          column: 16
        }
      },
      '47': {
        start: {
          line: 114,
          column: 16
        },
        end: {
          line: 114,
          column: 44
        }
      },
      '48': {
        start: {
          line: 117,
          column: 14
        },
        end: {
          line: 119,
          column: 15
        }
      },
      '49': {
        start: {
          line: 118,
          column: 16
        },
        end: {
          line: 118,
          column: 34
        }
      },
      '50': {
        start: {
          line: 121,
          column: 14
        },
        end: {
          line: 121,
          column: 44
        }
      },
      '51': {
        start: {
          line: 126,
          column: 8
        },
        end: {
          line: 126,
          column: 21
        }
      },
      '52': {
        start: {
          line: 131,
          column: 4
        },
        end: {
          line: 131,
          column: 29
        }
      },
      '53': {
        start: {
          line: 132,
          column: 4
        },
        end: {
          line: 138,
          column: 6
        }
      },
      '54': {
        start: {
          line: 133,
          column: 6
        },
        end: {
          line: 137,
          column: 9
        }
      },
      '55': {
        start: {
          line: 139,
          column: 4
        },
        end: {
          line: 149,
          column: 6
        }
      },
      '56': {
        start: {
          line: 140,
          column: 6
        },
        end: {
          line: 140,
          column: 47
        }
      },
      '57': {
        start: {
          line: 141,
          column: 6
        },
        end: {
          line: 148,
          column: 9
        }
      },
      '58': {
        start: {
          line: 150,
          column: 4
        },
        end: {
          line: 150,
          column: 20
        }
      },
      '59': {
        start: {
          line: 151,
          column: 4
        },
        end: {
          line: 181,
          column: 6
        }
      },
      '60': {
        start: {
          line: 152,
          column: 6
        },
        end: {
          line: 180,
          column: 9
        }
      },
      '61': {
        start: {
          line: 163,
          column: 8
        },
        end: {
          line: 165,
          column: 9
        }
      },
      '62': {
        start: {
          line: 164,
          column: 10
        },
        end: {
          line: 164,
          column: 17
        }
      },
      '63': {
        start: {
          line: 166,
          column: 8
        },
        end: {
          line: 166,
          column: 32
        }
      },
      '64': {
        start: {
          line: 167,
          column: 8
        },
        end: {
          line: 179,
          column: 11
        }
      },
      '65': {
        start: {
          line: 168,
          column: 10
        },
        end: {
          line: 178,
          column: 11
        }
      },
      '66': {
        start: {
          line: 169,
          column: 12
        },
        end: {
          line: 173,
          column: 15
        }
      },
      '67': {
        start: {
          line: 174,
          column: 12
        },
        end: {
          line: 174,
          column: 37
        }
      },
      '68': {
        start: {
          line: 176,
          column: 12
        },
        end: {
          line: 176,
          column: 51
        }
      },
      '69': {
        start: {
          line: 183,
          column: 4
        },
        end: {
          line: 385,
          column: 7
        }
      },
      '70': {
        start: {
          line: 184,
          column: 6
        },
        end: {
          line: 184,
          column: 33
        }
      },
      '71': {
        start: {
          line: 186,
          column: 6
        },
        end: {
          line: 384,
          column: 8
        }
      },
      '72': {
        start: {
          line: 188,
          column: 10
        },
        end: {
          line: 194,
          column: 13
        }
      },
      '73': {
        start: {
          line: 189,
          column: 12
        },
        end: {
          line: 189,
          column: 43
        }
      },
      '74': {
        start: {
          line: 190,
          column: 12
        },
        end: {
          line: 192,
          column: 15
        }
      },
      '75': {
        start: {
          line: 191,
          column: 14
        },
        end: {
          line: 191,
          column: 34
        }
      },
      '76': {
        start: {
          line: 193,
          column: 12
        },
        end: {
          line: 193,
          column: 34
        }
      },
      '77': {
        start: {
          line: 198,
          column: 12
        },
        end: {
          line: 198,
          column: 32
        }
      },
      '78': {
        start: {
          line: 212,
          column: 12
        },
        end: {
          line: 215,
          column: 13
        }
      },
      '79': {
        start: {
          line: 213,
          column: 14
        },
        end: {
          line: 213,
          column: 35
        }
      },
      '80': {
        start: {
          line: 214,
          column: 14
        },
        end: {
          line: 214,
          column: 38
        }
      },
      '81': {
        start: {
          line: 216,
          column: 12
        },
        end: {
          line: 219,
          column: 13
        }
      },
      '82': {
        start: {
          line: 217,
          column: 14
        },
        end: {
          line: 217,
          column: 35
        }
      },
      '83': {
        start: {
          line: 218,
          column: 14
        },
        end: {
          line: 218,
          column: 53
        }
      },
      '84': {
        start: {
          line: 220,
          column: 12
        },
        end: {
          line: 223,
          column: 13
        }
      },
      '85': {
        start: {
          line: 221,
          column: 14
        },
        end: {
          line: 221,
          column: 35
        }
      },
      '86': {
        start: {
          line: 222,
          column: 14
        },
        end: {
          line: 222,
          column: 50
        }
      },
      '87': {
        start: {
          line: 228,
          column: 10
        },
        end: {
          line: 228,
          column: 26
        }
      },
      '88': {
        start: {
          line: 229,
          column: 10
        },
        end: {
          line: 229,
          column: 34
        }
      },
      '89': {
        start: {
          line: 230,
          column: 10
        },
        end: {
          line: 230,
          column: 41
        }
      },
      '90': {
        start: {
          line: 231,
          column: 23
        },
        end: {
          line: 231,
          column: 40
        }
      },
      '91': {
        start: {
          line: 232,
          column: 10
        },
        end: {
          line: 232,
          column: 30
        }
      },
      '92': {
        start: {
          line: 233,
          column: 10
        },
        end: {
          line: 233,
          column: 34
        }
      },
      '93': {
        start: {
          line: 234,
          column: 10
        },
        end: {
          line: 236,
          column: 12
        }
      },
      '94': {
        start: {
          line: 237,
          column: 10
        },
        end: {
          line: 237,
          column: 34
        }
      },
      '95': {
        start: {
          line: 239,
          column: 10
        },
        end: {
          line: 245,
          column: 11
        }
      },
      '96': {
        start: {
          line: 240,
          column: 12
        },
        end: {
          line: 240,
          column: 37
        }
      },
      '97': {
        start: {
          line: 242,
          column: 12
        },
        end: {
          line: 244,
          column: 15
        }
      },
      '98': {
        start: {
          line: 243,
          column: 14
        },
        end: {
          line: 243,
          column: 47
        }
      },
      '99': {
        start: {
          line: 248,
          column: 10
        },
        end: {
          line: 248,
          column: 52
        }
      },
      '100': {
        start: {
          line: 251,
          column: 31
        },
        end: {
          line: 251,
          column: 70
        }
      },
      '101': {
        start: {
          line: 252,
          column: 10
        },
        end: {
          line: 252,
          column: 84
        }
      },
      '102': {
        start: {
          line: 252,
          column: 71
        },
        end: {
          line: 252,
          column: 82
        }
      },
      '103': {
        start: {
          line: 253,
          column: 10
        },
        end: {
          line: 253,
          column: 53
        }
      },
      '104': {
        start: {
          line: 254,
          column: 10
        },
        end: {
          line: 254,
          column: 53
        }
      },
      '105': {
        start: {
          line: 255,
          column: 10
        },
        end: {
          line: 255,
          column: 53
        }
      },
      '106': {
        start: {
          line: 256,
          column: 10
        },
        end: {
          line: 256,
          column: 53
        }
      },
      '107': {
        start: {
          line: 257,
          column: 10
        },
        end: {
          line: 257,
          column: 53
        }
      },
      '108': {
        start: {
          line: 258,
          column: 10
        },
        end: {
          line: 258,
          column: 52
        }
      },
      '109': {
        start: {
          line: 259,
          column: 10
        },
        end: {
          line: 259,
          column: 52
        }
      },
      '110': {
        start: {
          line: 260,
          column: 10
        },
        end: {
          line: 260,
          column: 52
        }
      },
      '111': {
        start: {
          line: 261,
          column: 10
        },
        end: {
          line: 261,
          column: 52
        }
      },
      '112': {
        start: {
          line: 262,
          column: 10
        },
        end: {
          line: 262,
          column: 52
        }
      },
      '113': {
        start: {
          line: 263,
          column: 10
        },
        end: {
          line: 263,
          column: 42
        }
      },
      '114': {
        start: {
          line: 264,
          column: 10
        },
        end: {
          line: 264,
          column: 216
        }
      },
      '115': {
        start: {
          line: 265,
          column: 10
        },
        end: {
          line: 267,
          column: 11
        }
      },
      '116': {
        start: {
          line: 266,
          column: 12
        },
        end: {
          line: 266,
          column: 19
        }
      },
      '117': {
        start: {
          line: 269,
          column: 10
        },
        end: {
          line: 271,
          column: 11
        }
      },
      '118': {
        start: {
          line: 270,
          column: 12
        },
        end: {
          line: 270,
          column: 54
        }
      },
      '119': {
        start: {
          line: 273,
          column: 10
        },
        end: {
          line: 275,
          column: 11
        }
      },
      '120': {
        start: {
          line: 273,
          column: 112
        },
        end: {
          line: 273,
          column: 196
        }
      },
      '121': {
        start: {
          line: 274,
          column: 12
        },
        end: {
          line: 274,
          column: 53
        }
      },
      '122': {
        start: {
          line: 277,
          column: 10
        },
        end: {
          line: 279,
          column: 11
        }
      },
      '123': {
        start: {
          line: 278,
          column: 12
        },
        end: {
          line: 278,
          column: 54
        }
      },
      '124': {
        start: {
          line: 281,
          column: 10
        },
        end: {
          line: 283,
          column: 11
        }
      },
      '125': {
        start: {
          line: 281,
          column: 115
        },
        end: {
          line: 281,
          column: 205
        }
      },
      '126': {
        start: {
          line: 282,
          column: 12
        },
        end: {
          line: 282,
          column: 53
        }
      },
      '127': {
        start: {
          line: 285,
          column: 10
        },
        end: {
          line: 287,
          column: 11
        }
      },
      '128': {
        start: {
          line: 286,
          column: 12
        },
        end: {
          line: 286,
          column: 54
        }
      },
      '129': {
        start: {
          line: 289,
          column: 10
        },
        end: {
          line: 291,
          column: 11
        }
      },
      '130': {
        start: {
          line: 289,
          column: 115
        },
        end: {
          line: 289,
          column: 205
        }
      },
      '131': {
        start: {
          line: 290,
          column: 12
        },
        end: {
          line: 290,
          column: 53
        }
      },
      '132': {
        start: {
          line: 293,
          column: 10
        },
        end: {
          line: 295,
          column: 11
        }
      },
      '133': {
        start: {
          line: 294,
          column: 12
        },
        end: {
          line: 294,
          column: 54
        }
      },
      '134': {
        start: {
          line: 297,
          column: 10
        },
        end: {
          line: 299,
          column: 11
        }
      },
      '135': {
        start: {
          line: 297,
          column: 115
        },
        end: {
          line: 297,
          column: 205
        }
      },
      '136': {
        start: {
          line: 298,
          column: 12
        },
        end: {
          line: 298,
          column: 53
        }
      },
      '137': {
        start: {
          line: 301,
          column: 10
        },
        end: {
          line: 303,
          column: 11
        }
      },
      '138': {
        start: {
          line: 302,
          column: 12
        },
        end: {
          line: 302,
          column: 54
        }
      },
      '139': {
        start: {
          line: 305,
          column: 10
        },
        end: {
          line: 307,
          column: 11
        }
      },
      '140': {
        start: {
          line: 305,
          column: 115
        },
        end: {
          line: 305,
          column: 205
        }
      },
      '141': {
        start: {
          line: 306,
          column: 12
        },
        end: {
          line: 306,
          column: 53
        }
      },
      '142': {
        start: {
          line: 309,
          column: 10
        },
        end: {
          line: 311,
          column: 11
        }
      },
      '143': {
        start: {
          line: 310,
          column: 12
        },
        end: {
          line: 310,
          column: 19
        }
      },
      '144': {
        start: {
          line: 312,
          column: 10
        },
        end: {
          line: 314,
          column: 11
        }
      },
      '145': {
        start: {
          line: 313,
          column: 12
        },
        end: {
          line: 313,
          column: 19
        }
      },
      '146': {
        start: {
          line: 317,
          column: 10
        },
        end: {
          line: 317,
          column: 40
        }
      },
      '147': {
        start: {
          line: 318,
          column: 10
        },
        end: {
          line: 320,
          column: 13
        }
      },
      '148': {
        start: {
          line: 319,
          column: 12
        },
        end: {
          line: 319,
          column: 36
        }
      },
      '149': {
        start: {
          line: 323,
          column: 10
        },
        end: {
          line: 328,
          column: 11
        }
      },
      '150': {
        start: {
          line: 324,
          column: 12
        },
        end: {
          line: 327,
          column: 13
        }
      },
      '151': {
        start: {
          line: 325,
          column: 14
        },
        end: {
          line: 325,
          column: 52
        }
      },
      '152': {
        start: {
          line: 326,
          column: 14
        },
        end: {
          line: 326,
          column: 20
        }
      },
      '153': {
        start: {
          line: 329,
          column: 10
        },
        end: {
          line: 329,
          column: 128
        }
      },
      '154': {
        start: {
          line: 330,
          column: 10
        },
        end: {
          line: 330,
          column: 40
        }
      },
      '155': {
        start: {
          line: 333,
          column: 10
        },
        end: {
          line: 333,
          column: 26
        }
      },
      '156': {
        start: {
          line: 336,
          column: 10
        },
        end: {
          line: 344,
          column: 13
        }
      },
      '157': {
        start: {
          line: 347,
          column: 10
        },
        end: {
          line: 347,
          column: 50
        }
      },
      '158': {
        start: {
          line: 348,
          column: 10
        },
        end: {
          line: 348,
          column: 49
        }
      },
      '159': {
        start: {
          line: 349,
          column: 10
        },
        end: {
          line: 351,
          column: 13
        }
      },
      '160': {
        start: {
          line: 350,
          column: 12
        },
        end: {
          line: 350,
          column: 42
        }
      },
      '161': {
        start: {
          line: 352,
          column: 10
        },
        end: {
          line: 352,
          column: 22
        }
      },
      '162': {
        start: {
          line: 355,
          column: 10
        },
        end: {
          line: 382,
          column: 13
        }
      },
      '163': {
        start: {
          line: 366,
          column: 12
        },
        end: {
          line: 368,
          column: 13
        }
      },
      '164': {
        start: {
          line: 367,
          column: 14
        },
        end: {
          line: 367,
          column: 21
        }
      },
      '165': {
        start: {
          line: 369,
          column: 12
        },
        end: {
          line: 369,
          column: 42
        }
      },
      '166': {
        start: {
          line: 370,
          column: 12
        },
        end: {
          line: 381,
          column: 15
        }
      },
      '167': {
        start: {
          line: 371,
          column: 14
        },
        end: {
          line: 380,
          column: 15
        }
      },
      '168': {
        start: {
          line: 372,
          column: 16
        },
        end: {
          line: 376,
          column: 19
        }
      },
      '169': {
        start: {
          line: 377,
          column: 16
        },
        end: {
          line: 377,
          column: 47
        }
      },
      '170': {
        start: {
          line: 379,
          column: 16
        },
        end: {
          line: 379,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 94
          },
          end: {
            line: 2,
            column: 95
          }
        },
        loc: {
          start: {
            line: 2,
            column: 143
          },
          end: {
            line: 53,
            column: 3
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 3,
            column: 32
          },
          end: {
            line: 3,
            column: 33
          }
        },
        loc: {
          start: {
            line: 3,
            column: 40
          },
          end: {
            line: 20,
            column: 5
          }
        },
        line: 3
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 25,
            column: 48
          },
          end: {
            line: 25,
            column: 49
          }
        },
        loc: {
          start: {
            line: 25,
            column: 56
          },
          end: {
            line: 34,
            column: 9
          }
        },
        line: 25
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 27,
            column: 17
          },
          end: {
            line: 27,
            column: 18
          }
        },
        loc: {
          start: {
            line: 27,
            column: 26
          },
          end: {
            line: 30,
            column: 13
          }
        },
        line: 27
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 37,
            column: 93
          },
          end: {
            line: 37,
            column: 94
          }
        },
        loc: {
          start: {
            line: 37,
            column: 101
          },
          end: {
            line: 39,
            column: 9
          }
        },
        line: 37
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 42,
            column: 78
          },
          end: {
            line: 42,
            column: 79
          }
        },
        loc: {
          start: {
            line: 42,
            column: 86
          },
          end: {
            line: 44,
            column: 9
          }
        },
        line: 42
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 47,
            column: 64
          },
          end: {
            line: 47,
            column: 65
          }
        },
        loc: {
          start: {
            line: 47,
            column: 72
          },
          end: {
            line: 49,
            column: 9
          }
        },
        line: 47
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 54,
            column: 36
          },
          end: {
            line: 54,
            column: 37
          }
        },
        loc: {
          start: {
            line: 54,
            column: 42
          },
          end: {
            line: 129,
            column: 3
          }
        },
        line: 54
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 91,
            column: 27
          },
          end: {
            line: 91,
            column: 28
          }
        },
        loc: {
          start: {
            line: 91,
            column: 48
          },
          end: {
            line: 96,
            column: 15
          }
        },
        line: 91
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 92,
            column: 36
          },
          end: {
            line: 92,
            column: 37
          }
        },
        loc: {
          start: {
            line: 92,
            column: 42
          },
          end: {
            line: 95,
            column: 17
          }
        },
        line: 92
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 109,
            column: 47
          },
          end: {
            line: 109,
            column: 48
          }
        },
        loc: {
          start: {
            line: 109,
            column: 55
          },
          end: {
            line: 122,
            column: 13
          }
        },
        line: 109
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 113,
            column: 38
          },
          end: {
            line: 113,
            column: 39
          }
        },
        loc: {
          start: {
            line: 113,
            column: 47
          },
          end: {
            line: 115,
            column: 15
          }
        },
        line: 113
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 130,
            column: 37
          },
          end: {
            line: 130,
            column: 38
          }
        },
        loc: {
          start: {
            line: 130,
            column: 111
          },
          end: {
            line: 386,
            column: 3
          }
        },
        line: 130
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 132,
            column: 17
          },
          end: {
            line: 132,
            column: 18
          }
        },
        loc: {
          start: {
            line: 132,
            column: 29
          },
          end: {
            line: 138,
            column: 5
          }
        },
        line: 132
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 139,
            column: 18
          },
          end: {
            line: 139,
            column: 19
          }
        },
        loc: {
          start: {
            line: 139,
            column: 34
          },
          end: {
            line: 149,
            column: 5
          }
        },
        line: 139
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 151,
            column: 24
          },
          end: {
            line: 151,
            column: 25
          }
        },
        loc: {
          start: {
            line: 151,
            column: 40
          },
          end: {
            line: 181,
            column: 5
          }
        },
        line: 151
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 162,
            column: 9
          },
          end: {
            line: 162,
            column: 10
          }
        },
        loc: {
          start: {
            line: 162,
            column: 22
          },
          end: {
            line: 180,
            column: 7
          }
        },
        line: 162
      },
      '17': {
        name: '(anonymous_17)',
        decl: {
          start: {
            line: 167,
            column: 64
          },
          end: {
            line: 167,
            column: 65
          }
        },
        loc: {
          start: {
            line: 167,
            column: 72
          },
          end: {
            line: 179,
            column: 9
          }
        },
        line: 167
      },
      '18': {
        name: '(anonymous_18)',
        decl: {
          start: {
            line: 183,
            column: 39
          },
          end: {
            line: 183,
            column: 40
          }
        },
        loc: {
          start: {
            line: 183,
            column: 47
          },
          end: {
            line: 385,
            column: 5
          }
        },
        line: 183
      },
      '19': {
        name: '(anonymous_19)',
        decl: {
          start: {
            line: 188,
            column: 45
          },
          end: {
            line: 188,
            column: 46
          }
        },
        loc: {
          start: {
            line: 188,
            column: 53
          },
          end: {
            line: 194,
            column: 11
          }
        },
        line: 188
      },
      '20': {
        name: '(anonymous_20)',
        decl: {
          start: {
            line: 190,
            column: 58
          },
          end: {
            line: 190,
            column: 59
          }
        },
        loc: {
          start: {
            line: 190,
            column: 67
          },
          end: {
            line: 192,
            column: 13
          }
        },
        line: 190
      },
      '21': {
        name: '(anonymous_21)',
        decl: {
          start: {
            line: 197,
            column: 35
          },
          end: {
            line: 197,
            column: 36
          }
        },
        loc: {
          start: {
            line: 197,
            column: 44
          },
          end: {
            line: 199,
            column: 11
          }
        },
        line: 197
      },
      '22': {
        name: '(anonymous_22)',
        decl: {
          start: {
            line: 242,
            column: 69
          },
          end: {
            line: 242,
            column: 70
          }
        },
        loc: {
          start: {
            line: 242,
            column: 78
          },
          end: {
            line: 244,
            column: 13
          }
        },
        line: 242
      },
      '23': {
        name: '(anonymous_23)',
        decl: {
          start: {
            line: 252,
            column: 62
          },
          end: {
            line: 252,
            column: 63
          }
        },
        loc: {
          start: {
            line: 252,
            column: 71
          },
          end: {
            line: 252,
            column: 82
          }
        },
        line: 252
      },
      '24': {
        name: '(anonymous_24)',
        decl: {
          start: {
            line: 273,
            column: 102
          },
          end: {
            line: 273,
            column: 103
          }
        },
        loc: {
          start: {
            line: 273,
            column: 112
          },
          end: {
            line: 273,
            column: 196
          }
        },
        line: 273
      },
      '25': {
        name: '(anonymous_25)',
        decl: {
          start: {
            line: 281,
            column: 105
          },
          end: {
            line: 281,
            column: 106
          }
        },
        loc: {
          start: {
            line: 281,
            column: 115
          },
          end: {
            line: 281,
            column: 205
          }
        },
        line: 281
      },
      '26': {
        name: '(anonymous_26)',
        decl: {
          start: {
            line: 289,
            column: 105
          },
          end: {
            line: 289,
            column: 106
          }
        },
        loc: {
          start: {
            line: 289,
            column: 115
          },
          end: {
            line: 289,
            column: 205
          }
        },
        line: 289
      },
      '27': {
        name: '(anonymous_27)',
        decl: {
          start: {
            line: 297,
            column: 105
          },
          end: {
            line: 297,
            column: 106
          }
        },
        loc: {
          start: {
            line: 297,
            column: 115
          },
          end: {
            line: 297,
            column: 205
          }
        },
        line: 297
      },
      '28': {
        name: '(anonymous_28)',
        decl: {
          start: {
            line: 305,
            column: 105
          },
          end: {
            line: 305,
            column: 106
          }
        },
        loc: {
          start: {
            line: 305,
            column: 115
          },
          end: {
            line: 305,
            column: 205
          }
        },
        line: 305
      },
      '29': {
        name: '(anonymous_29)',
        decl: {
          start: {
            line: 318,
            column: 55
          },
          end: {
            line: 318,
            column: 56
          }
        },
        loc: {
          start: {
            line: 318,
            column: 61
          },
          end: {
            line: 320,
            column: 11
          }
        },
        line: 318
      },
      '30': {
        name: '(anonymous_30)',
        decl: {
          start: {
            line: 349,
            column: 72
          },
          end: {
            line: 349,
            column: 73
          }
        },
        loc: {
          start: {
            line: 349,
            column: 90
          },
          end: {
            line: 351,
            column: 11
          }
        },
        line: 349
      },
      '31': {
        name: '(anonymous_31)',
        decl: {
          start: {
            line: 365,
            column: 13
          },
          end: {
            line: 365,
            column: 14
          }
        },
        loc: {
          start: {
            line: 365,
            column: 26
          },
          end: {
            line: 382,
            column: 11
          }
        },
        line: 365
      },
      '32': {
        name: '(anonymous_32)',
        decl: {
          start: {
            line: 370,
            column: 51
          },
          end: {
            line: 370,
            column: 52
          }
        },
        loc: {
          start: {
            line: 370,
            column: 59
          },
          end: {
            line: 381,
            column: 13
          }
        },
        line: 370
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 4,
            column: 21
          },
          end: {
            line: 7,
            column: 9
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 4,
            column: 55
          },
          end: {
            line: 4,
            column: 127
          }
        }, {
          start: {
            line: 5,
            column: 9
          },
          end: {
            line: 6,
            column: 84
          }
        }],
        line: 4
      },
      '1': {
        loc: {
          start: {
            line: 5,
            column: 9
          },
          end: {
            line: 6,
            column: 84
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 5,
            column: 43
          },
          end: {
            line: 5,
            column: 115
          }
        }, {
          start: {
            line: 6,
            column: 12
          },
          end: {
            line: 6,
            column: 84
          }
        }],
        line: 5
      },
      '2': {
        loc: {
          start: {
            line: 10,
            column: 8
          },
          end: {
            line: 12,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 10,
            column: 8
          },
          end: {
            line: 12,
            column: 9
          }
        }, {
          start: {
            line: 10,
            column: 8
          },
          end: {
            line: 12,
            column: 9
          }
        }],
        line: 10
      },
      '3': {
        loc: {
          start: {
            line: 13,
            column: 8
          },
          end: {
            line: 15,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 13,
            column: 8
          },
          end: {
            line: 15,
            column: 9
          }
        }, {
          start: {
            line: 13,
            column: 8
          },
          end: {
            line: 15,
            column: 9
          }
        }],
        line: 13
      },
      '4': {
        loc: {
          start: {
            line: 31,
            column: 10
          },
          end: {
            line: 33,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 31,
            column: 10
          },
          end: {
            line: 33,
            column: 11
          }
        }, {
          start: {
            line: 31,
            column: 10
          },
          end: {
            line: 33,
            column: 11
          }
        }],
        line: 31
      },
      '5': {
        loc: {
          start: {
            line: 68,
            column: 27
          },
          end: {
            line: 71,
            column: 15
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 68,
            column: 61
          },
          end: {
            line: 68,
            column: 133
          }
        }, {
          start: {
            line: 69,
            column: 15
          },
          end: {
            line: 70,
            column: 90
          }
        }],
        line: 68
      },
      '6': {
        loc: {
          start: {
            line: 69,
            column: 15
          },
          end: {
            line: 70,
            column: 90
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 69,
            column: 49
          },
          end: {
            line: 69,
            column: 121
          }
        }, {
          start: {
            line: 70,
            column: 18
          },
          end: {
            line: 70,
            column: 90
          }
        }],
        line: 69
      },
      '7': {
        loc: {
          start: {
            line: 74,
            column: 14
          },
          end: {
            line: 76,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 74,
            column: 14
          },
          end: {
            line: 76,
            column: 15
          }
        }, {
          start: {
            line: 74,
            column: 14
          },
          end: {
            line: 76,
            column: 15
          }
        }],
        line: 74
      },
      '8': {
        loc: {
          start: {
            line: 77,
            column: 14
          },
          end: {
            line: 79,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 77,
            column: 14
          },
          end: {
            line: 79,
            column: 15
          }
        }, {
          start: {
            line: 77,
            column: 14
          },
          end: {
            line: 79,
            column: 15
          }
        }],
        line: 77
      },
      '9': {
        loc: {
          start: {
            line: 117,
            column: 14
          },
          end: {
            line: 119,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 117,
            column: 14
          },
          end: {
            line: 119,
            column: 15
          }
        }, {
          start: {
            line: 117,
            column: 14
          },
          end: {
            line: 119,
            column: 15
          }
        }],
        line: 117
      },
      '10': {
        loc: {
          start: {
            line: 163,
            column: 8
          },
          end: {
            line: 165,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 163,
            column: 8
          },
          end: {
            line: 165,
            column: 9
          }
        }, {
          start: {
            line: 163,
            column: 8
          },
          end: {
            line: 165,
            column: 9
          }
        }],
        line: 163
      },
      '11': {
        loc: {
          start: {
            line: 168,
            column: 10
          },
          end: {
            line: 178,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 168,
            column: 10
          },
          end: {
            line: 178,
            column: 11
          }
        }, {
          start: {
            line: 168,
            column: 10
          },
          end: {
            line: 178,
            column: 11
          }
        }],
        line: 168
      },
      '12': {
        loc: {
          start: {
            line: 212,
            column: 12
          },
          end: {
            line: 215,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 212,
            column: 12
          },
          end: {
            line: 215,
            column: 13
          }
        }, {
          start: {
            line: 212,
            column: 12
          },
          end: {
            line: 215,
            column: 13
          }
        }],
        line: 212
      },
      '13': {
        loc: {
          start: {
            line: 216,
            column: 12
          },
          end: {
            line: 219,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 216,
            column: 12
          },
          end: {
            line: 219,
            column: 13
          }
        }, {
          start: {
            line: 216,
            column: 12
          },
          end: {
            line: 219,
            column: 13
          }
        }],
        line: 216
      },
      '14': {
        loc: {
          start: {
            line: 220,
            column: 12
          },
          end: {
            line: 223,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 220,
            column: 12
          },
          end: {
            line: 223,
            column: 13
          }
        }, {
          start: {
            line: 220,
            column: 12
          },
          end: {
            line: 223,
            column: 13
          }
        }],
        line: 220
      },
      '15': {
        loc: {
          start: {
            line: 239,
            column: 10
          },
          end: {
            line: 245,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 239,
            column: 10
          },
          end: {
            line: 245,
            column: 11
          }
        }, {
          start: {
            line: 239,
            column: 10
          },
          end: {
            line: 245,
            column: 11
          }
        }],
        line: 239
      },
      '16': {
        loc: {
          start: {
            line: 264,
            column: 46
          },
          end: {
            line: 264,
            column: 214
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 264,
            column: 46
          },
          end: {
            line: 264,
            column: 74
          }
        }, {
          start: {
            line: 264,
            column: 78
          },
          end: {
            line: 264,
            column: 109
          }
        }, {
          start: {
            line: 264,
            column: 113
          },
          end: {
            line: 264,
            column: 144
          }
        }, {
          start: {
            line: 264,
            column: 148
          },
          end: {
            line: 264,
            column: 179
          }
        }, {
          start: {
            line: 264,
            column: 183
          },
          end: {
            line: 264,
            column: 214
          }
        }],
        line: 264
      },
      '17': {
        loc: {
          start: {
            line: 265,
            column: 10
          },
          end: {
            line: 267,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 265,
            column: 10
          },
          end: {
            line: 267,
            column: 11
          }
        }, {
          start: {
            line: 265,
            column: 10
          },
          end: {
            line: 267,
            column: 11
          }
        }],
        line: 265
      },
      '18': {
        loc: {
          start: {
            line: 269,
            column: 10
          },
          end: {
            line: 271,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 269,
            column: 10
          },
          end: {
            line: 271,
            column: 11
          }
        }, {
          start: {
            line: 269,
            column: 10
          },
          end: {
            line: 271,
            column: 11
          }
        }],
        line: 269
      },
      '19': {
        loc: {
          start: {
            line: 269,
            column: 15
          },
          end: {
            line: 269,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 269,
            column: 15
          },
          end: {
            line: 269,
            column: 43
          }
        }, {
          start: {
            line: 269,
            column: 47
          },
          end: {
            line: 269,
            column: 49
          }
        }],
        line: 269
      },
      '20': {
        loc: {
          start: {
            line: 273,
            column: 10
          },
          end: {
            line: 275,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 273,
            column: 10
          },
          end: {
            line: 275,
            column: 11
          }
        }, {
          start: {
            line: 273,
            column: 10
          },
          end: {
            line: 275,
            column: 11
          }
        }],
        line: 273
      },
      '21': {
        loc: {
          start: {
            line: 273,
            column: 14
          },
          end: {
            line: 273,
            column: 198
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 273,
            column: 14
          },
          end: {
            line: 273,
            column: 61
          }
        }, {
          start: {
            line: 273,
            column: 65
          },
          end: {
            line: 273,
            column: 198
          }
        }],
        line: 273
      },
      '22': {
        loc: {
          start: {
            line: 273,
            column: 15
          },
          end: {
            line: 273,
            column: 49
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 273,
            column: 15
          },
          end: {
            line: 273,
            column: 43
          }
        }, {
          start: {
            line: 273,
            column: 47
          },
          end: {
            line: 273,
            column: 49
          }
        }],
        line: 273
      },
      '23': {
        loc: {
          start: {
            line: 273,
            column: 112
          },
          end: {
            line: 273,
            column: 196
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 273,
            column: 112
          },
          end: {
            line: 273,
            column: 165
          }
        }, {
          start: {
            line: 273,
            column: 169
          },
          end: {
            line: 273,
            column: 196
          }
        }],
        line: 273
      },
      '24': {
        loc: {
          start: {
            line: 277,
            column: 10
          },
          end: {
            line: 279,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 277,
            column: 10
          },
          end: {
            line: 279,
            column: 11
          }
        }, {
          start: {
            line: 277,
            column: 10
          },
          end: {
            line: 279,
            column: 11
          }
        }],
        line: 277
      },
      '25': {
        loc: {
          start: {
            line: 277,
            column: 15
          },
          end: {
            line: 277,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 277,
            column: 15
          },
          end: {
            line: 277,
            column: 46
          }
        }, {
          start: {
            line: 277,
            column: 50
          },
          end: {
            line: 277,
            column: 52
          }
        }],
        line: 277
      },
      '26': {
        loc: {
          start: {
            line: 281,
            column: 10
          },
          end: {
            line: 283,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 281,
            column: 10
          },
          end: {
            line: 283,
            column: 11
          }
        }, {
          start: {
            line: 281,
            column: 10
          },
          end: {
            line: 283,
            column: 11
          }
        }],
        line: 281
      },
      '27': {
        loc: {
          start: {
            line: 281,
            column: 14
          },
          end: {
            line: 281,
            column: 207
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 281,
            column: 14
          },
          end: {
            line: 281,
            column: 64
          }
        }, {
          start: {
            line: 281,
            column: 68
          },
          end: {
            line: 281,
            column: 207
          }
        }],
        line: 281
      },
      '28': {
        loc: {
          start: {
            line: 281,
            column: 15
          },
          end: {
            line: 281,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 281,
            column: 15
          },
          end: {
            line: 281,
            column: 46
          }
        }, {
          start: {
            line: 281,
            column: 50
          },
          end: {
            line: 281,
            column: 52
          }
        }],
        line: 281
      },
      '29': {
        loc: {
          start: {
            line: 281,
            column: 115
          },
          end: {
            line: 281,
            column: 205
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 281,
            column: 115
          },
          end: {
            line: 281,
            column: 174
          }
        }, {
          start: {
            line: 281,
            column: 178
          },
          end: {
            line: 281,
            column: 205
          }
        }],
        line: 281
      },
      '30': {
        loc: {
          start: {
            line: 285,
            column: 10
          },
          end: {
            line: 287,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 285,
            column: 10
          },
          end: {
            line: 287,
            column: 11
          }
        }, {
          start: {
            line: 285,
            column: 10
          },
          end: {
            line: 287,
            column: 11
          }
        }],
        line: 285
      },
      '31': {
        loc: {
          start: {
            line: 285,
            column: 15
          },
          end: {
            line: 285,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 285,
            column: 15
          },
          end: {
            line: 285,
            column: 46
          }
        }, {
          start: {
            line: 285,
            column: 50
          },
          end: {
            line: 285,
            column: 52
          }
        }],
        line: 285
      },
      '32': {
        loc: {
          start: {
            line: 289,
            column: 10
          },
          end: {
            line: 291,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 289,
            column: 10
          },
          end: {
            line: 291,
            column: 11
          }
        }, {
          start: {
            line: 289,
            column: 10
          },
          end: {
            line: 291,
            column: 11
          }
        }],
        line: 289
      },
      '33': {
        loc: {
          start: {
            line: 289,
            column: 14
          },
          end: {
            line: 289,
            column: 207
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 289,
            column: 14
          },
          end: {
            line: 289,
            column: 64
          }
        }, {
          start: {
            line: 289,
            column: 68
          },
          end: {
            line: 289,
            column: 207
          }
        }],
        line: 289
      },
      '34': {
        loc: {
          start: {
            line: 289,
            column: 15
          },
          end: {
            line: 289,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 289,
            column: 15
          },
          end: {
            line: 289,
            column: 46
          }
        }, {
          start: {
            line: 289,
            column: 50
          },
          end: {
            line: 289,
            column: 52
          }
        }],
        line: 289
      },
      '35': {
        loc: {
          start: {
            line: 289,
            column: 115
          },
          end: {
            line: 289,
            column: 205
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 289,
            column: 115
          },
          end: {
            line: 289,
            column: 174
          }
        }, {
          start: {
            line: 289,
            column: 178
          },
          end: {
            line: 289,
            column: 205
          }
        }],
        line: 289
      },
      '36': {
        loc: {
          start: {
            line: 293,
            column: 10
          },
          end: {
            line: 295,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 293,
            column: 10
          },
          end: {
            line: 295,
            column: 11
          }
        }, {
          start: {
            line: 293,
            column: 10
          },
          end: {
            line: 295,
            column: 11
          }
        }],
        line: 293
      },
      '37': {
        loc: {
          start: {
            line: 293,
            column: 15
          },
          end: {
            line: 293,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 293,
            column: 15
          },
          end: {
            line: 293,
            column: 46
          }
        }, {
          start: {
            line: 293,
            column: 50
          },
          end: {
            line: 293,
            column: 52
          }
        }],
        line: 293
      },
      '38': {
        loc: {
          start: {
            line: 297,
            column: 10
          },
          end: {
            line: 299,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 297,
            column: 10
          },
          end: {
            line: 299,
            column: 11
          }
        }, {
          start: {
            line: 297,
            column: 10
          },
          end: {
            line: 299,
            column: 11
          }
        }],
        line: 297
      },
      '39': {
        loc: {
          start: {
            line: 297,
            column: 14
          },
          end: {
            line: 297,
            column: 207
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 297,
            column: 14
          },
          end: {
            line: 297,
            column: 64
          }
        }, {
          start: {
            line: 297,
            column: 68
          },
          end: {
            line: 297,
            column: 207
          }
        }],
        line: 297
      },
      '40': {
        loc: {
          start: {
            line: 297,
            column: 15
          },
          end: {
            line: 297,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 297,
            column: 15
          },
          end: {
            line: 297,
            column: 46
          }
        }, {
          start: {
            line: 297,
            column: 50
          },
          end: {
            line: 297,
            column: 52
          }
        }],
        line: 297
      },
      '41': {
        loc: {
          start: {
            line: 297,
            column: 115
          },
          end: {
            line: 297,
            column: 205
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 297,
            column: 115
          },
          end: {
            line: 297,
            column: 174
          }
        }, {
          start: {
            line: 297,
            column: 178
          },
          end: {
            line: 297,
            column: 205
          }
        }],
        line: 297
      },
      '42': {
        loc: {
          start: {
            line: 301,
            column: 10
          },
          end: {
            line: 303,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 301,
            column: 10
          },
          end: {
            line: 303,
            column: 11
          }
        }, {
          start: {
            line: 301,
            column: 10
          },
          end: {
            line: 303,
            column: 11
          }
        }],
        line: 301
      },
      '43': {
        loc: {
          start: {
            line: 301,
            column: 15
          },
          end: {
            line: 301,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 301,
            column: 15
          },
          end: {
            line: 301,
            column: 46
          }
        }, {
          start: {
            line: 301,
            column: 50
          },
          end: {
            line: 301,
            column: 52
          }
        }],
        line: 301
      },
      '44': {
        loc: {
          start: {
            line: 305,
            column: 10
          },
          end: {
            line: 307,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 305,
            column: 10
          },
          end: {
            line: 307,
            column: 11
          }
        }, {
          start: {
            line: 305,
            column: 10
          },
          end: {
            line: 307,
            column: 11
          }
        }],
        line: 305
      },
      '45': {
        loc: {
          start: {
            line: 305,
            column: 14
          },
          end: {
            line: 305,
            column: 207
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 305,
            column: 14
          },
          end: {
            line: 305,
            column: 64
          }
        }, {
          start: {
            line: 305,
            column: 68
          },
          end: {
            line: 305,
            column: 207
          }
        }],
        line: 305
      },
      '46': {
        loc: {
          start: {
            line: 305,
            column: 15
          },
          end: {
            line: 305,
            column: 52
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 305,
            column: 15
          },
          end: {
            line: 305,
            column: 46
          }
        }, {
          start: {
            line: 305,
            column: 50
          },
          end: {
            line: 305,
            column: 52
          }
        }],
        line: 305
      },
      '47': {
        loc: {
          start: {
            line: 305,
            column: 115
          },
          end: {
            line: 305,
            column: 205
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 305,
            column: 115
          },
          end: {
            line: 305,
            column: 174
          }
        }, {
          start: {
            line: 305,
            column: 178
          },
          end: {
            line: 305,
            column: 205
          }
        }],
        line: 305
      },
      '48': {
        loc: {
          start: {
            line: 309,
            column: 10
          },
          end: {
            line: 311,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 309,
            column: 10
          },
          end: {
            line: 311,
            column: 11
          }
        }, {
          start: {
            line: 309,
            column: 10
          },
          end: {
            line: 311,
            column: 11
          }
        }],
        line: 309
      },
      '49': {
        loc: {
          start: {
            line: 309,
            column: 14
          },
          end: {
            line: 309,
            column: 197
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 309,
            column: 14
          },
          end: {
            line: 309,
            column: 45
          }
        }, {
          start: {
            line: 309,
            column: 49
          },
          end: {
            line: 309,
            column: 83
          }
        }, {
          start: {
            line: 309,
            column: 87
          },
          end: {
            line: 309,
            column: 121
          }
        }, {
          start: {
            line: 309,
            column: 125
          },
          end: {
            line: 309,
            column: 159
          }
        }, {
          start: {
            line: 309,
            column: 163
          },
          end: {
            line: 309,
            column: 197
          }
        }],
        line: 309
      },
      '50': {
        loc: {
          start: {
            line: 312,
            column: 10
          },
          end: {
            line: 314,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 312,
            column: 10
          },
          end: {
            line: 314,
            column: 11
          }
        }, {
          start: {
            line: 312,
            column: 10
          },
          end: {
            line: 314,
            column: 11
          }
        }],
        line: 312
      },
      '51': {
        loc: {
          start: {
            line: 312,
            column: 14
          },
          end: {
            line: 312,
            column: 195
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 312,
            column: 14
          },
          end: {
            line: 312,
            column: 47
          }
        }, {
          start: {
            line: 312,
            column: 51
          },
          end: {
            line: 312,
            column: 84
          }
        }, {
          start: {
            line: 312,
            column: 88
          },
          end: {
            line: 312,
            column: 121
          }
        }, {
          start: {
            line: 312,
            column: 125
          },
          end: {
            line: 312,
            column: 158
          }
        }, {
          start: {
            line: 312,
            column: 162
          },
          end: {
            line: 312,
            column: 195
          }
        }],
        line: 312
      },
      '52': {
        loc: {
          start: {
            line: 324,
            column: 12
          },
          end: {
            line: 327,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 324,
            column: 12
          },
          end: {
            line: 327,
            column: 13
          }
        }, {
          start: {
            line: 324,
            column: 12
          },
          end: {
            line: 327,
            column: 13
          }
        }],
        line: 324
      },
      '53': {
        loc: {
          start: {
            line: 366,
            column: 12
          },
          end: {
            line: 368,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 366,
            column: 12
          },
          end: {
            line: 368,
            column: 13
          }
        }, {
          start: {
            line: 366,
            column: 12
          },
          end: {
            line: 368,
            column: 13
          }
        }],
        line: 366
      },
      '54': {
        loc: {
          start: {
            line: 371,
            column: 14
          },
          end: {
            line: 380,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 371,
            column: 14
          },
          end: {
            line: 380,
            column: 15
          }
        }, {
          start: {
            line: 371,
            column: 14
          },
          end: {
            line: 380,
            column: 15
          }
        }],
        line: 371
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0, 0, 0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0, 0, 0, 0],
      '50': [0, 0],
      '51': [0, 0, 0, 0, 0],
      '52': [0, 0],
      '53': [0, 0],
      '54': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_irciqsoe9.s[0]++;
angular.module('deltaLogframeTemplateModule', []).factory('deltaLogframeTemplateFact', ['$CRUDService', '$q', '$translate', '$LOGINService', function ($CRUDService, $q, $translate, $LOGINService) {
  cov_irciqsoe9.f[0]++;
  cov_irciqsoe9.s[1]++;

  var getDefaultLogLabel = function getDefaultLogLabel(data) {
    cov_irciqsoe9.f[1]++;

    var langs = (cov_irciqsoe9.s[2]++, $LOGINService.getLang() == 'en' ? (cov_irciqsoe9.b[0][0]++, [data.LABEL_LOG_FRAME_EN, data.LABEL_LOG_FRAME, data.LABEL_LOG_FRAME_AR]) : (cov_irciqsoe9.b[0][1]++, $LOGINService.getLang() == 'fr' ? (cov_irciqsoe9.b[1][0]++, [data.LABEL_LOG_FRAME, data.LABEL_LOG_FRAME_EN, data.LABEL_LOG_FRAME_AR]) : (cov_irciqsoe9.b[1][1]++, [data.LABEL_LOG_FRAME_AR, data.LABEL_LOG_FRAME_EN, data.LABEL_LOG_FRAME])));

    cov_irciqsoe9.s[3]++;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = langs[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var lang = _step.value;
        cov_irciqsoe9.s[4]++;

        if (!lang) {
          cov_irciqsoe9.b[2][0]++;
          cov_irciqsoe9.s[5]++;

          continue;
        } else {
          cov_irciqsoe9.b[2][1]++;
        }
        cov_irciqsoe9.s[6]++;
        if (lang == '') {
          cov_irciqsoe9.b[3][0]++;
          cov_irciqsoe9.s[7]++;

          continue;
        } else {
          cov_irciqsoe9.b[3][1]++;
        }

        cov_irciqsoe9.s[8]++;
        return lang;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    cov_irciqsoe9.s[9]++;
    return $translate.instant('MENU.P_CL');
  };
  var PATH = (cov_irciqsoe9.s[10]++, 'DefaultLogFrame');
  cov_irciqsoe9.s[11]++;
  return {
    data: null,
    loadData: function loadData(fn) {
      var _this = this;

      cov_irciqsoe9.s[12]++;

      $CRUDService.getAll(PATH, { get: 'all' }, function (data) {
        cov_irciqsoe9.f[2]++;
        cov_irciqsoe9.s[13]++;

        _this.data = [{ id: '0', LABEL_LOG_FRAME: $translate.instant('PROJECT.CUSTOM_LOGFRAME') }].concat(data).map(function (value) {
          cov_irciqsoe9.f[3]++;
          cov_irciqsoe9.s[14]++;

          value.display = getDefaultLogLabel(value);
          cov_irciqsoe9.s[15]++;
          return value;
        });
        cov_irciqsoe9.s[16]++;
        if (fn) {
          cov_irciqsoe9.b[4][0]++;
          cov_irciqsoe9.s[17]++;

          fn(_this.data);
        } else {
          cov_irciqsoe9.b[4][1]++;
        }
      });
    },
    save: function save(validateItem, fn) {
      cov_irciqsoe9.s[18]++;

      $CRUDService.save(PATH, { action: 'MiseAJour', valeur: angular.toJson(validateItem) }, function (data) {
        cov_irciqsoe9.f[4]++;
        cov_irciqsoe9.s[19]++;

        fn(parseInt(data, 10));
      });
    },
    checkDelete: function checkDelete(validateItem, fn) {
      cov_irciqsoe9.s[20]++;

      $CRUDService.getAll(PATH, { get: 'check_delete', id: validateItem.id }, function (data) {
        cov_irciqsoe9.f[5]++;
        cov_irciqsoe9.s[21]++;

        fn(parseInt(data, 10) > 0);
      });
    },
    delete: function _delete(item, fn) {
      cov_irciqsoe9.s[22]++;

      $CRUDService.delet(PATH, { action: 'supp', id: item.id }, function (data) {
        cov_irciqsoe9.f[6]++;
        cov_irciqsoe9.s[23]++;

        fn(data);
      });
    }
  };
}]).directive('appLogframeTemplate', function () {
  cov_irciqsoe9.f[7]++;
  cov_irciqsoe9.s[24]++;

  return {
    restrict: 'AE', // attribute or element
    templateUrl: 'app/shared/logframe_template/dropdown.html',
    scope: {
      readonly: '=',
      value: '=',
      inline: '='
    },
    controller: function controller($scope, deltaLogframeTemplateFact, $LOGINService, $translate, $uibModal) {
      var vm = (cov_irciqsoe9.s[25]++, $scope);
      cov_irciqsoe9.s[26]++;
      vm.defaulSelect = $translate.instant('PROJECT.ME_METHOD_SELECT');
      cov_irciqsoe9.s[27]++;
      vm.fn = {
        getDefaultLogLabel: function getDefaultLogLabel(data) {
          var langs = (cov_irciqsoe9.s[28]++, $LOGINService.getLang() == 'en' ? (cov_irciqsoe9.b[5][0]++, [data.LABEL_LOG_FRAME_EN, data.LABEL_LOG_FRAME, data.LABEL_LOG_FRAME_AR]) : (cov_irciqsoe9.b[5][1]++, $LOGINService.getLang() == 'fr' ? (cov_irciqsoe9.b[6][0]++, [data.LABEL_LOG_FRAME, data.LABEL_LOG_FRAME_EN, data.LABEL_LOG_FRAME_AR]) : (cov_irciqsoe9.b[6][1]++, [data.LABEL_LOG_FRAME_AR, data.LABEL_LOG_FRAME_EN, data.LABEL_LOG_FRAME])));

          cov_irciqsoe9.s[29]++;
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = langs[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var lang = _step2.value;
              cov_irciqsoe9.s[30]++;

              if (!lang) {
                cov_irciqsoe9.b[7][0]++;
                cov_irciqsoe9.s[31]++;

                continue;
              } else {
                cov_irciqsoe9.b[7][1]++;
              }
              cov_irciqsoe9.s[32]++;
              if (lang == '') {
                cov_irciqsoe9.b[8][0]++;
                cov_irciqsoe9.s[33]++;

                continue;
              } else {
                cov_irciqsoe9.b[8][1]++;
              }

              cov_irciqsoe9.s[34]++;
              return lang;
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          cov_irciqsoe9.s[35]++;
          return $translate.instant('MENU.P_CL');
        },
        changeValue: function changeValue() {
          cov_irciqsoe9.s[36]++;

          vm.value = vm.values.selectedData.id;
        },
        openModelTemplate: function openModelTemplate() {
          cov_irciqsoe9.s[37]++;

          $uibModal.open({
            templateUrl: 'app/shared/logframe_template/modal.html',
            controller: function controller($uibModalInstance) {
              cov_irciqsoe9.f[8]++;
              cov_irciqsoe9.s[38]++;

              $scope.closeModal = function () {
                cov_irciqsoe9.f[9]++;
                cov_irciqsoe9.s[39]++;

                $uibModalInstance.dismiss('cancel');
                cov_irciqsoe9.s[40]++;
                vm.fn.init();
              };
            },
            scope: $scope,
            size: 'xs',
            windowClass: 'animated fadeInRight  left-modal',
            backdrop: 'static'
            // keyboard: false,
          });
        },
        init: function init() {
          cov_irciqsoe9.s[41]++;

          vm.values = {
            selectedData: null
          };
          cov_irciqsoe9.s[42]++;
          vm.loading = true;
          cov_irciqsoe9.s[43]++;
          deltaLogframeTemplateFact.loadData(function (data) {
            cov_irciqsoe9.f[10]++;
            cov_irciqsoe9.s[44]++;

            vm.data = data;
            cov_irciqsoe9.s[45]++;
            vm.loading = false;

            var item = (cov_irciqsoe9.s[46]++, vm.data.find(function (value) {
              cov_irciqsoe9.f[11]++;
              cov_irciqsoe9.s[47]++;

              return value.id == vm.value;
            }));

            cov_irciqsoe9.s[48]++;
            if (!item) {
              cov_irciqsoe9.b[9][0]++;
              cov_irciqsoe9.s[49]++;

              item = vm.data[0];
            } else {
              cov_irciqsoe9.b[9][1]++;
            }

            cov_irciqsoe9.s[50]++;
            vm.values.selectedData = item;
          });
        }
      };

      cov_irciqsoe9.s[51]++;
      vm.fn.init();
    }
  };
}).controller('DefaultLogFrameCtrl', function ($scope, SweetAlert, $uibModal, $translate, deltaLogframeTemplateFact) {
  cov_irciqsoe9.f[12]++;
  cov_irciqsoe9.s[52]++;

  $scope.listDefaults = [];
  cov_irciqsoe9.s[53]++;
  $scope.add = function () {
    cov_irciqsoe9.f[13]++;
    cov_irciqsoe9.s[54]++;

    $scope.edit({
      id: 0,
      LABEL_LOG_FRAME: '',
      STRUCTURE: []
    });
  };
  cov_irciqsoe9.s[55]++;
  $scope.edit = function (item) {
    cov_irciqsoe9.f[14]++;
    cov_irciqsoe9.s[56]++;

    $scope.validateItem = angular.copy(item);
    cov_irciqsoe9.s[57]++;
    $uibModal.open({
      templateUrl: 'app/views/default_log_frame/edit.html',
      controller: DefaultLogFrameCtrl_edit,
      scope: $scope,
      size: 'sm_',
      backdrop: 'static',
      windowClass: 'animated fadeInRight left-modal'
    });
  };
  cov_irciqsoe9.s[58]++;
  $scope.page = 1;
  cov_irciqsoe9.s[59]++;
  $scope.deleteItem = function (item) {
    cov_irciqsoe9.f[15]++;
    cov_irciqsoe9.s[60]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',
      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_irciqsoe9.f[16]++;
      cov_irciqsoe9.s[61]++;

      if (isconfirm) {
        cov_irciqsoe9.b[10][0]++;
        cov_irciqsoe9.s[62]++;

        return;
      } else {
        cov_irciqsoe9.b[10][1]++;
      }
      cov_irciqsoe9.s[63]++;
      $scope.isloading = true;
      cov_irciqsoe9.s[64]++;
      $CRUDService.delet(PATH, { action: 'supp', id: item.id }, function (data) {
        cov_irciqsoe9.f[17]++;
        cov_irciqsoe9.s[65]++;

        if (data < 0) {
          cov_irciqsoe9.b[11][0]++;
          cov_irciqsoe9.s[66]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
            type: 'warning'
          });
          cov_irciqsoe9.s[67]++;
          $scope.isloading = false;
        } else {
          cov_irciqsoe9.b[11][1]++;
          cov_irciqsoe9.s[68]++;

          $deltaDefaultTemplate.removeData(data);
          // $scope.getAll();
        }
      });
    });
  };

  cov_irciqsoe9.s[69]++;
  deltaLogframeTemplateFact.loadData(function (data) {
    cov_irciqsoe9.f[18]++;
    cov_irciqsoe9.s[70]++;

    $scope.listDefaults = data;

    cov_irciqsoe9.s[71]++;
    $scope.modal = {
      initData: function initData() {
        cov_irciqsoe9.s[72]++;

        deltaLogframeTemplateFact.loadData(function (data) {
          cov_irciqsoe9.f[19]++;
          cov_irciqsoe9.s[73]++;

          $scope.modal.isloading = false;
          cov_irciqsoe9.s[74]++;
          $scope.modal.params.dataSources = data.filter(function (value) {
            cov_irciqsoe9.f[20]++;
            cov_irciqsoe9.s[75]++;

            return value.id > 0;
          });
          cov_irciqsoe9.s[76]++;
          $scope.modal.cancel();
        });
      },

      params: {
        dataSources: data.filter(function (value) {
          cov_irciqsoe9.f[21]++;
          cov_irciqsoe9.s[77]++;

          return value.id > 0;
        }),
        columns: [{ field: 'display', headerText: $translate.instant('DEFAULT_LOGFRAME.LABEL').toUpperCase(), type: 'string' }, { headerText: '', width: 150, maxWidth: 180,
          commands: [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' }, id: 'edit' }, { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' }, id: 'delete' }]
        }],

        editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, allowEditOnDblClick: false },
        actionBegin: function actionBegin(action) {
          cov_irciqsoe9.s[78]++;

          if (action.requestType === 'add') {
            cov_irciqsoe9.b[12][0]++;
            cov_irciqsoe9.s[79]++;

            action.cancel = true;
            cov_irciqsoe9.s[80]++;
            $scope.modal.addModal();
          } else {
            cov_irciqsoe9.b[12][1]++;
          }
          cov_irciqsoe9.s[81]++;
          if (action.requestType === 'beginEdit') {
            cov_irciqsoe9.b[13][0]++;
            cov_irciqsoe9.s[82]++;

            action.cancel = true;
            cov_irciqsoe9.s[83]++;
            $scope.modal.editModal(action.rowData);
          } else {
            cov_irciqsoe9.b[13][1]++;
          }
          cov_irciqsoe9.s[84]++;
          if (action.requestType === 'delete') {
            cov_irciqsoe9.b[14][0]++;
            cov_irciqsoe9.s[85]++;

            action.cancel = true;
            cov_irciqsoe9.s[86]++;
            $scope.modal.delete(action.data[0]);
          } else {
            cov_irciqsoe9.b[14][1]++;
          }
        }
      },
      init: function init() {
        cov_irciqsoe9.s[87]++;

        $scope.page = 2;
        cov_irciqsoe9.s[88]++;
        this.error_code = false;
        cov_irciqsoe9.s[89]++;
        this.error_code_option = false;
        var PATH = (cov_irciqsoe9.s[90]++, 'DefaultLogFrame');
        cov_irciqsoe9.s[91]++;
        this.newOption = '';
        cov_irciqsoe9.s[92]++;
        this.blockDelete = true;
        cov_irciqsoe9.s[93]++;
        this.data = {
          option: ''
        };
        cov_irciqsoe9.s[94]++;
        this.blockDelete = true;
        // check_delete
        cov_irciqsoe9.s[95]++;
        if (this.validateItem.id == 0) {
          cov_irciqsoe9.b[15][0]++;
          cov_irciqsoe9.s[96]++;

          this.blockDelete = false;
        } else {
          cov_irciqsoe9.b[15][1]++;
          cov_irciqsoe9.s[97]++;

          deltaLogframeTemplateFact.checkDelete(this.validateItem, function (block) {
            cov_irciqsoe9.f[22]++;
            cov_irciqsoe9.s[98]++;

            $scope.modal.blockDelete = block;
          });
        }
      },
      deleteOption: function deleteOption() {
        cov_irciqsoe9.s[99]++;

        $scope.modal.validateItem.STRUCTURE.pop();
      },
      validateData: function validateData() {
        var validateItem = (cov_irciqsoe9.s[100]++, angular.copy($scope.modal.validateItem));
        cov_irciqsoe9.s[101]++;
        validateItem.STRUCTURE = validateItem.STRUCTURE.map(function (value) {
          cov_irciqsoe9.f[23]++;
          cov_irciqsoe9.s[102]++;
          return value.value;
        });
        cov_irciqsoe9.s[103]++;
        $scope.modal.error_code_require_fr = false;
        cov_irciqsoe9.s[104]++;
        $scope.modal.error_code_require_en = false;
        cov_irciqsoe9.s[105]++;
        $scope.modal.error_code_require_ar = false;
        cov_irciqsoe9.s[106]++;
        $scope.modal.error_code_require_es = false;
        cov_irciqsoe9.s[107]++;
        $scope.modal.error_code_require_pt = false;
        cov_irciqsoe9.s[108]++;
        $scope.modal.error_code_double_fr = false;
        cov_irciqsoe9.s[109]++;
        $scope.modal.error_code_double_en = false;
        cov_irciqsoe9.s[110]++;
        $scope.modal.error_code_double_ar = false;
        cov_irciqsoe9.s[111]++;
        $scope.modal.error_code_double_es = false;
        cov_irciqsoe9.s[112]++;
        $scope.modal.error_code_double_pt = false;
        cov_irciqsoe9.s[113]++;
        $scope.modal.error_code = false;
        cov_irciqsoe9.s[114]++;
        $scope.modal.error_code_require = !((cov_irciqsoe9.b[16][0]++, validateItem.LABEL_LOG_FRAME) || (cov_irciqsoe9.b[16][1]++, validateItem.LABEL_LOG_FRAME_EN) || (cov_irciqsoe9.b[16][2]++, validateItem.LABEL_LOG_FRAME_AR) || (cov_irciqsoe9.b[16][3]++, validateItem.LABEL_LOG_FRAME_ES) || (cov_irciqsoe9.b[16][4]++, validateItem.LABEL_LOG_FRAME_PT));
        cov_irciqsoe9.s[115]++;
        if ($scope.modal.error_code_require) {
          cov_irciqsoe9.b[17][0]++;
          cov_irciqsoe9.s[116]++;

          return;
        } else {
          cov_irciqsoe9.b[17][1]++;
        }

        cov_irciqsoe9.s[117]++;
        if (((cov_irciqsoe9.b[19][0]++, validateItem.LABEL_LOG_FRAME) || (cov_irciqsoe9.b[19][1]++, '')).length > 20) {
          cov_irciqsoe9.b[18][0]++;
          cov_irciqsoe9.s[118]++;

          $scope.modal.error_code_require_fr = true;
        } else {
          cov_irciqsoe9.b[18][1]++;
        }

        cov_irciqsoe9.s[119]++;
        if ((cov_irciqsoe9.b[21][0]++, ((cov_irciqsoe9.b[22][0]++, validateItem.LABEL_LOG_FRAME) || (cov_irciqsoe9.b[22][1]++, '')).length > 0) && (cov_irciqsoe9.b[21][1]++, $scope.modal.params.dataSources.find(function (value) {
          cov_irciqsoe9.f[24]++;
          cov_irciqsoe9.s[120]++;
          return (cov_irciqsoe9.b[23][0]++, value.LABEL_LOG_FRAME == validateItem.LABEL_LOG_FRAME) && (cov_irciqsoe9.b[23][1]++, value.id != validateItem.id);
        }))) {
          cov_irciqsoe9.b[20][0]++;
          cov_irciqsoe9.s[121]++;

          $scope.modal.error_code_double_fr = true;
        } else {
          cov_irciqsoe9.b[20][1]++;
        }

        cov_irciqsoe9.s[122]++;
        if (((cov_irciqsoe9.b[25][0]++, validateItem.LABEL_LOG_FRAME_EN) || (cov_irciqsoe9.b[25][1]++, '')).length > 20) {
          cov_irciqsoe9.b[24][0]++;
          cov_irciqsoe9.s[123]++;

          $scope.modal.error_code_require_en = true;
        } else {
          cov_irciqsoe9.b[24][1]++;
        }

        cov_irciqsoe9.s[124]++;
        if ((cov_irciqsoe9.b[27][0]++, ((cov_irciqsoe9.b[28][0]++, validateItem.LABEL_LOG_FRAME_EN) || (cov_irciqsoe9.b[28][1]++, '')).length > 0) && (cov_irciqsoe9.b[27][1]++, $scope.modal.params.dataSources.find(function (value) {
          cov_irciqsoe9.f[25]++;
          cov_irciqsoe9.s[125]++;
          return (cov_irciqsoe9.b[29][0]++, value.LABEL_LOG_FRAME_EN == validateItem.LABEL_LOG_FRAME_EN) && (cov_irciqsoe9.b[29][1]++, value.id != validateItem.id);
        }))) {
          cov_irciqsoe9.b[26][0]++;
          cov_irciqsoe9.s[126]++;

          $scope.modal.error_code_double_en = true;
        } else {
          cov_irciqsoe9.b[26][1]++;
        }

        cov_irciqsoe9.s[127]++;
        if (((cov_irciqsoe9.b[31][0]++, validateItem.LABEL_LOG_FRAME_AR) || (cov_irciqsoe9.b[31][1]++, '')).length > 20) {
          cov_irciqsoe9.b[30][0]++;
          cov_irciqsoe9.s[128]++;

          $scope.modal.error_code_require_ar = true;
        } else {
          cov_irciqsoe9.b[30][1]++;
        }

        cov_irciqsoe9.s[129]++;
        if ((cov_irciqsoe9.b[33][0]++, ((cov_irciqsoe9.b[34][0]++, validateItem.LABEL_LOG_FRAME_AR) || (cov_irciqsoe9.b[34][1]++, '')).length > 0) && (cov_irciqsoe9.b[33][1]++, $scope.modal.params.dataSources.find(function (value) {
          cov_irciqsoe9.f[26]++;
          cov_irciqsoe9.s[130]++;
          return (cov_irciqsoe9.b[35][0]++, value.LABEL_LOG_FRAME_AR == validateItem.LABEL_LOG_FRAME_AR) && (cov_irciqsoe9.b[35][1]++, value.id != validateItem.id);
        }))) {
          cov_irciqsoe9.b[32][0]++;
          cov_irciqsoe9.s[131]++;

          $scope.modal.error_code_double_ar = true;
        } else {
          cov_irciqsoe9.b[32][1]++;
        }

        cov_irciqsoe9.s[132]++;
        if (((cov_irciqsoe9.b[37][0]++, validateItem.LABEL_LOG_FRAME_ES) || (cov_irciqsoe9.b[37][1]++, '')).length > 20) {
          cov_irciqsoe9.b[36][0]++;
          cov_irciqsoe9.s[133]++;

          $scope.modal.error_code_require_es = true;
        } else {
          cov_irciqsoe9.b[36][1]++;
        }

        cov_irciqsoe9.s[134]++;
        if ((cov_irciqsoe9.b[39][0]++, ((cov_irciqsoe9.b[40][0]++, validateItem.LABEL_LOG_FRAME_ES) || (cov_irciqsoe9.b[40][1]++, '')).length > 0) && (cov_irciqsoe9.b[39][1]++, $scope.modal.params.dataSources.find(function (value) {
          cov_irciqsoe9.f[27]++;
          cov_irciqsoe9.s[135]++;
          return (cov_irciqsoe9.b[41][0]++, value.LABEL_LOG_FRAME_ES == validateItem.LABEL_LOG_FRAME_ES) && (cov_irciqsoe9.b[41][1]++, value.id != validateItem.id);
        }))) {
          cov_irciqsoe9.b[38][0]++;
          cov_irciqsoe9.s[136]++;

          $scope.modal.error_code_double_es = true;
        } else {
          cov_irciqsoe9.b[38][1]++;
        }

        cov_irciqsoe9.s[137]++;
        if (((cov_irciqsoe9.b[43][0]++, validateItem.LABEL_LOG_FRAME_PT) || (cov_irciqsoe9.b[43][1]++, '')).length > 20) {
          cov_irciqsoe9.b[42][0]++;
          cov_irciqsoe9.s[138]++;

          $scope.modal.error_code_require_pt = true;
        } else {
          cov_irciqsoe9.b[42][1]++;
        }

        cov_irciqsoe9.s[139]++;
        if ((cov_irciqsoe9.b[45][0]++, ((cov_irciqsoe9.b[46][0]++, validateItem.LABEL_LOG_FRAME_PT) || (cov_irciqsoe9.b[46][1]++, '')).length > 0) && (cov_irciqsoe9.b[45][1]++, $scope.modal.params.dataSources.find(function (value) {
          cov_irciqsoe9.f[28]++;
          cov_irciqsoe9.s[140]++;
          return (cov_irciqsoe9.b[47][0]++, value.LABEL_LOG_FRAME_PT == validateItem.LABEL_LOG_FRAME_PT) && (cov_irciqsoe9.b[47][1]++, value.id != validateItem.id);
        }))) {
          cov_irciqsoe9.b[44][0]++;
          cov_irciqsoe9.s[141]++;

          $scope.modal.error_code_double_pt = true;
        } else {
          cov_irciqsoe9.b[44][1]++;
        }

        cov_irciqsoe9.s[142]++;
        if ((cov_irciqsoe9.b[49][0]++, $scope.modal.error_code_require) || (cov_irciqsoe9.b[49][1]++, $scope.modal.error_code_require_en) || (cov_irciqsoe9.b[49][2]++, $scope.modal.error_code_require_ar) || (cov_irciqsoe9.b[49][3]++, $scope.modal.error_code_require_es) || (cov_irciqsoe9.b[49][4]++, $scope.modal.error_code_require_pt)) {
          cov_irciqsoe9.b[48][0]++;
          cov_irciqsoe9.s[143]++;

          return;
        } else {
          cov_irciqsoe9.b[48][1]++;
        }
        cov_irciqsoe9.s[144]++;
        if ((cov_irciqsoe9.b[51][0]++, $scope.modal.error_code_double_fr) || (cov_irciqsoe9.b[51][1]++, $scope.modal.error_code_double_en) || (cov_irciqsoe9.b[51][2]++, $scope.modal.error_code_double_ar) || (cov_irciqsoe9.b[51][3]++, $scope.modal.error_code_double_es) || (cov_irciqsoe9.b[51][4]++, $scope.modal.error_code_double_pt)) {
          cov_irciqsoe9.b[50][0]++;
          cov_irciqsoe9.s[145]++;

          return;
        } else {
          cov_irciqsoe9.b[50][1]++;
        }

        cov_irciqsoe9.s[146]++;
        $scope.modal.isloading = true;
        cov_irciqsoe9.s[147]++;
        deltaLogframeTemplateFact.save(validateItem, function () {
          cov_irciqsoe9.f[29]++;
          cov_irciqsoe9.s[148]++;

          $scope.modal.initData();
        });
      },
      addOption: function addOption() {
        cov_irciqsoe9.s[149]++;
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = $scope.modal.validateItem.STRUCTURE[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var item = _step3.value;
            cov_irciqsoe9.s[150]++;

            if (item == $scope.modal.data.option) {
              cov_irciqsoe9.b[52][0]++;
              cov_irciqsoe9.s[151]++;

              $scope.modal.error_code_option = true;
              cov_irciqsoe9.s[152]++;
              break;
            } else {
              cov_irciqsoe9.b[52][1]++;
            }
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }

        cov_irciqsoe9.s[153]++;
        $scope.modal.validateItem.STRUCTURE.push({ id: new Date().getTime(), value: angular.copy($scope.modal.data.option) });
        cov_irciqsoe9.s[154]++;
        $scope.modal.data.option = '';
      },
      cancel: function cancel() {
        cov_irciqsoe9.s[155]++;

        $scope.page = 1;
      },
      addModal: function addModal() {
        cov_irciqsoe9.s[156]++;

        this.editModal({
          id: 0,
          LABEL_LOG_FRAME: 'Cadre Logique',
          LABEL_LOG_FRAME_EN: 'LogFrame',
          LABEL_LOG_FRAME_ES: 'Marco de Registro',
          LABEL_LOG_FRAME_PT: 'Quadro de Registro',
          LABEL_LOG_FRAME_AR: 'سجل الإطار',
          STRUCTURE: []
        });
      },
      editModal: function editModal(item) {
        cov_irciqsoe9.s[157]++;

        $scope.modal.error_code_require = false;
        cov_irciqsoe9.s[158]++;
        this.validateItem = angular.copy(item);
        cov_irciqsoe9.s[159]++;
        this.validateItem.STRUCTURE = this.validateItem.STRUCTURE.map(function (value, index) {
          cov_irciqsoe9.f[30]++;
          cov_irciqsoe9.s[160]++;

          return { value: value, id: index + 1 };
        });
        cov_irciqsoe9.s[161]++;
        this.init();
      },
      delete: function _delete(item) {
        cov_irciqsoe9.s[162]++;

        SweetAlert.swal({
          title: $translate.instant('COMMON.CONFIRM'),
          // text: $translate.instant("FN_UNIT.CONFIRM_DELETE"),
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#c8c8c8',
          confirmButtonText: $translate.instant('COMMON.NO'),
          cancelButtonText: $translate.instant('COMMON.YES'),
          closeOnConfirm: true,
          closeOnCancel: true
        }, function (isconfirm) {
          cov_irciqsoe9.f[31]++;
          cov_irciqsoe9.s[163]++;

          if (isconfirm) {
            cov_irciqsoe9.b[53][0]++;
            cov_irciqsoe9.s[164]++;

            return;
          } else {
            cov_irciqsoe9.b[53][1]++;
          }
          cov_irciqsoe9.s[165]++;
          $scope.modal.isloading = true;
          cov_irciqsoe9.s[166]++;
          deltaLogframeTemplateFact.delete(item, function (data) {
            cov_irciqsoe9.f[32]++;
            cov_irciqsoe9.s[167]++;

            if (data < 0) {
              cov_irciqsoe9.b[54][0]++;
              cov_irciqsoe9.s[168]++;

              SweetAlert.swal({
                title: $translate.instant('COMMON.ERROR_DELETE'),
                text: $translate.instant('COMMON.TITLE_ERROR_DELETE'),
                type: 'warning'
              });
              cov_irciqsoe9.s[169]++;
              $scope.modal.isloading = false;
            } else {
              cov_irciqsoe9.b[54][1]++;
              cov_irciqsoe9.s[170]++;

              $scope.modal.initData();
            }
          });
        });
      }
    };
  });
});
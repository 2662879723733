'use strict';

var cov_20cfo8u1dt = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/formular/collectors/collectorsCtrl.js',
      hash = '341e62081c7fd82c7372ab9b8995ca4d953046f9',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/formular/collectors/collectorsCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 136,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 20
        },
        end: {
          line: 2,
          column: 26
        }
      },
      '2': {
        start: {
          line: 3,
          column: 26
        },
        end: {
          line: 3,
          column: 38
        }
      },
      '3': {
        start: {
          line: 5,
          column: 2
        },
        end: {
          line: 5,
          column: 30
        }
      },
      '4': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 38,
          column: 4
        }
      },
      '5': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 31,
          column: 7
        }
      },
      '6': {
        start: {
          line: 28,
          column: 8
        },
        end: {
          line: 28,
          column: 29
        }
      },
      '7': {
        start: {
          line: 30,
          column: 8
        },
        end: {
          line: 30,
          column: 45
        }
      },
      '8': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 36,
          column: 7
        }
      },
      '9': {
        start: {
          line: 33,
          column: 8
        },
        end: {
          line: 33,
          column: 29
        }
      },
      '10': {
        start: {
          line: 34,
          column: 8
        },
        end: {
          line: 34,
          column: 47
        }
      },
      '11': {
        start: {
          line: 40,
          column: 2
        },
        end: {
          line: 45,
          column: 4
        }
      },
      '12': {
        start: {
          line: 47,
          column: 2
        },
        end: {
          line: 60,
          column: 4
        }
      },
      '13': {
        start: {
          line: 48,
          column: 4
        },
        end: {
          line: 48,
          column: 52
        }
      },
      '14': {
        start: {
          line: 50,
          column: 4
        },
        end: {
          line: 50,
          column: 25
        }
      },
      '15': {
        start: {
          line: 52,
          column: 4
        },
        end: {
          line: 52,
          column: 28
        }
      },
      '16': {
        start: {
          line: 54,
          column: 4
        },
        end: {
          line: 59,
          column: 7
        }
      },
      '17': {
        start: {
          line: 55,
          column: 6
        },
        end: {
          line: 55,
          column: 30
        }
      },
      '18': {
        start: {
          line: 56,
          column: 6
        },
        end: {
          line: 56,
          column: 31
        }
      },
      '19': {
        start: {
          line: 58,
          column: 6
        },
        end: {
          line: 58,
          column: 31
        }
      },
      '20': {
        start: {
          line: 62,
          column: 2
        },
        end: {
          line: 76,
          column: 4
        }
      },
      '21': {
        start: {
          line: 63,
          column: 4
        },
        end: {
          line: 65,
          column: 5
        }
      },
      '22': {
        start: {
          line: 64,
          column: 6
        },
        end: {
          line: 64,
          column: 48
        }
      },
      '23': {
        start: {
          line: 67,
          column: 4
        },
        end: {
          line: 67,
          column: 50
        }
      },
      '24': {
        start: {
          line: 69,
          column: 4
        },
        end: {
          line: 75,
          column: 7
        }
      },
      '25': {
        start: {
          line: 78,
          column: 2
        },
        end: {
          line: 95,
          column: 4
        }
      },
      '26': {
        start: {
          line: 79,
          column: 4
        },
        end: {
          line: 79,
          column: 28
        }
      },
      '27': {
        start: {
          line: 80,
          column: 4
        },
        end: {
          line: 94,
          column: 7
        }
      },
      '28': {
        start: {
          line: 81,
          column: 6
        },
        end: {
          line: 81,
          column: 31
        }
      },
      '29': {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 92,
          column: 9
        }
      },
      '30': {
        start: {
          line: 84,
          column: 22
        },
        end: {
          line: 86,
          column: 13
        }
      },
      '31': {
        start: {
          line: 85,
          column: 10
        },
        end: {
          line: 85,
          column: 47
        }
      },
      '32': {
        start: {
          line: 87,
          column: 8
        },
        end: {
          line: 87,
          column: 44
        }
      },
      '33': {
        start: {
          line: 88,
          column: 8
        },
        end: {
          line: 90,
          column: 9
        }
      },
      '34': {
        start: {
          line: 89,
          column: 10
        },
        end: {
          line: 89,
          column: 29
        }
      },
      '35': {
        start: {
          line: 91,
          column: 8
        },
        end: {
          line: 91,
          column: 21
        }
      },
      '36': {
        start: {
          line: 97,
          column: 2
        },
        end: {
          line: 133,
          column: 4
        }
      },
      '37': {
        start: {
          line: 98,
          column: 4
        },
        end: {
          line: 132,
          column: 7
        }
      },
      '38': {
        start: {
          line: 110,
          column: 6
        },
        end: {
          line: 112,
          column: 7
        }
      },
      '39': {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 111,
          column: 15
        }
      },
      '40': {
        start: {
          line: 113,
          column: 6
        },
        end: {
          line: 113,
          column: 30
        }
      },
      '41': {
        start: {
          line: 114,
          column: 27
        },
        end: {
          line: 120,
          column: 7
        }
      },
      '42': {
        start: {
          line: 121,
          column: 6
        },
        end: {
          line: 131,
          column: 9
        }
      },
      '43': {
        start: {
          line: 122,
          column: 8
        },
        end: {
          line: 129,
          column: 9
        }
      },
      '44': {
        start: {
          line: 123,
          column: 10
        },
        end: {
          line: 126,
          column: 13
        }
      },
      '45': {
        start: {
          line: 127,
          column: 10
        },
        end: {
          line: 127,
          column: 35
        }
      },
      '46': {
        start: {
          line: 128,
          column: 10
        },
        end: {
          line: 128,
          column: 17
        }
      },
      '47': {
        start: {
          line: 130,
          column: 8
        },
        end: {
          line: 130,
          column: 33
        }
      },
      '48': {
        start: {
          line: 135,
          column: 2
        },
        end: {
          line: 135,
          column: 16
        }
      },
      '49': {
        start: {
          line: 139,
          column: 2
        },
        end: {
          line: 139,
          column: 28
        }
      },
      '50': {
        start: {
          line: 140,
          column: 26
        },
        end: {
          line: 140,
          column: 38
        }
      },
      '51': {
        start: {
          line: 142,
          column: 2
        },
        end: {
          line: 163,
          column: 4
        }
      },
      '52': {
        start: {
          line: 147,
          column: 36
        },
        end: {
          line: 147,
          column: 38
        }
      },
      '53': {
        start: {
          line: 148,
          column: 30
        },
        end: {
          line: 148,
          column: 32
        }
      },
      '54': {
        start: {
          line: 150,
          column: 6
        },
        end: {
          line: 152,
          column: 7
        }
      },
      '55': {
        start: {
          line: 151,
          column: 8
        },
        end: {
          line: 151,
          column: 15
        }
      },
      '56': {
        start: {
          line: 154,
          column: 6
        },
        end: {
          line: 159,
          column: 9
        }
      },
      '57': {
        start: {
          line: 155,
          column: 8
        },
        end: {
          line: 158,
          column: 9
        }
      },
      '58': {
        start: {
          line: 156,
          column: 10
        },
        end: {
          line: 156,
          column: 44
        }
      },
      '59': {
        start: {
          line: 157,
          column: 10
        },
        end: {
          line: 157,
          column: 44
        }
      },
      '60': {
        start: {
          line: 160,
          column: 6
        },
        end: {
          line: 160,
          column: 76
        }
      },
      '61': {
        start: {
          line: 165,
          column: 2
        },
        end: {
          line: 194,
          column: 4
        }
      },
      '62': {
        start: {
          line: 169,
          column: 4
        },
        end: {
          line: 172,
          column: 5
        }
      },
      '63': {
        start: {
          line: 170,
          column: 6
        },
        end: {
          line: 170,
          column: 31
        }
      },
      '64': {
        start: {
          line: 171,
          column: 6
        },
        end: {
          line: 171,
          column: 13
        }
      },
      '65': {
        start: {
          line: 174,
          column: 18
        },
        end: {
          line: 180,
          column: 5
        }
      },
      '66': {
        start: {
          line: 176,
          column: 8
        },
        end: {
          line: 176,
          column: 27
        }
      },
      '67': {
        start: {
          line: 181,
          column: 4
        },
        end: {
          line: 181,
          column: 28
        }
      },
      '68': {
        start: {
          line: 183,
          column: 4
        },
        end: {
          line: 191,
          column: 7
        }
      },
      '69': {
        start: {
          line: 185,
          column: 6
        },
        end: {
          line: 185,
          column: 31
        }
      },
      '70': {
        start: {
          line: 187,
          column: 6
        },
        end: {
          line: 190,
          column: 7
        }
      },
      '71': {
        start: {
          line: 188,
          column: 8
        },
        end: {
          line: 188,
          column: 33
        }
      },
      '72': {
        start: {
          line: 189,
          column: 8
        },
        end: {
          line: 189,
          column: 28
        }
      },
      '73': {
        start: {
          line: 196,
          column: 2
        },
        end: {
          line: 198,
          column: 4
        }
      },
      '74': {
        start: {
          line: 197,
          column: 4
        },
        end: {
          line: 197,
          column: 40
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 51
          },
          end: {
            line: 1,
            column: 52
          }
        },
        loc: {
          start: {
            line: 1,
            column: 159
          },
          end: {
            line: 136,
            column: 1
          }
        },
        line: 1
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 47,
            column: 16
          },
          end: {
            line: 47,
            column: 17
          }
        },
        loc: {
          start: {
            line: 47,
            column: 28
          },
          end: {
            line: 60,
            column: 3
          }
        },
        line: 47
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 54,
            column: 59
          },
          end: {
            line: 54,
            column: 60
          }
        },
        loc: {
          start: {
            line: 54,
            column: 68
          },
          end: {
            line: 59,
            column: 5
          }
        },
        line: 54
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 62,
            column: 25
          },
          end: {
            line: 62,
            column: 26
          }
        },
        loc: {
          start: {
            line: 62,
            column: 46
          },
          end: {
            line: 76,
            column: 3
          }
        },
        line: 62
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 78,
            column: 27
          },
          end: {
            line: 78,
            column: 28
          }
        },
        loc: {
          start: {
            line: 78,
            column: 39
          },
          end: {
            line: 95,
            column: 3
          }
        },
        line: 78
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 80,
            column: 83
          },
          end: {
            line: 80,
            column: 84
          }
        },
        loc: {
          start: {
            line: 80,
            column: 93
          },
          end: {
            line: 94,
            column: 5
          }
        },
        line: 80
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 83,
            column: 42
          },
          end: {
            line: 83,
            column: 43
          }
        },
        loc: {
          start: {
            line: 83,
            column: 51
          },
          end: {
            line: 92,
            column: 7
          }
        },
        line: 83
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 84,
            column: 45
          },
          end: {
            line: 84,
            column: 46
          }
        },
        loc: {
          start: {
            line: 84,
            column: 59
          },
          end: {
            line: 86,
            column: 9
          }
        },
        line: 84
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 97,
            column: 27
          },
          end: {
            line: 97,
            column: 28
          }
        },
        loc: {
          start: {
            line: 97,
            column: 43
          },
          end: {
            line: 133,
            column: 3
          }
        },
        line: 97
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 109,
            column: 7
          },
          end: {
            line: 109,
            column: 8
          }
        },
        loc: {
          start: {
            line: 109,
            column: 20
          },
          end: {
            line: 132,
            column: 5
          }
        },
        line: 109
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 121,
            column: 101
          },
          end: {
            line: 121,
            column: 102
          }
        },
        loc: {
          start: {
            line: 121,
            column: 109
          },
          end: {
            line: 131,
            column: 7
          }
        },
        line: 121
      },
      '11': {
        name: 'collectors_editCtrl',
        decl: {
          start: {
            line: 138,
            column: 9
          },
          end: {
            line: 138,
            column: 28
          }
        },
        loc: {
          start: {
            line: 138,
            column: 79
          },
          end: {
            line: 199,
            column: 1
          }
        },
        line: 138
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 154,
            column: 42
          },
          end: {
            line: 154,
            column: 43
          }
        },
        loc: {
          start: {
            line: 154,
            column: 51
          },
          end: {
            line: 159,
            column: 7
          }
        },
        line: 154
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 165,
            column: 24
          },
          end: {
            line: 165,
            column: 25
          }
        },
        loc: {
          start: {
            line: 165,
            column: 36
          },
          end: {
            line: 194,
            column: 3
          }
        },
        line: 165
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 175,
            column: 49
          },
          end: {
            line: 175,
            column: 50
          }
        },
        loc: {
          start: {
            line: 175,
            column: 58
          },
          end: {
            line: 177,
            column: 7
          }
        },
        line: 175
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 183,
            column: 93
          },
          end: {
            line: 183,
            column: 94
          }
        },
        loc: {
          start: {
            line: 183,
            column: 102
          },
          end: {
            line: 191,
            column: 5
          }
        },
        line: 183
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 196,
            column: 22
          },
          end: {
            line: 196,
            column: 23
          }
        },
        loc: {
          start: {
            line: 196,
            column: 34
          },
          end: {
            line: 198,
            column: 3
          }
        },
        line: 196
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 21,
            column: 12
          },
          end: {
            line: 21,
            column: 128
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 21,
            column: 40
          },
          end: {
            line: 21,
            column: 123
          }
        }, {
          start: {
            line: 21,
            column: 126
          },
          end: {
            line: 21,
            column: 128
          }
        }],
        line: 21
      },
      '1': {
        loc: {
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 31,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 31,
            column: 7
          }
        }, {
          start: {
            line: 27,
            column: 6
          },
          end: {
            line: 31,
            column: 7
          }
        }],
        line: 27
      },
      '2': {
        loc: {
          start: {
            line: 32,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 32,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }, {
          start: {
            line: 32,
            column: 6
          },
          end: {
            line: 36,
            column: 7
          }
        }],
        line: 32
      },
      '3': {
        loc: {
          start: {
            line: 62,
            column: 35
          },
          end: {
            line: 62,
            column: 44
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 62,
            column: 42
          },
          end: {
            line: 62,
            column: 44
          }
        }],
        line: 62
      },
      '4': {
        loc: {
          start: {
            line: 63,
            column: 4
          },
          end: {
            line: 65,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 63,
            column: 4
          },
          end: {
            line: 65,
            column: 5
          }
        }, {
          start: {
            line: 63,
            column: 4
          },
          end: {
            line: 65,
            column: 5
          }
        }],
        line: 63
      },
      '5': {
        loc: {
          start: {
            line: 63,
            column: 8
          },
          end: {
            line: 63,
            column: 25
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 63,
            column: 8
          },
          end: {
            line: 63,
            column: 13
          }
        }, {
          start: {
            line: 63,
            column: 17
          },
          end: {
            line: 63,
            column: 25
          }
        }],
        line: 63
      },
      '6': {
        loc: {
          start: {
            line: 88,
            column: 8
          },
          end: {
            line: 90,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 88,
            column: 8
          },
          end: {
            line: 90,
            column: 9
          }
        }, {
          start: {
            line: 88,
            column: 8
          },
          end: {
            line: 90,
            column: 9
          }
        }],
        line: 88
      },
      '7': {
        loc: {
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 112,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 112,
            column: 7
          }
        }, {
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 112,
            column: 7
          }
        }],
        line: 110
      },
      '8': {
        loc: {
          start: {
            line: 122,
            column: 8
          },
          end: {
            line: 129,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 122,
            column: 8
          },
          end: {
            line: 129,
            column: 9
          }
        }, {
          start: {
            line: 122,
            column: 8
          },
          end: {
            line: 129,
            column: 9
          }
        }],
        line: 122
      },
      '9': {
        loc: {
          start: {
            line: 150,
            column: 6
          },
          end: {
            line: 152,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 150,
            column: 6
          },
          end: {
            line: 152,
            column: 7
          }
        }, {
          start: {
            line: 150,
            column: 6
          },
          end: {
            line: 152,
            column: 7
          }
        }],
        line: 150
      },
      '10': {
        loc: {
          start: {
            line: 155,
            column: 8
          },
          end: {
            line: 158,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 155,
            column: 8
          },
          end: {
            line: 158,
            column: 9
          }
        }, {
          start: {
            line: 155,
            column: 8
          },
          end: {
            line: 158,
            column: 9
          }
        }],
        line: 155
      },
      '11': {
        loc: {
          start: {
            line: 169,
            column: 4
          },
          end: {
            line: 172,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 169,
            column: 4
          },
          end: {
            line: 172,
            column: 5
          }
        }, {
          start: {
            line: 169,
            column: 4
          },
          end: {
            line: 172,
            column: 5
          }
        }],
        line: 169
      },
      '12': {
        loc: {
          start: {
            line: 169,
            column: 8
          },
          end: {
            line: 169,
            column: 88
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 169,
            column: 8
          },
          end: {
            line: 169,
            column: 51
          }
        }, {
          start: {
            line: 169,
            column: 55
          },
          end: {
            line: 169,
            column: 88
          }
        }],
        line: 169
      },
      '13': {
        loc: {
          start: {
            line: 187,
            column: 6
          },
          end: {
            line: 190,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 187,
            column: 6
          },
          end: {
            line: 190,
            column: 7
          }
        }, {
          start: {
            line: 187,
            column: 6
          },
          end: {
            line: 190,
            column: 7
          }
        }],
        line: 187
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

cov_20cfo8u1dt.s[0]++;
angular.module('app').controller('collectorsCtrl', function ($scope, $stateParams, $deltahttp, $translate, $CRUDService, SweetAlert, $filter, $uibModal, $rootScope) {
  cov_20cfo8u1dt.f[0]++;

  var PATH_USER = (cov_20cfo8u1dt.s[1]++, 'User');
  var PATH_COLLECTORS = (cov_20cfo8u1dt.s[2]++, 'Collectors');

  cov_20cfo8u1dt.s[3]++;
  $scope.listeCollectors = [];

  cov_20cfo8u1dt.s[4]++;
  $scope.paramsTable = {
    editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, allowEditOnDblClick: false },
    columns: [{ field: 'EMAIL', headerText: $translate.instant('ALERT.EMAIL'), type: 'string', clipMode: 'EllipsisWithTooltip' }, { field: 'USER.n', width: 200, headerText: $translate.instant('MESSAGE.TO_'), type: 'string', clipMode: 'EllipsisWithTooltip' }, {
      headerText: '',
      width: 110,
      commands: []
      /* .concat(
        $rootScope.currentRight.u ? [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } }] : []
      ) */
      .concat($rootScope.currentRight.d ? (cov_20cfo8u1dt.b[0][0]++, [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }]) : (cov_20cfo8u1dt.b[0][1]++, []))
    }],
    actionBegin: function actionBegin(action) {
      cov_20cfo8u1dt.s[5]++;

      if (action.requestType === 'beginEdit') {
        cov_20cfo8u1dt.b[1][0]++;
        cov_20cfo8u1dt.s[6]++;

        action.cancel = true;

        cov_20cfo8u1dt.s[7]++;
        $scope.editCollector(action.rowData);
      } else {
        cov_20cfo8u1dt.b[1][1]++;
      }
      cov_20cfo8u1dt.s[8]++;
      if (action.requestType === 'delete') {
        cov_20cfo8u1dt.b[2][0]++;
        cov_20cfo8u1dt.s[9]++;

        action.cancel = true;
        cov_20cfo8u1dt.s[10]++;
        $scope.deleteCollector(action.data[0]);
        // $scope.setDeleteData(action.data[0]);
      } else {
        cov_20cfo8u1dt.b[2][1]++;
      }
    }
  };

  cov_20cfo8u1dt.s[11]++;
  $scope.collectorInit = {
    id: '0',
    idForm: $scope.selectedItem.id,
    user: '',
    emails: []
  };

  cov_20cfo8u1dt.s[12]++;
  $scope.init = function () {
    cov_20cfo8u1dt.f[1]++;
    cov_20cfo8u1dt.s[13]++;

    $scope.selectedCollector = $scope.collectorInit;

    cov_20cfo8u1dt.s[14]++;
    $scope.dataUser = [];

    cov_20cfo8u1dt.s[15]++;
    $scope.isloading = true;

    cov_20cfo8u1dt.s[16]++;
    $CRUDService.getAll(PATH_USER, { get: 'all_recipient' }, function (_data) {
      cov_20cfo8u1dt.f[2]++;
      cov_20cfo8u1dt.s[17]++;

      $scope.dataUser = _data;
      cov_20cfo8u1dt.s[18]++;
      $scope.isloading = false;

      cov_20cfo8u1dt.s[19]++;
      $scope.getAllCollector();
    });
  };

  cov_20cfo8u1dt.s[20]++;
  $scope.editCollector = function () {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (cov_20cfo8u1dt.b[3][0]++, {});
    cov_20cfo8u1dt.f[3]++;
    cov_20cfo8u1dt.s[21]++;

    if ((cov_20cfo8u1dt.b[5][0]++, !item) || (cov_20cfo8u1dt.b[5][1]++, !item.id)) {
      cov_20cfo8u1dt.b[4][0]++;
      cov_20cfo8u1dt.s[22]++;

      item = angular.copy($scope.collectorInit);
    } else {
      cov_20cfo8u1dt.b[4][1]++;
    }

    cov_20cfo8u1dt.s[23]++;
    $scope.selectedCollector = angular.copy(item);

    cov_20cfo8u1dt.s[24]++;
    $uibModal.open({
      templateUrl: 'app/views/formular/collectors/edit.html',
      controller: collectors_editCtrl,
      scope: $scope,
      size: 'md_',
      windowClass: 'animated fadeInRight left-modal'
    });
  };

  cov_20cfo8u1dt.s[25]++;
  $scope.getAllCollector = function () {
    cov_20cfo8u1dt.f[4]++;
    cov_20cfo8u1dt.s[26]++;

    $scope.isloading = true;
    cov_20cfo8u1dt.s[27]++;
    $CRUDService.getAll(PATH_COLLECTORS, { get: 'all', id: $scope.selectedItem.id }, function (_value) {
      cov_20cfo8u1dt.f[5]++;
      cov_20cfo8u1dt.s[28]++;

      $scope.isloading = false;

      cov_20cfo8u1dt.s[29]++;
      $scope.listeCollectors = _value.map(function (_item) {
        cov_20cfo8u1dt.f[6]++;

        var _user = (cov_20cfo8u1dt.s[30]++, $scope.dataUser.filter(function (_item_user) {
          cov_20cfo8u1dt.f[7]++;
          cov_20cfo8u1dt.s[31]++;

          return _item.IDUSER == _item_user.id;
        })[0]);
        cov_20cfo8u1dt.s[32]++;
        _item.USER = { id: '', l: '', n: '' };
        cov_20cfo8u1dt.s[33]++;
        if (_user) {
          cov_20cfo8u1dt.b[6][0]++;
          cov_20cfo8u1dt.s[34]++;

          _item.USER = _user;
        } else {
          cov_20cfo8u1dt.b[6][1]++;
        }
        cov_20cfo8u1dt.s[35]++;
        return _item;
      });
    });
  };

  cov_20cfo8u1dt.s[36]++;
  $scope.deleteCollector = function (item) {
    cov_20cfo8u1dt.f[8]++;
    cov_20cfo8u1dt.s[37]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_ACTORS.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_20cfo8u1dt.f[9]++;
      cov_20cfo8u1dt.s[38]++;

      if (isconfirm) {
        cov_20cfo8u1dt.b[7][0]++;
        cov_20cfo8u1dt.s[39]++;

        return;
      } else {
        cov_20cfo8u1dt.b[7][1]++;
      }
      cov_20cfo8u1dt.s[40]++;
      $scope.isloading = true;
      var validateItem = (cov_20cfo8u1dt.s[41]++, {

        idForm: $scope.selectedItem.id,
        Email: item.EMAIL,
        user: item.IDUSER,
        id: item.id
      });
      cov_20cfo8u1dt.s[42]++;
      $CRUDService.delet(PATH_COLLECTORS, { action: 'delete', valeur: $filter('json')(validateItem) }, function (data) {
        cov_20cfo8u1dt.f[10]++;
        cov_20cfo8u1dt.s[43]++;

        if (data < 0) {
          cov_20cfo8u1dt.b[8][0]++;
          cov_20cfo8u1dt.s[44]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_20cfo8u1dt.s[45]++;
          $scope.isloading = false;
          cov_20cfo8u1dt.s[46]++;
          return;
        } else {
          cov_20cfo8u1dt.b[8][1]++;
        }
        cov_20cfo8u1dt.s[47]++;
        $scope.getAllCollector();
      });
    });
  };

  cov_20cfo8u1dt.s[48]++;
  $scope.init();
});

function collectors_editCtrl($scope, $uibModalInstance, $filter, $CRUDService) {
  cov_20cfo8u1dt.f[11]++;
  cov_20cfo8u1dt.s[49]++;

  $scope.error_code = false;
  var PATH_COLLECTORS = (cov_20cfo8u1dt.s[50]++, 'Collectors');

  cov_20cfo8u1dt.s[51]++;
  $scope.emailInput = {
    onInvalidTag: function onInvalidTag(item) {},
    addOnPaste: function addOnPaste(item) {
      var selectedCollector_tmp = (cov_20cfo8u1dt.s[52]++, []);
      var email_collector = (cov_20cfo8u1dt.s[53]++, []);

      cov_20cfo8u1dt.s[54]++;
      if (_typeof($scope.selectedCollector.emails) != 'object') {
        cov_20cfo8u1dt.b[9][0]++;
        cov_20cfo8u1dt.s[55]++;

        return;
      } else {
        cov_20cfo8u1dt.b[9][1]++;
      }

      cov_20cfo8u1dt.s[56]++;
      $scope.selectedCollector.emails.map(function (_item) {
        cov_20cfo8u1dt.f[12]++;
        cov_20cfo8u1dt.s[57]++;

        if (!email_collector.includes(_item.email)) {
          cov_20cfo8u1dt.b[10][0]++;
          cov_20cfo8u1dt.s[58]++;

          email_collector.push(_item.email);
          cov_20cfo8u1dt.s[59]++;
          selectedCollector_tmp.push(_item);
        } else {
          cov_20cfo8u1dt.b[10][1]++;
        }
      });
      cov_20cfo8u1dt.s[60]++;
      $scope.selectedCollector.emails = angular.copy(selectedCollector_tmp);
    }
  };

  cov_20cfo8u1dt.s[61]++;
  $scope.setValidData = function () {
    cov_20cfo8u1dt.f[13]++;
    cov_20cfo8u1dt.s[62]++;

    // Vérification d'erreur


    if ((cov_20cfo8u1dt.b[12][0]++, $scope.selectedCollector.emails.length <= 0) || (cov_20cfo8u1dt.b[12][1]++, !$scope.selectedCollector.user.id)) {
      cov_20cfo8u1dt.b[11][0]++;
      cov_20cfo8u1dt.s[63]++;

      $scope.error_code = true;
      cov_20cfo8u1dt.s[64]++;
      return;
    } else {
      cov_20cfo8u1dt.b[11][1]++;
    }

    var _ITEM = (cov_20cfo8u1dt.s[65]++, {
      EMAIL: $scope.selectedCollector.emails.map(function (_item) {
        cov_20cfo8u1dt.f[14]++;
        cov_20cfo8u1dt.s[66]++;

        return _item.email;
      }),
      IDUSER: $scope.selectedCollector.user.id,
      FORM: $scope.selectedCollector.idForm
    });
    cov_20cfo8u1dt.s[67]++;
    $scope.isloading = true;

    cov_20cfo8u1dt.s[68]++;
    $CRUDService.save(PATH_COLLECTORS, { action: 'MiseAJour', valeur: angular.toJson(_ITEM) }, function (data_) {
      cov_20cfo8u1dt.f[15]++;
      cov_20cfo8u1dt.s[69]++;


      $scope.isloading = false;

      cov_20cfo8u1dt.s[70]++;
      if (data_.length > 0) {
        cov_20cfo8u1dt.b[13][0]++;
        cov_20cfo8u1dt.s[71]++;

        $scope.getAllCollector();
        cov_20cfo8u1dt.s[72]++;
        $scope.closeModal();
      } else {
        cov_20cfo8u1dt.b[13][1]++;
      }
    });

    // $scope.closeModal();
  };

  cov_20cfo8u1dt.s[73]++;
  $scope.closeModal = function () {
    cov_20cfo8u1dt.f[16]++;
    cov_20cfo8u1dt.s[74]++;

    $uibModalInstance.dismiss('cancel');
  };
}